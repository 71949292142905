import React, { useEffect, useState } from 'react';
import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import * as H from 'history';
import * as qs from 'qs';
import debounce from 'lodash.debounce';
import { PAGE } from 'root/store/paginate';
import { ROUTER_PATHS } from 'root/routes/constants';
import {
  ICatalogProducts,
  ICatalogQueryParams,
} from 'root/store/products/types/product-catalog.type';
import useBlockRoutingIfThereIsAnOpenSet from 'hooks/useBlockRoutingIfThereIsAnOpenSet';
import hintForProductSetStore from 'components/HintForProductSet/HintForProductSet.store';
import StopCreatingASetPopup from 'components/common/ModalPopup/popups/StopCreatingASetPopup';
import { EngagementRingsFiltersStore } from '../../store/engagementRingFilters.store';
import { IRingFiltersExtra } from '../../store/ringFiltersExtra.store';
import { Table } from '../Table';
import { Panel } from '../Panel';
import { IFilterRingsExtraParams, TEngagementRingsCatalog } from '../../types';

interface RingsProps {
  catalog: ICatalogProducts<
    TEngagementRingsCatalog,
    EngagementRingsFiltersStore
  >;
  filtersExtra: IRingFiltersExtra;
  historyReplace: (
    query: ICatalogQueryParams<EngagementRingsFiltersStore>,
  ) => void;
  location: H.Location<{ needToResetFilters?: boolean }>;
}

export const Catalog = observer((props: RingsProps) => {
  let reaction$: IReactionDisposer;
  const { search } = useLocation();
  const [mounted, setMounted] = useState(false); // check first render
  const { visiblePopup, releaseBlock } = useBlockRoutingIfThereIsAnOpenSet(
    ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
  );

  /**
   * Parse params from location search
   */
  const parseLocationSearch = () => {
    const { location, catalog, filtersExtra } = props;

    if (location.state?.needToResetFilters) {
      catalog.resetCatalog();
      catalog.filters.updateByExtraParams(filtersExtra.filtersExtra);
    }

    const locationSearch: any = location.search
      ? qs.parse(location.search, { ignoreQueryPrefix: true })
      : {};
    const queryFilterParams = locationSearch.filters
      ? { ...catalog.queryParams.filters, ...locationSearch.filters }
      : catalog.queryParams.filters;

    return location.search
      ? {
          ...catalog.queryParams,
          ...qs.parse(location.search, { ignoreQueryPrefix: true }),
          filters: queryFilterParams,
        }
      : null;
  };

  const handleRemoveSet = () => {
    hintForProductSetStore.removeSet();
    releaseBlock && releaseBlock();
  };

  /**
   * Facade load data and change history
   */
  const load = async (): Promise<void> => {
    props.historyReplace(props.catalog.queryParams);
    props.catalog.loadProducts();
  };

  const updateExtraParams = async () => {
    const filters =
      props.filtersExtra.fetchFilterExtraParams() as IFilterRingsExtraParams;
    props.catalog.filters.updateByExtraParams(filters);
  };

  const initCatalog = async () => {
    await updateExtraParams();

    const query = parseLocationSearch();
    query && props.catalog.updateCatalog(query);

    reaction$ = reaction(
      () => {
        props.catalog.loading = true;
        return JSON.stringify(props.catalog.queryParams);
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      debounce(async () => {
        try {
          await load();
        } catch (e) {}
      }, 800),
      { fireImmediately: true },
    );
  };

  useEffect(() => {
    void initCatalog();
    setMounted(true);

    return () => {
      reaction$ && reaction$();
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      const query = parseLocationSearch();
      query && props.catalog.updateCatalog(query);
    }
  }, [search]);

  /**
   * Order Handle
   * @param {String} property
   * @param {String} direction
   */
  const handleOrder = (property: string, direction: string | null): void => {
    props.catalog.order.updateOrder(property, direction || 'null');
  };

  /**
   * Limit pages Handle
   * @param {Number} limit
   */
  const handleLimit = (limit: number): void => {
    props.catalog.pagination.setLimitPage(limit);
  };

  /**
   * Pagination Handle
   * @param {Number} page
   */
  const handlePagination = (page: number): void => {
    props.catalog.pagination.setPage(page);
    window.scrollTo(0, 0);
  };

  /**
   * Filter Handle (for reactions)
   */
  const handleFilters = (): void => {
    props.catalog.pagination.setPage(PAGE);
  };

  return (
    <>
      <Panel
        filters={props.catalog.filters}
        filtersExtra={props.filtersExtra.filtersExtra}
        handleFilters={handleFilters}
      />
      <Table
        data={props.catalog.list}
        displaySpinner={props.catalog.loading}
        order={props.catalog.order}
        pagination={props.catalog.pagination}
        handleOrder={handleOrder}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
      />

      {!!hintForProductSetStore.product && (
        <StopCreatingASetPopup
          visiblePopup={visiblePopup}
          productParams={hintForProductSetStore.product}
          removeSet={handleRemoveSet}
        />
      )}
    </>
  );
});
