import React from 'react';
import Svg from '../../Svg';

interface ICushionIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CushionIcon(props: ICushionIconProps): JSX.Element {
  return (
    <Svg width="20" height="20" viewBox="0 0 410 410" fill="none" {...props}>
      <path
        d="M269.44 6.74H206.5l-.53-.22a2.51 2.51 0 0 0-1.94 0l-.53.22h-62.94C66.66 6.74 6.53 66.86 6.53 140.77v128.87c0 73.91 60.13 134.04 134.04 134.04h128.87c73.91 0 134.04-60.13 134.04-134.04V140.77C403.47 66.86 343.34 6.74 269.44 6.74zm-63.95 5 82.25 34.68H122.26l82.25-34.68h.98zm.89 285.08c-.84-.56-1.93-.56-2.76 0H113.38V113.6H296.6v183.22h-90.22zm90.23 5v54.62l-82.66-54.62h82.66zm-100.56 0-82.66 54.62v-54.62h82.66zM301.61 113.6h55.04l-55.04 83.29V113.6zm-5-5h-83.92l83.92-55.45v55.45zm-91.61-.91-85.16-56.27h170.32L205 107.69zm-7.69.91h-83.92V53.15l83.92 55.45zm-88.92 88.29L53.35 113.6h55.04v83.29zm-.5 8.32L51.21 291V119.42l56.68 85.79zm.5 8.32v83.29H53.35l55.04-83.29zM205 301.9l86.42 57.1H118.58L205 301.9zm96.61-88.37 55.04 83.29h-55.04v-83.29zm.5-8.32 56.69-85.79V291l-56.69-85.79zm56.68-96.61H301.6V51.41h57.19v57.19zm-250.4 0H51.21V51.41h57.18v57.19zM46.21 286.95l-34.47-81.74 34.47-81.74v163.48zm5 14.87h57.18V359H51.21v-57.18zM285.75 364 205 398.05 124.25 364h161.5zm15.86-62.18h57.19V359h-57.19v-57.18zm62.18-178.36 34.46 81.74-34.46 81.74V123.46zM269.44 11.74c33.94 0 64.86 13.18 87.92 34.68h-56.9c-.12-.08-.24-.16-.37-.22l-81.72-34.46h51.07zm-77.8 0L109.92 46.2c-.14.06-.25.14-.37.22h-56.9c23.06-21.5 53.98-34.68 87.92-34.68h51.07zM46.21 52.86v57.74l-34.68 82.25v-52.07c0-33.95 13.18-64.86 34.68-87.92zM11.53 269.64v-52.07l34.68 82.25v57.74c-21.5-23.06-34.68-53.97-34.68-87.92zM52.65 364h58.73l82.25 34.68h-53.06c-33.95 0-64.86-13.18-87.92-34.68zm163.72 34.68L298.62 364h58.73c-23.06 21.5-53.98 34.68-87.92 34.68h-53.06zm147.42-41.12v-57.74l34.68-82.25v52.07c0 33.94-13.18 64.86-34.68 87.92zm34.68-164.72-34.68-82.25V52.86c21.5 23.06 34.68 53.98 34.68 87.92v52.06z"
        fill="#4d4048"
      />
    </Svg>
  );
}
