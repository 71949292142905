import * as React from 'react';
import { observer } from 'mobx-react';
import { CartItemStoneImpl } from 'root/store/cart/item.store';
import Icon from 'components/common/Icon';

interface ItemCertificateProps {
  product: CartItemStoneImpl;
}

export const ItemCertificate = observer((props: ItemCertificateProps) => {
  const { product } = props;
  return (
    <>
      {product.certificate ? (
        <a
          className="common-link"
          target="_blank"
          rel="noopener noreferrer"
          href={`${product.certificate}`}
        >
          {product.gradingLab}
          <Icon id="file_certificate" />
        </a>
      ) : null}
    </>
  );
});
