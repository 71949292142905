import React from 'react';
import Svg from '../../Svg';

interface IOvalMeasWidthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function OvalMeasWidthIcon(
  props: IOvalMeasWidthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M100 163.25c24.35 0 44.09-28.318 44.09-63.25S124.35 36.75 100 36.75c-24.35 0-44.09 28.318-44.09 63.25s19.74 63.25 44.09 63.25z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.3 36.75 87.53 54.39l-19.37 1.85.52 26.73L55.91 100l12.77 15.81-1.23 26.87 19.47 1.72 13.38 18.85"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.7 36.75 12.77 17.64 19.37 1.85-.52 26.73L144.09 100l-12.77 15.81 1.22 26.87-19.46 1.72-13.38 18.85M82.23 42.1l5.3 12.29-5.47 20.07-13.38 8.51 6.69 16.42-6.69 16.42 13.38 7.91 4.86 20.68-5.65 12.88"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m117.77 42.1-5.3 12.29 5.47 20.07 13.38 8.51-6.69 16.42 6.69 16.42-13.38 7.91-4.86 20.68 5.65 12.88M100.3 63.51 82.36 74.76 75.67 99.7l6.69 24.32L100 135.58M58.36 79.14l10.32 3.83M58.36 120.86l10.32-5.05M141.66 79.14l-10.32 3.83M141.66 120.86l-10.32-5.05"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m87.53 54.39 12.77 9.12 17.34 11.25 6.69 24.94-6.69 24.32L100 135.58M112.47 54.39l-12.17 9.12M113.08 144.4l-12.78-8.52-.46-.3-12.92 8.82"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.4 98.71v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.4 94.19V29.1"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.03 3.03"
      />
      <path
        d="M144.4 27.59v-1.5M55.43 98.82v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.43 94.29V29.21"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.03 3.03"
      />
      <path
        d="M55.43 27.7v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.6 26.09h88.8"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
