import React from 'react';
import Svg from '../../Svg';

interface IRadiantTableIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function RadiantTableIcon(
  props: IRadiantTableIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M34.44 72.47v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.44 68.09V40.78"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.87 2.87"
      />
      <path
        d="M34.44 39.35v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.44 38.1h130.14"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.59 139.61 76.3 123.55l-13.36-12.19-27.28-38.9v-3.28l16.25-10.26h47.68l-37.52 5.61H43.02l20.84-5.61"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m135.32 58.92 20.84 5.61h-19.05l-37.52-5.61h47.68l16.26 10.26v3.28l-27.29 38.9-13.35 12.19-23.3 16.06v-32.8M35.66 69.18h127.87M35.66 72.47h127.87M62.07 64.53h75.04M78.15 124.83 56.76 92.69l-8.83-2.73"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m57.7 94.09 41.89 34.879-19.71-29.7-18.77-12.58-4.35 6M121.03 124.83l21.4-32.14 8.82-2.73M141.49 94.09l-41.9 34.879 19.72-29.7 18.76-12.58 4.36 6"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m79.88 99.27 19.71 7.54 19.72-7.54M61.11 86.69l-4.35-14.23v-3.28l3.68-4.65M138.07 86.69l4.36-14.23v-3.28l-3.68-4.65"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.58 72.47v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.58 68.09V40.78"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.87 2.87"
      />
      <path
        d="M164.58 39.35v-1.5M51.73 58.92v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.73 54.36V25.32"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.06 3.06"
      />
      <path
        d="M51.73 23.79v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.73 22.56h95.55"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.28 58.92v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.28 54.36V25.32"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.06 3.06"
      />
      <path
        d="M147.28 23.79v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
