import React from 'react';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import { PRODUCT_TYPE_TITLE_LIST } from 'utils/constants';
import { ICategoriesProduct } from 'root/store/products/types/product.type';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import { TProductFromHint } from 'components/HintForProductSet/HintForProductSet.types';
import Icon from '../../../Icon';

import './StopCreatingASetPopup.scss';

interface IStopCreatingASetPopupProps {
  visiblePopup: IObservableValue<boolean>;
  productParams: TProductFromHint;
  removeSet: () => void;
}

const StopCreatingASetPopup = (props: IStopCreatingASetPopupProps) => {
  const { visiblePopup, productParams, removeSet } = props;
  const currentCategory =
    productParams?.categories?.find(
      ({ alias }) => PRODUCT_TYPE_TITLE_LIST[alias],
    ) || ({} as ICategoriesProduct);

  return (
    <PortalControl>
      <ModalWindow visible={visiblePopup.get()}>
        <div
          className="btn btn_bordered-default_without_border btn_circle modal-window__btn-close"
          onClick={() => visiblePopup.set(false)}
        >
          <Icon id="close" />
        </div>
        <div className="modal-window-header">
          <h2 className="modal-window-header__title">
            Are you sure you want to cancel{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias]} SKU:
            {productParams?.sku} finishing?
          </h2>
        </div>
        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text_small modal-window-body__text_center">
            If you will proceed all{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias]?.toLowerCase()}{' '}
            data will be lost and{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias]?.toLowerCase()}{' '}
            setting will be removed from shopping cart
          </p>

          <div className="modal-window-body-btn-wrap">
            <button
              className="send btn btn_lg btn_px-0 btn_brown stop-creating-set-btn"
              onClick={removeSet}
            >
              Yes
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
};

export default observer(StopCreatingASetPopup);
