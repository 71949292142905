import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function CuttingA(props: IProps): JSX.Element {
  return (
    <Svg width="106" height="104" viewBox="0 0 106 104" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 46.6545L0 39.6612L12.5 39.3615L14.8 30.57L25 35.2655L16.2 38.9619L9 46.6545ZM25.5 34.9657L30 23.3769L41.8 31.2693L52.9 20.5796L64 31.2693L75.8 23.3769L80.3 34.9657L74.1 32.3683L53 30.2703L31.9 32.3683L25.5 34.9657ZM80.7 35.1656L90.9 30.4701L93.2 39.2616L105.7 39.5613L96.7 46.5546L89.5 38.7621L80.7 35.1656Z"
        fill="#D8C999"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8984 72.3311H75.6984L62.8984 83.4204H42.6984L29.8984 72.3311Z"
        fill="#7AC299"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8992 78.4246V10.0903H41.4992L31.3992 0L21.1992 10.0903H25.7992V68.7339L36.8992 78.4246Z"
        fill="url(#paint0_linear_9184_212676)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.6992 2.39795H79.7992V68.7342L68.6992 78.4248V2.39795Z"
        fill="url(#paint1_linear_9184_212676)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.7016 0.399902H79.8016V18.8821H84.4016L74.3016 28.9724L64.1016 18.8821H68.7016V0.399902Z"
        fill="url(#paint2_linear_9184_212676)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.8016 92.2121L98.1016 52.85L97.8016 47.8548L89.4016 38.8634L73.9016 32.3697L52.8016 30.3716L31.7016 32.3697L16.2016 38.8634L7.90157 47.8548L7.60156 52.85L52.8016 92.2121Z"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 92.2121L57.1008 80.7231L69.2008 64.239L84.6008 56.7462L87.8008 46.8557L81.8008 37.6645L73.9008 32.3696"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.8008 37.6655L74.2008 42.361L52.8008 44.2592L66.9008 50.7529L84.6008 56.7472"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.2008 42.3599L66.9008 50.7518L52.8008 60.8421"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.8031 46.8555L74.2031 42.3599"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 60.8423L69.2008 64.239"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 64.8386L69.0008 59.5436L83.3008 60.8424L92.7008 55.048L98.1008 52.8501"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.7016 55.0469L84.6016 56.7453"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.1008 52.8502L87.8008 46.856L92.7008 55.0481"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4023 38.8633L98.1023 52.8498"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.8008 37.6655L89.4008 38.8644"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9023 50.7524L69.0023 59.544"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.3023 60.8423L70.9023 76.4273"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.1016 80.7232L83.3016 60.8423"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 64.8384L57.1008 80.7231"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 92.2121L48.5008 80.7231L36.4008 64.239L21.1008 56.7462L17.8008 46.8557L23.9008 37.6645L31.7008 32.3696"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9016 37.6655L31.5016 42.361L52.8016 44.2592L38.7016 50.7529L21.1016 56.7472"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5 42.3599L38.7 50.7518L52.8 60.8421"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8008 46.8555L31.5008 42.3599"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8024 60.8423L36.4023 64.239"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8016 64.8386L36.6016 59.5436L22.3016 60.8424L13.0016 55.048L7.60156 52.8501"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 55.0469L21.1 56.7453"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.60156 52.8502L17.8016 46.856L13.0016 55.0481"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2016 38.8633L7.60156 52.8498"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9031 37.6655L16.2031 38.8644"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.7016 50.7524L36.6016 59.544"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3008 60.8423L34.7008 76.4273"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.5008 80.7232L22.3008 60.8423"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8 64.8384L48.5 80.7231"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9184_212676"
          x1="31.3737"
          y1="-0.0188818"
          x2="31.3737"
          y2="83.1632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9184_212676"
          x1="74.255"
          y1="83.0185"
          x2="74.255"
          y2="2.36468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9184_212676"
          x1="74.2574"
          y1="30.7059"
          x2="74.2574"
          y2="0.356045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
      </defs>
    </Svg>
  );
}
