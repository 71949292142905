import { AwnPosition } from 'awesome-notifications';

export const DEFAULT_NOTIFIER_OPTIONS = {
  position: 'top-right' as AwnPosition,
  maxNotifications: 10,
  animationDuration: 300,
  labels: {
    warning: 'Warning',
    info: 'Info',
    tip: 'Tip',
    success: 'Success',
    alert: 'Error',
    async: 'Loading',
  },
  icons: {
    // TODO: need styles~
    prefix: '<i class="icon icon_mr-sm ',
    suffix: '"></i>',
    info: 'icon_info-circle-notifier',
    warning: 'icon_warning-notifier',
    tip: 'icon_question-notifier',
    success: 'icon_check-notifier',
    alert: 'icon_warning-circle-notifier',
    async: 'cof fa-spin',
  },
  messages: {
    async: 'Please, wait…',
    'async-block': 'Loading',
  },
};

export const ACCOUNT_ERRORS = {
  CHANGE_SETTING: {
    label: 'Error setting update',
    message: 'Please, reload page and try again.',
  },
  PICTURE_UPDATING: {
    label: 'Error picture updating',
    message: 'Please, reload page and try again.',
  },
  PROFILE_UPDATING: {
    label: 'Error profile updating',
    message: 'Please, reload page and try again.',
  },
};

export const SUCCESS_MESSAGES = {
  CHANGE_SETTING: {
    label: 'Success',
    message: 'Settings are changed.',
  },
  PICTURE_UPDATING: {
    label: 'Success',
    message: 'Picture is updating.',
  },
  PROFILE_UPDATING: {
    label: 'Success',
    message: 'Profile is updating.',
  },
};
