import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { CartConfirmation } from './CartConfirmation';

import customerFormStore from '../CartCheckout/CustomerStep/customerForm.store';
import shippingFormStore from '../CartCheckout/ShippingStep/shipping.store';
import paymentFormsStore from '../CartCheckout/PaymentStep/paymentForms.store';
import cartConfirmationStore from './CartConfirmationStore';
import store from 'root/store';

const { cart } = store;

export const CartConfirmationPage = withRouter(
  observer((props: RouteComponentProps) => (
    <CartConfirmation
      {...props}
      store={cartConfirmationStore}
      cart={cart}
      customerFormStore={customerFormStore}
      shippingFormStore={shippingFormStore}
      paymentFormsStore={paymentFormsStore}
    />
  )),
);
