import React from 'react';
import Svg from '../../Svg';

interface IHeartMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function HeartMeasLengthIcon(
  props: IHeartMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M134.77 32.81h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.18 32.81h33.42"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M174.06 32.81h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.55 32.81v130.74"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.77 163.55h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.19 163.55h68.41"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M174.06 163.55h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157 44a25.575 25.575 0 0 0-5.13-4.67C121.84 18.64 100 55.88 100 55.88S78.16 18.64 48.14 39.33A25.563 25.563 0 0 0 43 44c-39.66 47.17 57 119.55 57 119.55S196.66 91.17 157 44z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m100 163.55-10.61-32.4-29.56-5.79 1.91-23.67-25.62-15.86 13.47-18.64-3.9-25.94L100 55.88l54.31-14.63-3.9 25.94 13.47 18.64-25.62 15.86 1.91 23.67-29.56 5.79-10.61 32.4z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m74.1 34.18 6.1 16.37-10.64 12.31.55 24.02-8.37 14.81 22.06 6.98 5.59 22.48-10.35 14.41M125.82 34.18l-6.1 16.37 10.65 12.31-.56 24.02 8.38 14.81-22.07 6.98-5.58 22.48 10.34 14.41"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m100 55.88-30.44 6.98-19.97 4.33 20.52 19.69 13.69 21.79 16.2 15.5-10.61 6.98"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.77 55.88 30.45 6.98 19.97 4.33-20.53 19.69-13.69 21.79-16.2 15.5 10.62 6.98M61.74 101.68l-9.73 13.75M138.19 101.72l9.73 13.75M49.59 67.19l-15.04-6.81M150.23 67.24l15.04-6.81"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
