export enum ROUTER_PATHS {
  AUTH_PATH = '/auth',
  SIGN_IN_PATH = '/auth/signin',
  SIGN_UP_PATH = '/auth/signup',
  CONFIRM_PATH = '/auth/confirmemail',
  FORGOT_PATH = '/auth/forgot',
  ACCOUNT_PATH = '/account',
  RINGS_PATH = '/rings',
  RINGS_ENGAGEMENT_PATH = '/rings/engagement',
  RINGS_ENGAGEMENT_CATALOG_PATH = '/rings/engagement/catalog',
  RINGS_ENGAGEMENT_DETAILS_PATH = '/rings/engagement/catalog/:ringId',
  RINGS_WEDDING_PATH = '/rings/wedding',
  RINGS_WEDDING_CATALOG_PATH = '/rings/wedding/catalog',
  RINGS_WEDDING_DETAILS_PATH = '/rings/wedding/catalog/:ringId',
  STONES_PATH = '/stones',
  DIAMONDS_PATH = '/diamonds',
  DIAMONDS_CATALOG_PATH = '/diamonds/catalog',
  DIAMONDS_DETAILS_PATH = '/diamonds/catalog/:stoneId',
  JEWELRY_PATH = '/jewelry',
  ST_VALENTINE_JEWELRY_PATH = '/st-valentine/jewelry',
  JEWELRY_CATALOG_PATH = '/jewelry/catalog',
  JEWELRY_DETAILS_PATH = '/jewelry/catalog/:jewelryId',
  EDUCATION_PATH = '/education',
  EDUCATION_CUTTING_PATH = '/education/cutting',
  EDUCATION_CLARITY_PATH = '/education/clarity',
  EDUCATION_COLOR_PATH = '/education/color',
  EDUCATION_CARAT_PATH = '/education/carat',
  FAQ_PATH = '/faq',
  FAQ_ABOUT_PATH = '/faq/about-us',
}
