import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function CuttingD(props: IProps): JSX.Element {
  return (
    <Svg width="106" height="104" viewBox="0 0 106 104" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.7976 76.7248V67.0342L32.0977 91.6105L41.3976 101.401L69.7976 76.7248Z"
        fill="#67B88B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6984 85.9165L45.1984 103.2H25.8984L32.6984 85.9165Z"
        fill="#67B88B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8984 76.7248V67.0342L74.4984 91.6105L65.2984 101.401L36.8984 76.7248Z"
        fill="#67B88B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.9 85.9165L61.5 103.2H80.8L73.9 85.9165Z"
        fill="#67B88B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.9961 7.09277H75.0961V72.0303L63.9961 81.721V7.09277Z"
        fill="url(#paint0_linear_9184_212679)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.9984 0H75.0984V18.4822H79.6984L69.4984 28.6724L59.3984 18.4822H63.9984V0Z"
        fill="url(#paint1_linear_9184_212679)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.4969 7.09277H31.2969V72.0303L42.4969 81.721V7.09277Z"
        fill="url(#paint2_linear_9184_212679)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.4969 0H31.2969V18.4822H26.7969L36.8969 28.6724L46.9969 18.4822H42.4969V0Z"
        fill="url(#paint3_linear_9184_212679)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.2 91.0139L98.5 51.6517L98.2 46.6565L89.8 37.6652L74.3 31.1714L53.2 29.1733L32.1 31.1714L16.6 37.6652L8.29999 46.6565L8 51.6517L53.2 91.0139Z"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 91.0139L57.4992 79.6248L69.5992 63.0408L84.9992 55.548L88.1992 45.6575L82.1992 36.4663L74.2992 31.1714"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.1992 36.4673L74.5992 41.2627L53.1992 43.061L67.2992 49.5547L84.9992 55.5489"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5992 41.2612L67.2992 49.5533L53.1992 59.7435"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.2015 45.657L74.6016 41.2612"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 59.7437L69.5992 63.0405"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 63.7402L69.3992 58.3454L83.6992 59.7441L93.0992 53.8498L98.4992 51.6519"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.1 53.8486L85 55.547"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.4992 51.652L88.1992 45.6577L93.0992 53.8498"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.8008 37.665L98.5008 51.6516"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.1992 36.4673L89.7992 37.6661"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.3008 49.5542L69.4008 58.3457"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.7008 59.7437L71.3008 75.2288"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5 79.6245L83.7 59.7437"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 63.7397L57.4992 79.6245"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 91.0139L48.8992 79.6248L36.7992 63.0408L21.4992 55.548L18.1992 45.6575L24.2992 36.4663L32.0992 31.1714"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3 36.4673L31.9 41.2627L53.2 43.061L39.1 49.5547L21.5 55.5489"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8984 41.2612L39.0984 49.5533L53.1984 59.7435"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1992 45.657L31.8992 41.2612"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.2008 59.7437L36.8008 63.0405"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.2 63.7402L37 58.3454L22.7 59.7441L13.4 53.8498L8 51.6519"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3984 53.8486L21.4984 55.547"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 51.652L18.2 45.6577L13.4 53.8498"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6 37.665L8 51.6516"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3015 36.4673L16.6016 37.6661"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.1 49.5542L37 58.3457"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6992 59.7437L35.0992 75.2288"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.8992 79.6245L22.6992 59.7437"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1984 63.7397L48.8984 79.6245"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9184_212679"
          x1="69.525"
          y1="110.439"
          x2="69.525"
          y2="7.05241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003916" />
          <stop offset="0.49" stopColor="#92CBA7" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9184_212679"
          x1="69.5523"
          y1="30.3623"
          x2="69.5523"
          y2="0.012388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="0.671875" stopColor="#D5E9DD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9184_212679"
          x1="36.9007"
          y1="110.445"
          x2="36.9007"
          y2="7.05241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003916" />
          <stop offset="0.49" stopColor="#92CBA7" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9184_212679"
          x1="36.9007"
          y1="30.3623"
          x2="36.9007"
          y2="0.012388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="0.671875" stopColor="#D5E9DD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </Svg>
  );
}
