import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import ModalWindow from 'components/common/ModalPopup';
import PortalControl from 'components/common/PortalControl';
import Icon from 'components/common/Icon';
import { GlobalSearchStoreImpl } from './GlobalSearchTypes';
import { SearchCustomSelect } from 'components/form/SearchCustomSelect/SearchCustomSelect';
import { CartItemImpl } from 'root/store/cart/item.store';
import ImageItem from 'components/common/ImageItem';
import { getPathnameByCategories, joinClasses } from 'lib/utils';
import { MouseEvent } from 'react';

import './GlobalSearch.scss';

interface GlobalSearchProps extends RouteComponentProps {
  triggerComponent?: JSX.Element | undefined;
  store: GlobalSearchStoreImpl;
  history: any;
}

export const GlobalSearch = observer((props: GlobalSearchProps) => {
  const closeModal = (): void => {
    const { store } = props;
    store.visibleModal(false);
    store.resetSearchQ();
    store.updateResults([]);
  };

  const handleVisibleContact =
    (value: boolean) =>
    (event: React.SyntheticEvent): void => {
      event.preventDefault();
      const { store } = props;
      store.visibleModal(value);
    };

  const searchHandler = async (q: string) => {
    const { store } = props;
    if (q) {
      try {
        await store.setSearchQ(q);
      } catch (e) {}
    } else {
      store.updateResults([]);
    }
  };

  const handleRedirectToProductDetails =
    (cartItem: CartItemImpl) => (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      const productPath = getPathnameByCategories(cartItem.categories);
      const { history } = props;
      history.push(`${productPath}/${cartItem.id}`);
      closeModal();
    };

  return (
    <>
      {props.triggerComponent ? (
        <div onClick={handleVisibleContact(true)} className="contact-trigger">
          {props.triggerComponent}
        </div>
      ) : (
        <a
          className="common-link"
          href="#"
          onClick={handleVisibleContact(true)}
        >
          customer service
        </a>
      )}

      <PortalControl>
        <ModalWindow
          className={'modal-window-wrapper'}
          visible={props.store.visible}
        >
          <div
            className="btn btn_bordered-default btn_circle modal-window__btn-close"
            onClick={closeModal}
          >
            <Icon id="close" />
          </div>
          <div className="modal-window-header">
            <SearchCustomSelect
              title="Find product by sku"
              values={props.store.searchQ}
              changeHandler={searchHandler}
            />
          </div>
          {props.store.searchResults && (
            <div className="modal-window-body modal-window-scroll-area block-scrollbar">
              {Object.keys(props.store.searchResults).map((key: string) => (
                <div className={'category-block'} key={key}>
                  <div className="category-block-title">
                    <h2 className="diamond-sku bold">{key}</h2>
                    {props.store.searchResults[key].length > 1 && (
                      <div
                        onClick={() => {
                          props.store.changeLimitFilter(
                            key,
                            !props.store.limitFilters[key],
                          );
                        }}
                        className="custom-select-labelled"
                      >
                        {props.store.limitFilters[key] ? 'Hide ' : 'Show more '}{' '}
                        {' | '}
                        {props.store.searchResults[key].length} results
                      </div>
                    )}
                  </div>
                  <div className="category-block-value block-scrollbar">
                    {props.store.searchResults[key]
                      .slice(
                        0,
                        props.store.limitFilters[key]
                          ? props.store.searchResults[key].length
                          : 1,
                      )
                      .map((result: any, index: number) => (
                        <div
                          key={index}
                          className="modal-window__text modal-window__text_center modal-window__text_my-10"
                        >
                          <div className="cart-item__container cart-item__container_search">
                            <div className="cart-item__image">
                              <ImageItem
                                src={
                                  result.type === 'stone'
                                    ? require(`img/shapes/${result?.shape
                                        ?.split(' ')
                                        .join('_')
                                        ?.toLocaleLowerCase()}.png`).default
                                    : result.photo
                                }
                              />
                            </div>
                            <div className="cart-item__characteristics">
                              <div className="cart-item__characteristics-header">
                                <h2 className="diamond-sku bold">
                                  {result.sku}
                                </h2>
                              </div>
                              <ul className="diamond-characteristics diamond-characteristics_with-lines diamond-characteristics_small-font">
                                {Object.keys(result.description).map((key) => (
                                  <li key={key}>
                                    <span>{key}</span>
                                    <span>
                                      {result.description[key] || 'N/A'}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="cart-item__actions">
                              <div className="grid-area-actions icons-container">
                                <div
                                  className={joinClasses([
                                    'btn btn_bordered-default btn_circle btn_circle-xl',
                                  ])}
                                  onClick={handleRedirectToProductDetails(
                                    result,
                                  )}
                                >
                                  <Icon id="eye" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
              <div className="modal-window-form"></div>
            </div>
          )}
          <div className="modal-window-footer"></div>
        </ModalWindow>
      </PortalControl>
    </>
  );
});
