import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
import { ROUTER_PATHS } from 'root/routes/constants';
import { ProductCategories } from 'root/ProductTypes';
import { getImg } from 'lib/utils';
import ImageItem from 'components/common/ImageItem';
import { imagesPaths } from '../../constants';
import useElementViewed from '../../helpers/useElementViewed';

import './Design.scss';

interface IProps {
  setAnimatedIndex: (index: number) => void;
  animatedIndex: Record<number, boolean>;
}

export function Design({
  setAnimatedIndex,
  animatedIndex,
}: IProps): JSX.Element {
  const [animated, setAnimated] = useState(false);

  const handleAnimate = () => {
    if (!animated && !animatedIndex[5]) {
      setAnimated(true);
      gsap.fromTo(
        '.design__left',
        { x: -500, opacity: 0 },
        { x: 0, opacity: 1, duration: 2 },
      );
      gsap.fromTo(
        '.design__right',
        { x: 500, opacity: 0 },
        { x: 0, opacity: 1, duration: 2 },
      );
    }
    setAnimatedIndex(4);
  };

  const { elementRef } = useElementViewed({
    callback: handleAnimate,
    isActiveListener: true,
    threshold: 0.2,
  });

  return (
    <div className="main-slide  main-slide_design" ref={elementRef}>
      <div className="content-main_block" id="design">
        <div className="content-main_block__title">Lab-Grown Jewelry</div>
        <div className="content-main_block__description">
          Explore our fine selection of Lab-Grown Jewelry
        </div>
        <div className="content-main_block__design">
          <div className="row">
            <div className="design__left">
              <span className="design-text design-text__left">STUDS</span>
              <Link
                to={{
                  pathname: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
                  state: { categories: ProductCategories.EARRINGS },
                }}
              >
                <ImageItem
                  className="image-wrap"
                  src={getImg('mainPage', imagesPaths, `/design1`)}
                />
              </Link>
            </div>
            <div className="design__right">
              <Link
                to={{
                  pathname: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
                  state: { categories: ProductCategories.PENDANTS },
                }}
              >
                <ImageItem
                  className="image-wrap"
                  src={getImg('mainPage', imagesPaths, `/design2`)}
                />
              </Link>
              <span className="design-text design-text__right">PENDANT</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Design);
