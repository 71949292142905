import React from 'react';
import { getProductImage } from 'utils';

interface IProps {
  product: any;
  properties: { firstColumn: string[]; secondColumn: string[] };
}

const ProductRow = ({ product, properties }: IProps): null | JSX.Element => {
  if (!product) return null;

  const transformValue = (property: string) => {
    return (
      property[0].toUpperCase() +
      property
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(' ')
    );
  };

  return (
    <div className="orders-current-window__table">
      <div className="orders-current-window__table-row" key={product.id}>
        <img
          src={getProductImage(product)}
          className="orders-current-window__table-row-image"
        />
        <div className="orders-current-window__table-row-stone-details">
          <div className="orders-current-window__details-info-title">
            {product.sku}
          </div>
          <div className="orders-current-window__details-info">
            <div className="orders-current-window__details-info-column">
              {properties.firstColumn.map((property, index) => (
                <div
                  key={index}
                  className="orders-current-window__details-info-item"
                >
                  <span>{transformValue(property)}</span>
                  <span>{product[property] || 'N/A'} </span>
                </div>
              ))}
            </div>
            <div className="orders-current-window__details-info-column">
              {properties.secondColumn.map((property, index) => (
                <div
                  key={index}
                  className="orders-current-window__details-info-item"
                >
                  <span>{transformValue(property)}</span>
                  <span>{product[property] || 'N/A'}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="orders-current-window__table-row-total-block">
          <div className="orders-current-window__table-row-total-item">
            Price
            <span>$ {product._price}</span>
          </div>
          <div className="orders-current-window__table-row-total-item">
            Tax
            <span>$ {product.tax || 0}</span>
          </div>
          <div className="orders-current-window__table-row-total-item">
            Subtotal
            <span>$ {product.Subtotal || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductRow;
