import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  ORDERS_STATUSES,
  HISTORY_ORDERS_STATUSES,
  DATE_AVAILABLE_VALUES,
  SORT_VALUES,
  ORDER_CURRENT_TYPE,
  ORDER_HISTORY_TYPE,
} from 'root/store/orders/constants';
import rootStore from 'root/store';
import { joinClasses } from 'lib/utils';
import CustomSelect from 'components/form/CustomSelect';
import PaginationComponent from 'components/common/Pagination';
import LightLoader from 'components/common/Loaders/LightLoader';
import { QuantityItems } from 'components/common/QuanityItems';
import Icon from 'components/common/Icon';
import TableOrders from './TableOrders/TableOrders';
import { OrderModalPage } from './OrdersModal';

import './OrdersPage.scss';

const { orders } = rootStore;

const OrdersCurrent = observer(
  ({ match, location }: RouteComponentProps<{ type: string }>) => {
    const {
      setFilter,
      clearFilter,
      loadOrders,
      loading,
      ordersList: { list: ordersList },
      filter: { pagination, createDate, status, search, sortState },
    } = orders;

    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showModalById, setShowModalById] = useState<string | null>(null);
    const ordersType =
      match.params.type === ORDER_HISTORY_TYPE
        ? ORDER_HISTORY_TYPE
        : ORDER_CURRENT_TYPE;
    const ordersStatuses =
      match.params.type === ORDER_HISTORY_TYPE
        ? HISTORY_ORDERS_STATUSES
        : ORDERS_STATUSES;

    useEffect(() => {
      void loadOrders(ordersType);
    }, [ordersType]);

    const toggleModal = (hash?: string | null) => {
      if (!hash) return setShowModalById(null);
      const id = hash.replace('#', '');
      setShowModalById(id);
    };

    useEffect(() => {
      toggleModal(location.hash ? location.hash : null);
    }, [location.hash]);

    const setPage = (page: number) => {
      setFilter(ordersType, { page });
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
      setFilter(ordersType, { search: e.target.value });
    };

    const handleFilter = (name: string, filter: { value: string }) => {
      setFilter(ordersType, {
        [name]: name === 'sortBy' ? filter.value : filter,
      });
    };

    const handleLimit = (limit: number): void => {
      setFilter(ordersType, { limit });
    };

    return (
      <>
        <div className="orders-page">
          <div className="orders-page__content">
            <div className="orders-page__header">
              <div className="orders-page__title">
                {match.params.type === ORDER_CURRENT_TYPE
                  ? 'CURRENT ORDERS '
                  : 'ORDER HISTORY'}
              </div>
              <div
                className={joinClasses([
                  'orders-page__search-btn',
                  showSearch && 'orders-page__search-btn_active',
                ])}
                onClick={() => setShowSearch((prev) => !prev)}
              />
              <div
                className={joinClasses([
                  'orders-page__search',
                  showSearch && 'orders-page__search_active',
                ])}
              >
                <Icon id="search" />
                <input
                  value={search}
                  type="text"
                  className="orders-page__search-input"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>

              <div className="orders-page__filters">
                <div
                  className={joinClasses([
                    'orders-page__filters-selects-wrapper',
                    showMenu && 'orders-page__filters-selects-wrapper_active',
                  ])}
                >
                  <CustomSelect
                    defaultValue={status[0].value || ''}
                    name="status"
                    label="Status"
                    values={ordersStatuses}
                    onChange={handleFilter}
                    emptyValue="All"
                  />

                  <CustomSelect
                    defaultValue={createDate.value || ''}
                    name="createDate"
                    label="Date"
                    values={DATE_AVAILABLE_VALUES}
                    onChange={handleFilter}
                    emptyValue="All"
                  />

                  <CustomSelect
                    defaultValue={sortState.sortBy || ''}
                    name="sortBy"
                    label="Sort by"
                    values={SORT_VALUES}
                    onChange={handleFilter}
                    emptyValue="All"
                  />
                </div>

                <div
                  className="orders-page__clear-filters"
                  onClick={() => clearFilter(ordersType)}
                >
                  <Icon id="trash_light" className="icon_mr-xs" />
                  <span>Clear All</span>
                </div>
              </div>
            </div>

            {loading ? (
              <LightLoader />
            ) : (
              <>
                <div className="grid-area-table orders-page__count">
                  {`${pagination.total} Orders`}
                  {!!ordersList.length && (
                    <>
                      <QuantityItems
                        limit={pagination.limit}
                        handleLimit={handleLimit}
                        setPage={setPage}
                        quantityItems={[5, 10, 20]}
                      />
                      <div
                        className={joinClasses([
                          'orders-page__filters-menu',
                          showMenu && 'orders-page__filters-menu_active',
                        ])}
                        onClick={() => setShowMenu((prev) => !prev)}
                      />
                    </>
                  )}
                </div>
                {!!ordersList.length && (
                  <>
                    <TableOrders orders={ordersList} />
                    <div className="orders-page__table-pagination-wrapper">
                      {pagination.total > 5 && (
                        <QuantityItems
                          limit={pagination.limit}
                          handleLimit={handleLimit}
                          setPage={setPage}
                          quantityItems={[5, 10, 20]}
                        />
                      )}
                      {pagination.total > pagination.limit && (
                        <PaginationComponent
                          pagination={pagination}
                          handlePagination={setPage}
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {showModalById && !loading && (
          <OrderModalPage id={showModalById} close={toggleModal} />
        )}
      </>
    );
  },
);

export default withRouter(OrdersCurrent);
