import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
import engagementRingCatalog from '../../store';
import FilterExtra from '../../store/ringFiltersExtra.store';
import { Catalog as DefaultComponent } from './Catalog';

interface IRingsProps extends RouteComponentProps {
  historyReplace: (query: unknown) => void;
  location: H.Location<{ needToResetFilters?: boolean }>;
}

const filtersExtra = new FilterExtra();

export const Catalog = withRouter((props: IRingsProps) => (
  <DefaultComponent
    {...props}
    catalog={engagementRingCatalog}
    filtersExtra={filtersExtra}
  />
));
