import React from 'react';
import { observer } from 'mobx-react';
import { StoneDetailImpl } from 'root/store/stones/one.store';
import { RingDetailImpl } from 'root/store/rings/one.store';
import { IMAGES_FOLDER } from 'pages/Compare/constants';
import ImageItem from 'components/common/ImageItem';
import { AVAILABLE_SHAPE_IMAGES } from 'utils/constants/stones/diamonds.constants';
import Cost from '../Cost';
import InfoEngraving from '../InfoEngraving';
import InfoRing from '../InfoRing';
import InfoStone from '../InfoStone';
import { CartItemEngraveSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';
import { IProductCustomizations } from 'root/store/products/types/product.type';

interface IProps {
  productForSet: {
    parent: {
      product: RingDetailImpl;
      price: string;
    };
    child: {
      product: StoneDetailImpl;
      price: string;
    };
  };
  productCustomization?: IProductCustomizations;
}

const Table = ({ productForSet, productCustomization }: IProps) => {
  const { parent, child } = productForSet;
  return (
    <table className="add-set-to-cart-popup__table">
      <tbody>
        <tr className="add-set-to-cart-popup__item">
          <td>
            <div>
              <div className="add-set-to-cart-popup__image">
                <ImageItem src={parent.product.photos[0]} />
              </div>
            </div>
          </td>
          <td>
            <div>
              <div className="add-set-to-cart-popup__info">
                <div className="add-set-to-cart-popup__title">
                  {parent.product.sku}
                </div>
                <div className="add-set-to-cart-popup__info-item">
                  <InfoRing
                    ring={parent.product}
                    customSize={productCustomization?.size || null}
                  />
                </div>
                <InfoEngraving
                  engrave={
                    productCustomization?.engrave as unknown as CartItemEngraveSettings[]
                  }
                />
              </div>
            </div>
          </td>
          <td>
            <div>
              <Cost cost={parent.price} />
            </div>
          </td>
        </tr>
        <tr className="add-set-to-cart-popup__item">
          <td>
            <div>
              <div className="add-set-to-cart-popup__image">
                <ImageItem
                  imageFolder={IMAGES_FOLDER}
                  images={AVAILABLE_SHAPE_IMAGES}
                  currentImage={child.product.shape
                    .split(' ')
                    .join('_')
                    ?.toLocaleLowerCase()}
                  needStyle
                />
              </div>
            </div>
          </td>
          <td>
            <div>
              <div className="add-set-to-cart-popup__info">
                <div className="add-set-to-cart-popup__title">
                  {child.product.sku}
                </div>
                <InfoStone productStone={child.product} />
              </div>
            </div>
          </td>
          <td>
            <div>
              <Cost cost={child.price} />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default observer(Table);
