import React from 'react';
import Svg from '../Svg';

interface ICurrentOrdersIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CurrentOrdersIcon(
  props: ICurrentOrdersIconProps,
): JSX.Element {
  return (
    <Svg width="28" height="25" viewBox="0 0 28 25" fill="none" {...props}>
      <path
        d="M22.703 0H6.25c-.188 0-.375.14-.469.281l-4.734 7.64c-.094.188-.094.47.047.657L14.03 23.813a.576.576 0 0 0 .89 0L27.86 8.578c.141-.187.141-.469.047-.656L23.22.282C23.079.14 22.89 0 22.703 0Zm-.61 1.688 3.47 5.812h-3.891L19 1.687h3.094Zm-4.921 0L19.797 7.5H9.157l2.671-5.813h5.344Zm-10.313 0h3.094L7.281 7.5h-3.89l3.468-5.813ZM3.86 9h3.422l3.844 9-7.266-9Zm5.25 0h10.735L14.5 21.375 9.11 9Zm8.766 9 3.797-9h3.422l-7.219 9Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
