import TilesIcon from './TilesIcon';
import ListIcon from './ListIcon';
import TrashIcon from './TrashIcon';
import TrashLightIcon from './TrashLightIcon';
import FilterIcon from './FilterIcon';
import NoImgIcon from './NoImgIcon';

export default {
  tiles: TilesIcon,
  list: ListIcon,
  trash: TrashIcon,
  trash_light: TrashLightIcon,
  filter: FilterIcon,
  no_img: NoImgIcon,
};
