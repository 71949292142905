import React from 'react';
import Svg from '../../Svg';

interface IPrincessTableIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CurrentOrdersIcon(
  props: IPrincessTableIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M100 127.97 29.23 72.88l52.66 57.76"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100 116.74 29 72.76l71 37.67v29.19l-18.11-8.98L65 119.1l-19.89-17.32-16.47-29.09L48.1 60.72H100l-41.2 7.05v-6.7l-21.96 7.2L29 72.76l28.94-4.77 42.06-.14M32.91 74.95H100M30.3 72.76H100M100 127.97l70.77-55.09-52.66 57.76"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m100 116.74 71-43.98-71 37.67v29.19l18.11-8.98L135 119.1l19.89-17.32 16.46-29.09-19.45-11.97H100l41.2 7.05v-6.7l21.96 7.2 7.84 4.49-28.94-4.77-42.06-.14M167.09 74.95H100M169.7 72.76H100"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.64 72.76V40.05M171.36 72.76V40.05"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M28.64 40.05h142.72"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.99 60.72V24.11M151.88 60.72V24.11"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M47.99 24.11h103.89"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
