import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Range as RangeSlider } from 'rc-slider';
import { IObservableValue, observable, transaction } from 'mobx';
import Tooltip from 'react-tooltip-lite';
import { FilterRange } from 'root/store/products/filterRange.store';
import Icon from '../Icon';
import { joinClasses } from 'lib/utils';

interface RangeImpl {
  title: string;
  range: FilterRange;
  className?: string;
  tooltip?: boolean | string;
  label?: string;
  step?: number;
  min?: number;
  max?: number;
  dots?: boolean;
  type?: string;
  enableInputs?: boolean;
  marks?: { [key: string]: number };
  onAfterChange?: () => void;
}

@observer
export default class Range extends Component<RangeImpl> {
  tipOpen: IObservableValue<boolean> = observable.box(false);

  toggleTip = (): void => {
    this.tipOpen.set(!this.tipOpen.get());
  };

  handleMin = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const from = Number(event.target.value) || 0;

    transaction(() => {
      this.props.range.setFrom(from);

      typeof this.props.onAfterChange === 'function' &&
        this.props.onAfterChange();
    });
  };

  handleMax = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const to = Number(event.target.value) || 0;

    transaction(() => {
      this.props.range.setTo(to);

      typeof this.props.onAfterChange === 'function' &&
        this.props.onAfterChange();
    });
  };

  handleRange = (value = [0, 0]): void => {
    const [from, to] = value;

    transaction(() => {
      this.props.range.setRange(this.maskValues(from), this.maskValues(to));

      typeof this.props.onAfterChange === 'function' &&
        this.props.onAfterChange();
    });
  };

  maskValues = (value: number): number => {
    const { marks } = this.props;
    return marks && marks[value] ? marks[value] : value;
  };

  searchMarksKey = (value: string | number): number => {
    for (const key in this.props.marks) {
      if (Object.hasOwnProperty.call(this.props.marks, key)) {
        if (this.props.marks[key] === value) {
          return Number(key);
        }
      }
    }
    return 0;
  };

  render(): JSX.Element {
    const {
      title,
      className,
      tooltip = false,
      label,
      range,
      step = 1,
      min = 0,
      max = 999999,
      marks = {},
      dots = false,
      type = 'number',
      enableInputs = true,
    } = this.props;

    const rangeFromValue = range.from < min ? min : range.from;
    const rangeToValue = range.to > max ? max : range.to;

    const from = Object.keys(marks).length
      ? this.searchMarksKey(range.from)
      : rangeFromValue;
    const to = Object.keys(marks).length
      ? this.searchMarksKey(range.to)
      : rangeToValue;

    return (
      <div className={`filter filter_range ${className || ''}`}>
        <h4 className="filter-name">
          {title}
          {tooltip ? (
            <Tooltip
              content={tooltip}
              direction="up-start"
              className="custom-tooltip custom-tooltip_ml-15"
              tagName="span"
              onToggle={this.toggleTip}
            >
              <span
                className={joinClasses([
                  'tooltip-base',
                  this.tipOpen.get() && 'active-tooltip',
                ])}
              >
                <Icon id="info" />
              </span>
            </Tooltip>
          ) : null}
        </h4>
        <div className="filter-body">
          <RangeSlider
            allowCross={false}
            defaultValue={[Number(from), Number(to)]}
            min={min}
            max={max}
            value={[Number(from), Number(to)]}
            step={step}
            marks={marks}
            dots={dots}
            onChange={this.handleRange}
          />

          {enableInputs ? (
            <div className="filter-range-inputs-wrap">
              <span className="label label_left">{label}</span>
              <div className="filter-range-input-block">
                <div className="filter-range-input">
                  {/* TODO: hide labels on laptop ? */}
                  <span className="label_from-to">From</span>
                  <input
                    className="input"
                    type={type}
                    min={min}
                    max={max}
                    step={step}
                    value={rangeFromValue}
                    onChange={this.handleMin}
                  />
                </div>
              </div>
              <span className="dash">&mdash;</span>
              <div className="filter-range-input-block">
                <div className="filter-range-input">
                  <span className="label_from-to">To</span>
                  <input
                    className="input"
                    type={type}
                    min={min}
                    max={max}
                    step={step}
                    value={rangeToValue}
                    onChange={this.handleMax}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
