import { makeAutoObservable } from 'mobx';
import {
  FormControl,
  FormGroup,
  maxLengthValidator,
  minLengthValidator,
  patternValidator,
  requiredValidator,
  ValidationEventTypes,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';
import {
  ContactServiceStoreImpl,
  FormContactService,
  TFormValues,
} from './ContactTypes';

export class ContactStore implements ContactServiceStoreImpl {
  visible = false;
  disable = false;
  status = '';

  form: FormGroup<FormContactService>;

  constructor() {
    makeAutoObservable(this);
    this.form = ContactStore.initialForm();
  }

  static initialForm(): FormGroup<FormContactService> {
    return new FormGroup<FormContactService>({
      firstName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('FirstName is required'),
            patternValidator(
              /^[a-zA-Z]+$/,
              'First Name must contain only latin letters',
            ),
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars ',
              ValidationEventTypes.Error,
            ),
            minLengthValidator(1),
          ]),
        ],
      }),
      lastName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('LastName is required'),
            patternValidator(
              /^[a-zA-Z]+$/,
              'Last Name must contain only latin letters',
            ),
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars ',
              ValidationEventTypes.Error,
            ),
            minLengthValidator(1),
          ]),
        ],
      }),
      email: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Email is required'),
            patternValidator(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              'Wrong Email format',
            ),
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars ',
              ValidationEventTypes.Error,
            ),
            minLengthValidator(1),
          ]),
        ],
      }),
      phone: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Phone is required'),
            patternValidator(
              /^(?=.*[0-9])[- +()0-9]+$/,
              'Phone must contain only digits, +, -',
            ),
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars ',
              ValidationEventTypes.Error,
            ),
            minLengthValidator(1),
          ]),
        ],
      }),
      message: new FormControl<string>(''),
    });
  }

  get formValues(): TFormValues {
    const { firstName, lastName, email, phone, message } = this.form.controls;

    return {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    };
  }

  resetForm(): void {
    this.form = ContactStore.initialForm();
  }

  disableForm(isDisable: boolean): void {
    this.disable = isDisable;
  }

  visibleContact(visible: boolean): void {
    this.visible = visible;
  }

  changeStatus(status: string): void {
    this.status = status;
  }
}
