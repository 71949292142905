import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';
import { sendPromoCodeToEmail } from 'service/api/app';
import OfferPopupStore from '../../store';
import { InputFormControl } from '@quantumart/mobx-form-validation-kit';
import { OFFER_STATUSES } from './constants';

import './PromoOfferPopup.scss';

interface PromoOfferPopupProps {
  store: OfferPopupStore;
  visiblePopup: IObservableValue<boolean>;
}

export const PromoOfferPopup = observer((props: PromoOfferPopupProps) => {
  const { visiblePopup, store } = props;
  const [error, setError] = useState(null as null | string);

  const handleSubmit = async () => {
    store.form.setTouched(true);

    if (store.form.valid) {
      store.disableForm(true);
      // store.changeStatus('PENDING');
      try {
        await sendPromoCodeToEmail(store.form.controls.email.value);
        store.changeStatus(OFFER_STATUSES.SUCCESS_SENDING_STEP);
        store.disableForm(true);
        store.form.setTouched(false);
      } catch (e: any) {
        setError(e.message || 'Sending error');
        console.log(e.message, error);
        // store.changeStatus('FAILED');
        store.disableForm(false);
      }
    }
  };

  return (
    <PortalControl>
      <ModalWindow modalSize="mmd" visible={visiblePopup.get()}>
        <div
          className="btn btn_bordered-default_without_border btn_circle modal-window__btn-close"
          onClick={() => visiblePopup.set(false)}
        >
          <Icon id="close" />
        </div>

        <div className="modal-window-body modal-window-body-wrap">
          <div className="content-main_image"></div>
          <div className="content-main-block">
            {(() => {
              switch (store.status) {
                case OFFER_STATUSES.APPLYING_EMAIL_STEP:
                  return (
                    <>
                      <div className="content-main-block_title">
                        Get {store.giftPromoCodeDiscount} off your <br />
                        first order!
                      </div>
                      <div className="content-main-block_description">
                        Enter your email to receive this one-time promo code as
                        well as updates, news and, other promotions from Venori.
                        {store.giftPromoCodeMinPriceValue && (
                          <>
                            <br />
                            Please note that minimum order total must be{' '}
                            {store.giftPromoCodeMinPrice} for this promotion.
                            Can not be combined with any offer, sale or coupon.
                          </>
                        )}
                      </div>

                      <div className="content-main-block_action">
                        <div className="email-info-block">
                          <div className="grid-area-email">
                            <div className="email-block">
                              <input
                                className={`single-input ${
                                  store.form.controls.email.invalid &&
                                  store.form.controls.email.touched
                                    ? 'invalid'
                                    : 'valid'
                                } ${
                                  store.form.controls.email.value
                                    ? 'shrink'
                                    : ''
                                }`}
                                type="text"
                                name="email"
                                placeholder="Enter email"
                                value={store.form.controls.email.value}
                                {...InputFormControl.bindActions(
                                  store.form.controls.email,
                                )}
                              />
                              <div
                                onClick={handleSubmit}
                                className="btn btn_brown"
                              >
                                Apply
                              </div>
                            </div>
                            {error && (
                              <p className="attention-message attention-message_warning attention-message_off-stream attention-message_mt-10">
                                <span>{error}</span>
                              </p>
                            )}
                            {!error &&
                              store.form.controls.email.touched &&
                              store.form.controls.email.errors && (
                                <p className="attention-message attention-message_warning attention-message_off-stream attention-message_mt-10">
                                  {store.form.controls.email.errors.map(
                                    (error: any) => (
                                      <div key={error.message}>
                                        {error.message}
                                      </div>
                                    ),
                                  )}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                case OFFER_STATUSES.SUCCESS_SENDING_STEP:
                  return (
                    <>
                      <div className="content-main-block_title">Thank you!</div>
                      <div className="content-main-block_description">
                        Your promocode was sent to{' '}
                        {store.form.controls.email.value || ''} successfully.
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
        <div className="modal-window-footer" />
      </ModalWindow>
    </PortalControl>
  );
});
