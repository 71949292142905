import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { IObservableArray } from 'mobx';
import { CompareImpl } from 'root/store/compare';
import { IProduct } from 'root/store/products/types/product.type';
import { AVAILABLE_SHAPE_IMAGES } from 'utils/constants/stones/diamonds.constants';
import Icon from 'components/common/Icon';
import { TDiamondCatalog } from '../../types';
import qs from 'qs';

interface TableBodyProps extends RouteComponentProps {
  stones: IObservableArray<TDiamondCatalog>;
  compare: CompareImpl;
}

export const TableBody = withRouter(
  observer((props: TableBodyProps) => {
    const { stones, compare } = props;

    const handleClick =
      (id: number) =>
      (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        e.stopPropagation();
        const { pathname, search } = props.location;
        const { paramsForSet } = qs.parse(search, { parseArrays: true });
        const query = qs.stringify(
          { productForSet: true, paramsForSet },
          { arrayFormat: 'brackets' },
        );

        paramsForSet
          ? props.history.push(`${pathname}/${id}?${query}`)
          : props.history.push(`${pathname}/${id}`);
      };

    const handleOpenUrl =
      (url: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(url, '_blank');
      };

    const stopPropagation = (
      e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    ) => {
      e.stopPropagation();
    };

    const handleAddToCompare = (product: IProduct) => () => {
      compare.toggle(product.id);
    };

    return (
      <tbody>
        {stones &&
          (stones.length === 0 ? (
            <tr className="no-hover">
              <td colSpan={8}>
                <p className="attention-message">
                  Found no items matched to search parameters.
                </p>
              </td>
            </tr>
          ) : (
            stones.map((stone, index) => {
              const iconModifier = AVAILABLE_SHAPE_IMAGES.includes(
                stone.shape.toLowerCase(),
              )
                ? stone.shape.toLowerCase()
                : 'noshape';

              return (
                <tr key={index} onClick={handleClick(stone.id)}>
                  <td>
                    <label className="checkbox-container">
                      <input
                        checked={compare.exist(stone.id)}
                        type="checkbox"
                        name={`compare_${stone.id}`}
                        onClick={stopPropagation}
                        onChange={handleAddToCompare(stone)}
                      />
                      <span
                        className="checkmark"
                        onClick={stopPropagation}
                      ></span>
                    </label>
                  </td>
                  <td>
                    <div className="stone-container__shape-block">
                      <Icon id={iconModifier} className="icon_mr-xs" />
                      <span>{stone.shape}</span>
                    </div>
                  </td>
                  <td>{stone.carat}</td>
                  <td>{stone.color}</td>
                  <td>{stone.cut}</td>
                  <td>{stone.clarity}</td>
                  <td>
                    <a
                      href="#"
                      className="certificate-link"
                      onClick={handleOpenUrl(stone.certificate)}
                    >
                      {stone.gradingLab}
                    </a>
                  </td>
                  <td>
                    {stone.photos && (
                      <Icon id="image_gallery" className="icon_mr-xs" />
                    )}
                    {stone.hasLoupe && <Icon id="image_360" />}
                  </td>
                  <td>{stone.fluorescence}</td>
                  <td>{stone.price}</td>
                </tr>
              );
            })
          ))}
      </tbody>
    );
  }),
);
