import React from 'react';
import Svg from '../Svg';

interface INoImgIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function NoImgIcon(props: INoImgIconProps): JSX.Element {
  return (
    <Svg width="251" height="247" viewBox="0 0 251 247" fill="none" {...props}>
      <path
        d="M69.892 227.264v12.508l-10.094-12.508H57v18.472h3.373v-12.508l10.095 12.508h2.798v-18.472h-3.374ZM86.722 246c5.675 0 9.859-4.011 9.859-9.5s-4.184-9.5-9.859-9.5-9.859 4.037-9.859 9.5 4.184 9.5 9.86 9.5Zm0-3.008c-3.687 0-6.433-2.718-6.433-6.492s2.746-6.492 6.433-6.492 6.433 2.718 6.433 6.492-2.746 6.492-6.433 6.492ZM107.412 245.736h3.399v-18.472h-3.399v18.472ZM135.794 245.736l-.026-18.472h-2.798l-7.14 12.244-7.269-12.244h-2.799v18.472h3.243V233.65l6.015 9.975h1.517l6.014-10.133.026 12.244h3.217ZM154.441 245.736h3.557l-8.264-18.472h-3.347l-8.238 18.472h3.505l1.804-4.275h9.153l1.83 4.275Zm-9.832-6.967 3.425-8.075 3.452 8.075h-6.877ZM172.571 242.042c-1.177.686-2.459.95-3.766.95-3.87 0-6.616-2.745-6.616-6.492 0-3.826 2.746-6.492 6.642-6.492 1.961 0 3.609.66 5.047 2.111l2.144-2.111c-1.726-1.979-4.288-3.008-7.348-3.008-5.727 0-9.911 3.985-9.911 9.5 0 5.515 4.184 9.5 9.859 9.5 2.589 0 5.256-.792 7.165-2.349v-7.362h-3.216v5.753ZM183.67 242.86v-5.093h8.839v-2.824h-8.839v-4.803h9.964v-2.876h-13.363v18.472H194v-2.876h-10.33ZM70.885 0a29.553 29.553 0 0 1 11.687 2.404c1.811.781 3.605 1.597 5.323 2.541a55.626 55.626 0 0 1 16.539 14.233 56.066 56.066 0 0 1 9.838 19.542c4.846 15.488 8.337 31.392 12.437 47.112 2.928 11.213 5.787 22.438 8.95 33.586.469 1.661 2.246 3.975 3.61 4.113a6.977 6.977 0 0 0 2.723-.966 7.031 7.031 0 0 0 2.106-1.988c1.926-2.511 3.251-5.477 5.502-8.006L126.687 176c-.605-.567-1.346-.949-1.589-1.537a719.183 719.183 0 0 1-27.153-76.495c-5.54-19.077-11.081-38.205-17.9-56.853-6.128-15.969-17.789-29.163-32.826-37.144C44.14 2.176 38.269.537 37.842 0h33.043Z"
        fill="#D6D1D0"
      />
      <path
        d="M28.981 3.534c13.455 3.13 18.544 10.354 25.189 19.317a234.424 234.424 0 0 1 11.908 17.686A83.4 83.4 0 0 1 35.89 28.908c-6.205-4.8-12.1-9.993-17.649-15.548A158.182 158.182 0 0 0 0 1.263c9.706-.044 19.4.715 28.981 2.271ZM161.033 0c25.955.159 19.75 16.926 18.961 20-1.168 4.541-13.28 46.515-13.28 46.515s16.622-42.3 18.066-45.932c1.445-3.631 6.521-20.978 25.657-20.493L211 0h-49.967Z"
        fill="#D6D1D0"
      />
    </Svg>
  );
}
