import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import { ADVANTAGES } from '../constants';
import * as React from 'react';
import { AdvantagesStoreI } from '../types';

class AdvantagesStore implements AdvantagesStoreI {
  advantages: IObservableArray = observable(ADVANTAGES);

  constructor() {
    makeAutoObservable(this);
  }

  openInfo({ target }: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const { textContent } = target as HTMLElement;

    const advantage = this.advantages.find((a) => a.title === textContent);
    this.advantages.forEach((a) => {
      if (a.title !== textContent) {
        a.isOpen = false;
      }
    });
    if (advantage) {
      advantage.isOpen = !advantage.isOpen;
    }
  }
}

export default new AdvantagesStore();
