import React from 'react';
import Svg from '../../Svg';

interface IPrincessIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PrincessIcon(props: IPrincessIconProps): JSX.Element {
  return (
    <Svg width="20" height="20" viewBox="0 0 410 410" fill="none" {...props}>
      <path
        d="M401.18 6.32H8.82a2.5 2.5 0 0 0-2.5 2.5v392.36a2.5 2.5 0 0 0 2.5 2.5h392.36a2.5 2.5 0 0 0 2.5-2.5V8.82a2.5 2.5 0 0 0-2.5-2.5zM13.32 396.67l42.82-106.82h63.4v63.91L13.32 396.67zm275.4-111.24-82.77 33.43-81.98-33.43-33.26-81.8 33.25-82.95 81.98-32.29 82.77 32.29 32.46 82.95-32.45 81.8zm35.13-74.98 29.12 74.39h-58.64l29.52-74.39zm-205.52 74.4H58.15l29.88-74.53 30.3 74.53zM88 196.96l-30.78-75.71h61.13L88 196.96zm206.31-75.71h59.56l-29.99 75.57-29.57-75.57zm-6.16-6.16L212.79 85.7l75.35-29.68v59.07zm-82.21-32.07-80.9-31.56h161.02l-80.12 31.56zm-6.85 2.7-74.54 29.36V56.64l74.54 29.08zm-79.54 30.53H55.19L13.32 13.26 119.55 54.7v61.55zm-34.23 87.39-30.61 76.34V128.36l30.61 75.28zm39.23 87.43 74.75 30.48-74.75 30.19v-60.67zm81.39 33.19 74.47 30.37H130.75l75.19-30.37zm6.65-2.69 75.56-30.52v61.33l-75.56-30.81zm80.56-31.72h61.78l41.81 106.82-103.58-42.24v-64.58zm33.41-86.21 31.42-79.18v159.48l-31.42-80.3zm29.3-87.39h-62.71v-62.2l103.58-40.79-40.87 102.99zm-62.74-67.56a2.49 2.49 0 0 0-2.47-2.22h-168.6a2.5 2.5 0 0 0-2.5 2.5v.36L22.11 11.32h365.91l-94.9 37.37zM50.21 117.27c-.31.41-.49.92-.49 1.48v168.6c0 .9.48 1.68 1.2 2.12l-39.59 98.76V21.61l38.88 95.66zm70.05 241.6c.45.47 1.09.76 1.79.76h168.6c.44 0 .85-.13 1.22-.33l96.57 39.38H21.68l98.58-39.81zm240.03-69.02h.19a2.5 2.5 0 0 0 2.5-2.5v-168.6c0-1.13-.76-2.08-1.79-2.38l37.49-94.47v366.04l-38.39-98.09z"
        fill="#4d4048"
      />
    </Svg>
  );
}
