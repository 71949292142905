import React from 'react';
import { observer } from 'mobx-react';
import { CountryImpl, StateImpl } from 'root/store/app';
import CustomSelect from 'components/form/CustomSelect';
import { joinClasses } from 'lib/utils';
import {
  CustomerImpl,
  TProfileFormName,
} from 'root/store/account/accountTypes';
import InputForm from 'components/common/InputForm';
import {
  FormControl,
  FormGroup,
  InputFormControl,
} from '@quantumart/mobx-form-validation-kit';

const FORM_CUSTOMER = 'customer';

interface ICustomerForm {
  customer: FormGroup<CustomerImpl>;
  countries: CountryImpl[];
  isEdit: boolean;
  getCurrentCountry: (country: FormControl<number>) => CountryImpl | undefined;
  showErrors: (data: FormControl) => boolean;
  onChangeSelect: (
    formName: TProfileFormName,
    name: string,
    value: CountryImpl | StateImpl,
  ) => void;
}

const CustomerForm = (props: ICustomerForm) => {
  const {
    customer,
    countries,
    isEdit,
    getCurrentCountry,
    onChangeSelect,
    showErrors,
  } = props;
  const {
    firstName,
    lastName,
    email,
    password,
    passwordConfirm,
    phone,
    country,
    state,
    city,
    street,
    apt,
  } = customer.controls;
  const currentCountry = getCurrentCountry(country);
  const states = (currentCountry && currentCountry.states) || [];

  const handleChangeSelect = (
    name: TProfileFormName,
    value: CountryImpl | StateImpl,
  ) => {
    onChangeSelect(FORM_CUSTOMER, name, value);
  };

  return (
    <form className="form" id={FORM_CUSTOMER} noValidate>
      <h3 className="form-title">Personal Information</h3>

      <InputForm
        name="firstName"
        label="First Name *"
        dataForm={FORM_CUSTOMER}
        className={joinClasses([
          'autofill-bg-beige',
          firstName.value && 'shrink',
        ])}
        data={firstName}
        showErrors={showErrors(firstName)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(firstName)}
      />

      <InputForm
        name="lastName"
        label="Last Name *"
        dataForm={FORM_CUSTOMER}
        className={joinClasses([
          'autofill-bg-beige',
          lastName.value && 'shrink',
        ])}
        data={lastName}
        showErrors={showErrors(lastName)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(lastName)}
      />

      <InputForm
        type="email"
        name="email"
        label="E-mail *"
        dataForm={FORM_CUSTOMER}
        className={joinClasses(['autofill-bg-beige', email.value && 'shrink'])}
        data={email}
        showErrors={showErrors(email)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(email)}
      />

      <InputForm
        name="phone"
        label="Phone *"
        dataForm={FORM_CUSTOMER}
        className={joinClasses(['autofill-bg-beige', phone.value && 'shrink'])}
        data={phone}
        showErrors={showErrors(phone)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(phone)}
      />

      <div>
        <CustomSelect
          name="country"
          label="Select your country *"
          form={FORM_CUSTOMER}
          values={countries}
          defaultValue={country.value}
          onChange={handleChangeSelect}
          disabled={!isEdit}
        />
        {country.invalid && country.touched
          ? country.errors.map((error) => (
              <div
                className="form-label__error form-label__error_blocky"
                key={error.key}
              >
                {error.message}
              </div>
            ))
          : null}
      </div>

      {!!states.length && (
        <div>
          <CustomSelect
            name="state"
            label="Select your state *"
            form={FORM_CUSTOMER}
            values={countries}
            defaultValue={state && state.value}
            onChange={handleChangeSelect}
            disabled={!isEdit}
          />
          {state.invalid && state.touched
            ? state.errors.map((error) => (
                <div
                  className="form-label__error form-label__error_blocky"
                  key={error.key}
                >
                  {error.message}
                </div>
              ))
            : null}
        </div>
      )}

      <InputForm
        name="city"
        label="City *"
        dataForm={FORM_CUSTOMER}
        className={joinClasses(['autofill-bg-beige', city.value && 'shrink'])}
        data={city}
        showErrors={showErrors(city)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(city)}
      />

      <InputForm
        name="street"
        label="Street Address *"
        dataForm={FORM_CUSTOMER}
        className={joinClasses(['autofill-bg-beige', street.value && 'shrink'])}
        data={street}
        showErrors={showErrors(street)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(street)}
      />

      <InputForm
        name="apt"
        label="Apt/Unit"
        dataForm={FORM_CUSTOMER}
        className={joinClasses(['autofill-bg-beige', apt.value && 'shrink'])}
        data={apt}
        showErrors={!!apt.value && showErrors(apt)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(apt)}
      />

      <InputForm
        name="password"
        label="Password"
        dataForm={FORM_CUSTOMER}
        className={joinClasses([
          'autofill-bg-beige',
          password.value && 'shrink',
        ])}
        data={password}
        showErrors={!!password.value && showErrors(password)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(password)}
      />

      <InputForm
        name="passwordConfirm"
        label={`Confirm Password ${password.value ? '*' : ''}`}
        dataForm={FORM_CUSTOMER}
        className={joinClasses([
          'autofill-bg-beige',
          passwordConfirm.value && 'shrink',
        ])}
        data={passwordConfirm}
        showErrors={!!passwordConfirm.value && showErrors(passwordConfirm)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(passwordConfirm)}
      />
    </form>
  );
};

export default observer(CustomerForm);
