import React from 'react';
import Icon from 'components/common/Icon';
import { Contact } from '../../Contact';
import { PHONE_NUMBER } from '../constants';

const Contacts = (): JSX.Element => {
  return (
    <div className="footer__column-content">
      <label className="footer__column-title contacts-title">Contact Us</label>
      <div className="footer__column-content-container">
        <a className="footer__column-items" href={`tel:${PHONE_NUMBER}`}>
          <Icon id="phone" />
          Call Us {PHONE_NUMBER}
        </a>
        {false && (
          <label className="footer__column-items">
            <Icon id="chat" />
            Live chat
          </label>
        )}
        <Contact
          triggerComponent={
            <label className="footer__column-items">
              <Icon id="email" />
              Email us
            </label>
          }
        />
      </div>
    </div>
  );
};

export default Contacts;
