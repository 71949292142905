import React from 'react';
import Svg from '../Svg';

interface IFilterIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function FilterIcon(props: IFilterIconProps): JSX.Element {
  return (
    <Svg width="20" height="19" viewBox="0 0 20 19" fill="none" {...props}>
      <path
        d="M18.1211 0.25H1.84375C1.07031 0.25 0.683594 1.16406 1.24609 1.69141L7.75 8.19531V15.4375C7.75 15.7188 7.85547 16 8.10156 16.1406L10.9141 18.1094C11.4766 18.4961 12.25 18.1094 12.25 17.4062V8.19531L18.7188 1.69141C19.2812 1.16406 18.8945 0.25 18.1211 0.25Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
