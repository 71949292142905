import React from 'react';
import { observer } from 'mobx-react-lite';
import { StoneDetailImpl } from 'root/store/stones/one.store';
import { RingDetailImpl } from 'root/store/rings/one.store';
import { IProductCustomizations } from 'root/store/products/types/product.type';
import { CartItemEngraveSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';
import { IMAGES_FOLDER } from 'pages/Compare/constants';
import { AVAILABLE_SHAPE_IMAGES } from 'utils/constants/stones/diamonds.constants';
import ImageItem from 'components/common/ImageItem';
import Cost from '../Cost';
import InfoEngraving from '../InfoEngraving';
import InfoRing from '../InfoRing';
import InfoStone from '../InfoStone';

import './BlockMobile.scss';

interface IProps {
  productForSet: {
    parent: {
      product: RingDetailImpl;
      price: string;
    };
    child: {
      product: StoneDetailImpl;
      price: string;
    };
  };
  productCustomization?: IProductCustomizations;
}

export const BlockMobile = ({
  productForSet,
  productCustomization,
}: IProps): JSX.Element | null => {
  const { parent, child } = productForSet;

  return (
    <div className="add-set-to-cart-popup__mobile-block">
      <div className="add-set-to-cart-popup__mobile-block__item">
        <div className="add-set-to-cart-popup__title">{parent.product.sku}</div>
        <div className="add-set-to-cart-popup__mobile-block__image-cost">
          <div className="add-set-to-cart-popup__image">
            <ImageItem src={parent.product.photos[0]} />
          </div>
          <Cost cost={parent.price} isMobile />
        </div>
        <div>
          <div className="add-set-to-cart-popup__info-item">
            <InfoRing
              customSize={productCustomization?.size || null}
              ring={parent.product}
            />
          </div>
          <InfoEngraving
            engrave={
              productCustomization?.engrave as unknown as CartItemEngraveSettings[]
            }
          />
        </div>
      </div>
      <div className="add-set-to-cart-popup__mobile-block__item">
        <div className="add-set-to-cart-popup__title">{child.product.sku}</div>
        <div className="add-set-to-cart-popup__mobile-block__image-cost">
          <div className="add-set-to-cart-popup__image">
            <ImageItem
              imageFolder={IMAGES_FOLDER}
              images={AVAILABLE_SHAPE_IMAGES}
              currentImage={child.product.shape
                .split(' ')
                .join('_')
                ?.toLocaleLowerCase()}
              needStyle
            />
          </div>
          <Cost cost={child.price} isMobile />
        </div>
        <div>
          <InfoStone productStone={child.product} />
        </div>
      </div>
    </div>
  );
};

export default observer(BlockMobile);
