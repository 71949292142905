import React from 'react';
import Svg from '../Svg';

interface InstagramIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function InstagramIcon(props: InstagramIconProps): JSX.Element {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M9.99832 4.87225C7.16062 4.87225 4.87172 7.16166 4.87172 10C4.87172 12.8383 7.16062 15.1277 9.99832 15.1277C12.836 15.1277 15.1249 12.8383 15.1249 10C15.1249 7.16166 12.836 4.87225 9.99832 4.87225ZM9.99832 13.3337C8.16453 13.3337 6.66536 11.8387 6.66536 10C6.66536 8.16133 8.16006 6.66629 9.99832 6.66629C11.8366 6.66629 13.3313 8.16133 13.3313 10C13.3313 11.8387 11.8321 13.3337 9.99832 13.3337V13.3337ZM16.5304 4.6625C16.5304 5.32746 15.995 5.85853 15.3346 5.85853C14.6698 5.85853 14.1389 5.32299 14.1389 4.6625C14.1389 4.00201 14.6743 3.46647 15.3346 3.46647C15.995 3.46647 16.5304 4.00201 16.5304 4.6625ZM19.9258 5.87638C19.85 4.27424 19.4841 2.85507 18.3107 1.68582C17.1417 0.516568 15.7228 0.150619 14.121 0.070289C12.4702 -0.0234297 7.52203 -0.0234297 5.87116 0.070289C4.27384 0.146156 2.85499 0.512105 1.68154 1.68136C0.508085 2.85061 0.146679 4.26978 0.066367 5.87192C-0.0273307 7.52315 -0.0273307 12.4724 0.066367 14.1236C0.142218 15.7258 0.508085 17.1449 1.68154 18.3142C2.85499 19.4834 4.26938 19.8494 5.87116 19.9297C7.52203 20.0234 12.4702 20.0234 14.121 19.9297C15.7228 19.8538 17.1417 19.4879 18.3107 18.3142C19.4796 17.1449 19.8455 15.7258 19.9258 14.1236C20.0195 12.4724 20.0195 7.52761 19.9258 5.87638V5.87638ZM17.7931 15.8953C17.4451 16.7701 16.7713 17.4439 15.8924 17.7965C14.5761 18.3186 11.4529 18.1981 9.99832 18.1981C8.54378 18.1981 5.41606 18.3142 4.10429 17.7965C3.22978 17.4484 2.55605 16.7745 2.20357 15.8953C1.68154 14.5788 1.80201 11.4549 1.80201 10C1.80201 8.54513 1.686 5.41671 2.20357 4.10465C2.55159 3.22995 3.22532 2.55606 4.10429 2.2035C5.42052 1.68136 8.54378 1.80185 9.99832 1.80185C11.4529 1.80185 14.5806 1.68582 15.8924 2.2035C16.7669 2.5516 17.4406 3.22548 17.7931 4.10465C18.3151 5.42118 18.1946 8.54513 18.1946 10C18.1946 11.4549 18.3151 14.5833 17.7931 15.8953Z" />
    </Svg>
  );
}
