import React from 'react';
import Svg from '../../Svg';

interface IOvalDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function OvalDepthIcon(props: IOvalDepthIconProps): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M100.67 143.56s57.72-32.87 75.07-53.55v-5.34l-22-20H48l-22 20V90c17.35 20.68 74.74 53.55 74.74 53.55l.33-53.72"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m128.7 89.51-28.03 54.05 51.38-54.05-22.87 36.51 36.22-36.51-4.34 14.19"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m72.64 89.51 28.03 54.05-51.38-54.05 22.78 36.36-36.13-36.36 4.67 14.01"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.08 89.67c1.69 0 2.47-1.25 4.86-1.16 1.77.06 3 1 5 1s4.21-1.33 6.58-1.44c2.7-.13 4.1 1.55 6.77 1.44 3.53-.15 9.27-1.64 12.68-1.64 4.25 0 7.24 1.76 10.67 1.64 3.43-.12 9.68-1 14.35-1s12 1.25 14 1.22M26.08 84.52c1.69 0 2.47 1.24 4.86 1.16 1.77-.07 3-1 5-1s4.21 1.32 6.58 1.43c2.7.13 4.1-1.55 6.77-1.43 3.53.15 9.27 1.64 12.68 1.64 4.25 0 7.24-1.77 10.67-1.64 3.43.13 9.68 1 14.35 1s11.05-1.23 14-1.23c2.95 0 9 1.23 13.68 1.23s10.92-.88 14.35-1c3.43-.12 6.43 1.64 10.67 1.64 3.41 0 9.15-1.49 12.68-1.64 2.67-.12 4.07 1.56 6.77 1.43 2.37-.11 4.58-1.43 6.58-1.43s3.23.93 5 1c2.39.08 3.17-1.16 4.86-1.16"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.83 89.67c-1.69 0-2.47-1.25-4.86-1.16-1.77.06-3 1-5 1s-4.21-1.33-6.58-1.44c-2.7-.13-4.1 1.55-6.77 1.44-3.53-.15-9.27-1.64-12.68-1.64-4.24 0-7.24 1.76-10.67 1.64-3.43-.12-9.68-1-14.35-1s-12 1.25-14 1.22M101 64.65l-20.17 5.99-12.52-5.99-25.1 4.32-7.27 15.71"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m100.5 64.65 20.17 5.99 12.53-5.99 25.1 4.32 7.26 15.71M43.21 68.97l7.37 15.71 30.25-14.04-7.1 13.81"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m158.3 68.97-7.37 15.71-30.26-14.04 7.1 13.81M80.83 70.64l20.3 13.65 19.54-13.65"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.65 62.7h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.11 62.7h75.58"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.96 2.96"
      />
      <path
        d="M182.18 62.7h1.5M100.65 143.56h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.11 143.56h75.58"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.96 2.96"
      />
      <path
        d="M182.18 143.56h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.68 62.7v80.86"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
