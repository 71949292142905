import { makeAutoObservable } from 'mobx';
import CarouselStore, { CarouselStoreImpl } from 'root/store/carousel';
import { ProductItemsImpl } from '../types';
import { CAROUSEL_ACTIVE_ITEM } from '../constants';

export interface IMainStore {
  isPopular: boolean;
  carousel: CarouselStoreImpl;
  setPopular: (isPopular: boolean) => void;
  setAnimation: (isAnimation: boolean) => void;
  updateCarouselItems: (productItems: Array<ProductItemsImpl>) => void;
  isNewAnimation: boolean;
}

class MainPageStore implements IMainStore {
  isPopular = true;
  isNewAnimation = false;
  carousel: CarouselStoreImpl;

  constructor() {
    makeAutoObservable(this);
    this.carousel = new CarouselStore(CAROUSEL_ACTIVE_ITEM);
  }

  updateCarouselItems = (productItems: Array<ProductItemsImpl>) => {
    const products = this.isPopular
      ? productItems.filter((item) => item.popular).slice()
      : productItems;
    this.carousel.update(products);
  };

  setPopular = (isPopular: boolean) => {
    this.isPopular = isPopular;
  };

  setAnimation = (isAnimation: boolean) => {
    this.isNewAnimation = isAnimation;
  };
}

export default new MainPageStore();
