import React, { ReactNode } from 'react';

const AccordionItem: React.FunctionComponent<{
  id: number;
  title?: string;
  children: ReactNode;
}> = ({ id, title, children }) => {
  return (
    <li className="accordion-item">
      <input type="checkbox" id={`checkbox${id}`} />
      <label className="accordion-item__label" htmlFor={`checkbox${id}`}>
        {title}
      </label>
      <div className="accordion-item__content">{children}</div>
    </li>
  );
};

export default AccordionItem;
