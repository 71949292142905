import UserIcon from './UserIcon';
import UserActiveIcon from './UserActiveIcon';
import UploadIcon from './UploadIcon';
import EmailIcon from './EmailIcon';
import TwitterIcon from './TwitterIcon';
import InstagramIcon from './InstagramIcon';
import FacebookIcon from './FacebookIcon';
import PinterestIcon from './PinterestIcon';
import CurrentOrdersIcon from './CurrentOrdersIcon';
import HistoryIcon from './HistoryIcon';
import FavoriteIcon from './FavoriteIcon';
import FavoriteSolidIcon from './FavoriteSolidIcon';
import SettingsIcon from './SettingsIcon';
import SignOutIcon from './SignOutIcon';
import EyeIcon from './EyeIcon';
import EyeLightIcon from './EyeLightIcon';
import EmailLightIcon from './EmailLightIcon';

export default {
  user: UserIcon,
  user_active: UserActiveIcon,
  upload: UploadIcon,
  email: EmailIcon,
  email_light: EmailLightIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  pinterest: PinterestIcon,
  current_orders: CurrentOrdersIcon,
  history: HistoryIcon,
  favorite: FavoriteIcon,
  favorite_solid: FavoriteSolidIcon,
  settings: SettingsIcon,
  sign_out: SignOutIcon,
  eye: EyeIcon,
  eye_light: EyeLightIcon,
};
