import React from 'react';
import { observer } from 'mobx-react';
import { CartItemEngraveSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';

interface IEngraveBlockDetailsProps {
  engraveSettings?: CartItemEngraveSettings;
}

export function EngraveBlockDetails({
  engraveSettings,
}: IEngraveBlockDetailsProps): JSX.Element {
  const { font, text, color } = engraveSettings || {};
  return (
    <ul className="product-detail-page__details-list product-detail-page__details-list_wrap">
      {font && (
        <li>
          <span>Engraving type</span> <span>{font.name}</span>
        </li>
      )}
      {text && (
        <li>
          <span>Message</span> <span>{text}</span>
        </li>
      )}
      {color && (
        <li>
          <span>Fill color</span> <span>{color.name}</span>
        </li>
      )}
    </ul>
  );
}

export default observer(EngraveBlockDetails);
