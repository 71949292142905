import React from 'react';
import { observer } from 'mobx-react-lite';
import { joinClasses } from 'lib/utils';

import './EngraveColorCircle.scss';

interface IProps {
  color: string;
  modifiers?: string;
}

export function EngraveColorCircle({ color, modifiers }: IProps): JSX.Element {
  return (
    <span
      style={{
        background: color === 'No Color' ? 'transparent' : color.toLowerCase(),
      }}
      className={joinClasses(['engrave-color-circle', modifiers])}
    />
  );
}

export default observer(EngraveColorCircle);
