import React from 'react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';

import './ScrollUpButton.scss';

@observer
class ScrollUpButton extends React.Component {
  @observable isShown = false;

  componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (): void => {
    runInAction(
      () =>
        (this.isShown =
          document.documentElement.scrollTop > 300 ||
          document.body.scrollTop > 300),
    );
  };

  scrollUp = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  render(): JSX.Element {
    return (
      <button
        className={`btn btn_bordered-brown btn_circle scroll-up-button ${
          this.isShown ? '' : 'scroll-up-button_hide'
        }`}
        onClick={this.scrollUp}
      >
        <i className="icon icon_arrow-up-light"></i>
      </button>
    );
  }
}

export default ScrollUpButton;
