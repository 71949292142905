import * as React from 'react';
import { makeAutoObservable, observable } from 'mobx';

export default class HeaderStore {
  isStickyHeaderActive = observable.box(false);
  stickyHeaderRef: React.RefObject<HTMLElement> | null = null;
  className: string[] = [];
  isMobileMenuOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  setStickyHeaderRef(value: React.RefObject<HTMLElement> | null): void {
    this.stickyHeaderRef = value;
  }

  addClass(className: string): void {
    this.className = [...this.className, className];
  }

  removeClass(currentClassName: string): void {
    this.className = this.className.filter(
      (className) => className !== currentClassName,
    );
  }

  toggleMobileMenuOpen(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  closeMobileMenu(): void {
    this.isMobileMenuOpen = false;
  }
}
