import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import ProfilePage from 'pages/AccountPage/components/Profile';
import OrdersPage from 'pages/AccountPage/components/OrdersPage';
import FavoritePage from 'pages/AccountPage/components/FavoritePage';
import ProfileSettingsPage from 'pages/AccountPage/components/ProfileSettings';
import { Sidebar } from 'components/app/Sidebar';

import './AccountPage.scss';

const AccountPage = (): JSX.Element => {
  return (
    <section className="root-account">
      <Sidebar />
      <Switch>
        <Route path="/account/profile" component={ProfilePage} />
        <Route path="/account/settings" component={ProfileSettingsPage} />
        <Route path="/account/orders/:type" component={OrdersPage} />
        <Route path="/account/favorites" component={FavoritePage} />
        <Route path="/" render={() => <Redirect to="/account/profile" />} />
      </Switch>
    </section>
  );
};

export default AccountPage;
