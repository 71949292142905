import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function DiamondSize250(props: IProps): JSX.Element {
  return (
    <Svg width="69" height="68" viewBox="0 0 69 68" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.527 1.207C16.417 1.207 1.734 15.89 1.734 34c0 18.111 14.682 32.793 32.793 32.793C52.638 66.793 67.32 52.11 67.32 34c0-18.111-14.682-32.793-32.793-32.793ZM.527 34c0-18.778 15.223-34 34-34 18.778 0 34 15.222 34 34s-15.222 34-34 34c-18.777 0-34-15.222-34-34Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.818.28a.402.402 0 0 1 .045.567L25.76 11.5l8.958 3.907a.402.402 0 0 1-.016.744l-23.068 8.927L1.43 34.282a.402.402 0 1 1-.54-.598L11.15 24.43a.404.404 0 0 1 .125-.076l22.224-8.6-8.559-3.734a.403.403 0 0 1-.145-.63L34.251.325a.402.402 0 0 1 .567-.045Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.951 2.62c.21-.073.44.038.512.248l3.018 8.65c.028.082.03.17.005.251l-2.716 8.853a.318.318 0 0 1-.01.03l-5.332 13.48a.402.402 0 0 1-.718.06l-5.567-9.144-7.627-3.269a.402.402 0 0 1 .317-.74l7.746 3.32c.077.033.141.09.185.16l5.213 8.565 5.028-12.713 2.673-8.712-2.974-8.526a.402.402 0 0 1 .247-.513Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.05 9.743a.403.403 0 0 1 .316-.1l13.782 1.61a.402.402 0 0 1-.094.8l-13.33-1.558.098 14.232a.402.402 0 0 1-.804.006l-.101-14.687c0-.116.048-.226.134-.303ZM34.295.28a.402.402 0 0 1 .568.045l9.456 11.065a.402.402 0 0 1-.145.63l-8.562 3.735 22.127 8.599a.4.4 0 0 1 .122.075l10.362 9.255a.402.402 0 1 1-.537.6L57.38 25.078 34.412 16.15a.402.402 0 0 1-.015-.744l8.958-3.907L34.25.847a.402.402 0 0 1 .044-.567Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.163 2.62c.21.073.32.303.248.513l-2.975 8.526 2.673 8.712 5.025 12.705 5.114-8.554a.403.403 0 0 1 .188-.164l7.847-3.32a.402.402 0 1 1 .313.742l-7.725 3.268-5.465 9.142a.403.403 0 0 1-.72-.058l-5.331-13.48a.392.392 0 0 1-.01-.03l-2.717-8.853a.402.402 0 0 1 .005-.25l3.018-8.651a.402.402 0 0 1 .512-.248Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.064 9.744a.402.402 0 0 1 .133.305l-.201 14.686a.403.403 0 0 1-.805-.01l.195-14.23-13.327 1.557a.402.402 0 1 1-.093-.8l13.782-1.609a.402.402 0 0 1 .316.101ZM.86 33.715a.402.402 0 0 1 .569-.03l10.205 9.206 23.068 8.926a.402.402 0 0 1 .016.744l-8.958 3.907 9.103 10.653a.402.402 0 1 1-.612.523l-9.456-11.066a.402.402 0 0 1 .145-.63l8.56-3.733-22.225-8.6a.4.4 0 0 1-.125-.077L.89 34.283a.402.402 0 0 1-.03-.568Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.088 33.584c.152.012.284.11.34.252l5.331 13.48a.38.38 0 0 1 .011.03l2.716 8.853a.403.403 0 0 1-.005.25L22.463 65.1a.402.402 0 1 1-.76-.265l2.975-8.526-2.673-8.712-5.028-12.713-5.213 8.565a.402.402 0 0 1-.181.158l-7.746 3.42a.402.402 0 1 1-.325-.736l7.63-3.369 5.568-9.147a.402.402 0 0 1 .378-.191Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.423 42.836a.403.403 0 0 1 .4.405l-.098 14.232 13.33-1.557a.402.402 0 0 1 .093.8l-13.782 1.609a.402.402 0 0 1-.449-.402l.1-14.687a.402.402 0 0 1 .406-.4ZM68.255 33.716a.402.402 0 0 1-.032.569L57.86 43.538a.4.4 0 0 1-.122.075l-22.127 8.6 8.562 3.734a.402.402 0 0 1 .145.63l-9.456 11.066a.402.402 0 0 1-.612-.523l9.103-10.653-8.958-3.907a.402.402 0 0 1 .015-.744l22.968-8.926 10.307-9.207a.402.402 0 0 1 .569.032Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.028 33.583c.153-.012.3.063.378.195l5.466 9.144 7.728 3.369a.402.402 0 0 1-.321.737l-7.847-3.42a.402.402 0 0 1-.184-.162l-5.114-8.554-5.025 12.705-2.673 8.712 2.974 8.526a.402.402 0 1 1-.76.266l-3.017-8.652a.402.402 0 0 1-.005-.25l2.716-8.853a.392.392 0 0 1 .01-.03l5.332-13.48a.402.402 0 0 1 .342-.253Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.588 42.836a.402.402 0 0 1 .408.397l.2 14.687a.402.402 0 0 1-.448.405l-13.782-1.61a.402.402 0 0 1 .093-.799l13.327 1.556-.195-14.228a.402.402 0 0 1 .397-.408Z"
        fill="#000"
      />
    </Svg>
  );
}
