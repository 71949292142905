import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import Icon from 'components/common/Icon';
import { CartItemEngraveSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';

import './EngraveInformation.scss';
import EngraveColorCircle from 'components/common/EngraveColorCircle';

interface IProps {
  engrave?: CartItemEngraveSettings[];
  engravePosition?: Record<'left' | 'top' | 'topTransform', number>;
  onClose: () => void;
}

export function EngraveInformation({
  engrave,
  engravePosition,
  onClose,
}: IProps): JSX.Element | null {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleClose = useCallback(
    (e: any) => {
      if (containerRef.current && containerRef.current.contains(e.target))
        return;
      onClose();
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('click', handleClose);

    return () => {
      document.removeEventListener('click', handleClose);
    };
  }, [handleClose]);

  if (!engrave) return null;

  return (
    <>
      <div
        className="cart-page__engrave-tip"
        style={
          engravePosition
            ? {
                left: `${engravePosition.left}px`,
                top: `${engravePosition.top}px`,
                transform: `translateY(${engravePosition.topTransform}%)`,
              }
            : undefined
        }
        ref={containerRef}
      >
        <div className="cart-page__engrave-tip__inner">
          {engrave.map((engraveItem) => {
            return (
              <div
                className="cart-page__engrave-tip__item"
                key={engraveItem.description}
              >
                <div className="cart-page__engrave-tip__title">
                  <Icon id="inside_bang" className="icon" />{' '}
                  {engraveItem.description}
                </div>
                <div className="cart-page__engrave-tip__text">
                  {engraveItem.text}
                </div>
                <div className="cart-page__engrave-tip__description">
                  {engraveItem.description} | {engraveItem.font.name} |{' '}
                  <EngraveColorCircle
                    color={engraveItem.color.name}
                    modifiers="cart-page__engrave-tip__description-color"
                  />{' '}
                  {engraveItem.color.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!engravePosition && <div className="cart-page__engrave-tip__overlay" />}
    </>
  );
}

export default observer(EngraveInformation);
