import * as React from 'react';
import { IObservableArray } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import ImageItem from 'components/common/ImageItem';
import { RouteComponentProps } from 'react-router-dom';
import { CategoriesProductImpl, ProductBands } from 'root/ProductTypes';
import { getCurrentCategory } from 'lib/utils';
import { TJewelryCatalog } from '../../../FineJewelry/types';
import { IProduct } from 'root/store/products/types/product.type';
import { CompareImpl } from 'root/store/compare';

export interface TableBodyProps extends RouteComponentProps {
  rings: IObservableArray<TJewelryCatalog>;
  compare: CompareImpl;
}

export const TableBody = withRouter(
  observer((props: TableBodyProps) => {
    const { rings, compare } = props;
    const handleClick =
      (id: number) =>
      (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        e.stopPropagation();
        const { pathname } = props.location;
        props.history.push(`${pathname}/${id}`);
      };

    const handleOpenUrl =
      (url: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(url, '_blank');
      };

    const stopPropagation = (
      e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    ) => {
      e.stopPropagation();
    };

    const handleAddToCompare = (product: IProduct) => () => {
      compare.toggle((product as any).productId);
    };

    const getCurrentBand = (categories: CategoriesProductImpl[]) => {
      let currentBand = '';
      const currentCategory = getCurrentCategory(categories);
      Object.keys(ProductBands).forEach((productBand) => {
        if (currentCategory.includes(productBand)) {
          currentBand = productBand;
        }
      });
      const band = categories.find((el) => el.alias === currentBand);
      return band?.name;
    };

    return (
      <tbody>
        {rings &&
          (rings.length === 0 ? (
            <tr className="no-hover">
              <td colSpan={8}>
                <p className="attention-message">
                  Found no items matched to search parameters.
                </p>
              </td>
            </tr>
          ) : (
            rings.map((ring, index) => {
              return (
                <tr key={index} onClick={handleClick(ring.productId)}>
                  <td>
                    <label className="checkbox-container">
                      <input
                        checked={compare.exist(ring.productId)}
                        type="checkbox"
                        name={`compare_${ring.id}`}
                        onClick={stopPropagation}
                        onChange={handleAddToCompare(ring)}
                      />
                      <span
                        className="checkmark"
                        onClick={stopPropagation}
                      ></span>
                    </label>
                  </td>
                  <td>
                    <CSSTransition in appear timeout={1000} classNames="zoom">
                      <ImageItem src={ring.photo} />
                    </CSSTransition>
                  </td>
                  <td>{ring.id}</td>
                  <td>{getCurrentBand(ring.categories) || 'N/A'}</td>
                  <td>{ring.metal}</td>
                  <td>${ring.minPrice}</td>
                </tr>
              );
            })
          ))}
      </tbody>
    );
  }),
);
