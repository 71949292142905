import React from 'react';
import { observer } from 'mobx-react';
import {
  IObservableArray,
  IObservableValue,
  observable,
  transaction,
} from 'mobx';
import { joinClasses } from 'lib/utils';
import Icon from '../Icon';
import Tooltip from 'react-tooltip-lite';

interface GridProps {
  title: string;
  list: {
    title: string;
    value: string | null;
    icon?: string | null;
    popular?: boolean;
  }[];
  columns: number;
  values: IObservableArray;
  tooltip?: boolean | string;
  icon: string | boolean;
  onAfterChange?: () => void;
}

@observer
export default class Grid extends React.Component<GridProps> {
  tipOpen: IObservableValue<boolean> = observable.box(false);

  toggleTip = (): void => {
    this.tipOpen.set(!this.tipOpen.get());
  };

  handleClick =
    (value: string) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      event.preventDefault();
      const $el = event.currentTarget;

      transaction(() => {
        if (!value) {
          this.props.values.clear();
        } else {
          if ($el.classList.contains('active')) {
            this.props.values.remove(value);
          } else {
            this.props.values.push(value);
          }
        }

        if (this.props.values.length === this.props.list.length - 1) {
          this.props.values.clear();
        }

        typeof this.props.onAfterChange === 'function' &&
          this.props.onAfterChange();
      });
    };

  setActiveClass = (value: string): string => {
    const { values } = this.props;

    return !values.length && !value
      ? 'active'
      : values.find((v) => v === value)
      ? 'active'
      : '';
  };

  render(): JSX.Element | null {
    const { title, list, columns, tooltip, icon } = this.props;

    return list.length ? (
      <div className="filter">
        <h4 className="filter-name">
          {title}
          {tooltip ? (
            <Tooltip
              content={tooltip}
              direction="up-start"
              className="custom-tooltip custom-tooltip_ml-15"
              tagName="span"
              onToggle={this.toggleTip}
            >
              <span
                className={joinClasses([
                  'tooltip-base',
                  this.tipOpen.get() && 'active-tooltip',
                ])}
              >
                <Icon id="info" />
              </span>
            </Tooltip>
          ) : null}
        </h4>
        <div className={`filter-body cols-${columns}`}>
          {list.map((element) => {
            return (
              <div
                key={element.value}
                className={`btn btn_default ${this.setActiveClass(
                  element.value || '',
                )}`}
                onClick={this.handleClick(element.value || '')}
              >
                {icon && element.icon ? <Icon id={element.icon} /> : null}
                {element.title}
              </div>
            );
          })}
        </div>
      </div>
    ) : null;
  }
}
