import { makeAutoObservable } from 'mobx';

export const LIMIT = 12;
export const TOTAL = 0;
export const PAGE = 1;
export const TOTAL_PAGES = 0;

export default class PaginateStore {
  limit = LIMIT;
  total = TOTAL;
  page = PAGE;
  totalPages = TOTAL_PAGES;

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  /**
   * Update pagination values
   * @param {Number} limit
   * @param {Number} total
   * @param {Number} page
   * @param {Number} totalPages
   */

  updatePaginate(
    limit: number = LIMIT,
    total: number = TOTAL,
    page: number = PAGE,
    totalPages: number = TOTAL_PAGES,
  ): void {
    this.limit = limit;
    this.total = total;
    this.page = page;
    this.totalPages = totalPages;
  }

  setLimitPage(limit: number = LIMIT, page: number = PAGE): void {
    this.limit = limit;
    this.page = page;
  }

  setLimit(limit: number = LIMIT): void {
    this.limit = limit;
  }

  setPage(page: number = PAGE): void {
    this.page = page;
  }

  setTotal(total: number = TOTAL): void {
    this.total = total;
  }

  setTotalPages(totalPages: number = TOTAL_PAGES): void {
    this.totalPages = totalPages;
  }

  reset(): void {
    this.limit = LIMIT;
    this.total = TOTAL;
    this.page = PAGE;
    this.totalPages = TOTAL_PAGES;
  }
}
