import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function DiamondSize300(props: IProps): JSX.Element {
  return (
    <Svg width="73" height="72" viewBox="0 0 73 72" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.236 1.208C17.021 1.208 1.444 16.785 1.444 36c0 19.215 15.577 34.792 34.792 34.792 19.216 0 34.792-15.577 34.792-34.792 0-19.215-15.577-34.792-34.792-34.792ZM.236 36c0-19.882 16.118-36 36-36 19.883 0 36 16.118 36 36s-16.117 36-36 36c-19.882 0-36-16.118-36-36Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.555.304c.167.146.185.4.04.568L26.81 12.123l9.643 4.243a.403.403 0 0 1-.017.744L11.96 26.618l-10.9 9.688a.403.403 0 1 1-.535-.601l10.954-9.737a.401.401 0 0 1 .122-.075l23.638-9.182-9.252-4.07a.403.403 0 0 1-.142-.633L35.987.344a.403.403 0 0 1 .568-.04Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.872 2.764c.21-.072.44.04.512.25l3.144 9.128c.028.08.03.166.005.246l-2.84 9.534a.429.429 0 0 1-.012.035l-5.68 14.2a.403.403 0 0 1-.718.058l-5.817-9.628-8.2-3.6a.403.403 0 1 1 .324-.737l8.317 3.651c.076.034.14.09.183.16l5.464 9.046 5.373-13.432 2.798-9.393-3.102-9.006a.403.403 0 0 1 .25-.512Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.273 10.248a.402.402 0 0 1 .316-.1l14.606 1.724a.403.403 0 1 1-.095.8L11.95 11l.198 15.262a.403.403 0 0 1-.806.01l-.202-15.72a.402.402 0 0 1 .133-.305ZM36.028.303a.403.403 0 0 1 .568.042l10.04 11.664a.403.403 0 0 1-.14.63l-9.16 4.071 23.544 9.183a.403.403 0 0 1 .122.075l10.954 9.737a.403.403 0 0 1-.535.601l-10.9-9.689-24.377-9.507a.403.403 0 0 1-.017-.744l9.546-4.242L35.985.87a.403.403 0 0 1 .043-.567Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.607 2.764c.21.073.322.302.25.512l-3.103 9.006 2.798 9.393 5.373 13.432 5.464-9.045a.403.403 0 0 1 .183-.16l8.317-3.652a.403.403 0 1 1 .324.737l-8.2 3.6-5.817 9.628a.403.403 0 0 1-.719-.059l-5.68-14.2a.429.429 0 0 1-.012-.034l-2.84-9.534a.402.402 0 0 1 .006-.246l3.144-9.128a.403.403 0 0 1 .512-.25Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.206 10.248a.402.402 0 0 1 .133.304l-.203 15.721a.403.403 0 1 1-.805-.01l.197-15.262-14.15 1.67a.403.403 0 0 1-.094-.8l14.605-1.724a.402.402 0 0 1 .317.101ZM.492 35.735a.403.403 0 0 1 .568-.03l10.9 9.789L36.436 55a.403.403 0 0 1 .014.745l-9.639 4.145 9.784 11.35a.403.403 0 1 1-.61.525L25.844 60.001a.403.403 0 0 1 .146-.633l9.238-3.973L11.6 46.218a.402.402 0 0 1-.124-.076L.522 36.304a.403.403 0 0 1-.03-.569Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.66 35.603c.153.012.285.11.342.253l5.68 14.3.011.033 2.84 9.433a.403.403 0 0 1-.004.246l-3.145 9.23a.403.403 0 0 1-.762-.26l3.103-9.108-2.798-9.292-5.372-13.527-5.464 9.138a.402.402 0 0 1-.184.162L3.59 49.862a.403.403 0 0 1-.324-.737l8.2-3.6 5.816-9.727a.403.403 0 0 1 .378-.195Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 45.44c.223.002.4.185.398.407l-.197 15.161 14.15-1.67a.403.403 0 1 1 .094.8l-14.606 1.724a.403.403 0 0 1-.45-.406l.204-15.62a.403.403 0 0 1 .407-.396ZM71.988 35.735a.403.403 0 0 1-.03.569l-10.955 9.838a.402.402 0 0 1-.123.076l-23.533 9.179 9.145 3.972a.402.402 0 0 1 .146.63L36.597 71.765a.403.403 0 0 1-.613-.523l9.688-11.352-9.542-4.144a.403.403 0 0 1 .014-.745l24.376-9.507 10.899-9.79a.403.403 0 0 1 .569.031Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.819 35.603c.152-.012.299.063.378.195l5.816 9.728 8.2 3.6a.403.403 0 1 1-.324.736l-8.317-3.65a.402.402 0 0 1-.184-.163l-5.464-9.138-5.372 13.527-2.798 9.292 3.103 9.108a.403.403 0 0 1-.763.26l-3.144-9.23a.403.403 0 0 1-.004-.246l2.84-9.433a.368.368 0 0 1 .011-.032l5.68-14.301a.403.403 0 0 1 .342-.253Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.729 45.44a.403.403 0 0 1 .407.397l.203 15.62a.403.403 0 0 1-.45.405l-14.605-1.725a.403.403 0 0 1 .095-.8l14.15 1.671-.198-15.16a.403.403 0 0 1 .398-.408Z"
        fill="#000"
      />
    </Svg>
  );
}
