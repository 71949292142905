import {
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import Currency, { CurrencyImpl } from '../currency';
import { fetchRing, fetchRingExtra } from 'service/api/rings';
import {
  BlocksElementsImpl,
  DetailBlocksImpl,
  ItemsBlockImpl,
  RingDetailResponseData,
} from 'service/api/apiTypes/catalogsApiTypes';
import { CategoriesProductImpl } from '../../ProductTypes';
import { AxiosResponse } from 'axios';

export interface ISettings {
  count: number;
  shape: string;
  size: string;
  settingType: string;
}

export interface RingDetailImpl {
  id: number;
  categories: CategoriesProductImpl[];
  currency: CurrencyImpl;
  price: string;
  photo: string | Array<string>;
  hasLoupe: boolean;
  loupe: string;
  outOfStock?: boolean;
  deleted?: boolean;
  size: string;
  metal: string;
  width: string;
  weight: string;
  sku: string;
  profile: string;
  gender: string;
  styles: IObservableArray<string>;
  bands: IObservableArray<string>;

  settings: IObservableArray<ISettings>;

  mainBlock: IObservableArray<ItemsBlockImpl> | undefined;
  detailBlock: IObservableArray<ItemsBlockImpl> | undefined;
  [key: string]: any;
}

export interface RingImpl extends RingDetailImpl {
  loadProduct: (id: number) => Promise<void>;
  updateRing: (stone: RingDetailResponseData) => void;
  reset: () => void;
  loadRingExtra: (id: number, hasLoupe: boolean) => Promise<void>;
  updateDetailBlocks: (detailBlocks: BlocksElementsImpl[]) => void;
  _price: string;
}

/**
 * Rings detail initial state
 */
export default class RingStore implements RingImpl {
  id = 0;
  categories = [] as CategoriesProductImpl[];
  currency: CurrencyImpl = new Currency();
  photo: string | Array<string> = [];
  _price = '';
  hasLoupe = false;
  loupe = '';
  outOfStock = false;
  size = '';
  metal = '';
  width = '';
  weight = '';
  sku = '';
  profile = '';
  gender = '';
  styles: IObservableArray<string> = observable.array([]);

  bands: IObservableArray<string> = observable.array([]);

  settings: IObservableArray<ISettings> = observable.array([]);

  mainBlock: IObservableArray<ItemsBlockImpl> | undefined = observable.array(
    [],
  );

  detailBlock: IObservableArray<ItemsBlockImpl> | undefined = observable.array(
    [],
  );

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  static new(ring: RingDetailResponseData): RingStore {
    const ringStore = new RingStore();
    ringStore.updateRing(ring);

    return ringStore;
  }

  updateRing(ring: RingDetailResponseData): void {
    this.id = ring.id;
    this.categories = ring.categories;
    this.currency.update(ring.currency);
    this.photo = ring.photo || ring.photos;
    this._price = ring.price;
    this.hasLoupe = ring.hasLoupe;
    this.loupe = ring.loupe;
    this.outOfStock = ring.outOfStock || false;
    this.size = ring.size;
    this.metal = ring.metal;
    this.width = ring.width;
    this.weight = ring.weight;
    this.sku = ring.sku;
    this.profile = ring.profile;
    this.gender = ring.gender;
    this.settings = ring.settings;
    this.styles.replace(ring.styles);
    this.bands.replace(ring.bands);
  }

  updateDetailBlocks(detailBlocks: BlocksElementsImpl[]): void {
    this.mainBlock?.replace(
      detailBlocks.find((el: BlocksElementsImpl) => el.blockType === 'main')
        ?.items || [],
    );
    this.detailBlock?.replace(
      detailBlocks.find((el: BlocksElementsImpl) => el.blockType === 'detail')
        ?.items || [],
    );
  }

  reset(): void {
    this.id = 0;
    this.currency = new Currency();
    this._price = '';
    this.photo = [];
    this.categories = [];
    this.hasLoupe = false;
    this.loupe = '';
    this.outOfStock = false;
    this.size = '';
    this.metal = '';
    this.width = '';
    this.weight = '';
    this.sku = '';
    this.profile = '';
    this.gender = '';
  }

  get price(): string {
    return this.currency.format(this._price);
  }

  /**
   * Update ring info
   * @param {Number=} id - ring id
   * @returns {Promise<void>}
   */
  async loadProduct(id: number): Promise<void> {
    id = id || this.id;
    try {
      const ring = await fetchRing(id);
      void this.loadRingExtra(id, ring.hasLoupe);
      this.updateRing(ring);
    } catch (err) {
      if (err && (err as AxiosResponse).status === 404) {
        window.location.href = '/404';
      }
    }
  }

  async loadRingExtra(id: number, hasLoupe: boolean): Promise<void> {
    id = id || this.id;
    fetchRingExtra(id)
      .then((res: DetailBlocksImpl) => {
        if (hasLoupe && res.loupeUrl) {
          runInAction(() => (this.loupe = res.loupeUrl));
        }
        this.updateDetailBlocks(res.detailBlocks);
      })
      .catch(() => {
        runInAction(() => {
          this.outOfStock = true;
        });
      });
  }
}
