import AWN from 'awesome-notifications';
import { Notifications } from './notifications.types';
import { DEFAULT_NOTIFIER_OPTIONS } from './notifications.constants';

class NotificationsService {
  readonly notifier = new AWN(DEFAULT_NOTIFIER_OPTIONS);

  constructor() {
    this.notifier = new Proxy(this.notifier, {
      get(target: Notifications, name: string) {
        if (target[name]) {
          return target[name];
        }
      },
    });
  }
}

export default new NotificationsService();
