import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import { CartItemImpl } from 'root/store/cart/item.store';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';

interface IDeleteSetPopupProps {
  visiblePopup: IObservableValue<boolean>;
  cartItem: CartItemImpl;
  removeCart: (event: MouseEvent<HTMLElement>) => void;
}

const DeleteSetPopup = observer((props: IDeleteSetPopupProps) => {
  const { visiblePopup, removeCart, cartItem } = props;
  const { sku, customizations } = cartItem;
  const stones = customizations?.stones;
  const stoneNames = stones?.reduce((text: string, item: any) => {
    return text ? `${text}, SKU:${item.sku}` : `SKU:${item.sku}`;
  }, '');

  const handleRemoveCart = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    removeCart(e);
  };

  return (
    <PortalControl>
      <ModalWindow visible={visiblePopup.get()}>
        <div
          className="btn btn_bordered-default_without_border btn_circle modal-window__btn-close"
          onClick={() => visiblePopup.set(false)}
        >
          <Icon id="close" />
        </div>

        <div className="modal-window-header">
          <h2 className="modal-window-header__title">Delete set</h2>
        </div>

        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text__small-text modal-window-body__text_center">
            Are you sure you want to delete SKU:{sku} and {stoneNames}?
          </p>

          <div className="modal-window-body-btn-wrap">
            <button
              onClick={handleRemoveCart}
              className="btn btn_brown btn_lg_popup"
            >
              Yes
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
});

export default DeleteSetPopup;
