import React, { DetailedHTMLProps, MutableRefObject, ReactNode } from 'react';
import { joinClasses } from 'lib/utils';
import Icon from '../Icon';

import './TooltipWithIcon.scss';

interface IProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  modifiers?: string;
  withoutIcon?: boolean;
  isHidden?: boolean;
  children: ReactNode;
  forwardRef?: MutableRefObject<HTMLSpanElement | null>;
}

export default function TooltipWithIcon({
  children,
  modifiers,
  withoutIcon,
  forwardRef,
  isHidden = false,
  ...rest
}: IProps): JSX.Element | null {
  return (
    <span
      {...rest}
      ref={forwardRef}
      className={joinClasses([
        'tooltip',
        isHidden && 'tooltip--hidden',
        modifiers,
      ])}
    >
      {!withoutIcon && <Icon className="tooltip__icon" id="tooltip" />}
      {children}
    </span>
  );
}
