import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as qs from 'qs';
import { joinClasses } from 'lib/utils';
import hintForProductSetStore from 'components/HintForProductSet/HintForProductSet.store';
import { ScrollUpButton } from 'components/common/ScrollUpButton';
import { ComparePanel } from '../Compare/components/ComparePanel';
import { Catalog } from './components/Catalog';

import './EngagementRing.scss';

export const EngagementRingsCatalogPage = withRouter(
  observer((props: RouteComponentProps) => {
    /**
     * Replace url by catalog params
     */
    const historyReplace = (query: any) => {
      const { history, match } = props;
      const { search } = history.location;
      const { paramsForSet } = qs.parse(search, { parseArrays: true });

      history.replace(
        match.path +
          '?' +
          qs.stringify({ ...query, paramsForSet }, { arrayFormat: 'brackets' }),
      );
    };

    return (
      <div
        className={joinClasses([
          'catalog-page grid',
          hintForProductSetStore.product && 'with-hint',
        ])}
      >
        <ul className="grid-area-breadcrumbs">
          <li>search inventory</li>
        </ul>

        <Catalog historyReplace={historyReplace} />

        <ComparePanel />

        <ScrollUpButton />
      </div>
    );
  }),
);
