export const CAROUSEL_ACTIVE_ITEM = 0;
export const IMAGES_FOLDER = 'shapes/';
export const ITEM_WIDTH_DESKTOP = 18.229;
export const ITEM_WIDTH_TABLET = 33;

export const SHAPES = [
  'asscher',
  'cushion',
  'emerald',
  'heart',
  'marquise',
  'oval',
  'pear',
  'princess',
  'radiant',
  'round',
];

export const DIAMOND_PROPERTIES_TO_COMPARE: Array<string> = [
  'shape',
  'carat',
  'cut',
  'clarity',
  'fluorescence',
  'polish',
  'certificate',
];

export const RING_PROPERTIES_TO_COMPARE: Array<string> = [
  'profile',
  'size',
  'weight',
  'width',
  'gender',
  'metal',

  'primaryStoneShape',
  'primaryStoneType',
  'primaryStoneSize',
  'initialNumber',
  'state',
];

export const PENDANTS_PROPERTIES_TO_COMPARE: Array<string> = [
  'pendantType',
  'gender',
  'metal',

  'primaryStoneShape',
  'primaryStoneType',
  'primaryStoneSize',

  'initialNumber',
  'birthstoneMonth',
  'state',
];

export const EARRINGS_PROPERTIES_TO_COMPARE: Array<string> = [
  'closureType',
  'earringType',
  'gender',
  'metal',

  'primaryStoneShape',
  'primaryStoneType',
  'primaryStoneSize',

  'state',
  'earringPostType',
  'stoneClarityColor',
];

export const BRACELETS_PROPERTIES_TO_COMPARE: Array<string> = [
  'closureType',
  'gender',
  'metal',

  'primaryStoneShape',
  'primaryStoneType',
  'primaryStoneSize',

  'state',
  'initialNumber',
  'chainLength',
];

export const NECKLACES_PROPERTIES_TO_COMPARE: Array<string> = [
  'chainStyle',
  'claspType',
  'necklaceLength',
  'closureType',
  'gender',
  'metal',

  'primaryStoneShape',
  'primaryStoneType',
  'primaryStoneSize',

  'birthstoneMonth',
  'initialNumber',
  'state',
];
