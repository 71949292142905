import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function ClarityDiamond06(props: IProps): JSX.Element {
  return (
    <Svg width="104" height="72" viewBox="0 0 104 72" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="m52 71 51-44.445-.402-5.656-9.355-10.202L75.74 3.323 52 1 28.26 3.323l-17.503 7.374-9.355 10.202L1 26.555 52 71Z"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m52 71 4.828-12.929 13.58-18.687L87.71 30.9l3.722-11.111-6.84-10.404-8.852-6.06"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m84.592 9.385-8.55 5.252L52 16.758l15.894 7.374L87.71 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m76.041 14.637-8.147 9.495L52 35.647M91.432 19.788l-15.39-5.151M52 35.647l18.408 3.737M52 40.092l18.308-6.06 16.094 1.615 10.462-6.565L103 26.557M96.864 29.082 87.71 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m103 26.555-11.568-6.768 5.432 9.293M93.243 10.697 103 26.556M84.592 9.385l8.65 1.313M67.894 24.13l2.414 9.9M86.402 35.647 72.42 53.222M56.829 58.07l29.574-22.423M52 40.09l4.828 17.98M52 71 47.17 58.071l-13.58-18.687L16.29 30.9l-3.722-11.111 6.84-10.404 8.852-6.06"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m19.408 9.385 8.55 5.252L52 16.758l-15.893 7.374L16.29 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m27.959 14.637 8.147 9.495L52 35.647M12.568 19.788l15.39-5.151M52 35.647l-18.408 3.737M52 40.092l-18.308-6.06-16.094 1.615-10.462-6.565L1 26.557M7.136 29.082 16.29 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m1 26.555 11.568-6.768-5.432 9.293M10.757 10.697 1 26.556M19.409 9.385l-8.651 1.313M36.106 24.13l-2.414 9.9M17.598 35.647 31.58 53.222M47.172 58.07 17.598 35.648M52 40.09 47.17 58.07"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.2 6.2 50 8l2.7 2.9 2.1-2.3-2.6-2.4ZM18.3 19l2.6-.7.8 2.4 3.6 2.3.1 2.7-3.3 1.1-.6-2.4-2.4-1.8-.8-3.6ZM51 18.6l-1.2 6.6 5.1 4.3-2.5 2.3-5.6-4.7.8-8 3.4-.5ZM75.9 17.9l-1.8 4.5 8.8 3.7 1.4-3.1-6.3-1.7.9-2.3-3-1.1Z"
        fill="#54A97E"
      />
    </Svg>
  );
}
