import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';
import { ROUTER_PATHS } from 'root/routes/constants';
import { ProductCategories } from 'root/ProductTypes';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import { JewelryFilterParams } from 'pages/FineJewelryMainPage/constants';
import { StoneImpl } from 'root/store/stones/one.store';

interface AddDiamondPopupProps {
  product: StoneImpl;
  choosedPrice?: number;
  visiblePopup: IObservableValue<boolean>;
  addToCart: () => void;
}

const AddRingPopup = (props: AddDiamondPopupProps) => {
  const {
    product: { id, shape, price },
    choosedPrice,
    visiblePopup,
    addToCart,
  } = props;

  const handleAddToCart = () => {
    addToCart();
    visiblePopup.set(false);
  };

  const getParams = (type: ProductCategories) => {
    return {
      filters: {
        [JewelryFilterParams.CATEGORIES]: [ProductCategories[type]],
        [JewelryFilterParams.STONE_SHAPE]: [shape?.toUpperCase()],
      },
      paramsForSet: {
        autoAddToCard: true,
        fromItemType: 'diamonds',
        fromItemId: id,
        level: LevelPropductForSet.Child,
        price: choosedPrice || price,
      },
    };
  };

  const getQueryString = (type: ProductCategories) => {
    return qs.stringify(getParams(type), {
      arrayFormat: 'brackets',
    });
  };

  return (
    <PortalControl>
      <ModalWindow visible={visiblePopup.get()}>
        <div
          className="btn btn_bordered-default_without_border btn_circle modal-window__btn-close"
          onClick={() => visiblePopup.set(false)}
        >
          <Icon id="close" />
        </div>
        <div className="modal-window-header">
          <h2 className="modal-window-header__title">
            Add diamond to shopping cart
          </h2>
        </div>
        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text_big uppercase modal-window-body__text_center">
            You can add selected diamond to a ring setting, pendant or earring
            pair
          </p>

          <div className="modal-window-body-btn-wrap">
            <Link
              to={{
                pathname: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
                search: getQueryString(ProductCategories.ENGAGEMENT_RINGS),
              }}
              className="send btn btn_lg_popup btn_px-0 btn_bordered-dark-gray btn_stretch"
            >
              Add to a setting
            </Link>
          </div>
        </div>
        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text_big uppercase modal-window-body__text_center">
            Continue only with a diamond
          </p>

          <div className="modal-window-body-btn-wrap">
            <button
              onClick={handleAddToCart}
              className="btn btn_lg_popup btn_brown"
            >
              Add to shopping cart
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
};

export default observer(AddRingPopup);
