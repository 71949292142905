import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function DiamondSize150(props: IProps): JSX.Element {
  return (
    <Svg width="57" height="56" viewBox="0 0 57 56" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4 1.209C13.603 1.209 1.609 13.204 1.609 28c0 14.796 11.994 26.791 26.79 26.791 14.797 0 26.792-11.995 26.792-26.791 0-14.796-11.995-26.791-26.791-26.791ZM.4 28c0-15.464 12.536-28 28-28s28 12.536 28 28-12.536 28-28 28S.4 43.464.4 28Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.662.298c.17.145.189.399.044.568l-7.403 8.653 7.26 3.206a.403.403 0 0 1-.018.744L9.58 20.795l-8.304 7.504a.403.403 0 1 1-.54-.598l8.359-7.554a.401.401 0 0 1 .125-.077l18.127-7.002-6.864-3.03a.403.403 0 0 1-.143-.631L28.094.342a.403.403 0 0 1 .568-.044Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.993 2.138c.21-.074.44.037.514.246l2.518 7.151c.029.083.03.173.003.257l-2.316 7.252a.283.283 0 0 1-.009.024l-4.33 11.079a.403.403 0 0 1-.72.064l-4.567-7.447-6.23-2.77a.403.403 0 0 1 .326-.736l6.346 2.82a.403.403 0 0 1 .18.158l4.209 6.863 4.031-10.313 2.272-7.11-2.473-7.024a.403.403 0 0 1 .246-.514Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.994 8.06a.403.403 0 0 1 .315-.1l11.382 1.309a.403.403 0 0 1-.092.8L9.669 8.813l.098 11.63a.403.403 0 0 1-.806.008l-.1-12.087a.403.403 0 0 1 .133-.303ZM28.136.3a.403.403 0 0 1 .568.04l7.856 9.065a.403.403 0 0 1-.143.633l-6.957 3.033L47.58 20.07a.4.4 0 0 1 .123.075l8.46 7.554a.403.403 0 0 1-.536.601l-8.406-7.505-18.967-7.326a.403.403 0 0 1-.016-.745l7.354-3.206-7.498-8.65A.403.403 0 0 1 28.137.3Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.802 2.137c.211.07.324.3.253.51l-2.376 7.029 2.174 7.113 4.03 10.31 4.21-6.863a.403.403 0 0 1 .18-.158l6.344-2.82a.403.403 0 1 1 .328.736l-6.231 2.77-4.568 7.447a.403.403 0 0 1-.719-.064l-4.33-11.08a.367.367 0 0 1-.01-.028L35.87 9.787a.404.404 0 0 1 .003-.247l2.417-7.15c.072-.212.3-.325.511-.253Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.806 8.06a.403.403 0 0 1 .133.303l-.1 12.087a.403.403 0 1 1-.806-.007l.097-11.63-10.828 1.256a.403.403 0 1 1-.093-.8L47.49 7.96a.403.403 0 0 1 .316.101ZM.706 27.73a.403.403 0 0 1 .569-.028l8.305 7.504 18.967 7.427a.403.403 0 0 1 .016.743l-7.26 3.206 7.403 8.653a.403.403 0 0 1-.612.524l-7.755-9.065a.403.403 0 0 1 .143-.63l6.87-3.034-18.135-7.1a.4.4 0 0 1-.123-.077L.734 28.3a.403.403 0 0 1-.028-.569Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.033 27.6a.403.403 0 0 1 .34.254l4.33 11.079a.388.388 0 0 1 .01.026l2.316 7.352a.404.404 0 0 1-.005.257l-2.518 7.05a.403.403 0 1 1-.759-.271l2.473-6.923-2.272-7.21-4.031-10.312-4.21 6.863a.403.403 0 0 1-.18.158l-6.345 2.82a.403.403 0 1 1-.327-.736l6.231-2.77 4.568-7.447a.403.403 0 0 1 .379-.19Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.367 35.152a.403.403 0 0 1 .4.407L9.67 47.29l10.929-1.257a.403.403 0 1 1 .092.8l-11.382 1.31a.403.403 0 0 1-.449-.404l.101-12.187a.403.403 0 0 1 .406-.4ZM56.197 27.732a.403.403 0 0 1-.032.57l-8.46 7.553a.403.403 0 0 1-.122.075l-18.129 7.098 6.963 3.035a.403.403 0 0 1 .143.633l-7.856 9.065a.403.403 0 0 1-.608-.528l7.497-8.65-7.354-3.206a.403.403 0 0 1 .014-.744l18.969-7.427 8.406-7.506a.403.403 0 0 1 .569.032Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.767 27.6c.152-.014.3.06.38.19l4.567 7.447 6.23 2.77a.403.403 0 1 1-.327.736l-6.345-2.82a.403.403 0 0 1-.18-.158l-4.21-6.863-4.03 10.31-2.173 7.212 2.375 6.928a.403.403 0 1 1-.762.261l-2.417-7.05a.402.402 0 0 1-.005-.247l2.216-7.353a.404.404 0 0 1 .01-.03l4.331-11.08a.403.403 0 0 1 .34-.254Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.432 35.152a.403.403 0 0 1 .407.4l.1 12.187a.403.403 0 0 1-.449.404l-11.28-1.31a.403.403 0 1 1 .092-.8l10.828 1.256-.097-11.73a.403.403 0 0 1 .4-.407Z"
        fill="#000"
      />
    </Svg>
  );
}
