import {
  computed,
  flow,
  IObservableArray,
  makeAutoObservable,
  observable,
} from 'mobx';
import Currency from '../currency';
import { CartItemFactory, CartItemImpl } from './item.store';
import * as cart from 'service/api/cart';
import { IProductCustomizations } from '../products/types/product.type';
import { TCartInfo, TCartPutItem } from './types';
import {
  TCalculateInput,
  TCalculateOutput,
} from 'service/api/apiTypes/cart.types';
import { ICustomizationsInHintProduct } from 'components/HintForProductSet/HintForProductSet.types';

export default class CartStore {
  items: IObservableArray<CartItemImpl> = observable.array([]);
  currency = new Currency();
  private _totalWithPromoCode: null | number = null;
  private _shipping = 0;
  private _tax = 0;
  private _subtotal = 0;
  private _total = 0;

  constructor() {
    makeAutoObservable(this, {
      put: flow,
    });
  }

  @computed
  exist = (productId: number): boolean =>
    this.items.some((item: { id: number }) => item.id === productId);

  @computed
  getCartItemById = (
    productId: string | number,
    productCustomizations?:
      | IProductCustomizations
      | ICustomizationsInHintProduct,
  ): CartItemImpl | undefined =>
    this.items.find(({ id, customizations }: CartItemImpl) => {
      const isSizeMatches = () => {
        if (customizations?.size) {
          return !!(
            productCustomizations &&
            customizations.size === productCustomizations.size
          );
        } else {
          return true;
        }
      };

      return id === productId && isSizeMatches();
    });

  get tax(): string {
    return this.currency.format(this._tax);
  }

  get subtotal(): string {
    return this.currency.format(this._subtotal);
  }

  get total(): string {
    return this.currency.format(this._total);
  }

  get totalWithPromoCode(): string | null {
    return this._totalWithPromoCode
      ? this.currency.format(this._totalWithPromoCode)
      : null;
  }

  get shipping(): string {
    return this.currency.format(this._shipping);
  }

  get totalAmount(): number {
    return this._total;
  }

  get subtotalAmount(): number {
    return this._subtotal;
  }

  get taxAmount(): number {
    return this._tax;
  }

  get shippingAmount(): number {
    return this._shipping;
  }

  get totalWithPromocodeAmount(): number | null {
    return this._totalWithPromoCode;
  }

  /**
   * Clear cart items
   */
  clear(): void {
    this.items.clear();
    // TODO: review logic for cart
    this._totalWithPromoCode = null;
    this._shipping = 0;
    this._tax = 0;
    this._subtotal = 0;
    this._total = 0;
  }

  /**
   * Update props of class
   */
  update(cartInfo: TCartInfo): void {
    cartInfo.items.forEach((item) => {
      item.currency = cartInfo.currency;
    });
    this.items.replace(
      cartInfo.items
        .map((item) => {
          return item.shape === 'Cushion Modified'
            ? { ...item, shape: 'Cushion' }
            : item;
        })
        .map(CartItemFactory.getItem),
    );
    this.currency.update(cartInfo.currency);
    this._shipping = cartInfo.shipping;
    this._tax = cartInfo.tax;
    this._subtotal = cartInfo.subtotal;
    this._total = cartInfo.total;
  }

  /**
   * Update total values
   * @param info
   */
  updateTotal(info: any) {
    // TODO: move to type
    this._shipping = info.shipping;
    this._tax = info.tax;
    this._subtotal = info.subtotal;
    this._total = info.total;
  }

  updateTotalWithPromoCode(amount: any) {
    // TODO: move to type
    this._totalWithPromoCode = amount;
  }

  /**
   * Find items from cart (session)
   */
  *find(): Generator<Promise<TCartInfo>, void, TCartInfo> {
    const cartInfo = yield cart.find();
    this.update(cartInfo);
  }

  /**
   * Put item to cart
   * @param {Object} item
   */
  *put(
    item: TCartPutItem,
  ): Generator<Promise<TCartInfo | void> | void, void, TCartInfo> {
    try {
      yield cart.put(item);
      const cartInfo = yield cart.find();
      yield this.update(cartInfo);
      yield window.localStorage.setItem('cartSync', 'true');
      yield window.localStorage.removeItem('cartSync');
    } catch (e) {
      window.location.reload();
    }
  }

  /**
   * Remove item from cart
   * @param {string} guid
   * @param {Number} removeChild
   * @returns {Promise<void>}
   */
  *remove(
    guid: string,
    removeChild: number,
  ): Generator<Promise<TCartInfo | void> | void, void, TCartInfo> {
    try {
      yield cart.remove(guid, removeChild);
      const cartInfo = yield cart.find();
      this.update(cartInfo);
      yield window.localStorage.setItem('cartSync', 'true');
      yield window.localStorage.removeItem('cartSync');
    } catch (e) {
      window.location.reload();
    }
  }

  /**
   * Cart calculation
   * @param data
   * @returns {Promise<TCalculateOutput>}
   */
  *calculate(
    data: TCalculateInput,
  ): Generator<
    Promise<TCalculateOutput | void> | void,
    void,
    TCalculateOutput
  > {
    try {
      const result = yield cart.calculate(data);
      this.updateTotal({
        shipping: 0,
        tax: result.tax,
        subtotal: result.subtotal,
        total: result.total,
      });
    } catch (e) {
      console.error(e); // TODO: handle global
    }
  }
}
