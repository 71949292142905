import * as React from 'react';
import { observer } from 'mobx-react';
import { ValidationEvent } from '@quantumart/mobx-form-validation-kit/dist/validation-event';
import { RouteComponentProps } from 'react-router';
import SelectWrap from 'components/common/SelectWrap';
import {
  InputFormControl,
  SelectFormControl,
  TextAreaFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { ShippingFormStore } from './shipping.store';
import CartStore from 'root/store/cart';

interface ShippingStepProps extends RouteComponentProps {
  store: ShippingFormStore;
  cart: CartStore;
}

export const ShippingStep = observer(({ store, cart }: ShippingStepProps) => {
  const { controls } = store.form;

  return (
    <form className="form" id="shipping" name="shipping" action="" noValidate>
      <div className="form-wrap">
        <div className="col">
          <h3 className="form-title">
            Shipping Information
          </h3>
          <SelectWrap>
            <select
              name="country"
              className={`${'select'} ${'form-label__textarea'} ${'block-scrollbar'} ${
                controls.country.invalid && controls.country.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.country.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.country.value}
              {...SelectFormControl.bindActions(controls.country)}
            >
              <option value="">Select your country *</option>
              {store.countries.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
            {controls.country.touched && controls.country.errors && (
              <div className="form-label__error">
                {controls.country.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </SelectWrap>

          {store.states.length ? (
            <SelectWrap>
              <select
                name="state"
                className={`${'select'} ${
                  controls.state.invalid && controls.state.touched
                    ? 'invalid'
                    : 'valid'
                } ${controls.state.value ? 'shrink' : ''} `}
                data-form="shipping"
                {...SelectFormControl.bindActions(controls.state)}
              >
                <option value="">Select your state *</option>
                {store.states.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </select>
              {controls.state.touched && controls.state.errors && (
                <div className="form-label__error">
                  {controls.state.errors.map((error: ValidationEvent) => (
                    <span key={error.message}>{error.message}</span>
                  ))}
                </div>
              )}
            </SelectWrap>
          ) : null}

          <label className="form-label">
            <input
              type="text"
              name="city"
              className={`${
                controls.city.invalid && controls.city.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.city.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.city.value}
              {...InputFormControl.bindActions(controls.city)}
            />
            <div className="label-text">City *</div>
            {controls.city.touched && controls.city.errors && (
              <div className="form-label__error">
                {controls.city.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>

          <div className="form-label form-label_with-textarea">
            <textarea
              rows={1}
              name="address"
              className={` ${'form-label__textarea'} ${'block-scrollbar'} ${
                controls.address.invalid && controls.address.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.address.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.address.value}
              {...TextAreaFormControl.bindActions(controls.address)}
            />
            <div className="label-text label-text_for-textarea">
              Street Address *
            </div>
            {controls.address.touched && controls.address.errors && (
              <div className="form-label__error">
                {controls.address.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </div>

          <label className="form-label">
            <input
              type="text"
              name="aptUnit"
              className={` ${'not-required'} ${
                controls.aptUnit.invalid && controls.aptUnit.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.aptUnit.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.aptUnit.value}
              {...InputFormControl.bindActions(controls.aptUnit)}
            />
            <div className="label-text">Apt/Unit</div>
            {controls.aptUnit.touched && controls.aptUnit.errors && (
              <div className="form-label__error">
                {controls.aptUnit.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>

          <label className="form-label">
            <input
              type="text"
              name="zipCode"
              className={`${
                controls.zipCode.invalid && controls.zipCode.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.zipCode.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.zipCode.value}
              {...InputFormControl.bindActions(controls.zipCode)}
            />
            <div className="label-text">ZIP Code *</div>
            {controls.zipCode.touched && controls.zipCode.errors && (
              <div className="form-label__error">
                {controls.zipCode.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>

          <p
            className={`attention-message attention-message_mt-20 attention-message_mb-35 ${
              store.form.touched && store.form.invalid
                ? 'attention-message_warning'
                : ''
            }`}
            data-form="shipping"
          >
            {store.form.touched && store.form.invalid
              ? '* Please fill correctly the required fields.'
              : '* Fields are required.'}
          </p>
        </div>

        <div className="col">
          <h3 className="box-title">&nbsp;</h3>
          <label
            className={`checkbox-container checkbox-container_mt-30 ${
              !controls.diffRecipient.value
                ? 'checkbox-container_checked-hide'
                : ''
            }`}
          >
            Different Recipient {controls.diffRecipient.value}
            <input
              type="checkbox"
              name="diffRecipient"
              data-form="shipping"
              checked={!!controls.diffRecipient.value}
              {...InputFormControl.bindActions(controls.diffRecipient)}
            />
            <span className="checkmark"></span>
          </label>

          <p className="attention-message">
            You can assign different recepient if needed
          </p>

          <label className="form-label form-label_hidden">
            <input
              type="text"
              name="firstName"
              className={`${
                controls.firstName.invalid && controls.firstName.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.firstName.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.firstName.value}
              {...InputFormControl.bindActions(controls.firstName)}
            />
            <div className="label-text">First Name *</div>
            {controls.firstName.touched && controls.firstName.errors && (
              <div className="form-label__error">
                {controls.firstName.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>

          <label className="form-label form-label_hidden">
            <input
              type="text"
              name="lastName"
              className={`${
                controls.lastName.invalid && controls.lastName.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.lastName.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.lastName.value}
              {...InputFormControl.bindActions(controls.lastName)}
            />
            <div className="label-text">Last Name *</div>
            {controls.lastName.touched && controls.lastName.errors && (
              <div className="form-label__error">
                {controls.lastName.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>

          <label className="form-label form-label_hidden">
            <input
              type="email"
              name="email"
              className={`${
                controls.email.invalid && controls.email.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.email.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.email.value}
              {...InputFormControl.bindActions(controls.email)}
            />
            <div className="label-text">E-mail *</div>
            {controls.email.touched && controls.email.errors && (
              <div className="form-label__error">
                {controls.email.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>

          <label className="form-label form-label_hidden">
            <input
              type="text"
              name="phone"
              className={`${
                controls.phone.invalid && controls.phone.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.phone.value ? 'shrink' : ''} `}
              data-form="shipping"
              value={controls.phone.value}
              {...InputFormControl.bindActions(controls.phone)}
            />
            <div className="label-text">Recipient mobile phone *</div>
            {controls.phone.touched && controls.phone.errors && (
              <div className="form-label__error">
                {controls.phone.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>
          <p
            className={`attention-message attention-message_hidden attention-message_mt-20 attention-message_mb-35 ${
              store.form.touched && store.form.invalid
                ? 'attention-message_warning'
                : ''
            }`}
            data-form="shipping"
          >
            {store.form.touched && store.form.invalid
              ? '* Please fill correctly the required fields.'
              : '* Fields are required.'}
          </p>
        </div>
      </div>
    </form>
  );
});
