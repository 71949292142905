import InfinityIcon from './InfinityIcon';
import StarChristmasIcon from './StarChristmasIcon';
import TruckIcon from './TruckIcon';
import BadgeDollarIcon from './BadgeDollarIcon';
import LeafRegularIcon from './LeafRegularIcon';

export default {
  infinity: InfinityIcon,
  star_christmas: StarChristmasIcon,
  truck: TruckIcon,
  badge_dollar: BadgeDollarIcon,
  leaf_regular: LeafRegularIcon,
};
