import * as React from 'react';
import { observer } from 'mobx-react';
import {
  InputFormControl,
  TextAreaFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { contactUs } from 'service/api/app';
import ModalWindow from 'components/common/ModalPopup';
import PortalControl from 'components/common/PortalControl';
import FieldErrors from 'components/FieldErrors';
import Icon from 'components/common/Icon';
import { ContactServiceStoreImpl } from './ContactTypes';

interface ContactServiceProps {
  triggerComponent?: JSX.Element | undefined;
  store: ContactServiceStoreImpl;
}

@observer
export class Contact extends React.Component<ContactServiceProps> {
  componentWillUnmount(): void {
    const { store } = this.props;
    store.form.dispose();
  }

  closeModal = (): void => {
    const { store } = this.props;

    store.visibleContact(false);
    store.resetForm();
    store.changeStatus('');
    store.disableForm(false);
  };

  handleSubmit = async (
    event: React.ChangeEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();
    const { store } = this.props;

    await store.form.wait();

    store.form.setTouched(true);

    if (store.form.valid) {
      store.disableForm(true);
      store.changeStatus('PENDING');

      contactUs(store.formValues)
        .then(() => {
          store.changeStatus('SUCCESS');
          store.disableForm(true);
        })
        .catch(() => {
          store.changeStatus('FAILED');
          store.disableForm(false);
          setTimeout(() => store.changeStatus(''), 5000);
        });
    }
  };

  handleVisibleContact =
    (value: boolean) =>
    (event: React.SyntheticEvent): void => {
      event.preventDefault();
      const { store } = this.props;
      store.visibleContact(value);
    };

  empty = (event: React.SyntheticEvent): void => {
    event.preventDefault();
  };

  render(): JSX.Element {
    const { store, triggerComponent } = this.props;
    const { controls } = store.form;
    const isFormDisabled = store.disable;

    return (
      <>
        {triggerComponent ? (
          <div
            onClick={this.handleVisibleContact(true)}
            className="contact-trigger"
          >
            {triggerComponent}
          </div>
        ) : (
          <a
            className="common-link"
            href="#"
            onClick={this.handleVisibleContact(true)}
          >
            customer service
          </a>
        )}

        <PortalControl>
          <ModalWindow visible={store.visible}>
            <div
              className="btn btn_bordered-default btn_circle modal-window__btn-close"
              onClick={this.closeModal}
            >
              <Icon id="close" />
            </div>
            <div className="modal-window-header">
              <h2 className="modal-window-header__title">Contact us</h2>
            </div>
            <div className="modal-window-body modal-window-scroll-area block-scrollbar">
              <p className="modal-window__text modal-window__text_center modal-window__text_my-10">
                Please fill out the form to contact us
              </p>

              <div className="modal-window-form">
                <form
                  className="form"
                  id="customer"
                  name="customer"
                  action=""
                  onSubmit={this.handleSubmit}
                  autoComplete="false"
                  noValidate
                >
                  <label className="form-label">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      data-form="customer"
                      className={`${
                        controls.firstName.invalid && controls.firstName.touched
                          ? 'invalid'
                          : 'valid'
                      } ${controls.firstName.value ? 'shrink' : ''}`}
                      value={controls.firstName.value}
                      disabled={isFormDisabled}
                      {...InputFormControl.bindActions(controls.firstName)}
                    />
                    <div className="label-text">First Name *</div>
                    <FieldErrors control={controls.firstName} />
                  </label>

                  <label className="form-label">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className={`${
                        controls.lastName.invalid && controls.lastName.touched
                          ? 'invalid'
                          : 'valid'
                      } ${controls.lastName.value ? 'shrink' : ''}`}
                      data-form="customer"
                      value={controls.lastName.value}
                      disabled={isFormDisabled}
                      {...InputFormControl.bindActions(controls.lastName)}
                    />
                    <div className="label-text">Last Name *</div>
                    <FieldErrors control={controls.lastName} />
                  </label>

                  <label className="form-label">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className={`${
                        controls.email.invalid && controls.email.touched
                          ? 'invalid'
                          : 'valid'
                      } ${controls.email.value ? 'shrink' : ''}`}
                      data-form="customer"
                      value={controls.email.value}
                      disabled={isFormDisabled}
                      {...InputFormControl.bindActions(controls.email)}
                    />
                    <div className="label-text">E-mail *</div>
                    <FieldErrors control={controls.email} />
                  </label>

                  <label className="form-label">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      className={`${
                        controls.phone.invalid && controls.phone.touched
                          ? 'invalid'
                          : 'valid'
                      } ${controls.phone.value ? 'shrink' : ''}`}
                      data-form="customer"
                      value={controls.phone.value}
                      disabled={isFormDisabled}
                      {...InputFormControl.bindActions(controls.phone)}
                    />
                    <div className="label-text">Phone *</div>
                    <FieldErrors control={controls.phone} />
                  </label>

                  <div className="form-textarea form-textarea_mt-30">
                    <label className="label-text">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      className="block-scrollbar"
                      value={controls.message.value}
                      disabled={isFormDisabled}
                      {...TextAreaFormControl.bindActions(controls.message)}
                    ></textarea>
                  </div>

                  <p className="attention-message attention-message_mb-30 bold">
                    All questions will be answered within two business days.{' '}
                    <br />
                    You can contact us by phone
                    <span className="bold text-brown"> +1-877-382-6275</span>
                  </p>

                  {!store.status ? (
                    <div className="modal-window-btn-wrap">
                      <button
                        className="btn btn_lg btn_brown btn_stretch"
                        type="submit"
                        value="Submit"
                        disabled={isFormDisabled}
                      >
                        Send
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
            <div className="modal-window-footer">
              {store.status === 'FAILED' ? (
                <div className="modal-window__text modal-window__text_center modal-window__text_my-10">
                  Sorry. Some problem is happened. Please try again.
                </div>
              ) : null}

              {store.status === 'SUCCESS' ? (
                <div className="modal-window__text modal-window__text_center modal-window__text_my-10">
                  Thank you! Your message has been successfully sent. We will
                  contact you very soon!
                </div>
              ) : null}
            </div>
          </ModalWindow>
        </PortalControl>
      </>
    );
  }
}
