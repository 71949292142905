import {
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import storage from 'service/storage';
import {
  ProductDetailImpl,
  ProductInfoImpl,
  StoneInfoImpl,
} from '../ProductTypes';
import { fetchCompareProducts } from 'service/api/products';
import { CartItemFactory } from './cart/item.store';

export const COMPARE_KEY = 'COMPARE_PRODUCTS';

export interface CompareImpl {
  list: IObservableArray<ProductDetailImpl>;
  localStorageList: IObservableArray<number>;
  isLoading: boolean;
  setLoading(value: boolean): void;
  exist(id: number): boolean;
  add(productId: number): void;
  toggle(productId: number): void;
  remove(productId: number): Promise<void>;
  filter(ids: number[]): void;
  clear(): void;
  update(productInfo: ProductInfoImpl[]): void;
  loadCompare(): Generator<
    Promise<Array<ProductInfoImpl>>,
    void,
    Array<ProductInfoImpl>
  >;
}

class Compare implements CompareImpl {
  list = observable<ProductDetailImpl>([], { deep: false });
  localStorageList = observable<number>([], { deep: false });
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
    const result: string = storage.get(COMPARE_KEY) || '';
    try {
      const ids = JSON.parse(result);
      runInAction(() => {
        this.localStorageList.replace(ids);
      });
      if (this.localStorageList.length) {
        this.loadCompare();
      } else {
        this.update([]);
      }
    } catch (e) {
      storage.set(COMPARE_KEY, []);
    }
  }

  setLoading(value: boolean): void {
    this.isLoading = value;
  }

  exist(productId: number): boolean {
    return !!~this.list.findIndex(
      (el: ProductDetailImpl) =>
        el.id === productId || el.productId === productId,
    );
  }

  toggle(productId: number): void {
    this.exist(productId) ? void this.remove(productId) : this.add(productId);
  }

  add(productId: number): void {
    this.isLoading = true;
    try {
      this.localStorageList.push(productId);
      storage.set(COMPARE_KEY, this.localStorageList); // sync with local storage
      this.loadCompare();
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }

  async remove(productId: number): Promise<void> {
    const items = this.localStorageList.filter(
      (el: number) => !(el === productId),
    );
    this.localStorageList.replace(items);
    storage.set(COMPARE_KEY, this.localStorageList); // sync with local storage

    if (this.localStorageList.length) {
      this.loadCompare();
    } else {
      this.update([]);
    }
  }

  filter(ids: number[]): void {
    const localStorageCompareKeys = storage.get(COMPARE_KEY) || '';
    const items = JSON.parse(localStorageCompareKeys).filter(
      (el: ProductDetailImpl) => ids.includes(el.id),
    );
    this.list.replace(items);
    storage.set(COMPARE_KEY, this.list); // sync with local storage
  }

  clear(): void {
    this.list.clear();
    this.localStorageList.clear();
    storage.remove(COMPARE_KEY);
  }

  update(productInfo: Array<ProductInfoImpl>): void {
    this.list.replace(
      productInfo
        .map((item) => {
          return (item as StoneInfoImpl).shape === 'Cushion Modified'
            ? { ...item, shape: 'Cushion' }
            : item;
        })
        .map(CartItemFactory.getItem),
    );
  }

  *loadCompare(): Generator<
    Promise<Array<ProductInfoImpl>>,
    void,
    Array<ProductInfoImpl>
  > {
    this.isLoading = true;
    try {
      const compareInfo = yield fetchCompareProducts({
        ids: this.localStorageList,
      });
      this.update(compareInfo);
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }
}

export default Compare;
