import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { HeaderInterfaceProps } from './Header.types';
import { Header as DefaultComponent } from './Header';
import rootStore from 'root/store';
import HeaderStore from './Header.store';
import weddingRingCatalog from 'pages/WeddingRings/store';
import engagementRingCatalog from 'pages/EngagementRings/store';
import diamondsCatalog from 'pages/Diamonds/store';
import fineJewelryCatalog from 'pages/FineJewelry/store';
import { useEffect } from 'react';

const { filters: filtersWeddingRings } = weddingRingCatalog;
const { filters: filtersEngagementRings } = engagementRingCatalog;
const { filters: filtersDiamonds } = diamondsCatalog;
const { filters: filtersFineJewelry } = fineJewelryCatalog;

const {
  account: { user },
  cart,
  compare,
  favorite,
  browserWindowType,
} = rootStore;

export const store = new HeaderStore();

export const Header = withRouter(
  observer((props: HeaderInterfaceProps) => {
    useEffect(() => {
      window.addEventListener('storage', (event) => {
        if (event.storageArea !== localStorage) return;
        if (event.key === 'cartSync') {
          cart.find();
        }
      });
    }, []);

    return (
      <DefaultComponent
        {...props}
        userIsAuth={user.isAuth}
        store={store}
        cart={cart}
        compare={compare}
        favorite={favorite}
        filtersStones={filtersDiamonds}
        filtersWeddingRings={filtersWeddingRings}
        filtersEngagementRings={filtersEngagementRings}
        filtersJewelry={filtersFineJewelry}
        browserWindowType={browserWindowType}
      />
    );
  }),
);
