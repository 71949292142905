import React from 'react';
import Svg from '../../Svg';

interface IPrincessMeasWidthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PrincessMeasWidthIcon(
  props: IPrincessMeasWidthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M163.23 36.21H36.14V163.3h127.09V36.21z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.38 36.21 61.6 99.75 36.38 163.3M162.98 36.21l-25.22 63.54 25.22 63.55"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m36.14 36.21 63.54 25.22 63.54-25.22M36.14 163.3l63.54-25.22 63.54 25.22"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.79 149.89v-19.35H49.36V69.62H69.3V49.48h60.76v20.14H150v60.92h-20.43v19.35H69.79z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m69.3 69.62-7.86 30.13 8.35 30.79 29.89 7.54 29.89-7.54 8.18-30.79-7.69-30.13-30.47-8.19-30.29 8.19z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.98 36.21v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.98 31.31v-11.9"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.4 3.4"
      />
      <path
        d="M162.98 17.71v-1.5M36.38 36.21v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.38 31.31v-11.9"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.4 3.4"
      />
      <path
        d="M36.38 17.71v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.38 16.21h126.6"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
