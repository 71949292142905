import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function DiamondSize075(props: IProps): JSX.Element {
  return (
    <Svg width="46" height="45" viewBox="0 0 46 45" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.293 1.21c-11.758 0-21.29 9.532-21.29 21.29s9.532 21.29 21.29 21.29c11.757 0 21.289-9.532 21.289-21.29S35.05 1.21 23.292 1.21ZM.793 22.5C.792 10.074 10.865 0 23.292 0c12.426 0 22.5 10.074 22.5 22.5S35.718 45 23.292 45C10.866 45 .793 34.926.793 22.5Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.563.291c.169.146.188.4.043.57l-5.899 6.85 5.754 2.505a.404.404 0 0 1-.016.747L8.279 16.788 1.676 22.79a.404.404 0 0 1-.543-.598l6.66-6.053a.406.406 0 0 1 .126-.079l14.316-5.498-5.352-2.331a.404.404 0 0 1-.145-.634L22.994.334a.404.404 0 0 1 .57-.043Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.997 1.83a.404.404 0 0 1 .512.253l1.917 5.65c.028.081.029.17.003.251l-1.816 5.752a.422.422 0 0 1-.009.023l-3.43 8.88a.404.404 0 0 1-.72.066l-3.668-5.947-4.93-2.17a.404.404 0 0 1 .325-.738l5.045 2.22a.404.404 0 0 1 .181.157l3.307 5.362 3.133-8.109 1.772-5.613-1.874-5.525a.404.404 0 0 1 .252-.512Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.796 6.449a.404.404 0 0 1 .317-.098l8.98 1.11a.404.404 0 0 1-.099.8L8.467 7.209v9.23a.404.404 0 1 1-.807 0V6.75c0-.115.05-.226.136-.302ZM23.039.289a.404.404 0 0 1 .569.047l6.154 7.265a.404.404 0 0 1-.144.63l-5.264 2.33 14.226 5.5a.405.405 0 0 1 .124.076l6.76 6.055a.404.404 0 1 1-.538.6l-6.706-6.004-15.066-5.826a.404.404 0 0 1-.018-.745l5.662-2.506L22.992.858a.404.404 0 0 1 .047-.569Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.608 1.832c.21.075.32.306.244.516L29.88 7.871l1.772 5.61 3.133 8.108 3.306-5.362a.404.404 0 0 1 .18-.157l5.046-2.22a.404.404 0 0 1 .325.739l-4.93 2.169-3.667 5.947a.404.404 0 0 1-.72-.066l-3.43-8.88a.357.357 0 0 1-.01-.024l-1.816-5.75a.404.404 0 0 1 .005-.258l2.018-5.65a.404.404 0 0 1 .516-.245Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.804 6.45a.403.403 0 0 1 .135.305l-.101 9.687a.404.404 0 1 1-.807-.009l.096-9.225-8.624 1.054a.404.404 0 1 1-.098-.801l9.081-1.11a.404.404 0 0 1 .319.1ZM1.106 22.22a.404.404 0 0 1 .57-.027l6.603 6.003 15.166 5.825a.404.404 0 0 1 .016.747l-5.754 2.505 5.899 6.85a.404.404 0 1 1-.612.527l-6.256-7.265a.404.404 0 0 1 .145-.633l5.352-2.33-14.316-5.5a.402.402 0 0 1-.127-.078L1.133 22.79a.404.404 0 0 1-.027-.57Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.834 22.09a.404.404 0 0 1 .34.255l3.43 8.778a.383.383 0 0 1 .01.027l1.815 5.852a.404.404 0 0 1-.003.25l-1.917 5.65a.404.404 0 0 1-.764-.26l1.875-5.526-1.773-5.713-3.131-8.01-3.309 5.364a.404.404 0 0 1-.18.158l-5.046 2.22a.404.404 0 0 1-.325-.74l4.93-2.168 3.668-5.947a.404.404 0 0 1 .38-.19Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.064 28.143c.223 0 .403.18.403.403v9.23l8.527-1.054a.404.404 0 1 1 .1.801l-8.98 1.11a.404.404 0 0 1-.454-.4v-9.687c0-.223.18-.403.404-.403ZM45.495 22.222a.404.404 0 0 1-.031.57l-6.76 6.054a.405.405 0 0 1-.124.076l-14.226 5.5 5.264 2.33a.403.403 0 0 1 .144.63l-6.154 7.266a.404.404 0 0 1-.616-.522l5.806-6.853-5.662-2.506a.404.404 0 0 1 .018-.746l15.066-5.825 6.706-6.005a.404.404 0 0 1 .57.031Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.665 22.09c.152-.014.3.06.38.19l3.667 5.947 4.93 2.169a.404.404 0 1 1-.325.739l-5.045-2.22a.404.404 0 0 1-.181-.158l-3.309-5.365-3.13 8.011-1.772 5.71 1.973 5.523a.404.404 0 0 1-.76.272l-2.019-5.65a.404.404 0 0 1-.005-.256l1.816-5.852a.44.44 0 0 1 .01-.027l3.43-8.778a.404.404 0 0 1 .34-.255Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.43 28.143a.404.404 0 0 1 .408.399l.1 9.686a.403.403 0 0 1-.452.405l-9.08-1.11a.404.404 0 1 1 .097-.801l8.624 1.054-.096-9.226a.404.404 0 0 1 .4-.407Z"
        fill="#000"
      />
    </Svg>
  );
}
