import axios from './index';
import { AxiosPromise } from 'axios';
import { CartCustomerInfoImpl } from 'root/store/cart/item.store';
import { TCartInfo } from 'root/store/cart/types';
import {
  TCalculateOutput,
  TCalculateInput,
  TGetPaymentProvidersOutput,
} from './apiTypes/cart.types';

/**
 * API path
 * @type {string}
 */
const pathStone = '/cart';

/**
 * Find products from cart
 * @returns {AxiosPromise}
 */
export const find = (): Promise<TCartInfo> => axios.get(`${pathStone}`);

/**
 * Put to cart product
 * @param params
 * @returns {AxiosPromise}
 */
export const put = (params: {
  id: number;
  guid?: string;
  quantity: number;
}): Promise<void> => axios.put(`${pathStone}/push`, { ...params });

/**
 * Put parent to cart product
 * @param {string} childGuid
 * @param params
 * @returns {AxiosPromise}
 */
export const putParentToCart = (
  childGuid: string,
  params: { id: number; quantity: number },
): Promise<void> =>
  axios.put(`${pathStone}/pushParentFor/${childGuid}`, { ...params });

/**
 * Put child to cart product
 * @param {string} parentGuid
 * @param params
 * @returns {AxiosPromise}
 */
export const putChildToCart = (
  parentGuid: string,
  params: { id: number; quantity: number },
): Promise<void> =>
  axios.put(`${pathStone}/pushChildFor/${parentGuid}`, { ...params });

/**
 * Remove item from cart product
 * @param {string} guid
 * @param {Number} removeChild //accepts 0 or 1, we should always pass 1, but if we need to remove the parent product leaving child, we pass 0
 * @returns {AxiosPromise}
 */
export const remove = (guid: string, removeChild: number): Promise<void> =>
  axios.delete(`${pathStone}/${guid}/${removeChild}`);

/**
 * Clear cart product
 * @returns {AxiosPromise}
 */
export const clear = (): AxiosPromise => axios.delete(`${pathStone}`);

/**
 * To place order before checkout
 * @returns {AxiosPromise}
 */
export const placeOrder = (): AxiosPromise =>
  axios.post(`${pathStone}/placeOrder`);

/**
 * Checkout cart product
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const checkout = (
  data: unknown,
): Promise<{ orderId: number }> => // TODO: move any to DTO
  axios.post(`${pathStone}/checkout`, data);

/**
 * Checkout cart product
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const applyPromoCode = (
  data: unknown,
): Promise<any> => // TODO: move any to DTO
  axios.post(`${pathStone}/apply-promocode`, data);

/**
 * Billing details autofill
 * @returns {AxiosPromise}
 */
export const autofill = (): Promise<CartCustomerInfoImpl> =>
  axios.get(`${pathStone}/autofill`);

/**
 * Calculate total by data
 * @param data
 * @returns {AxiosPromise}
 */
export const calculate = (data: TCalculateInput): Promise<TCalculateOutput> =>
  axios.post(`${pathStone}/calculate`, data);

/**
 * Get available payment system types
 * @returns {AxiosPromise}
 */
export const getPaymentProviders = (): Promise<TGetPaymentProvidersOutput[]> =>
  axios.get(`${pathStone}/payment-providers`);
