import React from 'react';
import Svg from '../../Svg';

interface IPearMeasDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PearMeasDepthIcon(
  props: IPearMeasDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M119.2 136.7 24.62 85.64v-5.11l12.44-8.66 27.3-7.77h85.48l23.98 19.1v5.55L119.2 136.7z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m37.06 71.87 21.75 9.33 21.32-8.49-.44-8.61-42.63 7.77zM104.25 64.1l-24.12 8.61 24.12 10.86 20.14-11.7-20.14-7.77zM133.41 64.1l-9.02 7.77L142 83.57l6.36-11.84-14.95-7.63zM149.84 64.1l-1.48 7.63 11.25 11.84 1.63-10.39M80.13 72.71l-1.04 9.32M124.39 71.87l-.6 11.7"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.82 88.75c-2.77 0-4-1.9-7-1.9s-4.11 2.12-7 2.12c-2.89 0-6.67-2.36-9.66-2.36s-4.89 1.88-7.44 1.88c-2.55 0-6.77-1.88-9.33-1.88-2.56 0-7.54 1.88-9.62 1.88-2.08 0-5.81-2-9.25-1.88-3.44.12-6.88 1.25-10.32 1.81-3.44.56-9.88-2.11-13.21-2.18-3.33-.07-9.33 1.29-11.92 1.51-2.59.22-8.07-2.22-10.26-2.55-2.19-.33-6 .22-8.44.44-2.44.22-12-.67-18.65-.89-5.712-.013-11.42.284-17.1.89v-5.11c3-.51 12 1.89 17.62 1.71 4.75-.16 12.69-1 16.57-1 3.71 0 6.78 2.37 10.37 2.37 3 0 6.12-1.54 9.91-1.54 4.32 0 8.15 2.8 12.29 2.87 4.14.07 9.77-1.48 12.88-1.33 3.11.15 6.18 1.26 10.5 1.33 4.32.07 6.07-1.33 9-1.33 2.93 0 6.29 1.53 10.07 1.55 3.78.02 5.62-1.55 8.14-1.55 2.52 0 6.22 1.77 8.88 1.63 2.66-.14 6.37-1.48 8.73-1.63 2.36-.15 5.41 1.7 7.94 1.74 1.48 0 6.27-2.11 6.27-2.11"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m24.62 85.64 48.52 17.76-12.77-17.76 43.18 31.86-24.46-29.75"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m123.79 88.49-2.03 28.45-17.54-28.52-.67 29.08 15.65 19.2 2.56-19.76 20.98-28.45 2.69 25.19 14.41-24.71M73.13 103.399l46.08 33.31"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.41 64.1v71.86"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.21 136.71h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.58 136.71h61.76"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.87 2.87"
      />
      <path
        d="M186.78 136.71h1.5M148.88 63.36h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.19 63.36h32.31"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.81 2.81"
      />
      <path
        d="M186.91 63.36h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.74 48.52h151.19"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.74 85.64v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.74 81.04V51.57"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.1 3.1"
      />
      <path
        d="M23.74 50.02v-1.5M174.93 85.64v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.93 81.04V51.57"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.1 3.1"
      />
      <path
        d="M174.93 50.02v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
