import React from 'react';
import Svg from '../Svg';

interface TwitterIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function TwitterIcon(props: TwitterIconProps): JSX.Element {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M17.9441 5.92613C17.9568 6.10379 17.9568 6.28148 17.9568 6.45914C17.9568 11.8779 13.8325 18.1216 6.29441 18.1216C3.97207 18.1216 1.81473 17.4489 0 16.2815C0.329961 16.3195 0.647187 16.3322 0.989844 16.3322C2.90605 16.3322 4.67004 15.685 6.07867 14.581C4.27664 14.5429 2.76648 13.3627 2.24617 11.7383C2.5 11.7764 2.75379 11.8018 3.02031 11.8018C3.38832 11.8018 3.75637 11.751 4.09898 11.6622C2.22082 11.2814 0.812148 9.63172 0.812148 7.63934V7.58859C1.35781 7.89316 1.99238 8.08352 2.66492 8.10887C1.56086 7.37281 0.837539 6.11648 0.837539 4.69516C0.837539 3.93375 1.04055 3.23578 1.3959 2.62664C3.41367 5.11395 6.44668 6.73828 9.84766 6.91598C9.78422 6.61141 9.74613 6.29418 9.74613 5.97691C9.74613 3.71801 11.5736 1.87793 13.8451 1.87793C15.0253 1.87793 16.0913 2.37285 16.84 3.17234C17.7664 2.99469 18.6547 2.65203 19.4416 2.1825C19.137 3.1343 18.4898 3.93379 17.6395 4.44137C18.4644 4.35258 19.2639 4.1241 19.9999 3.80687C19.4416 4.61902 18.7436 5.34234 17.9441 5.92613Z" />
    </Svg>
  );
}
