export const ADVANTAGES = [
  {
    title: 'HAND CRAFTED IN NEW YORK',
    description:
      'All of pieces are hand crafted in New York City to ensure the utmost quality and are carefully inspected before being sent to you.',
    isOpen: false,
  },
  // {
  //   title: 'NEWEST TECHNOLOGY FOR VIEWING DIAMONDS',
  //   description: 'Lets remove the Newest technology line for now.',
  // },
  {
    title: '80.000 DIAMONDS IN OUR INVENTORY',
    description:
      'Enjoy browsing through our extensive inventory of diamonds and gem stones to find the perfect fit for you or your loved one.',
    isOpen: false,
  },
  {
    title: 'LIFETIME CARE',
    description:
      'We stand behind our designs and guarantee that they are made with the highest quality materials and are free of any manufacturer defects. Our life time care includes one free annual cleaning, prong tightening, polishing, and rhodium plating. In the event that the issues with your ring are not covered by our life time care warranty, you will be notified of charges prior to starting the work. You are responsible for insured shipping charges. If you wish to purchase an insured return shipping label from us, you may do so by contacting us at repairs@venori.com.',
    isOpen: false,
  },
  {
    title: 'FINANCING OPTIONS',
    description:
      'We know that buying an engagement ring or piece of jewelry can be costly so we offer financing options through Syncrony as well as a 1.5% discount for purchases made via bank wire.',
    isOpen: false,
  },
  {
    title: 'BEST PRICE GUARANTEE',
    description:
      'Rest assured that you are receiving the best prices in the industry! We pride our selves in knowing that we make the highest quality jewelry and engagements ring at the best possible price for our clients.',
    isOpen: false,
  },
];
