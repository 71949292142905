import { RING_STYLES } from 'utils/constants/rings';
import { ROUTER_PATHS } from 'root/routes/constants';

export const CAROUSEL_ACTIVE_ITEM = 1;
export const CAROUSEL_ITEM_WIDTH = 1.301;
export const CAROUSEL_ITEM_ACTIVE_WIDTH = 3.385;
export const COEFFICIENT = 0.6505;

export enum EngagementRingsFilterParams {
  RING_STYLE = 'ringStyle',
}

export const RINGS_TITLES = {
  title: 'Engagement rings',
  state: { category: 'Engagement Rings' },
  subTile: 'Rings',
  navigatePath: '/rings/engagement',
  folderImg: 'engagementRings',
};

export const ENGAGEMENT_RINGS_IMG = [
  'solitaire',
  'halo',
  'two_stone',
  'three_stone',
  'accented_stone',
  'bezel_set',
  'vintage',
];

export const RINGS = [
  {
    link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    params: {
      filters: {
        [EngagementRingsFilterParams.RING_STYLE]: [
          RING_STYLES.EG_RING_SOLITAIRE,
        ],
      },
    },
    title: 'Solitaire',
    description: '',
    icon: 'solitaire',
    popular: true,
  },
  {
    link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    params: {
      filters: {
        [EngagementRingsFilterParams.RING_STYLE]: [RING_STYLES.EG_RING_HALO],
      },
    },
    title: 'Halo',
    description: '',
    icon: 'halo',
    popular: true,
  },
  {
    link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    params: {
      filters: {
        [EngagementRingsFilterParams.RING_STYLE]: [
          RING_STYLES.EG_RING_TWOSTONE,
        ],
      },
    },
    title: 'Two-stone',
    description: '',
    icon: 'two_stone',
    popular: false,
  },
  {
    link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    params: {
      filters: {
        [EngagementRingsFilterParams.RING_STYLE]: [
          RING_STYLES.EG_RING_THREESTONE,
        ],
      },
    },
    title: 'Three-stone',
    description: '',
    icon: 'three_stone',
    popular: true,
  },
  {
    link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    params: {
      filters: {
        [EngagementRingsFilterParams.RING_STYLE]: [
          RING_STYLES.EG_RING_ACCENTED,
        ],
      },
    },
    title: 'Accented stone',
    description: '',
    icon: 'accented_stone',
    popular: false,
  },
  {
    link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    params: {
      filters: {
        [EngagementRingsFilterParams.RING_STYLE]: [RING_STYLES.EG_RING_BEZEL],
      },
    },
    title: 'Bezel set',
    description: '',
    icon: 'bezel_set',
    popular: true,
  },
  {
    link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    params: {
      filters: {
        [EngagementRingsFilterParams.RING_STYLE]: [RING_STYLES.EG_RING_VINTAGE],
      },
    },
    title: 'Vintage',
    description: '',
    icon: 'vintage',
    popular: false,
  },
];
