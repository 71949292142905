import ProfileStore from './profile.store';
import UserStore from './user.store';

export default class AccountStore {
  profile: ProfileStore;
  user: UserStore;

  constructor() {
    this.profile = new ProfileStore();
    this.user = new UserStore();
  }
}
