import React from 'react';
import Svg from '../../Svg';

interface IMarquiseTableIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function MarquiseTableIcon(
  props: IMarquiseTableIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="m161.36 69.32-12.47-6.92H50.28l-12.47 6.92-15 14.31v3.47l76.77 48.239 76.78-48.24v-3.47l-15-14.31zM22.81 87.1h153.55M22.81 83.63h153.55"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.58 83.63 77.51 69.32l-3.92-6.92-35.78 6.92 3.7 14.31 36-14.31-5.07 14.31M99.58 83.63l22.08-14.31 3.92-6.92 35.78 6.92-3.7 14.31-36-14.31 5.07 14.31"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m77.51 69.32 22.07-6.92 22.08 6.92M67.04 114.89 42.89 87.1l47.86 31.77L73.36 87.1M99.58 135.34l-8.83-16.47 8.83-31.77M132.13 114.89l24.15-27.79-47.86 31.77 17.39-31.77M99.58 135.34l8.84-16.47-8.84-31.77"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.39 47.4h152.13"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.1 87.1v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.1 82.78V50.36"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.82 2.82"
      />
      <path
        d="M177.1 48.95v-1.5M21.81 87.1v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.81 82.77V50.31"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.82 2.82"
      />
      <path
        d="M21.81 48.9v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.73 31.67h95.45"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.17 62.4v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.17 57.82V34.75"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.08 3.08"
      />
      <path
        d="M148.17 33.209v-1.5M50.74 62.4v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.74 57.82V34.708"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.08 3.08"
      />
      <path
        d="M50.74 33.17v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
