import { makeAutoObservable } from 'mobx';

export class FilterRange {
  from: number | string;
  to: number | string;

  constructor(from: number | string, to: number | string) {
    makeAutoObservable(this);
    this.from = from;
    this.to = to;
  }

  setFrom(from: number | string) {
    this.from = from;
  }

  setTo(to: number | string) {
    this.to = to;
  }

  setRange = (from: number | string, to: number | string) => {
    this.from = from;
    this.to = to;
  };
}
