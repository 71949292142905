import axios from './index';
import {
  DetailBlocksImpl,
  IFilterExtraStonesResponse,
  StoneDetailResponseData,
} from './apiTypes/catalogsApiTypes';
import { RandomProductType } from 'root/store/stones/list.store';
import { IFilterStonesExtraParamsRequest } from 'pages/Diamonds/types';

/**
 * API path
 * @type {string}
 */
const pathStone = '/stones';

/**
 * Fetch catalog's stones
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchStones = (
  params: unknown,
): Promise<StoneDetailResponseData[]> => // TODO: create type data
  axios.get(`${pathStone}`, { params });

/**
 * Fetch random catalog's stones
 * @param productCategory
 * @returns {AxiosPromise}
 */
export const fetchRandomProduct = (
  productCategory: string,
): Promise<RandomProductType> => // TODO: create type data
  axios.get(`rings/random/${productCategory}`);

/**
 * Fetch full catalog's stones
 * @param params
 * @returns {AxiosPromise}
 */
export const fetchFullStones = (
  params: unknown,
): Promise<any> => // TODO: create type data
  axios.get(`${pathStone}/full`, { params });

/**
 * Fetch stone by id
 * @param id
 * @returns {AxiosPromise}
 */
export const fetchStone = (id: number): Promise<StoneDetailResponseData> =>
  axios.get(`${pathStone}/${id}`);

/**
 * Fetch extra stone by id
 * @param id
 * @returns {AxiosPromise}
 */
export const fetchStoneExtra = (id: number): Promise<DetailBlocksImpl> =>
  axios.get(`${pathStone}/${id}/extra`);

/**
 * Fetch stones filter params
 * @returns {Promise<IFilterExtraStonesResponse>}
 */
export const fetchStonesFilterParams = (
  params: IFilterStonesExtraParamsRequest,
): Promise<IFilterExtraStonesResponse> =>
  axios.post(`${pathStone}/extra`, params);
