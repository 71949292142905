import {
  IObservableArray,
  makeAutoObservable,
  observable,
  reaction,
  when,
} from 'mobx';
import { CountryImpl, StateImpl } from 'root/store/app';
import {
  FormControl,
  FormGroup,
  patternValidator,
  requiredValidator,
  ValidationEventTypes,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';
import { IFormShippingStep, TFormValues } from './shippingForm.types';
import { TFormCustomerValues } from '../CustomerStep/customerForm.types';
import { autofill } from '../cartCheckout.store';

import store from 'root/store';

const { app, cart } = store;

export class ShippingFormStore {
  countries: IObservableArray<CountryImpl> = app.countries;
  states = observable.array<StateImpl>([]);

  form: FormGroup<IFormShippingStep>;

  constructor() {
    makeAutoObservable(this);

    this.form = this.initialForm();

    when(
      () => autofill.isEmptyFill,
      () => {
        this.updateForm(autofill.deliveryFill, autofill.customerFill);
      },
    );
  }

  /**
   * Reset all forms
   */
  resetForm(): void {
    this.form = this.initialForm();
    when(
      () => autofill.isEmptyFill,
      () => {
        this.updateForm(autofill.deliveryFill, autofill.customerFill);
      },
    );
  }

  /**
   * Reset all forms
   */
  updateForm(delivery: TFormValues, customer: TFormCustomerValues): void {
    const { controls } = this.form;
    for (const field in controls) {
      if (
        Object.hasOwnProperty.call(controls, field) &&
        Object.hasOwnProperty.call(delivery, field)
      ) {
        if (delivery[field]) {
          (controls[field] as FormControl).value =
            (delivery[field] as CountryImpl).id || delivery[field];
        }

        if (!controls.diffRecipient && customer[field]) {
          controls.firstName.value = customer.firstName;
          controls.lastName.value = customer.lastName;
          controls.email.value = customer.email;
          controls.phone.value = customer.phone;
        }
      }
    }
  }

  /**
   * Return controls collection
   */
  private initialForm = (): FormGroup<IFormShippingStep> =>
    new FormGroup<IFormShippingStep>({
      firstName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z]+$/,
              'First Name must contain only latin letters',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
        activate: () => {
          return !!this.form?.controls.diffRecipient.value;
        },
      }),
      lastName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z]+$/,
              'Last Name must contain only latin letters',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
        activate: () => {
          return !!this.form?.controls.diffRecipient.value;
        },
      }),
      email: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              'Wrong Email format',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
        activate: () => {
          return !!this.form?.controls.diffRecipient.value;
        },
      }),
      phone: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^(?=.*[0-9])[- +()0-9]+$/,
              'Phone must contain only digits, +, -',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
        activate: () => {
          return !!this.form?.controls.diffRecipient.value;
        },
      }),
      country: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
        onChangeValue: (value) => {
          const country: CountryImpl | undefined = this.countries.find(
            (c) => c.id === Number(value),
          );

          if (country) {
            cart.calculate({
              amount: cart.subtotalAmount,
              countryId: +country.id,
              stateId: +this.form.controls.state.value,
            });

            this.states.replace(country?.states || []);
            this.form.controls.state.value = ''; // reset state value
          }
        },
      }),
      state: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
        activate: () => {
          return !!this.states.length;
        },
        onChangeValue: (value) => {
          const country: CountryImpl | undefined = this.countries.find(
            (c) => c.id === Number(this.form.controls.country.value),
          );

          if (country) {
            const state = country?.states.find((s) => s.id === Number(value));

            cart.calculate({
              amount: cart.subtotalAmount,
              countryId: +country.id,
              stateId: +(state?.id || 0),
            });
          }
        },
      }),
      city: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
      }),
      address: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
      }),
      aptUnit: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            patternValidator(
              /[\d\s]*$/,
              'The field format is invalid',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      zipCode: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^\d+$/,
              'The field format is invalid',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      diffRecipient: new FormControl<boolean>(false),
    });
}

export default new ShippingFormStore();
