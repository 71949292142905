import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AdvantagesPage as DefaultComponent } from './AdvantagesPage';
import store from './store/index';
import rootStore from 'root/store';

const { cart } = rootStore;

export const AdvantagesPage = observer(
  withRouter((props: RouteComponentProps) => (
    <DefaultComponent {...props} cart={cart} store={store} />
  )),
);
