import React from 'react';
import OrderStore from 'root/store/order';
import { observer } from 'mobx-react';
import { TableHeadTh } from '../TableHeadTh';

/**
 * Constant for table head
 * @type {[]}
 */
const COLS = [
  {
    title: 'Compare',
    className: '',
  },
  {
    title: 'View',
    className: '',
  },
  {
    title: 'Code',
    className: 'enableOrder',
    order: 'id',
  },
  {
    title: 'Metal',
    className: 'enableOrder',
    order: 'metal',
  },
  {
    title: 'Category',
    className: 'enableOrder',
    order: 'categoryName',
  },
  {
    title: 'Price',
    className: 'enableOrder',
    order: 'price',
  },
];

interface TableHeadProps {
  order: OrderStore;
  handleOrder: (property: string, direction: string | null) => void;
}

export const TableHead = observer((props: TableHeadProps) => {
  const { order, handleOrder } = props;
  return (
    <thead>
      <tr>
        {COLS.map((i) => {
          return (
            <TableHeadTh
              key={i.title}
              item={i}
              order={order}
              handleOrder={handleOrder}
            />
          );
        })}
      </tr>
    </thead>
  );
});
