import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function CuttingC(props: IProps): JSX.Element {
  return (
    <Svg width="106" height="104" viewBox="0 0 106 104" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3008 72.9309V63.2402L71.0008 90.4141L61.7008 97.6072L33.3008 72.9309Z"
        fill="#67B88B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.4023 82.1226L57.9023 99.4059H77.2024L70.4023 82.1226Z"
        fill="#67B88B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0984 72.9309V63.2402L35.3984 90.4141L44.5984 97.6072L73.0984 72.9309Z"
        fill="#67B88B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9992 82.1226L48.4992 99.4059H29.1992L35.9992 82.1226Z"
        fill="#67B88B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3008 4.0957H44.5008V82.6202L33.3008 72.9295V4.0957Z"
        fill="url(#paint0_linear_9184_212678)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3008 0H44.5008V18.4822H49.0008L38.9008 28.5725L28.8008 18.4822H33.3008V0Z"
        fill="url(#paint1_linear_9184_212678)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9023 6.99316H73.1024V73.1296L61.9023 82.8203V6.99316Z"
        fill="url(#paint2_linear_9184_212678)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9023 0H73.1024V18.4822H77.6024L67.5023 28.5725L57.4023 18.4822H61.9023V0Z"
        fill="url(#paint3_linear_9184_212678)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.2 90.3132L98.5 50.951L98.2 45.9558L89.9 36.9645L74.4 30.4707L53.2 28.4727L32.1 30.4707L16.6 36.9645L8.29999 45.9558L8 50.951L53.2 90.3132Z"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 90.3132L57.4992 78.9241L69.5992 62.3401L84.9992 54.8473L88.1992 44.9568L82.1992 35.7656L74.3992 30.4707"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.1992 35.7666L74.5992 40.562L53.1992 42.3603L67.2992 48.854L84.9992 54.8483"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5992 40.5605L67.2992 48.8526L53.1992 59.0428"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.2016 44.9563L74.6016 40.5605"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 59.043L69.5992 62.3398"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 63.0396L69.4992 57.6447L83.7992 59.0434L93.0992 53.1491L98.4992 50.9512"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.1 53.1479L85 54.8463"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.4992 50.9513L88.1992 44.957L93.0992 53.1492"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.8984 36.9644L98.4984 50.9509"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.1992 35.7666L89.8992 36.9654"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.3008 48.8535L69.5008 57.645"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.7984 59.043L71.3984 74.5281"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5 78.9238L83.8 59.043"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 63.0391L57.4992 78.9238"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1992 90.3132L48.8992 78.9241L36.8992 62.3401L21.4992 54.8473L18.1992 44.9568L24.2992 35.7656L32.0992 30.4707"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3 35.7666L31.9 40.562L53.2 42.3603L39.1 48.854L21.5 54.8483"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8984 40.5605L39.0984 48.8526L53.1985 59.0428"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1992 44.9563L31.8992 40.5605"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1985 59.043L36.8984 62.3398"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.2 63.0396L37 57.6447L22.7 59.0434L13.4 53.1491L8 50.9512"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3984 53.1479L21.4984 54.8463"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 50.9513L18.2 44.957L13.4 53.1492"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6 36.9644L8 50.9509"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3015 35.7666L16.6016 36.9654"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.1 48.8535L37 57.645"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6992 59.043L35.0992 74.5281"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.8992 78.9238L22.6992 59.043"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1985 63.0391L48.8984 78.9238"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9184_212678"
          x1="38.8974"
          y1="111.16"
          x2="38.8974"
          y2="4.13266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003916" />
          <stop offset="0.49" stopColor="#92CBA7" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9184_212678"
          x1="38.9046"
          y1="30.2565"
          x2="38.9046"
          y2="0.0123448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="0.671875" stopColor="#D5E9DD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9184_212678"
          x1="67.5033"
          y1="110.401"
          x2="67.5033"
          y2="7.01404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003916" />
          <stop offset="0.49" stopColor="#92CBA7" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9184_212678"
          x1="67.5062"
          y1="30.2565"
          x2="67.5062"
          y2="0.0123448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="0.671875" stopColor="#D5E9DD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </Svg>
  );
}
