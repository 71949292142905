import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteDataType } from '../../../pages/FAQ/FAQPage.type';
import Icon from '../Icon';

import './ScrollableTabs.scss';

interface IScrollableTabsProps extends RouteComponentProps {
  list: RouteDataType[];
  children?: React.ReactNode;
  className?: string;
}

@observer
class ScrollableTabs extends React.Component<IScrollableTabsProps> {
  sliderWrap: React.RefObject<HTMLInputElement>;
  slider: React.RefObject<HTMLUListElement>;

  shiftValues = observable<number>([]);
  activeIndex: IObservableValue<number> = observable.box(0);
  isRightArrowShown: IObservableValue<boolean> = observable.box(false);

  constructor(props: IScrollableTabsProps) {
    super(props);

    this.sliderWrap = React.createRef();
    this.slider = React.createRef();
  }

  @action
  moveSlide = (index: number): void => {
    this.activeIndex.set(index);
  };

  @action
  showHideArrows = (index: number): void => {
    const sliderWrapWidth = this.sliderWrap.current?.offsetWidth || 0;
    const sliderWidth = this.slider.current?.offsetWidth || 0;

    this.isRightArrowShown.set(
      !(sliderWrapWidth + this.shiftValues[index] >= sliderWidth),
    );
  };

  @action
  getShiftValues = (): void => {
    let sliderItems: number[] = [];
    const items: { offsetWidth: number }[] = [].slice.call(
      this.slider.current?.children,
    );
    for (const item of items) {
      sliderItems.push(item.offsetWidth);
    }
    sliderItems = sliderItems.map((_element: unknown, index: number) =>
      sliderItems.slice(0, index + 1).reduce((a: number, b: number) => a + b),
    );
    this.shiftValues.replace(sliderItems);

    // Add first value '0'
    this.shiftValues.unshift(0);
  };

  handleResize = (): void => {
    this.getShiftValues();
    this.showHideArrows(this.activeIndex.get());
  };

  componentDidMount(): void {
    this.getShiftValues();
    this.showHideArrows(this.activeIndex.get());

    window.addEventListener('resize', this.handleResize);

    // To show active slide after MainPage redirect
    if (this.props.location.pathname === '/faq/return-policy') {
      this.activeIndex.set(5);
    }
    if (this.props.location.pathname === '/faq/warranty-and-maintenance') {
      this.activeIndex.set(5);
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize);
  }

  handleSlide = (index: number) => (): void => {
    this.moveSlide(index);
    this.showHideArrows(index);
  };

  render(): JSX.Element {
    const { children, className } = this.props;
    const classNames = ['scrollable-tabs', className].join(' ');

    return (
      <div className={classNames}>
        {this.activeIndex.get() ? (
          <div
            className="scrollable-tabs__arrow scrollable-tabs__arrow_left"
            onClick={this.handleSlide(this.activeIndex.get() - 1)}
          >
            <Icon id="chevron" className="scrollable-tabs__arrow-icon" />
          </div>
        ) : null}

        {this.isRightArrowShown.get() ? (
          <div
            className="scrollable-tabs__arrow scrollable-tabs__arrow_right"
            onClick={this.handleSlide(this.activeIndex.get() + 1)}
          >
            <Icon id="chevron" />
          </div>
        ) : null}

        <div className="slider-wrap" ref={this.sliderWrap}>
          <ul
            className="slider"
            ref={this.slider}
            style={{
              transform: `translate3d(-${
                this.shiftValues[this.activeIndex.get()]
              }px, 0, 0)`,
            }}
          >
            {children}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(ScrollableTabs);
