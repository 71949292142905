import {
  FormControl,
  FormGroup,
  maxLengthValidator,
  patternValidator,
  requiredValidator,
  ValidationEventTypes,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';
import { makeAutoObservable } from 'mobx';
import { OFFER_STATUSES } from '../components/OfferPopup/constants';
import { getPromoActionParam } from '../../../../../service/api/app';

interface IGiftPromoCodeData {
  discountType: number;
  discountValue: number;
  minPrice: number;
}

export type TGiftPromoCodeData = IGiftPromoCodeData | null;

export default class OfferPopupStore {
  disable = false;
  form: FormGroup<any>;
  status = OFFER_STATUSES.APPLYING_EMAIL_STEP;
  giftPromoCodeData: TGiftPromoCodeData = null;

  constructor() {
    makeAutoObservable(this);
    this.form = OfferPopupStore.initialForm();
  }

  static initialForm() {
    return new FormGroup<any>({
      email: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars ',
              ValidationEventTypes.Error,
            ),
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              'Wrong Email format',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
    });
  }

  changeStatus(
    status:
      | OFFER_STATUSES.SUCCESS_SENDING_STEP
      | OFFER_STATUSES.SUCCESS_SENDING_STEP,
  ): void {
    this.status = status;
  }

  get formValues() {
    const { email } = this.form.controls;
    return {
      email: email.value,
    };
  }

  resetForm(): void {
    OfferPopupStore.initialForm();
  }

  disableForm(isDisable: boolean): void {
    this.disable = isDisable;
  }

  async fetchGiftPromoCodeData() {
    this.giftPromoCodeData = await getPromoActionParam().catch((e) => null);
  }

  get isGiftPromoCodeAvailable() {
    return !!this.giftPromoCodeData;
  }

  get giftPromoCodeDiscount() {
    const result = `$${this.giftPromoCodeData?.discountValue}%`;
    return this.giftPromoCodeData?.discountType
      ? result.slice(1)
      : result.slice(0, -1);
  }

  get giftPromoCodeMinPrice() {
    return `$${this.giftPromoCodeData?.minPrice}`;
  }

  get giftPromoCodeMinPriceValue() {
    return this.giftPromoCodeData?.minPrice;
  }
}
