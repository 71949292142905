import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as qs from 'qs';
import { ScrollUpButton } from 'components/common/ScrollUpButton';
import { Rings } from './components/Catalog';
import { ComparePanel } from '../Compare/components/ComparePanel';

import './WeddingRing.scss';

/**
 * Page Component
 * @returns {*}
 * @constructor
 */

export const WeddingRingsCatalogPage = withRouter(
  observer((props: RouteComponentProps) => {
    /**
     * Replace url by catalog params
     */
    const historyReplace = (query: unknown) => {
      const { history, match } = props;
      history.replace(
        match.path + '?' + qs.stringify(query, { arrayFormat: 'brackets' }),
      );
    };

    return (
      <div className={`catalog-page grid`}>
        <ul className="grid-area-breadcrumbs">
          <li>search inventory</li>
        </ul>

        <Rings historyReplace={historyReplace} />

        <ComparePanel />

        <ScrollUpButton />
      </div>
    );
  }),
);
