import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { action, flowResult, observable } from 'mobx';
import { useHistory } from 'react-router-dom';
import {
  ProductCategories,
  RING_CATEGORIES,
  JEWELRY_CATEGORIES,
  DIAMOND_CATEGORIES,
} from 'root/ProductTypes';
import Favorite from 'root/store/favorite';
import rootStore from 'root/store';
import { CompareImpl } from 'root/store/compare';
import CartStore from 'root/store/cart';
import { RingImpl } from 'root/store/rings/one.store';
import { ROUTER_PATHS } from 'root/routes/constants';
import useBlockRoutingIfThereIsAnOpenSet from 'hooks/useBlockRoutingIfThereIsAnOpenSet';
import hintForProductSetStore from 'components/HintForProductSet/HintForProductSet.store';
import { TProductFromHint } from 'components/HintForProductSet/HintForProductSet.types';
import {
  LevelPropductForSet,
  REMOVE_CHILD,
} from 'pages/ShoppingCart/constants';
import { getPathnameByCategories, joinClasses } from 'lib/utils';
import { PreviewStoreImpl } from 'components/app/Preview/PreviewTypes';
import StopCreatingASetPopup from 'components/common/ModalPopup/popups/StopCreatingASetPopup';
import { ProductDetailPageImpl } from '../../ProductDetailPage.types';
import AddEngravePopup from '../AddEngravePopup';
import AddRingPopup from '../AddRingPopup';
import AddDiamondPopup from '../AddDiamondPopup';
import Icon from 'components/common/Icon';
import AddSetToCartPopup from '../AddSetToCartPopup';
import { StoneImpl } from 'root/store/stones/one.store';
import * as qs from 'qs';
import { useLocation } from 'react-router';
import { convertQueryDataToBase64 } from '../../../../utils';

export interface ProductActionsProps {
  compare: CompareImpl;
  cart: CartStore;
  productId: number;
  favorite: Favorite;
  productStore: ProductDetailPageImpl;
  cartPreview: PreviewStoreImpl;
}

const {
  account: { user },
} = rootStore;

const ProductActions = (props: ProductActionsProps) => {
  const { cart, cartPreview, productId, productStore, compare, favorite } =
    props;
  const { pathname, search } = useLocation();
  const history = useHistory();
  const {
    product,
    customizations,
    settings,
    engraving,
    engravingSettings,
    formatPrice,
    choosedPrice,
    setIsShowEngravingTooltip,
  } = productStore;
  const { visiblePopup: visibleBlockRouterPopup, releaseBlock } =
    useBlockRoutingIfThereIsAnOpenSet(
      pathname.includes(ROUTER_PATHS.DIAMONDS_CATALOG_PATH)
        ? ROUTER_PATHS.DIAMONDS_CATALOG_PATH
        : ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    );
  const visibleDiamondPopup = observable.box(false);
  const visibleEngravePopup = observable.box(false);
  const visibleAddRingPopup = observable.box(false);
  const visibleSetToCartPopup = observable.box(false);
  const isNeededEngrave =
    !!engraving && !Object.values(engravingSettings).filter((i) => !!i).length;
  const currentItemFromCart = cart.getCartItemById(
    productId,
    productStore?.customizations,
  );
  const activeProductForSet =
    hintForProductSetStore?.product || ({} as TProductFromHint);
  const isDiamondsCategory = product.categories.some((category) =>
    DIAMOND_CATEGORIES.includes(category.alias as ProductCategories),
  );
  const isJewelryCategory = product.categories.some((category) =>
    [...RING_CATEGORIES, ...JEWELRY_CATEGORIES].includes(
      category.alias as ProductCategories,
    ),
  );
  const productPath = getPathnameByCategories(
    activeProductForSet?.categories || [],
  );
  const queyParams = qs.parse(search.slice(1), { parseArrays: true });
  const { paramsForSet } = queyParams as any;

  useEffect(() => {
    if (currentItemFromCart) {
      productStore.setViewFromCart(true);
      if (currentItemFromCart.customizations) {
        productStore.setStoneForSet(
          currentItemFromCart.customizations?.stones
            ? (currentItemFromCart.customizations?.stones[0] as any)
            : null,
        );

        productStore.replaceCustomizations(currentItemFromCart.customizations);
      }
    } else {
      productStore.setViewFromCart(false);
    }
  }, [currentItemFromCart]);

  const displayAddText = () => {
    if (paramsForSet?.autoAddToCard === 'false' && paramsForSet?.fromItemType) {
      return paramsForSet.fromItemType;
    } else {
      return 'Cart';
    }
  };
  const doesCompareHasItem = (): boolean => compare.exist(productId);

  const doesFavoriteHasItem = (): boolean => favorite.exist(productId);

  const removeFromCart = () => {
    currentItemFromCart && cart.remove(currentItemFromCart.guid, REMOVE_CHILD);
    cartPreview.showPreview(true);
  };

  const handleRemoveSet = () => {
    hintForProductSetStore.removeSet();
    releaseBlock && releaseBlock();
  };

  const closeHintAfterChooseSetProduct = () => {
    hintForProductSetStore.removeHintForDisplaying();
  };

  const addToCart = async (guid?: string | null) => {
    cart.put({ id: product.id, quantity: 1, customizations, guid } as any);
    cartPreview.showPreview(true);
  };

  const addExtraItemToCard = async () => {
    if (productStore.setWithItem && !hintForProductSetStore.product) {
      const { id } = productStore.product || {};
      const { customizations, setWithItem } = productStore;

      await flowResult(
        cart.put({
          id,
          quantity: 1,
          customizations: { ...customizations, stones: [setWithItem] },
        } as any),
      );
      history.replace(window.location.pathname, undefined);
      cart.find();
      cartPreview.showPreview(true);
      return;
    } else {
      const currentProductParams = {
        stoneForSet: product,
      };
      if (hintForProductSetStore?.level === LevelPropductForSet.Parent) {
        const { id } = activeProductForSet || {};
        const { customizations } = hintForProductSetStore;

        await flowResult(
          cart.put({
            id,
            quantity: 1,
            customizations: {
              ...customizations,
              stones: [currentProductParams.stoneForSet],
            },
          } as any),
        );
        const product = id && cart.getCartItemById(id, customizations);

        hintForProductSetStore?.customizations &&
          productStore.replaceCustomizations(
            hintForProductSetStore.customizations as any,
          );
        hintForProductSetStore.removeSet();
        product &&
          product &&
          productPath &&
          history.replace(`${productPath}/${product.id}`, {
            saveCustomization: true,
          });
        cart.find();
        cartPreview.showPreview(true);
        return;
      }

      if (hintForProductSetStore?.level === LevelPropductForSet.Child) {
        const currentProductParams = {
          id: productStore.product.id,
          quantity: 1,
          customizations: {
            ...customizations,
            stones: [hintForProductSetStore.product],
          },
        };
        history.replace(window.location.pathname);

        hintForProductSetStore.removeSet();
        await flowResult(cart.put(currentProductParams as any));
        cart.find();
        cartPreview.showPreview(true);
      }
    }
  };

  const getParams = () => {
    return {
      paramsForSet: {
        setWith: [product.id],
        customizations: hintForProductSetStore.customizations
          ? convertQueryDataToBase64(hintForProductSetStore.customizations)
          : null,
      },
    };
  };

  const getQueryString = () => {
    return qs.stringify(getParams(), {
      arrayFormat: 'brackets',
    });
  };

  const handleCart = action((e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    const isShowEngravePopup = isNeededEngrave && !product.settings?.length;

    if (paramsForSet?.autoAddToCard === 'false' && paramsForSet?.fromItemType) {
      closeHintAfterChooseSetProduct();
      history.push({
        pathname: `${productPath}/${paramsForSet.fromItemId}`,
        search: getQueryString(),
      });
      return;
    } else {
      if (currentItemFromCart && (isDiamondsCategory || isJewelryCategory)) {
        removeFromCart();
        productStore.setViewFromCart(false);
        return;
      }

      if (isShowEngravePopup) {
        visibleEngravePopup.set(true);
        return;
      }

      if (hintForProductSetStore?.product || productStore?.setWithItem) {
        void visibleSetToCartPopup.set(true);
        return;
      }

      if (isDiamondsCategory) {
        visibleAddRingPopup.set(true);
        return;
      }

      if (
        settings &&
        Object.values(settings.filters)?.length > 0 &&
        !productStore?.setWithItem
      ) {
        visibleDiamondPopup.set(true);
        return;
      }

      void addToCart();
    }
  });

  const showEngravingTooltip = () => {
    setIsShowEngravingTooltip(true);
  };

  const toggleCompare = () => {
    compare.toggle(product.id);
  };

  const toggleFavorite = () => {
    favorite.toggle(product as any);
  };

  const handleGoToHint = () => {
    history.push(`/hint/${product.id}`);
  };

  return (
    <>
      <div className="product-detail-page__product-price">
        <div className="btn-wrap action-wrap row">
          <div
            className="btn btn_text-big btn_px-0 col-lg-6 jc-start"
            onClick={toggleCompare}
          >
            <Icon id="compare" className="icon_mr-xs" />
            <span>
              {`${doesCompareHasItem() ? 'Added' : 'Add'} to Compare`}
            </span>
          </div>

          {user.isAuth && (
            <div
              className="btn btn_text-big btn_px-0 col-lg-6 jc-start"
              onClick={toggleFavorite}
            >
              <Icon id="favorite" className="icon_mr-xs" />
              <span>
                {`${doesFavoriteHasItem() ? 'Added' : 'Add'} to Favorite`}
              </span>
            </div>
          )}

          <div
            className="btn btn_text-big btn_px-0 col-lg-6 jc-start"
            onClick={handleGoToHint}
          >
            <Icon id="envelope" className="icon_mr-xs" />
            <span>Send a Hint</span>
          </div>
        </div>

        {productStore.setWithItem && (
          <div className="btn-wrap">
            <div className="product-detail-page__product-set-prices">
              <ul className="product-detail-page__product-set-prices-list">
                <li>
                  <span>Diamond cost</span>
                  <span>
                    <h2 className="diamond-price">
                      {productStore.currency.format(
                        productStore.setWithItem.price,
                      )}
                    </h2>
                  </span>
                </li>
                <li>
                  <span>Ring cost</span>
                  <span>
                    <h2 className="diamond-price">
                      {productStore.currency.format(productStore.choosedPrice)}
                    </h2>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="btn-wrap">
          <div className="product-detail-page__product-price-wrap">
            <button
              className={joinClasses([
                'btn btn_bordered-dark-gray btn_rounded btn_text-big btn_my-md',
                product.outOfStock && 'disabled',
                currentItemFromCart &&
                  !(isDiamondsCategory || isJewelryCategory) &&
                  'active',
                currentItemFromCart &&
                  (isDiamondsCategory || isJewelryCategory) &&
                  'added',
              ])}
              onClick={handleCart}
              disabled={
                !!currentItemFromCart &&
                !(isDiamondsCategory || isJewelryCategory)
              }
            >
              <Icon id="shopping" className="icon_mr-xs" />
              {(isDiamondsCategory || isJewelryCategory) &&
              currentItemFromCart ? (
                <span>Remove from Cart</span>
              ) : (
                <span>{`${
                  currentItemFromCart
                    ? 'Added'
                    : productStore.setWithItem ||
                      hintForProductSetStore.level === LevelPropductForSet.Child
                    ? 'Add Set'
                    : 'Add'
                } to ${displayAddText()}`}</span>
              )}
            </button>

            {product.outOfStock ? (
              <h2 className="diamond-price no-item">Product out of stock</h2>
            ) : (
              <div>
                <h2 className="diamond-price diamond-price_medium">
                  {productStore.setWithItem
                    ? productStore.currency.format(
                        Number(productStore.choosedPrice) +
                          Number(productStore.setWithItem.price),
                      )
                    : formatPrice}
                </h2>
              </div>
            )}
          </div>
        </div>

        <AddDiamondPopup
          product={product as unknown as RingImpl}
          formatPriceRing={formatPrice}
          choosedPrice={choosedPrice}
          customizations={customizations}
          visiblePopup={visibleDiamondPopup}
          extraSettings={settings}
          isNeededEngrave={isNeededEngrave}
          showEngravingTooltip={showEngravingTooltip}
        />

        <AddEngravePopup
          product={product}
          visiblePopup={visibleEngravePopup}
          addToCart={addToCart}
          showEngravingTooltip={showEngravingTooltip}
        />

        <AddRingPopup
          product={product as unknown as StoneImpl}
          choosedPrice={choosedPrice}
          visiblePopup={visibleAddRingPopup}
          addToCart={addToCart}
        />

        <AddSetToCartPopup
          productStore={productStore}
          stonesForSet={productStore.setWithItem}
          productFromDetailPage={product as any}
          formatProductPrice={formatPrice}
          customizationsParams={customizations}
          choosedPrice={choosedPrice}
          visiblePopup={visibleSetToCartPopup}
          addToCart={addExtraItemToCard}
        />
      </div>
      {!!activeProductForSet?.id && (
        <StopCreatingASetPopup
          productParams={activeProductForSet}
          visiblePopup={visibleBlockRouterPopup}
          removeSet={handleRemoveSet}
        />
      )}
    </>
  );
};

export default observer(ProductActions);
