import React from 'react';
import Svg from '../Svg';

interface IFileCertificateIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function FileCertificateIcon(
  props: IFileCertificateIconProps,
): JSX.Element {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <path
        d="M12.648 2.797 10.68.852C10.469.64 10.188.5 9.906.5H5.102A1.141 1.141 0 0 0 4 1.648V3.5h1.102V1.648h3.75v2.438a.57.57 0 0 0 .562.562h2.461v6.75H6.25V12.5h5.602c.632 0 1.148-.492 1.148-1.102V3.594c0-.281-.14-.586-.352-.797Zm-2.671.726V1.72l1.804 1.804H9.977ZM6.789 8.445a.808.808 0 0 0 .188-.68c-.141-.492-.141-.421 0-.89.046-.258-.024-.516-.188-.68-.351-.351-.328-.304-.445-.773a.729.729 0 0 0-.492-.516c-.47-.117-.399-.093-.75-.445-.188-.164-.446-.234-.68-.164-.469.117-.399.117-.867 0a.727.727 0 0 0-.68.164c-.352.351-.281.328-.75.445a.729.729 0 0 0-.492.516c-.14.469-.094.422-.446.773-.164.164-.234.422-.187.68.14.469.14.398 0 .89a.808.808 0 0 0 .188.68c.351.352.304.282.445.774a.74.74 0 0 0 .492.492c.328.094.258.07.352.117V12.5l1.5-.75 1.5.75V9.828c.117-.047.046-.023.375-.117a.74.74 0 0 0 .492-.492c.14-.492.093-.422.445-.774ZM4 8.75a1.48 1.48 0 0 1-1.5-1.5c0-.82.656-1.5 1.5-1.5.82 0 1.5.68 1.5 1.5 0 .844-.68 1.5-1.5 1.5Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
