import * as React from 'react';
import { observer } from 'mobx-react';
import { TableParamsImpl } from '../../store';
import { CartItemRingImpl } from 'root/store/cart/item.store';

export interface WeddingRingTemplateProps {
  product: CartItemRingImpl;
  property: TableParamsImpl;
}

export const WeddingRingTemplate = observer(
  (props: WeddingRingTemplateProps) => {
    const { product, property } = props;
    return (
      <>
        {(() => {
          switch (property.key) {
            case 'profile':
              return product.profile;
            case 'size':
              return product.size;
            case 'weight':
              return product.weight;
            case 'width':
              return product.width;
            case 'gender':
              return product.gender;
            case 'metal':
              return product.metal;
            case 'primaryStoneShape':
              return product.primaryStoneShape;
            case 'primaryStoneType':
              return product.primaryStoneType;
            case 'primaryStoneSize':
              return product.primaryStoneSize;
            case 'state':
              return product.state;
            case 'initialNumber':
              return product.initialNumber;
            default:
              return null;
          }
        })()}
      </>
    );
  },
);
