import React from 'react';
import withHeader from 'components/app/Header/hoc/withHeader';
import { withRouter } from 'react-router';
import MainPage, { IMainPageProps } from './MainPage';
import rootStore from 'root/store';
import { observer } from 'mobx-react';

export default withRouter(
  withHeader({ className: 'header_main-page' })(
    observer((props: IMainPageProps) => {
      const {
        browserWindowType: {
          mediaQuery: { isMobile },
        },
      } = rootStore;

      return <MainPage {...props} isMobile={isMobile} />;
    }),
  ),
);
