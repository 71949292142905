import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function ClarityDiamond02(props: IProps): JSX.Element {
  return (
    <Svg width="104" height="72" viewBox="0 0 104 72" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M51.95 71 103 26.554l-.302-5.656-9.465-10.202-17.42-7.374L51.95 1 28.187 3.323l-17.52 7.374-9.365 10.202L1 26.555 51.95 71Z"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m51.95 71 4.934-12.929 13.593-18.687L87.796 30.9l3.625-11.111-6.847-10.404-8.76-6.06"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m84.574 9.385-8.56 5.252-24.064 2.121 15.909 7.374L87.796 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m76.015 14.637-8.156 9.495-15.91 11.515M91.42 19.788l-15.405-5.151M51.95 35.647l18.527 3.737M51.95 40.092l18.325-6.06 16.111 1.615 10.472-6.565L103 26.557M96.858 29.082 87.796 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m103 26.555-11.58-6.768 5.438 9.293M93.233 10.697 103 26.556M84.574 9.385l8.66 1.313M67.859 24.13l2.416 9.9M86.386 35.647 72.39 53.222M56.883 58.07l29.503-22.423M51.95 40.09l4.934 17.98M51.95 71l-4.833-12.929-13.594-18.687L16.204 30.9 12.58 19.789l6.746-10.404 8.86-6.06"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m19.326 9.385 8.559 5.252 24.065 2.121-15.809 7.374L16.205 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m27.885 14.637 8.256 9.495L51.95 35.647M12.58 19.788l15.305-5.151M51.95 35.647l-18.427 3.737M51.95 40.092l-18.225-6.06-16.111 1.615-10.573-6.565L1 26.557M7.042 29.082l9.162 1.818"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m1 26.555 11.58-6.768L7.04 29.08M10.666 10.697 1 26.556M19.326 9.385l-8.66 1.313M36.141 24.13l-2.416 9.9M17.614 35.647 31.61 53.222M47.117 58.07 17.614 35.648M51.95 40.09l-4.834 17.98"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m19.8 19.3 1.6 6 2.8-1.2-1.3-5.5-3.1.7Z"
        fill="#54A97E"
      />
    </Svg>
  );
}
