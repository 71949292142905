import { ROUTER_PATHS } from 'root/routes/constants';
import { ProductCategories } from 'root/ProductTypes';
import { IDropDownMenuHeader } from './Header.types';

export const DROPDOWN_MENU_LIST: IDropDownMenuHeader = {
  [ProductCategories.ENGAGEMENT_RINGS]: {
    title: 'Engagement Rings',
    pathname: ROUTER_PATHS.RINGS_ENGAGEMENT_PATH,
    stateCategory: ProductCategories.ENGAGEMENT_RINGS,
    id: 'engagement',
  },
  [ProductCategories.DIAMONDS]: {
    title: 'Diamonds',
    pathname: ROUTER_PATHS.DIAMONDS_PATH,
    stateCategory: null,
    id: 'diamond',
  },
  [ProductCategories.WEDDING_RINGS]: {
    title: 'Wedding Rings',
    pathname: ROUTER_PATHS.RINGS_WEDDING_PATH,
    stateCategory: ProductCategories.WEDDING_RINGS,
    id: 'wedding',
  },
  [ProductCategories.JEWELRY]: {
    title: 'Fine Jewelry',
    pathname: ROUTER_PATHS.JEWELRY_PATH,
    stateCategory: null,
    id: 'jewelry',
  },
};
