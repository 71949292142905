import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import CarouselStore, { CarouselStoreImpl } from 'root/store/carousel';
import { CAROUSEL_ACTIVE_ITEM } from '../constants';

export type TableParamsImpl = {
  label: string;
  key: string;
  isValuesEqual?: boolean;
};

export default class CompareStore {
  isIdenticalParametersHidden = false;
  tableParams: IObservableArray<TableParamsImpl> = observable.array([], {
    deep: false,
  });

  carousel: CarouselStoreImpl;

  constructor() {
    makeAutoObservable(this);
    this.carousel = new CarouselStore(CAROUSEL_ACTIVE_ITEM);
  }

  setIdenticalParametersHidden(value: boolean): void {
    this.isIdenticalParametersHidden = value;
  }

  setTableParams(value: TableParamsImpl[]): void {
    this.tableParams.replace(value);
  }
}
