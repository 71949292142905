import React from 'react';
import Svg from '../Svg';

interface SpellCheckIcom {
  children?: React.ReactNode;
}

export default function InsideBand(props: SpellCheckIcom): JSX.Element {
  return (
    <Svg width="21" height="19" viewBox="0 0 21 19" fill="none" {...props}>
      <path
        d="M10 3C3.39844 3 0 5.53906 0 8V12.4922C0 15.5391 4.45312 18 10 18C15.5078 18 20 15.5391 20 12.4922V8C20 5.53906 16.5625 3 10 3ZM18.75 12.4922C18.75 14.5234 15.1562 16.75 10 16.75C4.80469 16.75 1.25 14.5234 1.25 12.4922V10.5391C2.77344 11.9453 5.70312 13 10 13C14.2578 13 17.1875 11.9453 18.75 10.5391V12.4922ZM3.47656 10.5C5.07812 9.75781 7.38281 9.25 10 9.25C12.5781 9.25 14.8828 9.75781 16.4844 10.5C14.8828 11.2812 12.5781 11.75 10 11.75C7.38281 11.75 5.07812 11.2812 3.47656 10.5ZM17.7344 9.75781C16.0547 8.74219 13.4766 8 10 8C6.48438 8 3.90625 8.74219 2.22656 9.75781C1.60156 9.25 1.25 8.66406 1.25 8C1.25 5.92969 5.15625 4.25 10 4.25C14.8047 4.25 18.75 5.92969 18.75 8C18.75 8.66406 18.3594 9.25 17.7344 9.75781Z"
        fill="#4D4048"
      />
      <path
        d="M18.9992 10C11.7992 13.6 3.16591 11.5 0.999241 10C-0.00074482 11.003 0.499166 12.8654 0.999262 13.8671C3.41479 18.7053 13.9994 18.3701 18.4994 14.8671C20.8327 12.7006 19.4992 10.5004 18.9992 10Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
