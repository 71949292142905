import React, {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useMemo,
  forwardRef,
} from 'react';
import { FormControl } from '@quantumart/mobx-form-validation-kit';
import { joinClasses } from 'lib/utils';
import Icon from '../Icon';

import './InputForm.scss';

interface PropsImpl {
  type?: string;
  name: string;
  dataForm?: string;
  className?: string;
  data: FormControl<string>;
  value?: string | number;
  errors?: string[];
  showErrors: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  onIconClick?: (e: any) => void;
  iconId?: string;
}

export default forwardRef<HTMLInputElement, PropsImpl>(function InputForm(
  props: PropsImpl,
  ref?,
): JSX.Element {
  const {
    type = 'text',
    name,
    dataForm,
    className,
    data,
    value = '',
    errors,
    showErrors = true,
    onChange,
    onBlur,
    disabled,
    label,
    onIconClick = () => {
      ('');
    },
    iconId,
  } = props;

  const inputValue = value || data.value;
  const inputErrors = useMemo(
    () => errors || data.errors.map((error) => error.message),
    [errors, data.errors],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>): void => {
      data.setTouched(true);
      data.setFocused(false);

      onBlur && onBlur(event);
    },
    [data, onBlur],
  );

  const isShowErrors = showErrors && inputErrors && inputErrors.length;

  return (
    <label
      className={joinClasses([
        'custom-input form-label',
        iconId && 'custom-input__svg',
        iconId && isShowErrors && 'custom-input__svg_error',
        disabled && 'custom-input_disabled',
      ])}
    >
      <input
        ref={ref}
        type={type}
        name={name}
        data-form={dataForm}
        className={joinClasses([
          'custom-input-field',
          className,
          isShowErrors && 'invalid',
        ])}
        value={inputValue}
        onChange={onChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <div className="label-text">{label}</div>
      {isShowErrors &&
        inputErrors.map((error) => (
          <div
            key={`${error}`}
            className="form-label__error form-label__error_blocky"
          >
            {error}
          </div>
        ))}

      {iconId && <Icon id={iconId} onClick={onIconClick} />}
    </label>
  );
});
