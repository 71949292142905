import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { getImg } from 'lib/utils';
import LightLoader from './LightLoader';

interface IImgLoaderProps {
  url?: string;
  folder?: string;
  images?: string[];
  name?: string;
  format?: string;
}

export default React.memo(function ImgLoader(
  props: PropsWithChildren<IImgLoaderProps>,
) {
  const [isLoaded, setLoaded] = useState(false);

  const { folder, images, name, format, url } = props;
  const src = useMemo(
    () =>
      url || getImg(folder || '', images || [''], name || '', format || 'webp'),
    [folder, images, name, format, url],
  );

  useEffect(() => {
    setLoaded(false);
    const image = new Image();
    image.src = src;
    image.onload = () => setLoaded(true);
  }, [src]);

  return isLoaded ? <>{props.children}</> : <LightLoader />;
});
