import axios from './index';
import { AxiosPromise } from 'axios';

/**
 * API path
 * @type {string}
 */
const path = '/auth';

/**
 * Sign In to Profile
 * @param {Object} data
 * @returns {AxiosPromise} which contains JWT token
 */
export const signIn = (data: unknown): AxiosPromise =>
  axios.post(`${path}/signin`, data);

/**
 * Sign Up Account
 * @param {Object} data
 */
export const signUp = (data: unknown): AxiosPromise =>
  axios.post(`${path}/signup`, data);

/**
 * resend Activation Email
 * @param {Object} data
 */
export const resendActivation = (data: { email: string }): AxiosPromise =>
  axios.post(`${path}/resendactivation`, data);

/**
 * Activate Account
 * @param {Object} data
 */
export const activate = (token: string): AxiosPromise =>
  axios.get(`${path}/activate?token=${token}`);

/**
 * Recovery Password
 * @param {Object} data
 */
export const recoveryPassword = (data: { email: string }): AxiosPromise =>
  axios.post(`${path}/recovery`, data);

/**
 * Activate Account to forgot password logic
 * @param {Object} data
 */
export const activateForgotPassword = (token: string): AxiosPromise =>
  axios.get(`${path}/forgot/${token}`);

/**
 * Send new password
 * @param {Object} data
 */
export const sendNewPassword = (data: {
  password: string;
  token: string;
}): AxiosPromise => axios.post(`${path}/Forgot`, data);
