import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function DiamondSize050(props: IProps): JSX.Element {
  return (
    <Svg width="40" height="39" viewBox="0 0 40 39" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.4 19.5C.4 8.713 9.113 0 19.9 0s19.5 8.713 19.5 19.5S30.687 39 19.9 39C9.214 39 .4 30.186.4 19.5ZM19.9 1.206C9.78 1.206 1.606 9.38 1.606 19.5c0 10.02 8.274 18.294 18.294 18.294 10.12 0 18.294-8.173 18.294-18.294 0-10.12-8.174-18.294-18.294-18.294Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.161.298a.402.402 0 0 1 .045.566l-4.974 5.819 4.828 2.095a.402.402 0 0 1-.013.743l-13 5.1L1.272 19.8a.402.402 0 1 1-.537-.598l5.83-5.227a.402.402 0 0 1 .121-.075l12.16-4.77-4.433-1.925a.402.402 0 0 1-.146-.63L19.594.342a.402.402 0 0 1 .567-.044Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.732 1.53a.402.402 0 0 1 .512.248l1.709 4.925c.028.083.03.172.003.255l-1.608 5.026a.407.407 0 0 1-.005.014l-.003.006-2.915 7.64a.402.402 0 0 1-.718.067l-3.15-5.119-4.306-1.86a.402.402 0 1 1 .319-.737l4.423 1.91c.075.032.14.087.183.158l2.789 4.532 2.62-6.867 1.564-4.888-1.665-4.798a.402.402 0 0 1 .248-.512Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.463 5.633a.402.402 0 0 1 .316-.101l7.84.905a.402.402 0 1 1-.092.798L7.14 6.383l.095 7.886a.402.402 0 0 1-.804.01l-.1-8.343a.402.402 0 0 1 .132-.303ZM19.636.3a.402.402 0 0 1 .567.04l5.428 6.231a.402.402 0 0 1-.146.634l-4.523 1.927 12.152 4.767a.402.402 0 0 1 .122.075l5.83 5.227a.402.402 0 1 1-.537.598l-5.776-5.178-13-5.1a.402.402 0 0 1-.01-.744l4.918-2.095L19.597.867A.402.402 0 0 1 19.636.3Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.06 1.528a.402.402 0 0 1 .258.507L25.75 6.84l1.467 4.89 2.709 6.862 2.697-4.524a.402.402 0 0 1 .186-.164l4.422-1.91a.402.402 0 1 1 .32.739l-4.304 1.858-3.05 5.115a.402.402 0 0 1-.719-.058l-3.015-7.64a.358.358 0 0 1-.011-.031L24.943 6.95a.403.403 0 0 1 .003-.24l1.608-4.926a.402.402 0 0 1 .507-.257Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.337 5.633a.402.402 0 0 1 .133.303l-.1 8.343a.402.402 0 0 1-.805-.01l.095-7.885-7.285.851a.402.402 0 1 1-.094-.798l7.74-.905a.402.402 0 0 1 .316.1ZM.704 19.231a.402.402 0 0 1 .568-.03l5.774 5.177 12.998 4.999a.402.402 0 0 1 .022.742l-4.84 2.191 4.98 5.825a.402.402 0 1 1-.612.523l-5.327-6.232a.402.402 0 0 1 .14-.628l4.452-2.015-12.17-4.681a.404.404 0 0 1-.124-.076l-5.83-5.227a.402.402 0 0 1-.031-.568Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.086 19.1a.402.402 0 0 1 .339.255l2.915 7.538a.42.42 0 0 1 .008.025l1.608 5.126a.403.403 0 0 1-.004.255l-1.709 4.825a.402.402 0 1 1-.758-.269l1.664-4.698-1.564-4.986-2.618-6.77-2.791 4.536a.402.402 0 0 1-.183.159l-4.423 1.91a.402.402 0 1 1-.319-.739l4.306-1.86 3.15-5.118a.402.402 0 0 1 .38-.19Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.838 24.324c.222.003.4.185.397.407l-.095 7.886 7.387-.852a.402.402 0 0 1 .092.799l-7.84.904a.402.402 0 0 1-.448-.404l.1-8.343a.402.402 0 0 1 .407-.397ZM39.096 19.231a.402.402 0 0 1-.03.568l-5.83 5.227a.404.404 0 0 1-.124.076L20.95 29.779l4.541 2.018a.402.402 0 0 1 .14.632l-5.428 6.232a.402.402 0 0 1-.606-.528l5.07-5.822-4.93-2.191a.402.402 0 0 1 .019-.743l12.998-5 5.775-5.177a.402.402 0 0 1 .567.031Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 19.099a.402.402 0 0 1 .376.195l3.05 5.115 4.303 1.858a.402.402 0 0 1-.319.738l-4.422-1.91a.402.402 0 0 1-.186-.163l-2.7-4.528-2.706 6.766-1.467 4.987 1.569 4.705a.402.402 0 0 1-.763.255l-1.609-4.825a.403.403 0 0 1-.004-.24l1.508-5.127a.379.379 0 0 1 .012-.036l3.016-7.539a.402.402 0 0 1 .341-.251Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.962 24.324a.402.402 0 0 1 .407.397l.1 8.343a.402.402 0 0 1-.448.404l-7.74-.904a.402.402 0 0 1 .094-.799l7.285.852-.095-7.886a.402.402 0 0 1 .397-.407Z"
        fill="#000"
      />
    </Svg>
  );
}
