import * as React from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IReactionDisposer, reaction } from 'mobx';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';
import * as qs from 'qs';
import * as H from 'history';
import { PAGE } from 'root/store/paginate';
import { CompareImpl } from 'root/store/compare';
import { IProduct } from 'root/store/products/types/product.type';
import { IJewerlyFiltersExtra } from '../../store/jewerlyFiltersExtra.store';
import { JewelryCatalogStore } from '../../store';
import { IFilterJewerlyExtraParams } from '../../types';
import { Table } from '../Table';
import { Panel } from '../Panel';

interface JewelryCatalogProps {
  location: H.Location<{ needToResetFilters?: boolean }>;
  catalog: JewelryCatalogStore;
  compare: CompareImpl;
  filtersExtra: IJewerlyFiltersExtra;
  historyReplace(query: unknown): void;
}

export const Catalog = observer(
  ({
    catalog,
    filtersExtra,
    compare,
    location,
    historyReplace,
  }: JewelryCatalogProps) => {
    let reaction$: IReactionDisposer;
    const { search } = useLocation();

    const [mounted, setMounted] = useState(false); // check first render

    /**
     * Parse params from location search
     */
    const parseLocationSearch = () => {
      if (location.state?.needToResetFilters) {
        catalog.resetCatalog();
        catalog.filters.updateByExtraParams(filtersExtra.filtersExtra);
      }

      const locationSearch: any = location.search
        ? qs.parse(location.search, { ignoreQueryPrefix: true })
        : {};
      const queryFilterParams = locationSearch.filters
        ? { ...catalog.queryParams.filters, ...locationSearch.filters }
        : catalog.queryParams.filters;

      return location.search
        ? {
            ...catalog.queryParams,
            ...qs.parse(location.search, { ignoreQueryPrefix: true }),
            filters: queryFilterParams,
          }
        : null;
    };

    /**
     * Facade load data and change history
     */
    const load = async (): Promise<void> => {
      historyReplace(catalog.queryParams);
      catalog.loadProducts();
    };

    const updateExtraParams = async () => {
      const filters =
        filtersExtra.fetchFilterExtraParams() as IFilterJewerlyExtraParams;
      catalog.filters.updateByExtraParams(filters);
    };

    const initCatalog = async () => {
      await updateExtraParams();

      const query = parseLocationSearch();
      query && catalog.updateCatalog(query);

      reaction$ = reaction(
        () => {
          catalog.loading = true;
          return JSON.stringify(catalog.queryParams);
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        debounce(async () => {
          try {
            await load();
          } catch (e) {}
        }, 800),
        { fireImmediately: true },
      );
    };

    useEffect(() => {
      if (location.state) {
        catalog.filters.reset();
        catalog.filters.categories.push(
          (location.state as { categories: string }).categories,
        );
      }

      void initCatalog();
      setMounted(true);
      return () => {
        reaction$();
        setMounted(false);
      };
    }, []);

    useEffect(() => {
      if (mounted) {
        const query = parseLocationSearch();
        query && catalog.updateCatalog(query);
      }
    }, [search]);

    /**
     * Filter Handle (for reactions)
     */
    const handleFilters = (): void => {
      catalog.pagination.setPage(PAGE);
    };

    /**
     * Order Handle
     * @param {String} property
     * @param {String} direction
     */
    const handleOrder = (property: string, direction: string | null): void => {
      catalog.order.updateOrder(property, direction || '');
    };

    /**
     * Limit pages Handle
     * @param {Number} limit
     */
    const handleLimit = (limit: number): void => {
      catalog.pagination.setLimitPage(limit);
    };

    /**
     * Pagination Handle
     * @param {Number} page
     */
    const handlePagination = (page: number): void => {
      catalog.pagination.setPage(page);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <Panel
          filters={catalog.filters}
          filtersExtra={filtersExtra.filtersExtra}
          handleFilters={handleFilters}
        />
        <Table
          data={catalog.list}
          displaySpinner={catalog.loading}
          order={catalog.order}
          pagination={catalog.pagination}
          compare={compare}
          handleOrder={handleOrder}
          handleLimit={handleLimit}
          handlePagination={handlePagination}
        />
      </>
    );
  },
);
