import { makeAutoObservable } from 'mobx';
import CarouselStore, { CarouselStoreImpl } from 'root/store/carousel';
import { CAROUSEL_ACTIVE_ITEM } from './bestSellersCarousel.constants';
import { fetchBestSellersProducts } from 'service/api/products';

export class BestSellersCarouselStore {
  carousel: CarouselStoreImpl;

  constructor() {
    makeAutoObservable(this);
    this.carousel = new CarouselStore(CAROUSEL_ACTIVE_ITEM);
  }

  async loadBestSellers() {
    const products = await fetchBestSellersProducts();
    this.carousel.update(products.concat(products));
  }
}
