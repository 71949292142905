import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RingDetailResponseData } from 'service/api/apiTypes/catalogsApiTypes';
import { IBrowserWindowType } from 'root/store/infoBrowserWindowType.store';
import ImageItem from 'components/common/ImageItem';
import { getPathnameByCategories } from 'lib/utils';

import './MatchingBandBlock.scss';

interface IMatchingBandBlockProps extends RouteComponentProps {
  matchingBand?: RingDetailResponseData | null;
  browserWindowType: IBrowserWindowType;
}

export function MatchingBandBlock(
  props: IMatchingBandBlockProps,
): JSX.Element | null {
  const {
    matchingBand,
    browserWindowType: {
      mediaQuery: { isMobile, isTablet },
    },
  } = props;

  if (!matchingBand) return null;

  const { photos, metal, size, sku, id, weight, gender, categories } =
    matchingBand;
  const path = `${getPathnameByCategories(categories)}/${id}`;

  return (
    <div className="matching-band-block">
      <p className="matching-band-block__title">
        Looking for the matching band?
      </p>

      <Link to={path} className="matching-band-block__product">
        <ImageItem src={photos[0]} />
        <div className="matching-band-block__info">
          <p className="matching-band-block__subtitle">Sku: {sku}</p>
          <div className="matching-band-block__info-description">
            {metal}
            <span className="matching-band-block__info-delimiter">|</span>
            {gender}
            <span className="matching-band-block__info-delimiter">|</span>
            Weight {weight}
            {!isTablet && !isMobile && (
              <>
                <span className="matching-band-block__info-delimiter">|</span>
                Size {size}
              </>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default withRouter(observer(MatchingBandBlock));
