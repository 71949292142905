import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { CSSTransition } from 'react-transition-group';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SwiperCore, {
  Mousewheel,
  EffectFade,
  EffectFlip,
  Navigation,
  Pagination,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  getCurrentCategory,
  getSuccessResponseOfImage,
  joinClasses,
} from 'lib/utils';
import { ProductCategories } from 'root/ProductTypes';
import { AVAILABLE_SHAPE_IMAGES } from 'utils/constants/stones/diamonds.constants';
import ImageItem from 'components/common/ImageItem';
import LightLoader from 'components/common/Loaders/LightLoader';
import Icon from 'components/common/Icon';
import { ICurrentProductDetail } from '../../ProductDetailPage.types';
import ButtonImgType from './components/ButtonImgType';

import 'swiper/swiper-bundle.css';

interface ProductImageProps extends RouteComponentProps {
  product: ICurrentProductDetail;
  data: string | undefined;
  sprite: React.RefObject<HTMLInputElement>;
  isShowDetails: boolean;
  isOpen3DView: boolean;
  loupeUrl: string;
  setSsOpen3DView: (value: boolean) => void;
}

const DIAMONDS_CATEGORIES = [
  ProductCategories.DIAMONDS,
  ProductCategories.DIAMONDS_LAB_CREATED,
];

const IMAGES_FOLDER = 'shapes/';
SwiperCore.use([Mousewheel, EffectFade, EffectFlip, Navigation, Pagination]);

@observer
class ProductImage extends React.Component<ProductImageProps> {
  activeSwiper = observable.box(0);
  isLoadingImg = observable.box(true);
  isImgGalleryView = observable.box(false);

  state = {
    swiperItems: [],
  };

  async componentDidMount() {
    const {
      product: { photos },
    } = this.props;

    if (Array.isArray(photos)) {
      const swiperItems = [];
      for (const src of photos) {
        const successUrl = await getSuccessResponseOfImage(src);
        successUrl && swiperItems.push(successUrl);
      }

      this.setState({ swiperItems });
    }
  }

  handleClickImageView = (value: boolean) => {
    this.isImgGalleryView.set(value);
  };

  handleClick3DView = (value: boolean) => {
    const { loupeUrl } = this.props;
    const isVideo = loupeUrl
      ?.split('/')
      ?.some((pathChunk) => pathChunk === 'video');

    if (loupeUrl && isVideo && value) {
      window.open(loupeUrl, '_blank');
    } else {
      this.isLoadingImg.set(value);
      this.props.setSsOpen3DView(value);
    }
  };

  isVideoUrl = (url: string): boolean => {
    if (url) {
      return url
        ?.split('.')
        ?.some((pathChunk) => pathChunk.toUpperCase() === 'MP4');
    }
    return false;
  };

  setActiveSwiper = (index: number) => {
    this.activeSwiper.set(index);
  };

  handleLoadIframe = () => {
    this.isLoadingImg.set(false);
  };

  isDiamondCategory = () => {
    const currentCategory = getCurrentCategory(this.props.product.categories);

    return currentCategory.some((category: any) =>
      DIAMONDS_CATEGORIES.includes(category),
    );
  };

  render() {
    const { product, loupeUrl, history, isShowDetails, data, isOpen3DView } =
      this.props;
    const { swiperItems } = this.state;
    const currentPhoto = swiperItems[this.activeSwiper.get()];
    const isShowSwiper = this.isDiamondCategory()
      ? !!swiperItems.length && this.isImgGalleryView.get()
      : swiperItems.length > 1 && !isOpen3DView;
    const shapeName = product?.shape?.split(' ').join('_')?.toLocaleLowerCase();
    const isShowShapeSprite =
      AVAILABLE_SHAPE_IMAGES.includes(shapeName) && isShowDetails;
    const iconId = product?.shape && `${shapeName}_${data}`;
    const needPlayer = this.isVideoUrl(loupeUrl);

    return (
      <div className="product-detail-page__product-image">
        <div className="product-detail-page__product-image-header">
          <a
            className="product-detail-page__back-link"
            title="Cart"
            onClick={() => history.goBack()}
          >
            <Icon id="long_arrow_left" className="icon_mr-sm" />
            BACK
          </a>

          <div className="product-detail-page__view-btns-wrap">
            {this.isDiamondCategory() && !!swiperItems.length && !isOpen3DView && (
              <ButtonImgType
                iconId="image_gallery"
                isActive={this.isImgGalleryView.get()}
                handleClickOpen={() => {
                  this.handleClickImageView(true);
                }}
                handleClickclose={() => {
                  this.handleClickImageView(false);
                }}
              />
            )}

            {!!loupeUrl && !this.isImgGalleryView.get() && (
              <ButtonImgType
                iconId="image_360"
                isActive={isOpen3DView}
                handleClickOpen={() => {
                  this.handleClick3DView(true);
                }}
                handleClickclose={() => {
                  this.handleClick3DView(false);
                }}
              />
            )}
          </div>
        </div>

        {/* TODO: add animation for sprite on enter and exit */}
        <div
          className={joinClasses([
            'product-detail-page__product-images-wrap',
            isOpen3DView && 'product-detail-page__product-images-wrap-3d',
            this.isDiamondCategory() &&
              !this.isImgGalleryView.get() &&
              'product-detail-page__product-images-wrap-stone',
          ])}
        >
          {isOpen3DView ? (
            <div className="product-detail-page__product-images-wrap-3d__container">
              {needPlayer ? (
                <video controls autoPlay>
                  <source src={loupeUrl} type="video/mp4" />
                </video>
              ) : (
                <iframe
                  className={`view${isShowShapeSprite ? '_notDisplay' : ''}`}
                  src={loupeUrl}
                  onLoad={this.handleLoadIframe}
                />
              )}
            </div>
          ) : (
            <CSSTransition in appear timeout={1000} classNames="zoom">
              {this.isDiamondCategory() && !this.isImgGalleryView.get() ? (
                <ImageItem
                  isShowDetails={isShowShapeSprite}
                  imageFolder={IMAGES_FOLDER}
                  images={AVAILABLE_SHAPE_IMAGES}
                  currentImage={product.shape
                    .split(' ')
                    .join('_')
                    ?.toLocaleLowerCase()}
                  needStyle
                />
              ) : currentPhoto ? (
                <ImageItem
                  isShowDetails={isShowShapeSprite}
                  src={currentPhoto}
                  needStyle
                />
              ) : (
                <LightLoader />
              )}
            </CSSTransition>
          )}
        </div>

        {isShowSwiper && !isShowShapeSprite && (
          <div className="product-swiper__container">
            <CSSTransition in appear timeout={1000} classNames="zoom">
              <Swiper
                slidesPerView={1}
                navigation
                longSwipesRatio={0.5}
                longSwipesMs={200}
                pagination={{
                  clickable: true,
                }}
                loop={swiperItems.length > 2}
                className="product-swiper"
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                  },
                  1025: {
                    slidesPerView: 3,
                    shortSwipes: false,
                  },
                }}
              >
                {swiperItems.map((path: string, index: number) => (
                  <SwiperSlide key={path}>
                    <ImageItem
                      className={joinClasses([
                        'product-swiper__image',
                        index === this.activeSwiper.get() &&
                          'product-swiper__image_active',
                      ])}
                      src={path}
                      onClick={() => this.setActiveSwiper(index)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </CSSTransition>
          </div>
        )}

        {isShowShapeSprite && isShowDetails && (
          <div
            className={joinClasses([
              'product-detail-page__product-sprite',
              // isShowDetails &&
              //   sprite.current &&
              //   `product-detail-page__product-sprite_${data}`,
            ])}
          >
            <Icon id={iconId} />
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(ProductImage);
