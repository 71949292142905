import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { gsap } from 'gsap';
import { ProductCategories } from 'root/ProductTypes';
import { RandomProductType } from 'root/store/stones/list.store';
import { fetchRandomProduct } from 'service/api/stones';
import ButtonsBlock from './components/ButtonsBlock';
import { useSlidersComponents } from './components/sliders';
import { joinClasses } from 'lib/utils';

import './Start.scss';

interface IProps {
  sectionHeight: number;
  isMobile: boolean;
}

const Start = ({ sectionHeight, isMobile }: IProps): JSX.Element => {
  const [startImageLoaded, setStartImageLoaded] = useState(false);
  const [currentProduct, setCurrentProduct] = useState('');
  const [productPhoto, setProductPhoto] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [randomProduct, setRandomProduct] = useState<RandomProductType>(
    {} as RandomProductType,
  );

  // hook for slider management
  const [Slider, slidersCount, handleNextSlider, handlePrevSlider] =
    useSlidersComponents();

  const showInfo = async (product: string) => {
    const infoBlock = document.querySelector('.product-info');
    let curPoint;
    if (currentProduct) {
      curPoint = document.querySelector(`#${currentProduct}`);
    }
    if (curPoint) {
      curPoint.classList.remove('active');
    }
    const point = document.querySelector(`#${product}`);
    if (point) {
      point.classList.add('active');
    }

    if (product === 'ring-info')
      setProductCategory(ProductCategories.ENGAGEMENT_RINGS);

    void fetchRandomProduct(ProductCategories.ENGAGEMENT_RINGS).then((data) => {
      setRandomProduct(data);
      setProductPhoto(data.photos[0]);
      setCurrentProduct(product);
      const points: NodeListOf<HTMLElement> =
        document.querySelectorAll('.info-point');
      if (points.length) {
        points.forEach((p) => {
          p.style.zIndex = '0';
        });
      }
      if (infoBlock) {
        if (!infoBlock.classList.contains('active')) {
          infoBlock.classList.add('active');
          gsap.fromTo(
            '.product-info',
            { opacity: 0 },
            { opacity: 1, duration: 2 },
          );
        } else {
          gsap.fromTo(
            '.info-block',
            { opacity: 0.4 },
            { opacity: 1, duration: 2 },
          );
          gsap.fromTo(
            '.image-block',
            { opacity: 0.4 },
            { opacity: 1, duration: 2 },
          );
        }
      }
    });
  };

  const closeInfo = () => {
    const infoBlock = document.querySelector('.product-info');
    if (infoBlock) {
      infoBlock.classList.remove('active');
    }
    gsap.fromTo('.product-info', { opacity: 1 }, { opacity: 0, duration: 2 });
    const point = document.querySelector(`#${currentProduct}`);
    if (point) {
      point.classList.remove('active');
    }
    const points: NodeListOf<HTMLElement> =
      document.querySelectorAll('.info-point');
    if (points.length) {
      points.forEach((p) => {
        p.style.zIndex = '10';
      });
    }
  };

  const handleShowImage = useCallback(() => {
    setStartImageLoaded(true);

    gsap.fromTo('#startImage', { opacity: 0 }, { opacity: 1, duration: 2 });
    gsap.fromTo('.info-point', { opacity: 0 }, { opacity: 1, duration: 2 });
  }, []);

  return (
    <div
      className="main-slide main-slide_start"
      style={isMobile ? {} : { height: `${sectionHeight}px` }}
    >
      <div className="content-main_block" id="start">
        <div className="content-main_block__start">
          <div>
            {!!Slider && <Slider {...{ sectionHeight }} />}
            {slidersCount > 1 ? (
              <div className="pagination-block">
                <ul className="pagination">
                  <li
                    onClick={handlePrevSlider}
                    className={joinClasses(['pagination-item', 'active'])}
                  >
                    <i className="icon-arrow icon-arrow_left"></i>
                  </li>
                  <li
                    onClick={handleNextSlider}
                    className={joinClasses(['pagination-item', 'active'])}
                  >
                    <i className="icon-arrow icon-arrow_right"></i>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
          <ButtonsBlock />
        </div>
      </div>
    </div>
  );
};

export default observer(Start);
