import { IProductCustomizations } from 'root/store/products/types/product.type';
import * as qs from 'qs';
import { convertQueryDataFromBase64 } from 'utils';

export function getConvertBase64QueryParams(search: string): {
  setWithItems: number[] | null;
  customizationParams: IProductCustomizations | null;
} {
  const queryParams = qs.parse(search.slice(1), { parseArrays: true });
  const { paramsForSet } = queryParams as any;
  return {
    setWithItems:
      paramsForSet && !paramsForSet.fromItemType && paramsForSet?.setWith
        ? paramsForSet?.setWith
        : null,
    customizationParams:
      paramsForSet && !paramsForSet.fromItemType && paramsForSet.customizations
        ? convertQueryDataFromBase64(paramsForSet.customizations)
        : null,
  };
}
