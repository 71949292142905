import { ProductCategories } from 'root/ProductTypes';

import { ROUTER_PATHS } from 'root/routes/constants';

export const CAROUSEL_ACTIVE_ITEM = 1;
export const CAROUSEL_ITEM_WIDTH = 1.301;
export const CAROUSEL_ITEM_ACTIVE_WIDTH = 3.385;
export const COEFFICIENT = 0.6505;

export enum JewelryFilterParams {
  STONE_SHAPE = 'stoneShape',
  CATEGORIES = 'categories',
}

export const JEWELRY_TITLES = {
  title: 'Fine Jewelry',
  subTile: 'Categories',
  navigatePath: '/jewelry',
  folderImg: 'fineJewelry',
};

export const JEWELRY_IMG = [
  'pendant',
  'earrings',
  'bracelet',
  'necklace',
  'ring',
];

export const JEWELRY = [
  {
    link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
    params: {
      filters: {
        [JewelryFilterParams.CATEGORIES]: [ProductCategories.PENDANTS],
      },
    },
    title: 'Pendants',
    description: '',
    icon: 'pendant',
    popular: true,
  },
  {
    link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
    params: {
      filters: {
        [JewelryFilterParams.CATEGORIES]: [ProductCategories.EARRINGS],
      },
    },
    title: 'Earrings',
    description: '',
    icon: 'earrings',
    popular: true,
  },
  {
    link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
    params: {
      filters: {
        [JewelryFilterParams.CATEGORIES]: [ProductCategories.BRACELETS],
      },
    },
    title: 'Bracelet',
    description: '',
    icon: 'bracelet',
    popular: true,
  },
  {
    link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
    params: {
      filters: {
        [JewelryFilterParams.CATEGORIES]: [ProductCategories.NECKLACES],
      },
    },
    title: 'Necklace',
    description: '',
    icon: 'necklace',
    popular: true,
  },
  {
    link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
    params: {
      filters: {
        [JewelryFilterParams.CATEGORIES]: [ProductCategories.RINGS],
      },
    },
    title: 'Rings',
    description: '',
    icon: 'ring',
    popular: true,
  },
];
