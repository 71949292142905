import { IObservableArray, makeAutoObservable, observable, toJS } from 'mobx';
import { IFilterRingsExtraParams } from 'pages/EngagementRings/types';
import { FilterRange } from 'root/store/products/filterRange.store';
import { QueryParamsWeddingRingFilters } from '../types';

export enum WeddingRingsFilterParams {
  PRICE = 'price',
  METAL = 'metal',
  STONE_SHAPE = 'stoneShape',
  BAND = 'ringBand',
  CATEGORIES = 'categories',
}

export class WeddingRingsFiltersStore {
  price!: FilterRange;
  metal: IObservableArray = observable([]);
  stoneShape: IObservableArray = observable([]);
  ringBand: IObservableArray = observable([]);
  category = 'WEDDING_RINGS';

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  get queryParams(): WeddingRingsFiltersStore {
    return toJS(this);
  }

  update(filters: QueryParamsWeddingRingFilters): void {
    const { price, metal, ringBand, stoneShape } = filters;
    price && this.price.setRange(Number(price.from), Number(price.to));
    stoneShape && this.stoneShape.replace(stoneShape);
    metal && this.metal.replace(metal);
    ringBand && this.ringBand.replace(ringBand);
  }

  updateByExtraParams(params: IFilterRingsExtraParams) {
    const { price } = params;

    this.price.setRange(Number(price?.min) || 0, Number(price?.max) || 999999);
  }

  reset() {
    this.price = new FilterRange(0, 999999);
    this.metal.clear();
    this.ringBand.clear();
    this.stoneShape.clear();
  }
}
