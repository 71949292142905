import { makeAutoObservable } from 'mobx';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import { fetchJewelryDetail } from 'service/api/jewelry';
import { fetchRing } from 'service/api/rings';
import { fetchStone } from 'service/api/stones';
import Currency, { CurrencyImpl } from 'root/store/currency';
import {
  HintForProductSetImpl,
  ICustomizationsInHintProduct,
  TProductFromHint,
} from './HintForProductSet.types';

interface IUpdateHint {
  customizations?: ICustomizationsInHintProduct;
  level?: LevelPropductForSet;
  price?: number;
}

class HintForProductSetStore implements HintForProductSetImpl {
  level: LevelPropductForSet | null = null;
  product: TProductFromHint = null;
  productType = '';
  customizations?: ICustomizationsInHintProduct;
  currency: CurrencyImpl = new Currency();
  price = 0;
  formatPrice = '';

  constructor() {
    makeAutoObservable(this);
  }

  removeSet() {
    this.level = null;
    this.product = null;
    this.productType = '';
    this.price = 0;
    this.formatPrice = '';
    this.customizations = {};
  }

  removeHintForDisplaying() {
    this.level = null;
    this.product = null;
    this.productType = '';
    this.price = 0;
    this.formatPrice = '';
  }

  update({ customizations, level, price }: IUpdateHint) {
    level && (this.level = level);
    customizations && (this.customizations = customizations);
    this.price = price || 0;
  }

  updatePrice(currency: CurrencyImpl) {
    this.currency.update(currency);
    this.formatPrice = this.currency.format(this.price);
  }

  private async getProductDetails(apiType: string, id: number): Promise<any> {
    switch (apiType) {
      case 'jewelry':
      case 'earrings':
      case 'bracelet':
      case 'pendant':
      case 'necklace':
        return await fetchJewelryDetail(id);
      case 'ring':
      case 'rings':
        return await fetchRing(id);
      case 'diamonds':
        return await fetchStone(id);
      default:
        break;
    }
  }

  async loadProduct(type: string, id: number) {
    this.product = await this.getProductDetails(type, id);
    this.productType = type;
    this.product && this.updatePrice(this.product.currency);
  }
}

export default new HintForProductSetStore();
