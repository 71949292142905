import OvalMeasLengthIcon from './OvalMeasLengthIcon';
import OvalMeasWidthIcon from './OvalMeasWidthIcon';
import OvalMeasDepthIcon from './OvalMeasDepthIcon';
import OvalDepthIcon from './OvalDepthIcon';
import OvalTableIcon from './OvalTableIcon';
import OvalIcon from './OvalIcon';

export default {
  oval_measLength: OvalMeasLengthIcon,
  oval_measWidth: OvalMeasWidthIcon,
  oval_measDepth: OvalMeasDepthIcon,
  oval_depth: OvalDepthIcon,
  oval: OvalIcon,
};
