import * as React from 'react';
import Preview from './Preview';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import rootStore from 'root/store';
import { previewStore } from './PreviewStore';

const { app, cart } = rootStore;

export default withRouter(
  observer((props: RouteComponentProps) => (
    <Preview
      {...props}
      store={previewStore}
      cart={cart}
      countries={app.countries}
    />
  )),
);
