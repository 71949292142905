import React from 'react';
import Svg from '../../Svg';

interface IMarquiseMeasDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function MarquiseMeasDepthIcon(
  props: IMarquiseMeasDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M191.19 62.4v72.179"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.59 135.329h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.14 135.329h83.9"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.05 3.05"
      />
      <path
        d="M189.57 135.329h1.5M99.59 61.65h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.14 61.65h84.02"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.06 3.06"
      />
      <path
        d="M189.69 61.65h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m161.36 69.32-12.47-6.93H50.28l-12.47 6.93-15 14.31v3.46l76.77 48.24 76.78-48.24v-3.46l-15-14.31zM22.81 87.089h153.55M22.81 83.629h153.55"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.58 83.63 77.51 69.32l-3.92-6.93-35.78 6.93 3.7 14.31 36-14.31-5.07 14.31M99.58 83.63l22.08-14.31 3.92-6.93 35.78 6.93-3.7 14.31-36-14.31 5.07 14.31"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m77.51 69.32 22.07-6.93 22.08 6.93M67.04 114.879l-24.15-27.79 47.86 31.78-17.39-31.78M99.58 135.329l-8.83-16.46 8.83-31.78M132.13 114.879l24.15-27.79-47.86 31.78 17.39-31.78M99.58 135.329l8.84-16.46-8.84-31.78"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.39 47.4h152.13"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.1 87.089v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.1 82.77V50.36"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.82 2.82"
      />
      <path
        d="M177.1 48.95v-1.5M21.81 87.089v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.81 82.77V50.31"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.82 2.82"
      />
      <path
        d="M21.81 48.9v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
