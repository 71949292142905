import { RING_BAND } from 'utils/constants/rings';
import { ROUTER_PATHS } from 'root/routes/constants';

export const CAROUSEL_ACTIVE_ITEM = 1;
export const CAROUSEL_ITEM_WIDTH = 1.301;
export const CAROUSEL_ITEM_ACTIVE_WIDTH = 3.385;
export const COEFFICIENT = 0.6505;

export enum WeddingRingsFilterParams {
  BAND = 'ringBand',
}

export const RINGS_TITLES = {
  title: 'Wedding bands',
  state: { category: 'Wedding rings' },
  subTile: 'Rings',
  navigatePath: '/rings/wedding',
  folderImg: 'weddingRings',
};

export const WeddingRingImg = [
  'womens_diamond_band',
  'mens_diamond_band',
  'classic_band',
  'alternative_metal_band',
  'stackable_ring',
];

export const RINGS = [
  {
    params: {
      filters: {
        [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_DIAMOND_WOMAN],
      },
    },
    link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    title: 'Women’s diamond band',
    description: '',
    icon: 'womens_diamond_band',
    forMen: false,
    forWomen: true,
  },
  {
    params: {
      filters: {
        [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_DIAMOND_MAN],
      },
    },
    link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    title: 'Men’s diamond band',
    description: '',
    icon: 'mens_diamond_band',
    forMen: true,
    forWomen: false,
  },
  {
    params: {
      filters: {
        [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_CLASSIC],
      },
    },
    link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    title: 'Classic band',
    description: '',
    icon: 'classic_band',
    forMen: true,
    forWomen: true,
  },
  {
    params: {
      filters: {
        [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_ALT_METAL],
      },
    },
    link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    title: 'Alternative Metal bands',
    description: '',
    icon: 'alternative_metal_band',
    forMen: true,
    forWomen: false,
  },
  {
    params: {
      filters: {
        [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_STACKABLE],
      },
    },
    link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    title: 'Stackable Ring',
    description: '',
    icon: 'stackable_ring',
    forMen: false,
    forWomen: true,
  },
  {
    params: {
      filters: {
        [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_ANNIVERSARY],
      },
    },
    link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    title: 'Anniversary Ring',
    description: '',
    icon: 'anniversary_ring',
    forMen: false,
    forWomen: true,
  },
  {
    params: {
      filters: {
        [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_CARVED],
      },
    },
    link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    title: 'Carved Ring',
    description: '',
    icon: 'carved_ring',
    forMen: true,
    forWomen: false,
  },
  {
    params: {
      filters: {
        [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_ETERNITY],
      },
    },
    link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    title: 'Eternity Ring',
    description: '',
    icon: 'eternity_ring',
    forMen: false,
    forWomen: true,
  },
];
