import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import qs from 'qs';
import { IProduct } from 'root/store/products/types/product.type';
import { CompareImpl } from 'root/store/compare';
import { getIsMobileVersion, joinClasses } from 'lib/utils';
import useClickOutside from 'hooks/useClickOutside';
import ImageItem from 'components/common/ImageItem';
import Icon from 'components/common/Icon';

import './TilesCatalogPage.scss';

/**
 * Page Component
 */

interface TilesCatalogPageProps extends RouteComponentProps {
  products: IProduct[];
  compare: CompareImpl;
}

export const TilesCatalogPage = observer((props: TilesCatalogPageProps) => {
  const [activeItem, setIsActiveItem] = useState<number | undefined>(undefined);
  const isMobileVersion = getIsMobileVersion();
  const { search } = useLocation();

  useClickOutside({
    closestElement: '.product-item_active',
    callback: () => setIsActiveItem(undefined),
  });

  const handleAddToCompare = (product: IProduct, index?: number) => () => {
    const { compare } = props;
    compare.toggle((product as any).productId);
    if (index !== undefined) {
      setTimeout(() => setIsActiveItem(index), 500);
    }
  };

  const doesCompareHasItem = (productId: number): boolean => {
    const { compare } = props;
    return compare.exist(productId);
  };

  const getQueryString = () => {
    const { paramsForSet } = qs.parse(search);

    return paramsForSet
      ? qs.stringify(
          { productForSet: true, paramsForSet },
          { arrayFormat: 'brackets' },
        )
      : '';
  };

  const { products } = props;

  return (
    <>
      <div
        className={joinClasses([
          'tile-container',
          isMobileVersion ? 'tile-container_mobile' : 'tile-container_pc',
        ])}
      >
        {products &&
          (products.length === 0 ? (
            <div className="no-hover">
              <p className="attention-message">
                Found no items matched to search parameters.
              </p>
            </div>
          ) : (
            products.map((product: any, index: number) => {
              return (
                <div
                  key={index}
                  className={joinClasses([
                    'product-item',
                    activeItem === index && 'product-item_active',
                  ])}
                >
                  <div className="product-item_image">
                    <Icon
                      id="compare"
                      className="icon_compare-solid"
                      onClick={handleAddToCompare(product, index)}
                    />
                    <Link
                      to={{
                        pathname: `${props.location.pathname}/${product.productId}`,
                        search: getQueryString(),
                      }}
                    >
                      <ImageItem src={product.photo} />
                    </Link>
                    <Icon id="favorite_solid" className="hide" />
                  </div>

                  <div className="product-item_description">
                    {product.metal}
                  </div>

                  <div className="product-item_sku">{product.description}</div>

                  <div className="product-item_price">
                    <div>${product.minPrice}</div>
                  </div>

                  <div className="product-item_info-hover-block">
                    <div
                      className="btn btn_rounded btn_text-big btn_my-md"
                      onClick={handleAddToCompare(product)}
                    >
                      <Icon id="compare" className="icon_mr-sm" />
                      <span>
                        {doesCompareHasItem(product.productId)
                          ? 'Added to Compare'
                          : 'Compare'}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ))}
      </div>
    </>
  );
});
