import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import {
  ScrollableTabs,
  ScrollableTabsItem,
} from 'components/common/ScrollableTabs';
import ChunkOfQuestions from './components/ChunkOfQuestions';
import { IFAQStore } from './FAQPage.store';

import './FAQPage.scss';

interface FAQPageProps {
  store: IFAQStore;
}

function FAQPage({ store }: FAQPageProps): JSX.Element {
  const { formattedData } = store;

  return (
    <div className="faq-page grid">
      <h1 className="grid-area-page-title page-title">
        FREQUENTLY ASKED QUESTIONS
      </h1>
      <div className="grid-area-content">
        <div className="tabs_container">
          <div className="tabs-links-wrap">
            <ScrollableTabs list={formattedData}>
              {formattedData.map((route) => (
                <ScrollableTabsItem key={route.path.toString()}>
                  <NavLink
                    to={`/faq${route.path}`}
                    className="slider-item__link"
                    activeClassName="active"
                  >
                    {route.topicName}
                  </NavLink>
                </ScrollableTabsItem>
              ))}
            </ScrollableTabs>
          </div>

          <Switch>
            {formattedData.map((route) => (
              <Route key={route.path} path={`/faq${route.path}`}>
                <CSSTransition
                  in
                  appear
                  timeout={1000}
                  classNames="fadeInDown-slow"
                >
                  <ChunkOfQuestions questionsBlock={route.questions} />
                </CSSTransition>
              </Route>
            ))}

            <Redirect to="/faq/about-us" />
          </Switch>
        </div>
      </div>

      <div className="grid-area-attention">
        Please use website portrait mode for a better user experience
      </div>
    </div>
  );
}

export default FAQPage;
