export const SORT_DIAMONDS_BY_VALUES = [
    {name: 'Price \u2191', value: 'price_ASC'},
    {name: 'Price \u2193', value: 'price_DESC'},
    {name: 'Color \u2191', value: 'color_ASC'},
    {name: 'Color \u2193', value: 'color_DESC'},
    {name: 'Clarity \u2191', value: 'clarity_ASC'},
    {name: 'Clarity \u2193', value: 'clarity_DESC'},
    {name: 'Cut \u2191', value: 'cut_ASC'},
    {name: 'Cut \u2193', value: 'cut_DESC'},
];
