import React, { createRef, RefObject } from 'react';
import { Link, RouteComponentProps, NavLink } from 'react-router-dom';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import CompareStore, { TableParamsImpl } from './store';
import CartStore from 'root/store/cart';
import { CartItemImpl } from 'root/store/cart/item.store';
import { CompareImpl } from 'root/store/compare';
import { ROUTER_PATHS } from 'root/routes/constants';
import { ProductCategories, ProductDetailImpl } from 'root/ProductTypes';
import { getCurrentCategory, joinClasses } from 'lib/utils';
import Loader from 'components/common/Loaders/Loader';
import Icon from 'components/common/Icon';
import CompareItem from './components/CompareProducts/components/CompareItem';
import {
  BRACELETS_PROPERTIES_TO_COMPARE,
  CAROUSEL_ACTIVE_ITEM,
  DIAMOND_PROPERTIES_TO_COMPARE,
  EARRINGS_PROPERTIES_TO_COMPARE,
  ITEM_WIDTH_DESKTOP,
  ITEM_WIDTH_TABLET,
  NECKLACES_PROPERTIES_TO_COMPARE,
  PENDANTS_PROPERTIES_TO_COMPARE,
  RING_PROPERTIES_TO_COMPARE,
} from './constants';

import './Compare.scss';

interface ComparePageProps extends RouteComponentProps {
  store: CompareStore;
  compare: CompareImpl;
  cart: CartStore;
}

@observer
export class ComparePage extends React.Component<ComparePageProps> {
  compareBtn: React.RefObject<HTMLDivElement>;
  comparsionBlock: RefObject<HTMLDivElement> = createRef();

  constructor(props: ComparePageProps) {
    super(props);
    const { store } = props;

    store.setIdenticalParametersHidden(false);
    this.compareBtn = React.createRef();
  }

  public componentDidMount = async (): Promise<void> => {
    const { store, compare } = this.props;

    if (compare.localStorageList.length) {
      compare.loadCompare();
    } else {
      compare.update([]);
    }
    this.chooseCurrentCategories();

    store.carousel.update(toJS(compare.list));
    document.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount(): void {
    const { store } = this.props;
    store.carousel.updateActiveIndexInfinite(0);
    document.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (): void => {
    const { store, compare } = this.props;
    this.resetComparsionBlockPosition();
    store.carousel.update(toJS(compare.list), CAROUSEL_ACTIVE_ITEM);
  };

  resetComparsionBlockPosition(): void {
    if (!this.comparsionBlock.current) return;
    this.comparsionBlock.current.scrollLeft = 0;
  }

  chooseCurrentCategories = (): void => {
    const { store, compare } = this.props;

    const propertiesToCompare: Array<string> = [];

    const transformValue = (el: Array<string>) => {
      return [...new Set(el)]
        .map((property) => {
          return {
            key: property,
            label:
              property.toLowerCase() === property
                ? property[0].toUpperCase() + property.slice(1).toLowerCase()
                : property[0].toUpperCase() +
                  property
                    .slice(1)
                    .split(/(?=[A-Z])/)
                    .join(' '),
            isValuesEqual: false,
          };
        })
        .concat([
          { key: '_price', label: 'Price', isValuesEqual: false },
          { key: 'addToCart', label: 'Add To Cart', isValuesEqual: false },
        ]);
    };
    compare.list.forEach((el) => {
      const category = getCurrentCategory(el.categories);
      switch (true) {
        case category.includes(ProductCategories.DIAMONDS):
          return propertiesToCompare.push(...DIAMOND_PROPERTIES_TO_COMPARE);
        case category.includes(ProductCategories.WEDDING_RINGS):
          return propertiesToCompare.push(...RING_PROPERTIES_TO_COMPARE);
        case category.includes(ProductCategories.ENGAGEMENT_RINGS):
          return propertiesToCompare.push(...RING_PROPERTIES_TO_COMPARE);
        case category.includes(ProductCategories.RINGS):
          return propertiesToCompare.push(...RING_PROPERTIES_TO_COMPARE);
        case category.includes(ProductCategories.PENDANTS):
          return propertiesToCompare.push(...PENDANTS_PROPERTIES_TO_COMPARE);
        case category.includes(ProductCategories.EARRINGS):
          return propertiesToCompare.push(...EARRINGS_PROPERTIES_TO_COMPARE);
        case category.includes(ProductCategories.BRACELETS):
          return propertiesToCompare.push(...BRACELETS_PROPERTIES_TO_COMPARE);
        case category.includes(ProductCategories.NECKLACES):
          return propertiesToCompare.push(...NECKLACES_PROPERTIES_TO_COMPARE);
      }
    });

    store.setTableParams(transformValue(propertiesToCompare));
  };

  handleKeyDown = (event: KeyboardEvent): void => {
    const { store } = this.props;
    const numberOfVisibleItems = window.innerWidth > 1024 ? 3 : 2;

    if (event.code === 'ArrowLeft') {
      store.carousel.activeIndex > 0 && store.carousel.updateActiveIndex(-1);
      event.preventDefault();
    } else if (event.code === 'ArrowRight') {
      store.carousel.activeIndex <
        store.carousel.items.length - numberOfVisibleItems &&
        store.carousel.updateActiveIndex(1);
      event.preventDefault();
    }
  };

  /**
   * Handle slide carousel
   * @param {number} index
   */
  handleSlide = (index: number) => (): void => {
    const { store } = this.props;
    store.carousel.updateActiveIndex(index - store.carousel.activeIndex);
  };

  handleRemoveItem =
    (item: ProductDetailImpl) =>
    async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
      const { store } = this.props;
      event.stopPropagation();

      const { compare } = this.props;

      const numberOfVisibleItems = window.innerWidth > 1024 ? 3 : 2;
      const coefficient = window.innerWidth > 1024 ? 4 : 3;
      const offset =
        compare.list.length > numberOfVisibleItems &&
        store.carousel.activeIndex > compare.list.length - coefficient
          ? store.carousel.activeIndex - 1
          : store.carousel.activeIndex;

      await compare.remove(item.id);

      store.carousel.update(compare.list, offset);
      store.setIdenticalParametersHidden(false);
      this.chooseCurrentCategories();
    };

  handleRemoveItems = (): void => {
    const { compare } = this.props;
    compare.clear();
  };

  findEqualValues = (array: string[]): boolean => {
    return array.every((v: string) => array.length > 1 && v === array[0]);
  };

  handleIdenticalParameters = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { store, compare } = this.props;
    const target = event.target;
    store.setIdenticalParametersHidden(target.checked);

    store.tableParams.forEach((item: TableParamsImpl) => {
      const values = compare.list
        .filter((product) => product[item.key] !== undefined)
        .map((product) => product[item.key]);
      item.isValuesEqual = target.checked && this.findEqualValues(values);
    });
  };

  doesCartHasItem = (productId: number): CartItemImpl | undefined => {
    const { cart } = this.props;

    return cart.items.find((item) => item.id === productId);
  };

  doesServerHasItem = (id: number): boolean => {
    const { compare } = this.props;
    return compare.exist(id);
  };

  render(): JSX.Element {
    const {
      compare: { list, isLoading },
      store,
      cart,
    } = this.props;
    return (
      <div className="compare-page grid">
        <div className="grid-area-content">
          <ul className="grid-area-breadcrumbs">
            <li>
              <Link to={ROUTER_PATHS.DIAMONDS_CATALOG_PATH}>
                search inventory
              </Link>
            </li>
            <li>compare</li>
          </ul>

          <h1 className="grid-area-page-title page-title">compare</h1>

          {list.length ? (
            <div className="grid-area-left-bar">
              <div className="left-bar-checkbox-wrap">
                <label
                  className={`checkbox-container ${
                    list.length < 2 ? 'disabled' : ''
                  }`}
                >
                  Hide identical parameters
                  <input
                    type="checkbox"
                    name="identicalParameters"
                    onChange={this.handleIdenticalParameters}
                    disabled={list.length < 2}
                    checked={store.isIdenticalParametersHidden}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              <ul className="left-bar-list">
                {store.tableParams.map((param: TableParamsImpl) => {
                  return (
                    param.key !== 'category' &&
                    param.key !== 'color' && (
                      <li
                        key={param.key}
                        className={
                          param.isValuesEqual && param.key !== 'addToCart'
                            ? 'equal'
                            : ''
                        }
                      >
                        {param.label}
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          ) : null}

          <div className="grid-area-comparison-block">
            {isLoading ? (
              <div className="comparison-block-items">
                <div className="items-loader">
                  <Loader />
                </div>
              </div>
            ) : (
              <>
                {list.length ? (
                  <>
                    <div
                      className="comparison-block-items no-scrollbar"
                      ref={this.comparsionBlock}
                    >
                      <div
                        className="carousel-wrap"
                        style={{
                          transform: `translate3d(-${
                            store.carousel.activeIndex *
                            (window.innerWidth > 1024
                              ? ITEM_WIDTH_DESKTOP
                              : ITEM_WIDTH_TABLET)
                          }vw, 0, 0)`,
                        }}
                      >
                        {list.map((product) => {
                          return (
                            <CompareItem
                              key={product.id}
                              product={product}
                              cart={cart}
                              store={store}
                              handleRemoveItem={this.handleRemoveItem}
                              doesServerHasItem={this.doesServerHasItem}
                              doesCartHasItem={this.doesCartHasItem}
                            />
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className={joinClasses([
                        'arrow arrow_left',
                        store.carousel.activeIndex === 0 && 'disabled',
                      ])}
                      onClick={this.handleSlide(store.carousel.activeIndex - 1)}
                    >
                      <i className="icon-arrow icon-arrow_left"></i>
                      <span className="arrow__text">Previous</span>
                    </div>

                    <div
                      className={`arrow arrow_right ${
                        store.carousel.activeIndex ===
                          store.carousel.items.length - 3 ||
                        store.carousel.items.length < 3
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={this.handleSlide(store.carousel.activeIndex + 1)}
                    >
                      <span className="arrow__text">Next</span>
                      <i className="icon-arrow icon-arrow_right"></i>
                    </div>

                    <div
                      className="btn btn_rounded btn_bordered-dark-gray btn_text-sm btn_clear"
                      onClick={this.handleRemoveItems}
                    >
                      <Icon id="trash_light" />
                      <span>Clear All ({list.length})</span>
                    </div>
                  </>
                ) : (
                  <div className="screen-saver">
                    <Icon id="gem_large" />
                    <h2 className="screen-saver__heading">
                      You don`t have items to compare.
                    </h2>
                    <p className="attention-message attention-message_text-center attention-message_m-20">
                      Please, go to{' '}
                      <NavLink
                        to={{
                          pathname: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
                        }}
                        className="common-link"
                      >
                        search inventory{' '}
                      </NavLink>
                      and select diamonds.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {list.length ? (
          <div
            ref={this.compareBtn}
            className="btn btn_rounded btn_bordered-dark-gray btn_text-sm btn_clear btn_clear_sticky"
            onClick={this.handleRemoveItems}
            style={{
              top:
                window.innerHeight -
                (this.compareBtn.current?.offsetHeight || 0) * 1.2,
            }}
          >
            <Icon id="trash_light" className="icon_mr-xs" />
            <span>Clear All ({list.length})</span>
          </div>
        ) : null}
      </div>
    );
  }
}
