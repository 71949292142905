import React from 'react';
import Svg from '../../Svg';

interface IRadiantMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function RadiantMeasLengthIcon(
  props: IRadiantMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M139.37 35.58H60.9L47.32 49.16v100.72l12.94 12.95h80.38l12.3-12.95V49.16l-13.57-13.58z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m47.32 149.88 9.32-9.93V57.68l-8.92-8.92M152.94 149.88l-9.31-9.93V57.68l8.92-8.92M56.64 140.02l13.78 11.51-10.16 11.3M143.63 139.95l-14.39 11.58 11.27 11.3M143.63 139.95l-12.4-40.63 12.4-41.64L129.7 46.6l9.67-11.02M56.64 139.95l12.4-40.63-12.4-41.64L70.56 46.6 60.9 35.58M70.42 151.53l29.71-8.46 29.11 8.46H70.42z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m70.36 46.64 29.57 8.03 29.77-8.07-59.34.04zM70.42 151.52A48.296 48.296 0 0 0 72.66 140c.1-5.12-3.62-40.63-3.62-40.63s3.19-35.88 3.19-41a51.996 51.996 0 0 0-1.87-11.68M129.67 151.52a47.809 47.809 0 0 1-2.24-11.52c-.11-5.12 3.62-40.63 3.62-40.63s-3.2-35.88-3.2-41a52.03 52.03 0 0 1 1.87-11.68"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.64 57.68c5.177.545 10.385.758 15.59.64 6.71-.32 23.23-3.79 27.7-3.65h.4c4.48-.14 21 3.33 27.7 3.65 5.208.119 10.419-.095 15.6-.64M56.64 140.05a122.241 122.241 0 0 1 15.59-.64c6.71.32 23.23 3.8 27.7 3.66h.4c4.48.14 21-3.34 27.7-3.66 5.208-.119 10.419.095 15.6.64"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.47 34.98h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.81 34.98h27.04"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.85 2.85"
      />
      <path
        d="M172.27 34.98h1.5M139.47 163.09h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.81 163.09h27.04"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.85 2.85"
      />
      <path
        d="M172.27 163.09h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.77 35.3v127.53"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
