import * as React from 'react';
import { DiamondTemplate } from './DiamondTemplate';
import { PendantTemplate } from './PendantTemplate';
import { RingTemplate } from './RingTemplate';
import { observer } from 'mobx-react';
import { EarringsTemplate } from './EarringsTemplate';
import { BraceletsTemplate } from './BraceletsTemplate';
import { NecklacesTemplate } from './NecklacesTemplate';

const templates: any = {
  stone: DiamondTemplate,
  ring: RingTemplate,
  pendant: PendantTemplate,
  earrings: EarringsTemplate,
  bracelet: BraceletsTemplate,
  necklaces: NecklacesTemplate,
};

export interface CompareTemplateProps {
  templateType: Array<string>;
  product: any;
}

export const ProductInfoTemplate = observer((props: CompareTemplateProps) => {
  let Component;
  for (const key in templates) {
    if (props.templateType.includes(key)) {
      Component = templates[key];
    }
  }
  return <Component product={props.product} />;
});
