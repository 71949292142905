import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import LightLoader from 'components/common/Loaders/LightLoader';
import { IObservableArray } from 'mobx';
import { getIsMobileVersion } from 'lib/utils';
import OrderStore from 'root/store/order';
import PaginateStore from 'root/store/paginate';
import {
  CatalogPagination,
  CatalogsSortHeader,
} from 'components/app/Product/CatalogPage/common';
import { TilesCatalogPage } from 'components/app/Product/CatalogPage/TilesCatalogPage';
import { TableBody } from '../TableBody';
import { TableHead } from '../TableHead';
import { TEngagementRingsCatalog } from '../../types';
import { SORT_BY_VALUES } from '../../constants/constants';

export interface TableRingsProps extends RouteComponentProps {
  data: IObservableArray<TEngagementRingsCatalog>;
  displaySpinner: boolean;
  order: OrderStore;
  pagination: PaginateStore;
  handleOrder: (property: string, direction: string | null) => void;
  handleLimit: (limit: number) => void;
  handlePagination: (page: number) => void;
}

export const Table = withRouter(
  observer((props: TableRingsProps) => {
    const {
      data,
      order,
      pagination,
      handlePagination,
      handleOrder,
      handleLimit,
      displaySpinner,
    } = props;
    const [catalogAsTiles, changeCatalogAsTiles] = useState(true);

    return (
      <>
        <CatalogsSortHeader
          pagination={pagination}
          handleLimit={handleLimit}
          changeCatalogAsTiles={changeCatalogAsTiles}
          isTilesCatalog={catalogAsTiles}
          handleOrder={handleOrder}
          order={order}
          sortValues={SORT_BY_VALUES}
        />
        <div className="grid-area-table">
          {displaySpinner ? (
            <>
              <div className="table-wrap block-scrollbar">
                <div className="parent">
                  <LightLoader />
                </div>
              </div>
            </>
          ) : catalogAsTiles ? (
            <>
              <TilesCatalogPage products={data} />

              {!!data.length && (
                <CatalogPagination
                  pagination={pagination}
                  handlePagination={handlePagination}
                />
              )}
            </>
          ) : (
            <>
              <div className="table-wrap block-scrollbar">
                <table
                  className={getIsMobileVersion() ? 'table_mobile' : 'table_pc'}
                >
                  <TableHead order={order} handleOrder={handleOrder} />
                  <TableBody data={data} />
                </table>
              </div>

              {!!data.length && (
                <CatalogPagination
                  pagination={pagination}
                  handlePagination={handlePagination}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  }),
);
