import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  RouteComponentProps,
  withRouter,
  Link,
  useHistory,
} from 'react-router-dom';
import Favorite from 'root/store/favorite';
import { CompareImpl } from 'root/store/compare';
import CartStore from 'root/store/cart';
import InfoBrowserWindowType from 'root/store/infoBrowserWindowType.store';
import { ScrollToTopOnMount } from 'components/common/ScrollToTop';
import ProductDetailWrapper from './components/ProductDetailWrapper';
import { ProductDetailPageImpl } from './ProductDetailPage.types';
import { joinClasses } from 'lib/utils';
import { PreviewStoreImpl } from '../../components/app/Preview/PreviewTypes';
import { IProductCustomizations } from 'root/store/products/types/product.type';
import hintForProductSetStore from 'components/HintForProductSet/HintForProductSet.store';

import './ProductDetailPage.scss';

interface ProductDetailPageProps extends RouteComponentProps {
  productId: number;
  inventoryPath: string;
  constantTypes: {
    [key: string]: {
      tooltip: string | boolean;
      isNeedDetail: boolean;
    };
  };
  productTitle: string;
  productStore: ProductDetailPageImpl;
  cartPreview: PreviewStoreImpl;
  defaultCustomizationParams: IProductCustomizations | null;
  setWithItems: number[] | null;
}

interface IProps extends ProductDetailPageProps {
  cart: CartStore;
  compare: CompareImpl;
  favorite: Favorite;
  browserWindowType: InfoBrowserWindowType;
}

const ProductDetailPage = observer((props: IProps) => {
  const {
    inventoryPath,
    productId,
    productStore,
    constantTypes,
    productTitle,
    cart,
    compare,
    favorite,
    browserWindowType,
    cartPreview,
    location: { state = {} },
    defaultCustomizationParams,
    setWithItems,
  } = props;
  const history = useHistory();
  const [isOpen3DView, setSsOpen3DView] = useState(false);
  const isEdit = (state as any).edit;
  const isSaveCustomization = (state as any).saveCustomization;

  const handleEditableProductForSet = () => {
    productStore.setIsEdit(true);

    const { customizations, product } = hintForProductSetStore;

    customizations?.engrave && productStore.setIsResetEngravingSettings(false);
    customizations &&
      productStore.replaceCustomizations(
        customizations as IProductCustomizations,
      );
    product?.price && productStore.updatePrice(+product?.price, true);
    hintForProductSetStore.removeHintForDisplaying();
  };

  useEffect(() => {
    productStore.resetChoosedPrice();
    if (isEdit) {
      history.push({
        pathname: window.location.pathname,
        state: undefined,
      });
      handleEditableProductForSet();
    }
    if (isSaveCustomization) {
      history.replace({
        pathname: window.location.pathname,
        state: undefined,
      });
    }
    return () => {
      productStore.resetStore();
    };
  }, [productId]);

  return (
    <div
      className={joinClasses([
        'product-detail-page',
        isOpen3DView && 'product-detail-page-3d',
      ])}
    >
      <ScrollToTopOnMount />

      <ul className="product-detail-page__breadcrumbs grid-area-breadcrumbs">
        <li>
          <Link to={inventoryPath}>search inventory</Link>
        </li>
        <li>{productStore.product?.sku}</li>
      </ul>

      <ProductDetailWrapper
        compare={compare}
        favorite={favorite}
        productId={productId}
        productStore={productStore}
        cart={cart}
        constantTypes={constantTypes}
        productTitle={productTitle}
        cartPreview={cartPreview}
        isOpen3DView={isOpen3DView}
        setSsOpen3DView={setSsOpen3DView}
        browserWindowType={browserWindowType}
        isWithSetHint={!!hintForProductSetStore.product}
        defaultCustomizationParams={defaultCustomizationParams || null}
        setWithItems={setWithItems || null}
      />
    </div>
  );
});

export default withRouter(ProductDetailPage);
