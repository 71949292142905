import React from 'react';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import { NavLink } from 'react-router-dom';
import qs from 'qs';
import { ROUTER_PATHS } from 'root/routes/constants';
import { ICategoriesProduct } from 'root/store/products/types/product.type';
import { CartItemImpl } from 'root/store/cart/item.store';
import { DIAMOND_TYPES } from 'utils/constants/stones/diamonds.constants';
import { PRODUCT_TYPE_TITLE_LIST } from 'utils/constants';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import { DiamondsFilterParams } from 'pages/Diamonds/store/diamondFilters.store';
import {
  IProductDetailsExtraSettings,
  ISettings,
} from 'pages/ProductDetailPage/ProductDetailPage.types';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';
import { RingImpl } from 'root/store/rings/one.store';
import { convertQueryDataToBase64 } from 'utils';

interface AddDiamondPopupProps {
  product: RingImpl;
  formatPriceRing?: string;
  choosedPrice?: number;
  customizations?: CartItemImpl['customizations'];
  visiblePopup: IObservableValue<boolean>;
  isNeededEngrave?: boolean;
  showEngravingTooltip?: () => void;
  extraSettings?: IProductDetailsExtraSettings | Record<string, never>;
}

const AddDiamondPopup = observer((props: AddDiamondPopupProps) => {
  const {
    product: { settings, id, categories, price },
    choosedPrice,
    customizations,
    extraSettings,
    visiblePopup,
    isNeededEngrave,
    showEngravingTooltip,
  } = props;
  const getShape = (settings: ISettings[]) => {
    if (!settings || !settings.length) return undefined;
    return settings[0].shape;
  };
  const currentCategory =
    categories.find(({ alias }) => PRODUCT_TYPE_TITLE_LIST[alias]) ||
    ({} as ICategoriesProduct);

  const getParams = (type?: 'lab') => {
    const isLab = type === 'lab';
    return {
      filters: {
        [DiamondsFilterParams.TYPE]: [
          DIAMOND_TYPES[isLab ? 'DIAMONDS_LAB_CREATED' : 'DIAMONDS'],
        ],
        [DiamondsFilterParams.SHAPE]: [getShape(settings as ISettings[])],
        [DiamondsFilterParams.WIDTH]: extraSettings?.filters?.stoneWidth || {},
        [DiamondsFilterParams.LENGTH]:
          extraSettings?.filters?.stoneLength || {},
      },
      paramsForSet: {
        fromItemType:
          PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias].toLowerCase(),
        fromItemId: id,
        autoAddToCard: true,
        level: LevelPropductForSet.Parent,
        customizations: customizations
          ? convertQueryDataToBase64(customizations)
          : null,
        price: choosedPrice || price,
      },
    };
  };

  const getQueryString = (type?: 'lab') => {
    return qs.stringify(getParams(type), {
      arrayFormat: 'brackets',
    });
  };

  const handleShowEngravingTooltip = () => {
    visiblePopup.set(false);
    showEngravingTooltip && showEngravingTooltip();
  };

  return (
    <PortalControl>
      <ModalWindow visible={visiblePopup.get()}>
        <div
          className="btn btn_bordered-default_without_border btn_circle modal-window__btn-close"
          onClick={() => visiblePopup.set(false)}
        >
          <Icon id="close" />
        </div>
        <div className="modal-window-header">
          <h2 className="modal-window-header__title">
            Add diamond to the{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias].toLowerCase()}
          </h2>
        </div>
        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text_big uppercase modal-window-body__text_center">
            Please select a diamond to finish your{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias].toLowerCase()}
          </p>

          <div className="modal-window-body-btn-wrap">
            <NavLink
              to={{
                pathname: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
                search: getQueryString(),
              }}
              className="send btn btn_lg_popup btn_px-0 btn_brown btn_stretch"
            >
              Add a diamond
            </NavLink>
            <NavLink
              to={{
                pathname: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
                search: getQueryString('lab'),
              }}
              className="send btn btn_lg_popup btn_px-0 btn_bordered-dark-gray btn_stretch"
            >
              Add a lab created diamond
            </NavLink>
          </div>
        </div>

        {isNeededEngrave && (
          <div className="modal-window-body">
            <p className="modal-window-body__text modal-window-body__text_big uppercase modal-window-body__text_center">
              You can add a personal touch to your{' '}
              {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias].toLowerCase()}{' '}
              creation by using our engraving service
            </p>

            <div className="modal-window-body-btn-wrap">
              <button
                onClick={handleShowEngravingTooltip}
                className="btn btn_lg_popup btn_bordered-dark-gray"
              >
                Add engraving
              </button>
            </div>
          </div>
        )}
        <div className="modal-window-footer" />
      </ModalWindow>
    </PortalControl>
  );
});

export default AddDiamondPopup;
