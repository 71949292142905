import React from 'react';
import Svg from '../Svg';

interface ICaretIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CaretIcon(props: ICaretIconProps): JSX.Element {
  return (
    <Svg width="10" height="6" viewBox="0 0 10 6" fill="none" {...props}>
      <path
        d="M1.281 0C.594 0 .25.813.75 1.281L4.469 5c.281.313.75.313 1.062 0L9.25 1.281C9.719.813 9.375 0 8.719 0H1.28Z"
        fill="#62545d"
      />
    </Svg>
  );
}
