import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IObservableArray } from 'mobx';
import { observer } from 'mobx-react';
import OrderStore from 'root/store/order';
import PaginateStore from 'root/store/paginate';
import Pagination from 'components/common/Pagination';
import LightLoader from 'components/common/Loaders/LightLoader';
import { CatalogsSortHeader } from 'components/app/Product/CatalogPage/common';
import { getIsMobileVersion } from 'lib/utils';
import { TDiamondCatalog } from '../../types';
import { TableBody } from '../TableBody';
import { TableHead } from '../TableHead';
import { SORT_DIAMONDS_BY_VALUES } from '../../constants/constants';

export interface TableStonesProps extends RouteComponentProps {
  stones: IObservableArray<TDiamondCatalog>;
  order: OrderStore;
  pagination: PaginateStore;
  handlePagination: (page: number) => void;
  handleOrder: (property: string, direction: string | null) => void;
  handleLimit: (limit: number) => void;
  displaySpinner: boolean;
}

export const Table = withRouter(
  observer((props: TableStonesProps) => {
    const {
      stones,
      order,
      pagination,
      handlePagination,
      handleOrder,
      handleLimit,
      displaySpinner,
    } = props;

    return (
      <>
        <CatalogsSortHeader
          pagination={pagination}
          handleLimit={handleLimit}
          isDiamondCatalog={true}
          order={order}
          handleOrder={handleOrder}
          sortValues={SORT_DIAMONDS_BY_VALUES}
        />

        <div className="grid-area-table">
          {displaySpinner ? (
            <>
              <div className="table-wrap block-scrollbar">
                <table>
                  <TableHead order={order} handleOrder={handleOrder} />
                </table>
                <div className="parent">
                  <LightLoader />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="table-wrap block-scrollbar">
                <table
                  className={getIsMobileVersion() ? 'table_mobile' : 'table_pc'}
                >
                  <TableHead order={order} handleOrder={handleOrder} />

                  <TableBody stones={stones} />
                </table>
              </div>

              {!!stones?.length && (
                <Pagination
                  pagination={pagination}
                  handlePagination={handlePagination}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  }),
);
