import React, { useMemo } from 'react';
import MenuItemLinks from './components/MenuItemLinks';
import Contacts from './components/Contacts';
import {
  VENORI_POLICIES,
  CUSTOMER_INFORMATION,
  USEFUL_INFORMATION,
} from './constants';

import './Footer.scss';

const Footer = (): JSX.Element => {
  const fullYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className="footer-wrapper">
      <div className="footer">
        <MenuItemLinks menuItem={VENORI_POLICIES} />
        <MenuItemLinks menuItem={CUSTOMER_INFORMATION} />
        <MenuItemLinks menuItem={USEFUL_INFORMATION} />
        <Contacts />
      </div>
      <div className="footer-bottom">
        www.venori.com {fullYear} All right reserved
      </div>
    </div>
  );
};

export default Footer;
