import React from 'react';
import { joinClasses } from 'lib/utils';
import Svg from '../../Icon/Svg';

import './LightLoader.scss';

interface IProps {
  className?: string;
}

const LightLoader = (props: IProps): JSX.Element => {
  const { className, ...rest } = props;

  return (
    <div className="light-loader__container">
      <Svg
        className={joinClasses(['light-loader', className])}
        shapeRendering="geometricPrecision"
        viewBox="0 0 250 250"
        {...rest}
      >
        <defs>
          <linearGradient
            id="light-loader-gradient"
            className="light-loader__gradient"
            x1="-198.579"
            y1="0.019"
            x2="204.947"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopOpacity="0" />
            <stop offset="0.497" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <mask id="light-loader-mask" className="light-loader__mask">
            <g className="light-loader__group-1">
              <path
                fill="#eeeced"
                d="M-26.107,-96.73C-30.313,-98.525,-34.839,-99.449,-39.412,-99.447C-39.412,-99.447,-77.03,-99.447,-77.03,-99.447C-76.545,-98.841,-69.858,-96.988,-66.355,-94.96C-49.235,-85.941,-35.961,-71.029,-28.985,-52.983C-21.222,-31.91,-14.914,-10.294,-8.606,11.264C0.053,40.641,10.374,69.503,22.307,97.71C22.584,98.375,23.428,98.807,24.117,99.447C24.117,99.447,50.201,27.768,50.201,27.768C47.639,30.625,46.13,33.977,43.937,36.815C43.302,37.722,42.486,38.487,41.54,39.062C40.594,39.637,39.538,40.007,38.44,40.152C36.887,39.997,34.864,37.382,34.33,35.505C30.73,22.907,27.474,10.221,24.141,-2.45C19.473,-20.215,15.499,-38.187,9.982,-55.69C7.742,-63.718,3.936,-71.223,-1.218,-77.774C-6.372,-84.325,-12.771,-89.79,-20.047,-93.858C-22.002,-94.925,-24.045,-95.847,-26.107,-96.73ZM-58.441,-73.614C-66.005,-83.743,-71.799,-91.907,-87.117,-95.443C-87.117,-95.443,-87.117,-95.443,-87.117,-95.443C-98.026,-97.201,-109.061,-98.06,-120.111,-98.01C-112.882,-93.937,-105.943,-89.368,-99.344,-84.339C-93.027,-78.061,-86.316,-72.193,-79.252,-66.769C-68.758,-60.199,-57.084,-55.735,-44.884,-53.627C-49.149,-60.569,-53.647,-67.201,-58.441,-73.614ZM84.812,-76.846C85.71,-80.319,92.774,-99.268,63.225,-99.447C63.225,-99.447,120.111,-99.447,120.111,-99.447C120.111,-99.447,119.47,-99.345,119.47,-99.345C97.684,-99.893,91.906,-80.29,90.261,-76.186C88.616,-72.082,69.693,-24.28,69.693,-24.28C69.693,-24.28,83.483,-71.714,84.812,-76.846Z"
                fillRule="nonzero"
              />
            </g>
          </mask>
        </defs>
        <g className="light-loader__group-1">
          <path
            fill="#eeeced"
            d="M-26.107,-96.73C-30.313,-98.525,-34.839,-99.449,-39.412,-99.447C-39.412,-99.447,-77.03,-99.447,-77.03,-99.447C-76.545,-98.841,-69.858,-96.988,-66.355,-94.96C-49.235,-85.941,-35.961,-71.029,-28.985,-52.983C-21.222,-31.91,-14.914,-10.294,-8.606,11.264C0.053,40.641,10.374,69.503,22.307,97.71C22.584,98.375,23.428,98.807,24.117,99.447C24.117,99.447,50.201,27.768,50.201,27.768C47.639,30.625,46.13,33.977,43.937,36.815C43.302,37.722,42.486,38.487,41.54,39.062C40.594,39.637,39.538,40.007,38.44,40.152C36.887,39.997,34.864,37.382,34.33,35.505C30.73,22.907,27.474,10.221,24.141,-2.45C19.473,-20.215,15.499,-38.187,9.982,-55.69C7.742,-63.718,3.936,-71.223,-1.218,-77.774C-6.372,-84.325,-12.771,-89.79,-20.047,-93.858C-22.002,-94.925,-24.045,-95.847,-26.107,-96.73ZM-58.441,-73.614C-66.005,-83.743,-71.799,-91.907,-87.117,-95.443C-87.117,-95.443,-87.117,-95.443,-87.117,-95.443C-98.026,-97.201,-109.061,-98.06,-120.111,-98.01C-112.882,-93.937,-105.943,-89.368,-99.344,-84.339C-93.027,-78.061,-86.316,-72.193,-79.252,-66.769C-68.758,-60.199,-57.084,-55.735,-44.884,-53.627C-49.149,-60.569,-53.647,-67.201,-58.441,-73.614ZM84.812,-76.846C85.71,-80.319,92.774,-99.268,63.225,-99.447C63.225,-99.447,120.111,-99.447,120.111,-99.447C120.111,-99.447,119.47,-99.345,119.47,-99.345C97.684,-99.893,91.906,-80.29,90.261,-76.186C88.616,-72.082,69.693,-24.28,69.693,-24.28C69.693,-24.28,83.483,-71.714,84.812,-76.846Z"
            fillRule="nonzero"
          />
        </g>
        <g mask="url(#light-loader-mask)">
          <g className="light-loader__layer">
            <g>
              <path
                fill="url(#light-loader-gradient)"
                d="M201.445,-19.095L201.445,19.095L-201.445,19.095L-201.445,-19.095L201.445,-19.095Z"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </Svg>
    </div>
  );
};

export default LightLoader;
