import * as React from 'react';
import { observer } from 'mobx-react';
import { CartItemBraceletsImpl } from 'root/store/cart/item.store';
import ProductRow from './ProductRow';

export interface BraceletsTemplateProps {
  product: CartItemBraceletsImpl;
}

const BRACELETS_PROPERTIES = {
  firstColumn: [
    'metal',
    'gender',
    'collection',
    'primaryStoneShape',
    'primaryStoneType',
    'primaryStoneSize',
  ],
  secondColumn: ['closureType', 'initialNumber', 'chainLength', 'state'],
};

export const BraceletsTemplate = observer((props: BraceletsTemplateProps) => {
  const { product } = props;
  return <ProductRow product={product} properties={BRACELETS_PROPERTIES} />;
});
