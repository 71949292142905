import React from 'react';
import Svg from '../../Svg';

interface IPrincessMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PrincessMeasLengthIcon(
  props: IPrincessMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M163.31 36.29H36.22v127.09h127.09V36.29z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m36.47 36.29 25.22 63.54-25.22 63.54M163.07 36.29l-25.22 63.54 25.22 63.54"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m36.22 36.29 63.55 25.22 63.54-25.22M36.22 163.37l63.55-25.22 63.54 25.22"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.87 149.96v-19.34H49.45V69.7h19.93V49.55h60.77V69.7h19.94v60.92h-20.43v19.34H69.87z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m69.38 69.7-7.86 30.13 8.35 30.79 29.9 7.53 29.89-7.53 8.18-30.79-7.69-30.13-30.47-8.19-30.3 8.19z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.31 36.29h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.27 36.29h12.11"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.46 3.46"
      />
      <path
        d="M182.11 36.29h1.5M163.31 163.37h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.27 163.37h12.11"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.46 3.46"
      />
      <path
        d="M182.11 163.37h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.61 36.6v126.51"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
