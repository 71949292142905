import * as React from 'react';
import { DiamondTemplate } from './DiamondTemplate';
import { PendantTemplate } from './PendantTemplate';
import { WeddingRingTemplate } from './WeddingRingTemplate';
import { EngagementRingTemplate } from './EngagementRingTemplate';
import { observer } from 'mobx-react';
import { TableParamsImpl } from '../../store';
import { EarringsTemplate } from './EarringsTemplate';
import { BraceletsTemplate } from './BraceletsTemplate';
import { NecklacesTemplate } from './NecklacesTemplate';
import { JewelryRingTemplate } from './JewelryRingTemplate';
import { ProductDetailImpl } from 'root/ProductTypes';

const templates: any = {
  DIAMONDS: DiamondTemplate,
  WEDDING_RINGS: WeddingRingTemplate,
  ENGAGEMENT_RINGS: EngagementRingTemplate,
  RINGS: JewelryRingTemplate,
  PENDANTS: PendantTemplate,
  EARRINGS: EarringsTemplate,
  BRACELETS: BraceletsTemplate,
  NECKLACES: NecklacesTemplate,
};

export interface CompareTemplateProps {
  templateType: Array<string>;
  product: ProductDetailImpl;
  property: TableParamsImpl;
}

export const CompareTemplate = observer((props: CompareTemplateProps) => {
  let Component;
  for (const key in templates) {
    if (props.templateType.includes(key)) {
      Component = templates[key];
    }
  }
  return <Component product={props.product} property={props.property} />;
});
