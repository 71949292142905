import React from 'react';
import Svg from '../Svg';

interface IInfoIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CircleInfoIcon(props: IInfoIconProps): JSX.Element {
  return (
      <Svg width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
          <path d="M8.5 0.25C4.21875 0.25 0.75 3.75 0.75 8C0.75 12.2812 4.21875 15.75 8.5 15.75C12.75 15.75 16.25 12.2812 16.25 8C16.25 3.75 12.75 0.25 8.5 0.25ZM8.5 14.25C5.03125 14.25 2.25 11.4688 2.25 8C2.25 4.5625 5.03125 1.75 8.5 1.75C11.9375 1.75 14.75 4.5625 14.75 8C14.75 11.4688 11.9375 14.25 8.5 14.25ZM8.5 3.6875C7.75 3.6875 7.1875 4.28125 7.1875 5C7.1875 5.75 7.75 6.3125 8.5 6.3125C9.21875 6.3125 9.8125 5.75 9.8125 5C9.8125 4.28125 9.21875 3.6875 8.5 3.6875ZM10.25 11.625V10.875C10.25 10.6875 10.0625 10.5 9.875 10.5H9.5V7.375C9.5 7.1875 9.3125 7 9.125 7H7.125C6.90625 7 6.75 7.1875 6.75 7.375V8.125C6.75 8.34375 6.90625 8.5 7.125 8.5H7.5V10.5H7.125C6.90625 10.5 6.75 10.6875 6.75 10.875V11.625C6.75 11.8438 6.90625 12 7.125 12H9.875C10.0625 12 10.25 11.8438 10.25 11.625Z"
                fill="#928A8E"/>
      </Svg>
  );
}


