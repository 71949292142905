import { useCallback, useEffect, useMemo, useState } from 'react';
import { IReactComponent } from 'mobx-react/src/types/IReactComponent';
import MainSlider from './Main';
// import ValentineCampaign from './ValentainCompaign';
// import MotherDayCampaign from './ValentainCompaign';

// TODO: set config for env
export const SlidersComponents: IReactComponent[] = [
  // MotherDayCampaign,
  MainSlider,
];

export function useSlidersComponents(): [
  IReactComponent,
  number,
  () => unknown,
  () => unknown,
] {
  const [index, setIndex] = useState(0);
  const Slider = useMemo<IReactComponent>(
    () => SlidersComponents[index],
    [index],
  );
  const slidersCount = SlidersComponents.length;

  const handleNextSlider = useCallback(() => {
    setIndex((value) => {
      value++;
      return value > SlidersComponents.length - 1 ? 0 : value;
    });
  }, [index]);

  const handlePrevSlider = useCallback(() => {
    setIndex((value) => {
      value--;
      return value < 0 ? SlidersComponents.length - 1 : value;
    });
  }, [index]);

  useEffect(() => {
    let interval = 0;
    if (slidersCount > 1) {
      interval = window.setInterval(() => {
        handleNextSlider();
      }, 7000);
    }

    return () => clearInterval(interval);
  }, []);

  return [Slider, slidersCount, handleNextSlider, handlePrevSlider];
}
