import React from 'react';
import { joinClasses } from 'lib/utils';
import { observer } from 'mobx-react';
import common from './common';
import profile from './profile';
import product from './product';
import header from './header';
import catalog from './catalog';
import education from './education';
import main from './main';
import shape from './shape';
import footer from './footer';

const icons: {
  [key: string]: (props: {
    children?: React.ReactNode;
    className: string;
  }) => JSX.Element;
} = {
  ...common,
  ...education,
  ...profile,
  ...product,
  ...header,
  ...catalog,
  ...main,
  ...shape,
  ...footer,
};

interface IconProps {
  id: string;
  className?: string;
  onClick?: (e: any) => void;
}

const Icon = (props: IconProps) => {
  const { id, className, ...restProps } = props;
  const IconComponent = icons[id];

  return IconComponent ? (
    <IconComponent
      className={joinClasses([`icon_${id}`, className])}
      {...restProps}
    />
  ) : null;
};

export default observer(Icon);
