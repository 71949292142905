import React from 'react';
import Svg from '../Svg';

interface IFacebookIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function FacebookIcon(props: IFacebookIconProps): JSX.Element {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M14.9043 11.25L15.4598 7.63047H11.9867V5.28164C11.9867 4.29141 12.4719 3.32617 14.0273 3.32617H15.6062V0.244531C15.6062 0.244531 14.1734 0 12.8035 0C9.94336 0 8.07383 1.73359 8.07383 4.87187V7.63047H4.89453V11.25H8.07383V20H11.9867V11.25H14.9043Z" />
    </Svg>
  );
}
