import { action, makeAutoObservable } from 'mobx';
import storage from 'service/storage';

export const SIDEBAR_ACTIVE = 'SIDEBAR_ACTIVE';

export interface ISidebarStore {
  active: boolean;
  toggle(): void;
}

export default class SidebarStore implements ISidebarStore {
  active = false;
  documentWidth = document.documentElement.clientWidth;
  isDeviceMobile = this.documentWidth <= 1024 && this.documentWidth >= 768;

  constructor() {
    makeAutoObservable(this);

    let result: boolean | string;

    if (this.isDeviceMobile) {
      this.active = true;
      storage.set(SIDEBAR_ACTIVE, true);
    } else {
      result = storage.get(SIDEBAR_ACTIVE) || '';
      try {
        this.active = JSON.parse(result);
      } catch (e) {
        storage.set(SIDEBAR_ACTIVE, false);
      }
    }
  }

  @action
  toggle(): void {
    this.active = !this.active;
    !this.isDeviceMobile && storage.set(SIDEBAR_ACTIVE, this.active);
  }
}
