import { action, makeAutoObservable } from 'mobx';
import OrderStore from '../order';
import PaginateStore from '../paginate';
import { OrdersFilterImpl } from './types';

const DEFAULT_VALUE = { name: 'All' };
const DEFAULT_SORT_ORDER = 'DESC';

type FiltersProductsImpl = {
  status?: Array<string | undefined>;
  createDate?: { to: Date; from: Date };
};

interface IQueryParams {
  sortBy?: string;
  sortOrder?: string;
  page: number;
  search?: string;
  limit: number;
  filters: FiltersProductsImpl;
}

export default class OrdersFilter {
  sortState = new OrderStore();
  pagination = new PaginateStore();
  search = '';
  status: { name: string; value?: string }[] = [DEFAULT_VALUE];
  createDate: {
    name: string;
    value?: {
      type: string;
      value: number;
    };
  } = DEFAULT_VALUE;

  constructor() {
    makeAutoObservable(this);
    this.clearFilter();
  }

  @action
  clearFilter = (): void => {
    this.search = '';
    this.status = [DEFAULT_VALUE];
    this.createDate = DEFAULT_VALUE;
    this.pagination.setPage(1);
    this.pagination.setLimit(5);
    this.sortState.updateOrder('', DEFAULT_SORT_ORDER);
  };

  setSortState = (sortBy: string): void => {
    const sortOrder =
      sortBy === this.sortState.sortBy && this.sortState.sortOrder === 'DESK'
        ? 'ASK'
        : 'DESK';
    this.sortState.updateOrder(sortBy, sortOrder);
  };

  updateFilter = ({
    search,
    status,
    createDate,
    sortBy,
    page,
    limit,
  }: OrdersFilterImpl): void => {
    if (search || search === '') this.search = search;
    if (status) this.status = [status];
    if (createDate) this.createDate = createDate;
    if (sortBy) this.setSortState(sortBy);
    if (page) this.pagination.setPage(page);
    if (limit) this.pagination.setLimit(limit);
  };

  getCreateDateFilter = ({
    value,
    type,
  }: {
    type: string;
    value: number;
  }): { to: Date; from: Date } => {
    const to = new Date();
    const from = new Date(
      new Date().setDate(
        (type === 'month' ? to.getMonth() : to.getDate()) - value,
      ),
    );
    return { to, from };
  };

  get queryParams(): IQueryParams {
    const sortState: { sortBy?: string; sortOrder?: string } = {
      sortBy: 'date_order',
      sortOrder: DEFAULT_SORT_ORDER,
    };
    const filters: {
      status?: Array<string | undefined>;
      createDate?: { to: Date; from: Date };
    } = {};
    if (this.status.length)
      filters.status = this.status.map((status) => status.value);
    if (this.createDate.value)
      filters.createDate = this.getCreateDateFilter(this.createDate.value);
    if (this.sortState.sortBy) {
      sortState.sortBy = this.sortState.sortBy;
      sortState.sortOrder = this.sortState.sortOrder;
    }

    return {
      page: this.pagination.page,
      search: this.search || undefined,
      limit: this.pagination.limit,
      filters,
      ...sortState,
    };
  }
}
