import * as React from 'react';
import { observer } from 'mobx-react';
import { CartItemPendantImpl } from 'root/store/cart/item.store';
import ProductRow from './ProductRow';

export interface PendantTemplateProps {
  product: CartItemPendantImpl;
}

const PENDANT_PROPERTIES = {
  firstColumn: [
    'metal',
    'gender',
    'collection',
    'primaryStoneShape',
    'primaryStoneType',
    'primaryStoneSize',
  ],
  secondColumn: ['pendantType', 'birthstoneMonth', 'initialNumber', 'state'],
};

export const PendantTemplate = observer((props: PendantTemplateProps) => {
  const { product } = props;
  return <ProductRow product={product} properties={PENDANT_PROPERTIES} />;
});
