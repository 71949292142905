import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import Footer from '../../components/app/Footer';
import BestSellersCarousel from './components/BestsSellerCarousel';
import Rings from './components/Rings';
import Jewelry from './components/Jewelry';
import Design from './components/Design';
import Brighter from './components/Brighter';
import { Start } from './components/Start';

// import UpgradeDeviceModal from './components/UpgradeDeviceModal';

import './MainPage.scss';

export interface IMainPageProps {
  isMobile: boolean;
}

const MainPage = ({ isMobile }: IMainPageProps): JSX.Element => {
  const [sectionHeight, setSectionHeight] = useState(window.innerHeight);
  // const [visibleNotification, setVisibleNotification] = useState(false);
  const [animatedIndex, setAnimatedIndex] = useState({});

  const handleSetSectionHeight = debounce(() => {
    setSectionHeight(window.innerHeight);
  }, 300);

  useEffect(() => {
    window.addEventListener('orientationchange', handleSetSectionHeight);

    return () => {
      window.removeEventListener('orientationchange', handleSetSectionHeight);
    };
  }, []);

  const handleSetAnimated = useCallback((index: number) => {
    setAnimatedIndex((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  }, []);

  return (
    <>
      <div className="content-main">
        <div className="main-container">
          <div className="main-wrapper">
            <Start sectionHeight={sectionHeight} isMobile={isMobile} />
            <Rings
              animatedIndex={animatedIndex}
              setAnimatedIndex={handleSetAnimated}
            />
            <Jewelry
              animatedIndex={animatedIndex}
              setAnimatedIndex={handleSetAnimated}
            />
            <Design
              animatedIndex={animatedIndex}
              setAnimatedIndex={handleSetAnimated}
            />
            <Brighter
              animatedIndex={animatedIndex}
              setAnimatedIndex={handleSetAnimated}
            />

            <div className="main-slide main-slide_carousel">
              <BestSellersCarousel
                animatedIndex={animatedIndex}
                setAnimatedIndex={handleSetAnimated}
              />
              {!isMobile && <Footer />}
            </div>

            {isMobile && (
              <div className="main-slide main-slide_footer-mobile main-slide-mobile">
                <div className="content-main_block footer-block-mobile">
                  <Footer />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <UpgradeDeviceModal visibleNotification={visibleNotification} /> */}

        <div className="attention">
          Please use website portrait mode for a better user experience
        </div>
      </div>
    </>
  );
};

export default MainPage;
