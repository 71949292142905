import {
  maxLengthValidator,
  minLengthValidator,
  patternValidator,
  requiredValidator,
  ValidationEventTypes,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';

const emailFormRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const nameFormRegExp = /^[a-zA-Z]+$/;

export const nameFieldConfig = {
  validators: [
    wrapperSequentialCheck([
      requiredValidator('FirstName is required'),
      patternValidator(
        nameFormRegExp,
        'First Name must contain only latin letters',
      ),
      maxLengthValidator(
        80,
        'Field maximum length is 80 chars ',
        ValidationEventTypes.Error,
      ),
      minLengthValidator(1),
    ]),
  ],
  additionalData: {
    blockId: Date.now(),
  },
};

export const emailFieldConfig = {
  validators: [
    wrapperSequentialCheck([
      requiredValidator('Email is required'),
      patternValidator(emailFormRegExp, 'Wrong Email format'),
      maxLengthValidator(
        80,
        'Field maximum length is 80 chars ',
        ValidationEventTypes.Error,
      ),
      minLengthValidator(1),
    ]),
  ],
};

export const messageFieldConfig = {
  validators: [
    wrapperSequentialCheck([
      maxLengthValidator(
        250,
        'You have exceeded the limit of 250 characters ',
        ValidationEventTypes.Error,
      ),
      minLengthValidator(0),
    ]),
  ],
};
