import React from 'react';
import { observer } from 'mobx-react';
import Icon from 'components/common/Icon';
import TooltipWithIcon from 'components/common/TooltipWithIcon';
import {
  CartItemEngraveSettings,
  ProductDetailPageImpl,
} from '../../ProductDetailPage.types';
import EngraveBlockItem from './components/EngraveBlockItem';
import EngravePopup from '../EngravePopup';

export interface IProps {
  engraving: ProductDetailPageImpl['engraving'];
  engravingValues: any | null;
  productStore: ProductDetailPageImpl;
  disableAction: boolean;
}

interface IState {
  index: number | null;
}

@observer
export default class EngraveBlock extends React.Component<IProps, IState> {
  state: IState = {
    index: null,
  };

  componentDidMount(): void {
    if (this.props.productStore.isResetEngravingSettings) {
      this.props.productStore.handleSetCustomization(
        'engrave',
        this.props?.engravingValues ? this.props.engravingValues : {},
      );
      this.props?.engravingValues &&
        this.handleSave(this.props?.engravingValues[0], 0);
    } else {
      this.props?.engravingValues &&
        this.handleSave(this.props?.engravingValues[0], 0);
      this.props.productStore.setIsResetEngravingSettings(true);
    }
  }

  handleSave = (
    popupState: CartItemEngraveSettings | undefined,
    index: number,
    prevSettings?: CartItemEngraveSettings,
  ): void | null => {
    const { productStore } = this.props;
    const settings = productStore.engravingSettings;
    const newStateSettings = settings;
    if (popupState) {
      productStore.updatePrice(popupState?.price, false, 'engrave');
    } else {
      productStore.updatePrice(0, false, 'engrave');
    }

    newStateSettings[index] = popupState;
    productStore.handleSetCustomization('engrave', newStateSettings);
  };

  handleRemove = (index: number): void => {
    this.handleSave(undefined, index);
  };

  isAddedBand = (index: number): boolean => {
    const settings = this.props.productStore.engravingSettings;
    return !!settings[index];
  };

  handleClosePopup = (): void => this.setState({ index: null });
  handleSetIndex = (index: number): void => this.setState({ index });

  hideEngravingTooltip = (): void => {
    const {
      productStore: { setIsShowEngravingTooltip },
    } = this.props;
    setIsShowEngravingTooltip(false);
  };

  render(): JSX.Element | null {
    const {
      engraving,
      productStore: { isShowEngravingTooltip },
      disableAction,
    } = this.props;
    const settings = this.props.productStore.engravingSettings;

    if (!engraving) return null;

    return (
      <>
        <h2 className="product-detail-page__details-title uppercase decoration-line">
          <Icon id="spell_check" className="icon" />
          ENGRAVE
          {isShowEngravingTooltip && (
            <TooltipWithIcon
              modifiers="product-detail-page__details-tooltip"
              onClick={this.hideEngravingTooltip}
              onMouseEnter={this.hideEngravingTooltip}
            >
              Add an engraving to your ring{' '}
            </TooltipWithIcon>
          )}
        </h2>

        <div className="product-detail-page__product-engrave">
          {engraving.map((engraveOptions, index) => {
            return (
              <EngraveBlockItem
                disableAction={disableAction}
                key={engraveOptions.description}
                description={engraveOptions.description}
                index={index}
                isAddedBand={this.isAddedBand(index)}
                currentEngraveSettings={settings[index]}
                onOpenPopup={this.handleSetIndex}
                onChange={this.handleSetIndex}
                onRemove={this.handleRemove}
              />
            );
          })}
        </div>
        {this.state.index !== null && (
          <EngravePopup
            engraveSettings={settings}
            index={this.state.index}
            engraving={engraving}
            onClose={this.handleClosePopup}
            onSave={this.handleSave}
          />
        )}
      </>
    );
  }
}
