import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface IProps {
  isActiveListener: boolean;
  threshold?: number | number[];
  rootMargin?: string;
  root?: Element | null;
  callback: () => void;
}

export default function useElementViewed({
  isActiveListener,
  root,
  threshold = 1,
  rootMargin,
  callback,
}: IProps): { elementRef: RefObject<HTMLDivElement> } {
  const [isElementShowed, setIsElementShowed] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const intersectionCallback: IntersectionObserverCallback = useCallback(
    (entries) => {
      if (entries[0].intersectionRatio < threshold) return;

      !isElementShowed && callback();
      setIsElementShowed(true);
    },
    [isElementShowed, threshold, callback],
  );

  const observer = useMemo(
    () =>
      new IntersectionObserver(intersectionCallback, {
        root,
        threshold,
        rootMargin,
      }),
    [root, threshold, rootMargin, intersectionCallback],
  );

  const startObserveElement = useCallback(
    () => elementRef.current && observer.observe(elementRef.current),
    [observer],
  );

  useEffect(() => {
    if (isElementShowed) return;

    isActiveListener && startObserveElement();

    const element = elementRef.current;

    return () => {
      element && observer.unobserve(element);
    };
  }, [isActiveListener, observer, isElementShowed, startObserveElement]);

  return { elementRef };
}
