import React from 'react';
import { observer } from 'mobx-react';
import {
  IProductDetailsExtraSettings,
  ISettings,
} from 'pages/ProductDetailPage/ProductDetailPage.types';
import Icon from 'components/common/Icon';
import { NavLink } from 'react-router-dom';
import { ROUTER_PATHS } from 'root/routes/constants';
import qs from 'qs';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import { RingImpl } from 'root/store/rings/one.store';
import { CartItemImpl } from 'root/store/cart/item.store';
import { DiamondsFilterParams } from 'pages/Diamonds/store/diamondFilters.store';
import { DIAMOND_TYPES } from 'utils/constants/stones/diamonds.constants';
import { PRODUCT_TYPE_TITLE_LIST } from 'utils/constants';
import { ICategoriesProduct } from 'root/store/products/types/product.type';
import { StoneDetailImpl } from 'root/store/stones/one.store';
import { convertQueryDataToBase64 } from 'utils';

export interface CanBeSetWithBlockProps {
  setItem: StoneDetailImpl | null;
  product: RingImpl;
  choosedPrice?: number;
  customizations?: CartItemImpl['customizations'];
  handleRemoveProductForSet: () => void;
  disableAction: boolean;
  extraSettings?: IProductDetailsExtraSettings | Record<string, never>;
}

const CanBeSetWithBlock = observer((props: CanBeSetWithBlockProps) => {
  const {
    setItem,
    product: { id, categories, settings, price },
    extraSettings,
    customizations,
    disableAction,
    handleRemoveProductForSet,
  } = props;

  const currentCategory =
    categories.find(({ alias }) => PRODUCT_TYPE_TITLE_LIST[alias]) ||
    ({} as ICategoriesProduct);

  const getShape = (settings: ISettings[]) => {
    if (!settings || !settings.length) return undefined;
    return settings[0].shape;
  };

  const getParams = (type?: 'lab') => {
    const isLab = type === 'lab';

    return {
      filters: {
        [DiamondsFilterParams.TYPE]: [
          DIAMOND_TYPES[isLab ? 'DIAMONDS_LAB_CREATED' : 'DIAMONDS'],
        ],
        [DiamondsFilterParams.SHAPE]: [getShape(settings as ISettings[])],
        [DiamondsFilterParams.WIDTH]: extraSettings?.filters?.stoneWidth || {},
        [DiamondsFilterParams.LENGTH]:
          extraSettings?.filters?.stoneLength || {},
      },
      paramsForSet: {
        fromItemType:
          PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias].toLowerCase(),
        fromItemId: id,
        autoAddToCard: false,
        level: LevelPropductForSet.Parent,
        customizations: customizations
          ? convertQueryDataToBase64(customizations)
          : null,
      },
    };
  };

  const getQueryString = (type?: 'lab') => {
    return qs.stringify(getParams(type), {
      arrayFormat: 'brackets',
    });
  };

  return (
    <>
      <h2 className="product-detail-page__details-title uppercase decoration-line">
        <Icon id="cogs" />
        CAN BE SET WITH
      </h2>

      <div className="product-detail-page__product-set-with">
        {extraSettings?.data?.map((item, index) => {
          return (
            <div key={index}>
              {setItem?.shape ? (
                <div className="can_be_set_with-block">
                  <div className="can_be_set_with-block__product">
                    <img
                      src={
                        // eslint-disable-next-line @typescript-eslint/no-var-requires
                        require(`img/shapes/${setItem?.shape?.toLowerCase()}.png`)
                          .default
                      }
                    />
                    <div className="can_be_set_with-block__info">
                      <p className="can_be_set_with-block__subtitle">
                        Sku: {setItem.sku}
                      </p>
                      <div className="can_be_set_with-block__info-description">
                        {setItem.carat}
                        <span className="can_be_set_with-block__info-delimiter">
                          |
                        </span>
                        {setItem.shape}
                        <span className="can_be_set_with-block__info-delimiter">
                          |
                        </span>
                        {setItem.clarity}
                        <span className="can_be_set_with-block__info-delimiter">
                          |
                        </span>
                        {setItem.cut}
                        <span className="can_be_set_with-block__info-delimiter">
                          |
                        </span>
                        {setItem.color}
                        <span className="can_be_set_with-block__info-delimiter">
                          |
                        </span>
                        {setItem.categories.some(
                          ({ alias }) =>
                            alias === DIAMOND_TYPES.DIAMONDS_LAB_CREATED,
                        )
                          ? 'Lab-Created'
                          : 'Natural'}
                      </div>
                    </div>
                    <div className="can_be_set_with-block__action">
                      {!disableAction && (
                        <div
                          className="btn btn_bordered-default btn_circle btn_circle-sm"
                          onClick={handleRemoveProductForSet}
                        >
                          <Icon id="trash_light" className="icon_mr-xs" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <NavLink
                  className="product-detail-page__product-set-with-link"
                  key={index}
                  to={{
                    pathname: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
                    search: getQueryString(),
                  }}
                >
                  <p className="product-detail-page__product-set-with-paragraph">
                    <span className="product-detail-page__product-set-with-paragraph-description">
                      {' '}
                      {item.shape} DIAMOND SIZE {item.size} SETTING TYPE{' '}
                      {item.settingType}
                    </span>
                    <span className="product-detail-page__product-set-with-paragraph-pcs">
                      {item.count}pcs
                    </span>
                  </p>
                </NavLink>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
});
export default CanBeSetWithBlock;
