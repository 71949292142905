import React from 'react';
import Svg from '../../Svg';

interface IRoundTableIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function RoundTableIcon(
  props: IRoundTableIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M136.43 49.64H61.9l-32.91 24.4 70.17 59.71 70.17-59.71-32.9-24.4z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m48.2 75.48-4.52-1.78 4.33-1.61a10.4 10.4 0 0 1 6.36-.29l7 1.9-6.71 2a9.82 9.82 0 0 1-6.46-.22v0z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m33.61 75.34-4.11-1.2 4.41-1.64a7.52 7.52 0 0 1 4.45-.25l5.89 1.47-5.07 1.57a9.61 9.61 0 0 1-5.57.05zM67.9 75l-6.57-1.29 4.74-1a36.869 36.869 0 0 1 15.89.11l4 .9-6.07 1.24A30.61 30.61 0 0 1 67.9 75v0zM150.63 75.48l4.52-1.78-4.33-1.61a10.401 10.401 0 0 0-6.36-.29l-7 1.9 6.71 2a9.82 9.82 0 0 0 6.46-.22v0z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m165.22 75.34 4.11-1.2-4.41-1.64a7.51 7.51 0 0 0-4.45-.25l-5.89 1.47 5.07 1.57a9.61 9.61 0 0 0 5.57.05v0zM130.93 75l6.57-1.29-4.74-1a36.869 36.869 0 0 0-15.89.11l-4 .9 6.07 1.24a30.61 30.61 0 0 0 11.99.04v0zM112.91 73.7l-9.6 2c-2.734.57-5.556.57-8.29 0l-9.1-1.92 8.83-2c2.767-.62 5.634-.64 8.41-.06l9.75 1.98zM36.43 75.73l40.03 38.71L51 76.16l39.66 38.28-17.03-38.89"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.16 133.75-8.5-19.31 8.5-38.38M161.79 75.92l-39.93 38.52 25.46-38.28-39.66 38.28 17.04-38.89M99.16 133.75l8.5-19.31-8.5-38.38M52.5 56.61l-1.39 14.63 27.68-15.5-8.18-6.1-18.11 6.97zM99.16 49.64l-20.37 6.1-4.58 16.14M99.16 71.24l-20.37-15.5M99.42 71.24l20.37-15.5M146.07 56.61l1.4 14.63-27.68-15.5 8.17-6.1 18.11 6.97z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.42 49.64 20.37 6.1 4.58 16.14"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.05 74.24v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.05 69.89V37.14"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.85 2.85"
      />
      <path
        d="M28.05 35.71v-1.5M169.88 74.24v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.88 69.89V37.14"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.85 2.85"
      />
      <path
        d="M169.88 35.71v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.05 34.21h141.83"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.52 49.64v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.52 45.09V22.24"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.05 3.05"
      />
      <path
        d="M61.52 20.71v-1.5M136.4 49.64v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.4 45.09V22.24"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.05 3.05"
      />
      <path
        d="M136.4 20.71v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.52 19.21h74.88"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
