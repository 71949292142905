import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { store as headerStore } from '../index';

export default ({ className = '' }: { className: string }) =>
  (WrappedComponent: any): ((props: unknown) => JSX.Element) => {
    const WithHeader = (props: unknown): JSX.Element => {
      const handleActive = (value: boolean) =>
        value
          ? headerStore.addClass('active')
          : headerStore.removeClass('active');

      useEffect(() => {
        headerStore.addClass(className);
        return () => {
          headerStore.removeClass(className);
        };
      }, []);

      return (
        <WrappedComponent {...(props as any)} handleActive={handleActive} />
      );
    };

    return observer(WithHeader);
  };
