import CloseIcon from './CloseIcon';
import ArrowLeftIcon from './ArrowLeftIcon';
import ArrowTopIcon from './ArrowTopIcon';
import CheckBoxIcon from './CheckBoxIcon';
import CheckIcon from './CheckIcon';
import MapMarketAltIcon from './MapMarketAltIcon';
import PhoneDarkIcon from './PhoneDarkIcon';
import LongArrowLeftIcon from './LongArrowLeftIcon';
import PhoneIcon from './PhoneIcon';
import PhoneLightIcon from './PhoneLightIcon';
import LogoMobileIcon from './LogоMobileIcon';
import InfoIcon from './InfoIcon';
import MapLightIcon from './MapLightIcon';
import ChevronIcon from './ChevronIcon';
import PlusCircleIcon from './PlusCircleIcon';
import MinusCircleIcon from './MinusCircleIcon';
import CaretIcon from './CaretIcon';
import MarkerIcon from './MarkerIcon';
import CardIcon from './CardIcon';
import CircleInfoIcon from './CircleInfoIcon';
import SearchIcon from './SearchIcon';

export default {
  close: CloseIcon,
  arrow_left: ArrowLeftIcon,
  arrow_top: ArrowTopIcon,
  long_arrow_left: LongArrowLeftIcon,
  check_box_icon: CheckBoxIcon,
  check_icon: CheckIcon,
  map_market_alt: MapMarketAltIcon,
  phone_dark: PhoneDarkIcon,
  phone_light: PhoneLightIcon,
  phone: PhoneIcon,
  logo_mobile: LogoMobileIcon,
  info: InfoIcon,
  circle_info: CircleInfoIcon,
  map_light: MapLightIcon,
  chevron: ChevronIcon,
  plus_circle: PlusCircleIcon,
  minus_circle: MinusCircleIcon,
  caret: CaretIcon,
  marker: MarkerIcon,
  card: CardIcon,
  search: SearchIcon,
};
