import React from 'react';
import Svg from '../Svg';

interface ILeafRegularIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function LeafRegularIcon(
  props: ILeafRegularIconProps,
): JSX.Element {
  return (
    <Svg width="16" height="14" viewBox="0 0 576 512" fill="none" {...props}>
      <path
        fill="#4d4048"
        d="M546.2 9.7c-2.9-6.5-8.6-9.7-14.3-9.7-5.3 0-10.7 2.8-14 8.5C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 17.8 2.6 34.9 7.1 51.2C29 403.7 1.8 478.8 1.3 480.2c-4.3 12.5 2.3 26.2 14.8 30.5 14 4.8 26.7-3.8 30.5-14.8.4-1.1 21-57.5 76.3-110.1C160.5 449 231.5 487 308.4 478.9 465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6zM303.4 431.2c-86.1 9.1-130.6-54.5-142.2-76.5 47.4-32.9 112-58.7 198.9-58.7 13.2 0 24-10.8 24-24s-10.8-24-24-24c-91.3 0-161.1 25.5-214 59.4-.9-6.4-2-12.8-2-19.4 0-79.4 64.6-144 144-144h80c57.9 0 111.6-22 152-60.9 5.2 23.2 8 47.5 8 71.2-.1 151-93.9 267.4-224.7 276.9z"
      />
    </Svg>
  );
}
