import AsscherMeasLengthIcon from './AsscherMeasLengthIcon';
import AsscherMeasWidthIcon from './AsscherMeasWidthIcon';
import AsscherMeasDepthIcon from './AsscherMeasDepthIcon';
import AsscherDepthIcon from './AsscherDepthIcon';
import AsscherTableIcon from './AsscherTableIcon';
import AsscherIcon from './AsscherIcon';

export default {
  asscher_measLength: AsscherMeasLengthIcon,
  asscher_measWidth: AsscherMeasWidthIcon,
  asscher_measDepth: AsscherMeasDepthIcon,
  asscher_depth: AsscherDepthIcon,
  asscher_table: AsscherTableIcon,
  asscher: AsscherIcon,
};
