import React from 'react';
import Svg from '../Svg';

interface IImageGalleryIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function ImageGalleryIcon(
  props: IImageGalleryIconProps,
): JSX.Element {
  return (
    <Svg width="20" height="15" viewBox="0 0 20 15" fill="none" {...props}>
      <path
        d="M18.125 0H1.875C.82 0 0 .86 0 1.875v11.25C0 14.18.82 15 1.875 15h16.25C19.141 15 20 14.18 20 13.125V1.875C20 .859 19.14 0 18.125 0Zm-.234 13.125H2.109c-.156 0-.234-.078-.234-.234V2.109c0-.117.078-.234.234-.234h15.782c.117 0 .234.117.234.234v10.782c0 .156-.117.234-.234.234ZM5 3.437c-.898 0-1.563.704-1.563 1.563 0 .898.665 1.563 1.563 1.563.86 0 1.563-.665 1.563-1.563 0-.86-.704-1.563-1.563-1.563ZM3.75 11.25h12.5V8.125l-3.438-3.398a.45.45 0 0 0-.664 0L7.5 9.375 5.937 7.852a.45.45 0 0 0-.664 0L3.75 9.375v1.875Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
