import * as React from 'react';
import {
  NavLink,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { ScrollToTopOnMount } from 'components/common/ScrollToTop';
import Icon from 'components/common/Icon';
import { POLICIES_TABS } from './Policies.constants';

import './Policy.scss';

@observer
class Policy extends React.Component<RouteComponentProps> {
  previousPageLocation = '';
  previousPageTitle = '';

  constructor(props: RouteComponentProps) {
    super(props);

    const previousLocation = this.props.location.state as {
      from: string;
      pageTitle: string;
    };

    this.previousPageLocation = previousLocation && previousLocation.from;
    this.previousPageTitle = previousLocation && previousLocation.pageTitle;
  }

  handlePrev = (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.previousPageLocation
      ? this.props.history.push(this.previousPageLocation)
      : this.props.history.push('/');
  };

  render() {
    const { privacyPolicy, shippingPolicy, termsAndConditions } = POLICIES_TABS;

    return (
      <div className="policy-page grid">
        <ScrollToTopOnMount />

        <ul className="grid-area-breadcrumbs">
          <li>
            <a href="/">main</a>
          </li>
          {this.previousPageTitle ? (
            <li>
              <a onClick={this.handlePrev}>{this.previousPageTitle}</a>
            </li>
          ) : null}
          <li>policy</li>
        </ul>

        <div className="grid-area-link"></div>

        <h1 className="grid-area-page-title page-title">venori policies</h1>

        <div className="grid-area-tabs">
          <div className="tabs_container">
            <div className="tabs-links">
              <NavLink
                to="/policy/privacypolicy"
                className="tab__link"
                activeClassName="active"
              >
                Privacy Policy
              </NavLink>
              <NavLink to="/policy/shippingpolicy" className="tab__link">
                Shipping & Returns Policy
              </NavLink>
              <NavLink to="/policy/termsconditions" className="tab__link">
                Terms and Conditions
              </NavLink>
            </div>

            <div className="tabs-panels block-scrollbar">
              <Switch>
                <Route path="/policy/privacypolicy">
                  <div className="panel">
                    <div className="panel-header">
                      <h1 className="panel-header__title">
                        {privacyPolicy.title}
                      </h1>
                      <p className="attention-message">
                        {privacyPolicy.dateUpdate}
                      </p>
                    </div>
                    <div className="panel-content">
                      {privacyPolicy.description}
                    </div>
                  </div>
                </Route>

                <Route path="/policy/shippingpolicy">
                  <div className="panel">
                    <div className="panel-header">
                      <h1 className="panel-header__title">
                        {shippingPolicy.title}
                      </h1>
                      <p className="attention-message">
                        {shippingPolicy.dateUpdate}
                      </p>
                    </div>
                    <div className="panel-content">
                      {shippingPolicy.description}
                    </div>
                  </div>
                </Route>

                <Route path="/policy/termsconditions">
                  <div className="panel">
                    <div className="panel-header">
                      <h1 className="panel-header__title">
                        {termsAndConditions.title}
                      </h1>
                      <p className="attention-message">
                        {termsAndConditions.dateUpdate}
                      </p>
                    </div>
                    <div className="panel-content">
                      {termsAndConditions.description}
                    </div>
                  </div>
                </Route>
              </Switch>
            </div>
          </div>
        </div>

        <div className="grid-area-page-btn">
          <a
            className="btn btn_sm btn_text-bold btn_bordered-default btn_fixed-height btn_stretch-mobile"
            title="Cart"
            onClick={this.handlePrev}
          >
            <Icon id="long_arrow_left" className="icon_mr-sm" />
            BACK
          </a>
        </div>

        <div className="grid-area-attention">
          Please use website portrait mode for a better user experience
        </div>
      </div>
    );
  }
}

export default withRouter(Policy);
