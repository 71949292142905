import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import { CartItemImpl } from 'root/store/cart/item.store';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';
import { StoneDetailImpl } from 'root/store/stones/one.store';

interface IDeleteDiamondPopupProps {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setItem: StoneDetailImpl | null;
  removeProductForSet: (event: MouseEvent<HTMLElement>) => void;
}

const DeleteDiamondPopup = observer((props: IDeleteDiamondPopupProps) => {
  const { isVisible, setVisible, setItem, removeProductForSet } = props;

  const onAcceptHandler = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    removeProductForSet(e);
  };

  const onCloseHandler = (e: MouseEvent<HTMLElement>) => {
    setVisible(false);
  };

  return (
    <PortalControl>
      <ModalWindow visible={!!isVisible}>
        <div
          className="btn btn_bordered-default_without_border btn_circle modal-window__btn-close"
          onClick={onCloseHandler}
        >
          <Icon id="close" />
        </div>

        <div className="modal-window-header">
          <h2 className="modal-window-header__title">Delete diamond</h2>
        </div>

        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text__small-text modal-window-body__text_center">
            Are you sure you want to delete SKU:{setItem?.sku}?
          </p>

          <div className="modal-window-body-btn-wrap">
            <button
              onClick={onAcceptHandler}
              className="btn btn_lg_popup btn_brown"
            >
              Yes
            </button>
            <button
              className="btn btn_lg_popup btn_bordered-dark-gray"
              onClick={onCloseHandler}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
});

export default DeleteDiamondPopup;
