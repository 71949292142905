import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ComparePage as DefaultComponent } from './Compare';
import store from 'root/store';
import CompareStore from './store';

const { cart, compare } = store;
const compareStore = new CompareStore();

export const ComparePage = withRouter((props: RouteComponentProps) => (
  <DefaultComponent
    {...props}
    store={compareStore}
    cart={cart}
    compare={compare}
  />
));
