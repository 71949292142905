import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { BestSellersCarouselStore } from './BestSellersCarousel.store';
import { gsap } from 'gsap';
import useElementViewed from '../../helpers/useElementViewed';

import './BestSellersCarousel.scss';
import CarouselItem from './components/CarouselItem/CarouselItem';

const $level2height = '12.878vw';
const $level2width = '10.281vw';
const $level1height = '15.584vw';
const $level1width = '12.445vw';
const $level0height = '18.939vw';
const $level0width = '15.151vw';

const $leveL2left = '75vw';
const $leveL1left = '60.138vw';
const $level0left = '42.7vw';
const $level1left = '28vw';
const $level2left = '15.5vw';

export interface BestSellersCarouselStoreImpl {
  store: BestSellersCarouselStore;
}

export interface BestSellersCarouselProps {
  setAnimatedIndex: (index: number) => void;
  animatedIndex: Record<number, boolean>;
}

export const BestSellersCarousel = observer(
  (props: BestSellersCarouselProps & BestSellersCarouselStoreImpl) => {
    const { carousel } = props.store;
    const { animatedIndex, setAnimatedIndex } = props;
    const [animated, setAnimated] = useState(false);

    const handleAnimate = useCallback(() => {
      if (!animated) {
        setAnimated(true);
        setAnimatedIndex(6);
        gsap.fromTo('.carousel-item', { scale: 0 }, { scale: 1, duration: 2 });
      }
    }, [animatedIndex, setAnimatedIndex]);

    const { elementRef } = useElementViewed({
      callback: handleAnimate,
      isActiveListener: true,
      threshold: 0.2,
    });

    useEffect(() => {
      void props.store.loadBestSellers();
    }, []);

    const generateItems = () => {
      const items = [];
      let level: number;
      for (
        let i = carousel.activeIndex - 3;
        i < carousel.activeIndex + 4;
        i++
      ) {
        let index = i;
        if (i < 0) {
          index = carousel.items.length + i;
        } else if (i >= carousel.items.length) {
          index = i - carousel.items.length;
        }
        level = carousel.activeIndex - i;

        items.push(
          <CarouselItem
            key={index}
            link={carousel.items[index] ? carousel.items[index].id : null}
            item={
              carousel.items[index]
                ? carousel.items[index].photo
                : require('img/default_images/venori_no_image.png').default
            }
            level={level}
          />,
        );
      }
      return items;
    };

    const moveLeft = () => {
      let newActive = carousel.activeIndex;
      newActive--;
      carousel.updateActiveIndexInfinite(
        newActive < 0 ? carousel.items.length - 1 : newActive,
      );
      gsap.fromTo(
        '#level3',
        { scale: 0, duration: 1, opacity: 0 },
        { scale: 1, duration: 1, opacity: 1 },
      );

      gsap.fromTo(
        '#level3',
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          marginTop: '25px',
          left: $level2left,
          duration: 1,
        },
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          marginTop: '25px',
          left: $level2left,
          duration: 1,
        },
      );

      gsap.fromTo(
        '#level2',
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          marginTop: '25px',
          left: $level2left,
          duration: 1,
        },
        {
          height: $level1height,
          width: $level1width,
          lineHeight: $level1height,
          marginTop: '10px',
          left: $level1left,
          duration: 1,
        },
      );

      gsap.fromTo(
        '#level1',
        {
          height: $level1height,
          width: $level1width,
          lineHeight: $level1height,
          marginTop: '10px',
          left: $level1left,
          duration: 1,
        },
        {
          height: $level0height,
          width: $level0width,
          lineHeight: $level0height,
          left: $level0left,
          duration: 1,
        },
      );
      gsap.fromTo(
        '#level0',
        {
          height: $level0height,
          width: $level0width,
          lineHeight: $level0height,
          left: $level0left,
          duration: 1,
        },
        {
          height: $level1height,
          width: $level1width,
          lineHeight: $level1height,
          marginTop: '10px',
          left: $leveL1left,
          duration: 1,
        },
      );
      gsap.fromTo(
        '#level-1',
        {
          height: $level1height,
          width: $level1width,
          lineHeight: $level1height,
          marginTop: '10px',
          left: $leveL1left,
          duration: 1,
        },
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          left: $leveL2left,
          duration: 1,
          marginTop: '25px',
        },
      );

      gsap.fromTo(
        '#level-2',
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          left: $leveL2left,
          duration: 1,
          marginTop: '25px',
        },
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          left: $leveL2left,
          duration: 1,
          marginTop: '25px',
        },
      );

      gsap.fromTo(
        '#level-2',
        { scale: 1, duration: 0.6, opacity: 1 },
        { scale: 0, duration: 0.6, opacity: 0 },
      );
    };

    const moveRight = () => {
      const newActive = carousel.activeIndex;
      carousel.updateActiveIndexInfinite(
        (newActive + 1) % carousel.items.length,
      );

      gsap.fromTo(
        '#level2',
        { scale: 1, duration: 1, opacity: 1 },
        { scale: 0, duration: 1, opacity: 0 },
      );

      gsap.fromTo(
        '#level1',
        {
          height: $level1height,
          width: $level1width,
          lineHeight: $level1height,
          marginTop: '10px',
          left: $level1left,
          duration: 1,
        },
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          marginTop: '25px',
          left: $level2left,
          duration: 1,
        },
      );
      gsap.fromTo(
        '#level0',
        {
          height: $level0height,
          width: $level0width,
          lineHeight: $level0height,
          left: $level0left,
          duration: 1,
        },
        {
          height: $level1height,
          width: $level1width,
          lineHeight: $level1height,
          marginTop: '10px',
          left: $level1left,
          duration: 1,
        },
      );
      gsap.fromTo(
        '#level-1',
        {
          height: $level1height,
          width: $level1width,
          lineHeight: $level1height,
          marginTop: '10px',
          left: $leveL1left,
          duration: 1,
        },
        {
          height: $level0height,
          width: $level0width,
          lineHeight: $level0height,
          left: $level0left,
          duration: 1,
        },
      );

      gsap.fromTo(
        '#level-2',
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          left: $leveL2left,
          duration: 1,
          marginTop: '25px',
        },
        {
          height: $level1height,
          width: $level1width,
          lineHeight: $level1height,
          marginTop: '10px',
          left: $leveL1left,
          duration: 1,
        },
      );

      gsap.fromTo(
        '#level-3',
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          left: $leveL2left,
          duration: 1,
          marginTop: '25px',
        },
        {
          height: $level2height,
          width: $level2width,
          lineHeight: $level2height,
          left: $leveL2left,
          duration: 1,
          marginTop: '25px',
        },
      );

      gsap.fromTo(
        '#level-3',
        { scale: 0, duration: 1.3, opacity: 0 },
        { scale: 1, duration: 1.3, opacity: 1 },
      );
    };

    return (
      <>
        <div className="content-main_block" id="bestSellers">
          <div className="content-main_block__title">Our Bestsellers</div>
          <div className="content-main_block__description">
            Get inspired.
            <br /> Browse through our most popular designs.
          </div>
          <div className="content-main_block__best-sellers">
            <div id="carousel" className="noselect">
              <div className="arrow arrow-left" onClick={moveLeft}>
                <i className="icon-arrow icon-arrow_left"></i>
              </div>
              {generateItems()}
              <div className="arrow arrow-right" onClick={moveRight}>
                <i className="icon-arrow icon-arrow_right"></i>
              </div>
            </div>
          </div>
        </div>

        <div
          className="content-main_block"
          id="bestSellersMobile"
          ref={elementRef}
        >
          <div className="content-main_block__title">Our Bestsellers</div>
          <div className="content-main_block__description">
            Get inspired.
            <br /> Browse through our most popular designs.
          </div>

          <div className="content-main_block__best-sellers">
            <div
              className={`arrow arrow-left ${
                carousel.activeIndex === 0 ? 'disabled' : ''
              }`}
              onClick={moveRight}
            >
              <i className="icon-arrow icon-arrow_left"></i>
            </div>
            <div className="mid carousel-item">
              <CarouselItem
                link={
                  carousel.items[carousel.activeIndex]
                    ? carousel.items[carousel.activeIndex].id
                    : null
                }
                item={
                  carousel.items[carousel.activeIndex]
                    ? carousel.items[carousel.activeIndex].photo
                    : require('img/default_images/venori_no_image.png').default
                }
              />
            </div>
            <div
              className={`arrow arrow-right ${
                carousel.activeIndex === 9 ? 'disabled' : ''
              }`}
              onClick={moveLeft}
            >
              <i className="icon-arrow icon-arrow_right"></i>
            </div>
          </div>
        </div>
      </>
    );
  },
);
