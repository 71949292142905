import React from 'react';

import './Loader.scss';

const Loader: React.FunctionComponent = () => {
  return (
    <div className="parent parent-loader">
      <div className="loader">
        <svg
          id="evn6zgb3x1hm1"
          viewBox="0 0 595.276000 841.890000"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
        >
          <line
            className="line"
            id="eighth"
            x1="135.721000"
            y1="266.752000"
            x2="434.897000"
            y2="266.752000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="300"
            strokeDasharray="300"
          />
          <line
            className="line"
            id="tenth"
            x1="367.621000"
            y1="192.502000"
            x2="212.164000"
            y2="192.502000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="157"
            strokeDasharray="157"
          />
          <line
            className="line"
            id="seventh"
            x1="210.421000"
            y1="264.652000"
            x2="210.421000"
            y2="193.502000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="72"
            strokeDasharray="72"
          />
          <line
            className="line"
            id="sixth"
            x1="211.855000"
            y1="190.528000"
            x2="285.105000"
            y2="265.416000"
            transform="matrix(0 1 -1 0 475.83699999999993 -18.35299999999999)"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="110"
            strokeDasharray="110"
          />
          <line
            className="line"
            id="fifth"
            x1="367.730000"
            y1="266.752000"
            x2="289.130000"
            y2="193.502000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="110"
            strokeDasharray="110"
          />
          <line
            className="line"
            id="fourth"
            x1="332.046000"
            y1="237.581000"
            x2="403.960000"
            y2="237.472000"
            transform="matrix(0 1 -1 0 605.20199999999977 -138.54399999999998)"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="75"
            strokeDasharray="75"
          />
          <line
            className="line"
            id="twelfth"
            x1="135.721000"
            y1="266.752000"
            x2="285.309000"
            y2="440.502000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="231"
            strokeDasharray="231"
          />
          <line
            className="line"
            id="thirteenth"
            x1="286.322000"
            y1="441.202000"
            x2="375.075000"
            y2="327.597000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="145"
            strokeDasharray="145"
          />
          <line
            className="line"
            id="ninth"
            x1="436.022000"
            y1="266.752000"
            x2="367.621000"
            y2="192.502000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="101"
            strokeDasharray="101"
          />
          <line
            className="line"
            id="eleventh"
            x1="210.421000"
            y1="193.502000"
            x2="135.721000"
            y2="265.416000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="106"
            strokeDasharray="106"
          />
          <path
            className="line"
            id="third"
            d="M353.801000,400.828000C360.954000,412.462000,365.080000,426.158000,365.080000,440.818000C365.080000,483.045000,330.849000,517.276000,288.622000,517.276000C246.395000,517.276000,212.164000,483.045000,212.164000,440.818000C212.164000,398.591000,246.395000,364.360000,288.622000,364.360000C305.027000,364.360000,320.225000,369.527000,332.678000,378.321000C333.273000,378.741000,333.861000,379.169000,334.443000,379.606000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="1000"
            strokeDasharray="1000"
          />
          <path
            className="line"
            id="second"
            d="M373.471000,373.702000L380.865000,384.663000C390.989000,401.129000,396.827000,420.511000,396.827000,441.258000C396.827000,501.019000,348.382000,549.464000,288.621000,549.464000C228.860000,549.464000,180.415000,501.019000,180.415000,441.258000C180.415000,381.497000,228.860000,333.052000,288.621000,333.052000C311.838000,333.052000,333.347000,340.364000,350.971000,352.811000C351.813000,353.405000,352.646000,354.012000,353.470000,354.629000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="1000"
            strokeDasharray="1000"
          />
          <path
            className="line"
            id="first"
            d="M392.971000,340.252000L401.930000,351.379000L411.684000,365.840000C425.040000,387.562000,432.742000,413.133000,432.742000,440.502000C432.742000,519.341000,368.831000,583.252000,289.992000,583.252000C211.153000,583.252000,147.242000,519.341000,147.242000,440.502000C147.242000,361.663000,211.153000,297.752000,289.992000,297.752000C320.621000,297.752000,348.996000,307.398000,372.247000,323.818000C373.358000,324.602000,374.456000,325.402000,375.543000,326.217000"
            fill="none"
            stroke="rgb(0,0,0)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeDashoffset="1000"
            strokeDasharray="1000"
          />
        </svg>
      </div>
    </div>
  );
};

export default Loader;
