import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import { ComparePanel } from '../Compare/components/ComparePanel';
import { ScrollUpButton } from '../../components/common/ScrollUpButton';
import { Catalog } from './components/Catalog';

import './FineJewelry.scss';

// interface FineJewelryPageProps extends RouteComponentProps {
// }

const FineJewelry = observer((props: RouteComponentProps) => {
  /**
   * Replace url by catalog params
   */
  const historyReplace = (query: unknown) => {
    const { history, match } = props;
    history.replace(
      match.path + '?' + qs.stringify(query, { arrayFormat: 'brackets' }),
    );
  };

  return (
    <div className="catalog-page grid">
      <ul className="grid-area-breadcrumbs">
        <li>search inventory</li>
      </ul>

      <Catalog location={props.location} historyReplace={historyReplace} />

      <ComparePanel />

      <ScrollUpButton />
    </div>
  );
});

export const FineJewelryPage = withRouter(FineJewelry);
