import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function ColorF(props: IProps): JSX.Element {
  return (
    <Svg width="69" height="48" viewBox="0 0 69 48" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m34.524 46.9 33.524-29.127-.201-3.716-6.123-6.73-11.542-4.82L34.524 1 18.866 2.507 7.424 7.327 1.2 14.058 1 17.773 34.524 46.9Z"
        fill="#fff"
      />
      <path
        clipRule="evenodd"
        d="m34.524 47 33.524-29.227-.201-3.716-6.123-6.73-11.542-4.82L34.524 1 18.866 2.507 7.424 7.327 1.2 14.058 1 17.773 34.524 47Z"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m34.524 47 3.212-8.538 8.933-12.253 11.442-5.625 2.409-7.331-4.517-6.83-5.821-3.917"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m56.003 6.424-5.62 3.515-15.86 1.406 10.44 4.821 13.148 4.42"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m50.383 9.94-5.42 6.226-10.44 7.533M60.52 13.254 50.383 9.939M34.524 23.7l12.145 2.51M34.524 26.71l12.044-4.017 10.64 1.004 6.825-4.319 4.015-1.607M64.033 19.38l-5.922 1.206"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m68.047 17.772-7.527-4.52 3.512 6.127M61.724 7.328l6.324 10.446M56.003 6.424l5.721.904M44.962 16.166l1.606 6.528M57.207 23.7l-9.234 11.55M37.736 38.463 57.208 23.7M34.524 26.71l3.212 11.752M34.524 47l-3.212-8.538L22.38 26.21l-11.342-5.625-2.409-7.331 4.417-6.83 5.821-3.917"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m13.044 6.424 5.621 3.515 15.859 1.406-10.439 4.821-13.048 4.42"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m18.666 9.94 5.42 6.226L34.524 23.7M8.628 13.254l10.038-3.315M34.523 23.7l-12.145 2.51M34.524 26.71l-12.045-4.017-10.539 1.004-6.925-4.319L1 17.771M5.015 19.38l6.022 1.206"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m1 17.772 7.628-4.52-3.613 6.127M7.424 7.328 1 17.774M13.045 6.424l-5.621.904M24.085 16.166l-1.606 6.528M11.94 23.7l9.134 11.55M31.312 38.463 11.94 23.7M34.524 26.71l-3.212 11.752"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
