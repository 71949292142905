import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { QuestionsType, RouteDataType } from '../../FAQPage.type';
import Question from '../Question';

interface IProps {
  questionsBlock: QuestionsType[];
}

function ChunkOfQuestions({ questionsBlock }: IProps): JSX.Element {
  const [openedAnswer, setOpenedAnswer] = useState<
    RouteDataType['path'] | null
  >(null);

  const handleAnswerToggle = (path: RouteDataType['path']): void => {
    setOpenedAnswer(path === openedAnswer ? null : path);
  };

  return (
    <div className="tabs-panels block-scrollbar">
      <div className="chunk-of-questions">
        {questionsBlock.map((question) => {
          const isOpened = question.path === openedAnswer;

          return (
            <Question
              key={question.path}
              isOpened={isOpened}
              question={question}
              onClick={handleAnswerToggle}
            />
          );
        })}
      </div>
    </div>
  );
}

export default observer(ChunkOfQuestions);
