import React from 'react';
import Svg from '../../Svg';

interface IEmeraldMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function EmeraldMeasLengthIcon(
  props: IEmeraldMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M134.39 34.91H65.33L51 49.23v100.28l14.33 14.32h69.06l14.32-14.32V49.23l-14.32-14.32z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.71 45.95H73.38L60.83 55.5v88.24l11.84 10.12h53.75l11.83-10.12V55.5l-12.54-9.55z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.52 51.37H76.94l-11.12 7.41v82.25l11.12 7.55h45.58l11.12-7.55V58.78l-11.12-7.41zM65.82 141.03 51 149.51M76.94 148.58l-11.61 15.25M122.52 148.58l11.87 15.25M133.64 141.03l15.07 8.48M148.71 49.23l-15.07 9.55M134.39 34.91l-11.87 16.46M65.33 34.91l11.61 16.46M65.82 58.78 51 49.23"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.39 40.67 55.75 52.29v94.5l13.3 12.16h61.54l13.04-12.3v-94.2l-13.4-11.78H69.39z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.64 34.91h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.06 34.91h27.69"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.92 2.92"
      />
      <path
        d="M167.21 34.91h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.46 34.91v128.92"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.64 163.83h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.06 163.83h27.69"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.92 2.92"
      />
      <path
        d="M167.21 163.83h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
