import Axios, { AxiosError, AxiosResponse } from 'axios';
import * as qs from 'qs';

/**
 * Base api
 * @type {string}
 */
export const api = process.env.REACT_APP_API;

const ROUTES_TO_REDIRECT_WITH_401 = [
  '/account/orders',
  '/account/settings',
  '/account/profile',
];

/**
 * Get axios instance
 * @type {AxiosInstance}
 */
const axios = Axios.create({
  baseURL: api,
  withCredentials: true,
  paramsSerializer: (params: unknown) => {
    return qs.stringify(params, { arrayFormat: 'brackets' }); // indices: false,
  },
});

/**
 * Axios response interceptor
 */
axios.interceptors.response.use(
  // TODO: rewrite interceptor response
  // Do something with response data
  (response: AxiosResponse) => response.data.payload,
  // Do something with response error
  (error: AxiosError) => {
    if (error.response) {
      let err = {};
      let isRedirect = false;

      switch (error.response.status) {
        case 403:
          err = {
            status: error.response.status,
            title: error.response.statusText,
            message: 'Please activate user account',
          };
          break;
        case 401:
          isRedirect = ROUTES_TO_REDIRECT_WITH_401.some((url) =>
            window.location.pathname.includes(url),
          );
          err = {
            status: error.response.status,
            title: error.response.statusText,
            message:
              error.response.data.message ||
              'Please enter the correct credential',
          };

          if (isRedirect) {
            window.location.href = '/auth/signin';
          }
          break;
        default:
          err = {
            status: error.response.status,
            title: error.response.statusText,
            message: error.response.data.message || 'Unexpected Server Error',
          };
      }
      return Promise.reject(err);
    }
    return Promise.reject(error);
  },
);

export default axios;
