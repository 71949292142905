import React from 'react';
import Svg from '../Svg';

interface IEmailIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function EmailIcon(props: IEmailIconProps): JSX.Element {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M18.125 3H1.875C0.820312 3 0 3.85938 0 4.875V16.125C0 17.1797 0.820312 18 1.875 18H18.125C19.1406 18 20 17.1797 20 16.125V4.875C20 3.85938 19.1406 3 18.125 3ZM18.125 4.875V6.47656C17.2266 7.21875 15.8203 8.3125 12.8516 10.6562C12.1875 11.1641 10.8984 12.4141 10 12.375C9.0625 12.4141 7.77344 11.1641 7.10938 10.6562C4.14062 8.3125 2.73438 7.21875 1.875 6.47656V4.875H18.125ZM1.875 16.125V8.89844C2.73438 9.60156 4.02344 10.6172 5.9375 12.1406C6.79688 12.8047 8.32031 14.2891 10 14.25C11.6406 14.2891 13.125 12.8047 14.0234 12.1406C15.9375 10.6172 17.2266 9.60156 18.125 8.89844V16.125H1.875Z"
        fill="#928A8E"
      />
    </Svg>
  );
}
