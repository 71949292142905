import React from 'react';
import Svg from '../Svg';

interface IArrowLeftIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function ArrowLeftIcon(props: IArrowLeftIconProps): JSX.Element {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <path
        d="m6.637.29.547.519c.136.136.136.355 0 .464L2.945 5.54h9.352c.164 0 .328.137.328.328v.766a.353.353 0 0 1-.328.328H2.945l4.239 4.238c.136.11.136.328 0 .465l-.547.52c-.11.136-.328.136-.465 0L.457 6.469a.315.315 0 0 1 0-.465L6.172.289c.137-.137.355-.137.465 0Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
