import * as React from 'react';
import { observer } from 'mobx-react';
import { DIAMONDS_ITEMS } from 'utils/constants/header';
import { MenuItemLinks } from './MenuItemLinks';

export const DiamondsMenu = observer(() => {
  return (
    <div className="dropdown__content second">
      {DIAMONDS_ITEMS.map((item) => (
        <div key={item.text} className="dropdown__column">
          <MenuItemLinks item={item} />
        </div>
      ))}
    </div>
  );
});
