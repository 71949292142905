import * as React from 'react';
import { observer } from 'mobx-react';
import { TableParamsImpl } from '../../../../store';

interface ItemProps {
  property: TableParamsImpl;
  children: React.ReactNode;
}

export const Item = observer((props: ItemProps) => {
  const { property } = props;
  return (
    <li
      key={property.key}
      className={`${property.key === '_price' && 'feature-price'} ${
        property.isValuesEqual && property.key !== 'addToCart' ? 'equal' : ''
      }`}
    >
      <span className="label">{property.label}</span>
      {props.children}
    </li>
  );
});
