import React from 'react';
import Svg from '../../Svg';

interface IСushionDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function СushionDepthIcon(
  props: IСushionDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M142.1 56.02H56.51l-30.1 25.23v4.2l62.85 56.44h20.08l62.86-56.44v-4.2l-30.1-25.23zM26.41 85.45H172.2M172.2 81.25H26.41"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.3 56.02-28.19 9-14.16 16.23-8.41-12.62L33.86 75M99.3 56.02l28.19 9 14.17 16.23 8.41-12.62L164.74 75"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m39.69 81.25 16.82-25.23L99.3 81.25M32.98 91.36l49.42 38.58-41.09-44.49H57.8l31.46 56.44M164.74 91.36l-49.42 38.58 41.09-44.49h-16.49l-31.46 56.44"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.34 85.45 99.3 129.4 57.8 85.45"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.3 141.89V81.25l42.8-25.23 16.82 25.23"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.07 55.21h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.52 55.21h81.06"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.95 2.95"
      />
      <path
        d="M186.05 55.21h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.55 56.1v85.79"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.07 142.78h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.52 142.78h81.06"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.95 2.95"
      />
      <path
        d="M186.05 142.78h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
