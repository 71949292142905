import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import CompareStore from 'root/store/compare';
import Icon from 'components/common/Icon';

import './ComparePanel.scss';

interface ComparePanelProps extends RouteComponentProps {
  compare: CompareStore;
}

@observer
export class ComparePanel extends Component<ComparePanelProps> {
  handleClearCompare = (): void => {
    const { compare } = this.props;
    compare.clear();
  };

  render(): JSX.Element {
    const { compare } = this.props;

    return (
      <CSSTransition
        in={!!compare.list.length}
        timeout={1000}
        classNames="fadeInRight2"
        unmountOnExit
      >
        <div className="compare-panel">
          <div className="info">
            <Link className="info__link" to={{ pathname: '/compare' }}>
              Added to Compare ({compare.list.length})
            </Link>
          </div>

          <div
            className="trash btn btn_text-sm btn_clear"
            onClick={this.handleClearCompare}
          >
            <Icon id="trash" />
            {/* <img src={trash} /> */}
          </div>
        </div>
      </CSSTransition>
    );
  }
}
