import { makeAutoObservable } from 'mobx';
import { PreviewStoreImpl } from './PreviewTypes';

export const previewStore = makeAutoObservable<PreviewStoreImpl>({
  isPreviewShown: false,

  showPreview(value: boolean) {
    this.isPreviewShown = value;
  },
});
