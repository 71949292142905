import React, { ChangeEvent, useEffect } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import {
  SettingPropsImpl,
  TProfileSettingNames,
} from 'pages/AccountPage/components/ProfileSettings/ProfileSettings.types';

import './profileSettings.scss';

interface PropsImpl {
  store: SettingPropsImpl;
}

const ProfileSettingsPage = (props: PropsImpl) => {
  const {
    allowMailNotifications,
    sendNews,
    updateSettingParams,
    fetchSettings,
  } = props.store;

  const handleChange = action((event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as TProfileSettingNames;
    const value = event.target.checked;
    const settingParam = props.store[name];

    settingParam.set(value);
    void updateSettingParams();
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div className="content content-profile settings">
      <h1 className="page-title">SETTINGS</h1>
      <div className="content-main">
        <p>
          <label className="checkbox-container">
            <input
              name="allowMailNotifications"
              type="checkbox"
              checked={allowMailNotifications.get()}
              onChange={handleChange}
            />
            <span className="checkmark" />
            Allow Email notifications
          </label>
        </p>
        <p>
          <label className="checkbox-container">
            <input
              name="sendNews"
              type="checkbox"
              checked={sendNews.get()}
              onChange={handleChange}
            />
            <span className="checkmark" />
            Keep me up to date on news and exclusive offers
          </label>
        </p>
      </div>
    </div>
  );
};

export default observer(ProfileSettingsPage);
