import React from 'react';
import { observer } from 'mobx-react';
import Icon from 'components/common/Icon';
import ImageItem from 'components/common/ImageItem';
import EngraveColorCircle from 'components/common/EngraveColorCircle';
import { RingDetailImpl } from 'root/store/rings/one.store';
import { CartItemEngraveSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';

interface IHintForDiamondProps {
  product: RingDetailImpl;
  customizations?: {
    engrave?: CartItemEngraveSettings[] | undefined;
    size?: string | number | undefined;
  };
  handleChange: () => void;
}

const HintForJewelry = (props: IHintForDiamondProps) => {
  const {
    product: { photos, sku, metal, gender, weight, size },
    customizations,
    handleChange,
  } = props;

  return (
    <div className="hint-product-set__info">
      <ImageItem src={photos[0]} className="hint-product-set__img" />

      <span className="hint-product-set__name">{sku}</span>

      <span
        className="hint-product-set__name hint-product-set__name_mobile"
        onClick={handleChange}
      >
        {sku} <Icon id="pencil" />
      </span>

      <div className="hint-product-set__description">
        <div>
          {metal} | {gender} | Weight {weight} | Size{' '}
          {customizations?.size || size}
        </div>
        {customizations?.engrave?.map(({ color, text, font, description }) => (
          <div key={description}>
            Engraving |{' '}
            <span>
              {description} | {font.name} |{' '}
              <EngraveColorCircle color={color.name} />
              {color.name} | <span className="text">{text}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(HintForJewelry);
