import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FormControl, FormGroup } from '@quantumart/mobx-form-validation-kit';
import { CountryImpl, StateImpl } from 'root/store/app';
import {
  CustomerImpl,
  ShippingImpl,
  RecipientImpl,
  BillingImpl,
  TProfileFormName,
} from 'root/store/account/accountTypes';
import LightLoader from 'components/common/Loaders/LightLoader';
import Error500 from '../../../../components/app/errorPopups/Error500';
import CustomerForm from './components/Customer';
import ShippingForm from './components/Shipping';
import RecipientForm from './components/Recipient';
import BillingForm from './components/Billing';
import ProfilePicture from './components/ProfilePicture';
import { ProfileInterfaceProps, TFormValues } from './Profile.types';

import './Profile.scss';

type TForm =
  | FormGroup<CustomerImpl>
  | FormGroup<ShippingImpl>
  | FormGroup<RecipientImpl>
  | FormGroup<BillingImpl>;

function ProfilePage({
  user,
  profile,
  countries,
  profileStore,
}: ProfileInterfaceProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const {
    setError500Title,
    handleVisibleError500,
    error500Title,
    isVisibleError500,
  } = profileStore;
  const { customer, shipping, recipient, billing, updateUserPicture } = profile;
  const {
    params: { id: userId },
  } = user;

  const getProfileParams = useCallback((): void => {
    setIsLoading(true);

    if (!userId) return;

    profile.fetchCurrentProfile(userId);
    setIsLoading(false);
  }, [userId]);

  useEffect(() => {
    getProfileParams();
  }, [getProfileParams, userId]);

  const getCurrentCountry = (
    country: FormControl<number>,
  ): CountryImpl | undefined =>
    countries.find(({ id }) => id === country.value);

  const handleChangeSelect = (
    formName: TProfileFormName,
    name: string,
    value: CountryImpl | StateImpl,
  ): void => {
    const inputForm = profile[formName].controls[name] as FormControl;

    profile.changeParams(formName, name, value.id);
    inputForm.setTouched(true);
  };

  const showErrors = ({
    invalid,
    touched,
    value,
  }: FormControl<string>): boolean => {
    return (invalid && touched) || (invalid && !!value);
  };

  const validateForm = (withFocus = true): boolean => {
    const formValues: TFormValues = { customer, shipping };

    const isDifferentRecipient = recipient.controls.isDifferent.value;
    const isDifferentBilling = !billing.controls.likeShiping.value;

    if (isDifferentRecipient) {
      formValues.recipient = recipient;
    }
    if (isDifferentBilling) {
      formValues.billing = billing;
    }

    return Object.values(formValues).every((form: TForm) =>
      Object.keys(form.controls).every((paramName) => {
        const inputForm = form.controls[paramName];

        if (paramName === 'state') {
          const country = form.controls.country as FormControl<number>;
          const countryParams = getCurrentCountry(country);

          withFocus && inputForm.setTouched(true);
          return countryParams && countryParams?.states?.length
            ? inputForm.valid
            : true;
        }

        withFocus && inputForm.setTouched(true);
        return inputForm.valid;
      }),
    );
  };

  const handleSubmit = (): void => {
    const isValid = validateForm();

    userId &&
      isValid &&
      profile
        .updateProfileParams(userId)
        .then(() => {
          setIsEdit(false);
        })
        .catch((error) => {
          const { status, title } = error;

          title && setError500Title(title);
          status === 500 && handleVisibleError500(true);
        });
  };

  return (
    <div className="content content-profile">
      <Error500
        title={error500Title}
        visible={isVisibleError500}
        handleVisible={handleVisibleError500}
      />

      <h1 className="page-title">PROFILE</h1>
      {isLoading ? (
        <LightLoader />
      ) : (
        <div className="content-main">
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <div className="profile-photo-wrap">
                <ProfilePicture
                  userId={userId}
                  picturePath={customer.controls.picturePath}
                  updateUserPicture={updateUserPicture}
                  handleVisibleError={handleVisibleError500}
                  setError500Title={setError500Title}
                  disabled={!isEdit}
                />

                <button
                  className="btn btn_brown btn_sm btn_stretch"
                  title="Edit"
                  onClick={isEdit ? handleSubmit : () => setIsEdit(true)}
                  disabled={isEdit && !validateForm(false)}
                >
                  {isEdit ? 'Update' : 'Edit'}
                </button>
              </div>
            </div>

            <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
              <CustomerForm
                customer={customer}
                countries={countries}
                getCurrentCountry={getCurrentCountry}
                onChangeSelect={handleChangeSelect}
                isEdit={isEdit}
                showErrors={showErrors}
              />
            </div>

            <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
              <ShippingForm
                shipping={shipping}
                countries={countries}
                getCurrentCountry={getCurrentCountry}
                onChangeSelect={handleChangeSelect}
                isEdit={isEdit}
                showErrors={showErrors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 offset-sm-2 offset-md-2">
              <RecipientForm
                recipient={recipient}
                isEdit={isEdit}
                showErrors={showErrors}
              />
            </div>

            <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
              <BillingForm
                billing={billing}
                countries={countries}
                getCurrentCountry={getCurrentCountry}
                onChangeSelect={handleChangeSelect}
                isEdit={isEdit}
                showErrors={showErrors}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(ProfilePage);
