import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

/**
 * Component ScrollToTopOnUpdate can be used globally for all pages and placed after the opening tag <Router>.
 */
class ScrollToTopOnUpdate extends React.Component<RouteComponentProps> {
  componentDidUpdate(prevProps: RouteComponentProps) {
    this.props.location.pathname !== prevProps.location.pathname &&
      window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTopOnUpdate);
