import * as React from 'react';
import { observer } from 'mobx-react';
import { MainPage } from './components/MainPage';

import mainStore from './store';

/**
 * Page Component
 */

export const FineJewelryMainPage = observer(() => {
  return <MainPage store={mainStore} />;
});
