import { IProductCustomizations } from 'root/store/products/types/product.type';

export const getProductImage = (product: any): string => {
  if (product.photos && product.photos.length) {
    return product.photos[0];
  }
  if (product.photo && typeof product.photo === 'object')
    return product.photo[0];
  if (
    product.photo &&
    typeof product.photo === 'string' &&
    product.photo.includes('[')
  ) {
    return JSON.parse(product.photo)[0];
  }
  if (
    product.photo &&
    typeof product.photo === 'string' &&
    !product.photo.includes('[')
  ) {
    return product.photo;
  }
  if (product.shape) {
    const nameOfImage = product.shape.split(' ').join('_').toLowerCase();
    /* eslint @typescript-eslint/no-var-requires: "off" */
    return require(`img/shapes/${nameOfImage}.png`).default;
  }
  return '';
};

export function addDaysToADate(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const convertQueryDataToBase64 = (data: any): string =>
  Buffer.from(JSON.stringify(data)).toString('base64');

export const convertQueryDataFromBase64 = (
  data: string,
): IProductCustomizations =>
  JSON.parse(Buffer.from(JSON.stringify(data), 'base64').toString('ascii'));
