import {
  action,
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';

type CompareItemsType = {
  id: number;
  category: string;
  [key: string]: number | string;
};

export type MainPageItems = {
  link: string;
  params: { filters: { [key: string]: any } };
  value: string;
  title: string;
  description: string;
  icon: string;
  popular: boolean;
  [key: string]: any;
};

export interface CarouselStoreImpl {
  items: CompareItemsType[] | MainPageItems[];
  offset: number;
  activeIndex: number;
  activeItem: CompareItemsType | MainPageItems;
  updateActiveIndex(v: number): void;
  updateActiveIndexInfinite(v: number): void;
  push(item: unknown, offset: number): void;
  update(list: unknown[], offset?: number): void;
  reset(): void;
  changeOffset(v: number): void;
}

export default class CarouselStore implements CarouselStoreImpl {
  items: IObservableArray = observable([]);
  offset = 1;
  activeIndex = 0;

  constructor(activeIndex?: number, items?: IObservableArray) {
    makeAutoObservable(this);
    if (items) this.items = items;
    runInAction(() => {
      this.activeIndex = activeIndex || 0;
    });
  }

  get activeItem(): CompareItemsType | MainPageItems {
    return this.items[this.activeIndex] || {};
  }

  @action
  updateActiveIndex(v: number): void {
    const current: number = this.activeIndex + v;
    if (current < 0 || current < this.items.length) {
      this.activeIndex = current;
    }
  }

  @action
  updateActiveIndexInfinite(v: number): void {
    this.activeIndex = v;
  }

  push(item: MainPageItems | CompareItemsType, activeIndex: number): void {
    this.items.push(item);
    this.activeIndex = activeIndex;
  }

  update(
    list: CompareItemsType[] | MainPageItems[],
    activeIndex?: number,
  ): void {
    (this.items as CompareItemsType[] | MainPageItems[]) = list;
    if (activeIndex !== undefined) this.activeIndex = activeIndex;
  }

  reset(): void {
    this.items.clear();
    this.activeIndex = 0;
  }

  changeOffset(v = 1): void {
    const current: number = this.offset + v;
    if (current < 0 || current < this.items.length) {
      this.offset = current;
    }
  }
}
