import { ProductCategories } from 'root/ProductTypes';

export const PRODUCT_TYPE_TITLE_LIST: { [item: string]: string } = {
  [ProductCategories.RINGS]: 'Ring',
  [ProductCategories.ENGAGEMENT_RINGS]: 'Ring',
  [ProductCategories.WEDDING_RINGS]: 'Ring',
  [ProductCategories.NECKLACES]: 'Necklace',
  [ProductCategories.EARRINGS]: 'Earrings',
  [ProductCategories.PENDANTS]: 'Pendant',
  [ProductCategories.BRACELETS]: 'Bracelet',
  [ProductCategories.STONES]: 'Diamond',
  [ProductCategories.DIAMONDS]: 'Diamond',
  [ProductCategories.DIAMONDS_LAB_CREATED]: 'Diamond',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
