import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function CuttingB(props: IProps): JSX.Element {
  return (
    <Svg width="106" height="104" viewBox="0 0 106 104" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 46.2546L0 39.2613L12.5 38.9616L14.8 30.1701L25 34.8656L16.2 38.562L9 46.2546ZM25.5 34.5658L30 22.977L41.8 30.8694L52.9 20.1797L64 30.8694L75.8 22.977L80.3 34.5658L74.1 31.9683L53 29.8704L31.9 31.9683L25.5 34.5658ZM80.7 34.7657L90.9 30.0702L93.2 38.8617L105.7 39.1614L96.7 46.1547L89.5 38.3622L80.7 34.7657Z"
        fill="#F1ECD9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5 72.1299H74.2V73.2288L62.7 83.2192H42.9L31.5 73.2288V72.1299Z"
        fill="#7AC299"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.0977 1.69775H74.1977V73.229L63.0977 82.9196V1.69775Z"
        fill="url(#paint0_linear_9184_212677)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5984 82.9196V11.7881H47.0984L36.9984 1.69775L26.8984 11.7881H31.4984V73.229L42.5984 82.9196Z"
        fill="url(#paint1_linear_9184_212677)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.1 0H74.2V18.4822H78.8L68.6 28.5725L58.5 18.4822H63.1V0Z"
        fill="url(#paint2_linear_9184_212677)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.8016 91.8122L98.1016 52.4501L97.8016 47.4549L89.5016 38.4635L74.0016 31.9698L52.8016 29.9717L31.7016 31.9698L16.2016 38.4635L7.90155 47.4549L7.60156 52.4501L52.8016 91.8122Z"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 91.8122L57.1008 80.4231L69.2008 63.8391L84.6008 56.3463L87.8008 46.4558L81.8008 37.2647L74.0008 31.9697"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.8008 37.2656L74.2008 42.061L52.8008 43.8593L66.9008 50.353L84.6008 56.3473"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.2008 42.0596L66.9008 50.3516L52.8008 60.5418"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.8031 46.4553L74.2031 42.0596"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 60.542L69.2008 63.8388"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 64.5386L69.1008 59.1438L83.4008 60.5424L92.7008 54.6481L98.1008 52.4502"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.7016 54.647L84.6016 56.3454"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.1008 52.4503L87.8008 46.4561L92.7008 54.6482"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.5 38.4634L98.1 52.4499"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.8008 37.2656L89.5008 38.4645"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9023 50.3525L69.1024 59.1441"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.4 60.542L71 76.0271"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.1016 80.4229L83.4016 60.542"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 64.5381L57.1008 80.4228"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8008 91.8122L48.5008 80.4231L36.5008 63.8391L21.1008 56.3463L17.8008 46.4558L23.9008 37.2647L31.7008 31.9697"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9016 37.2656L31.5016 42.061L52.8016 43.8593L38.7016 50.353L21.1016 56.3473"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5 42.0596L38.7 50.3516L52.8 60.5418"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8008 46.4553L31.5008 42.0596"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8 60.542L36.5 63.8388"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8016 64.5386L36.6016 59.1438L22.3016 60.5424L13.0016 54.6481L7.60156 52.4502"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 54.647L21.1 56.3454"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.60156 52.4503L17.8016 46.4561L13.0016 54.6482"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2016 38.4634L7.60156 52.4499"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9031 37.2656L16.2031 38.4645"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.7016 50.3525L36.6016 59.1441"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3008 60.542L34.7008 76.0271"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.5008 80.4229L22.3008 60.542"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8 64.5381L48.5 80.4228"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9184_212677"
          x1="68.647"
          y1="112.456"
          x2="68.647"
          y2="1.71923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9184_212677"
          x1="37.0227"
          y1="-4.28799"
          x2="37.0227"
          y2="100.563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="0.63" stopColor="#E9F4EE" />
          <stop offset="0.86" stopColor="#FDFEFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9184_212677"
          x1="68.6493"
          y1="30.3165"
          x2="68.6493"
          y2="-0.0333686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57A97F" />
          <stop offset="1" stopColor="#E1EFE6" />
        </linearGradient>
      </defs>
    </Svg>
  );
}
