import {
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { ProductCategories } from 'root/ProductTypes';
import OrderStore from 'root/store/order';
import PaginateStore from 'root/store/paginate';
import { IJewelryCatalogResponse } from 'service/api/apiTypes/catalogsApiTypes';
import { fetchFullRings } from 'service/api/rings';
import { getCurrentCategory } from 'lib/utils';
import { EngagementRingsFiltersStore } from './engagementRingFilters.store';
import {
  ICatalogProducts,
  ICatalogQueryParams,
} from 'root/store/products/types/product-catalog.type';
import { TEngagementRingsCatalog } from '../types';

class EngagementRingCatalogStore
  implements
    ICatalogProducts<TEngagementRingsCatalog, EngagementRingsFiltersStore>
{
  filters!: EngagementRingsFiltersStore;
  order!: OrderStore;
  pagination!: PaginateStore;
  list: IObservableArray<TEngagementRingsCatalog> =
    observable<TEngagementRingsCatalog>([]);

  loading = false;

  constructor() {
    makeAutoObservable(this);
    this.resetCatalog();
  }

  get queryParams(): ICatalogQueryParams<EngagementRingsFiltersStore> {
    return {
      page: this.pagination.page,
      limit: this.pagination.limit,
      sortBy: this.order.sortBy,
      sortOrder: this.order.sortOrder,
      filters: this.filters.queryParams,
    };
  }

  resetCatalog(): void {
    this.list.clear();
    this.filters = new EngagementRingsFiltersStore();
    this.order = new OrderStore();
    this.pagination = new PaginateStore();
  }

  putCatalog(products: TEngagementRingsCatalog[] = []) {
    this.list.replace(products);
  }

  updateCatalog(query: ICatalogQueryParams<EngagementRingsFiltersStore>) {
    this.pagination.setPage(Number(query.page));
    this.filters.update(query.filters);
    this.pagination.setLimit(Number(query.limit));
    this.order.updateOrder(query.sortBy, query.sortOrder);
  }

  *loadProducts(
    query?: ICatalogQueryParams<EngagementRingsFiltersStore>,
  ): Generator<
    Promise<TEngagementRingsCatalog>,
    void,
    IJewelryCatalogResponse
  > {
    try {
      const start = Date.now();
      const params = { ...this.queryParams, ...query };
      const { results, total, totalPages } = yield fetchFullRings(params);
      results.map((el) =>
        // TODO: check this condition
        // why is the selection by WEDDING_RINGS
        getCurrentCategory(el.categories).includes(
          ProductCategories.WEDDING_RINGS,
        ),
      );
      this.putCatalog(results);
      this.pagination.setTotalPages(totalPages);
      this.pagination.setTotal(total);
      const delay = Date.now() - start;
      setTimeout(
        () => {
          runInAction(() => {
            this.loading = false;
          });
        },
        delay < 700 ? 700 - delay : 0,
      );
    } catch {
      window.location.reload();
    }
  }
}

export default new EngagementRingCatalogStore();
