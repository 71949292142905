import React from 'react';
import { observer } from 'mobx-react-lite';
import FormFriendContactItem from '../FormFriendContactItem';
import { FormFriendContactTypeImpl } from './FormFriendContact.types';
import { config } from './config';

import './FormFriendContact.scss';

export function FormFriendContact({
  checkedItem,
  onChange,
}: FormFriendContactTypeImpl): JSX.Element | null {
  if (!config.length) return null;

  return (
    <div className="form-friend-contacts">
      {config.map((value) => {
        return (
          <FormFriendContactItem
            key={value}
            value={value}
            checkedItem={checkedItem}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
}

export default observer(FormFriendContact);
