import React from 'react';
import { observer } from 'mobx-react-lite';
import { HintProductProps } from '../../Hint.types';

import './HintProduct.scss';

export function HintProduct({
  product,
  isProductLoading,
}: HintProductProps): JSX.Element | null {
  if (!product || isProductLoading) return null;

  return (
    <div className="item">
      <div className="item-image">
        <img src={product.photos[0] || product.defaultPhoto} alt="" />
      </div>

      <div className="item-characteristics">
        <h2 className="diamond-sku diamond-sku_mb-20 bold">{product.sku}</h2>
      </div>

      <div className="flex-wrapper">
        <div className="item-price">
          <h2 className="diamond-price">{product.price}</h2>
        </div>
      </div>
    </div>
  );
}

export default observer(HintProduct);
