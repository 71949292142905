import React from 'react';
import Svg from '../Svg';

interface IFavoriteIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function FavoriteIcon(props: IFavoriteIconProps): JSX.Element {
  return (
    <Svg width="22" height="20" viewBox="0 0 22 20" fill="none" {...props}>
      <path
        d="M18.89 3.04c-2.265-1.915-5.742-1.642-7.89.585-2.188-2.227-5.664-2.5-7.93-.586C.14 5.5.57 9.523 2.68 11.672l6.836 6.992c.39.39.898.625 1.484.625.547 0 1.055-.234 1.445-.625l6.875-6.992c2.07-2.149 2.5-6.172-.43-8.633Zm-.937 7.304-6.836 6.992c-.078.078-.156.078-.273 0l-6.836-6.992c-1.446-1.446-1.719-4.18.273-5.86 1.524-1.289 3.867-1.093 5.352.391L11 6.281l1.367-1.406c1.445-1.484 3.79-1.68 5.313-.43 1.992 1.72 1.718 4.453.273 5.899Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
