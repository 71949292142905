import React from 'react';
import { observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';
import { QuestionsType } from '../../FAQPage.type';
import { joinClasses } from 'lib/utils';
import Icon from 'components/common/Icon';

interface IQuestion {
  question: QuestionsType;
  isOpened: boolean;
  onClick: (path: QuestionsType['path']) => void;
}

const Question = ({ question, isOpened, onClick }: IQuestion): JSX.Element => (
  <>
    <div className="dialog-question-wrap">
      <div
        className={joinClasses(['dialog__question', isOpened && 'active'])}
        onClick={() => onClick(question.path)}
      >
        <span>{question.questionName}</span>
        <Icon id="arrow_top" className="dialog-question__arrow" />
      </div>
    </div>

    {isOpened && (
      <CSSTransition in appear timeout={1000} classNames="fadeInDown-slow">
        <div className="dialog-venori-says-wrap answer">
          <div className="dialog-venori-says-icon">
            <Icon id="logo_mobile" />
          </div>
          <div className="dialog__venori-says answer">
            <h2 className="answer__title">{question.questionName}</h2>
            {question.answer}
          </div>
        </div>
      </CSSTransition>
    )}
  </>
);

export default observer(Question);
