import React from 'react';
import Icon from 'components/common/Icon';
import { observer } from 'mobx-react';
import {
  CartItemEngraveSettings,
  IEngraving,
} from 'pages/ProductDetailPage/ProductDetailPage.types';
import EngraveBlockDetails from '../EngraveBlockDetails';

interface IEngraveBlockItem {
  description: IEngraving['description'];
  isAddedBand: boolean;
  index: number;
  currentEngraveSettings?: CartItemEngraveSettings;
  onOpenPopup: (index: number) => void;
  onChange: (index: number) => void;
  onRemove: (index: number) => void;
  disableAction: boolean;
}

export function EngraveBlockItem({
  description,
  onOpenPopup,
  onChange,
  onRemove,
  isAddedBand,
  currentEngraveSettings,
  index,
  disableAction,
}: IEngraveBlockItem): JSX.Element {
  return (
    <>
      <div className="product-detail-page__product-engrave-description">
        <span
          role="none"
          className="product-detail-page__product-engrave-action"
          onClick={() => onOpenPopup(index)}
        >
          <Icon id="inside_bang" className="icon" />
          <span>Add Engrave</span>
        </span>
        {isAddedBand && !disableAction && (
          <div className="product-detail-page__product-engrave-controls">
            <span role="none" onClick={() => onChange(index)}>
              <Icon id="change" className="icon" />
            </span>
            <span role="none" onClick={() => onRemove(index)}>
              <Icon id="remove" className="icon" />
            </span>
          </div>
        )}
      </div>
      {isAddedBand && (
        <EngraveBlockDetails engraveSettings={currentEngraveSettings} />
      )}
    </>
  );
}

export default observer(EngraveBlockItem);
