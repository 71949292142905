import React from 'react';
import Svg from '../../Svg';

interface IAsscherTableIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function AsscherTableIcon(
  props: IAsscherTableIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="m68.11 62.26-5.12 4.26-3.19 4.69-4.05 10.66v3.41l9.59 29.83 16.62 19.39 17.35 16.41-27.15-16.41-24.93-18.75L28.9 86.13v-3.84l13.21-11.5 7.25-4.27 7.24-4.26h42.71M130.58 62.26l5.11 4.26 3.2 4.69 4.05 10.66v3.41l-9.59 29.83-16.62 19.39-17.35 16.41 27.15-16.41 24.94-18.75 18.32-29.62v-3.84l-13.21-11.5-7.25-4.27-7.24-4.26H99.38M28.9 82.29h139.87M28.9 86.06h139.87M41.2 71.57h115.56M49.36 66.52h100.05M47.23 115.75h103.25M72.16 134.5h53.28"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.63 86.06v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.63 81.42V45.33"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.14 3.14"
      />
      <path
        d="M27.63 43.76v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.63 42.58h143.15"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.78 86.06v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.78 81.42V45.33"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.14 3.14"
      />
      <path
        d="M170.78 43.76v-1.5M56.35 62.26v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.35 57.94V31.13"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.82 2.82"
      />
      <path
        d="M56.35 29.72v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.35 28.47h85.71"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.06 62.26v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.06 57.94V31.13"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.82 2.82"
      />
      <path
        d="M142.06 29.72v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
