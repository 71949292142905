import React from 'react';
import Svg from '../../Svg';

interface IHeartMeasWidthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function HeartMeasWidthIcon(
  props: IHeartMeasWidthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M32.41 17.8h134.44"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.85 69.22v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.85 64.56V21.87"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.16 3.16"
      />
      <path
        d="M166.85 20.29v-1.5M32.41 69.22v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.41 64.56V21.87"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.16 3.16"
      />
      <path
        d="M32.41 20.29v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157 44a25.575 25.575 0 0 0-5.13-4.67c-30-20.69-51.86 16.55-51.86 16.55S78.17 18.64 48.15 39.33A25.565 25.565 0 0 0 43 44c-39.67 47.17 57 119.55 57 119.55S196.66 91.17 157 44z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m100 163.55-10.61-32.4-29.56-5.79 1.91-23.67-25.62-15.86 13.47-18.64-3.9-25.94L100 55.88l54.31-14.63-3.9 25.94 13.47 18.64-25.62 15.86 1.91 23.67-29.56 5.79-10.61 32.4z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m74.1 34.18 6.1 16.37-10.64 12.31.55 24.02-8.37 14.81 22.06 6.98 5.59 22.48-10.35 14.41M125.82 34.18l-6.1 16.37 10.65 12.31-.56 24.02 8.38 14.81-22.07 6.98-5.58 22.48 10.34 14.41"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m100 55.88-30.44 6.98-19.97 4.33 20.52 19.69 13.69 21.79 16.2 15.5-10.61 6.98"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.77 55.88 30.45 6.98 19.97 4.33-20.53 19.69-13.69 21.79-16.2 15.5 10.62 6.98M61.74 101.68l-9.73 13.75M138.19 101.72l9.73 13.75M49.59 67.19l-15.04-6.81M150.23 67.24l15.04-6.81"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
