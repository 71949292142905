import * as React from 'react';
import { matchPath, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import * as qs from 'qs';
import Icon from 'components/common/Icon';
import { ItemsLinkType, MenuItemType } from '../../Header.types';

interface MenuItemLinksProps {
  item: MenuItemType;
}

export const MenuItemLinks = observer((props: MenuItemLinksProps) => {
  const { item } = props;

  return (
    <div className="dropdown__column-content">
      <input
        className="dropdown__column-checkbox"
        id={`checkbox${item.index}_${item && item.text}`}
        type="checkbox"
      />
      {item?.link ? (
        <NavLink to={item.link} className="dropdown__title-link dropdown__link">
          {item.text}
        </NavLink>
      ) : (
        <label
          className="dropdown__title dropdown__title_with-caret"
          htmlFor={`checkbox${item.index}_${item && item.text}`}
        >
          <div className={item.index === 10 ? 'mobile-displaying' : ''}>
            <div>{item.text}</div>
            {item.index === 10 ? <div> Lab-Grown Jewelry</div> : null}
          </div>
          <Icon id="caret" />
        </label>
      )}

      <div className="dropdown__links-wrapper">
        {item.items.length &&
          item.items.map((subItem: ItemsLinkType) => {
            const { text, link, params } = subItem;
            const queryString = qs.stringify(params || '', {
              arrayFormat: 'brackets',
            });
            return (
              <div key={`${text}`}>
                <NavLink
                  to={{
                    pathname: link,
                    search: queryString,
                    state: { needToResetFilters: true },
                  }}
                  className={'dropdown__link'}
                  activeClassName={'active'}
                  isActive={(match, location) => {
                    if (queryString) {
                      const isPromoExistAndMatches = !(
                        location.search.includes('promo') &&
                        !queryString.includes('promo')
                      );
                      return (
                        !!match &&
                        isPromoExistAndMatches &&
                        !![queryString].filter((param) => {
                          const matchReturn = (
                            matchPath(location.search, {
                              path: `(.*)${param}(.*)`,
                              exact: true,
                              strict: false,
                            }) as any
                          )?.params[1];
                          return (
                            matchReturn !== '_LAB_CREATED' &&
                            matchReturn !== undefined
                          );
                        })?.length
                      );
                    }

                    return !!match;
                  }}
                >
                  {text}
                </NavLink>
              </div>
            );
          })}
      </div>
    </div>
  );
});
