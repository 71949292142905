import asscher from './asscher';
import cushion from './cushion';
import cushionModified from './cushionModified';
import emerald from './emerald';
import heart from './heart';
import marquise from './marquise';
import oval from './oval';
import pear from './pear';
import princess from './princess';
import radiant from './radiant';
import round from './round';

export default {
  ...asscher,
  ...cushion,
  ...cushionModified,
  ...emerald,
  ...heart,
  ...marquise,
  ...oval,
  ...pear,
  ...princess,
  ...radiant,
  ...round,
};
