import React from 'react';
import Svg from '../../Svg';

interface IMarquiseMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function MarquiseMeasLengthIcon(
  props: IMarquiseMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M100 33.63h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.5 33.63h40.54"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M146.54 33.63h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.9 33.63v130.74"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100 164.37h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.5 164.37h40.54"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M146.54 164.37h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.28 33.63s-76.88 62.06 0 130.78c76.88-68.72 0-130.78 0-130.78z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.28 33.63 74.13 133.84l20.01 12.35-5.86 7.32M99.28 33.63l25.14 100.21-20 12.35 5.86 7.32"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m87.83 45.14 11.45 13.61-34.17 40.27 34.17 41.12-5.14 6.05"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.72 45.14 99.28 58.75l34.17 40.27-34.17 41.12 5.14 6.05M94.42 52.98 74.13 65.23l25.15 99.18"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m104.13 52.98 20.29 12.25-25.14 99.18"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
