import * as React from 'react';
import { Link, matchPath, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { joinClasses } from 'lib/utils';
import { ROUTER_PATHS } from 'root/routes/constants';
import Icon from 'components/common/Icon';
import Preview from '../Preview';
import { DropdownMenu } from './components';
import type { HeaderProps, IDropdownMenuHeaderItem } from './Header.types';
import { DROPDOWN_MENU_LIST } from './Header.constants';
import { GlobalSearch } from '../GlobalSearch';

import './Header.scss';

@observer
export class Header extends React.Component<HeaderProps> {
  constructor(props: HeaderProps) {
    super(props);
    const { store } = props;

    store.setStickyHeaderRef(React.createRef<HTMLElement>());
    this.resizeHeaderOnScroll();
    // listeners
    window.addEventListener('scroll', () => {
      this.resizeHeaderOnScroll();
    });
  }

  componentDidMount(): void {
    try {
      this.props.cart.find();
      this.props.userIsAuth && this.props.favorite.loadFavorites();
    } catch (e) {
      console.error(e);
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', () => {
      this.resizeHeaderOnScroll();
    });
  }

  resizeHeaderOnScroll = (): void => {
    const { store } = this.props;
    runInAction(() => {
      store.isStickyHeaderActive.set(
        !!document.documentElement.scrollTop || !!document.body.scrollTop,
      );
    });
  };

  closeMobileMenu = () => {
    this.props.store.isMobileMenuOpen && this.props.store.closeMobileMenu();
  };

  toggleMobileMenu = () => {
    this.props.store.toggleMobileMenuOpen();
  };

  render(): JSX.Element {
    const {
      store,
      cart,
      className,
      compare,
      favorite,
      browserWindowType: {
        mediaQuery: { isMobile, isDesktop },
      },
    } = this.props;

    return (
      <header
        className={joinClasses([
          'grid-area-header header header_sticky',
          className,
          store.isStickyHeaderActive.get() && 'active',
          ...store.className,
        ])}
        ref={store.stickyHeaderRef}
      >
        <div className="item header__logo">
          <Link to="/" title="Venori">
            <Icon
              id={isMobile ? 'logo_mobile' : 'logo'}
              onClick={this.closeMobileMenu}
            />
          </Link>
        </div>

        <nav className="item header__menu">
          <div id="menu-toggle">
            <input
              className={joinClasses([
                'menu__checkbox',
                store.isMobileMenuOpen && 'open_menu',
              ])}
              type="checkbox"
              id="menu__checkbox"
              onClick={this.toggleMobileMenu}
            />
            <span></span>
            <span></span>
            <span></span>

            <div id="menu" className="menu-toggle-block">
              <div className="menu_scrollable">
                <div className="menu-part">
                  {Object.values(DROPDOWN_MENU_LIST).map(
                    (category: IDropdownMenuHeaderItem, index) => (
                      <DropdownMenu
                        key={index}
                        dropdownCategory={category}
                        closeMobileMenu={this.closeMobileMenu}
                        isDesktop={isDesktop}
                      />
                    ),
                  )}

                  <NavLink
                    to={ROUTER_PATHS.EDUCATION_CUTTING_PATH}
                    className={'link menu__link menu_item_link'}
                    activeClassName={'active'}
                    isActive={(m, l) =>
                      !!matchPath(l.pathname, {
                        path: ROUTER_PATHS.EDUCATION_PATH,
                      })
                    }
                  >
                    Education
                  </NavLink>

                  <NavLink
                    to={ROUTER_PATHS.FAQ_ABOUT_PATH}
                    className={'link menu__link menu_item_link'}
                    activeClassName={'active'}
                    isActive={(m, l) =>
                      !!matchPath(l.pathname, { path: ROUTER_PATHS.FAQ_PATH })
                    }
                  >
                    FAQ
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div className="item header__actions">
          <div className="header__actions-item">
            <GlobalSearch triggerComponent={<Icon id="search" />} />
          </div>

          {this.props.userIsAuth && (
            <div className="header__actions-item">
              <span className="cart-count-items">{favorite.list.length}</span>
              <Link to="/account/favorites" title="Favorite">
                <Icon id="favorite" />
              </Link>
            </div>
          )}

          <div className="header__actions-item">
            <span className="cart-count-items">{compare.list.length}</span>
            <Link to="/compare" title="Compare">
              <Icon id="compare" />
            </Link>
          </div>

          <div className="header__actions-item preview-parent">
            <span className="cart-count-items">{cart.items.length}</span>

            <div
              className={`preview-block ${
                this.props.history.location.pathname.includes('/cart') ||
                !cart.items.length
                  ? 'no-hover'
                  : ''
              }`}
            >
              <Link
                to="/cart"
                title="Shopping cart"
                className="header__actions-item_link"
              >
                <Icon id="shopping" />
              </Link>

              <Preview />
            </div>
          </div>

          <div className="header__actions-item">
            <Link
              to={this.props.userIsAuth ? '/account/profile' : '/auth/signin'}
              title="Profile"
            >
              <Icon id={this.props.userIsAuth ? 'user_active' : 'user'} />
            </Link>
          </div>
        </div>
      </header>
    );
  }
}
