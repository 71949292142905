import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function DiamondSize100(props: IProps): JSX.Element {
  return (
    <Svg width="51" height="51" viewBox="0 0 51 51" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.855 1.2C12.71 1.2 2.054 11.856 2.054 25.001c0 13.145 10.656 23.8 23.8 23.8 13.145 0 23.801-10.655 23.801-23.8 0-13.145-10.656-23.8-23.8-23.8ZM.854 25.001C.854 11.193 12.047 0 25.854 0c13.808 0 25.002 11.193 25.002 25.001 0 13.808-11.194 25.001-25.002 25.001-13.807 0-25-11.193-25-25.001Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.093.295a.4.4 0 0 1 .045.564l-6.55 7.689 6.405 2.784a.4.4 0 0 1-.014.74L9.146 18.646 1.7 25.297a.4.4 0 1 1-.533-.596l7.5-6.7a.4.4 0 0 1 .12-.074l15.997-6.248-6.01-2.613a.4.4 0 0 1-.145-.626l6.9-8.1a.4.4 0 0 1 .564-.045Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.601 2.022a.4.4 0 0 1 .51.246l2.2 6.3a.4.4 0 0 1 .004.25l-2 6.5a.387.387 0 0 1-.01.03l-3.9 9.8a.4.4 0 0 1-.713.06l-4.035-6.592-5.585-2.45a.4.4 0 1 1 .322-.732l5.7 2.5a.4.4 0 0 1 .18.157l3.683 6.018 3.598-9.042 1.957-6.359-2.157-6.176a.4.4 0 0 1 .246-.51Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.566 7.202a.4.4 0 0 1 .314-.1l10.1 1.2a.4.4 0 1 1-.094.795L9.237 7.95l.096 10.346a.4.4 0 0 1-.8.007l-.1-10.8a.4.4 0 0 1 .133-.301ZM25.574.295a.4.4 0 0 1 .564.045l6.9 8.1a.4.4 0 0 1-.145.626l-6.011 2.613 15.997 6.248a.401.401 0 0 1 .12.074l7.5 6.7a.4.4 0 0 1-.532.596l-7.447-6.651-16.832-6.574a.4.4 0 0 1-.014-.74l6.404-2.784-6.55-7.69a.4.4 0 0 1 .046-.563Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.065 2.022a.4.4 0 0 1 .246.51l-2.157 6.176 1.957 6.36 3.598 9.042 3.683-6.019a.4.4 0 0 1 .18-.157l5.7-2.5a.4.4 0 1 1 .322.732l-5.585 2.45-4.035 6.593a.4.4 0 0 1-.713-.061l-3.9-9.8a.35.35 0 0 1-.01-.03l-2-6.5a.4.4 0 0 1 .004-.25l2.2-6.3a.4.4 0 0 1 .51-.246Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.1 7.202a.4.4 0 0 1 .133.301l-.1 10.8a.4.4 0 0 1-.8-.007L42.43 7.95 32.78 9.097a.4.4 0 0 1-.094-.795l10.1-1.2a.4.4 0 0 1 .314.1ZM1.135 24.733a.4.4 0 0 1 .565-.032l7.446 6.652 16.833 6.574a.4.4 0 0 1 .014.74l-6.405 2.784 6.55 7.69a.4.4 0 1 1-.61.518l-6.9-8.1a.4.4 0 0 1 .146-.626l6.01-2.614-15.996-6.247a.398.398 0 0 1-.121-.074l-7.5-6.7a.4.4 0 0 1-.032-.565Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.067 24.601a.4.4 0 0 1 .338.25l3.9 9.8.01.031 2 6.5a.4.4 0 0 1-.004.25l-2.2 6.3a.4.4 0 1 1-.756-.264l2.157-6.176-1.957-6.36-3.598-9.042-3.683 6.018a.4.4 0 0 1-.18.158l-5.7 2.5a.4.4 0 1 1-.322-.733l5.585-2.45 4.035-6.592a.4.4 0 0 1 .375-.19Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.937 31.299a.4.4 0 0 1 .396.404l-.096 10.345 9.649-1.146a.4.4 0 0 1 .094.794l-10.1 1.2a.4.4 0 0 1-.447-.4l.1-10.8a.4.4 0 0 1 .404-.397ZM50.531 24.733a.4.4 0 0 1-.031.565l-7.5 6.7a.4.4 0 0 1-.121.074L26.882 38.32l6.01 2.614a.4.4 0 0 1 .146.626l-6.9 8.1a.4.4 0 1 1-.61-.519l6.55-7.689-6.404-2.785a.4.4 0 0 1 .014-.739l16.832-6.574 7.447-6.652a.4.4 0 0 1 .564.032Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.599 24.601a.4.4 0 0 1 .375.19l4.035 6.593 5.585 2.45a.4.4 0 1 1-.322.732l-5.7-2.5a.4.4 0 0 1-.18-.158l-3.683-6.018-3.598 9.043-1.957 6.359 2.157 6.176a.4.4 0 0 1-.756.263l-2.2-6.3a.4.4 0 0 1-.004-.249l2-6.5a.363.363 0 0 1 .01-.03l3.9-9.8a.4.4 0 0 1 .338-.25Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.73 31.299a.4.4 0 0 1 .403.396l.1 10.8a.4.4 0 0 1-.447.401l-10.1-1.2a.4.4 0 1 1 .094-.794l9.649 1.146-.096-10.346a.4.4 0 0 1 .396-.403Z"
        fill="#000"
      />
    </Svg>
  );
}
