import { IObservableArray, makeAutoObservable, observable, when } from 'mobx';
import {
  FormControl,
  FormGroup,
  patternValidator,
  requiredValidator,
  ValidationEventTypes,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';
import { CountryImpl, StateImpl } from 'root/store/app';
import { IFormCustomerStep, TFormCustomerValues } from './customerForm.types';

import store from 'root/store';
import { autofill } from '../cartCheckout.store';

const { app } = store;

export class CustomerFormStore {
  countries: IObservableArray<CountryImpl> = app.countries;
  states = observable.array<StateImpl>([]);

  form: FormGroup<IFormCustomerStep>;

  constructor() {
    makeAutoObservable(this);

    this.form = this.initialForm();

    when(
      () => autofill.isEmptyFill,
      () => {
        this.updateForm(autofill.customerFill);
      },
    );
  }

  /**
   * Reset all forms
   */
  resetForm(): void {
    this.form = this.initialForm();
    when(
      () => autofill.isEmptyFill,
      () => {
        this.updateForm(autofill.customerFill);
      },
    );
  }

  /**
   * Reset all forms
   */
  updateForm(values: TFormCustomerValues): void {
    const { controls } = this.form;
    for (const field in controls) {
      if (
        Object.hasOwnProperty.call(controls, field) &&
        Object.hasOwnProperty.call(values, field)
      ) {
        if (values[field]) {
          (controls[field] as FormControl).value =
            (values[field] as CountryImpl).id || values[field];
        }
      }
    }
  }

  /**
   * Return controls collection
   */
  private initialForm(): FormGroup<IFormCustomerStep> {
    return new FormGroup<IFormCustomerStep>({
      firstName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z]+$/,
              'First Name must contain only latin letters',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      lastName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z]+$/,
              'Last Name must contain only latin letters',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      email: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              'Wrong Email format',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      phone: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^(?=.*[0-9])[- +()0-9]+$/,
              'Phone must contain only digits, +, -',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      country: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
        onChangeValue: (value) => {
          const country: CountryImpl | undefined = this.countries.find(
            (c) => c.id === Number(value),
          );
          country && this.states.replace(country?.states || []);
        },
      }),
      state: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
        activate: () => {
          return !!this.states.length;
        },
      }),
      city: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
      }),
      address: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
      }),
      aptUnit: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            patternValidator(
              /[\d\s]*$/,
              'The field format is invalid',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
    });
  }
}

export default new CustomerFormStore();
