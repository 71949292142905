import * as React from 'react';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import { observable, action } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Favorite from 'root/store/favorite';
import CartStore from 'root/store/cart';
import { CompareImpl } from 'root/store/compare';
import InfoBrowserWindowType from 'root/store/infoBrowserWindowType.store';
import { IProductCustomizations } from 'root/store/products/types/product.type';
import LightLoader from 'components/common/Loaders/LightLoader';
import { ProductDetailPageImpl } from '../../ProductDetailPage.types';
import MatchingBandBlock from '../MatchingBandBlock';
import ProductFeatures from '../ProductFeatures';
import ProductDetails from '../ProductDetails';
import ProductImage from '../ProductImage';
import ProductActions from '../ProductActions';
import { PreviewStoreImpl } from 'components/app/Preview/PreviewTypes';
import { joinClasses } from 'lib/utils';

interface DetailPageProps extends RouteComponentProps {
  compare: CompareImpl;
  favorite: Favorite;
  productStore: ProductDetailPageImpl;
  cart: CartStore;
  productId: number;
  constantTypes: {
    [key: string]: {
      tooltip: string | boolean;
      isNeedDetail: boolean;
    };
  };
  productTitle: string;
  isOpen3DView: boolean;
  setSsOpen3DView: (value: boolean) => void;
  browserWindowType: InfoBrowserWindowType;
  cartPreview: PreviewStoreImpl;
  isWithSetHint: boolean;
  defaultCustomizationParams: IProductCustomizations | null;
  setWithItems: number[] | null;
}

@observer
class ProductDetailWrapper extends React.Component<DetailPageProps> {
  data: IObservableValue<string | undefined> = observable.box('');
  sprite: React.RefObject<HTMLInputElement>;
  isShowDetails: IObservableValue<boolean> = observable.box(false);

  loading: IObservableValue<boolean> = observable.box(true);

  constructor(props: DetailPageProps) {
    super(props);
    this.sprite = React.createRef();
    this.data.set('');
  }

  @action
  async componentDidMount() {
    const {
      productId,
      productStore,
      productTitle,
      history,
      defaultCustomizationParams,
      setWithItems,
    } = this.props;
    const cartProductGuid = history.location.search.split('?guid=')[1];

    await productStore.loadProduct(
      productTitle,
      productId,
      defaultCustomizationParams,
      setWithItems,
    );
    this.loading.set(false);

    if (cartProductGuid) {
      productStore.setViewFromCart(true);
      await this.handleCustomizationsFromPropductCart(cartProductGuid);
      history.replace(window.location.pathname, undefined);
    }
  }

  @action
  async componentDidUpdate(prevProps: DetailPageProps) {
    const {
      productId,
      productStore,
      productTitle,
      defaultCustomizationParams,
      setWithItems,
    } = this.props;
    if (prevProps.productId !== productId) {
      this.loading.set(true);
      await productStore.loadProduct(
        productTitle,
        productId,
        defaultCustomizationParams,
        setWithItems,
      );
      this.loading.set(false);
    }
  }

  handleCustomizationsFromPropductCart = async (productGuid: string) => {
    const { cart, productStore } = this.props;
    const cartItem = cart.items.find(({ guid }) => guid === productGuid);
    const customizations = cartItem?.customizations;

    const stoneId = cartItem?.customizations?.stones
      ? cartItem?.customizations?.stones[0]?.id
      : null;
    if (stoneId) {
      await productStore.loadStoneForSet('diamonds', stoneId);
    }
    if (!customizations?.engrave && !customizations?.size) return;

    productStore.setIsResetEngravingSettings(false);
    productStore.replaceCustomizations(
      customizations as IProductCustomizations,
    );

    cartItem?.price && productStore.updatePrice(cartItem?._price, true);
  };

  @action
  handleDetailsOn = (e: React.MouseEvent<HTMLElement>) => {
    this.data.set(e.currentTarget.dataset.detail);
    this.isShowDetails.set(true);
  };

  @action
  handleDetailsLeave = (e: React.MouseEvent<HTMLElement>) => {
    this.data.set(e.currentTarget.dataset.detail);
    this.isShowDetails.set(false);
    if (this.sprite.current) {
      this.sprite.current.classList.remove(
        `product-detail-page__product-sprite_${this.data}`,
      );
      this.sprite.current.style.cssText = '';
    }
  };

  render() {
    const {
      productStore,
      productId,
      constantTypes,
      favorite,
      cart,
      compare,
      productTitle,
      isOpen3DView,
      setSsOpen3DView,
      browserWindowType: {
        mediaQuery: { isMobile },
      },
      cartPreview,
      isWithSetHint,
    } = this.props;

    if (productStore.loading || !productStore.product) return <LightLoader />;

    return (
      <>
        <div className="product-detail-page__product-image__container">
          <ProductImage
            product={productStore.product}
            data={this.data.get()}
            sprite={this.sprite}
            loupeUrl={productStore.loupeUrl}
            isShowDetails={this.isShowDetails.get()}
            isOpen3DView={isOpen3DView}
            setSsOpen3DView={setSsOpen3DView}
          />
          {!isMobile && (
            <MatchingBandBlock matchingBand={productStore.matchingBand} />
          )}
        </div>

        <div
          className={joinClasses([
            'product-detail-page__product-features__container',
            isWithSetHint &&
              'product-detail-page__product-features__container_with-set-hint',
          ])}
        >
          <ProductFeatures
            product={productStore.product}
            productStore={productStore}
          />

          <ProductActions
            compare={compare}
            cart={cart}
            productId={productId}
            favorite={favorite}
            productStore={productStore}
            cartPreview={cartPreview}
          />

          {isMobile && (
            <MatchingBandBlock matchingBand={productStore.matchingBand} />
          )}
        </div>

        <ProductDetails
          productStore={productStore}
          handleDetailsOn={this.handleDetailsOn}
          handleDetailsLeave={this.handleDetailsLeave}
          constantTypes={constantTypes}
          productTitle={productTitle}
          isWithSetHint={isWithSetHint}
        />
      </>
    );
  }
}

export default withRouter(ProductDetailWrapper);
