import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function ColorG(props: IProps): JSX.Element {
  return (
    <Svg width="69" height="48" viewBox="0 0 69 48" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m34.624 46.9 33.524-29.127-.2-3.716-6.224-6.73-11.442-4.82L34.624 1 18.966 2.507 7.424 7.327 1.3 14.058 1 17.773 34.624 46.9Z"
        fill="#FBFBF5"
      />
      <path
        clipRule="evenodd"
        d="m34.624 47 33.524-29.227-.2-3.716-6.224-6.73-11.442-4.82L34.624 1 18.966 2.507 7.424 7.327 1.3 14.058 1 17.773 34.624 47Z"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m34.624 47 3.212-8.538 8.933-12.253 11.342-5.625 2.409-7.331-4.517-6.83-5.721-3.917"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m56.003 6.424-5.62 3.515-15.759 1.406 10.439 4.821 13.048 4.42"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m50.382 9.94-5.32 6.226L34.624 23.7M60.52 13.254 50.383 9.939M34.624 23.7l12.145 2.51M34.624 26.71l12.044-4.017 10.54 1.004 6.925-4.319 4.015-1.607M64.133 19.38l-6.022 1.206"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m68.148 17.772-7.628-4.52 3.613 6.127M61.724 7.328l6.424 10.446M56.003 6.424l5.721.904M45.063 16.166l1.605 6.528M57.208 23.7l-9.134 11.55M37.836 38.463 57.208 23.7M34.624 26.71l3.212 11.752M34.624 47l-3.212-8.538L22.48 26.21l-11.442-5.625-2.41-7.331 4.518-6.83 5.821-3.917"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m13.145 6.424 5.62 3.515 15.86 1.406-10.44 4.821-13.148 4.42"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m18.766 9.94 5.42 6.226L34.624 23.7M8.628 13.254l10.137-3.315M34.624 23.7l-12.145 2.51M34.624 26.71 22.58 22.693l-10.64 1.004-6.925-4.319L1 17.771M5.015 19.38l6.022 1.206"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m1 17.772 7.628-4.52-3.613 6.127M7.424 7.328 1 17.774M13.145 6.424l-5.721.904M24.186 16.166l-1.606 6.528M11.94 23.7l9.234 11.55M31.412 38.463 11.94 23.7M34.624 26.71l-3.212 11.752"
        stroke="#000"
        strokeWidth=".75"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
