import React from 'react';
import { observer } from 'mobx-react-lite';
import { CartItemEngraveSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';
import Icon from 'components/common/Icon';
import EngraveColorCircle from 'components/common/EngraveColorCircle';

import './InfoEngraving.scss';

interface IProps {
  engrave?: CartItemEngraveSettings[];
}

export function InfoEngraving({ engrave }: IProps): JSX.Element | null {
  if (!engrave) return null;

  return (
    <>
      {engrave?.map(({ color, text, font, description }) => {
        return (
          <div className="add-set-to-cart-popup__info-item" key={description}>
            <Icon id="inside_bang" className="icon" /> Engraving |{' '}
            <span>
              {description} | {font.name} |{' '}
              <EngraveColorCircle color={color.name} /> {color.name} |{' '}
              <span className="text">{text}</span>
            </span>
          </div>
        );
      })}
    </>
  );
}

export default observer(InfoEngraving);
