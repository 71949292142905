import RoundMeasLengthIcon from './RoundMeasLengthIcon';
import RoundMeasWidthIcon from './RoundMeasWidthIcon';
import RoundMeasDepthIcon from './RoundMeasDepthIcon';
import RoundDepthIcon from './RoundDepthIcon';
import RoundTableIcon from './RoundTableIcon';
import RoundIcon from './RoundIcon';

export default {
  round_measLength: RoundMeasLengthIcon,
  round_measWidth: RoundMeasWidthIcon,
  round_measDepth: RoundMeasDepthIcon,
  round_depth: RoundDepthIcon,
  round_table: RoundTableIcon,
  round: RoundIcon,
};
