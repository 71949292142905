import CushionModifiedMeasLengthIcon from './CushionModifiedMeasLengthIcon';
import CushionModifiedMeasWidthIcon from './CushionModifiedMeasWidthIcon';
import CushionModifiedMeasDepthIcon from './CushionModifiedMeasDepthIcon';
import CushionModifiedDepthIcon from './CushionModifiedDepthIcon';
import CushionModifiedTableIcon from './CushionModifiedTableIcon';
import CushionModifiedIcon from './CushionModifiedIcon';

export default {
  cushion_modified_measLength: CushionModifiedMeasLengthIcon,
  cushion_modified_measWidth: CushionModifiedMeasWidthIcon,
  cushion_modified_measDepth: CushionModifiedMeasDepthIcon,
  cushion_modified_depth: CushionModifiedDepthIcon,
  cushion_modified_table: CushionModifiedTableIcon,
  cushion_modified: CushionModifiedIcon,
};
