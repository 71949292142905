import { makeAutoObservable } from 'mobx';
import {
  FormControl,
  FormGroup,
  maxLengthValidator,
  minLengthValidator,
  patternValidator,
  requiredValidator,
  ValidationEventTypes,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';
import {
  IForgotPasswordForm,
  IForgotPasswordStore,
  TContentType,
  TPageStatus,
} from './ForgotPassword.types';

export class ForgotPasswordStore implements IForgotPasswordStore {
  status: TPageStatus | null = null;
  form: FormGroup<IForgotPasswordForm>;
  disable = false;
  visible = false;
  inputPasswordType = 'password';
  inputPasswordConfirmType = 'password';
  passwordConfirmErrorMessage = '';
  resendTimerCount = 0;

  content = {} as TContentType;

  constructor() {
    makeAutoObservable(this);
    this.form = this.initialForm();
  }

  setTimerCount(delay: number): void {
    this.resendTimerCount = delay;
  }

  private initialForm() {
    return new FormGroup<IForgotPasswordForm>({
      password: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Password is required'),
            minLengthValidator(8, 'Field minimum length is 8 chars'),
            patternValidator(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,255}$/,
              'Password must contain one uppercase, one lowercase, one number and spec chars',
            ),
            maxLengthValidator(
              255,
              'Field maximum length is 255 chars ',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      passwordConfirm: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Confirm Password is required'),
            minLengthValidator(8, 'Field minimum length is 8 chars'),
            maxLengthValidator(
              255,
              'Field maximum length is 255 chars ',
              ValidationEventTypes.Error,
            ),
            async () => {
              if (
                this.form?.controls.password.value !==
                this.form?.controls.passwordConfirm.value
              ) {
                return [
                  {
                    message: 'Confirm Password does not match with Password',
                    type: ValidationEventTypes.Error,
                  },
                ];
              }
              return [];
            },
          ]),
        ],
      }),
    });
  }

  setStatus(status: TPageStatus | null): void {
    this.status = status;
  }

  setPasswordConfirmErrorMessage(message: string): void {
    this.passwordConfirmErrorMessage = message;
  }

  disableForm(isDisable: boolean): void {
    this.disable = isDisable;
  }

  visibleForgotPasswordModal(visible: boolean): void {
    this.visible = visible;
  }

  getPasswordValue(): { [k: string]: string | undefined } {
    const { password, passwordConfirm } = this.form.controls;
    return {
      password: password.value,
      passwordConfirm: passwordConfirm.value,
    };
  }

  setContent(content: TContentType): void {
    this.content = content;
  }
}
