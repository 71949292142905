import * as React from 'react';
import { observer } from 'mobx-react';
import { TableParamsImpl } from '../../store';
import { CartItemBraceletsImpl } from 'root/store/cart/item.store';

export interface BraceletsTemplateProps {
  product: CartItemBraceletsImpl;
  property: TableParamsImpl;
}

export const BraceletsTemplate = observer((props: BraceletsTemplateProps) => {
  const { product, property } = props;
  return (
    <>
      {(() => {
        switch (property.key) {
          case 'gender':
            return product.gender;
          case 'metal':
            return product.metal;
          case 'closureType':
            return product.closureType;
          case 'primaryStoneShape':
            return product.primaryStoneShape;
          case 'primaryStoneType':
            return product.primaryStoneType;
          case 'primaryStoneSize':
            return product.primaryStoneSize;
          case 'state':
            return product.state;
          case 'initialNumber':
            return product.initialNumber;
          case 'chainLength':
            return product.chainLength;
          default:
            return null;
        }
      })()}
    </>
  );
});
