export const TYPES = {
  gender: {
    tooltip: 'Genders type of rings.',
    isNeedDetail: false,
  },
  size: {
    tooltip: 'Size of rings.',
    isNeedDetail: false,
  },
  price: {
    tooltip: 'Price of rings.',
    isNeedDetail: false,
  },
  width: {
    tooltip: 'Width of rings.',
    isNeedDetail: false,
  },
  weight: {
    tooltip: 'Weight of rings.',
    isNeedDetail: false,
  },
  metal: {
    tooltip: 'Metals of rings.',
    isNeedDetail: false,
  },
  profile: {
    tooltip: 'Profile of rings.',
    isNeedDetail: false,
  },
  category: {
    tooltip: 'Category of rings.',
    isNeedDetail: false,
  },
  ringStyle: {
    tooltip: 'Style of engagement ring.',
    isNeedDetail: false,
  },
  bands: {
    tooltip: 'Bands of wedding ring.',
    isNeedDetail: false,
  },
  stoneShape: {
    tooltip: 'Stone shape.',
    isNeedDetail: false,
  },
};

export const STONE_SHAPE = [
  {
    title: 'Round',
    value: 'ROUND',
  },
  {
    title: 'Oval',
    value: 'OVAL',
  },
  {
    title: 'Radiant',
    value: 'RADIANT',
  },
  {
    title: 'Cushion',
    value: 'CUSHION',
  },
  {
    title: 'Emerald',
    value: 'EMERALD',
  },
  {
    title: 'Asscher',
    value: 'ASSCHER',
  },
  {
    title: 'Marquise',
    value: 'MARQUISE',
  },
  {
    title: 'Pear',
    value: 'PEAR',
  },
  {
    title: 'Princess',
    value: 'PRINCESS',
  },
  {
    title: 'All',
    value: null,
  },
];
export const METALS = [
  {
    title: 'White Gold',
    value: 'White Gold',
  },
  {
    title: 'Yellow Gold',
    value: 'Yellow Gold',
  },
  {
    title: 'Rose Gold',
    value: 'Rose Gold',
  },
  {
    title: 'Platinum',
    value: 'Platinum',
  },
  {
    title: 'All',
    value: null,
  },
];

export const RING_STYLE = [
  {
    title: 'Solitaire',
    value: 'EG_RING_SOLITAIRE',
  },
  {
    title: 'Halo',
    value: 'EG_RING_HALO',
  },
  {
    title: 'Two-Stone',
    value: 'EG_RING_TWOSTONE',
  },
  {
    title: 'Three-Stone',
    value: 'EG_RING_THREESTONE',
  },
  {
    title: 'Accented Stones',
    value: 'EG_RING_ACCENTED',
  },
  {
    title: 'Bezel Set',
    value: 'EG_RING_BEZEL',
  },
  {
    title: 'Vintage',
    value: 'EG_RING_VINTAGE',
  },
  {
    title: 'All',
    value: null,
  },
];

export const BANDS = [
  {
    title: "Women's Diamond Bands",
    value: 'WB_RING_DIAMOND_WOMAN',
  },
  {
    title: "Men's Diamond Bands",
    value: 'WB_RING_DIAMOND_MAN',
  },
  {
    title: 'Classic Bands',
    value: 'WB_RING_CLASSIC',
  },
  {
    title: 'Alternative Metal Bands',
    value: 'WB_RING_ALT_METAL',
  },
  {
    title: 'Stackable Rings',
    value: 'WB_RING_STACKABLE',
  },
  {
    title: 'Carved Bands',
    value: 'WB_RING_CARVED',
  },
  {
    title: 'Anniversary Bands',
    value: 'WB_RING_ANNIVERSARY',
  },
  {
    title: 'Eternity Bands',
    value: 'WB_RING_ETERNITY',
  },
  {
    title: 'All',
    value: null,
  },
];
