import * as React from 'react';
import { observer } from 'mobx-react';
import { CartItemEarringsImpl } from 'root/store/cart/item.store';
import ProductRow from './ProductRow';

export interface EarringsTemplateProps {
  product: CartItemEarringsImpl;
}

const EARRINGS_PROPERTIES = {
  firstColumn: [
    'metal',
    'gender',
    'collection',
    'primaryStoneShape',
    'primaryStoneType',
    'primaryStoneSize',
  ],
  secondColumn: [
    'categories',
    'earringType',
    'closureType',
    'state',
    'earringPostType',
    'stoneClarityColor',
  ],
};

export const EarringsTemplate = observer((props: EarringsTemplateProps) => {
  const { product } = props;
  return <ProductRow product={product} properties={EARRINGS_PROPERTIES} />;
});
