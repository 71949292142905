import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import { ProductDetailImpl } from '../ProductTypes';
import {
  getFavorites,
  removeFavoriteItem,
  updateFavorites,
} from 'service/api/account';
import { CartItemFactory } from './cart/item.store';

export interface FavoriteImpl {
  list: IObservableArray<ProductDetailImpl>;
  exist(id: number): boolean;
  toggle(product: ProductDetailImpl): void;
  clear(): void;
  isLoading: boolean;
  loadFavorites(): Generator<
    Promise<ProductDetailImpl[]>,
    void,
    ProductDetailImpl[]
  >;
}

class Favorite implements FavoriteImpl {
  isLoading = false;
  list: IObservableArray<ProductDetailImpl> = observable.array([], {
    deep: false,
  });

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean): void {
    this.isLoading = value;
  }

  *loadFavorites(): Generator<
    Promise<ProductDetailImpl[]>,
    void,
    ProductDetailImpl[]
  > {
    this.isLoading = true;
    try {
      const favoriteList = yield getFavorites();
      this.update(favoriteList);
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }

  *add(product: ProductDetailImpl): Generator {
    this.isLoading = true;
    try {
      yield updateFavorites(product.id);
      yield this.loadFavorites();
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }

  *remove(product: ProductDetailImpl): Generator {
    try {
      yield removeFavoriteItem(product.id);
      this.list.remove(product);
      yield this.loadFavorites();
    } catch (error) {
      console.error(error);
    }
  }

  exist = (productId: number): boolean =>
    this.list.some(({ id }: ProductDetailImpl) => id === productId);

  toggle(product: ProductDetailImpl): void {
    this.exist(product.id) ? this.remove(product) : this.add(product);
  }

  clear(): void {
    this.list.clear();
  }

  update(productInfo: Array<ProductDetailImpl>): void {
    this.list.replace(
      productInfo
        .map((item) => {
          return item.shape === 'Cushion Modified'
            ? { ...item, shape: 'Cushion' }
            : item;
        })
        .map(CartItemFactory.getItem),
    );
  }
}
export default Favorite;
