import { joinClasses } from 'lib/utils';
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '../Icon';

import './Collapse.scss';

interface CollapseState {
  open: boolean;
}

interface CollapseProps {
  children: React.ReactNode;
}

class Collapse extends Component<CollapseProps, CollapseState> {
  constructor(props: CollapseProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleToggle = (): void =>
    this.setState((state: CollapseState) => ({
      open: !state.open,
    }));

  render(): JSX.Element {
    const { children } = this.props;
    return (
      <React.Fragment>
        <label
          htmlFor="advanced-filters-title"
          className="collapse advanced-filters-title"
          onClick={this.handleToggle}
        >
          <Icon
            id="caret"
            className={joinClasses([
              'collapse__caret-icon',
              this.state.open && 'collapse__caret-icon_up',
            ])}
          />
          Advanced filter parameters
        </label>

        <CSSTransition
          in={this.state.open}
          timeout={300}
          classNames="toggle"
          unmountOnExit
        >
          <div>{children}</div>
        </CSSTransition>
      </React.Fragment>
    );
  }
}

export default Collapse;
