import React from 'react';
import Svg from '../Svg';

interface ICheckBoxIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CheckBoxIcon(props: ICheckBoxIconProps): JSX.Element {
  return (
    <Svg width="11" height="11" viewBox="0 0 448 512" fill="none" {...props}>
      <path
        fill="#4d4048"
        d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 32c8.823 0 16 7.178 16 16v352c0 8.822-7.177 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h352m-34.301 98.293l-8.451-8.52c-4.667-4.705-12.265-4.736-16.97-.068l-163.441 162.13-68.976-69.533c-4.667-4.705-12.265-4.736-16.97-.068l-8.52 8.451c-4.705 4.667-4.736 12.265-.068 16.97l85.878 86.572c4.667 4.705 12.265 4.736 16.97.068l180.48-179.032c4.704-4.667 4.735-12.265.068-16.97z"
      />
    </Svg>
  );
}
