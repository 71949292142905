import * as React from 'react';

import './Page404.scss';

export default class Page404 extends React.Component {
  render(): JSX.Element {
    return (
      <div className="page404 grid">
        <div className="grid-area-content">
          <p className="attention-message attention-message_text-center">
            Sorry, page is under construction now. Thank you for your being
            patient. Please come back later.
          </p>
        </div>
      </div>
    );
  }
}
