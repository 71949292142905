import React from 'react';
import Svg from '../../Svg';

interface IСushionMeasWidthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function СushionMeasWidthIcon(
  props: IСushionMeasWidthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M62.26 34h75.48l26.96 26.8v76.15l-27.76 25.79H62.26L35.3 136.62V61.14L62.26 34z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m35.3 61.14 26.96 15.67 16.01-15.67L62.26 34 35.3 61.14zM164.7 61.14l-26.96 15.67-16.01-15.67L137.74 34l26.96 27.14zM164.7 136.95l-26.96-15.67-16.01 15.67 15.21 25.79 27.76-25.79zM35.3 136.95l26.96-15.67 16.01 15.67-16.01 25.79-26.96-25.79z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.37 66.41v65.26l23.3 23.25h64.67l23.64-23.04V66.21l-23.53-23.24h-64.9L44.37 66.41z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.85 51.96 53.39 71.65v54.78l19.46 19.7h54.3l19.81-19.49V71.45l-19.81-19.49h-54.3z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.27 61.14h43.46l16.01 15.67v44.47l-16.01 15.67H78.27l-16.01-15.67V76.81l16.01-15.67z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.63 61.14v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.63 56.65V22.3"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.99 2.99"
      />
      <path
        d="M35.63 20.8v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.63 19.49h128.74"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.37 61.14v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.37 56.65V22.3"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.99 2.99"
      />
      <path
        d="M164.37 20.8v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
