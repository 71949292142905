import * as React from 'react';
import { TableBody as DefaultComponent } from './TableBody';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import rootStore from 'root/store';
import { IObservableArray } from 'mobx';
import { TDiamondCatalog } from '../../types';

const { compare } = rootStore;

export interface TableBodyProps extends RouteComponentProps {
  stones: IObservableArray<TDiamondCatalog>;
}

export const TableBody = withRouter((props: TableBodyProps) => (
  <DefaultComponent {...props} compare={compare} />
));
