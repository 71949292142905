import axios from './index';
import {
  OrderDetailsImpl,
  OrderItemImpl,
  OrdersFilterImpl,
  TOrdersType,
} from 'root/store/orders/types';
import { CurrencyImpl } from 'root/store/currency';

/**
 * API path
 * @type {string}
 */
const path = '/orders';

/**
 * Get Orders
 * @params type, filter
 * @returns {AxiosPromise}
 */

export const getOrdersByType = async (
  type: TOrdersType,
  params: OrdersFilterImpl,
): Promise<{
  results: OrderItemImpl[];
  total: number;
  totalPages: number;
  currency: CurrencyImpl;
}> => {
  return axios.get(`${path}/${type}`, { params });
};

/**
 * Get Orders
 * @params type, filter
 * @returns {AxiosPromise}
 */

export const getOrderDetails = (id: string): Promise<OrderDetailsImpl> => {
  return axios.get(`${path}/${id}/info`);
};
