import { makeAutoObservable } from 'mobx';

export interface CurrencyOneImpl {
  id: number;
  after: boolean;
  iso: string;
  name: string;
  space: boolean;
  symbol: string;
}

export interface CurrencyImpl extends CurrencyOneImpl {
  update: (currency: CurrencyOneImpl) => void;
  format: (n: number | string) => string;
}

export default class Currency implements CurrencyImpl {
  id!: number;
  after!: boolean;
  iso!: string;
  name!: string;
  space!: boolean;
  symbol!: string;

  constructor() {
    makeAutoObservable(this);
  }

  update(currency: CurrencyOneImpl): void {
    this.id = currency.id ? currency.id : 0;
    this.after = currency.after ? currency.after : false;
    this.iso = currency.iso;
    this.name = currency.name;
    this.space = currency.space;
    this.symbol = currency.symbol;
  }

  format(n: number | string): string {
    // TODO: may be move to another class...
    n = Number(n)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return this.symbol
      ? this.after
        ? n + (this.space ? ' ' : '') + this.symbol
        : this.symbol + (this.space ? ' ' : '') + n
      : '';
  }
}
