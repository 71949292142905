import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { activate, resendActivation } from 'service/api/auth';
import qs from 'qs';
import Icon from 'components/common/Icon';
import ResendInfo from './components/ResendInfo';

import './ConfirmationEmail.scss';

type TPageStatus =
  | typeof RESEND_EMAIL
  | typeof CONFIRMATION_ERROR
  | typeof CONFIRMATION_ERROR_404
  | typeof CONFIRMATION_ERROR_400
  | typeof CONFIRMATION_COMPLETED
  | 'NONE';

type State = {
  resendTimerCount: number;
  status: TPageStatus;
};

const RESEND_EMAIL_DELAY = 300;
const COMPLETED_REDIRECT_DELAY = 10;
const RESEND_EMAIL = 'RESEND_EMAIL';
const CONFIRMATION_ERROR = 'CONFIRMATION_ERROR';
const CONFIRMATION_ERROR_400 = 'CONFIRMATION_ERROR_400';
const CONFIRMATION_ERROR_404 = 'CONFIRMATION_ERROR_404';
const CONFIRMATION_COMPLETED = 'CONFIRMATION_COMPLETED';

class ConfirmationEmail extends Component<RouteComponentProps, State> {
  state: State = {
    resendTimerCount: 0,
    status: 'NONE',
  };

  timeout?: ReturnType<typeof setInterval>;

  changeStatus(status: TPageStatus): void {
    this.setState({ status });
  }

  setTimerCount(delay: number): void {
    this.setState({ resendTimerCount: delay });
  }

  componentDidMount(): void {
    void this.checkPageStatus();
  }

  componentWillUnmount(): void {
    this.timeout && clearInterval(this.timeout);
  }

  setTimeout = (delay: number, callback?: () => void): void => {
    this.timeout && clearInterval(this.timeout);
    this.setTimerCount(delay);
    this.timeout = setInterval(() => {
      const { resendTimerCount } = this.state;
      const currentCount = resendTimerCount - 1;
      this.setTimerCount(currentCount);
      if (currentCount === 0) {
        this.timeout && clearInterval(this.timeout);
        callback && callback();
      }
    }, 1000);
  };

  handleResendEmail = async (): Promise<void> => {
    const {
      location: { state = {} },
      history,
    } = this.props;
    const { resendTimerCount, status } = this.state;
    const { email = '' } = state as { email?: string };

    if (resendTimerCount || status !== RESEND_EMAIL) return;
    if (!email) {
      history.push('/auth/signup');
      return;
    }

    try {
      await resendActivation({ email });
      this.setTimeout(RESEND_EMAIL_DELAY);
    } catch (error) {
      this.changeStatus(CONFIRMATION_ERROR);
    }
  };

  checkPageStatus = async (): Promise<void> => {
    const {
      location: { search, state = {} },
      history,
    } = this.props;
    if (search) {
      const searchParams = qs.parse(search.slice(1)) || {};
      const { token } = searchParams;
      try {
        await activate(token as string);
        this.setTimeout(COMPLETED_REDIRECT_DELAY, () =>
          this.props.history.push('/account/sigin'),
        );
        return this.changeStatus(CONFIRMATION_COMPLETED);
      } catch (error: any) {
        if (error.status === 404) {
          return this.changeStatus(CONFIRMATION_ERROR_404);
        } else if (error.status === 400) {
          return this.changeStatus(CONFIRMATION_ERROR_400);
        } else {
          return this.changeStatus(CONFIRMATION_ERROR);
        }
      }
    }
    const { email = '' } = state as { email?: string };
    if (email) {
      this.changeStatus(RESEND_EMAIL);
      return this.setTimeout(RESEND_EMAIL_DELAY);
    }
    return history.push('/auth/signin');
  };

  getContentByType = (): {
    title: string;
    text: string;
    btnText: string;
  } | null => {
    const { status, resendTimerCount } = this.state;
    switch (status) {
      case RESEND_EMAIL:
        return {
          title: 'CONFIRMATION E-MAIL HAS BEEN SENT',
          text: 'Please check your e-mail to confirm your registration. The e-mail will contain a link to activate your account.<br /><p>Please make sure to click the link to activate your account.</p> If you didn’t receive confirmation e-mail by any reasons click the button below to send it again.',
          btnText: 'Resend e-mail',
        };

      case CONFIRMATION_COMPLETED:
        return {
          title: 'Registration completed',
          // eslint-disable-next-line max-len
          text: `You Registration Process is completed successfully. You can sign in now. <br/>You will be redirected to sign in screen automatically in ${resendTimerCount} seconds…`,
          btnText: 'Proceed to Sing In',
        };

      case CONFIRMATION_ERROR:
        return {
          title: 'Something went wrong',
          text: 'Sorry, something went wrong here. Please contact your technical support to solve the problem by phone <span>+1-877-382-6275</span> or e-mail customerservice@venori.com.',
          btnText: '',
        };

      case CONFIRMATION_ERROR_400:
        return {
          title: 'Something went wrong',
          text: 'Confirmation link has been expired.<br /><p> Please proceed to account creation',
          btnText: '',
        };

      case CONFIRMATION_ERROR_404:
        return {
          title: 'Something went wrong',
          text: 'Token is not valid',
          btnText: '',
        };

      default:
        return null;
    }
  };

  btnAction = (): void => {
    const { status } = this.state;

    status === RESEND_EMAIL
      ? void this.handleResendEmail()
      : this.props.history.push('/auth/signin');
  };

  render(): JSX.Element | null {
    const content = this.getContentByType();
    if (!content) return null;

    const { resendTimerCount, status } = this.state;

    if (!status) return null;

    const isResendEmail = status === RESEND_EMAIL;
    const isConfirmationCompleted = status === CONFIRMATION_COMPLETED;
    const { btnText, title, text } = content;

    return (
      <div className="confirmation-page">
        <div className="confirmation-page__logo-wrap">
          <Icon id="gem_large" className="logo" />
          {!isResendEmail && (
            <div
              className={`logo-status logo-status_${
                isConfirmationCompleted ? 'completed' : 'warning'
              }`}
            />
          )}
        </div>
        <p className="confirmation-page__title confirmation-page__title_email">
          {title}
        </p>
        <p
          className="confirmation-page__text confirmation-page__text_email"
          dangerouslySetInnerHTML={{ __html: text }}
        />

        {btnText && (
          <>
            <button
              className="button button_email btn btn_brown"
              onClick={this.btnAction}
              disabled={!!resendTimerCount && isResendEmail}
            >
              {btnText}
            </button>
            {!!resendTimerCount && isResendEmail && (
              <p className="confirmation-page__text confirmation-page__text_email color-red">
                <ResendInfo time={resendTimerCount} />
              </p>
            )}
          </>
        )}
      </div>
    );
  }
}

export default ConfirmationEmail;
