import { makeAutoObservable } from 'mobx';

export const profileStore = makeAutoObservable({
  isVisibleError500: false,
  error500Title: '',
  handleVisibleError500: (value: boolean): void => {
    profileStore.isVisibleError500 = value;
  },
  setError500Title: (title: string): void => {
    profileStore.error500Title = title;
  },
});
