import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import InputMask from 'react-input-mask';
import {
  FormControl,
  FormGroup,
  InputFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { ValidationEvent } from '@quantumart/mobx-form-validation-kit/dist/validation-event';
import { PaymentTypes } from 'pages/ShoppingCart/constants';
import Icon from 'components/common/Icon';
import { IFormCardPaymentStep } from '../paymentForms.types';
import { IPaymentTypesFactoryProps } from './index';
import { getAffirmJS } from 'lib/affirm/wrapper';

export const PaymentOptionsFactory = observer(
  (props: IPaymentTypesFactoryProps) => {
    const { paymentType, store, cart } = props;

    useEffect(() => {
      paymentType !== PaymentTypes.creditCard
        ? store.cardForms.length && store.cardForms.clear()
        : !store.cardForms.length && store.addCardForm();
    }, [paymentType]);

    const handleAddCard = () => {
      store.addCardForm();
      const amount =
        Math.ceil((Number(cart.totalAmount) / store.cardForms.length) * 100) /
        100;
      store.cardForms.forEach((card: FormGroup<IFormCardPaymentStep>) => {
        card.controls.amount.value = amount;
      });
    };

    const handleRemoveCard =
      (index: number) => (event: React.SyntheticEvent) => {
        event.preventDefault();
        store.removeCardForm(index);
      };

    const handlersCardAmount =
      (formControl: FormControl) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const target: any = event.target;
        const index = +target.dataset.formIndex;
        const value = +target.value;

        props.handleChange(formControl);

        const amount = Number(cart.totalAmount) - value;
        for (let i = 0; i < store.cardForms.length; i++) {
          if (i === index) {
            continue;
          }
          store.cardForms[i].controls.amount.value = amount; // TODO: hardcode! Does not work for more than two credit cards!
        }
      };

    switch (paymentType) {
      case PaymentTypes.creditCard:
        return (
          <>
            {store.cardForms.map((card, index) => (
              <div key={index}>
                {!!index && (
                  <p className="action-cc" onClick={handleRemoveCard(index)}>
                    <span className="action-cc__underline">
                      <Icon id="minus_circle" />
                      Remove Credit Card
                    </span>
                  </p>
                )}

                <label className="form-label select-card">
                  <input
                    type="text"
                    name="number"
                    className={`${
                      card.controls.number.invalid &&
                      card.controls.number.touched
                        ? 'invalid'
                        : 'valid'
                    } ${card.controls.number.value ? 'shrink' : ''} `}
                    data-form="cards"
                    data-form-index={index}
                    value={card.controls.number.value}
                    {...InputFormControl.bindActions(card.controls.number)}
                  />
                  <div className="label-text">Card Number *</div>
                  {card.controls.number.touched &&
                    card.controls.number.errors && (
                      <div className="form-label__error">
                        {card.controls.number.errors.map(
                          (error: ValidationEvent) => (
                            <span key={error.message}>{error.message}</span>
                          ),
                        )}
                      </div>
                    )}
                </label>

                <label className="form-label">
                  <input
                    type="text"
                    name="cardholder"
                    className={`${
                      card.controls.cardholder.invalid &&
                      card.controls.cardholder.touched
                        ? 'invalid'
                        : 'valid'
                    } ${card.controls.cardholder.value ? 'shrink' : ''} `}
                    data-form="cards"
                    data-form-index={index}
                    value={card.controls.cardholder.value}
                    {...InputFormControl.bindActions(card.controls.cardholder)}
                  />
                  <div className="label-text">Card Holder *</div>
                  {card.controls.cardholder.touched &&
                    card.controls.cardholder.errors && (
                      <div className="form-label__error">
                        {card.controls.cardholder.errors.map(
                          (error: ValidationEvent) => (
                            <span key={error.message}>{error.message}</span>
                          ),
                        )}
                      </div>
                    )}
                </label>

                <label className="form-label form-label_with-static-label">
                  <InputMask
                    mask="99/99"
                    placeholder="00/00"
                    type="text"
                    name="expirationDate"
                    className={`${'animation-disabled'} ${
                      card.controls.expirationDate.invalid &&
                      card.controls.expirationDate.touched
                        ? 'invalid'
                        : 'valid'
                    } ${card.controls.expirationDate.value ? 'shrink' : ''} `}
                    data-form="cards"
                    data-form-index={index}
                    value={card.controls.expirationDate.value}
                    onChange={props.handleChange(card.controls.expirationDate)}
                    onBlur={props.handleBlur(card.controls.expirationDate)}
                  />
                  <div className="label-text">Expiration Date *</div>
                  {card.controls.expirationDate.touched &&
                    card.controls.expirationDate.errors && (
                      <div className="form-label__error">
                        {card.controls.expirationDate.errors.map(
                          (error: ValidationEvent) => (
                            <span key={index}>{error.message}</span>
                          ),
                        )}
                      </div>
                    )}
                </label>

                <label className="form-label">
                  <input
                    type="text"
                    name="code"
                    className={`${
                      card.controls.code.invalid && card.controls.code.touched
                        ? 'invalid'
                        : 'valid'
                    } ${card.controls.code.value ? 'shrink' : ''} `}
                    data-form="cards"
                    data-form-index={index}
                    value={card.controls.code.value}
                    {...InputFormControl.bindActions(card.controls.code)}
                  />
                  <div className="label-text">CVC/CVV *</div>
                  {card.controls.code.touched && card.controls.code.errors && (
                    <div className="form-label__error">
                      {card.controls.code.errors.map(
                        (error: ValidationEvent) => (
                          <span key={error.message}>{error.message}</span>
                        ),
                      )}
                    </div>
                  )}
                </label>

                {store.cardForms.length > 1 && (
                  <label className="form-label">
                    <input
                      type="text"
                      name="amount"
                      className={`${'not-required'} ${
                        card.controls.amount.invalid &&
                        card.controls.amount.touched
                          ? 'invalid'
                          : 'valid'
                      } ${card.controls.amount.value ? 'shrink' : ''} `}
                      data-form="cards"
                      data-form-index={index}
                      value={card.controls.amount.value}
                      {...InputFormControl.bindActions(card.controls.amount, {
                        onChange: handlersCardAmount(card.controls.amount),
                      })}
                    />
                    <div className="label-text">
                      Amount Credit Card #{index + 1}
                    </div>
                    {card.controls.amount.touched &&
                      card.controls.amount.errors && (
                        <div className="form-label__error">
                          {card.controls.amount.errors.map(
                            (error: ValidationEvent) => (
                              <span key={error.message}>{error.message}</span>
                            ),
                          )}
                        </div>
                      )}
                  </label>
                )}

                {store.cardForms.length > 1 || (
                  <p className="action-cc" onClick={handleAddCard}>
                    <span className="action-cc__underline">
                      <Icon id="plus_circle" />
                      Add Credit Card
                    </span>
                  </p>
                )}
              </div>
            ))}
          </>
        );

      case PaymentTypes.affirm:
        getAffirmJS();
        return null;

      case PaymentTypes.bankWire:
        return null;

      default:
        return null;
    }
  },
);
