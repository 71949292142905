import { makeAutoObservable } from 'mobx';
import { getOrdersByType } from 'service/api/orders';
import debounce from 'lodash.debounce';
import ListStore from '../list';
import OrdersFilter from './ordersFilter.store';
import { OrderItemDetailImpl, OrdersFilterImpl, TOrdersType } from './types';
import Currency from '../currency';
import { CartItemFactory } from '../cart/item.store';

export default class OrdersStore {
  filter = new OrdersFilter();
  loading = true;
  currency = new Currency();
  ordersList = new ListStore();

  constructor() {
    makeAutoObservable(this);
  }

  clearFilter = (type: TOrdersType): void => {
    this.filter.clearFilter();
    void this.loadOrders(type);
  };

  setFilter = (type: TOrdersType, filter: OrdersFilterImpl): void => {
    this.filter.updateFilter(filter);
    this.debounceLoad(type);
  };

  debounceLoad = debounce((type: TOrdersType) => {
    void this.loadOrders(type);
  }, 500);

  loadOrders = async (type: TOrdersType): Promise<void> => {
    this.loading = true;
    try {
      const { results, total, totalPages, currency } = await getOrdersByType(
        type,
        this.filter.queryParams,
      );
      const orderResults = results.map((result) => ({
        ...result,
        items: result.items
          .map((item: OrderItemDetailImpl) => {
            const result =
              item.shape === 'Cushion Modified'
                ? { ...item, shape: 'Cushion' }
                : item;

            return { ...result, currency }; // TODO: Currency Hack! Need refactoring!
          })
          .map(CartItemFactory.getItem),
      }));
      this.ordersList.put(orderResults);
      this.filter.pagination.setTotalPages(totalPages);
      this.filter.pagination.setTotal(total);
      this.currency.update(currency);
    } catch (error) {
      this.ordersList.reset();
    }
    this.loading = false;
  };
}
