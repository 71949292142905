import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import rootStore from 'root/store';
import { CurrencyImpl } from 'root/store/currency';
import { getCreateDate } from 'lib/utils';
import Loader from 'components/common/Loaders/Loader';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import DetailsBlock from './components/DetailsBlock';
import Icon from 'components/common/Icon';
import { ProductInfoTemplate } from './components/ProductTemplates';
import OrderModalStore from './ordersModal.store';

import './OrdersModal.scss';
import { OrderItemDetailImpl } from '../../../../../root/store/orders/types';

const {
  orders: { currency },
} = rootStore;

interface IProps {
  id: string;
  close: () => void;
  currency: CurrencyImpl;
  store: OrderModalStore;
}

export const OrdersModal = observer(({ id: orderId, close, store }: IProps) => {
  const closeModal = () => {
    window.location.hash = '';
    store.resetOrderInfo();
    close();
  };

  const loadCurrentOrders = () => {
    try {
      store.setLoading(true);
      store.loadCurrentOrders(orderId);
      store.setLoading(false);
    } catch (e) {
      closeModal();
    }
  };

  useEffect(() => {
    loadCurrentOrders();
  }, []);

  const {
    id,
    createDate,
    customer,
    total,
    delivery,
    payment,
    items,
    shipping,
  } = store;

  const priceFormat = (price: number) => currency && currency.format(price);

  const orderItems = useMemo(() => {
    const list: OrderItemDetailImpl[] = [];
    items.forEach((item) => {
      list.push(item as OrderItemDetailImpl);
      if (item.children && item.children.length) {
        list.push(...item.children);
      }
    });
    return list;
  }, [items.length]);

  return (
    <>
      {store.loading ? (
        <div className="orders-current-window__loader-wrapper">
          <Loader />
        </div>
      ) : (
        <PortalControl>
          <ModalWindow visible modalSize="full-screen">
            <div className="orders-current-window">
              <div
                className="btn btn_bordered-default btn_circle modal-window__btn-close"
                onClick={closeModal}
              >
                <Icon id="close" />
              </div>
              <div className="orders-current-window__header">
                <Icon
                  id="logo"
                  className="orders-current-window__header-logo"
                />

                <div className="orders-current-window__header-info">
                  <div className="orders-current-window__header-info-title">
                    {`Order # ${id}`}
                  </div>
                  <div className="orders-current-window__header-info-date">
                    <span>{`Date: ${getCreateDate(createDate)}`}</span>
                    <span>Page 1 / 1</span>
                  </div>
                  <div className="orders-current-window__header-info-text">
                    You order # {id} was paid successfully, <br /> Thank you for
                    the order!
                  </div>
                  <div className="orders-current-window__header-info-additional">
                    <Icon id="truck" />
                    Receive in <span>5-7 days (FedEX Standard)</span> /
                    Estimated Shipping date{' '}
                    <span>{getCreateDate(createDate, 7)}</span>
                  </div>
                </div>

                <div className="orders-current-window__header-info orders-current-window__header-info_to-right">
                  <span className="orders-current-window__header-info-subtitle">
                    Total Cost
                  </span>
                  <div className="orders-current-window__header-info-title">
                    {priceFormat(total)}
                  </div>

                  <div className="orders-current-window__header-seller">
                    <b>Seller info:</b>
                  </div>
                  <div className="orders-current-window__header-info-text orders-current-window__header-info-text_to-right">
                    Venori inc., info@venori.com <br /> Phone: 877-382-6275
                  </div>
                </div>
              </div>

              <div className="orders-current-window__details">
                <div className="orders-current-window__details-item">
                  <h3 className="orders-current-window__details-title">
                    Customer Information
                  </h3>
                  <DetailsBlock label="First Name" value={customer.firstName} />
                  <DetailsBlock label="Last Name" value={customer.lastName} />
                  <DetailsBlock label="E-mail" value={customer.email} />
                  <DetailsBlock label="Phone" value={customer.phone} />
                </div>

                <div className="orders-current-window__details-item">
                  <h3 className="orders-current-window__details-title">
                    Address
                  </h3>
                  <DetailsBlock
                    label="Country"
                    value={customer?.country?.name}
                  />
                  <DetailsBlock label="City" value={customer.city} />
                  <DetailsBlock label="Address" value={customer.address} />
                  <DetailsBlock label="Apt/Unit" value={customer.unit} />
                  <DetailsBlock label="ZIP code" value={customer.zipCode} />
                </div>

                <div className="orders-current-window__details-item">
                  <h3 className="orders-current-window__details-title">
                    Shipping Information
                  </h3>
                  <DetailsBlock
                    label="Country"
                    value={delivery?.country?.name}
                  />
                  <DetailsBlock label="City" value={delivery.city} />
                  <DetailsBlock label="Address" value={delivery.address} />
                  <DetailsBlock label="Apt/Unit" value={delivery.aptUnit} />
                  <DetailsBlock label="ZIP code" value={delivery.zipCode} />
                </div>

                <div className="orders-current-window__details-item">
                  <h3 className="orders-current-window__details-title">
                    Recipient Information
                  </h3>
                  <DetailsBlock label="First Name" value={delivery.firstName} />
                  <DetailsBlock label="Last Name" value={delivery.lastName} />
                  <DetailsBlock label="Phone" value={delivery.phone} />
                </div>

                <div className="orders-current-window__details-item">
                  <h3 className="orders-current-window__details-title">
                    Billing information
                  </h3>
                  <DetailsBlock
                    label="Country"
                    value={payment?.country?.name}
                  />
                  <DetailsBlock label="City" value={payment.city} />
                  <DetailsBlock label="Address" value={payment.address} />
                  <DetailsBlock label="Apt/Unit" value={payment.unit} />
                  <DetailsBlock label="ZIP code" value={payment.zip} />
                </div>

                <div className="orders-current-window__details-item">
                  <h3 className="orders-current-window__details-title">
                    Bank and Payment details
                  </h3>
                  {/* <DetailsBlock label="Bank" value={'???????'} />
              <DetailsBlock label="Branch Code" value={'???????'} />
              <DetailsBlock label="Account" value={'???????'} />
              <DetailsBlock label="SWIFT" value={'???????'} /> */}
                  <DetailsBlock
                    label="Payment Details"
                    value={payment.details}
                  />
                </div>
              </div>

              {orderItems.map((product) => (
                <ProductInfoTemplate
                  key={product.id}
                  product={product}
                  templateType={product.type}
                />
              ))}

              <div className="orders-current-window__footer">
                <div className="orders-current-window__footer-phone">
                  <Icon id="phone_dark" />
                  If you have any question about this invoice please contact us
                  by phone <span>+1-877-382-6275</span>
                </div>
                <div className="orders-current-window__header-info orders-current-window__header-info_to-right">
                  <span className="orders-current-window__header-info-subtitle">
                    Total Cost
                  </span>
                  <div className="orders-current-window__header-info-title">
                    {priceFormat(total)}
                  </div>

                  <div className="orders-current-window__footer-info">
                    {/* <div className="orders-current-window__footer-info-item">
                  <span>Promocode</span>
                  <span>???????</span>
                </div> */}
                    <div className="orders-current-window__footer-info-item">
                      <span>Shipping</span>
                      <span>{priceFormat(shipping || 0)}</span>
                    </div>
                    {/*
                                        <div className="orders-current-window__footer-info-item">
                      <span>Tax</span>
                      <span>{priceFormat(tax || 0)}</span>
                    </div>
                    */}
                    <div className="orders-current-window__footer-info-item">
                      <span>Subtotal</span>
                      <span>{priceFormat(total)}</span>
                    </div>
                  </div>
                </div>

                <div className="orders-current-window__footer-action">
                  <button
                    className="btn btn_brown btn_fixed-height"
                    onClick={closeModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </ModalWindow>
        </PortalControl>
      )}
    </>
  );
});
