import React from 'react';
import Svg from '../Svg';

interface PinterestIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PinterestIcon(props: PinterestIconProps): JSX.Element {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M10.4688 0.253906C6.46094 0.253906 2.5 2.92578 2.5 7.25C2.5 10 4.04688 11.5625 4.98438 11.5625C5.37109 11.5625 5.59375 10.4844 5.59375 10.1797C5.59375 9.81641 4.66797 9.04297 4.66797 7.53125C4.66797 4.39062 7.05859 2.16406 10.1523 2.16406C12.8125 2.16406 14.7812 3.67578 14.7812 6.45312C14.7812 8.52734 13.9492 12.418 11.2539 12.418C10.2813 12.418 9.44922 11.7148 9.44922 10.707C9.44922 9.23047 10.4805 7.80078 10.4805 6.27734C10.4805 3.69141 6.8125 4.16016 6.8125 7.28516C6.8125 7.94141 6.89453 8.66797 7.1875 9.26562C6.64844 11.5859 5.54688 15.043 5.54688 17.4336C5.54688 18.1719 5.65234 18.8984 5.72266 19.6367C5.85547 19.7852 5.78906 19.7695 5.99219 19.6953C7.96094 17 7.89062 16.4727 8.78125 12.9453C9.26172 13.8594 10.5039 14.3516 11.4883 14.3516C15.6367 14.3516 17.5 10.3086 17.5 6.66406C17.5 2.78516 14.1484 0.253906 10.4688 0.253906Z" />
    </Svg>
  );
}
