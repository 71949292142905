import React from 'react';
import OrderStore from 'root/store/order';
import { observer } from 'mobx-react';
import { Th } from '../TableHeadTh';

/**
 * Constant for table head
 * @type {[]}
 */
const COLS = [
  {
    title: 'Compare',
    className: '',
  },
  {
    title: 'Shape',
    className: '',
    // order: 'shape',
  },
  {
    title: 'Carat',
    className: '',
    // order: 'carat',
  },
  {
    title: 'Color',
    className: '',
    // order: 'color',
  },
  {
    title: 'Cut',
    className: '',
    // order: 'cut',
  },
  {
    title: 'Clarity',
    className: '',
  },
  {
    title: 'Certificate',
    className: '',
  },
  {
    title: 'View',
    className: '',
  },
  {
    title: 'Fluorescence',
    className: '',
  },
  {
    title: 'Price',
    className: '',
    // order: 'price',
  },
];

interface TableHeadProps {
  order: OrderStore;
  handleOrder: (property: string, direction: string | null) => void;
}

export const TableHead = observer((props: TableHeadProps) => {
  const { order, handleOrder } = props;

  return (
    <thead>
      <tr>
        {COLS.map((i) => {
          return (
            <Th
              key={i.title}
              item={i}
              order={order}
              handleOrder={handleOrder}
            />
          );
        })}
      </tr>
    </thead>
  );
});
