import React from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import { CartStoreImpl } from 'root/store/cart/item.store';
import { observer } from 'mobx-react';
import withHeader from '../../components/app/Header/hoc/withHeader';

import './EducationPage.scss';

interface EducationPageProps extends RouteComponentProps {
  children?: React.ReactNode;
  cart: CartStoreImpl;
}

const EducationPage = observer(function ({ children }: EducationPageProps) {
  return (
    <div className="education-page grid">
      <h1 className="grid-area-page-title page-title">EDUCATE YOURSELF</h1>

      {children ? children : <Redirect to="/404" />}

      <div className="grid-area-attention">
        Please use website portrait mode for a better user experience
      </div>
    </div>
  );
});

export default withRouter(
  withHeader({ className: 'education-page-header' })(EducationPage),
);
