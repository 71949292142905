import React from 'react';
import Svg from '../../Svg';

interface IPrincessMeasDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PrincessMeasDepthIcon(
  props: IPrincessMeasDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M99.58 128.05 28.81 72.97l52.66 57.76"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.58 116.83-71-43.98 71 37.67v29.19l-18.11-8.98-16.89-11.55-19.89-17.32-16.47-29.09L47.68 60.8h51.9l-41.21 7.06v-6.7l-21.95 7.2-7.84 4.49 28.94-4.78 42.06-.14M32.49 75.03h67.09M29.88 72.85h69.7"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.58 128.05 70.77-55.08-52.66 57.76"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.58 116.83 71-43.98-71 37.67v29.19l18.11-8.98 16.89-11.55 19.89-17.32 16.46-29.09-19.46-11.97H99.58l41.2 7.06v-6.7l21.96 7.2 7.84 4.49-28.94-4.78-42.06-.14M166.67 75.03H99.58M169.28 72.85h-69.7"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.58 59.8h82.84M99.58 139.71h82.84"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M182.42 60v78.71"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.22 72.85V40.13M170.93 72.85V40.13"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M28.22 40.13h142.71"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
