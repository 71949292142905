import * as React from 'react';
import { observer } from 'mobx-react';
import { CartItemRingImpl } from 'root/store/cart/item.store';
import ProductRow from './ProductRow';

export interface WeddingRingTemplateProps {
  product: CartItemRingImpl;
}

const RINGS_PROPERTIES = {
  firstColumn: [
    'size',
    'weight',
    'width',
    'metal',
    'gender',
    'collection',
    'categories',
  ],
  secondColumn: [
    'primaryStoneShape',
    'primaryStoneType',
    'primaryStoneSize',
    'state',
    'profile',
    'initialNumber',
  ],
};

export const RingTemplate = observer((props: WeddingRingTemplateProps) => {
  const { product } = props;
  return <ProductRow product={product} properties={RINGS_PROPERTIES} />;
});
