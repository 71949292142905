import * as React from 'react';
import Page404 from 'pages/Page404';
import Policy from 'pages/PolicyPage';
import { EducationPage } from 'pages/EducationPage';
import FAQPage from 'pages/FAQ';
import { Clarity } from 'pages/EducationPage/components/Clarity';
import { Color } from 'pages/EducationPage/components/Color';
import { Cutting } from 'pages/EducationPage/components/Cutting';
import { Carat } from 'pages/EducationPage/components/Carat';
import LoginPage from 'pages/LoginContainer';
import HintPage from 'pages/HintPage';
import MainPage from 'pages/MainPage';
import ConfirmationEmail from 'pages/ConfirmationEmail';
import { ContactsPage } from 'pages/Contacts';
import { ComparePage } from 'pages/Compare';
import AccountPage from 'pages/AccountPage';
import { ForgotPassword } from 'pages/ForgotPassword';
import { FineJewelryPage } from 'pages/FineJewelry/FineJewelry';
import { WeddingRingsCatalogPage } from 'pages/WeddingRings';
import { EngagementRingsCatalogPage } from 'pages/EngagementRings';
import { Diamonds } from 'pages/Diamonds';
import { StoneMainPage } from 'pages/DiamondsMainPage/StoneMain';
import { FineJewelryMainPage } from 'pages/FineJewelryMainPage/JewelryMain';
import { EngagementRingsMainPage } from 'pages/EngagementRingsMainPage/RingsMain';
import { WeddingRingsMainPage } from 'pages/WeddingRingsMainPage/RingsMain';
import { ROUTER_PATHS } from './constants';
import ProductDetailPage from 'pages/ProductDetailPage';
import { CartCheckout } from '../../pages/ShoppingCart/components/CartCheckout';
import { CartConfirmationPage } from '../../pages/ShoppingCart/components/CartConfirmationPage';
import { ShoppingCart } from '../../pages/ShoppingCart';
import { AdvantagesPage } from '../../pages/AdvantagesPage';

export interface RouteProps {
  component: React.ComponentType<any>;
  id: string;
  path: string | readonly string[];
  main?: boolean;
  routes?: RouteProps[];
  private: boolean;
}

/**
 * Routes list
 * @type {[]}
 */
const routes: RouteProps[] = [
  {
    id: 'signin',
    path: '/auth/signin',
    component: LoginPage,
    private: false,
  },
  {
    id: 'signup',
    path: '/auth/signup',
    component: LoginPage,
    private: false,
  },
  {
    id: 'confirm',
    path: '/auth/confirmemail',
    component: ConfirmationEmail,
    private: false,
  },
  {
    id: 'forgot',
    path: '/auth/forgot',
    component: ForgotPassword,
    private: false,
  },
  {
    id: 'account',
    path: '/account',
    component: AccountPage,
    private: true,
  },
  {
    id: 'rings_details',
    path: [
      ROUTER_PATHS.RINGS_WEDDING_DETAILS_PATH,
      ROUTER_PATHS.RINGS_ENGAGEMENT_DETAILS_PATH,
      ROUTER_PATHS.DIAMONDS_DETAILS_PATH,
      ROUTER_PATHS.JEWELRY_DETAILS_PATH,
    ],
    component: ProductDetailPage,
    private: false,
  },
  {
    id: 'diamonds_catalog',
    path: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    component: Diamonds,
    private: false,
  },
  {
    id: 'diamonds',
    path: ROUTER_PATHS.DIAMONDS_PATH,
    component: StoneMainPage,
    private: false,
  },
  {
    id: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    path: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
    component: WeddingRingsCatalogPage,
    private: false,
  },
  /* Engagement rings PATH */
  {
    id: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    path: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
    component: EngagementRingsCatalogPage,
    private: false,
  },
  {
    id: ROUTER_PATHS.RINGS_ENGAGEMENT_PATH,
    path: ROUTER_PATHS.RINGS_ENGAGEMENT_PATH,
    component: EngagementRingsMainPage,
    private: false,
  },
  {
    id: 'jewelry_catalog',
    path: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
    component: FineJewelryPage,
    private: false,
  },
  {
    id: 'cart_checkout',
    path: '/cart/checkout',
    component: CartCheckout,
    private: false,
  },
  {
    id: 'cart_confirmation',
    path: '/cart/confirmation',
    component: CartConfirmationPage,
    private: false,
  },
  {
    id: 'cart',
    path: '/cart',
    component: ShoppingCart,
    private: false,
  },
  {
    id: 'education',
    path: ROUTER_PATHS.EDUCATION_PATH,
    component: EducationPage,
    routes: [
      {
        id: 'clarity',
        path: ROUTER_PATHS.EDUCATION_CLARITY_PATH,
        component: Clarity,
        private: false,
      },
      {
        id: 'color',
        path: ROUTER_PATHS.EDUCATION_COLOR_PATH,
        component: Color,
        private: false,
      },
      {
        id: 'cutting',
        path: ROUTER_PATHS.EDUCATION_CUTTING_PATH,
        component: Cutting,
        private: false,
      },
      {
        id: 'carat',
        path: ROUTER_PATHS.EDUCATION_CARAT_PATH,
        component: Carat,
        private: false,
      },
    ],
    private: false,
  },
  {
    id: 'faq',
    path: ROUTER_PATHS.FAQ_PATH,
    component: FAQPage,
    private: false,
  },
  {
    id: 'contacts',
    path: '/contacts',
    component: ContactsPage,
    private: false,
  },
  {
    id: 'policy',
    path: '/policy',
    component: Policy,
    private: false,
  },
  {
    id: 'compare',
    path: '/compare',
    component: ComparePage,
    private: false,
  },
  {
    id: 'hint_page',
    path: '/hint/:productId',
    component: HintPage,
    private: false,
  },
  {
    id: 'advantages',
    path: '/advantages',
    component: AdvantagesPage,
    private: false,
  },
  {
    id: '404',
    path: '/404',
    component: Page404,
    private: false,
  },
  {
    id: ROUTER_PATHS.RINGS_WEDDING_PATH,
    path: ROUTER_PATHS.RINGS_WEDDING_PATH,
    component: WeddingRingsMainPage,
    private: false,
  },
  {
    id: ROUTER_PATHS.JEWELRY_PATH,
    path: ROUTER_PATHS.JEWELRY_PATH,
    component: FineJewelryMainPage,
    private: false,
  },
  {
    id: 'mainPage',
    path: '/',
    main: true,
    component: MainPage,
    private: false,
  },
];

export default routes;
