import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { OrdersModal as DefaultComponent } from './OrderModal';
import store from 'root/store';
import OrderModalStore from './ordersModal.store';

const { orders } = store;
const orderModalStore = new OrderModalStore();

interface IOrderModalPageProps extends RouteComponentProps {
  id: string;
  close: () => void;
}

export const OrderModalPage = withRouter((props: IOrderModalPageProps) => (
  <DefaultComponent
    {...props}
    currency={orders.currency}
    store={orderModalStore}
  />
));
