import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  BestSellersCarousel as DefaultComponent,
  BestSellersCarouselProps,
} from './BestSellersCarousel';
import { BestSellersCarouselStore } from './BestSellersCarousel.store';

const bestSellersStore = new BestSellersCarouselStore();

export const BestSellersCarousel = withRouter(
  (props: RouteComponentProps & BestSellersCarouselProps) => (
    <DefaultComponent {...props} store={bestSellersStore} />
  ),
);

export default BestSellersCarousel;
