import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function DiamondSize200(props: IProps): JSX.Element {
  return (
    <Svg width="63" height="62" viewBox="0 0 63 62" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.868 1.212C15.416 1.212 2.079 14.548 2.079 31c0 16.452 13.337 29.788 29.789 29.788 16.451 0 29.788-13.336 29.788-29.788 0-16.452-13.337-29.788-29.788-29.788ZM.868 31c0-17.12 13.879-31 31-31 17.12 0 31 13.88 31 31 0 17.12-13.88 31-31 31-17.121 0-31-13.88-31-31Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.16.273c.17.146.19.4.044.57l-8.326 9.68 8.18 3.52a.404.404 0 0 1-.014.747l-20.935 8.153-9.337 8.332a.404.404 0 0 1-.538-.602l9.391-8.381a.405.405 0 0 1 .122-.075l20.085-7.822-7.778-3.346a.404.404 0 0 1-.147-.635L31.591.316c.146-.169.4-.188.57-.043Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.46 2.417c.212-.07.44.044.51.256l2.626 7.876a.404.404 0 0 1 .004.244l-2.424 8.078a.473.473 0 0 1-.01.033l-4.848 12.218a.404.404 0 0 1-.721.06l-4.983-8.271-7.051-3.079a.404.404 0 0 1 .323-.74l7.17 3.13c.076.034.14.09.184.162l4.628 7.683 4.54-11.445 2.381-7.939-2.585-7.755a.404.404 0 0 1 .256-.51Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.422 8.863a.404.404 0 0 1 .319-.1l12.52 1.514a.404.404 0 1 1-.096.802L11.103 9.62l.195 12.968a.404.404 0 1 1-.808.012l-.202-13.43a.404.404 0 0 1 .134-.307ZM31.634.273a.404.404 0 0 1 .57.043l8.684 10.098a.404.404 0 0 1-.147.635l-7.776 3.345 20.183 7.822a.405.405 0 0 1 .125.077l9.29 8.381a.404.404 0 0 1-.541.6l-9.235-8.331-21.035-8.153a.404.404 0 0 1-.014-.747l8.179-3.52L31.59.844a.404.404 0 0 1 .043-.57Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.44 2.419c.211.073.323.303.25.514l-2.684 7.753 2.381 7.936 4.543 11.453 4.728-7.694a.404.404 0 0 1 .18-.158l7.069-3.13a.404.404 0 0 1 .327.738l-6.954 3.08-5.084 8.274a.404.404 0 0 1-.72-.063L42.63 18.904a.426.426 0 0 1-.012-.033l-2.423-8.078a.403.403 0 0 1 .005-.248l2.726-7.877a.404.404 0 0 1 .514-.25Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.372 8.862a.404.404 0 0 1 .135.305l-.101 13.43a.404.404 0 1 1-.808-.006l.098-12.971-12.066 1.46a.404.404 0 1 1-.097-.803l12.521-1.514a.404.404 0 0 1 .318.1ZM1.203 30.701a.404.404 0 0 1 .57-.029l9.336 8.432 20.933 8.052a.404.404 0 0 1 .019.746l-8.186 3.617 8.329 9.685a.404.404 0 0 1-.613.527l-8.684-10.098A.404.404 0 0 1 23.05 51l7.79-3.441-20.091-7.728a.404.404 0 0 1-.126-.077l-9.39-8.482a.404.404 0 0 1-.03-.57Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.975 30.57a.404.404 0 0 1 .344.254l4.847 12.32.01.032 2.424 8.078a.404.404 0 0 1-.004.244l-2.625 7.876a.404.404 0 0 1-.767-.256l2.585-7.755-2.381-7.94-4.54-11.536-4.627 7.774a.404.404 0 0 1-.19.166l-7.17 3.029a.404.404 0 0 1-.314-.744l7.047-2.978 4.982-8.368a.404.404 0 0 1 .38-.196Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9 39.05a.404.404 0 0 1 .398.41l-.195 12.968 12.062-1.459a.404.404 0 1 1 .097.802L10.74 53.286a.404.404 0 0 1-.453-.407l.202-13.43a.404.404 0 0 1 .41-.398ZM62.59 30.7c.15.165.14.42-.026.57l-9.29 8.483a.405.405 0 0 1-.128.079l-20.188 7.727L40.745 51a.404.404 0 0 1 .143.633l-8.684 10.098a.404.404 0 0 1-.613-.527l8.33-9.685-8.187-3.617a.404.404 0 0 1 .02-.746l21.032-8.051 9.234-8.431a.404.404 0 0 1 .57.026Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.818 30.57c.153-.013.3.061.38.193l5.082 8.371 6.95 2.978a.404.404 0 1 1-.319.743l-7.068-3.03a.405.405 0 0 1-.186-.16l-4.727-7.787-4.543 11.546-2.381 7.937 2.684 7.753a.404.404 0 0 1-.764.264L40.2 51.502a.403.403 0 0 1-.005-.248l2.423-8.078a.4.4 0 0 1 .011-.032l4.847-12.32a.404.404 0 0 1 .342-.254Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.999 39.05a.404.404 0 0 1 .407.402l.1 13.43a.404.404 0 0 1-.452.404l-12.52-1.515a.404.404 0 1 1 .096-.802l12.066 1.46-.098-12.971A.404.404 0 0 1 53 39.05Z"
        fill="#000"
      />
    </Svg>
  );
}
