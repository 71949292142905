import { IObservableArray, makeAutoObservable, observable, when } from 'mobx';
import { CountryImpl, StateImpl } from 'root/store/app';
import {
  FormControl,
  FormGroup,
  patternValidator,
  requiredValidator,
  ValidationEvent,
  ValidationEventTypes,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';
import { PaymentTypes } from 'pages/ShoppingCart/constants';
import {
  IFormCardPaymentStep,
  IFormControls,
  IFormPaymentStep,
  TFormPaymentValues,
} from './paymentForms.types';
import store from 'root/store';
import { TFormValues } from '../ShippingStep/shippingForm.types';
import { autofill } from '../cartCheckout.store';
import { TGetPaymentProvidersOutput } from 'service/api/apiTypes/cart.types';
import { setAffirmJSPublicApiKey } from 'lib/affirm/wrapper';
import shippingFormStore from '../ShippingStep/shipping.store';

const { app } = store;

export class PaymentFormsStore {
  countries: IObservableArray<CountryImpl> = app.countries;
  states = observable.array<StateImpl>([]);
  cardForms = observable.array<FormGroup<IFormCardPaymentStep>>([]);

  form: FormGroup<IFormControls>;
  billingForm: FormGroup<IFormPaymentStep>;

  paymentProviders: TGetPaymentProvidersOutput[] = [];

  constructor() {
    makeAutoObservable(this);

    this.form = this.initialForm();
    this.billingForm = this.initialBillingForm();
    this.cardForms.push(this.initialCardForm());

    when(
      () => autofill.isEmptyFill,
      () => {
        this.updateForm(autofill.deliveryFill, autofill.paymentFill);
      },
    );
  }

  /**
   * Reset all forms
   */
  updateForm(delivery: TFormValues, payment: TFormPaymentValues): void {
    const { controls: billingControls } = this.billingForm;

    for (const field in billingControls) {
      if (
        Object.hasOwnProperty.call(billingControls, field) &&
        Object.hasOwnProperty.call(delivery, field)
      ) {
        if (payment.sameShippingAddress && delivery[field]) {
          (billingControls[field] as FormControl).value =
            (delivery[field] as CountryImpl).id || delivery[field];
        }

        if (!payment.sameShippingAddress && payment[field]) {
          (billingControls[field] as FormControl).value =
            (payment[field] as CountryImpl).id || payment[field];
        }
      }
    }
  }

  /**
   * Reset all forms
   */
  resetForms(): void {
    this.form = this.initialForm();
    this.billingForm = this.initialBillingForm();
    this.cardForms.push(this.initialCardForm());

    when(
      () => autofill.isEmptyFill,
      () => {
        this.updateForm(autofill.deliveryFill, autofill.paymentFill);
      },
    );
  }

  addCardForm(): void {
    this.cardForms.push(this.initialCardForm());
  }

  removeCardForm(index: number): void {
    index = index || this.cardForms.length - 1;
    this.cardForms.splice(index, 1);
  }

  *loadPaymentProviders(): Generator<
    Promise<TGetPaymentProvidersOutput[]>,
    void,
    TGetPaymentProvidersOutput[]
  > {
    const providers = yield autofill.fetchPaymentProviders();
    this.paymentProviders = providers;
    const affirm = this.paymentProviders.find(
      (provider) => provider.type === PaymentTypes.affirm,
    );
    if (affirm && affirm.publicKey) {
      setAffirmJSPublicApiKey(affirm.publicKey);
    }
  }

  /**
   * Get billing country
   * @returns {CountryImpl | undefined}
   */
  private getBillingCountry = (): CountryImpl | undefined => {
    const countryId = !this.form?.controls.sameShippingAddress.value
      ? this.billingForm?.controls.country.value
      : shippingFormStore.form?.controls.country.value;
    return this.countries.find((c) => c.id === Number(countryId));
  };

  /**
   * Return controls collection
   */
  private initialForm = (): FormGroup<IFormControls> =>
    new FormGroup<IFormControls>({
      sameShippingAddress: new FormControl<boolean>(true),
      paymentType: new FormControl<string>(PaymentTypes.creditCard, {
        validators: [
          async (control: FormControl<string>) => {
            if (
              control.value === PaymentTypes.affirm &&
              this.getBillingCountry()?.iso2 !== 'US'
            ) {
              return [
                {
                  message:
                    'Affirm is supported only in United States of America',
                  type: ValidationEventTypes.Error,
                },
              ];
            }
            return [];
          },
        ],
      }),
    });

  /**
   * Return billing controls collection
   */
  private initialBillingForm = (): FormGroup<IFormPaymentStep> =>
    new FormGroup<IFormPaymentStep>({
      country: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
        activate: () => {
          return !this.form?.controls.sameShippingAddress.value;
        },
      }),
      state: new FormControl<string>('', {
        validators: [],
        activate: () => {
          return !this.form?.controls.sameShippingAddress.value;
        },
      }),
      city: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
        activate: () => {
          return !this.form?.controls.sameShippingAddress.value;
        },
      }),
      address: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
        activate: () => {
          return !this.form?.controls.sameShippingAddress.value;
        },
      }),
      aptUnit: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            patternValidator(
              /[\d\s]*$/,
              'The field format is invalid',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
        activate: () => {
          return !this.form?.controls.sameShippingAddress.value;
        },
      }),
      zipCode: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^\d+$/,
              'The field format is invalid',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
        activate: () => {
          return !this.form?.controls.sameShippingAddress.value;
        },
      }),
    });

  /**
   * Return card controls collection
   */
  private initialCardForm = (): FormGroup<IFormCardPaymentStep> =>
    new FormGroup<IFormCardPaymentStep>({
      number: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^\s*-?[0-9]{1,16}\s*$/,
              'The field format is invalid',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
        activate: () => {
          return (
            this.form.controls.paymentType.value === PaymentTypes.creditCard
          );
        },
      }),
      code: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[0-9]{3,4}$/,
              'The field format is invalid',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
        activate: () => {
          return (
            this.form.controls.paymentType.value === PaymentTypes.creditCard
          );
        },
      }),
      expirationDate: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            PaymentFormsStore.isValidExpData,
          ]),
        ],
        activate: () => {
          return (
            this.form.controls.paymentType.value === PaymentTypes.creditCard
          );
        },
      }),
      cardholder: new FormControl<string>('', {
        validators: [
          requiredValidator('Field is required', ValidationEventTypes.Error),
        ],
        activate: () => {
          return (
            this.form.controls.paymentType.value === PaymentTypes.creditCard
          );
        },
      }),
      amount: new FormControl<number>(0, {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[0-9.]+$|(^$)/,
              'The field format is invalid',
              ValidationEventTypes.Error,
            ) as any,
          ]),
        ],
        activate: () => {
          return (
            this.form.controls.paymentType.value === PaymentTypes.creditCard
          );
        },
      }),
    });

  /**
   * Custom validator for exp data
   * @param control
   */
  static isValidExpData = async (
    control: FormControl,
  ): Promise<ValidationEvent[]> => {
    if (control.value.match(/^(0\d|1[0-2])\/\d{2}$/)) {
      const { 0: month, 1: year } = control.value.split('/');
      const year20thCentury = 2000 + Number(year);
      const actual = new Date(year20thCentury, Number(month));
      const current = new Date();
      if (actual.getTime() < current.getTime()) {
        return [
          {
            message: 'Сard is not valid',
            type: ValidationEventTypes.Error,
          },
        ];
      }
    }
    return [];
  };
}

export default new PaymentFormsStore();
