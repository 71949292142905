import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { joinClasses } from 'lib/utils';
import Icon from 'components/common/Icon';
import useDebounce from 'hooks/useDebounce';

import './SearchCustomSelect.scss';

export interface ICustomSelect {
  id?: string;
  title: string;
  values: string;
  disabled?: boolean;
  emptyValue?: string;
  changeHandler: (q: string) => void;
}

export const SearchCustomSelect = observer((props: ICustomSelect) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');

  const searchHandler = () => {
    props.changeHandler(value);
    runInAction(() => {
      setIsOpen(true);
    });
  };

  useDebounce(
    () => {
      searchHandler();
    },
    [value],
    800,
  );

  return (
    <div className={joinClasses(['custom-select-labelled', isOpen && 'open'])}>
      <div className="select">
        <label className="select__label">
          <div className="btn btn_circle">
            <Icon id="search" className="icon_mr-xs" />
          </div>
        </label>
        <span className="select-inner-wrap">
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={'Find products by SKU'}
            className="select__inner"
            type="text"
          />
        </span>
      </div>
    </div>
  );
});
