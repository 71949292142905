import * as React from 'react';
import { observer } from 'mobx-react';
import { AdvantagesPageProps } from './types';
import { Accordion, AccordionItem } from 'components/common/Accordion';
import Icon from 'components/common/Icon';

import './AdvantagesPage.scss';

export const AdvantagesPage = observer((props: AdvantagesPageProps) => {
  const { store } = props;

  return (
    <>
      <div className="bg-image_behind"></div>

      <div className="advantages-page grid">
        <div className="bg-image_in-front" />

        <div className="grid-area-advantages block-scrollbar">
          <div className="advantages-header">
            <Icon id="gem_light" />
            <h1 className="advantages-header__title">VENORI ADVANTAGES</h1>
          </div>
          <div className="advantages-list">
            {store.advantages.map((advantage) => (
              <div className="advantages-list-item" key={advantage.title}>
                <div
                  className="advantages-list-item__title"
                  onClick={store.openInfo}
                >
                  {advantage.title}
                </div>
                {advantage.isOpen && (
                  <div className="advantages-list-item__info">
                    {advantage.description}
                  </div>
                )}
              </div>
            ))}
          </div>

          <Accordion className="accordion_mobile">
            {store.advantages.map((advantage, index) => {
              return (
                <AccordionItem id={index} key={index} title={advantage.title}>
                  <p>{advantage.description}</p>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </>
  );
});
