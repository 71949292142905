import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import CustomSelect from '../../form/CustomSelect';
import Icon from '../Icon';
import OrderStore from 'root/store/order';

interface QuantityItemsProps {
  limit: number;
  handleLimit: (quantity: number) => void;
  setPage?: (page: number) => void;
  quantityItems?: number[];
  isTilesCatalog?: boolean;
  handleOrder?: (property: string, direction: (string | null)) => void;
  order?: OrderStore;
  sortValues?: {name: string; value: string}[];
}

export const ITEMS_PER_PAGE_VALUES = [
  { name: '12', value: '12' },
  { name: '24', value: '24' },
  { name: '48', value: '48' },
  { name: '96', value: '96' },
];

export const QuantityItems = observer((props: QuantityItemsProps) => {
  const [openFilter, setOpenFilter] = useState(false);
  const sortByValue = props.order ? props.order.sortBy+'_'+props.order.sortOrder :null;

  useEffect(() => {
    const filterBlock = document.querySelector('.grid-area-filters');
    if (openFilter) {
      filterBlock && filterBlock.classList.add('show');
    } else {
      filterBlock && filterBlock.classList.remove('show');
    }
  }, [openFilter]);

  const handleShowBy =
    (quantity: number) =>
    (event: React.MouseEvent<Element>): void => {
      event.preventDefault();

      props.handleLimit(quantity);
      props.setPage && props.setPage(1);
      // this.setState({
      //   isActive: quantity,
      // });
    };

  const handleFilterLimit = (name: string, filter: { value: string }) => {
    props.handleLimit(Number(filter.value));
    props.setPage && props.setPage(1);
  };

  const orderByPrice = (name: string, {value}: any) => {
    const [sortBy, sortOrder] = value.split('_');
    props.handleOrder &&   props.handleOrder(sortBy, sortOrder)
  }

  const { limit, quantityItems, sortValues } = props;
  return (
    <>
      {quantityItems ? (
        <div className="grid-area-items-per-page">
          <ul className="quantity-items">
            {quantityItems.map((value: number) => (
              <li
                className={value === limit ? 'active' : ''}
                key={value}
                onClick={handleShowBy(value)}
              >
                <span>{value}</span>
              </li>
            ))}
            <span className="quantity-items__label">items per page</span>
          </ul>
        </div>
      ) : (
        <div className="items-per-page">
          <div
            onClick={() => setOpenFilter(!openFilter)}
            className="btn btn_lg2 btn_bordered-dark-gray"
          >
            <Icon id="filter" />
            Filter by
          </div>
          <CustomSelect
            defaultValue={'12'}
            name="items"
            label="Items per page"
            values={ITEMS_PER_PAGE_VALUES}
            onChange={handleFilterLimit}
            emptyValue="All"
          />
          {(sortByValue && sortValues) && <CustomSelect
              defaultValue={sortByValue}
              name="SortBy"
              label="Sort By"
              values={sortValues}
              onChange={orderByPrice}
              emptyValue="All"
          />
          }
        </div>
      )}
    </>
  );
});
