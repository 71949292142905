import React from 'react';
import Svg from '../Svg';

interface IGemLargeIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function GemLargeIcon(props: IGemLargeIconProps): JSX.Element {
  return (
    <Svg width="102" height="73" viewBox="0 0 102 73" fill="none" {...props}>
      <path
        data-name="Path 936"
        d="M89.795 12.917a.984.984 0 0 0-.33-.249L67.677 2.581a.981.981 0 0 0-.43-.177L50.864.01a.989.989 0 0 0-.291 0l-16.264 2.5a.976.976 0 0 0-.264.081l-21.7 10.08a.979.979 0 0 0-.33.249L.239 26.553a.982.982 0 0 0-.068 1.2c.014.02.03.037.046.056l6.04 7.589c.038.048.357.327.357.327l43.657 37a.982.982 0 0 0 1.27 0L95.29 35.647s.226-.2.264-.248l6.04-7.589c.015-.019.032-.036.046-.056a.983.983 0 0 0-.068-1.2Zm-1.349 1.444 9.223 10.679-13.517-4.766L75.125 9.08l-1.914-1.788Zm-7.868 4.652-11.472-4.046 4.7-4.21ZM34.896 4.406l15.83-2.431 15.957 2.332 5.832 4.971-5.563 4.982-15.83 2.431-15.958-2.332-5.833-4.971Zm13.968 38.346-20.905-4.485 13.3-6.272Zm-12.5-26.233 13.011 1.9-7.582 10.385Zm13 28.347-7.54 9.361-13.498-13.869Zm-6.57-14.1 8.115-11.115 8.112 11.116-8.115 11.461ZM52.946 42.75l7.614-10.753 13.3 6.272Zm20.542-2.394L59.99 54.227l-7.54-9.361ZM60.02 28.805l-7.545-10.338 12.994-2ZM32.809 14.931l-11.577 4.082 6.732-8.21Zm-19.445-.57 15.523-7.21s-1.771 1.6-1.8 1.636l-9.422 11.49-13.517 4.766ZM2.61 27.667l12.645-4.459-8.218 10.021Zm15.337-4.643 5.69 14.324-14.83-3.181Zm-7.302 13.543 13.564 2.907.334 8.872Zm8.888-14.873 14.68-5.176 6.082 13.761-14.613 6.893Zm7.04 28.372-.341-9.045 14.862 15.275 6.348 11.458Zm24.332 19.883-7.836-14.142 7.836-9.729 7.836 9.729Zm24.332-19.882L54.364 67.755l6.349-11.458 14.862-15.275ZM61.516 30.279l6.081-13.761 14.68 5.176-6.149 15.477Zm15.748 18.068.334-8.872 13.566-2.908Zm.907-11 5.69-14.323 9.143 11.143Zm16.6-4.119-8.218-10.021 12.645 4.459Z"
        fill="#4d4048"
      />
    </Svg>
  );
}
