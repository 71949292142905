import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/common/Icon';

import './ButtonsBlock.scss';

export function ButtonsBlock(): JSX.Element {
  return (
    <div className="button-block">
      <Link
        to={{
          pathname: '/faq/return-policy',
        }}
        className="btn btn__bordered-default_without_border btn_text-big btn_my-md"
      >
        <Icon id="badge_dollar" className="icon_mr-sm" />
        <span>100% Money Back Guarantee</span>
      </Link>
      <Link
        className="btn btn__bordered-default_without_border btn_text-big btn_my-md"
        to={{
          pathname: '/faq/shipping',
        }}
      >
        <Icon id="truck" className="icon_mr-sm" />
        <span>Free Overnight Shipping</span>
      </Link>
      <Link
        className="btn btn__bordered-default_without_border btn_text-big btn_my-md"
        to={{
          pathname: '/faq/return-policy',
        }}
      >
        <Icon id="star_christmas" className="icon_mr-sm" />
        <span>Free 14-Day Returns</span>
      </Link>
      <Link
        className="btn btn__bordered-default_without_border btn_text-big btn_my-md"
        to={{
          pathname: '/faq/warranty-and-maintenance',
        }}
      >
        <Icon id="infinity" className="icon_mr-sm" />
        <span>Life-Time Care</span>
      </Link>
    </div>
  );
}

export default memo(ButtonsBlock);
