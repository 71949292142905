import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ShoppingCart as DefaultComponent } from './ShoppingCart';
import shoppingCartStore from './store';
import rootStore from 'root/store';

const { app, cart } = rootStore;

export const ShoppingCart = withRouter((props: RouteComponentProps) => (
  <DefaultComponent
    {...props}
    store={shoppingCartStore}
    countries={app.countries}
    cart={cart}
  />
));
