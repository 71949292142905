import * as React from 'react';
import { observer } from 'mobx-react';
import { match as IMatch } from 'react-router-dom';
import ProductDetailPage from './ProductDetailPage';
import rootStore from 'root/store';
import { getCurrentCategory } from 'lib/utils';
import { ProductCategories } from 'root/ProductTypes';
import ProductDetailPageStore from './store/productDetail.store';
import hintForProductSetStore from 'components/HintForProductSet/HintForProductSet.store';
import { previewStore } from 'components/app/Preview/PreviewStore';
import { TYPES as ringTypes } from 'pages/WeddingRings/components/Panel/constants';
import { TYPES as stoneTypes } from '../../utils/constants/stones/panel.constants';
import { TYPES as jewelryTypes } from '../FineJewelry/components/Panel/constants';
import { ROUTER_PATHS } from 'root/routes/constants';
import { getConvertBase64QueryParams } from './util';

interface IMatchProps extends IMatch {
  params: {
    jewelryId?: string;
    stoneId?: string;
    ringId?: string;
  };
}

interface ProductDetailPageProps {
  match: IMatchProps;
  location: Location;
}

const {
  cart,
  compare,
  favorite,
  rings: { detail: ring },
  browserWindowType,
} = rootStore;

const productStore = new ProductDetailPageStore();

export default observer((props: ProductDetailPageProps) => {
  const {
    match: { params },
    location: { search },
  } = props;
  const { ringId, stoneId, jewelryId } = params || {};

  const { customizationParams, setWithItems } =
    getConvertBase64QueryParams(search);

  setWithItems && hintForProductSetStore.removeSet();

  const getPropsByMatchParams = () => {
    if (jewelryId)
      return {
        productId: Number(jewelryId),
        productTitle: 'jewelry',
        inventoryPath: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        constantTypes: jewelryTypes,
      };

    if (stoneId)
      return {
        productId: Number(stoneId),
        productTitle: 'diamonds',
        inventoryPath: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        constantTypes: stoneTypes,
      };

    return {
      productId: Number(ringId),
      productTitle: 'rings',
      inventoryPath: getCurrentCategory(ring.categories).includes(
        ProductCategories.WEDDING_RINGS,
      )
        ? ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH
        : ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
      constantTypes: ringTypes,
    };
  };

  if (!ringId && !stoneId && !jewelryId) {
    window.location.href = '/404';
    return null;
  }

  return (
    <ProductDetailPage
      {...getPropsByMatchParams()}
      cartPreview={previewStore}
      productStore={productStore}
      cart={cart}
      compare={compare}
      favorite={favorite}
      browserWindowType={browserWindowType}
      defaultCustomizationParams={customizationParams || null}
      setWithItems={setWithItems || null}
    />
  );
});
