export const VENORI_POLICIES = {
  text: 'Venori Policies',
  items: [
    { link: '/policy/privacypolicy', text: 'Privacy Policy', params: null },
    { link: '/policy/shippingpolicy', text: 'Return Policy', params: null },
    {
      link: '/policy/termsconditions',
      text: 'Terms & Conditions',
      params: null,
    },
  ],
};

export const CUSTOMER_INFORMATION = {
  text: 'Customer Information',
  items: [
    { link: '/faq/payments', text: 'Payment options', params: null },
    { link: '/faq/shipping', text: 'Shipping', params: null },
    { link: '/faq/warranty-and-maintenance', text: 'Warranty', params: null },
  ],
};

export const USEFUL_INFORMATION = {
  text: 'Useful Information',
  items: [
    {
      link: '/faq/conflict-free',
      text: 'Conflict-free diamonds',
      params: null,
    },
    {
      link: '/faq/complimentary-ring-sizing',
      text: 'Complimentary Ring size',
      params: null,
    },
    { link: '/faq/about-us', text: 'About us', params: null },
  ],
};

export const PHONE_NUMBER = '+1-877-382-6275';
