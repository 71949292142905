import * as React from 'react';
import { observer } from 'mobx-react';
import { FormGroup } from '@quantumart/mobx-form-validation-kit';
import {
  IFormCardPaymentStep,
  IFormControls,
} from '../../CartCheckout/PaymentStep/paymentForms.types';
import { PaymentTypes, PaymentTypesTitle } from '../../../constants';

interface CustomerOrderBlockProps {
  paymentForm: FormGroup<IFormControls>;
  cardForms: FormGroup<IFormCardPaymentStep>[];
}

export const PaymentDetailsOrderBlock = observer(
  ({ cardForms, paymentForm }: CustomerOrderBlockProps) => {
    return (
      <div className="order-block">
        <h3 className="order-block__title uppercase">payment details</h3>
        <table className="order-block__table">
          <tbody>
            <tr>
              <td className="label">Payment Type</td>
              <td className="value">
                {PaymentTypesTitle[paymentForm.controls.paymentType.value]}
              </td>
            </tr>
            {paymentForm.controls.paymentType.value === PaymentTypes.creditCard
              ? cardForms.map((card: FormGroup<IFormCardPaymentStep>) => (
                  <>
                    <tr>
                      <td
                        colSpan={2}
                        className="value"
                      >{`XXXX XXXX XXXX ${card.controls.number.value.slice(
                        -4,
                      )}`}</td>
                    </tr>
                    <tr>
                      <td className="label">Cardholder</td>
                      <td className="value">
                        {card.controls.cardholder.value}
                      </td>
                    </tr>
                  </>
                ))
              : null}
          </tbody>
        </table>
      </div>
    );
  },
);
