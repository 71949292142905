import React from 'react';
import Svg from '../../Svg';

interface IPearTableIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PearTableIcon(props: IPearTableIconProps): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M119.2 136.7 24.62 85.64v-5.11l12.44-8.66 27.3-7.77h85.48l23.98 19.09v5.55L119.2 136.7z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m37.06 71.87 21.75 9.33 21.32-8.5-.44-8.6-42.63 7.77zM104.25 64.1l-24.12 8.6 24.12 10.86 20.14-11.69-20.14-7.77zM133.41 64.1l-9.02 7.77L142 83.56l6.36-11.84-14.95-7.62zM149.84 64.1l-1.48 7.62 11.25 11.84 1.63-10.39M80.13 72.7l-1.04 9.32M124.39 71.87l-.6 11.69"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.82 88.74c-2.77 0-4-1.9-7-1.9s-4.11 2.13-7 2.13c-2.89 0-6.67-2.37-9.66-2.37s-4.89 1.89-7.44 1.89c-2.55 0-6.77-1.89-9.33-1.89-2.56 0-7.54 1.89-9.62 1.89-2.08 0-5.81-2-9.25-1.89-3.44.11-6.88 1.26-10.32 1.81-3.44.55-9.88-2.11-13.21-2.17-3.33-.06-9.33 1.28-11.92 1.51-2.59.23-8.06-2.23-10.28-2.56-2.22-.33-6 .22-8.44.45-2.44.23-12-.67-18.65-.89-5.712-.013-11.42.285-17.1.89v-5.11c3-.52 12 1.89 17.62 1.7 4.75-.15 12.69-1 16.57-1 3.71 0 6.78 2.36 10.37 2.36 3 0 6.12-1.54 9.91-1.54 4.32 0 8.15 2.8 12.29 2.88 4.14.08 9.77-1.48 12.88-1.34 3.11.14 6.18 1.26 10.5 1.34 4.32.08 6.07-1.34 9-1.34 2.93 0 6.29 1.53 10.07 1.56 3.78.03 5.62-1.56 8.14-1.56 2.52 0 6.22 1.78 8.88 1.63 2.66-.15 6.37-1.48 8.73-1.63 2.36-.15 5.41 1.7 7.94 1.74 1.48 0 6.27-2.11 6.27-2.11"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m24.62 85.64 48.52 17.76-12.77-17.76 43.18 31.86-24.46-29.75M123.79 88.49l-2.03 28.449-17.54-28.53-.67 29.09 15.65 19.2 2.56-19.76 20.98-28.45 2.69 25.18 14.41-24.7M73.13 103.399l46.08 33.3"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.74 48.51h151.19"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.74 85.64v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.74 81.03V51.56"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.1 3.1"
      />
      <path
        d="M23.74 50.01v-1.5M174.93 85.64v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.93 81.03V51.56"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.1 3.1"
      />
      <path
        d="M174.93 50.01v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.09 33.81h85.57"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.09 64.1v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.09 59.57V36.83"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.03 3.03"
      />
      <path
        d="M64.09 35.31v-1.5M149.66 64.1v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.66 59.57V36.83"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.03 3.03"
      />
      <path
        d="M149.66 35.31v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
