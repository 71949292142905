import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getImg } from 'lib/utils';
import ImgLoader from 'components/common/Loaders/ImgLoader';
import Icon from 'components/common/Icon';
import { COLOR_IMAGES, TabLinks } from '../../constants';
import { TabsLinks } from '../TabLinks';

import '../../EducationPage.scss';

const Color = (): JSX.Element => {
  return (
    <>
      <div className="education-page-img__container grid-area-cover-image-container">
        <ImgLoader
          folder="education-imgs/"
          images={COLOR_IMAGES}
          name="education-img-cover-color"
        >
          <TransitionGroup component={null}>
            <CSSTransition
              appear
              timeout={400}
              classNames="switch-transparency"
            >
              <div
                className="grid-area-cover-image color"
                style={{
                  backgroundImage: `url(${getImg(
                    'education-imgs/',
                    COLOR_IMAGES,
                    'education-img-cover-color',
                  )})`,
                }}
              ></div>
            </CSSTransition>
          </TransitionGroup>
        </ImgLoader>
      </div>

      <div className="grid-area-tabs block-scrollbar block-scrollbar_margin-right">
        <div className="tabs_container">
          <TabsLinks activeTab={TabLinks.COLOR} />

          <div className="tabs-panels block-scrollbar">
            <div className="panel">
              <div className="panel-header">
                <Icon id="gem_light" />
                <h1 className="panel-header__title">DIAMOND COLOR</h1>
              </div>
              <div className="panel-text-block">
                <p>
                  Color grade describes the hue of a diamond — ranging from pure
                  white to noticeably yellow. D grade diamonds are purest white
                  — they’re also rarest and most expensive.
                </p>
                <p>
                  To strike the best balance between cost and beauty, go for
                  diamonds in the Near Colorless range: G, H and I.
                </p>
              </div>
              <div className="panel-imgs-wrap">
                <div className="three-imgs-in-block colorless">
                  <div className="figures-wrap">
                    <figure className="figure">
                      <figcaption>D</figcaption>
                      <Icon id="color_d" className="figure__img" />
                    </figure>
                    <figure className="figure">
                      <figcaption>E</figcaption>
                      <Icon id="color_e" className="figure__img" />
                    </figure>
                    <figure className="figure">
                      <figcaption>F</figcaption>
                      <Icon id="color_f" className="figure__img" />
                    </figure>
                  </div>
                  <p className="imgs-block__title colorless">Colorless</p>
                </div>
                <div className="three-imgs-in-block near-colorless">
                  <div className="figures-wrap">
                    <figure className="figure">
                      <figcaption className="near-colorless">G</figcaption>
                      <Icon id="color_g" className="figure__img" />
                    </figure>
                    <figure className="figure">
                      <figcaption className="near-colorless">H</figcaption>
                      <Icon id="color_h" className="figure__img" />
                    </figure>
                    <figure className="figure">
                      <figcaption className="near-colorless">I</figcaption>
                      <Icon id="color_i" className="figure__img" />
                    </figure>
                  </div>
                  <p className="imgs-block__title near-colorless">
                    Near-Colorless
                  </p>
                </div>
                <div className="three-imgs-in-block noticeable-color">
                  <div className="figures-wrap">
                    <figure className="figure">
                      <figcaption className="noticeable-color">J</figcaption>
                      <Icon id="color_j" className="figure__img" />
                    </figure>
                    <figure className="figure">
                      <figcaption className="noticeable-color">K</figcaption>
                      <Icon id="color_k" className="figure__img" />
                    </figure>
                    <figure className="figure">
                      <figcaption className="noticeable-color">L</figcaption>
                      <Icon id="color_l" className="figure__img" />
                    </figure>
                  </div>
                  <p className="imgs-block__title noticeable-color">
                    Noticeable-Color
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Color;
