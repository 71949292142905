import React, { ReactNode } from 'react';

const Accordion: React.FunctionComponent<{
  className?: string;
  children: ReactNode;
}> = ({ className, children }) => {
  return <ul className={`accordion ${className}`}>{children}</ul>;
};

export default Accordion;
