import React, { MouseEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import {
  DIAMOND_CATEGORIES,
  RING_CATEGORIES,
  ProductDetailImpl,
  ProductCategories,
} from 'root/ProductTypes';
import CartStore from 'root/store/cart';
import { CartItemImpl } from 'root/store/cart/item.store';
import { RingImpl } from 'root/store/rings/one.store';
import AddDiamondPopup from 'pages/ProductDetailPage/components/AddDiamondPopup';
import AddRingPopup from 'pages/ProductDetailPage/components/AddRingPopup';
import { REMOVE_CHILD } from 'pages/ShoppingCart/constants';
import CompareStore, { TableParamsImpl } from 'pages/Compare/store';
import { previewStore as cartPreview } from 'components/app/Preview/PreviewStore';
import { getCurrentCategory } from 'lib/utils';
import { Item } from '../Items/ItemValue';
import { ItemPrice } from '../Items/ItemPrice';
import { ItemButton } from '../Items/ItemButton';
import { CompareTemplate } from '../../index';
import { ProductImageTemplate } from '../ProductImage';
import { fetchJewelryDetailExtra } from 'service/api/jewelry';
import { fetchRingExtra } from 'service/api/rings';
import { fetchStoneExtra } from 'service/api/stones';
import { DetailBlocksImpl } from 'service/api/apiTypes/catalogsApiTypes';
import { IProductDetailsExtraSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';
import { StoneImpl } from 'root/store/stones/one.store';

type TExtraSettings =
  | IProductDetailsExtraSettings
  | Record<string, never>
  | undefined;

interface ICompareItemProps {
  product: ProductDetailImpl;
  cart: CartStore;
  store: CompareStore;
  handleRemoveItem: (
    item: ProductDetailImpl,
  ) => (event: MouseEvent<HTMLDivElement>) => void;
  doesServerHasItem: (id: number) => boolean;
  doesCartHasItem: (productId: number) => CartItemImpl | undefined;
}

const CompareItem = observer((props: ICompareItemProps) => {
  const {
    product,
    cart,
    store,
    handleRemoveItem,
    doesServerHasItem,
    doesCartHasItem,
  } = props;
  const [settings, setSettings] = useState<TExtraSettings>();
  const visibleDiamondPopup = observable.box(false);
  const visibleAddRingPopup = observable.box(false);
  const isDiamond = product.categories.some(({ alias }) =>
    DIAMOND_CATEGORIES.includes(alias as Partial<ProductCategories>),
  );
  const isRing = product.categories.some(({ alias }) =>
    RING_CATEGORIES.includes(alias as Partial<ProductCategories>),
  );

  const removeFromCart = () => {
    const cartItem = cart.getCartItemById(product.id);

    cartItem && cart.remove(cartItem.guid, REMOVE_CHILD);
    cartPreview.showPreview(true);
  };

  const addToCart = (guid?: string | null) => {
    cart.put({ id: product.id, quantity: 1, guid } as any);
    cartPreview.showPreview(true);
  };

  const handleAddToCart = () => {
    const settingFilters = settings?.filters || [];

    if (doesCartHasItem && doesCartHasItem(product.id)) {
      removeFromCart();
      return;
    }

    if (isDiamond) {
      visibleAddRingPopup.set(true);
      return;
    }

    if (Object.values(settingFilters)?.length > 0) {
      visibleDiamondPopup.set(true);
      return;
    }

    addToCart();
  };

  const loadProductExtra = async (
    apiType: string,
    id: number,
  ): Promise<void> => {
    const getDetailExtra = async () => {
      switch (apiType) {
        case 'jewelry':
          return await fetchJewelryDetailExtra(id);
        case 'rings':
          return await fetchRingExtra(id);
        case 'diamonds':
          return await fetchStoneExtra(id);
        default:
          break;
      }
    };

    const response: DetailBlocksImpl | undefined = await getDetailExtra();
    response?.settings && setSettings(response.settings);
  };

  useEffect(() => {
    const apiType =
      (isDiamond && 'diamonds') || (isRing && 'rings') || 'jewelry';
    void loadProductExtra(apiType, product.id);
  }, []);

  return (
    <>
      <div className="comparison-block-item">
        <div className="comparison-block-item-header">
          <ProductImageTemplate
            product={product}
            handleRemoveItem={handleRemoveItem}
          />

          <h2 className="comparison-block-item-header__sku">{product.sku}</h2>
        </div>

        <ul className="comparison-block-item-features">
          {store.tableParams.map((param: TableParamsImpl) => {
            return (
              <Item property={param} key={param.key}>
                {(() => {
                  switch (param.key) {
                    case '_price':
                      return (
                        <ItemPrice
                          doesServerHasItem={doesServerHasItem}
                          product={product}
                        />
                      );
                    case 'addToCart':
                      return (
                        <ItemButton
                          handleAddToCart={handleAddToCart}
                          doesCartHasItem={doesCartHasItem}
                          doesServerHasItem={doesServerHasItem}
                          cart={cart}
                          id={product.id}
                          guid={product.guid}
                        />
                      );
                    default:
                      return (
                        <CompareTemplate
                          templateType={getCurrentCategory(product.categories)}
                          product={product}
                          property={param}
                        />
                      );
                  }
                })()}
              </Item>
            );
          })}
        </ul>
      </div>
      <AddDiamondPopup
        product={product as RingImpl}
        visiblePopup={visibleDiamondPopup}
        extraSettings={settings}
      />
      <AddRingPopup
        product={product as StoneImpl}
        visiblePopup={visibleAddRingPopup}
        addToCart={addToCart}
      />{' '}
    </>
  );
});

export default CompareItem;
