import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PaginationItem from './PaginationItem';
import PaginateStore from 'root/store/paginate';

interface IPaginationComponentProps {
  pagination: PaginateStore;
  handlePagination: (page: number) => void;
}

@observer
class PaginationComponent extends Component<IPaginationComponentProps> {
  generatePagination(c: number, m: number): JSX.Element[] {
    const current = c;
    const last = m;
    const delta = 2;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(
            <PaginationItem
              key={l + 1}
              active={current === l + 1}
              onClick={this.handlePage(l + 1)}
            >
              {l + 1}
            </PaginationItem>,
          );
        } else if (i - l !== 1) {
          rangeWithDots.push(
            <PaginationItem
              key={`ellipsis${i}`}
              ellipsis={true}
              onClick={this.handleEllipsis(c, m, !(current < i))}
            />,
          );
        }
      }
      rangeWithDots.push(
        <PaginationItem
          key={i}
          active={current === i}
          onClick={this.handlePage(i)}
        >
          {i}
        </PaginationItem>,
      );
      l = i;
    }

    return rangeWithDots;
  }

  handlePage =
    (i: number) =>
    (event: React.MouseEvent<HTMLLIElement>): void => {
      event.preventDefault();

      this.props.handlePagination(i);
    };

  handleEllipsis =
    (c: number, m: number, isLeft: boolean) =>
    (event: React.MouseEvent<HTMLLIElement>): void => {
      event.preventDefault();

      const delta = 2;
      const left = c - delta;
      const right = c + delta + 1;

      const page = Math.ceil(isLeft ? (1 + left) / 2 : (m + right) / 2);

      this.props.handlePagination(page);
    };

  onStep =
    (currentPage: number) =>
    (event: React.MouseEvent<HTMLSpanElement>): void => {
      event.preventDefault();

      const { totalPages } = this.props.pagination;

      if (currentPage > 0 && currentPage <= totalPages) {
        this.props.handlePagination(currentPage);
      }
    };

  render(): JSX.Element {
    const { page, totalPages } = this.props.pagination;

    return (
      <ul className="pagination pagination_my-30 pagination_mt-30">
        <span className="arrow" onClick={this.onStep(page - 1)}>
          ‹
        </span>
        {this.generatePagination(page, totalPages)}
        <span className="arrow" onClick={this.onStep(page + 1)}>
          ›
        </span>
      </ul>
    );
  }
}

export default PaginationComponent;
