import React from 'react';
import Svg from '../Svg';

interface ITruckIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function TruckIcon(props: ITruckIconProps): JSX.Element {
  return (
    <Svg width="18" height="15" viewBox="0 0 18 15" fill="none" {...props}>
      <path
        d="M17.5625 10.8125H17.125V7.64062C17.125 7.12109 16.9062 6.62891 16.5234 6.24609L14.2539 3.97656C13.8711 3.59375 13.3789 3.375 12.8594 3.375H11.875V2.28125C11.875 1.46094 11.1641 0.75 10.3438 0.75H2.03125C1.18359 0.75 0.5 1.46094 0.5 2.28125V10.5938C0.5 11.4414 1.18359 12.125 2.03125 12.125H2.25C2.25 13.5742 3.42578 14.75 4.875 14.75C6.32422 14.75 7.5 13.5742 7.5 12.125H11C11 13.5742 12.1758 14.75 13.625 14.75C15.0742 14.75 16.25 13.5742 16.25 12.125H17.5625C17.7812 12.125 18 11.9336 18 11.6875V11.25C18 11.0312 17.7812 10.8125 17.5625 10.8125ZM4.875 13.4375C4.13672 13.4375 3.5625 12.8633 3.5625 12.125C3.5625 11.4141 4.13672 10.8125 4.875 10.8125C5.58594 10.8125 6.1875 11.4141 6.1875 12.125C6.1875 12.8633 5.58594 13.4375 4.875 13.4375ZM10.5625 10.8125H7.11719C6.67969 10.0469 5.83203 9.5 4.875 9.5C3.89062 9.5 3.04297 10.0469 2.60547 10.8125H2.03125C1.89453 10.8125 1.8125 10.7305 1.8125 10.5938V2.28125C1.8125 2.17188 1.89453 2.0625 2.03125 2.0625H10.3438C10.4531 2.0625 10.5625 2.17188 10.5625 2.28125V10.8125ZM11.875 4.6875H12.8594C13.0234 4.6875 13.1875 4.76953 13.3242 4.87891L15.3203 6.875H11.875V4.6875ZM13.625 13.4375C12.8867 13.4375 12.3125 12.8633 12.3125 12.125C12.3125 11.4141 12.8867 10.8125 13.625 10.8125C14.3359 10.8125 14.9375 11.4141 14.9375 12.125C14.9375 12.8633 14.3359 13.4375 13.625 13.4375ZM15.8125 10.7031C15.3203 9.99219 14.5273 9.5 13.625 9.5C12.9414 9.5 12.3398 9.77344 11.875 10.1836V8.1875H15.8125V10.7031Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
