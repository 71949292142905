import { action, computed, IObservableArray, observable } from 'mobx';
import { getCountries } from 'service/api/app';

export interface StateImpl {
  id: number;
  iso: string;
  name: string;
  [key: string]: number | string;
}

export interface CountryImpl {
  id: number;
  iso2: string;
  iso3: string;
  name: string;
  states: StateImpl[];
  tax: number;
  [key: string]: number | string | StateImpl[];
}

export default class AppStore {
  private _countries = observable.array<CountryImpl>([], { deep: false });
  isReturnedToMain = observable.box(false);

  constructor() {
    if (sessionStorage.getItem('countries')) {
      this._countries.replace(
        JSON.parse(sessionStorage.getItem('countries') as string),
      );
    } else {
      this.fetchCountries(); // pre-load countries
    }
    window.addEventListener('popstate', () => {
      if (window.location.pathname === '/') {
        this.isReturnedToMain.set(true);
        setTimeout(() => {
          this.isReturnedToMain.set(false);
        }, 0);
      }
    });
  }

  @computed
  get countries(): IObservableArray<CountryImpl> {
    return this._countries;
  }

  @action
  fetchCountries(): void {
    void getCountries().then((result: CountryImpl[]) => {
      this._countries.replace(result);
      sessionStorage.setItem('countries', JSON.stringify(result));
    });
  }
}
