import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { logout } from 'service/api/account';
import rootStore from 'root/store';
import { joinClasses } from 'lib/utils';
import Icon from 'components/common/Icon';
import SidebarStore, { ISidebarStore } from './store';

import './Sidebar.scss';

const {
  account: { user: userStore },
  cart,
} = rootStore;

@observer
class Sidebar extends Component<RouteComponentProps> {
  sidebar: ISidebarStore;

  constructor(props: RouteComponentProps) {
    super(props);
    this.sidebar = new SidebarStore();
  }

  handleClick = (): void => {
    this.sidebar.toggle();
  };

  handleLogout = async (): Promise<void> => {
    await logout();
    userStore.handleUserAuth(false);
    cart.clear();

    this.props.history.push('/auth/signin');
  };

  render() {
    const { active } = this.sidebar;
    const { location } = this.props;

    return (
      <aside className={joinClasses(['sidebar', active && 'sidebar_active'])}>
        <div
          className="btn btn_bordered-dark-gray btn_circle btn_sidebar-handler"
          onClick={this.handleClick}
        >
          <Icon id="arrow_left" />
        </div>

        <div className="sidebar-nav-wrap">
          <nav className="sidebar-nav">
            <NavLink
              to="/account/profile"
              className={joinClasses([
                'sidebar-nav-link',
                location.pathname === '/account' && 'active',
              ])}
            >
              <Icon id="user" className="sidebar-nav-link__icon" />
              <span className="sidebar-nav-link__text">PROFILE</span>
            </NavLink>

            <NavLink
              to="/account/orders/current"
              className={joinClasses([
                'sidebar-nav-link',
                location.pathname === '/account/orders' && 'active',
              ])}
            >
              <Icon id="current_orders" className="sidebar-nav-link__icon" />
              <span className="sidebar-nav-link__text">CURRENT ORDERS</span>
            </NavLink>

            <NavLink to="/account/orders/history" className="sidebar-nav-link">
              <Icon id="history" className="sidebar-nav-link__icon" />
              <span className="sidebar-nav-link__text">ORDER HISTORY</span>
            </NavLink>

            <NavLink to="/account/favorites" className="sidebar-nav-link">
              <Icon id="favorite" className="sidebar-nav-link__icon" />
              <span className="sidebar-nav-link__text">FAVORITES</span>
            </NavLink>

            <NavLink
              to="/account/settings"
              className={joinClasses([
                'sidebar-nav-link',
                location.pathname === '/account/settings' && 'active',
              ])}
            >
              <Icon id="settings" className="sidebar-nav-link__icon" />
              <span className="sidebar-nav-link__text">SETTINGS</span>
            </NavLink>

            <div className="sidebar-nav-btn-wrap">
              <a
                className="sidebar-nav-link"
                href="#"
                onClick={this.handleLogout}
              >
                <Icon id="sign_out" className="sidebar-nav-link__icon" />
              </a>

              <a
                className="btn btn_xs btn_text-bold btn_bordered-default sidebar-btn_bordered"
                onClick={this.handleLogout}
                title="Log Out"
              >
                <Icon id="sign_out" className="icon_mr-sm" />
                Log Out
              </a>
            </div>
          </nav>
        </div>
      </aside>
    );
  }
}

export default withRouter(Sidebar);
