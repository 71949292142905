export const ORDER_CURRENT_TYPE = 'current';
export const ORDER_SPECIAL_TYPE = 'special';
export const ORDER_HISTORY_TYPE = 'history';

export const ORDERS_STATUSES = [
  { name: 'New', value: 'NEW' },
  { name: 'In progress', value: 'IN_PROGRESS' },
  { name: 'Confirmed', value: 'CONFIRMED' },
  { name: 'Unconfirmed', value: 'UNCONFIRMED' },
  { name: 'Ready to Delivery', value: 'READY_TO_DELIVERY' },
  { name: 'Delivering', value: 'DELIVERING' },
  { name: 'Delivered', value: 'DELIVERED' },
  { name: 'On Hold', value: 'ON_HOLD' },
  { name: 'All' },
];

export const HISTORY_ORDERS_STATUSES = [
  { name: 'Cancelled', value: 'CANCELLED' },
  { name: 'Closed', value: 'CLOSED' },
  { name: 'All' },
];

export const DATE_AVAILABLE_VALUES = [
  { name: '24h', value: { type: 'day', value: 1 } },
  { name: '3 days', value: { type: 'day', value: 3 } },
  { name: '1 week', value: { type: 'day', value: 7 } },
  { name: '2 weeks', value: { type: 'day', value: 14 } },
  { name: '1 month', value: { type: 'month', value: 1 } },
  { name: 'All' },
];

export const SORT_VALUES = [
  { name: 'Date', value: 'date_order' },
  { name: 'Order Number', value: 'id' },
  { name: 'Order Status', value: 'total' },
  { name: 'All' },
];
