import EmeraldMeasLengthIcon from './EmeraldMeasLengthIcon';
import EmeraldMeasWidthIcon from './EmeraldMeasWidthIcon';
import EmeraldMeasDepthIcon from './EmeraldMeasDepthIcon';
import EmeraldDepthIcon from './EmeraldDepthIcon';
import EmeraldTableIcon from './EmeraldTableIcon';
import EmeraldIcon from './EmeraldIcon';

export default {
  emerald_measLength: EmeraldMeasLengthIcon,
  emerald_measWidth: EmeraldMeasWidthIcon,
  emerald_measDepth: EmeraldMeasDepthIcon,
  emerald_depth: EmeraldDepthIcon,
  emerald_table: EmeraldTableIcon,
  emerald: EmeraldIcon,
};
