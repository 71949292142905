import { IObservableArray, makeAutoObservable, observable } from 'mobx';

export interface ServiceRequestStoreImpl {
  error: boolean;
  loading: boolean;
  result: IObservableArray<PromiseSettledResult<void>>;
  serviceRequestHandler: (request: Array<Promise<void>>) => void;
}

export class ServiceRequestStore implements ServiceRequestStoreImpl {
  error = false;
  loading = true;
  result: IObservableArray<PromiseSettledResult<void>> = observable.array([]);

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  setError = (value: boolean): void => {
    this.error = value;
  };

  setResult = (value: PromiseSettledResult<void>[]): void => {
    this.result.replace(value);
  };

  async serviceRequestHandler(request: Array<Promise<void>>): Promise<void> {
    this.setLoading(true);
    try {
      const results = await Promise.allSettled(request);
      results.forEach((result) => {
        if (result.status === 'rejected') {
          this.setError(true);
        }
      });
      this.setResult(results);
      this.setLoading(false);
    } catch (e) {
      this.setError(true);
      this.setLoading(false);
    }
  }
}
