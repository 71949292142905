import { makeAutoObservable } from 'mobx';
import { IFilterExtraRingsResponse } from 'service/api/apiTypes/catalogsApiTypes';
import { fetchRingsFilterParams } from 'service/api/rings';
import {
  IFilterRingsExtraParams,
  IFilterRingsExtraParamsRequest,
} from '../types';

export const EXTRA_PARAMS: IFilterRingsExtraParamsRequest = {
  filters: {
    price: {},
  },
};

export interface IRingFiltersExtra {
  filtersExtra: IFilterRingsExtraParams;
  fetchFilterExtraParams: () => Generator<
    Promise<IFilterExtraRingsResponse>,
    IFilterRingsExtraParams,
    IFilterExtraRingsResponse
  >;
}

export default class RingFiltersExtra implements IRingFiltersExtra {
  filtersExtra: IFilterRingsExtraParams = {};

  constructor() {
    makeAutoObservable(this);
  }

  *fetchFilterExtraParams(): Generator<
    Promise<IFilterExtraRingsResponse>,
    IFilterRingsExtraParams,
    IFilterExtraRingsResponse
  > {
    const { filters } = yield fetchRingsFilterParams(EXTRA_PARAMS);

    this.filtersExtra = (filters as IFilterRingsExtraParams) || {};

    return this.filtersExtra;
  }
}
