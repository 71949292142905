import React from 'react';
import Svg from '../../Svg';

interface IOvalMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function OvalMeasLengthIcon(
  props: IOvalMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M100.44 163.25c24.35 0 44.09-28.318 44.09-63.25s-19.74-63.25-44.09-63.25c-24.35 0-44.09 28.318-44.09 63.25s19.74 63.25 44.09 63.25z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.74 36.75 87.97 54.39 68.6 56.24l.52 26.73L56.35 100l12.77 15.81-1.23 26.87 19.47 1.72 13.38 18.85"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m100.14 36.75 12.77 17.64 19.37 1.85-.52 26.73L144.53 100l-12.77 15.81 1.22 26.87-19.46 1.72-13.38 18.85M82.67 42.1l5.3 12.29-5.47 20.07-13.38 8.51 6.69 16.42-6.69 16.42 13.38 7.91 4.86 20.68-5.65 12.88"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m118.21 42.1-5.3 12.29 5.47 20.07 13.38 8.51-6.69 16.42 6.69 16.42-13.38 7.91-4.86 20.68 5.65 12.88M100.74 63.51 82.8 74.76 76.11 99.7l6.69 24.32 17.64 11.56M58.8 79.14l10.32 3.83M58.8 120.86l10.32-5.05M142.1 79.14l-10.32 3.83M142.1 120.86l-10.32-5.05"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m87.97 54.39 12.77 9.12 17.34 11.25 6.69 24.94-6.69 24.32-17.64 11.56M112.91 54.39l-12.17 9.12M113.52 144.4l-12.78-8.52-.46-.3-12.92 8.82"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.32 36.75h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.85 36.75h53.04"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.03 3.03"
      />
      <path
        d="M159.41 36.75h1.5M100.32 163.25h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.85 163.25h53.04"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.03 3.03"
      />
      <path
        d="M159.41 163.25h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.91 36.75v126.5"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
