import React from 'react';
import Svg from '../../Svg';

interface IPearMeasWidthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PearMeasWidthIcon(
  props: IPearMeasWidthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M143.2 79.74c-1.36-39.3-32.6-44.94-43.88-44.94-11.28 0-42.52 5.64-43.88 44.94-1.35 42.44 43.88 85.25 43.88 85.25s45.23-42.81 43.88-85.25z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.32 164.99 64.69 50.91l25.2 2.14 9.43-18.25M99.32 164.99l34.63-114.08-25.2 2.14-9.43-18.25"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m78.66 39.65 11.23 13.4L82 67.26l-11.31 3.43-15.25 9.05 43.88 50.069-15.9 17.3M119.98 39.65l-11.23 13.4 7.88 14.21 11.31 3.43 15.26 9.05-43.88 50.069 15.9 17.3"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m89.89 53.05 9.43 7.14L82 67.26l-9.83 31.57-12.69 6.05M108.75 53.05l-9.43 7.14 17.31 7.07 9.83 31.57 12.7 6.05"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m72.17 98.83-2.77 27.7 21.89 12.01M126.37 98.83l2.77 27.7-21.89 12.01"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.22 79.09v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.22 74.68V23.8"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M54.22 22.34v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.22 19.8h89.98"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.2 79.09v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.2 74.68V23.8"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M144.2 22.34v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
