import React from 'react';
import Svg from '../Svg';

interface ILogoIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function LogoIcon(props: ILogoIconProps): JSX.Element {
  return (
    <Svg width="133" height="40" viewBox="0 0 133 40" fill="none" {...props}>
      <g clipPath="url(#a)" fill="#4D4048">
        <path d="M13.072 4c.742 0 1.476.15 2.159.44.334.144.666.294.983.467a10.254 10.254 0 0 1 4.872 6.194c.895 2.84 1.54 5.757 2.297 8.64.541 2.056 1.07 4.115 1.654 6.16.086.304.414.728.666.753a1.293 1.293 0 0 0 .892-.541c.356-.46.6-1.005 1.017-1.469l-4.233 11.633c-.111-.104-.248-.174-.293-.282a131.285 131.285 0 0 1-5.016-14.029c-1.023-3.498-2.047-7.006-3.306-10.426A12.995 12.995 0 0 0 8.7 4.728C8.132 4.4 7.047 4.098 6.969 4h6.103Z" />
        <path d="M5.332 4.65c2.485.573 3.425 1.898 4.652 3.542a42.984 42.984 0 0 1 2.2 3.243 15.472 15.472 0 0 1-5.576-2.132 33.993 33.993 0 0 1-3.26-2.852 29.238 29.238 0 0 0-3.37-2.218 32.697 32.697 0 0 1 5.354.416ZM97.594 20.69a8.161 8.161 0 0 1-16.32.11 8.267 8.267 0 0 1 8.423-8.277c3.955.1 8 3.519 7.897 8.167Zm-8.175-6.491a6.476 6.476 0 1 0-.015 12.952 6.476 6.476 0 0 0 .015-12.952ZM58.69 15.601v12.684h-1.034V12.23L68.14 26.087l.151-.071V13.022h1V29.31L58.854 15.498l-.165.103ZM115.625 22.034l4.809 6.239a1.498 1.498 0 0 1-1.816-.594c-1.219-1.71-2.534-3.35-3.825-5.008a.833.833 0 0 0-.53-.328c-1.173-.03-2.349-.015-3.609-.015v5.983h-1.016V13.15c1.861 0 3.77-.261 5.569.072a4.142 4.142 0 0 1 3.375 3.936c.224 2.277-.764 3.802-2.957 4.875Zm-4.943-8.159v7.413c1.259 0 2.487.078 3.7-.018a3.552 3.552 0 0 0 3.172-3.43 3.403 3.403 0 0 0-2.676-3.648 38.258 38.258 0 0 0-4.196-.317ZM37.305 28.296V13.055h8.738v.866h-7.628v4.89h5.105l.056.933H38.42V27.3h8.243l.045.995h-9.403ZM133.023 13.052v15.253h-1.062V13.052h1.062ZM29.725 4c4.794.03 3.648 3.104 3.502 3.668-.216.833-2.453 8.53-2.453 8.53s3.07-7.757 3.337-8.423c.267-.666 1.205-3.847 4.74-3.758L38.953 4h-9.23Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h133v40H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
}
