import React from 'react';
import Svg from '../Svg';

interface IPhoneIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PhoneIcon(props: IPhoneIconProps): JSX.Element {
  return (
    <Svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M15.875 1.71875L12.9375 1.0625C12.25 0.90625 11.5625 1.25 11.3125 1.875L9.9375 5.0625C9.6875 5.625 9.84375 6.3125 10.3438 6.71875L11.5938 7.75C10.7188 9.375 9.34375 10.7188 7.71875 11.625L6.6875 10.375C6.28125 9.875 5.59375 9.71875 5.03125 9.96875L1.84375 11.3125C1.21875 11.5938 0.875 12.2812 1.03125 12.9688L1.6875 15.9062C1.84375 16.5625 2.4375 17 3.09375 17C10.75 17 17 10.8125 17 3.125C17 2.46875 16.5312 1.875 15.875 1.71875ZM3.15625 15.5L2.5 12.6875L5.5625 11.375L7.3125 13.5C10.4062 12.0312 12 10.4375 13.4688 7.34375L11.3438 5.59375L12.6562 2.53125L15.5 3.1875C15.4688 9.96875 9.9375 15.4688 3.15625 15.5Z"
        fill="#928A8E"
      />
    </Svg>
  );
}
