import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { ProductCategories } from 'root/ProductTypes';
import { ItemsBlockImpl } from 'service/api/apiTypes/catalogsApiTypes';
import { getCurrentCategory, joinClasses } from 'lib/utils';
import { addDaysToADate } from 'utils';
import { monthNames } from 'utils/constants';
import Icon from 'components/common/Icon';
import TooltipWithIcon from 'components/common/TooltipWithIcon';
import { ProductDetailPageImpl } from '../../ProductDetailPage.types';
import CanBeSetWithBlock from '../CanBeSetWithBlock';
import DetailItems from '../DetailItems/DetailItems';
import EngraveBlock from '../EngraveBlock';
import { useHistory } from 'react-router';
import hintStore from 'components/HintForProductSet/HintForProductSet.store';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import DeleteDiamondPopup from '../DeleteDiamondPopup/DeleteDiamondPopup';

export interface IProps {
  productStore: ProductDetailPageImpl;
  handleDetailsOn: (e: React.MouseEvent<HTMLElement>) => void;
  handleDetailsLeave: (e: React.MouseEvent<HTMLElement>) => void;
  constantTypes: {
    [key: string]: {
      tooltip: string | boolean;
      isNeedDetail: boolean;
    };
  };
  productTitle: string;
  isWithSetHint: boolean;
}

const PRODUCT_TYPE_TITLE_LIST: { [item: string]: string } = {
  [ProductCategories.ENGAGEMENT_RINGS]: 'Ring',
  [ProductCategories.WEDDING_RINGS]: 'Ring',
  [ProductCategories.RINGS]: 'Ring',
  [ProductCategories.NECKLACES]: 'Necklace',
  [ProductCategories.EARRINGS]: 'Earrings',
  [ProductCategories.PENDANTS]: 'Pendant',
  [ProductCategories.BRACELETS]: 'Bracelet',
  [ProductCategories.DIAMONDS]: 'Diamond',
  [ProductCategories.DIAMONDS_LAB_CREATED]: 'Diamond',
};

export function ProductDetails({
  productStore,
  productStore: { product, detailBlock },
  handleDetailsOn,
  handleDetailsLeave,
  constantTypes,
  productTitle,
  isWithSetHint,
}: IProps): JSX.Element | null {
  const history = useHistory();

  const tooltipRef = useRef(null);
  const tooltipTitleRef = useRef(null);
  const [isShippingTooltipOpen, setIsShippingTooltipOpen] = useState(false);
  const [isDeleteDiamondPopupOpen, setIsDeleteDiamondPopupOpen] =
    useState(false);

  const currentCategory = getCurrentCategory(product.categories);
  const isDiamondsCategory = currentCategory.includes(
    ProductCategories.DIAMONDS,
  );

  const isProductCustomization = detailBlock?.some(
    ({ customization }) => !!customization?.length,
  );

  const detailsTitle =
    currentCategory.find((category) => PRODUCT_TYPE_TITLE_LIST[category]) || '';

  const shipDate = addDaysToADate(new Date(), isDiamondsCategory ? 7 : 14);

  const handleShippingTooltipOpen = (isOpen: boolean) => {
    const tooltip = tooltipRef.current as unknown as Element;
    const title = tooltipTitleRef.current as unknown as Element;
    const footer = document.getElementsByClassName('footer-wrapper')[0];
    if (
      tooltip?.getClientRects()[0].top < 50 &&
      footer?.getClientRects()[0].top - title?.getClientRects()[0].top >
        tooltip.clientHeight
    ) {
      tooltip?.classList.add('bottom');
    } else {
      tooltip?.classList.remove('bottom');
    }
    setIsShippingTooltipOpen(isOpen);
  };

  const handleRemoveProductForSet = () => {
    history.replace({ search: '' });
    productStore.removeStoneForSet();
    setIsDeleteDiamondPopupOpen(false);
  };

  if (!product) return null;

  return (
    <div
      className={joinClasses([
        'product-detail-page__product-details',
        isWithSetHint && 'product-detail-page__product-details_with-set-hint',
      ])}
    >
      <div className="product-detail-page__details-wrap block-scrollbar">
        <h2 className="product-detail-page__details-title uppercase decoration-line details-title_display-none-mobile">
          <Icon id="gem_solid" />
          {isProductCustomization
            ? `design your own ${PRODUCT_TYPE_TITLE_LIST[detailsTitle]}`
            : `${PRODUCT_TYPE_TITLE_LIST[detailsTitle]} details`}
        </h2>

        <ul className="product-detail-page__details-list">
          {detailBlock?.map((item: ItemsBlockImpl, index) => {
            return (
              <DetailItems
                key={index}
                sizeValue={hintStore.customizations?.size || null}
                productStore={productStore}
                handleDetailsOn={handleDetailsOn}
                handleDetailsLeave={handleDetailsLeave}
                constantTypes={constantTypes}
                item={item}
              />
            );
          })}
        </ul>
        <div
          className={
            productStore.isViewFromCart ? 'disable_detail_actions' : ''
          }
        >
          {hintStore.level !== LevelPropductForSet.Child &&
            productStore?.settings?.data &&
            productStore?.settings?.data?.length > 0 && (
              <CanBeSetWithBlock
                setItem={productStore.setWithItem}
                extraSettings={productStore.settings}
                product={productStore.product as unknown as any}
                customizations={productStore.customizations}
                choosedPrice={productStore.choosedPrice}
                handleRemoveProductForSet={() =>
                  setIsDeleteDiamondPopupOpen(true)
                }
                disableAction={productStore.isViewFromCart}
              />
            )}

          {['jewelry', 'rings'].includes(productTitle) &&
            productStore.engraving && (
              <EngraveBlock
                disableAction={productStore.isViewFromCart}
                engravingValues={
                  hintStore.customizations?.engrave ||
                  productStore.customizations?.engrave
                }
                engraving={productStore.engraving}
                productStore={productStore}
              />
            )}

          <h2 className="product-detail-page__details-title uppercase decoration-line">
            <Icon id="truck" />
            DELIVERY
          </h2>

          <div
            className="product-detail-page__delivery-conditions"
            onMouseEnter={() => handleShippingTooltipOpen(true)}
            onMouseLeave={() => handleShippingTooltipOpen(false)}
          >
            <div
              className="product-detail-page__delivery-conditions-title decoration-underline"
              ref={tooltipTitleRef}
            >
              <Icon id="info" className="product-detail-page__delivery-icon" />{' '}
              Free Over Night Shipping
              <TooltipWithIcon
                withoutIcon
                modifiers="product-detail-page__delivery-tooltip"
                forwardRef={tooltipRef}
                isHidden={!isShippingTooltipOpen}
              >
                <div>
                  <p className="product-detail-page__delivery-conditions-paragraph">
                    All loose stones will be shipped out with in 5-7 business
                    days of placing the order. All of our jewelry is
                    hand-crafted and carefully inspected before it gets to you
                    so please allow 1- 2 weeks for your order to ship. Some
                    finger sizes or stone sizes may prolong shipping. All orders
                    are shipped via FEDEX Overnight.
                  </p>
                  <p className="product-detail-page__delivery-conditions-paragraph">
                    <strong>Please note:</strong> If you need your order rushed,
                    please call us at 877-382-6275. We will do our absolute best
                    to accommodate you.
                  </p>
                  <p className="product-detail-page__delivery-conditions-paragraph">
                    <strong>Estimated Ship Date:</strong>{' '}
                    {monthNames[shipDate.getMonth()]} {shipDate.getDate()}
                    {', '}
                    {shipDate.getFullYear()}
                  </p>
                </div>
              </TooltipWithIcon>
            </div>
          </div>
        </div>
      </div>

      <DeleteDiamondPopup
        isVisible={isDeleteDiamondPopupOpen}
        setVisible={setIsDeleteDiamondPopupOpen}
        setItem={productStore.setWithItem}
        removeProductForSet={handleRemoveProductForSet}
      />
    </div>
  );
}

export default observer(ProductDetails);
