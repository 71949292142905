import * as React from 'react';
import { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import UncaughtErrorPage from '../../pages/UncaughtErrorPage';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError = (): { hasError: boolean } => ({
    // Update state so the next render will show the fallback UI.
    hasError: true,
  });

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.info('Uncaught error:', error, errorInfo);
    if (process.env.SENTRY_LOG === 'on') {
      Sentry.captureException(error);
    }
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <UncaughtErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
