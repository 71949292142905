import React from 'react';
import Svg from '../Svg';

interface IChatIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function ChatIcon(props: IChatIconProps): JSX.Element {
  return (
    <Svg width="17" height="14" viewBox="0 0 17 14" fill="none" {...props}>
      <path
        d="M12.5 4.25H5.5C5.21875 4.25 5 4.5 5 4.75V5.25C5 5.53125 5.21875 5.75 5.5 5.75H12.5C12.75 5.75 13 5.53125 13 5.25V4.75C13 4.5 12.75 4.25 12.5 4.25ZM9.5 7.25H5.5C5.21875 7.25 5 7.5 5 7.75V8.25C5 8.53125 5.21875 8.75 5.5 8.75H9.5C9.75 8.75 10 8.53125 10 8.25V7.75C10 7.5 9.75 7.25 9.5 7.25ZM9 0C4.5625 0 1 2.9375 1 6.5C1 8 1.59375 9.375 2.625 10.4688C2.1875 11.6875 1.21875 12.75 1.1875 12.75C0.96875 12.9688 0.9375 13.2812 1.03125 13.5625C1.15625 13.8438 1.4375 14 1.75 14C3.65625 14 5.1875 13.2188 6.09375 12.5625C7 12.8438 7.96875 13 9 13C13.4062 13 17 10.0938 17 6.5C17 2.9375 13.4062 0 9 0ZM9 11.5C8.15625 11.5 7.3125 11.375 6.53125 11.125L5.8125 10.9062L5.21875 11.3438C4.78125 11.6562 4.15625 12 3.40625 12.25C3.65625 11.875 3.875 11.4375 4.03125 11L4.375 10.125L3.71875 9.4375C3.15625 8.84375 2.5 7.84375 2.5 6.5C2.5 3.75 5.40625 1.5 9 1.5C12.5625 1.5 15.5 3.75 15.5 6.5C15.5 9.28125 12.5625 11.5 9 11.5Z"
        fill="#928A8E"
      />
    </Svg>
  );
}
