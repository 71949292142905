import React from 'react';
import Svg from '../Svg';

interface ISignOutIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function SignOutIcon(props: ISignOutIconProps): JSX.Element {
  return (
    <Svg width="18" height="15" viewBox="0 0 18 15" fill="none" {...props}>
      <path
        d="M17.473 7.848c.316-.317.316-.844 0-1.196L11.566.746c-.562-.527-1.441-.14-1.441.598v3.375H5.344a.833.833 0 0 0-.844.843v3.375c0 .493.352.844.844.844h4.781v3.375c0 .774.914 1.125 1.441.598l5.907-5.906ZM6.75 13.578v-1.406a.454.454 0 0 0-.422-.422H3.375a1.11 1.11 0 0 1-1.125-1.125v-6.75c0-.598.492-1.125 1.125-1.125h2.953c.211 0 .422-.176.422-.422V.922C6.75.71 6.54.5 6.328.5H3.375A3.376 3.376 0 0 0 0 3.875v6.75A3.376 3.376 0 0 0 3.375 14h2.953c.211 0 .422-.176.422-.422Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
