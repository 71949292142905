import axios from './';
import { CountryImpl } from 'root/store/app';
import { AxiosPromise } from 'axios';
import { TGiftPromoCodeData } from '../../pages/MainPage/components/Start/store';

/**
 * API path
 * @type {string}
 */
const path = '/app';

/**
 * Get list of countries
 * @returns {AxiosPromise}
 */
export const getCountries = (): Promise<CountryImpl[]> =>
  axios.get<void, CountryImpl[]>(`${path}/countries`);

/**
 * Contact us
 * @params {Object} data
 * @returns {AxiosPromise}
 */
export const contactUs = (data: unknown): AxiosPromise =>
  axios.post(`${path}/contact-us`, data);

/**
 * Get promo-action param
 * @returns {AxiosPromise}
 */
export const getPromoActionParam = (): Promise<TGiftPromoCodeData> =>
  axios.get(`${path}/gift-promocode`);

/**
 * Send promo-code to email address
 * @params {Object} data
 * @returns {AxiosPromise}
 */
export const sendPromoCodeToEmail = (email: string): AxiosPromise =>
  axios.post(`${path}/gift-promocode`, { email });

/**
 * sendHint
 * @params {Object} data
 * @returns {AxiosPromise}
 */
export const sendHint = (data: unknown): AxiosPromise =>
  axios.post(`${path}/hint`, data);
