import { joinClasses } from 'lib/utils';
import * as React from 'react';
import Icon from '../Icon';
import { ReactNode } from 'react';

interface ModalImpl {
  visible: boolean;
  smallWindow?: boolean;
  closeModal?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => boolean | void;
  modalSize?: string;
  className?: string;
  footerText?: string;
  children: ReactNode;
}

export default class Modal extends React.PureComponent<ModalImpl> {
  closeModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): boolean | void =>
    typeof this.props.closeModal === 'function' && this.props.closeModal(e);

  componentWillUnmount(): void {
    document.body.classList.contains('prevent-scroll') &&
      document.body.classList.remove('prevent-scroll');
  }

  render(): JSX.Element {
    const { children, visible, smallWindow, footerText } = this.props;
    visible
      ? document.body.classList.add('prevent-scroll')
      : document.body.classList.remove('prevent-scroll');

    return (
      <div
        className={`modal ${!visible ? '' : 'open'} `}
        onClick={this.closeModal}
      >
        <div
          className={joinClasses([
            'modal-window',
            smallWindow && 'small-window',
            this.props.modalSize && 'modal-window_' + this.props.modalSize,
            this.props.className,
          ])}
        >
          {visible ? children : null}

          {footerText && (
            <div className="modal_bottom">
              <Icon id="circle_info" className="icon" />
              <span>{footerText}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
