import React, { Component, FormEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { action, observable, runInAction } from 'mobx';
import { IObservableValue } from 'mobx/dist/internal';
import { observer } from 'mobx-react';
import {
  FormControl,
  FormGroup,
  maxLengthValidator,
  minLengthValidator,
  patternValidator,
  requiredValidator,
  wrapperSequentialCheck,
  ValidationEventTypes,
  InputFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { signUp } from 'service/api/auth';
import { joinClasses } from 'lib/utils';
import { validFormControlPasswordConfirm } from 'lib/validations';
import ErrorAlert from 'components/ErrorAlert';
import InputForm from 'components/common/InputForm';
import type { ErrorImpl } from '../../LoginContainer.types';
import { SignUpFormImpl, SignUpPropsImpl } from './SignUp.types';
import Icon from 'components/common/Icon';

@observer
class SignUp extends Component<SignUpPropsImpl> {
  visible = observable.box(false);
  disable = observable.box(false);
  status = observable.box(''); // SUCCESS, PENDING, FAILED
  inputPasswordType = observable.box('password');
  inputPasswordConfirmType = observable.box('password');
  formStatus: IObservableValue<boolean> = observable.box(
    this.props.location.state ? this.props.location.state.formStatus : false,
  );

  showError = observable.box(false);
  @observable error: ErrorImpl = {
    status: 0,
    title: '',
    message: [],
    emptyErrors: [],
  };

  @observable form: FormGroup<SignUpFormImpl> = new FormGroup<SignUpFormImpl>({
    firstName: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('FirstName is required'),
          patternValidator(
            /^[a-zA-Z]+$/,
            'First Name must contain only latin letters',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    lastName: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('LastName is required'),
          patternValidator(
            /^[a-zA-Z]+$/,
            'Last Name must contain only latin letters',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    email: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Email is required'),
          patternValidator(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Wrong Email format',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    password: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Password is required'),
          minLengthValidator(6, 'Field minimum length is 6 chars'),
          patternValidator(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,255}$/,
            'Password must contain one uppercase, one lowercase, one number and spec chars',
          ),
          maxLengthValidator(
            255,
            'Field maximum length is 255 chars ',
            ValidationEventTypes.Error,
          ),
        ]),
      ],
    }),
    passwordConfirm: new FormControl<string>('', {
      validators: [
        requiredValidator('Confirm Password is required'),
        wrapperSequentialCheck([
          async (state) =>
            validFormControlPasswordConfirm(
              state,
              this.form?.controls.password,
            ),
        ]),
      ],
    }),
  });

  @action
  changeStatus(status: string): void {
    this.status.set(status);
  }

  @action
  disableForm(isDisable: boolean): void {
    this.disable.set(isDisable);
  }

  @action
  visibleForgotPasswordModal(visible: boolean): void {
    this.visible.set(visible);
  }

  getFormDataValue = (): { [k: string]: string | undefined } => {
    const { firstName, lastName, email, password, passwordConfirm } =
      this.form.controls;
    return {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      passwordConfirm: passwordConfirm.value,
    };
  };

  handleChangeInputPasswordType = (): void => {
    this.inputPasswordType.get() === 'password'
      ? this.inputPasswordType.set('text')
      : this.inputPasswordType.set('password');
  };

  handleChangeInputPasswordConfirmType = (): void => {
    this.inputPasswordConfirmType.get() === 'password'
      ? this.inputPasswordConfirmType.set('text')
      : this.inputPasswordConfirmType.set('password');
  };

  handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const controls = this.form.controls;
    const isValidForm = Object.values(controls).every(
      (inputForm) => !inputForm.invalid,
    );

    const data = this.getFormDataValue();

    if (isValidForm) {
      this.disableForm(true);
      this.changeStatus('PENDING');
      try {
        await signUp(data);
        this.disableForm(false);
        this.changeStatus('SUCCESS');
      } catch (err) {
        runInAction(() => {
          this.error = { ...this.error, ...(err as ErrorImpl) };
          this.showError.set(true);
        });
        this.disableForm(false);
        this.changeStatus('FAILED');
      }
    }
  };

  render(): JSX.Element {
    const { firstName, lastName, email, password, passwordConfirm } =
      this.form.controls;

    return (
      <>
        {this.status.get() === 'SUCCESS' && (
          <Redirect
            to={{
              pathname: '/auth/confirmemail',
              state: { email: email.value },
            }}
          />
        )}

        <div className="content">
          <div className="tabbed">
            <div
              className={joinClasses([
                'tab-content',
                this.formStatus && 'opened',
              ])}
            >
              {this.showError.get() && (
                <ErrorAlert
                  error={this.error}
                  onClose={() => this.showError.set(false)}
                />
              )}

              <div className="form-navigation">
                <div className="form-navigation__item">
                  <Icon id="shopping" />
                  <p className="title">Faster Checkouts</p>
                </div>
                <div className="form-navigation__item">
                  <Icon id="map_market_alt" />
                  <p className="title">Save Addresses</p>
                </div>
                <div className="form-navigation__item">
                  <Icon id="check_box_icon" />
                  <p className="title">Check Order Status</p>
                </div>
                <div className="form-navigation__item">
                  <Icon id="history" />
                  <p className="title">Check Order History</p>
                </div>
              </div>

              <form
                onChange={() => this.showError.set(false)}
                className="form"
                onSubmit={this.handleSubmit}
              >
                <InputForm
                  label="First Name *"
                  name="firstName"
                  data={firstName}
                  className={joinClasses([
                    'autofill-bg-beige',
                    firstName.value && 'shrink',
                  ])}
                  showErrors={
                    (firstName.invalid && firstName.touched) ||
                    (firstName.invalid && !!firstName.value)
                  }
                  disabled={this.disable.get()}
                  {...InputFormControl.bindActions(firstName)}
                />

                <InputForm
                  label="Last Name *"
                  name="lastName"
                  data={lastName}
                  className={joinClasses([
                    'autofill-bg-beige',
                    lastName.value && 'shrink',
                  ])}
                  showErrors={
                    (lastName.invalid && lastName.touched) ||
                    (lastName.invalid && !!lastName.value)
                  }
                  disabled={this.disable.get()}
                  {...InputFormControl.bindActions(lastName)}
                />

                <InputForm
                  label="E-mail *"
                  type="email"
                  name="email"
                  data={email}
                  className={joinClasses([
                    'autofill-bg-beige',
                    email.value && 'shrink',
                  ])}
                  showErrors={
                    (email.invalid && email.touched) ||
                    (email.invalid && !!email.value)
                  }
                  disabled={this.disable.get()}
                  {...InputFormControl.bindActions(email)}
                />

                <InputForm
                  label="Password *"
                  type={this.inputPasswordType.get()}
                  name="password"
                  data={password}
                  iconId={
                    this.inputPasswordType.get() === 'password'
                      ? 'eye'
                      : 'eye_light'
                  }
                  className={joinClasses([
                    'autofill-bg-beige',
                    password.value && 'shrink',
                  ])}
                  showErrors={
                    (password.invalid && password.touched) ||
                    (password.invalid && !!password.value)
                  }
                  disabled={this.disable.get()}
                  onIconClick={this.handleChangeInputPasswordType}
                  {...InputFormControl.bindActions(password)}
                />

                <InputForm
                  label="Confirm Password *"
                  type={this.inputPasswordConfirmType.get()}
                  name="passwordConfirm"
                  data={passwordConfirm}
                  iconId={
                    this.inputPasswordConfirmType.get() === 'password'
                      ? 'eye'
                      : 'eye_light'
                  }
                  className={joinClasses([
                    'autofill-bg-beige',
                    passwordConfirm.value && 'shrink',
                  ])}
                  showErrors={
                    (passwordConfirm.invalid && passwordConfirm.touched) ||
                    (passwordConfirm.invalid && !!passwordConfirm.value)
                  }
                  disabled={this.disable.get()}
                  onIconClick={this.handleChangeInputPasswordConfirmType}
                  {...InputFormControl.bindActions(passwordConfirm)}
                />

                <div
                  className={joinClasses([
                    'required',
                    this.error.emptyErrors &&
                      this.error.emptyErrors.length &&
                      'required_error',
                  ])}
                >
                  * Fields are required. Please fill them
                </div>

                <input
                  className="btn_brown submit"
                  type="submit"
                  value="Create Account"
                  disabled={
                    this.error.emptyErrors &&
                    Boolean(this.error.emptyErrors.length)
                  }
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SignUp;
