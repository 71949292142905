import * as React from 'react';
import { ShippingStep as ShippingStepComponent } from './ShippingStep';
import shippingFormStore from './shipping.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CartStore from 'root/store/cart';

interface IShippingStep extends RouteComponentProps {
  cart: CartStore;
}

export const ShippingStep = withRouter(
  (props: IShippingStep): JSX.Element => (
    <ShippingStepComponent {...props} store={shippingFormStore} />
  ),
);
