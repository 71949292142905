import { IObservableArray, makeAutoObservable, observable, toJS } from 'mobx';
import { FilterRange } from 'root/store/products/filterRange.store';
import { IFilterStonesExtraParams, QueryParamsDiamondFilters } from '../types';

export enum DiamondsFilterParams {
  PRICE = 'price',
  CARAT = 'carat',
  COLOR = 'color',
  SHAPE = 'shape',
  TABLE = 'table',
  DEPTH = 'depth',
  CUT = 'cut',
  CLARITY = 'clarity',
  FLUORESCENCE = 'fluorescence',
  GRADING = 'gradingLab',
  POLISH = 'polish',
  SYMMETRY = 'symmetry',
  TYPE = 'stoneType',
  WIDTH = 'stoneWidth',
  LENGTH = 'stoneLength',
}

export class DiamondFiltersStore {
  price!: FilterRange;
  carat!: FilterRange;
  color!: FilterRange;
  table!: FilterRange;
  depth!: FilterRange;
  stoneWidth!: FilterRange;
  stoneLength!: FilterRange;
  shape: IObservableArray = observable([]);
  cut: IObservableArray = observable([]);
  clarity: IObservableArray = observable([]);
  fluorescence: IObservableArray = observable([]);
  gradingLab: IObservableArray = observable([]);
  polish: IObservableArray = observable([]);
  symmetry: IObservableArray = observable([]);
  stoneType: IObservableArray = observable([]);

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  get queryParams(): DiamondFiltersStore {
    return toJS(this);
  }

  update(filters: QueryParamsDiamondFilters): void {
    const {
      price,
      carat,
      color,
      table,
      depth,
      shape,
      cut,
      clarity,
      fluorescence,
      gradingLab,
      polish,
      symmetry,
      stoneType,
      stoneWidth,
      stoneLength,
    } = filters;

    price && this.price.setRange(Number(price.from), Number(price.to));
    carat && this.carat.setRange(Number(carat.from), Number(carat.to));
    color && this.color.setRange(color.from, color.to);
    table && this.table.setRange(Number(table.from), Number(table.to));
    depth && this.depth.setRange(Number(depth.from), Number(depth.to));
    stoneWidth &&
      this.stoneWidth.setRange(Number(stoneWidth.from), Number(stoneWidth.to));
    stoneLength &&
      this.stoneLength.setRange(
        Number(stoneLength.from),
        Number(stoneLength.to),
      );

    shape && this.shape.replace(shape);
    cut && this.cut.replace(cut);
    clarity && this.clarity.replace(clarity);
    fluorescence && this.fluorescence.replace(fluorescence);
    gradingLab && this.gradingLab.replace(gradingLab);
    polish && this.polish.replace(polish);
    symmetry && this.symmetry.replace(symmetry);
    stoneType && this.stoneType.replace(stoneType);
  }

  updateByExtraParams(params: IFilterStonesExtraParams): void {
    const { price, carat, table, depth, stoneWidth, stoneLength } = params;

    this.price.setRange(Number(price?.min) || 0, Number(price?.max) || 999999);
    this.carat.setRange(Number(carat?.min) || 0.1, Number(carat?.max) || 50);
    this.table.setRange(Number(table?.min) || 0, Number(table?.max) || 100);
    this.depth.setRange(Number(depth?.min) || 0, Number(depth?.max) || 100);
    this.stoneWidth.setRange(
      Number(stoneWidth?.min) || 0,
      Number(stoneWidth?.max) || 100,
    );
    this.stoneLength.setRange(
      Number(stoneLength?.min) || 0,
      Number(stoneLength?.max) || 100,
    );
  }

  reset(params?: IFilterStonesExtraParams): void {
    this.price = new FilterRange(
      params?.price?.min || 0,
      params?.price?.max || 999999,
    );
    this.carat = new FilterRange(
      params?.carat?.min || 0.1,
      params?.carat?.max || 50,
    );
    this.color = new FilterRange('D', 'M');
    this.table = new FilterRange(
      params?.table?.min || 0,
      params?.table?.max || 100,
    );
    this.depth = new FilterRange(
      params?.depth?.min || 0,
      params?.depth?.max || 100,
    );
    this.stoneWidth = new FilterRange(
      params?.stoneWidth?.min || 0,
      params?.stoneWidth?.max || 100,
    );
    this.stoneLength = new FilterRange(
      params?.stoneLength?.min || 0,
      params?.price?.max || 100,
    );

    this.shape.clear();
    this.cut.clear();
    this.clarity.clear();
    this.fluorescence.clear();
    this.gradingLab.clear();
    this.polish.clear();
    this.symmetry.clear();
    this.stoneType.clear();
  }
}
