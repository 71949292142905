import React from 'react';
import Svg from '../Svg';

interface ICompareIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CompareIcon(props: ICompareIconProps): JSX.Element {
  return (
    <Svg width="24" height="20" viewBox="0 0 24 20" fill="none" {...props}>
      <path
        d="M9.625 11.656h-.037c0-.556.111-.26-3.154-6.717a1.68 1.68 0 0 0-1.559-1.002c-.668 0-1.299.334-1.596 1.002C.051 11.36.125 11.062.125 11.656c0 1.67 2.115 2.969 4.75 2.969 2.598 0 4.75-1.299 4.75-2.969ZM4.875 5.72s2.412 4.898 2.672 5.343H2.166c.223-.408 1.967-3.859 2.709-5.343Zm14.88.927.186-.556c.112-.334-.037-.668-.37-.78l-4.677-1.558c.038-.111.075-.26.075-.408A2.978 2.978 0 0 0 12 .375c-1.113 0-2.078.63-2.598 1.559L4.95.412c-.334-.074-.668.074-.742.371l-.186.594a.567.567 0 0 0 .372.742L9.03 3.678c.149 1.187.965 2.152 2.041 2.486v11.43h-6.79a.61.61 0 0 0-.595.593v.594c0 .334.26.594.594.594h8.016a.61.61 0 0 0 .594-.594V6.164a2.79 2.79 0 0 0 1.261-.78l4.862 1.634c.334.11.668-.075.742-.372ZM12 4.531c-.668 0-1.188-.52-1.188-1.187 0-.631.52-1.188 1.188-1.188.63 0 1.188.557 1.188 1.188 0 .668-.557 1.187-1.188 1.187Zm11.838 11.875c0-.556.111-.26-3.154-6.717a1.68 1.68 0 0 0-1.559-1.002c-.668 0-1.299.334-1.596 1.002-3.228 6.42-3.154 6.123-3.154 6.717 0 1.67 2.115 2.969 4.75 2.969 2.598 0 4.75-1.299 4.75-2.969h-.037Zm-7.422-.593c.223-.409 1.967-3.86 2.709-5.344v.037c.668 1.373 2.412 4.861 2.672 5.306h-5.381Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
