import React from 'react';
import { observer } from 'mobx-react';
import {
  FormGroup,
  FormControl,
  InputFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { CountryImpl, StateImpl } from 'root/store/app';
import {
  ShippingImpl,
  TProfileFormName,
} from 'root/store/account/accountTypes';
import CustomSelect from 'components/form/CustomSelect';
import InputForm from 'components/common/InputForm';
import { joinClasses } from 'lib/utils';

const FORM_SHIPPING = 'shipping';

interface IShippingForm {
  shipping: FormGroup<ShippingImpl>;
  countries: CountryImpl[];
  isEdit: boolean;
  getCurrentCountry: (country: FormControl<number>) => CountryImpl | undefined;
  showErrors: (data: FormControl) => boolean;
  onChangeSelect: (
    formName: TProfileFormName,
    name: string,
    value: CountryImpl | StateImpl,
  ) => void;
}

const ShippingForm = (props: IShippingForm) => {
  const {
    shipping,
    countries,
    isEdit,
    getCurrentCountry,
    showErrors,
    onChangeSelect,
  } = props;
  const { country, state, city, street, apt, zip } = shipping.controls;
  const currentCountry = getCurrentCountry(country);
  const states = (currentCountry && currentCountry.states) || [];

  const handleChangeSelect = (
    name: TProfileFormName,
    value: CountryImpl | StateImpl,
  ) => {
    onChangeSelect(FORM_SHIPPING, name, value);
  };

  return (
    <form className="form" id={FORM_SHIPPING} noValidate>
      <h3 className="form-title">Shipping Address</h3>

      <div>
        <CustomSelect
          name="country"
          label="Country *"
          form={FORM_SHIPPING}
          values={countries}
          defaultValue={country.value}
          onChange={handleChangeSelect}
          disabled={!isEdit}
        />
        {country.invalid && country.touched
          ? country.errors.map((error) => (
              <div
                className="form-label__error form-label__error_blocky"
                key={error.key}
              >
                {error.message}
              </div>
            ))
          : null}
      </div>

      {!!states.length && (
        <div>
          <CustomSelect
            name="state"
            label="State *"
            form={FORM_SHIPPING}
            values={states}
            defaultValue={state && state.value}
            onChange={handleChangeSelect}
            disabled={!isEdit}
          />

          {state.invalid && state.touched
            ? state.errors.map((error) => (
                <div
                  className="form-label__error form-label__error_blocky"
                  key={error.key}
                >
                  {error.message}
                </div>
              ))
            : null}
        </div>
      )}

      <InputForm
        name="city"
        label="City *"
        dataForm={FORM_SHIPPING}
        className={joinClasses(['autofill-bg-beige', city.value && 'shrink'])}
        data={city}
        showErrors={showErrors(city)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(city)}
      />

      <InputForm
        name="street"
        label="Street Address *"
        dataForm={FORM_SHIPPING}
        className={joinClasses(['autofill-bg-beige', street.value && 'shrink'])}
        data={street}
        showErrors={showErrors(street)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(street)}
      />

      <InputForm
        name="apt"
        label="Apt/Unit"
        dataForm={FORM_SHIPPING}
        className={joinClasses(['autofill-bg-beige', apt.value && 'shrink'])}
        data={apt}
        showErrors={showErrors(apt)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(apt)}
      />

      <InputForm
        name="zip"
        label="ZIP Code *"
        dataForm={FORM_SHIPPING}
        className={joinClasses(['autofill-bg-beige', zip.value && 'shrink'])}
        data={zip}
        showErrors={showErrors(zip)}
        disabled={!isEdit}
        {...InputFormControl.bindActions(zip)}
      />
    </form>
  );
};

export default observer(ShippingForm);
