import React from 'react';
import Svg from '../Svg';

interface ISettingsIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function SettingsIcon(props: ISettingsIconProps): JSX.Element {
  return (
    <Svg width="23" height="24" viewBox="0 0 23 24" fill="none" {...props}>
      <path
        d="m22.094 14.063-1.5-.891c0-.328.047-.844.047-1.172 0-.281-.047-.797-.047-1.125l1.5-.89c.468-.235.656-.797.515-1.266a11.669 11.669 0 0 0-2.672-4.688c-.375-.375-.937-.468-1.406-.187l-1.5.89a8.699 8.699 0 0 0-2.015-1.171V1.827c0-.562-.329-.984-.844-1.125a11.638 11.638 0 0 0-5.39 0c-.516.14-.845.563-.845 1.125v1.734a8.7 8.7 0 0 0-2.015 1.172l-1.5-.89c-.469-.281-1.031-.188-1.406.187A11.669 11.669 0 0 0 .344 8.72c-.14.469.047 1.031.515 1.265l1.5.891c0 .328-.046.844-.046 1.125 0 .328.046.844.046 1.172l-1.5.89c-.468.235-.656.797-.515 1.266a11.668 11.668 0 0 0 2.672 4.688c.375.375.937.468 1.406.187l1.5-.89a8.7 8.7 0 0 0 2.016 1.171v1.735c0 .562.328.984.843 1.125a11.64 11.64 0 0 0 5.39 0c.517-.14.845-.563.845-1.125v-1.735c.609-.234 1.5-.75 2.015-1.172l1.5.891c.469.281 1.032.188 1.407-.187a11.669 11.669 0 0 0 2.671-4.688c.141-.469-.047-1.031-.515-1.265Zm-3.047 4.687-2.203-1.266c-1.266 1.125-1.688 1.36-3.328 1.97v2.483c-.563.141-1.454.235-2.016.235-.61 0-1.5-.094-2.063-.235v-2.484c-1.593-.562-2.062-.844-3.328-1.969L3.906 18.75a11.256 11.256 0 0 1-2.062-3.563l2.203-1.218c-.328-1.688-.328-2.203 0-3.89l-2.203-1.22a11.257 11.257 0 0 1 2.062-3.562L6.11 6.563c1.266-1.125 1.735-1.407 3.329-1.97V2.11c.562-.14 1.453-.234 2.062-.234.563 0 1.453.094 2.016.234v2.485c1.593.562 2.062.843 3.328 1.968l2.203-1.265a11.258 11.258 0 0 1 2.062 3.562l-2.203 1.22c.328 1.687.328 2.202 0 3.89l2.203 1.219a11.258 11.258 0 0 1-2.062 3.562ZM11.5 7.5C9.016 7.5 7 9.563 7 12c0 2.484 2.016 4.5 4.5 4.5 2.438 0 4.5-2.016 4.5-4.5 0-2.438-2.063-4.5-4.5-4.5Zm0 7.5c-1.688 0-3-1.313-3-3 0-1.64 1.313-3 3-3 1.64 0 3 1.36 3 3 0 1.688-1.36 3-3 3Z"
        fill="#928A8E"
      />
    </Svg>
  );
}
