import PearMeasLengthIcon from './PearMeasLengthIcon';
import PearMeasWidthIcon from './PearMeasWidthIcon';
import PearMeasDepthIcon from './PearMeasDepthIcon';
import PearDepthIcon from './PearDepthIcon';
import PearTableIcon from './PearTableIcon';
import PearIcon from './PearIcon';

export default {
  pear_measLength: PearMeasLengthIcon,
  pear_measWidth: PearMeasWidthIcon,
  pear_measDepth: PearMeasDepthIcon,
  pear_depth: PearDepthIcon,
  pear_table: PearTableIcon,
  pear: PearIcon,
};
