import MarquiseMeasLengthIcon from './MarquiseMeasLengthIcon';
import MarquiseMeasWidthIcon from './MarquiseMeasWidthIcon';
import MarquiseMeasDepthIcon from './MarquiseMeasDepthIcon';
import MarquiseDepthIcon from './MarquiseDepthIcon';
import MarquiseTableIcon from './MarquiseTableIcon';
import MarquiseIcon from './MarquiseIcon';

export default {
  marquise_measLength: MarquiseMeasLengthIcon,
  marquise_measWidth: MarquiseMeasWidthIcon,
  marquise_measDepth: MarquiseMeasDepthIcon,
  marquise_depth: MarquiseDepthIcon,
  marquise_table: MarquiseTableIcon,
  marquise: MarquiseIcon,
};
