import React from 'react';
import Svg from '../Svg';

interface ICheckIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CheckIcon(props: ICheckIconProps): JSX.Element {
  return (
    <Svg width="18" height="13" viewBox="0 0 18 13" fill="none" {...props}>
      <path
        fill="#fff"
        d="M15.293.203 6.047 9.45 2.672 6.04c-.176-.14-.457-.14-.598 0l-1.02 1.02c-.14.14-.14.421 0 .597l4.712 4.676a.405.405 0 0 0 .597 0L16.91 1.785c.14-.14.14-.422 0-.597L15.89.203c-.14-.176-.421-.176-.597 0Z"
      />
    </Svg>
  );
}
