import * as React from 'react';
import { withRouter } from 'react-router-dom';
import OfferPopupStore from '../../store';
import { PromoOfferPopup as DefaultComponent } from './PromoOfferPopup';

export const offerPopupStore = new OfferPopupStore();

export const PromoOfferPopup = withRouter((props: any) => (
  <DefaultComponent visiblePopup={props.visiblePopup} store={offerPopupStore} />
));
