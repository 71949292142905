import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTER_PATHS } from 'root/routes/constants';
import ImageItem from 'components/common/ImageItem';

interface ICarouselItemProps extends RouteComponentProps<{ type: string }> {
  item: string;
  link: string;
  level?: number;
}

const CarouselItem = observer((props: ICarouselItemProps) => {
  const { link, level, history } = props;

  const handleClick = () => {
    link &&
      history.push(`${ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH}/${link}`);
  };

  return (
    <div id={`level${props.level}`} className={`item level${level}`}>
      <ImageItem src={props.item} onClick={handleClick} />
    </div>
  );
});

export default withRouter(CarouselItem);
