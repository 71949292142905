import {
  FormControl,
  FormGroup,
  maxLengthValidator,
  patternValidator,
  requiredValidator,
  ValidationEventTypes,
  wrapperSequentialCheck,
} from '@quantumart/mobx-form-validation-kit';
import { makeAutoObservable } from 'mobx';
import { ContactsStoreImpl, FormContacts, TFormValues } from '../types';

export default class ContactsStore implements ContactsStoreImpl {
  disable = false;
  status = '';
  form: FormGroup<FormContacts>;

  constructor() {
    makeAutoObservable(this);
    this.form = ContactsStore.initialForm();
  }

  static initialForm(): FormGroup<FormContacts> {
    return new FormGroup<FormContacts>({
      firstName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator('Field is required', ValidationEventTypes.Error),
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars ',
              ValidationEventTypes.Error,
            ),
            patternValidator(
              /^[a-zA-Z]+$/,
              'First Name must contain only latin letters',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      lastName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars ',
              ValidationEventTypes.Error,
            ),
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z]+$/,
              'Last Name must contain only latin letters',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      email: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars ',
              ValidationEventTypes.Error,
            ),
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              'Wrong Email format',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      phone: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            maxLengthValidator(
              80,
              'Field maximum length is 80 chars',
              ValidationEventTypes.Error,
            ),
            requiredValidator('Field is required', ValidationEventTypes.Error),
            patternValidator(
              /^(?=.*[0-9])[- +()0-9]+$/,
              'Phone must contain only digits, +, -',
              ValidationEventTypes.Error,
            ),
          ]),
        ],
      }),
      message: new FormControl<string>('', {
        validators: [
          maxLengthValidator(
            1000,
            'Field maximum length is 1000 chars',
            ValidationEventTypes.Error,
          ),
          patternValidator(
            /./,
            'Wrong Message format',
            ValidationEventTypes.Error,
          ),
        ],
      }),
    });
  }

  get formValues(): TFormValues {
    const { firstName, lastName, email, phone, message } = this.form.controls;
    return {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    };
  }

  changeStatus(status: string): void {
    this.status = status;
  }

  resetForm(): void {
    ContactsStore.initialForm();
  }

  disableForm(isDisable: boolean): void {
    this.disable = isDisable;
  }
}
