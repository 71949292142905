import React from 'react';
import Svg from '../../Svg';

interface IPrincessDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PrincessDepthIcon(
  props: IPrincessDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M100.2 128.54 23.36 68.73l57.18 62.71"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.2 116.35 23.11 68.6l77.09 40.9v31.7l-19.66-9.76-18.35-12.53L40.6 100.1 22.72 68.52l21.13-13h56.35l-44.74 7.66v-7.27l-23.84 7.81-8.51 4.88 31.42-5.19 45.67-.15M27.35 70.97h72.85M24.52 68.6h75.68M100.2 128.54l76.84-59.81-57.18 62.71"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m100.2 116.35 77.09-47.75-77.09 40.9v31.7l19.66-9.76 18.34-12.53 21.6-18.81 17.88-31.58-21.13-13H100.2l44.74 7.66v-7.27l23.84 7.81 8.51 4.88-31.42-5.19-45.67-.15M173.04 70.97H100.2M175.88 68.6H100.2"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.2 56.3h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.7 56.3h82.45"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M188.64 56.3h1.5M100.2 142.25h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.7 142.25h82.45"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M188.64 142.25h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.14 55.63v85.46"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
