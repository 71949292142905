import React from 'react';
import Svg from '../../Svg';

interface IEmeraldMeasWidthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function EmeraldMeasWidthIcon(
  props: IEmeraldMeasWidthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M134.39 41.03H65.33L51 55.35v100.28l14.33 14.32h69.06l14.32-14.32V55.35l-14.32-14.32z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.71 52.07H73.38l-12.55 9.55v88.23l11.84 10.13h53.75l11.83-10.13V61.62l-12.54-9.55z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.52 57.49H76.94L65.82 64.9v82.25l11.12 7.55h45.58l11.12-7.55V64.9l-11.12-7.41zM65.82 147.15 51 155.63M76.94 154.7l-11.61 15.25M122.52 154.7l11.87 15.25M133.64 147.15l15.07 8.48M148.71 55.35l-15.07 9.55M134.39 41.02l-11.87 16.47M65.33 41.02l11.61 16.47M65.82 64.9 51 55.35"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.39 46.79 55.75 58.41v94.5l13.3 12.15h61.54l13.04-12.29v-94.2l-13.4-11.78H69.39z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 55.29v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 50.73V33.91"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.06 3.06"
      />
      <path
        d="M51 32.38v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 30.48h97.71"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.71 55.29v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.71 50.73V33.91"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.06 3.06"
      />
      <path
        d="M148.71 32.38v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
