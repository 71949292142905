import React from 'react';
import Svg from '../Svg';

interface ITilesIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function TilesIcon(props: ITilesIconProps): JSX.Element {
  return (
    <Svg width="24" height="22" viewBox="0 0 24 22" fill="none" {...props}>
      <path
        d="M13.875 0.5C13.2188 0.5 12.75 1.01562 12.75 1.625V9.125C12.75 9.78125 13.2188 10.25 13.875 10.25H22.875C23.4844 10.25 24 9.78125 24 9.125V1.625C24 1.01562 23.4844 0.5 22.875 0.5H13.875ZM10.125 0.5H1.125C0.46875 0.5 0 1.01562 0 1.625V9.125C0 9.78125 0.46875 10.25 1.125 10.25H10.125C10.7344 10.25 11.25 9.78125 11.25 9.125V1.625C11.25 1.01562 10.7344 0.5 10.125 0.5ZM0 12.875V20.375C0 21.0312 0.46875 21.5 1.125 21.5H10.125C10.7344 21.5 11.25 21.0312 11.25 20.375V12.875C11.25 12.2656 10.7344 11.75 10.125 11.75H1.125C0.46875 11.75 0 12.2656 0 12.875ZM13.875 21.5H22.875C23.4844 21.5 24 21.0312 24 20.375V12.875C24 12.2656 23.4844 11.75 22.875 11.75H13.875C13.2188 11.75 12.75 12.2656 12.75 12.875V20.375C12.75 21.0312 13.2188 21.5 13.875 21.5Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
