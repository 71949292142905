import React from 'react';
import Svg from '../Svg';

interface IGemSolidIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function GemSolidIcon(props: IGemSolidIconProps): JSX.Element {
  return (
    <Svg width="20" height="15" viewBox="0 0 576 512" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M485.5 0L576 160H474.9L405.7 0h79.8zm-128 0l69.2 160H149.3L218.5 0h139zm-267 0h79.8l-69.2 160H0L90.5 0zM0 192h100.7l123 251.7c1.5 3.1-2.7 5.9-5 3.3L0 192zm148.2 0h279.6l-137 318.2c-1 2.4-4.5 2.4-5.5 0L148.2 192zm204.1 251.7l123-251.7H576L357.3 446.9c-2.3 2.7-6.5-.1-5-3.2z"
      />
    </Svg>
  );
}
