import {
  JewelryCollectionCategories,
  ProductCategories,
  ProductPromos,
} from 'root/ProductTypes';

export const TYPES = {
  gender: {
    tooltip: 'Genders type of jewelry.',
    isNeedDetail: false,
  },
  stoneType: {
    tooltip: 'Stone type of jewelry.',
    isNeedDetail: false,
  },
  jewelryCollection: {
    tooltip: 'Collection of jewelry.',
    isNeedDetail: false,
  },
  price: {
    tooltip: 'Price of jewelry.',
    isNeedDetail: false,
  },
  metal: {
    tooltip: 'Metals of jewelry.',
    isNeedDetail: false,
  },
  category: {
    tooltip: 'Categories of jewelry.',
    isNeedDetail: false,
  },
  pendantType: {
    tooltip: 'Type of pendant.',
    isNeedDetail: false,
  },
  earringType: {
    tooltip: 'Type of earring.',
    isNeedDetail: false,
  },
  closureType: {
    tooltip: 'Closure type of jewelry.',
    isNeedDetail: false,
  },
  necklaceLength: {
    tooltip: 'Length of necklaces.',
    isNeedDetail: false,
  },
  claspType: {
    tooltip: 'Clasp type of necklaces.',
    isNeedDetail: false,
  },
  chainStyle: {
    tooltip: 'Chain style of necklaces.',
    isNeedDetail: false,
  },
  size: {
    tooltip: 'Size of rings.',
    isNeedDetail: false,
  },
  width: {
    tooltip: 'Width of rings.',
    isNeedDetail: false,
  },
  weight: {
    tooltip: 'Weight of rings.',
    isNeedDetail: false,
  },
  profile: {
    tooltip: 'Profile of rings.',
    isNeedDetail: false,
  },
};

export const GENDERS = [
  {
    title: 'Mens',
    value: 'Mens',
  },
  {
    title: 'Ladies',
    value: 'Ladies',
  },
  {
    title: 'Unisex',
    value: 'Unisex',
  },
  {
    title: 'All',
    value: null,
  },
];

export const METALS = [
  {
    title: 'White Gold',
    value: 'White Gold',
  },
  {
    title: 'Yellow Gold',
    value: 'Yellow Gold',
  },
  {
    title: 'Rose Gold',
    value: 'Rose Gold',
  },
  {
    title: 'All',
    value: null,
  },
];

export const CATEGORIES = [
  {
    title: 'Pendants',
    value: ProductCategories.PENDANTS,
  },
  {
    title: 'Earrings',
    value: ProductCategories.EARRINGS,
  },
  {
    title: 'Bracelets',
    value: ProductCategories.BRACELETS,
  },
  {
    title: 'Necklaces',
    value: ProductCategories.NECKLACES,
  },
  {
    title: 'Rings',
    value: ProductCategories.RINGS,
  },
  {
    title: 'All',
    value: null,
  },
];
export const PROMOS = [
  {
    title: 'St.Valentine',
    value: ProductPromos.ST_VALENTINE,
  },
  {
    title: 'All',
    value: null,
  },
];

export const COLLECTION = [
  {
    title: 'Diamond Jewelry',
    value: JewelryCollectionCategories.DIAMOND_JEWELRY,
  },
  {
    title: 'All',
    value: null,
  },
];
