import * as React from 'react';
import { observer } from 'mobx-react';
import { TableParamsImpl } from '../../store';
import { CartItemEarringsImpl } from 'root/store/cart/item.store';

export interface EarringsTemplateProps {
  product: CartItemEarringsImpl;
  property: TableParamsImpl;
}

export const EarringsTemplate = observer((props: EarringsTemplateProps) => {
  const { product, property } = props;
  return (
    <>
      {(() => {
        switch (property.key) {
          case 'gender':
            return product.gender;
          case 'metal':
            return product.metal;
          case 'earringType':
            return product.earringType;
          case 'closureType':
            return product.closureType;
          case 'primaryStoneShape':
            return product.primaryStoneShape;
          case 'primaryStoneType':
            return product.primaryStoneType;
          case 'primaryStoneSize':
            return product.primaryStoneSize;

          case 'state':
            return product.state;
          case 'earringPostType':
            return product.earringPostType;
          case 'stoneClarityColor':
            return product.stoneClarityColor;
          default:
            return null;
        }
      })()}
    </>
  );
});
