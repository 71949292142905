import React from 'react';
import { observer } from 'mobx-react';
import { DIAMOND_CATEGORY_TITLE } from 'root/ProductTypes';
import { StoneDetailImpl } from 'root/store/stones/one.store';

interface IProps {
  productStone: StoneDetailImpl;
}

const InfoStone = ({ productStone }: IProps) => {
  return (
    <div>
      {productStone.carat} ct | {productStone.shape} | {productStone.clarity} |{' '}
      {productStone.cut} Cut | {productStone.color} |{' '}
      {DIAMOND_CATEGORY_TITLE[productStone.categories[0]?.alias]}
    </div>
  );
};

export default observer(InfoStone);
