import * as React from 'react';

import './UncaughtErrorPage.scss';

export default class UncaughtErrorPage extends React.Component {
  reload = (): void => {
    window.location.reload();
  };

  render(): JSX.Element {
    return (
      <div className="uncaught-error grid">
        <div className="grid-area-content">
          <div className="wrap-logo">
            <img
              className="logo"
              src={require('img/wrong.png').default}
              alt="Manager"
            />
          </div>
          <p className="error-status">Error: Uncaught error</p>
          <p className="error-message">
            Sorry, something went wrong here. Please contact your technical
            support to solve the problem <br />
            by phone +1-877-382-6275 or e-mail customerservice@venori.com.
          </p>
          <button
            className="error-button btn btn_lg btn_brown btn_stretch"
            onClick={this.reload}
          >
            Try again
          </button>
        </div>
      </div>
    );
  }
}
