import React from 'react';
import Svg from '../Svg';

interface ISpellCheckIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function SpellCheckIcon(
  props: ISpellCheckIconProps,
): JSX.Element {
  return (
    <Svg width="21" height="19" viewBox="0 0 21 19" fill="none" {...props}>
      <path
        d="M9.938 9.75h3.199c1.511 0 2.883-1.125 2.953-2.637 0-.035.035-.105.035-.176 0-.808-.352-1.511-.914-2.039.21-.386.316-.843.316-1.3v-.282C15.387 1.84 14.051.75 12.574.75H9.938a.578.578 0 0 0-.563.563v7.875c0 .316.246.562.563.562Zm1.406-7.031h1.406c.457 0 .844.386.844.844a.833.833 0 0 1-.844.843h-1.406V2.72Zm0 3.375h1.969c.457 0 .843.386.843.843a.833.833 0 0 1-.844.844h-1.968V6.094Zm-5.52-4.535C5.648 1.102 5.227.75 4.734.75h-.879c-.492 0-.914.352-1.09.809L.376 9.047v.176c0 .28.246.527.563.527h.843c.282 0 .492-.14.563-.387l.422-1.3h3.058l.422 1.3c.07.246.281.387.563.387h.878a.578.578 0 0 0 .563-.563c0-.035-.035-.07-.035-.14l-2.39-7.488Zm-2.32 4.253.808-2.39.774 2.39H3.504Zm16.945 4.676-1.582-1.582a.582.582 0 0 0-.422-.176c-.14 0-.28.07-.386.176l-5.309 5.309-1.969-1.934a.564.564 0 0 0-.386-.176c-.176 0-.317.07-.387.176L8.39 13.863a.529.529 0 0 0-.141.387c0 .176.035.316.14.422l3.938 3.937a.583.583 0 0 0 .422.176c.14 0 .281-.07.387-.176l7.312-7.312a.582.582 0 0 0 .176-.422c0-.14-.07-.281-.176-.387Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
