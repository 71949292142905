import React from 'react';
import Svg from '../Svg';

interface ITrashIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function TrashIcon(props: ITrashIconProps): JSX.Element {
  return (
    <Svg width="24" height="22" viewBox="0 0 448 512" fill="none" {...props}>
      <path
        fill="#4D4048"
        d="M432 80h-82.4l-34-56.7A48 48 0 0 0 274.4 0H173.6a48 48 0 0 0-41.2 23.3L98.4 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16l21.2 339a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM173.6 48h100.8l19.2 32H154.4zm173.3 416H101.11l-21-336h287.8z"
      />
    </Svg>
  );
}
