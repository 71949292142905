import * as React from 'react';
import { observer } from 'mobx-react';
import { ScrollToTopOnUpdate } from 'components/common/ScrollToTop';
import Icon from 'components/common/Icon';

interface CheckoutTabProps {
  id: string;
  title: string;
  icon: string;
  checked: number | boolean;
  children: React.ReactNode;
  handleChange?: (e: React.ChangeEvent) => void;
}

export const CheckoutTab = observer(
  ({ id, title, icon, checked, children, handleChange }: CheckoutTabProps) => {
    const handler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof handleChange === 'function') {
        handleChange(e);
      }
    };
    return (
      <>
        <ScrollToTopOnUpdate />

        <input
          name="tabs"
          type="radio"
          id={id}
          className="input"
          checked={checked as boolean}
          onChange={(e) => {
            handler(e);
          }}
        />

        <label htmlFor={id} className="label">
          <div className="label-inner">
            <Icon id={icon} />
            {title}
          </div>
        </label>

        <div className="panel block-scrollbar">{children}</div>
      </>
    );
  },
);
