import { JewelryImpl } from './store/jewelry/one.store';
import { RingImpl } from './store/rings/one.store';
import { StoneImpl } from './store/stones/one.store';

export enum ProductTypes {
  Stone = 'stone',
  Ring = 'ring',
  Pendant = 'pendant',
  Earrings = 'earrings',
  Bracelets = 'bracelet',
  Necklaces = 'necklaces',
}

export enum ProductStyles {
  EG_RING_CLASSIC = 'EG_RING_CLASSIC',
  EG_RING_SOLITAIRE = 'EG_RING_SOLITAIRE',
  EG_RING_HALO = 'EG_RING_HALO',
  EG_RING_TWOSTONE = 'EG_RING_TWOSTONE',
  EG_RING_ACCENTED = 'EG_RING_ACCENTED',
  EG_RING_SIDESTONE = 'EG_RING_SIDESTONE',
  EG_RING_THREESTONE = 'EG_RING_THREESTONE',
  EG_RING_BEZEL = 'EG_RING_BEZEL',
  EG_RING_MODERN = 'EG_RING_MODERN',
  EG_RING_VINTAGE = 'EG_RING_VINTAGE',
}

export enum ProductBands {
  WB_RING_CLASSIC = 'WB_RING_CLASSIC',
  WB_RING_DIAMOND_WOMAN = 'WB_RING_DIAMOND_WOMAN',
  WB_RING_DIAMOND_MAN = 'WB_RING_DIAMOND_MAN',
  WB_RING_ALT_METAL = 'WB_RING_ALT_METAL',
  WB_RING_STACKABLE = 'WB_RING_STACKABLE',
  WB_RING_CARVED = 'WB_RING_CARVED',
  WB_RING_ANNIVERSARY = 'WB_RING_ANNIVERSARY',
  WB_RING_ETERNITY = 'WB_RING_ETERNITY',
}

export enum ProductCategories {
  JEWELRY = 'JEWELRY',
  RINGS = 'RINGS',
  ENGAGEMENT_RINGS = 'ENGAGEMENT_RINGS',
  WEDDING_RINGS = 'WEDDING_RINGS',
  NECKLACES = 'NECKLACES',
  EARRINGS = 'EARRINGS',
  PENDANTS = 'PENDANTS',
  BRACELETS = 'BRACELETS',
  STONES = 'STONES',
  DIAMONDS = 'DIAMONDS',
  DIAMONDS_LAB_CREATED = 'DIAMONDS_LAB_CREATED',
  GEMSTONES = 'GEMSTONES',
}

export enum ProductPromos {
  ST_VALENTINE = 'SV',
}

export enum JewelryCollectionCategories {
  DIAMOND_JEWELRY = 'DIAMOND_JEWELRY',
  GEMSTONE_JEWELRY = 'GEMSTONE_JEWELRY',
  PEARL_JEWELRY = 'PEARL_JEWELRY',
}

export const DIAMOND_CATEGORIES = [
  ProductCategories.DIAMONDS,
  ProductCategories.DIAMONDS_LAB_CREATED,
];

export const RING_CATEGORIES = [
  ProductCategories.RINGS,
  ProductCategories.ENGAGEMENT_RINGS,
  ProductCategories.WEDDING_RINGS,
  ProductCategories.RINGS,
];

export const JEWELRY_CATEGORIES = [
  ProductCategories.ENGAGEMENT_RINGS,
  ProductCategories.WEDDING_RINGS,
  ProductCategories.NECKLACES,
  ProductCategories.EARRINGS,
  ProductCategories.PENDANTS,
  ProductCategories.BRACELETS,
];

export const DIAMOND_CATEGORY_TITLE: { [key: string]: string } = {
  [ProductCategories.DIAMONDS]: 'Natural Diamonds',
  [ProductCategories.DIAMONDS_LAB_CREATED]: 'Lab-Created Diamonds',
};

export type ProductDetailImpl = JewelryImpl | RingImpl | StoneImpl;

export type ProductInfoImpl = StoneInfoImpl | RingInfoImpl | JewelryInfoImpl;

export interface CategoriesProductImpl {
  id: number;
  name: string;
  alias: string;
}

export interface StoneInfoImpl {
  id: number;
  category: {
    id: number;
    name: string;
  };
  currency: {
    id: number;
    name: string;
    iso: string;
    symbol: string;
    after: boolean;
    space: boolean;
    active: boolean;
  };
  price: string;
  type: string;
  description: string;
  photo: string;
  hasLoupe: boolean;
  shape: string;
  cut: string;
  carat: string;
  color: string;
  clarity: string;
  fluorescence: string;
  polish: string;
  gradingLab: string;
  certificate: string;
  symmetry: string;
  table: string;
  depth: string;
  measLength: string;
  measWidth: string;
  measDepth: string;
}

export interface RingInfoImpl {
  id: number;
  category: {
    id: number;
    name: string;
  };
  currency: {
    id: number;
    name: string;
    iso: string;
    symbol: string;
    after: boolean;
    space: boolean;
    active: boolean;
  };
  price: string;
  type: string;
  description: string;
  sku: string;
  size: string;
  metal: string;
  jeweleryImage: string;
  width: string;
  weight: string;
  profile: string;
  gender: string;
}

export interface JewelryInfoImpl {
  id: number;
  category: {
    id: number;
    name: string;
  };
  currency: {
    id: number;
    name: string;
    iso: string;
    symbol: string;
    after: boolean;
    space: boolean;
    active: boolean;
  };
  price: string;
  type: string;
  description: string;
  metal: string;
  jeweleryImage: string;
  weight: string;
  gender: string;
  jewelryCollection: string;

  pendantType: string;
  earringType: string;
  closureType: string;
  necklaceLength: string;
  claspType: string;
  chainStyle: string;
}
