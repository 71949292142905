import React from 'react';
import Svg from '../../Svg';

interface IСushionMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function СushionMeasLengthIcon(
  props: IСushionMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M120.08 163.43H79a43.431 43.431 0 0 1-43.37-43.38V77.29A43.42 43.42 0 0 1 79 33.92h41.08a43.43 43.43 0 0 1 43.38 43.37v42.76a43.432 43.432 0 0 1-43.38 43.38zM79 34.67a42.67 42.67 0 0 0-42.62 42.62v42.76A42.67 42.67 0 0 0 79 162.68h41.08a42.668 42.668 0 0 0 42.63-42.63V77.29a42.67 42.67 0 0 0-42.63-42.62H79z"
        fill="#777"
      />
      <path
        d="m99.54 34.26 29.4 16.36 20.92-3.83-2.99 21.25 16.09 32.36-16.09 28.37 3.41 21.34-20.67-3.58-30.07 16.4-30.31-16.4-20.5 3.5 3.24-21.26L36 100.39l15.97-32.35-2.99-21.25 20.91 3.83 29.65-16.36z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6 134.79s14.39-9.86 31.94-7.74c17.55 2.12 22.58 5.22 28.84 4.85M38.6 62.29S53 72.15 70.54 70c17.54-2.15 22.58-5.23 28.84-4.86"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m69.23 146.52 30.15-14.62c6.25.37 11.29-2.74 28.84-4.85 17.55-2.11 31.94 7.74 31.94 7.74M129.61 146.52 99.38 131.9M69.89 50.62l29.49 14.56c6.25-.37 11.29 2.74 28.84 4.86s31.94-7.75 31.94-7.75M128.94 50.62 99.38 65.18M62 37.81c4.42 2.75 8.83 21.66 8.59 32.23-1.55 13.7-4.06 24.53-4.1 28.63-.04 4.1 2.55 14.86 4.1 28.56.24 10.56-4.17 29.48-8.59 32.23"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.52 37.81c-4.43 2.75-8.84 21.66-8.59 32.23 1.55 13.7 4 24.53 4.09 28.63.09 4.1-2.54 14.86-4.09 28.56-.25 10.56 4.16 29.48 8.59 32.23"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m146.87 68.04-14.85 30.63 14.85 30.1M51.97 68.04l14.47 30.63-14.47 30.1"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.28 33.63h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.77 33.63h76.2"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.99 2.99"
      />
      <path
        d="M181.46 33.63h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.58 33.63v130.74"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.28 164.37h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.77 164.37h76.2"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.99 2.99"
      />
      <path
        d="M181.46 164.37h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
