import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { TabLinks, TABS } from '../../constants';

interface CaratProps {
  activeTab: TabLinks;
}

export const TabsLinks = ({ activeTab }: CaratProps): JSX.Element => {
  return (
    <div className="tabs-links">
      {TABS.map(({ link, name }) => (
        <NavLink
          key={link}
          to={link}
          className="tab__link"
          activeClassName={activeTab === name ? 'active' : ''}
        >
          {name}
        </NavLink>
      ))}
    </div>
  );
};
