import * as React from 'react';
import { observer } from 'mobx-react';
import { CartItemNecklacesImpl } from 'root/store/cart/item.store';
import ProductRow from './ProductRow';

export interface NecklacesTemplateProps {
  product: CartItemNecklacesImpl;
}

const NECKLACES_PROPERTIES = {
  firstColumn: [
    'metal',
    'gender',
    'collection',
    'primaryStoneShape',
    'primaryStoneType',
    'primaryStoneSize',
  ],
  secondColumn: [
    'closureType',
    'necklaceLength',
    'claspType',
    'chainStyle',
    'birthstoneMonth',
    'initialNumber',
    'state',
  ],
};

export const NecklacesTemplate = observer((props: NecklacesTemplateProps) => {
  const { product } = props;
  return <ProductRow product={product} properties={NECKLACES_PROPERTIES} />;
});
