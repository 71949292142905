import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import rootStore from 'root/store';
import DefaultComponent from './CartListItem';
import { CartItemImpl } from 'root/store/cart/item.store';

export interface ICartListItemIndexProps {
  cartItem: CartItemImpl;
  className?: string;
  isPreview?: boolean;
  handleRemoveItem?: (event: MouseEvent<HTMLElement>) => void;
  handleRedirectToProductDetails?: (event: MouseEvent<HTMLElement>) => void;
}

const { browserWindowType } = rootStore;

const CartListItem = observer((props: ICartListItemIndexProps) => (
  <DefaultComponent {...props} browserWindowType={browserWindowType} />
));

export default CartListItem;
