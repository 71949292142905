import React from 'react';
import { observer } from 'mobx-react';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';
import { IObservableValue } from 'mobx/dist/internal';
import { PRODUCT_TYPE_TITLE_LIST } from 'utils/constants';
import { ICategoriesProduct } from 'root/store/products/types/product.type';
import { ICurrentProductDetail } from 'pages/ProductDetailPage/ProductDetailPage.types';
import { ProductDetailImpl } from 'root/ProductTypes';

interface AddDiamondPopupProps {
  product: ICurrentProductDetail | ProductDetailImpl;
  visiblePopup: IObservableValue<boolean>;
  addToCart: () => void;
  showEngravingTooltip: () => void;
}

const AddEngravePopup = ({
  product: { categories },
  visiblePopup,
  addToCart,
  showEngravingTooltip,
}: AddDiamondPopupProps) => {
  const currentCategory =
    categories.find(({ alias }) => PRODUCT_TYPE_TITLE_LIST[alias]) ||
    ({} as ICategoriesProduct);

  const handleAddToCart = () => {
    addToCart();
    visiblePopup.set(false);
  };

  const handleShowEngravingTooltip = () => {
    visiblePopup.set(false);
    showEngravingTooltip();
  };

  return (
    <PortalControl>
      <ModalWindow visible={visiblePopup.get()}>
        <div
          className="btn btn_bordered-default_without_border btn_circle modal-window__btn-close"
          onClick={() => visiblePopup.set(false)}
        >
          <Icon id="close" />
        </div>
        <div className="modal-window-header">
          <h2 className="modal-window-header__title">
            Add {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias].toLowerCase()}{' '}
            to shopping cart
          </h2>
        </div>
        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text_big uppercase modal-window-body__text_center">
            Would you like to add an engraving to your{' '}
            {PRODUCT_TYPE_TITLE_LIST[currentCategory?.alias].toLowerCase()}?
          </p>

          <div className="modal-window-body-btn-wrap">
            <button
              className="btn btn_lg_popup btn_px-0 btn_bordered-dark-gray btn_stretch"
              onClick={handleShowEngravingTooltip}
            >
              Yes
            </button>
            <button
              className="btn btn_lg_popup btn_px-0 btn_bordered-dark-gray btn_stretch"
              onClick={handleAddToCart}
            >
              No
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
};

export default observer(AddEngravePopup);
