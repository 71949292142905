import React from 'react';
import Svg from '../Svg';

interface IUserIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function UserIcon(props: IUserIconProps): JSX.Element {
  return (
    <Svg width="18" height="20" viewBox="0 0 18 20" {...props}>
      <path
        d="M12.4766 12.375C11.3438 12.375 10.8359 13 9 13C7.125 13 6.61719 12.375 5.48438 12.375C2.59375 12.375 0.25 14.7578 0.25 17.6484V18.625C0.25 19.6797 1.07031 20.5 2.125 20.5H15.875C16.8906 20.5 17.75 19.6797 17.75 18.625V17.6484C17.75 14.7578 15.3672 12.375 12.4766 12.375ZM15.875 18.625H2.125V17.6484C2.125 15.7734 3.60938 14.25 5.48438 14.25C6.07031 14.25 6.96875 14.875 9 14.875C10.9922 14.875 11.8906 14.25 12.4766 14.25C14.3516 14.25 15.875 15.7734 15.875 17.6484V18.625ZM9 11.75C12.0859 11.75 14.625 9.25 14.625 6.125C14.625 3.03906 12.0859 0.5 9 0.5C5.875 0.5 3.375 3.03906 3.375 6.125C3.375 9.25 5.875 11.75 9 11.75ZM9 2.375C11.0312 2.375 12.75 4.09375 12.75 6.125C12.75 8.19531 11.0312 9.875 9 9.875C6.92969 9.875 5.25 8.19531 5.25 6.125C5.25 4.09375 6.92969 2.375 9 2.375Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
