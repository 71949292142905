import { makeAutoObservable } from 'mobx';
import {
  ControlsCollection,
  FormControl,
  FormGroup,
} from '@quantumart/mobx-form-validation-kit';

/**
 * Get all form values with prop name
 * @param controls
 */
export const allFormValues = (
  controls: ControlsCollection,
): { [p: string]: string } => {
  return Object.keys(controls).reduce(
    (o, key) =>
      Object.assign(o, {
        [key]: (controls[key] as FormControl)?.value,
      }),
    {},
  );
};

/**
 * FromStore class
 * Can be expanded if necessary
 */
export default class FormStore<T extends ControlsCollection> {
  formGroup: FormGroup<T>;

  disabled = false;

  private readonly _controlsCollection: T;

  constructor(controlsCollection: T) {
    makeAutoObservable(this);

    this._controlsCollection = controlsCollection;

    this.formGroup = new FormGroup<T>(controlsCollection);
  }

  get allFormValues(): unknown {
    return Object.keys(this.formGroup.controls).reduce(
      (o, key) =>
        Object.assign(o, {
          [key]: (this.formGroup.controls[key] as FormControl)?.value,
        }),
      {},
    );
  }

  resetForm(): void {
    this.formGroup = new FormGroup<T>(this._controlsCollection);
  }

  disableForm(disabled: boolean): void {
    this.disabled = disabled;
  }
}
