import { observable, runInAction } from 'mobx';
import { updateSettings, getSettings } from 'service/api/account';
import { SettingPropsImpl, SettingParamsImpl } from './ProfileSettings.types';
import notificationsService from 'service/notifications/notifications.service';
import {
  ACCOUNT_ERRORS,
  SUCCESS_MESSAGES,
} from 'service/notifications/notifications.constants';

export default class SettingsStore implements SettingPropsImpl {
  allowMailNotifications = observable.box(true);
  sendNews = observable.box(true);

  updateSettingParams = async (): Promise<void> => {
    const params: SettingParamsImpl = {
      allowMailNotifications: this.allowMailNotifications.get(),
      sendNews: this.sendNews.get(),
    };
    try {
      await updateSettings(params);
      notificationsService.notifier.success(
        SUCCESS_MESSAGES.CHANGE_SETTING.message,
        { labels: { warning: SUCCESS_MESSAGES.CHANGE_SETTING.label } },
      );
    } catch (e) {
      notificationsService.notifier.warning(
        ACCOUNT_ERRORS.CHANGE_SETTING.message,
        { labels: { warning: ACCOUNT_ERRORS.CHANGE_SETTING.label } },
      );
    }
  };

  fetchSettings = (): void => {
    getSettings()
      .then(({ allowMailNotifications, sendNews }: SettingParamsImpl) => {
        runInAction(() => {
          this.allowMailNotifications.set(allowMailNotifications);
          this.sendNews.set(sendNews);
        });
      })
      .catch((error) => {
        notificationsService.notifier.warning(
          ACCOUNT_ERRORS.CHANGE_SETTING.message,
          { labels: { warning: ACCOUNT_ERRORS.CHANGE_SETTING.label } },
        );
        throw error;
      });
  };
}
