import PrincessMeasLengthIcon from './PrincessMeasLengthIcon';
import PrincessMeasWidthIcon from './PrincessMeasWidthIcon';
import PrincessMeasDepthIcon from './PrincessMeasDepthIcon';
import PrincessDepthIcon from './PrincessDepthIcon';
import PrincessTableIcon from './PrincessTableIcon';
import PrincessIcon from './PrincessIcon';

export default {
  princess_measLength: PrincessMeasLengthIcon,
  princess_measWidth: PrincessMeasWidthIcon,
  princess_measDepth: PrincessMeasDepthIcon,
  princess_depth: PrincessDepthIcon,
  princess_table: PrincessTableIcon,
  princess: PrincessIcon,
};
