import React from 'react';
import { observer } from 'mobx-react';
import {
  FormControl,
  FormGroup,
  InputFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { CountryImpl, StateImpl } from 'root/store/app';
import { BillingImpl, TProfileFormName } from 'root/store/account/accountTypes';
import CustomSelect from 'components/form/CustomSelect';
import { joinClasses } from 'lib/utils';
import InputForm from 'components/common/InputForm';

const FORM_BILLING = 'billing';

interface IBillingForm {
  billing: FormGroup<BillingImpl>;
  countries: CountryImpl[];
  isEdit: boolean;
  getCurrentCountry: (country: FormControl<number>) => CountryImpl | undefined;
  showErrors: (data: FormControl) => boolean;
  onChangeSelect: (
    formName: TProfileFormName,
    name: string,
    value: CountryImpl | StateImpl,
  ) => void;
}

const BillingForm = (props: IBillingForm) => {
  const {
    billing,
    countries,
    isEdit,
    getCurrentCountry,
    showErrors,
    onChangeSelect,
  } = props;
  const { likeShiping, country, state, city, street, apt, zip } =
    billing.controls;
  const currentCountry = getCurrentCountry(country);
  const states = (currentCountry && currentCountry.states) || [];

  const handleChangeSelect = (
    name: TProfileFormName,
    value: CountryImpl | StateImpl,
  ) => {
    onChangeSelect(FORM_BILLING, name, value);
  };

  return (
    <form className="form" id={FORM_BILLING} noValidate>
      <h3 className="form-title">Billing Address</h3>

      <label
        className={joinClasses([
          'checkbox-container checkbox-container_mt-30',
          !isEdit && 'checkbox-container_disabled',
        ])}
      >
        Same to Shipping address
        <input
          type="checkbox"
          name="likeShiping"
          data-form={FORM_BILLING}
          checked={likeShiping.value}
          disabled={!isEdit}
          {...InputFormControl.bindActions(likeShiping)}
        />
        <span className="checkmark"></span>
      </label>

      <p className="attention-message attention-message_mt-20 ">
        In order to ship to a different address other than your billing address,
        you must first add it with your credit card company. If you have any
        questions, please feel free to call us at +1-877-382-6275.
      </p>

      {!likeShiping.value && (
        <>
          <div>
            <CustomSelect
              name="country"
              label="Country *"
              form={FORM_BILLING}
              values={countries}
              defaultValue={country.value}
              onChange={handleChangeSelect}
              disabled={!isEdit}
            />
            {country.invalid && country.touched
              ? country.errors.map((error) => (
                  <div
                    className="form-label__error form-label__error_blocky"
                    key={error.key}
                  >
                    {error.message}
                  </div>
                ))
              : null}
          </div>

          {!!states.length && (
            <div>
              <CustomSelect
                name="state"
                label="State *"
                form={FORM_BILLING}
                values={states}
                defaultValue={state.value}
                onChange={handleChangeSelect}
                disabled={!isEdit}
              />
              {state.invalid && state.touched
                ? state.errors.map((error) => (
                    <div
                      className="form-label__error form-label__error_blocky"
                      key={error.key}
                    >
                      {error.message}
                    </div>
                  ))
                : null}
            </div>
          )}

          <InputForm
            name="city"
            label="City *"
            dataForm={FORM_BILLING}
            className={joinClasses([
              'autofill-bg-beige',
              city.value && 'shrink',
            ])}
            data={city}
            showErrors={showErrors(city)}
            disabled={!isEdit}
            {...InputFormControl.bindActions(city)}
          />

          <InputForm
            name="street"
            label="Street Address *"
            dataForm={FORM_BILLING}
            className={joinClasses([
              'autofill-bg-beige',
              street.value && 'shrink',
            ])}
            data={street}
            showErrors={showErrors(street)}
            disabled={!isEdit}
            {...InputFormControl.bindActions(street)}
          />

          <InputForm
            name="apt"
            label="Apt/Unit"
            dataForm={FORM_BILLING}
            className={joinClasses([
              'autofill-bg-beige',
              apt.value && 'shrink',
            ])}
            data={apt}
            showErrors={!!apt.value && showErrors(apt)}
            disabled={!isEdit}
            {...InputFormControl.bindActions(apt)}
          />

          <InputForm
            name="zip"
            label="ZIP Code *"
            dataForm={FORM_BILLING}
            className={joinClasses([
              'autofill-bg-beige',
              zip.value && 'shrink',
            ])}
            data={zip}
            showErrors={showErrors(zip)}
            disabled={!isEdit}
            {...InputFormControl.bindActions(zip)}
          />
        </>
      )}
    </form>
  );
};

export default observer(BillingForm);
