import React from 'react';
import Svg from '../Svg';

interface UploadIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function UploadIcon(props: UploadIconProps): JSX.Element {
  return (
    <Svg viewBox="0 0 38 27" width="38" height="27" {...props}>
      <path
        d="M23.1445 14.4531L17.3438 8.65234C17.0508 8.35938 16.6406 8.35938 16.3477 8.65234L10.5469 14.4531C10.0781 14.9219 10.4297 15.625 11.0742 15.625H15V20.5469C15 20.957 15.293 21.25 15.7031 21.25H18.0469C18.3984 21.25 18.75 20.957 18.75 20.5469V15.625H22.6758C23.2617 15.625 23.6133 14.9219 23.1445 14.4531ZM31.8164 10.5273C31.582 7.12891 28.7695 4.375 25.3125 4.375C24.8438 4.375 24.375 4.43359 23.9648 4.55078C22.0898 2.20703 19.2188 0.625 15.9375 0.625C10.957 0.625 6.79688 4.14062 5.80078 8.82812C2.28516 10.293 0 13.6914 0 17.5C0 22.7148 4.16016 26.875 9.375 26.875H29.0625C33.6914 26.875 37.5 23.125 37.5 18.4375C37.5 14.8633 35.1562 11.6992 31.8164 10.5273ZM29.0625 24.0625H9.375C5.74219 24.0625 2.8125 21.1328 2.8125 17.5C2.8125 14.2188 5.21484 11.4648 8.4375 11.0547V10.9375C8.4375 6.83594 11.7773 3.4375 15.9375 3.4375C19.043 3.4375 21.7383 5.37109 22.8516 8.125C23.4961 7.53906 24.375 7.1875 25.3125 7.1875C27.3633 7.1875 29.0625 8.88672 29.0625 10.9375C29.0625 11.6406 28.8281 12.2852 28.5352 12.8711C28.7109 12.8711 28.8867 12.8125 29.0625 12.8125C32.168 12.8125 34.6875 15.332 34.6875 18.4375C34.6875 21.543 32.168 24.0625 29.0625 24.0625Z"
        fill="white"
      />
    </Svg>
  );
}
