import React from 'react';
import Svg from '../Svg';

interface IPhoneDarkIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PhoneDarkIcon(props: IPhoneDarkIconProps): JSX.Element {
  return (
    <Svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M20.2578 1.47656L16.1953 0.539062C15.7656 0.421875 15.2969 0.65625 15.1016 1.08594L13.2266 5.46094C13.0703 5.85156 13.1875 6.28125 13.5 6.55469L15.8828 8.50781C14.4766 11.4766 12.0156 13.9766 8.96875 15.4219L7.01562 13.0391C6.74219 12.7266 6.3125 12.6094 5.92188 12.7656L1.54688 14.6406C1.11719 14.8359 0.921875 15.3047 1 15.7344L1.9375 19.7969C2.05469 20.2266 2.40625 20.5 2.875 20.5C12.875 20.5 21 12.4141 21 2.375C21 1.94531 20.6875 1.59375 20.2578 1.47656Z"
        fill="#1C1C1F"
      />
    </Svg>
  );
}
