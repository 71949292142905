import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuItemLinksProps } from '../FooterTypes';

const MenuItemLinks = ({ menuItem }: MenuItemLinksProps): JSX.Element => {
  const { pathname } = useLocation();
  return (
    <div className="footer__column-content">
      <label className="footer__column-title">{menuItem.text}</label>
      {menuItem?.items?.map(({ link, text }) => (
        <Link
          key={link}
          to={{
            pathname: link,
            state: {
              from: pathname,
              pageTitle: pathname.split('/')?.[1],
            },
          }}
          className="footer__column-items"
        >
          {text}
        </Link>
      ))}
    </div>
  );
};

export default MenuItemLinks;
