import ClarityDiamond01 from './ClarityDiamond01';
import ClarityDiamond02 from './ClarityDiamond02';
import ClarityDiamond03 from './ClarityDiamond03';
import ClarityDiamond04 from './ClarityDiamond04';
import ClarityDiamond05 from './ClarityDiamond05';
import ClarityDiamond06 from './ClarityDiamond06';

export default {
  clarity_01: ClarityDiamond01,
  clarity_02: ClarityDiamond02,
  clarity_03: ClarityDiamond03,
  clarity_04: ClarityDiamond04,
  clarity_05: ClarityDiamond05,
  clarity_06: ClarityDiamond06,
};
