export const imagesPaths = [
  '/background_photo',
  '/diamond1',
  '/diamond2',
  '/ring1',
  '/ring2',
  '/br1',
  '/br2_1',
  '/br2_2',
  '/br3',
  '/br4',
  '/design1',
  '/design2',
];
