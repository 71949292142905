import { IObservableArray, makeAutoObservable, observable, toJS } from 'mobx';
import { FilterRange } from 'root/store/products/filterRange.store';
import {
  IFilterRingsExtraParams,
  QueryParamsEngagementRingFilters,
} from '../types';

export enum EngagementRingsFilterParams {
  PRICE = 'price',
  METAL = 'metal',
  RING_STYLE = 'ringStyle',
  STONE_SHAPE = 'stoneShape',
}

export class EngagementRingsFiltersStore {
  // set of filter fields, please synchronize with enumeration EngagementRingsFilterParams
  price!: FilterRange;
  metal: IObservableArray = observable([]);
  stoneShape: IObservableArray = observable([]);
  ringStyle: IObservableArray = observable([]);

  category = 'ENGAGEMENT_RINGS';

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  get queryParams(): EngagementRingsFiltersStore {
    return toJS(this);
  }

  update(filters: QueryParamsEngagementRingFilters): void {
    const { price, metal, ringStyle, stoneShape } = filters;
    price && this.price.setRange(Number(price.from), Number(price.to));
    stoneShape && this.stoneShape.replace(stoneShape);
    metal && this.metal.replace(metal);
    ringStyle && this.ringStyle.replace(ringStyle);
  }

  updateByExtraParams(params: IFilterRingsExtraParams) {
    const { price } = params;

    this.price.setRange(Number(price?.min) || 0, Number(price?.max) || 999999);
  }

  reset() {
    this.price = new FilterRange(0, 999999);
    this.metal.clear();
    this.ringStyle.clear();
    this.stoneShape.clear();
  }
}
