import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function ClarityDiamond04(props: IProps): JSX.Element {
  return (
    <Svg width="104" height="72" viewBox="0 0 104 72" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M51.95 71 103 26.554l-.302-5.656-9.465-10.202-17.42-7.374L51.95 1 28.187 3.323l-17.52 7.374-9.365 10.202L1 26.555 51.95 71Z"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m51.95 71 4.833-12.929 13.694-18.687L87.796 30.9l3.625-11.111-6.847-10.404-8.76-6.06"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m84.574 9.385-8.56 5.252-24.064 2.121 15.909 7.374L87.796 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m76.015 14.637-8.156 9.495-15.91 11.515M91.42 19.788l-15.405-5.151M51.95 35.647l18.527 3.737M51.95 40.092l18.325-6.06 16.111 1.615 10.472-6.565L103 26.557M96.858 29.082 87.796 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m103 26.555-11.58-6.768 5.438 9.293M93.233 10.697 103 26.556M84.574 9.385l8.66 1.313M67.859 24.13l2.416 9.9M86.386 35.647 72.39 53.222M56.783 58.07l29.603-22.423M51.95 40.09l4.833 17.98M51.95 71l-4.834-12.929-13.593-18.687L16.204 30.9l-3.726-11.111 6.848-10.404 8.86-6.06"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m19.326 9.385 8.559 5.252 24.065 2.121-15.91 7.374L16.206 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m27.885 14.637 8.156 9.495L51.95 35.647M12.479 19.788l15.405-5.151M51.95 35.647l-18.427 3.737M51.95 40.092l-18.225-6.06-16.212 1.615-10.472-6.565L1 26.557M7.042 29.082l9.162 1.818"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m1 26.555 11.479-6.768L7.04 29.08M10.666 10.697 1 26.556M19.326 9.385l-8.66 1.313M36.04 24.13l-2.315 9.9M17.513 35.647 31.51 53.222M47.116 58.07 17.513 35.648M51.95 40.09l-4.834 17.98"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m39 5.6.4 5.9 2.2-.2-.3-5.7H39ZM78.6 19.2l1.9 5.9 2.3-.8-1.3-5.7-2.9.6Z"
        fill="#54A97E"
      />
    </Svg>
  );
}
