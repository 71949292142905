import { IObservableValue, makeAutoObservable, observable } from 'mobx';
import { FormControl, FormGroup } from '@quantumart/mobx-form-validation-kit';
import { ValueTypeImpl } from './components/FormFriendContactItem/FormFriendContactItem.types';
import {
  emailFieldConfig,
  messageFieldConfig,
  nameFieldConfig,
} from './Hint.config';
import type {
  ClientFormImpl,
  FormValueImpl,
  FriendFormImpl,
} from './Hint.types';
import { TProductHint } from 'pages/HintPage/HintPage.types';

export default class HintStore {
  disable = observable.box(false);
  status = observable.box('');
  errorsFromServer: IObservableValue<string[]> = observable.box([]);
  valueOfTypeOfContacts: IObservableValue<ValueTypeImpl> =
    observable.box('email');

  constructor() {
    makeAutoObservable(this);
  }

  clientForm = new FormGroup<ClientFormImpl>({
    name: new FormControl<string>('', nameFieldConfig),
    email: new FormControl<string>('', emailFieldConfig),
    message: new FormControl<string>('', messageFieldConfig),
  });

  friendsForm = [
    new FormGroup<FriendFormImpl>({
      name: new FormControl<string>('', nameFieldConfig),
      email: new FormControl<string>('', emailFieldConfig),
    }),
  ];

  addFriend = (): void => {
    this.friendsForm.push(
      new FormGroup<FriendFormImpl>({
        name: new FormControl<string>('', nameFieldConfig),
        email: new FormControl<string>('', emailFieldConfig),
      }),
    );
  };

  deleteFriend = (index: number): void => {
    this.friendsForm.splice(index, 1);
  };

  formValues = (product: TProductHint): FormValueImpl => {
    return {
      name: this.clientForm.controls.name.value,
      email: this.clientForm.controls.email.value,
      message: this.clientForm.controls.message.value,
      friends: this.friendsForm.map((friend: FormGroup<FriendFormImpl>) => ({
        name: friend.controls.name.value,
        email: friend.controls.email.value,
      })),
      item: {
        ...product,
        price: product.price,
      },
    };
  };

  changeStatus = (status: string): void => {
    this.status.set(status);
  };

  resetForm = (): void => {
    this.clientForm.controls.name.value = '';
    this.clientForm.controls.name.setTouched(false);
    this.clientForm.controls.email.value = '';
    this.clientForm.controls.email.setTouched(false);
    this.clientForm.controls.message.value = '';
    this.clientForm.controls.message.setTouched(false);
    this.friendsForm.forEach((friend) => {
      friend.controls.name.value = '';
      friend.controls.name.setTouched(false);
      friend.controls.email.value = '';
      friend.controls.email.setTouched(false);
    });
  };

  disableForm = (isDisable: boolean): void => {
    this.disable.set(isDisable);
  };

  setErrorsFromServer = (errors: string[]): void => {
    this.errorsFromServer.set(errors);
  };

  handleChangeTypeOfContacts = (value: ValueTypeImpl): void => {
    this.valueOfTypeOfContacts.set(value);
  };
}
