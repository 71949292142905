import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { gsap } from 'gsap';
import { getImg } from 'lib/utils';
import useElementViewed from '../../helpers/useElementViewed';
import ImageItem from 'components/common/ImageItem';
import { imagesPaths } from '../../constants';

import './Brighter.scss';

interface IProps {
  setAnimatedIndex: (index: number) => void;
  animatedIndex: Record<number, boolean>;
}

export function Brighter({
  setAnimatedIndex,
  animatedIndex,
}: IProps): JSX.Element {
  const [animated, setAnimated] = useState(false);

  const handleAnimate = () => {
    if (!animated && !animatedIndex[6]) {
      setAnimated(true);
      gsap.fromTo('.br1', { y: 50 }, { y: 0, duration: 1, delay: 1 });
      gsap.fromTo(
        '.br2_1',
        { y: -20, x: 20 },
        { y: 0, x: 0, duration: 1, delay: 1 },
      );
      gsap.fromTo(
        '.br2_2',
        { y: 50, x: -50 },
        { y: 0, x: 0, duration: 1, delay: 1 },
      );
      gsap.fromTo(
        '.br3',
        { y: -50, x: -100 },
        { y: 0, x: 0, duration: 1, delay: 1 },
      );
      gsap.fromTo(
        '.br4',
        { y: 100, x: 50 },
        { y: 0, x: 0, duration: 1, delay: 1 },
      );
    }
    setAnimatedIndex(5);
  };

  const { elementRef } = useElementViewed({
    callback: handleAnimate,
    isActiveListener: true,
    threshold: 0.2,
  });

  return (
    <div className="main-slide  main-slide_brighter" ref={elementRef}>
      <div className="content-main_block" id="brighter">
        <div className="content-main_block__title">
          A brighter start to forever
        </div>
        <div className="content-main_block__description">
          A collection of our customers' most special moment.
        </div>

        <div className="content-main_block__brighter">
          <div className="images-block">
            <ImageItem
              className="br1"
              src={getImg('mainPage', imagesPaths, `/br1`)}
            />
          </div>
          <div className="images-block">
            <ImageItem
              className="br2_1"
              src={getImg('mainPage', imagesPaths, `/br2_1`)}
            />
            <ImageItem
              className="br2_2"
              src={getImg('mainPage', imagesPaths, `/br2_2`)}
            />
          </div>
          <div className="images-block">
            <ImageItem
              className="br3"
              src={getImg('mainPage', imagesPaths, `/br3`)}
            />
          </div>
          <div className="images-block">
            <ImageItem
              className="br4"
              src={getImg('mainPage', imagesPaths, `/br4`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Brighter);
