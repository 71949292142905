import {
  getAffirmJSPublicApiKey,
  getAffirmJS,
  removeAffirmJS,
} from './wrapper';
import store from '../../root/store';
import CartStore from '../../root/store/cart';
import { getPathnameByCategories } from '../utils';
import { IAffirmCheckout } from './affirm.checkout.types';
import { CountryImpl, StateImpl } from '../../root/store/app';
import { getProductImage } from '../../utils';

/**
 * Get country object and state object by country id and state id
 * @param {number} countryId
 * @param {number} stateId
 * @returns {[CountryImpl | undefined, StateImpl | undefined]} [ country, state ]
 */
const getCountryAndState = (
  countryId: number,
  stateId: number,
): [CountryImpl | undefined, StateImpl | undefined] => {
  const country = store.app.countries.find(
    (country) => country.id === countryId,
  );
  const state = country?.states.find((state) => state.id === stateId);
  return [country, state];
};

/**
 * Prepare request data to affirm checkout format
 * @param {CartStore} cartStore
 * @param {any} data - request data
 * @returns {IAffirmCheckout} checkout data
 */
const prepareCheckoutData = (
  cartStore: CartStore,
  data: any,
): IAffirmCheckout => {
  const [shippingCountry, shippingState] = getCountryAndState(
    data.delivery.country.id,
    data.delivery.state?.id,
  );
  const [billingCountry, billingState] = getCountryAndState(
    data.payment.country.id,
    data.payment.state?.id,
  );

  const checkoutData: IAffirmCheckout = {
    order_id: '',

    merchant: {
      user_confirmation_url: '',
      user_cancel_url: '',
      public_api_key: getAffirmJSPublicApiKey(),
    },

    metadata: {
      mode: 'modal',
    },

    currency: cartStore.currency.iso as 'USD',
    shipping_amount: Math.round(cartStore.shippingAmount * 100),
    tax_amount: Math.round(cartStore.taxAmount * 100),
    total: Math.round(cartStore.totalAmount * 100),

    shipping: {
      name: {
        first: data.delivery.firstName,
        last: data.delivery.lastName,
      },
      address: {
        line1: data.delivery.address,
        line2: data.delivery.aptUnit,
        city: data.delivery.city,
        state: String(shippingState?.iso),
        zipcode: data.delivery.zipCode,
        country: String(shippingCountry?.iso2),
      },
      phone_number: data.delivery.phone,
      email: data.delivery.email,
    },
    billing: {
      name: {
        first: data.customer.firstName,
        last: data.customer.lastName,
      },
      address: {
        line1: data.payment.address,
        line2: data.payment.aptUnit,
        city: data.payment.city,
        state: String(billingState?.iso),
        zipcode: data.payment.zipCode,
        country: String(billingCountry?.iso2),
      },
      phone_number: data.customer.phone,
      email: data.customer.email,
    },

    items: cartStore.items.map((item) => ({
      display_name: item.sku,
      sku: item.sku,
      unit_price: Math.round(Number(item.priceSetValue) * 100),
      qty: item.quantity,
      item_image_url:
        getProductImage(item)[0] === '/'
          ? `${window.location.origin}${getProductImage(item)}`
          : getProductImage(item),
      item_url:
        `${window.location.origin}` +
        `${getPathnameByCategories(item.categories)}/${item.id}` +
        `?guid=${item.guid}`,
      categories: item.categories.map((category) => category.name),
    })),
  };

  if (cartStore.totalWithPromocodeAmount !== null) {
    const discountAmount = Math.round(
      (cartStore.totalAmount - Number(cartStore.totalWithPromocodeAmount)) *
        100,
    );

    checkoutData.discounts = {
      [data.promocode]: {
        discount_amount: discountAmount,
        discount_display_name: `Discount amount: ${cartStore.currency.format(
          discountAmount / 100,
        )}`,
      },
    };
  }

  return checkoutData;
};

/**
 * Open AffirmJS modal checkout
 * @returns checkout token
 */
export const checkout = async (
  ...args: Parameters<typeof prepareCheckoutData>
) => {
  const affirm = getAffirmJS();
  const checkoutData = prepareCheckoutData(...args);

  const promise: Promise<{ token: string } | null> = new Promise(
    (resolve, reject) => {
      // const timeoutId = setTimeout(() => {
      //   reject('Timeout');
      // }, 15000);

      affirm.jsReady(() => {
        affirm.checkout(checkoutData);

        affirm.checkout.open({
          onFail: () => {
            resolve(null);
          },
          onSuccess: (data: any) => {
            resolve({ token: data.checkout_token });
          },
          onOpen: (checkout_token: string) => {
            // clearTimeout(timeoutId);
          },
          onValidationError: () => {
            resolve(null);
          },
        });
      });
    },
  );

  return await promise;
};

/**
 * Clear AffirmJS from global
 */
export const clear = removeAffirmJS;
