import React from 'react';
import { observer } from 'mobx-react';
import * as FILTER from './constants';
import Grid from 'components/common/Filters/Grid';
import Range from 'components/common/Filters/Range';
// import { SearchCustomSelect } from 'components/form/SearchCustomSelect/SearchCustomSelect';
import { JewelryFiltersStore } from '../../store/jewelryFilters.store';
import { IFilterJewerlyExtraParams } from '../../types';
import Select from '../../../../components/common/Filters/Select';

interface PanelProps {
  filters: JewelryFiltersStore;
  filtersExtra: IFilterJewerlyExtraParams;
  handleFilters: () => void;
}

export const Panel = observer((props: PanelProps) => {
  const {
    filtersExtra: { price },
  } = props;

  const clearFilters = (): void => {
    props.filters.reset();
  };

  return (
    <div className="grid-area-filters">
      <div className="filters-header">
        <h1 className="page-title">Filter</h1>
        <div className="btn btn_default btn_text-big" onClick={clearFilters}>
          Clear filters
        </div>
      </div>
      {/* TODO: need condition for promo */}
      {/* <Select
        title="Recommended as Mother's day Gift"
        list={FILTER.PROMOS}
        columns={3}
        values={props.filters.promo}
        icon={false}
        onAfterChange={props.handleFilters}
      ></Select> */}

      <Grid
        title="Category"
        list={FILTER.CATEGORIES}
        columns={3}
        values={props.filters.categories}
        icon={false}
        onAfterChange={props.handleFilters}
      />
      <Range
        title="Price"
        label="$"
        max={price?.max ? Number(price?.max) : undefined}
        min={price?.min ? Number(price?.min) : undefined}
        step={0.01}
        range={props.filters.price}
        onAfterChange={props.handleFilters}
      />
      <Grid
        title="Metal"
        list={FILTER.METALS}
        columns={3}
        values={props.filters.metal}
        icon={false}
        onAfterChange={props.handleFilters}
      />
      {/* Note: VEN-1225 */}
      {/* <Grid
        title="Collection"
        list={FILTER.COLLECTION}
        columns={3}
        values={props.filters.collection}
        icon={false}
        onAfterChange={props.handleFilters}
      /> */}
    </div>
  );
});
