import React from 'react';
import Svg from '../Svg';

interface IInfinityIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function InfinityIcon(props: IInfinityIconProps): JSX.Element {
  return (
    <Svg width="18" height="10" viewBox="0 0 18 10" fill="none" {...props}>
      <path
        d="M13.7344 0.375C11.6289 0.375 10.043 2.26172 9.25 3.46484C8.42969 2.26172 6.87109 0.375 4.73828 0.375C2.38672 0.375 0.5 2.34375 0.5 4.75C0.5 7.18359 2.38672 9.125 4.73828 9.125C6.87109 9.125 8.42969 7.26562 9.25 6.0625C10.043 7.26562 11.6289 9.125 13.7344 9.125C16.0859 9.125 18 7.18359 18 4.75C18 2.34375 16.0859 0.375 13.7344 0.375ZM4.73828 7.8125C3.125 7.8125 1.8125 6.44531 1.8125 4.75C1.8125 3.08203 3.125 1.6875 4.73828 1.6875C6.59766 1.6875 8.04688 3.95703 8.48438 4.75C8.04688 5.57031 6.59766 7.8125 4.73828 7.8125ZM13.7344 7.8125C11.875 7.8125 10.4258 5.57031 9.98828 4.75C10.4531 3.95703 11.875 1.6875 13.7344 1.6875C15.3477 1.6875 16.6875 3.08203 16.6875 4.75C16.6875 6.44531 15.3477 7.8125 13.7344 7.8125Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
