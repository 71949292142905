import * as React from 'react';
import { observer } from 'mobx-react';

interface IPaginationItemProps {
  active?: boolean;
  onClick: React.MouseEventHandler<HTMLLIElement>;
  children?: React.ReactNode;
  ellipsis?: boolean;
}

@observer
class PaginationItem extends React.Component<IPaginationItemProps> {
  render(): JSX.Element {
    const { active, onClick, children, ellipsis } = this.props;

    return (
      <li className={active ? 'active' : ''} onClick={onClick}>
        {active ? (
          <span>{children}</span>
        ) : (
          <a role="button" href="#">
            {children}
            {ellipsis ? <span aria-hidden="true">…</span> : null}
          </a>
        )}
      </li>
    );
  }
}

export default PaginationItem;
