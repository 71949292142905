import * as React from 'react';
import { observer } from 'mobx-react';
import { CartItemStoneImpl } from 'root/store/cart/item.store';
import ProductRow from './ProductRow';

interface DiamondTemplateProps {
  product: CartItemStoneImpl;
}

const DIAMONDS_PROPERTIES = {
  firstColumn: ['carat', 'shape', 'cut', 'polish', 'color'],
  secondColumn: ['fluorescence', 'certificate', 'symmetry', 'table', 'depth'],
};

export const DiamondTemplate = observer((props: DiamondTemplateProps) => {
  const { product } = props;
  return <ProductRow product={product} properties={DIAMONDS_PROPERTIES} />;
});
