import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import {
  BillingResponseImpl,
  CustomerResponseImpl,
  ShippingResponseImpl,
} from 'service/api/apiTypes/accountApiTypes';
import { OrderDetailsImpl, OrderItemDetailImpl } from 'root/store/orders/types';
import { getOrderDetails } from 'service/api/orders';
import { ProductDetailImpl, StoneInfoImpl } from 'root/ProductTypes';
import { CartItemFactory } from 'root/store/cart/item.store';

export default class OrdersModalStore {
  createDate = '';
  shipping = 0;
  total = 0;
  id = 0;
  customer = {} as any;
  delivery = {} as any;
  payment = {} as any;

  items: IObservableArray<ProductDetailImpl> = observable.array([], {
    deep: false,
  });

  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  setCommonInfoData(orderDetails: OrderDetailsImpl): void {
    this.createDate = orderDetails.createDate;
    this.shipping = orderDetails.shipping;
    this.total = orderDetails.total;
    this.id = orderDetails.id;
  }

  setCustomerInfo(customer: any): void {
    this.customer = customer;
  }

  setDeliveryInfo(delivery: any): void {
    this.delivery = delivery;
  }

  setPaymentInfo(payment: any): void {
    this.payment = payment;
  }

  setOrderInfo(items: OrderItemDetailImpl[]): void {
    this.items.replace(
      items
        .map((item: OrderItemDetailImpl) =>
          item.shape === 'Cushion Modified'
            ? { ...item, shape: 'Cushion' }
            : item,
        )
        .map(CartItemFactory.getItem),
    );
  }

  *loadCurrentOrders(
    orderId: string,
  ): Generator<Promise<OrderDetailsImpl>, void, OrderDetailsImpl> {
    const orderDetails = yield getOrderDetails(orderId);
    this.setOrderInfo(orderDetails.items);
    this.setCommonInfoData(orderDetails);

    this.setCustomerInfo(orderDetails.customer);
    this.setDeliveryInfo(orderDetails.delivery);
    this.setPaymentInfo(orderDetails.payment);
  }

  resetOrderInfo() {
    this.createDate = '';
    this.customer = {} as CustomerResponseImpl;
    this.delivery = {} as ShippingResponseImpl;
    this.payment = {} as BillingResponseImpl;
    this.items = observable.array([], {
      deep: false,
    });
    this.loading = true;
  }

  setLoading(value: boolean) {
    this.loading = value;
  }
}
