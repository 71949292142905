import React from 'react';
import { observer } from 'mobx-react';
import { RecipientImpl } from 'root/store/account/accountTypes';
import InputForm from 'components/common/InputForm';
import { joinClasses } from 'lib/utils';
import {
  FormControl,
  FormGroup,
  InputFormControl,
} from '@quantumart/mobx-form-validation-kit';

const FORM_RECIPIENT = 'recipient';

interface IRecipientForm {
  recipient: FormGroup<RecipientImpl>;
  isEdit: boolean;
  showErrors: (data: FormControl) => boolean;
}

const RecipientForm = (props: IRecipientForm) => {
  const { recipient, isEdit, showErrors } = props;
  const { isDifferent, firstName, lastName, email, phone } = recipient.controls;

  return (
    <form className="form" id={FORM_RECIPIENT} noValidate>
      <h3 className="form-title">Recipient</h3>

      <label
        className={joinClasses([
          'checkbox-container checkbox-container_mt-30',
          !isEdit && 'checkbox-container_disabled',
        ])}
      >
        Different Recipient
        <input
          type="checkbox"
          name="isDifferent"
          data-form={FORM_RECIPIENT}
          checked={isDifferent.value}
          disabled={!isEdit}
          {...InputFormControl.bindActions(isDifferent)}
        />
        <span className="checkmark" />
      </label>

      <p className="attention-message attention-message_mt-20 ">
        You can assign different recepient if needed
      </p>

      {isDifferent.value && (
        <>
          <InputForm
            name="firstName"
            label="First Name *"
            dataForm={FORM_RECIPIENT}
            className={joinClasses([
              'autofill-bg-beige',
              firstName.value && 'shrink',
            ])}
            data={firstName}
            showErrors={showErrors(firstName)}
            disabled={!isEdit}
            {...InputFormControl.bindActions(firstName)}
          />

          <InputForm
            name="lastName"
            label="Last Name *"
            dataForm={FORM_RECIPIENT}
            className={joinClasses([
              'autofill-bg-beige',
              lastName.value && 'shrink',
            ])}
            data={lastName}
            showErrors={showErrors(lastName)}
            disabled={!isEdit}
            {...InputFormControl.bindActions(lastName)}
          />

          <InputForm
            name="email"
            label="E-mail *"
            dataForm={FORM_RECIPIENT}
            className={joinClasses([
              'autofill-bg-beige',
              email.value && 'shrink',
            ])}
            data={email}
            showErrors={showErrors(email)}
            disabled={!isEdit}
            {...InputFormControl.bindActions(email)}
          />

          <InputForm
            name="phone"
            label="Recipient mobile phone *"
            dataForm={FORM_RECIPIENT}
            className={joinClasses([
              'autofill-bg-beige',
              phone.value && 'shrink',
            ])}
            data={phone}
            showErrors={showErrors(phone)}
            disabled={!isEdit}
            {...InputFormControl.bindActions(phone)}
          />
        </>
      )}
    </form>
  );
};

export default observer(RecipientForm);
