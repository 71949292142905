import React from 'react';
import { observer } from 'mobx-react';
import { computed, IObservableValue, observable } from 'mobx';
import { NavLink } from 'react-router-dom';
import { joinClasses } from 'lib/utils';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import { LoginContainerPropsImpl } from './LoginContainer.types';

import './LoginContainer.scss';

const SIGN_IN = 'signin';
const SIGN_UP = 'signup';

function LoginContainer(props: LoginContainerPropsImpl) {
  const { location } = props;
  const currentPage = computed(() => {
    return location.pathname.includes(SIGN_IN) ? SIGN_IN : SIGN_UP;
  });
  const formStatus: IObservableValue<boolean> = observable.box(
    location.state ? location.state.formStatus : false,
  );

  return (
    <div className="content grid">
      <h1 className="grid-area-page-title page-title">
        Create Account <br /> or Sign In
      </h1>

      <div className="grid-area-info-bar">
        <div className="tabbed">
          <ul className={joinClasses(['tabs', formStatus && 'closed'])}>
            <li className="tab tabs__item">
              <label
                className={joinClasses([
                  currentPage.get() === SIGN_IN && 'active',
                ])}
              >
                <NavLink
                  className="tabs__link"
                  to={{
                    pathname: '/auth/signin',
                    state: { formStatus: true },
                  }}
                >
                  Sign In
                </NavLink>
              </label>
            </li>
            <li className="tab tabs__item">
              <label
                className={joinClasses([
                  currentPage.get() === SIGN_UP && 'active',
                ])}
              >
                <NavLink
                  className="tabs__link"
                  to={{
                    pathname: '/auth/signup',
                    state: { formStatus: true },
                  }}
                >
                  Create Customer Account
                </NavLink>
              </label>
            </li>
          </ul>

          {currentPage.get() === SIGN_UP ? (
            <SignUp location={location} />
          ) : (
            <SignIn location={location} />
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(LoginContainer);
