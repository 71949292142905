import React from 'react';
import Svg from '../Svg';

interface IEyeIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function EyeIcon(props: IEyeIconProps): JSX.Element {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <path
        d="M9 7c1.094 0 2-.875 2-2 0-1.094-.906-2-2-2-.031 0-.063.031-.094.031.031.125.094.344.094.5 0 .813-.688 1.5-1.5 1.5-.156 0-.375-.062-.5-.093V5c0 1.125.875 2 2 2Zm8.875-1.438C16.187 2.25 12.812 0 9 0 5.156 0 1.781 2.25.094 5.563a1.142 1.142 0 0 0 0 .906C1.78 9.78 5.156 12 9 12c3.813 0 7.188-2.219 8.875-5.531a1.142 1.142 0 0 0 0-.907ZM9 1c2.188 0 4 1.813 4 4 0 2.219-1.813 4-4 4-2.219 0-4-1.781-4-4 0-2.188 1.781-3.969 4-4Zm0 10c-3.375 0-6.438-1.906-8-5 .75-1.469 2.563-3.281 4.031-4.031A4.933 4.933 0 0 0 4 5c0 2.781 2.219 5 5 5 2.75 0 5-2.219 5-5a4.96 4.96 0 0 0-1.063-3.031C14.408 2.719 16.22 4.53 17 6c-1.594 3.094-4.656 5-8 5Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
