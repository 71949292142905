import React from 'react';
import Svg from '../Svg';

interface SpellCheckIcom {
  children?: React.ReactNode;
}

export default function OutsideBand(props: SpellCheckIcom): JSX.Element {
  return (
    <Svg width="21" height="19" viewBox="0 0 21 19" fill="none" {...props}>
      <path
        d="M10 3C3.39844 3 0 5.53906 0 8V12.4922C0 15.5391 4.45312 18 10 18C15.5078 18 20 15.5391 20 12.4922V8C20 5.53906 16.5625 3 10 3ZM18.75 12.4922C18.75 14.5234 15.1562 16.75 10 16.75C4.80469 16.75 1.25 14.5234 1.25 12.4922V10.5391C2.77344 11.9453 5.70312 13 10 13C14.2578 13 17.1875 11.9453 18.75 10.5391V12.4922ZM3.47656 10.5C5.07812 9.75781 7.38281 9.25 10 9.25C12.5781 9.25 14.8828 9.75781 16.4844 10.5C14.8828 11.2812 12.5781 11.75 10 11.75C7.38281 11.75 5.07812 11.2812 3.47656 10.5ZM17.7344 9.75781C16.0547 8.74219 13.4766 8 10 8C6.48438 8 3.90625 8.74219 2.22656 9.75781C1.60156 9.25 1.25 8.66406 1.25 8C1.25 5.92969 5.15625 4.25 10 4.25C14.8047 4.25 18.75 5.92969 18.75 8C18.75 8.66406 18.3594 9.25 17.7344 9.75781Z"
        fill="#4D4048"
      />
      <path
        d="M17.501 10.5C10.301 6.90004 4.16765 9.00004 2.00098 10.5C1.00099 9.49708 0.500759 8.00166 1.00085 7C3.41639 2.16183 14.001 2.49704 18.501 6.00003C20.8343 8.16652 18.001 9.99959 17.501 10.5Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
