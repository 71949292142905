import { makeAutoObservable, observable } from 'mobx';
import { GlobalSearchStoreImpl } from './GlobalSearchTypes';
import { fetchGlobalSearch } from 'service/api/products';
import { CartItemFactory, CartItemImpl } from 'root/store/cart/item.store';
import { getCurrentCategory } from 'lib/utils';
import { ProductInfoImpl } from 'root/ProductTypes';

export class GlobalSearchStore implements GlobalSearchStoreImpl {
  visible = false;
  disable = false;
  searchQ = '';
  searchResults = observable({});
  limitFilters = observable({} as { [key: string]: boolean });

  constructor() {
    makeAutoObservable(this);
  }

  visibleModal(visible: boolean): void {
    this.visible = visible;
  }

  resetSearchQ() {
    this.searchQ = '';
  }

  async setSearchQ(q: string) {
    this.searchQ = q;
    const results = await this.getGlobalSearchResults();
    this.updateResults(results);
  }

  updateResults(results: ProductInfoImpl[]) {
    const productsGroupByType = results
      .map(CartItemFactory.getItem)
      .reduce((acc, curProduct: CartItemImpl) => {
        const currentCategory = getCurrentCategory(curProduct.categories);
        const categoryTitle =
          currentCategory && currentCategory.length
            ? currentCategory[0].replace(/_/g, ' ')
            : 'Other results';
        if (acc[categoryTitle]) {
          acc[categoryTitle].push(curProduct);
        } else {
          this.limitFilters[categoryTitle] = false;
          acc[categoryTitle] = [curProduct];
        }
        return acc;
      }, {});
    this.searchResults = productsGroupByType;
  }

  changeLimitFilter(entity: string, isVisible: boolean) {
    this.limitFilters[entity] = isVisible;
  }
  private async getGlobalSearchResults(): Promise<ProductInfoImpl[]> {
    return await fetchGlobalSearch(this.searchQ);
  }
}
