import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ValidationEvent } from '@quantumart/mobx-form-validation-kit/dist/validation-event';
import SelectWrap from 'components/common/SelectWrap';
import {
  FormControl,
  InputFormControl,
  SelectFormControl,
  TextAreaFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { PaymentTypesFactory } from './PaymentOptions';
import { PaymentTypesTitle } from 'pages/ShoppingCart/constants';
import CartStore from 'root/store/cart';
import { PaymentFormsStore } from './paymentForms.store';

interface PaymentStepProps {
  store: PaymentFormsStore;
  cart: CartStore;
}

export const PaymentStep = observer(({ store, cart }: PaymentStepProps) => {
  useEffect(() => {
    store.loadPaymentProviders();
  }, []);

  const handleChange =
    (formControl: FormControl) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
      event.preventDefault();
      formControl.value = event.target.value;
    };

  const handleBlur =
    (formControl: FormControl) =>
    (event: React.SyntheticEvent): void => {
      event.preventDefault();
      formControl.setTouched(true);
      formControl.setFocused(false);
    };

  const { form, billingForm } = store;
  const { controls } = billingForm;

  return (
    <form className="form" id="payment" name="payment" action="" noValidate>
      <div className="form-wrap">
        <div className="col">
          <h3 className="form-title">Billing Address</h3>

          <label
            className={`checkbox-container checkbox-container_mt-30 ${
              form.controls.sameShippingAddress.value
                ? 'checkbox-container_checked-hide'
                : ''
            }`}
          >
            Same to Shipping address
            <input
              type="checkbox"
              name="sameShippingAddress"
              className={`${
                form.controls.sameShippingAddress.invalid &&
                form.controls.sameShippingAddress.touched
                  ? 'invalid'
                  : 'valid'
              } ${form.controls.sameShippingAddress.value ? 'shrink' : ''} `}
              data-form="payment"
              checked={form.controls.sameShippingAddress.value}
              {...InputFormControl.bindActions(
                form.controls.sameShippingAddress,
              )}
            />
            <span className="checkmark"></span>
          </label>

          <p className="attention-message">
            In order to ship to a different address other than your billing
            address, you must first add it with your credit card company. If you
            have any questions, please feel free to call us at +1-877-382-6275.
          </p>

          <SelectWrap className="select_hidden">
            <select
              name="country"
              className={`${'select'} ${
                controls.country.invalid && controls.country.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.country.value ? 'shrink' : ''} `}
              data-form="payment"
              value={controls.country.value}
              {...SelectFormControl.bindActions(controls.country)}
            >
              <option value="">Select your country *</option>
              {store.countries.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
            {controls.country.touched && controls.country.errors && (
              <div className="form-label__error">
                {controls.country.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </SelectWrap>

          {store.states.length ? (
            <SelectWrap className="select_hidden">
              <select
                name="state"
                className={`${'select'} ${
                  controls.state.invalid && controls.state.touched
                    ? 'invalid'
                    : 'valid'
                } ${controls.state.value ? 'shrink' : ''} `}
                data-form="payment"
                {...SelectFormControl.bindActions(controls.state)}
              >
                <option value="">Select your state *</option>
                {store.states.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </select>
              {controls.state.touched && controls.state.errors && (
                <div className="form-label__error">
                  {controls.state.errors.map((error: ValidationEvent) => (
                    <span key={error.message}>{error.message}</span>
                  ))}
                </div>
              )}
            </SelectWrap>
          ) : null}

          <label className="form-label form-label_hidden">
            <input
              type="text"
              name="city"
              className={` ${
                controls.city.invalid && controls.city.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.city.value ? 'shrink' : ''} `}
              data-form="payment"
              value={controls.city.value}
              {...InputFormControl.bindActions(controls.city)}
            />
            <div className="label-text">City *</div>
            {controls.city.touched && controls.city.errors && (
              <div className="form-label__error">
                {controls.city.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>

          <div className="form-label form-label_with-textarea form-label_hidden">
            <textarea
              rows={1}
              name="address"
              className={`${'form-label__textarea'} ${'block-scrollbar'} ${
                controls.address.invalid && controls.address.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.address.value ? 'shrink' : ''} `}
              data-form="payment"
              value={controls.address.value}
              {...TextAreaFormControl.bindActions(controls.address)}
            />
            <div className="label-text label-text_for-textarea">
              Street Address *
            </div>
            {controls.address.touched && controls.address.errors && (
              <div className="form-label__error">
                {controls.address.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </div>

          <label className="form-label form-label_hidden">
            <input
              type="text"
              name="aptUnit"
              className={`${'not-required'} ${
                controls.aptUnit.invalid && controls.aptUnit.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.aptUnit.value ? 'shrink' : ''} `}
              data-form="payment"
              value={controls.aptUnit.value}
              {...InputFormControl.bindActions(controls.aptUnit)}
            />
            <div className="label-text">Apt/Unit</div>
            {controls.aptUnit.touched && controls.aptUnit.errors && (
              <div className="form-label__error">
                {controls.aptUnit.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>
          <label className="form-label form-label_hidden">
            <input
              type="text"
              name="zipCode"
              className={`${
                controls.zipCode.invalid && controls.zipCode.touched
                  ? 'invalid'
                  : 'valid'
              } ${controls.zipCode.value ? 'shrink' : ''} `}
              data-form="payment"
              value={controls.zipCode.value}
              {...InputFormControl.bindActions(controls.zipCode)}
            />
            <div className="label-text">ZIP Code *</div>
            {controls.zipCode.touched && controls.zipCode.errors && (
              <div className="form-label__error">
                {controls.zipCode.errors.map((error: ValidationEvent) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </div>
            )}
          </label>
          <p
            className={`attention-message attention-message_hidden attention-message_mt-20 attention-message_mb-35 ${
              store.billingForm.touched && store.billingForm.invalid
                ? 'attention-message_warning'
                : ''
            }`}
            data-form="customer"
          >
            {store.billingForm.invalid && store.billingForm.touched
              ? '* Please fill correctly the required fields.'
              : '* Fields are required.'}
          </p>
        </div>

        <div className="col">
          <h3 className="form-title">Payment</h3>
          <SelectWrap className="select-container_mb-30">
            <select
              name="paymentType"
              className={`select ${
                form.controls.paymentType.invalid && 'invalid'
              }`}
              data-form="payment"
              value={form.controls.paymentType.value}
              {...SelectFormControl.bindActions(form.controls.paymentType)}
            >
              {store.paymentProviders.map((provider) => (
                <option key={provider.type} value={provider.type}>
                  {PaymentTypesTitle[provider.type]}
                </option>
              ))}
            </select>
            {form.controls.paymentType.errors && (
              <div className="form-label__error">
                {form.controls.paymentType.errors.map(
                  (error: ValidationEvent) => (
                    <span key={error.message}>{error.message}</span>
                  ),
                )}
              </div>
            )}
          </SelectWrap>

          <p className="attention-message attention-message_mb-30">
            If you pay via bank wire you will receive a discount of 1.5% on the
            whole order
          </p>
          <p className="attention-message">
            All transactions are secure and encrypted
          </p>

          <PaymentTypesFactory
            paymentType={form.controls.paymentType.value}
            store={store}
            cart={cart}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
      </div>
    </form>
  );
});
