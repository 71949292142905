import LogoIcon from './LogoIcon';
import ShoppingIcon from './ShoppingIcon';
import CompareIcon from './CompareIcon';
import LogoMobileIcon from '../common/LogоMobileIcon';

export default {
  logo: LogoIcon,
  logo_mobile: LogoMobileIcon,
  shopping: ShoppingIcon,
  compare: CompareIcon,
};
