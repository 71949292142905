import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Pagination from 'components/common/Pagination';
import PaginateStore from 'root/store/paginate';
import { observer } from 'mobx-react';

interface CatalogPaginationProps extends RouteComponentProps {
  pagination: PaginateStore;
  handlePagination: (page: number) => void;
}

export const CatalogPagination = withRouter(
  observer((props: CatalogPaginationProps) => {
    const { pagination, handlePagination } = props;
    return (
      <div className="pagination-footer">
        <Pagination
          pagination={pagination}
          handlePagination={handlePagination}
        />
      </div>
    );
  }),
);
