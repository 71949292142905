import React, { useEffect, useRef, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { RouteComponentProps, useLocation } from 'react-router';
import { observer } from 'mobx-react';
import { joinClasses } from 'lib/utils';
import Icon from 'components/common/Icon';
import { IDropdownMenuHeaderItem } from '../Header.types';
import { ProductMenuTemplate } from './templates';

interface DropdownMenuProps extends RouteComponentProps {
  dropdownCategory: IDropdownMenuHeaderItem;
  closeMobileMenu: () => void;
  isDesktop: boolean;
}

export const DropdownMenu = withRouter(
  observer((props: DropdownMenuProps) => {
    const { dropdownCategory, closeMobileMenu, isDesktop } = props;
    const { pathname } = useLocation();

    const [isDropdownOnTabletShow, setIsDropdownOnTabletShow] = useState(false);
    const refDropdown = useRef<HTMLDivElement>(null);

    const hideMenu = (): void => {
      setIsDropdownOnTabletShow(false);
    };

    const openMenuOnTablet = (): void => {
      setIsDropdownOnTabletShow(!isDropdownOnTabletShow);
    };

    const handleClickOutsideActiveMenu = (event: Event): void => {
      const eventTarget = event.target as HTMLElement;

      if (refDropdown?.current?.contains(eventTarget)) return;
      hideMenu();
    };

    useEffect(() => {
      hideMenu();
      closeMobileMenu();
    }, [pathname]);

    useEffect(() => {
      window.addEventListener('scroll', hideMenu);
      window.addEventListener('mousedown', handleClickOutsideActiveMenu);
      return () => {
        window.removeEventListener('scroll', hideMenu);
        window.removeEventListener('mousedown', handleClickOutsideActiveMenu);
      };
    });

    return (
      <div
        ref={refDropdown}
        className={joinClasses([
          'link',
          'menu__link',
          'menu_item_link',
          `dropdown dropdown__${dropdownCategory.id}`,
          isDropdownOnTabletShow && 'show-on-tablet',
        ])}
      >
        <input
          className="dropdown__checkbox"
          id={`checkbox${dropdownCategory.id}`}
          type="checkbox"
        />
        {!isDesktop ? (
          <label
            htmlFor={`checkbox${dropdownCategory.id}`}
            onClick={openMenuOnTablet}
            className={'link menu__link'}
          >
            {dropdownCategory.title}
          </label>
        ) : (
          <NavLink
            to={{
              pathname: dropdownCategory.pathname,
              state: {
                category: dropdownCategory.stateCategory,
              },
            }}
            className={'link menu__link'}
            activeClassName={'active'}
          >
            {dropdownCategory.title}
          </NavLink>
        )}

        <label
          className={joinClasses([
            'dropdown__button dropdown__button_with-caret',
            dropdownCategory.id,
          ])}
          htmlFor={`checkbox${dropdownCategory.id}`}
          onClick={openMenuOnTablet}
        >
          <Icon id="caret" />
        </label>

        <ProductMenuTemplate templateType={dropdownCategory.id} />
      </div>
    );
  }),
);
