import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
import store from 'root/store';
import FilterExtra from 'pages/EngagementRings/store/ringFiltersExtra.store';
import weddingRingCatalog from '../../store';
import { Catalog as DefaultComponent } from './Catalog';

const { cart, compare, favorite } = store;
const filtersExtra = new FilterExtra();

interface IRingsProps extends RouteComponentProps {
  historyReplace: (query: unknown) => void;
  location: H.Location<{ needToResetFilters?: boolean }>;
}

export const Rings = withRouter((props: IRingsProps) => (
  <DefaultComponent
    {...props}
    cart={cart}
    compare={compare}
    favorite={favorite}
    catalog={weddingRingCatalog}
    filtersExtra={filtersExtra}
  />
));
