import React from 'react';
import Svg from '../../Svg';

interface IRoundMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function RoundMeasLengthIcon(
  props: IRoundMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M99.61 163.85c35.965 0 65.12-29.155 65.12-65.12 0-35.964-29.155-65.12-65.12-65.12S34.49 62.766 34.49 98.73c0 35.965 29.155 65.12 65.12 65.12z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.61 33.61 81.52 54.38l-27.5-2.15v28.1l-19.53 18.4 20.46 18.25-1.61 27.57 27.26-1.92 19.01 21.22M99.61 33.61l18.1 20.77 27.49-2.15v28.1l19.53 18.4-20.45 18.25 1.6 27.57-27.26-1.92-19.01 21.22"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m124.81 38.66-7.1 15.71-18.1 8.86 45.59 17.1s-22.61 40.31-26.58 62.3c-2.44-.76-19-9.16-19-9.16s-16.57 8.4-19 9.16c-4-22-26.57-62.3-26.57-62.3l45.58-17.1-18.11-8.86-7.11-15.71"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.52 54.37S63.5 106.14 55 117M117.8 54.37s18 51.77 26.57 62.61M99.61 133.47S63.71 118.31 54.77 117l-15.42 6.48M99.61 133.47s35.81-15.16 44.76-16.49l15.39 6.75M54.03 80.33 39.35 74M145.2 80.33 159.87 74"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.61 33.61h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.22 33.61h66.9"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.11 3.11"
      />
      <path
        d="M172.67 33.61h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.51 33.61v130.73"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.61 164.34h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.22 164.34h66.9"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.11 3.11"
      />
      <path
        d="M172.67 164.34h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
