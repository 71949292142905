import * as React from 'react';
import { Link } from 'react-router-dom';

export const POLICIES_TABS = {
  privacyPolicy: {
    title: 'Privacy Policy',
    dateUpdate: 'Last Updated: Nov 13 2019',
    description: (
      <>
        <h2 className="sub-title_medium">
          All defined terms used below shall have the meanings set forth in Our
          Terms and Conditions. See{' '}
          <Link to="/policy/termsconditions" className="common-link">
            Terms and Conditions
          </Link>
          .
        </h2>
        <p>
          This Privacy Policy ("Policy") explains how information about You is
          collected, used, and disclosed by Your access or use of this Site or
          otherwise as a result of Your interactions with Us. By visiting this
          Site directly or through another site, You accept the terms and
          conditions of this Policy. This Policy applies to this Site. We are
          not responsible for the content or privacy practices on any website
          not operated by Us to which this Site links or that links to this
          Site.
        </p>
        <p>
          We respect children’s privacy. We do not knowingly or intentionally
          collect personal information from children under age 13. Elsewhere on
          the Site, you have represented and warranted that you are either 18
          years of age or using the Site with the supervision of a parent or
          guardian. If you are under the age of 13, please do not submit any
          personal information to Us, and rely on a parent or guardian to assist
          you.
        </p>
        <h2 className="sub-title_bold">Information Collection</h2>
        <p>
          We collect information from you in several different ways on this
          Site.
        </p>
        <p className="sub-title_medium">Registration and Ordering</p>
        <p>
          Before using certain portions of this Site or ordering products, you
          may be required to complete an online registration form. During
          registration, you will be asked to provide to Us certain personal
          information, including but not limited to your name, shipping and
          billing address(es), phone number, email address, gender, and credit
          card number. In addition, We may also ask you for your country of
          residence and/or your organization’s country of operation, so we can
          comply with applicable laws and regulations. These kinds of personal
          information are used for billing purposes, to fulfill your orders, to
          communicate with you about your order and the Sites, and for internal
          marketing purposes. If We encounter a problem when processing your
          order, your personal information may be used to contact you.
        </p>
        <p className="sub-title_medium">Information We Collect Automatically</p>
        <p>
          We may also automatically collect information about you when you
          access or use the Site or transact business with Us, including:
        </p>
        <ul className="list_unordered">
          <li>
            Transaction Information: When you purchase or return a product, We
            collect information about the transaction, such as product details,
            purchase price, and the date and location of the transaction.
          </li>
          <li>
            Log Information: We obtain information about your use of Our
            websites, including the type of browser you use, access times, pages
            viewed, your IP address and the page you visited before navigating
            to this Site.
          </li>
          <li>
            Device Information: We collect information about the computer or
            mobile device you use to access Our Services, such as the hardware
            model, operating system and version, unique device identifiers,
            mobile network information, and browsing behavior.
          </li>
          <li>
            Location Information: We may collect information about the precise
            location of your device when you consent to the collection of this
            information. We may also collect information about your approximate
            location each time you access this Site.
          </li>
          <li>
            Information Collected by Cookies and other Tracking Technologies: We
            may use cookies, web beacons, and other tracking technologies to
            collect information about you and your interaction with this Site,
            including information about your browsing behavior, purchase
            behavior, and other engagement with the Services. We use this
            information in one or more of the ways described in the "Use of
            Information" section below. Most web browsers are set to accept
            cookies by default, but you can usually change your browser settings
            to remove or reject cookies.
          </li>
        </ul>
        <p className="sub-title_medium">
          Information We Collect from Other Sources
        </p>
        <p>
          We may also receive information about you from other sources and
          combine or link that with information We have about you. For example,
          We may collect demographic and change-of-address information from
          third party sources and information from third party social media
          platforms (such as Facebook) if you log into this Site using your
          social media account credentials or if you make certain content and
          information publicly available, such as photos, videos, and profile
          information.
        </p>
        <h2 className="sub-title_bold">Information Use and Disclosure</h2>
        <h2 className="sub-title_medium">Internal Use</h2>
        <p>
          We use your personal information to process your order and provide you
          with customer service. We may internally your personal information to
          improve this Site’s content and layout, to improve outreach and for
          Our own marketing efforts (including marketing our services and
          products to you), and to determine general marketplace information
          about visitors to this Site.
        </p>
        <h2 className="sub-title_medium">Communications with You</h2>
        <p>
          We will use your personal information to communicate with you about
          this Site and your orders and deliveries. Also, We may send you a
          confirmation email when you register with Us. We may send you a
          service-related announcement on the rare occasions when it is
          necessary (for example, if we must temporarily suspend our service for
          maintenance.) Also, you may submit your email address for reasons such
          as to register for a contest or sweepstakes or to sign up for email
          newsletters and special offers. If you submit your email address, We
          use it to deliver the information to you. We always permit you to
          unsubscribe or opt out of future emails. Because We have to
          communicate with you about orders that you choose to place, you cannot
          opt out of receiving emails related to your orders.
        </p>
        <h2 className="sub-title_medium">External Use</h2>
        <p>
          Except as otherwise set forth below, We do not sell, rent, trade,
          license or otherwise disclose your specific personal information or
          financial information to anyone.
        </p>
        <p>
          We may disclose information to third parties that perform specific
          functions on Our behalf. However, We will only disclose the
          information that is necessary for them to perform their service.
        </p>
        <p>
          We must provide your credit card number to financial-services
          corporations such as credit-card processors and issuers as is required
          to process your orders. We will use industry standard security
          measures, including data encryption, when providing your credit card
          number to others.
        </p>
        <p>
          We may disclose personal information or financial information in
          response to requests from law enforcement officials conducting
          investigations; subpoenas; a court order; or if We are otherwise
          required to disclose such information by law. We also will release
          personal information where disclosure is necessary to protect our
          legal rights, enforce our Terms and Conditions or other agreements, or
          to protect ourselves or others. For example, We may share information
          to reduce the risk of fraud or if someone uses or attempts to use this
          Site for illegal reasons or to commit fraud.
        </p>
        <p>
          While We will not sell (or trade or rent) personally identifiable
          information to other companies as part of Our regular course of
          business. However, it’s possible that We might acquire or merge with
          or be acquired by another company or that We might dispose of some or
          all of Our assets. If that happens, your personal information may be
          disclosed to another company, but that disclosure will be subject to
          the Privacy Policy in effect.
        </p>
        <p>
          We may share non-personal information (such as the number of daily
          visitors to a particular web page, or the size of an order placed on a
          certain date) with third parties such as advertising partners. This
          information does not directly personally identify you or any user.
        </p>
        <h2 className="sub-title_bold">Data Security</h2>
        <p>
          We take reasonable measures, including administrative, technical, and
          physical safeguards, to protect information about you from loss,
          theft, misuse, unauthorized access, disclosure, alteration, and
          destruction.
        </p>
        <h2 className="sub-title_bold">Opt Out/Corrections</h2>
        <p>
          Upon your request, We will (a) correct or update your personal
          information; (b) stop sending emails to your email address; and/or (c)
          disable your account to prevent any future purchases through that
          account. You can make these requests by emailing Us at
          customerservice@venori.com.
        </p>
        <p>
          Please do not email your credit-card number or other sensitive
          information.
        </p>
        <h2 className="sub-title_bold">
          Offline Collection Use and Disclosure
        </h2>
        <p>
          We also may collect information offline. We will treat any information
          collected offline in a manner that is consistent with this Policy. One
          example involves someone calling Us to place an order or to ask
          questions. When someone calls, We will ask only for the personal
          information We need in order to place the order or to answer the
          question. When We need to store information (such as order
          information), We will enter it into our database.
        </p>
        <h2 className="sub-title_bold">Updates to this Policy</h2>
        <p>
          If We change or update this Privacy Policy, We will post changes and
          updates on the Site so that you will always be aware of what
          information We collect, use and disclose. We encourage you to review
          this Policy from time to time so you will know if the Privacy Policy
          has been changed or updated. If you have any questions about the
          Policy, please contact us at customerservice@venori.com.
        </p>
      </>
    ),
  },
  shippingPolicy: {
    title: 'Shipping & Return Policy',
    dateUpdate: 'Last Updated: Nov 13 2019',
    description: (
      <>
        <h2 className="sub-title_medium">
          All defined terms used below shall have the meanings set forth in our
          Terms and Conditions. See{' '}
          <Link to="/policy/termsconditions" className="common-link">
            Terms and Conditions
          </Link>
          .
        </h2>
        <h2 className="sub-title_bold">Order Cancellations</h2>
        <p>
          Orders that you submit online are processed immediately and may not be
          cancelled, and you may need to wait until you receive the merchandise
          in order to return it.
        </p>
        <h2 className="sub-title_bold">Returns</h2>
        <p>
          Once an item of merchandise is delivered to you, you can return that
          item within 30 days of delivery. To be eligible for a return, your
          merchandise must be unused and in the same condition that you received
          it and must be in the original Venori packaging [Venori gift box]. Our
          return policy does not apply to the following goods: [custom orders,
          eternity bands, personalized or engraved items, items that are over
          the 30 day return period. These items are not eligible for return,
          refund or exchange.
        </p>
        <h2 className="sub-title_bold">Shipping</h2>
        <p>
          Shipping on orders over $500 will be shipped via FEDEX Priority
          Overnight in our discreet packaging. Orders $500 or less will be
          shipped via FEDEX 2 Day Shipping.{' '}
        </p>
        <p>
          To initiate a return, please email us at customerservice@venori.com or
          call us at 877-382-6275 so that we may process your return and provide
          you with an RMA number to accompany your return.
        </p>
        <p>
          All returned merchandise should be sent to us at 231 Central Ave,
          Suite 2, White Plains, NY, 10606.
        </p>
        <p>
          Returns are free within the 30 day policy and you will receive a 2-day
          priority pre-paid shipping label.
        </p>
        <p>
          All returns will be processed within 2 weeks of receiving the return.
          Credit Card purchases will be refunded back to the credit card used
          for purchase and bank wires will be refunded via our company bank
          check.{' '}
        </p>
        <h2 className="sub-title_bold">Refunds and Exchanges</h2>
        <p>
          After We have received your valid return, We will send you an email to
          notify you that We have received your returned item and notify you of
          the acceptance or rejection of your return.
        </p>
        <p>
          If your return is accepted by Us, We will provide one of the following
          within a reasonable time: an exchange of merchandise for the item
          returned, a non-transferable merchandise credit, a credit to the
          payment card or original method of payment used to pay for the item, a
          check, or another remedy that we determine in good faith is
          appropriate in the circumstances.
        </p>
        <h2 className="sub-title_bold">General</h2>
        <p>
          If you do not comply with any of the above conditions, We reserve the
          right to refuse the return or exchange, or to impose different or
          additional conditions.
        </p>
      </>
    ),
  },
  termsAndConditions: {
    title: 'Terms and Conditions',
    dateUpdate: 'Last Updated: Nov 13 2019',
    description: (
      <>
        <p>
          This website and any mobile application (collectively, this “Site”) is
          owned by Venori Inc. (“We”, “Us” or “Venori Inc.”). We are providing
          you with access to this Site and our online store (together, our
          “Services”) subject to the following terms and conditions.
        </p>
        <p>
          By browsing, accessing, using, registering for or purchasing
          merchandise on this Site or otherwise using our Services, you are
          agreeing to all of the following terms and conditions, including any
          policies referred to herein (collectively, these “Terms”). So, please
          read these Terms carefully. We reserve the right to change this Site
          and these Terms at any time. If you are unwilling to be bound by these
          Terms‚ you should not browse, access‚ use‚ register for or purchase
          merchandise from the Site.
        </p>
        <p>
          You represent and warrant that you are at least 18 years old or
          visiting this Site under the supervision of a parent or guardian.
        </p>
        <h2 className="sub-title_bold">Privacy Policy</h2>
        <p>
          Our Privacy Policy, which also governs your visit to Our Site, can be
          found at [INSERT LINK TO YOUR PRIVACY POLICY]. Please review our
          Privacy Policy for information on how We collect, use and share
          information about our users.
        </p>
        <h2 className="sub-title_bold">Use of This Site</h2>
        <p>
          Subject to your compliance with these Terms‚ We grant you a limited‚
          non-exclusive‚ non-transferable‚ non-sublicensable license to access
          and make personal‚ non-commercial use of this Site. This license grant
          does not include: (a) any resale or commercial use of this Site or
          content therein; (b) the collection and use of any product listings or
          descriptions; (c) making derivative uses of this Site and its
          contents; or (d) use of any data mining‚ robots‚ or similar data
          gathering and extraction methods on this Site. You may not use‚ frame
          or utilize framing techniques to enclose any of Our trademark‚ logo‚
          content or other proprietary information (including the images found
          at this Site‚ the content of any text or the layout/design of any page
          or form contained on a page) without Our express written consent.
          Further‚ you may not use any meta tags or any other “hidden text”
          utilizing Our name‚ trademark‚ or product name without Our express
          written consent. Any breach of these Terms shall result in the
          immediate revocation of the license granted in this paragraph without
          notice to you.
        </p>
        <p>
          You are granted a limited, revocable, and nonexclusive right to create
          a hyperlink to the homepage of this Site so long as the link does not
          portray Us or Our products or services in a false, misleading,
          derogatory, or otherwise offensive matter. This limited right may be
          revoked at any time. You may not use any of Our logos or other
          proprietary graphics or trademarks as part of the link without express
          written permission.
        </p>
        <h2 className="sub-title_bold">Account</h2>
        <p>
          In order to access some features of this Site‚ you may be required to
          register and We may assign to you, or you may be required to select, a
          password and user name or account identification. If you register‚ you
          agree to provide Us with accurate and complete registration
          information‚ and to inform us immediately of any updates or other
          changes to such information.
        </p>
        <p>
          You are solely responsible for protecting the security and
          confidentiality of the password and identification assigned to you.
          You shall immediately notify Us of any unauthorized use of your
          password or identification or any other breach or threatened breach of
          this Site's security. Each time you use a password or identification,
          you will be deemed to be authorized to access and use the Site in a
          manner consistent with these Terms, and We have no obligation to
          investigate the authorization or source of any such access or use of
          this Site. YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF
          THIS SITE BY ANYONE USING THE PASSWORD AND IDENTIFICATION ORIGINALLY
          SELECTED BY, OR ASSIGNED TO, YOU WHETHER OR NOT SUCH ACCESS TO AND USE
          OF THIS SITE IS ACTUALLY AUTHORIZED BY YOU, INCLUDING WITHOUT
          LIMITATION, ALL COMMUNICATIONS AND TRANSMISSIONS AND ALL OBLIGATIONS
          (INCLUDING WITHOUT LIMITATION FINANCIAL OBLIGATIONS) INCURRED THROUGH
          SUCH ACCESS OR USE.
        </p>
        <h2 className="sub-title_bold">Electronic Communication</h2>
        <p>
          When you use this Site, or send emails to Us, you are communicating
          with Us electronically. You consent to receive communications from Us
          electronically. We will communicate with you by e-mail or by posting
          notices on this Site or through our other services. You agree that all
          agreements, notices, disclosures and other communication that We
          provide to you electronically satisfy any legal requirements that such
          communications be in writing.
        </p>
        <h2 className="sub-title_bold">User Content</h2>
        <p>
          This Site may include features and functionality (“Interactive
          Features”) that allows users to create, post, transmit or store any
          content, such as text, music, sound, photos, video, graphics or code
          on the Sites ("User Content"). User Content is publicly-viewable and
          may include your profile information and any content you post pursuant
          to your profile, but it does not include your account information or
          information you submit in order to make a purchase. You agree that you
          are solely responsible for your User Content and for your use of
          Interactive Features, and that your use any Interactive Features at
          your own risk.
        </p>
        <p>
          By using any Interactive Areas, you agree not to post, upload to,
          transmit, distribute, store, create or otherwise publish or send
          through the Sites any of the following:
        </p>
        <ul className="list_unordered">
          <li>
            User Content that is unlawful, libelous, defamatory, obscene,
            pornographic, indecent, lewd, suggestive, harassing, threatening,
            abusive, inflammatory, fraudulent or otherwise objectionable;
          </li>
          <li>
            User Content that would constitute, encourage or provide
            instructions for a criminal offense, violate the rights of any party
            or that would otherwise create liability or violate any local,
            state, national or international law;
          </li>
          <li>
            User Content that displays, describes or encourages usage of any
            product We sell in a manner that could be offensive, inappropriate
            or harmful to Us or any user or consumer;
          </li>
          <li>
            User Content that may impinge upon or violate the publicity, privacy
            or data protection rights of others, including pictures, videos,
            images or information about another individual where you have not
            obtained such individual's consent;
          </li>
          <li>
            User Content that makes false or misleading statements, claims or
            depictions about a person, company, product or service;
          </li>
          <li>
            User Content that may infringe any patent, trademark, trade secret,
            copyright or other intellectual or proprietary right of any party;
          </li>
          <li>
            User Content that impersonates any person or entity or otherwise
            misrepresents your affiliation with a person or entity;
          </li>
          <li>
            Viruses, malware of any kind, corrupted data or other harmful,
            disruptive or destructive files or code.
          </li>
        </ul>
        <h2 className="sub-title_bold">Rights in User Content</h2>
        <p>
          Except as otherwise provided in these Terms, on this Site or in a
          separate agreement with Us, We claim no ownership or control over any
          User Content. However, by submitting or posting User Content on this
          Site, you grant to Us a nonexclusive, royalty-free, worldwide,
          perpetual, irrevocable, transferable, and fully sub-licensable right
          to use, reproduce, modify, adapt, publish, translate, create
          derivative works from, distribute, perform and display such User
          Content on this Site and on third-party sites and mobile applications
          and in all other media or formats, whether currently known or
          hereafter developed, for any purpose and without any compensation to
          you. You also grant users of this Site the right to access your User
          Content in connection with their use of this Site.
        </p>
        <p>
          By posting User Content to this Site, you represent and warrant that
          (a) such User Content is non-confidential; (b) you own and control all
          of the rights, title and interest in and to the User Content or you
          otherwise have all necessary rights to post and use such User Content
          to this Site and to grant to Us the rights that you grant in these
          Terms; (c) the User Content is accurate and not misleading or harmful
          in any manner; and (d) the User Content, and your use and posting
          thereof in connection with this Site, do not and will not violate
          these Terms or any other of Our applicable terms, guidelines or
          policies or any applicable law, rule or regulation.
        </p>
        <h2 className="sub-title_bold">Feedback</h2>
        <p>
          Separate and apart from User Content, you may have the ability to
          submit questions, comments suggestions, reviews, ideas, plans,
          designs, notes, proposals, drawings, original or creative materials
          and other information regarding this Site, Us and our products or
          services (collectively "Feedback"). You agree that Feedback is
          non-confidential and shall become Our sole property. We shall own
          exclusive rights, including all intellectual property rights, in and
          to such Feedback and shall be entitled to the unrestricted use and
          dissemination of the Feedback for any purpose, commercial or
          otherwise, without acknowledgment or compensation to you.
        </p>
        <h2 className="sub-title_bold">Restrictions on Rights to Use</h2>
        <p>
          You agree you shall not (and you agree not to allow any other
          individual or entity using your password and identification to):
        </p>
        <ul className="list_unordered">
          <li>
            download‚ modify‚ reproduce‚ adapt‚ translate‚ reverse engineer‚
            create derivative works based upon‚ publicly display‚ sell‚ rent‚
            license‚ or in any way commercially exploit any portion of this
            Site‚ except and to the extent expressly permitted under these
            Terms;
          </li>
          <li>
            remove any copyright‚ trademark or other proprietary rights notice
            contained in or on the Site;
          </li>
          <li>
            use any robot‚ spider‚ site search/retrieval application‚ or other
            device to retrieve or index any portion of this Site;
          </li>
          <li>
            collect any information about other users (including usernames
            and/or email addresses) for any purpose other than to solicit and/or
            share reviews with other users;
          </li>
          <li>
            reformat or frame any portion of any Web pages that are part of this
            Site;
          </li>
          <li>
            create user accounts by automated means or under false or fraudulent
            pretenses;
          </li>
          <li>
            create or transmit to other users unsolicited electronic
            communications‚ such as “spam‚” or otherwise interfere with other
            users’ enjoyment of the Site;
          </li>
          <li>
            submit to this Site any content that falsely states or implies that
            such content is sponsored or endorsed by us;
          </li>
          <li>
            transmit or upload to this Site any item containing or embodying any
            virus‚ worm‚ defect‚ malware‚ Trojan horse‚ software bomb or other
            feature designed to damage or degrade in any manner the performance
            of this Site‚ any other Web site‚ or any computer or other device or
            system‚ or the enjoyment of this Site by any user;
          </li>
          <li>
            use this Site to violate the security of or gain unauthorized access
            to any computer or computer network or other device or system
            (including unauthorized attempts to discover passwords or security
            encryption codes);
          </li>
          <li>
            submit to this Site any content that is unlawful or facilitates‚
            constitutes‚ promotes or encourages illegal activity; or otherwise
            use the Site to transfer or store illegal material‚ including any
            material deemed threatening or obscene;
          </li>
          <li>
            copy or store any User Content offered on this Site other than for
            your personal‚ non-commercial use;
          </li>
          <li>
            take any action that imposes‚ or may impose‚ in our sole discretion‚
            an unreasonable or disproportionately large data or traffic load on
            this Site or the IT infrastructure used to operate and make this
            Site available;
          </li>
          <li>
            use this Site and/ or any User Content‚ intentionally or
            unintentionally‚ to violate any applicable local‚ state‚ federal or
            international law.
          </li>
          <p>
            We have no obligation to monitor any user conduct on this Site, and
            We reserve the right and have absolute discretion to monitor any
            user conduct on this Site at any time and for any reason without
            notice.
          </p>
        </ul>
        <h2 className="sub-title_bold">Ownership</h2>
        <p>
          As between you and Us‚ this Site‚ including all photographs‚ images‚
          text‚ graphics‚ icons‚ audio clips‚ software‚ source code and other
          aspects thereof (excluding User Content)‚ all improvements or
          modifications thereof‚ all derivative works based thereon‚ and the
          collection‚ arrangement‚ and assembly of this Site (collectively, the
          “Site Content”)‚ including all copyrights‚ trademarks‚ and other
          intellectual property or proprietary rights in the foregoing‚ are
          owned by Us or our licensors and protected by applicable copyright
          laws.
        </p>
        <p>
          The use of any of Our trademarks or service marks without our express
          written consent is strictly prohibited. You may not use our trademarks
          or service marks in connection with any product or service in any way
          that is likely to cause confusion. You may not use our trademarks or
          service marks in any manner that disparages or discredits us. You may
          not use any of our trademarks or service marks in meta tags without
          prior explicit consent. Nothing in these Terms shall be deemed to
          grant to you or any other user any license or right in or to any of
          Our patents‚ copyrights‚ trademarks‚ trade secrets or other
          proprietary rights.
        </p>
        <h2 className="sub-title_bold">Purchases on this Site</h2>
        <p>
          You agree that all of your transactions with or through this Site may,
          at Our option, be conducted electronically from start to finish. If We
          decide to proceed non-electronically, those transactions will still be
          governed by the remainder of these Terms unless you enter into
          different terms provided by us. You are responsible to print or make
          an electronic a copy of these Terms and any other contract or
          disclosure that we are required to provide to you.
        </p>
        <p>
          The risk of loss and title for items purchased by you on this Site
          pass to you upon our delivery of the items to the carrier pursuant to
          a shipment contract.
        </p>
        <p>
          We charge sales tax for merchandise ordered on this Site based on the
          applicable state sales tax rate of the location to which the order is
          being shipped.
        </p>
        <p>
          For orders over $500, the address that you wish to ship must be on
          file with your credit card company in order to process the
          transaction. We reserve the right to increase, decrease and add or
          eliminate charges from time to time and without prior notice, so you
          agree to check all charges before placing an order or signing up for a
          service. Any shipping or handling charges may or may not reflect
          actual costs.
        </p>
        <p>
          Only valid credit cards or other payment method acceptable to us may
          be used. By submitting your order, you represent and warrant that you
          are authorized to use the designated card or method and authorize us
          to charge your order (including taxes, shipping, handling and any
          other amounts described on the Sites) to that card or other method. If
          the card (or other method) cannot be verified, is invalid, or is not
          otherwise acceptable, your order may be suspended or cancelled
          automatically.
        </p>
        <p>
          All returns are governed by our Return Policy, which can be found at
          [INSERT LINK TO YOUR REFUND POLICY].
        </p>
        <p>
          We attempt to be as accurate as possible and eliminate errors on this
          Site; however, We do not warrant that any product, service,
          description, photograph, pricing or other information is accurate,
          complete, reliable, current or error-free. In the event of an error,
          whether on this Site, in an order confirmation, in processing an
          order, delivering a product or service or otherwise, We reserve the
          right to correct such error and revise your order accordingly if
          necessary (including charging the correct price) or to cancel the
          order and refund any amount charged. Your sole remedy in the event of
          such error is to cancel your order and obtain a refund.
        </p>
        <p>
          All items are subject to availability and We reserve the right to
          impose quantity limits on any order, to reject all or part of an order
          and to discontinue products or services without notice, even if you
          have already placed your order. All prices are subject to change
          without notice. We reserve the right to refuse or cancel any orders
          placed for products and/or services which the sale or use of such
          product and/or service in your state or jurisdiction is restricted or
          prohibited.
        </p>
        <h2 className="sub-title_bold">Links</h2>
        <p>
          This Site may contain links to other sites on the Internet that are
          owned and operated by third parties. You acknowledge that We are not
          responsible for the operation of or content located on or through any
          such site.
        </p>
        <h2 className="sub-title_bold">Termination</h2>
        <p>
          You may terminate the Terms at any time by closing your account,
          discontinuing your use of this Site and providing Us with a notice of
          termination. We reserve the right, without notice and in our sole
          discretion, to terminate your right to use this Site, or any portion
          of this Site, and to block or prevent your future access to and use of
          this Site or any portion of this Site.
        </p>
        <h2 className="sub-title_bold">Indemnification</h2>
        <p>
          To the fullest extent permitted by applicable law, you agree to
          defend, indemnify and hold harmless Us and our subsidiaries and
          affiliates, and our respective officers, directors, agents, partners,
          members, employees, independent contractors, service providers and
          consultants ("Our Related Parties"), from and against any claims,
          damages, costs, liabilities and expenses (collectively, "Claims")
          arising out of or related to (a) your access to and use or misuse of
          this Site; (b) any User Content you post, upload, use, distribute,
          store or otherwise transmit on or through this Site; (c) any Feedback
          that you provide; (d) your violation of these Terms; and (e) your
          violation of any rights of another. You agree to promptly notify Us of
          any third party Claims, cooperate with Us in defending such Claims and
          pay all fees, costs and expenses associated with defending such Claims
          (including but not limited to attorneys' fees). You further agree that
          the We shall have the right to control of the defense or settlement of
          any third party Claims.
        </p>
        <h2 className="sub-title_bold">Disclaimers</h2>
        <p>
          Except as expressly provided, this Site, including all Site Content,
          and services provided on or in connection with this Site are provided
          on an "AS IS" and "WITH ALL FAULTS" basis without representations,
          warranties or conditions of any kind, either express or implied. WE
          DISCLAIM ALL OTHER REPRESENTATIONS, WARRANTIES, CONDITIONS AND DUTIES,
          EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO IMPLIED
          WARRANTIES, DUTIES OR CONDITIONS: (A) OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE OR USE, RESULTS, TITLE, AND NON-INFRINGEMENT; AND
          (B) CREATED BY TRADE USAGE, COURSE OF DEALING OR COURSE OF
          PERFORMANCE. We does not represent or warrant that this Site is
          accurate, complete, reliable, current or error-free. We do not
          represent or warrant that this Site or our servers are free of viruses
          or other harmful components.
        </p>
        <h2 className="sub-title_bold">
          Exclusivity of Remedy; Limitation of Liability
        </h2>
        <p>
          Your sole and exclusive remedy, and Our sole and exclusive liability,
          for any breach of warranty shall be your right to return the product,
          or receive a refund for the service under Our applicable returns and
          exchanges policies. IN NO EVENT SHALL THE WE OR OUR RELATED PARTIES,
          BE LIABLE FOR SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES,
          INCLUDING LOST PROFITS OR LOSS OF BUSINESS, EVEN IF THEY HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, NOR SHALL OUR AND OUR
          RELATED PARTIES’ AGGREGATE LIABILITY, WHETHER IN CONTRACT, WARRANTY,
          TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR
          OTHER THEORY, ARISING OUT OF OR RELATING TO THESE TERMS OR THE
          PURCHASE OR USE OF ANY PRODUCTS OR SERVICES PURCHASED THROUGH THIS
          SITE EXCEED THE PURCHASE PRICE OF THE PRODUCT OR SERVICE. THE
          LIMITATIONS SET FORTH IN THIS PARAGRAPH WILL NOT LIMIT OR EXCLUDE OUR
          OR OUR RELATED PARTIES’ GROSS NEGLIGENCE, FRAUD, INTENTIONAL, WILLFUL,
          MALICIOUS OR RECKLESS MISCONDUCT.
        </p>
        <h2 className="sub-title_bold">Remedies</h2>
        <p>
          You agree that our remedy at law for any actual or threatened breach
          of these Terms would be inadequate and that we shall be entitled to
          specific performance or injunctive relief, or both, in addition to any
          damages that we may be legally entitled to recover, together with
          reasonable expenses of any form of dispute resolution, including,
          without limitation, attorneys' fees.
        </p>
        <p>
          No right or remedy of ours shall be exclusive of any other, whether at
          law or in equity, including without limitation damages injunctive
          relief, attorneys' fees and expenses.
        </p>
        <h2 className="sub-title_bold">Modifications to Site</h2>
        <p>
          We reserve the right to modify or discontinue, temporarily or
          permanently, this Site or any features or portions thereof without
          prior notice.
        </p>
        <h2 className="sub-title_bold">Severability</h2>
        <p>
          If any these provisions shall be deemed invalid, void, or for any
          reason unenforceable, that condition shall be deemed several and shall
          not affect the validity and enforceability of any remaining provision.
        </p>
        <h2 className="sub-title_bold">No Third-Party Beneficiaries</h2>
        <p>
          These Terms are for the benefit of, and will be enforceable by, the
          parties only. These Terms are not intended to confer any right or
          benefit on any third party or to create any obligations or liability
          of a party to any such third party.
        </p>
        <h2 className="sub-title_bold">Miscellaneous</h2>
        <p>
          No agency‚ partnership‚ joint venture‚ or employment relationship is
          created as a result of these Terms‚ and you do not have any authority
          of any kind to bind Us in any respect whatsoever. We may provide you
          with notices‚ including those regarding changes to these Terms‚ by
          email‚ regular mail‚ or postings on this Site. These Terms, which
          shall be deemed accepted by you upon your use of the Site‚ constitute
          the entire agreement among you and Us regarding use of this Site. Our
          failure to exercise or enforce any right or provision of these Terms
          shall not constitute a waiver of the enforcement of such right or
          provision. If any provision of these Terms is found to be
          unenforceable or invalid‚ that provision shall be limited or
          eliminated to the minimum extent necessary so that these Terms shall
          otherwise remain in full force and effect and enforceable. These Terms
          are not assignable‚ transferable or sublicensable by you‚ except with
          our prior written consent. These Terms include and incorporate by
          reference Our Privacy Policy, which can be found at [INSERT LINK TO
          YOUR PRIVACY POLICY], and any notices regarding the Site.
        </p>
        <h2 className="sub-title_bold">Questions</h2>
        <p>
          Questions regarding these Terms, Our Privacy Policy, or other policy
          related material can be directed to our support staff by emailing us
          at: customerservice@venori.com.
        </p>
      </>
    ),
  },
};
