import React, {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { IBrowserWindowType } from 'root/store/infoBrowserWindowType.store';
import { CartItemFactory, CartItemStoneImpl } from 'root/store/cart/item.store';
import { CartItemEngraveSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';
import ImageItem from 'components/common/ImageItem';
import Icon from 'components/common/Icon';
import { joinClasses } from 'lib/utils';
import EngraveInformation from '../EngraveInformation';
import DeleteSetPopup from '../DeleteSetPopup';
import { ICartListItemIndexProps } from '.';

import './CartListItem.scss';

interface ICartListItemProps extends ICartListItemIndexProps {
  browserWindowType: IBrowserWindowType;
}

const CartListItem = observer((props: ICartListItemProps) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [engraveState, setEngraveState] = useState<
    CartItemEngraveSettings[] | undefined
  >(undefined);
  const [engravePosition, setEngravePosition] = useState<
    Record<'left' | 'top' | 'topTransform', number>
  >({
    left: 0,
    top: 0,
    topTransform: 0,
  });
  const {
    cartItem,
    className,
    isPreview,
    browserWindowType: {
      mediaQuery: { isMobile, isTouchDevice },
    },
    handleRemoveItem,
    handleRedirectToProductDetails,
  } = props;
  const { photo, type, sku, description, price, priceSet, customizations } =
    cartItem;
  const childrenStone: CartItemStoneImpl[] | undefined = customizations?.stones;
  const visibleDeleteSetPopup = observable.box(false);

  const toggleCartItem = () => setIsDropdownActive((prev) => !prev);

  const handleRemoveCart = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (childrenStone?.length) {
      visibleDeleteSetPopup.set(true);
      return;
    }

    handleRemoveItem && handleRemoveItem(e);
  };

  const handleShowEngraveinformation = useCallback(
    (e: SyntheticEvent, engrave?: CartItemEngraveSettings[]) => {
      const { left, top, width, height } =
        e.currentTarget.getBoundingClientRect();
      const topTransform = top < 330 ? 0 : -50;
      setEngravePosition({
        left: left + width + 10,
        top: top + height,
        topTransform,
      });
      setEngraveState(engrave);
    },
    [setEngravePosition, setEngraveState],
  );

  const handleHideEngraveinformation = useCallback(
    () => setEngraveState(undefined),
    [setEngraveState],
  );

  return (
    <div
      className={joinClasses([
        'cart-item',
        className,
        isPreview && 'cart-item-preview',
        childrenStone?.length && 'cart-item__set',
        childrenStone?.length && isDropdownActive && 'cart-item__set_active',
      ])}
    >
      <div className="cart-item__container" onClick={toggleCartItem}>
        <div className="cart-item__image">
          <ImageItem
            src={
              type === 'stone'
                ? require(`img/shapes/${(
                    cartItem as CartItemStoneImpl
                  ).shape.toLowerCase()}.png`).default
                : photo
            }
          />
        </div>

        <div className="cart-item__characteristics">
          {!!childrenStone?.length && !isPreview && isMobile && (
            <p className="cart-item__price-set diamond-sku_mb-20">
              Set (Diamond included)
            </p>
          )}
          <div className="cart-item__characteristics-header">
            <div className="cart-item__characteristics-header-title">
              <h2 className="diamond-sku bold">{sku}</h2>
              {!!childrenStone?.length && <Icon id="caret" />}
            </div>

            {!isPreview && !isMobile && (
              <div className="cart-item__price">
                <p className="cart-item__price-title">Net cost:</p>
                <p className="diamond-price">{price}</p>
              </div>
            )}
          </div>
          <ul className="diamond-characteristics diamond-characteristics_with-lines diamond-characteristics_small-font">
            {Object.keys(description).map((key) => (
              <li key={key}>
                <span>{key}</span>
                <span>{description[key] || 'N/A'}</span>
              </li>
            ))}
            {type === 'ring' && customizations?.engrave && !isPreview && (
              <li
                className="cart-page__engrave-link"
                onMouseEnter={(e) =>
                  !isTouchDevice &&
                  handleShowEngraveinformation(e, customizations?.engrave)
                }
                onMouseLeave={
                  !isTouchDevice ? handleHideEngraveinformation : undefined
                }
                onClick={(e) =>
                  isTouchDevice &&
                  handleShowEngraveinformation(e, customizations?.engrave)
                }
              >
                Engraving
              </li>
            )}
          </ul>
        </div>

        <div className="flex-wrapper">
          <div className="cart-item__price">
            <h2 className="diamond-price diamond-sku">{priceSet}</h2>
            {!!childrenStone?.length && !isPreview && !isMobile && (
              <p className="cart-item__price-set">Set (Diamond included)</p>
            )}

            {isMobile && (
              <div className="cart-item__net-cost diamond-sku_mb-20">
                <p className="cart-item__net-cost-title">Net cost:</p>
                <p className="cart-item__net-cost-price">{price}</p>
              </div>
            )}
          </div>

          <div className="cart-item__actions">
            <div className="grid-area-actions icons-container">
              <div
                className={joinClasses([
                  !isPreview &&
                    'btn btn_bordered-default btn_circle btn_circle-xl',
                ])}
                onClick={handleRedirectToProductDetails}
              >
                {!isPreview && <Icon id="eye" />}
              </div>

              {cartItem._price ? (
                <div
                  className={joinClasses([
                    !isPreview &&
                      'btn btn_bordered-default btn_circle btn_circle-xl',
                  ])}
                  onClick={handleRemoveCart}
                >
                  {!isPreview && <Icon id="trash" />}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {isDropdownActive &&
        childrenStone
          ?.map(CartItemFactory.getItem)
          ?.map((item: any, index: number) => (
            <div
              key={index}
              className="cart-item__container cart-item__children"
            >
              {isMobile && (
                <div className="cart-item__children-included">
                  <p>Included</p>
                </div>
              )}
              <div className="cart-item__image cart-item__children-image">
                <ImageItem
                  src={
                    item.type === 'stone'
                      ? require(`img/shapes/${(
                          item as CartItemStoneImpl
                        ).shape.toLowerCase()}.png`).default
                      : item.photo
                  }
                />
              </div>

              <div className="cart-item__characteristics cart-item__children-characteristics">
                <div className="cart-item__characteristics-header cart-item__children-characteristics-header">
                  <h2 className="diamond-sku bold">{item.sku}</h2>
                  {!isPreview && !isMobile && (
                    <div className="cart-item__price">
                      <p className="cart-item__price-title">Net cost:</p>
                      <p className="diamond-price">{item.price}</p>
                    </div>
                  )}
                </div>

                <ul className="diamond-characteristics diamond-characteristics_with-lines diamond-characteristics_small-font">
                  {Object.keys(item.description).map((key) => (
                    <li key={key}>
                      <span>{key}</span>
                      <span>{item.description[key] || 'N/A'}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {isMobile && (
                <div className="flex-wrapper">
                  <div className="cart-item__net-cost">
                    <p className="cart-item__net-cost-title">Net cost:</p>
                    <p className="cart-item__net-cost-price">{item.price}</p>
                  </div>
                </div>
              )}
            </div>
          ))}

      {!!handleRemoveItem && (
        <DeleteSetPopup
          visiblePopup={visibleDeleteSetPopup}
          cartItem={cartItem}
          removeCart={handleRemoveItem}
        />
      )}
      {!isPreview && engraveState && (
        <EngraveInformation
          engrave={engraveState}
          engravePosition={!isTouchDevice ? engravePosition : undefined}
          onClose={handleHideEngraveinformation}
        />
      )}
    </div>
  );
});

export default CartListItem;
