import * as React from 'react';
import { observer } from 'mobx-react';
import { TableParamsImpl } from '../../store';
import { CartItemNecklacesImpl } from 'root/store/cart/item.store';

export interface NecklacesTemplateProps {
  product: CartItemNecklacesImpl;
  property: TableParamsImpl;
}

export const NecklacesTemplate = observer((props: NecklacesTemplateProps) => {
  const { product, property } = props;
  return (
    <>
      {(() => {
        switch (property.key) {
          case 'gender':
            return product.gender;
          case 'metal':
            return product.metal;
          case 'necklaceLength':
            return product.necklaceLength;
          case 'claspType':
            return product.claspType;
          case 'chainStyle':
            return product.chainStyle;
          case 'primaryStoneShape':
            return product.primaryStoneShape;
          case 'primaryStoneType':
            return product.primaryStoneType;
          case 'primaryStoneSize':
            return product.primaryStoneSize;

          case 'birthstoneMonth':
            return product.birthstoneMonth;
          case 'initialNumber':
            return product.initialNumber;
          case 'state':
            return product.state;
          default:
            return null;
        }
      })()}
    </>
  );
});
