import React from 'react';
import Svg from '../../Svg';

interface IRadiantMeasWidthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function RadiantMeasWidthIcon(
  props: IRadiantMeasWidthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M139.54 35.09H61.06L47.49 48.66v100.73l12.94 12.94h80.38l12.3-12.94V48.66l-13.57-13.57z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m47.49 149.39 9.32-9.94V57.19l-8.93-8.92M153.11 149.39l-9.32-9.94V57.19l8.93-8.92M56.81 139.52l13.78 11.51-10.16 11.3M143.79 139.45l-14.38 11.58 11.27 11.3M143.79 139.45 131.4 98.83l12.39-41.64-13.92-11.08 9.67-11.02M56.81 139.45 69.2 98.83 56.81 57.19l13.92-11.08-9.67-11.02"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m70.59 151.03 29.71-8.45 29.11 8.45H70.59zM70.53 46.14l29.57 8.03 29.77-8.06-59.34.03z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.59 151a48.121 48.121 0 0 0 2.23-11.58c.11-5.11-3.61-40.62-3.61-40.62s3.19-35.89 3.19-41a52 52 0 0 0-1.87-11.69M129.84 151a47.9 47.9 0 0 1-2.24-11.58c-.11-5.11 3.62-40.62 3.62-40.62s-3.2-35.89-3.2-41a52 52 0 0 1 1.87-11.69"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.81 57.19c5.177.545 10.385.759 15.59.64 6.71-.32 23.23-3.8 27.7-3.66h.4c4.47-.14 21 3.34 27.7 3.66 5.208.119 10.418-.095 15.6-.64M56.81 139.55a122.24 122.24 0 0 1 15.59-.64c6.71.32 23.23 3.8 27.7 3.66h.4c4.47.14 21-3.34 27.7-3.66 5.208-.119 10.418.095 15.6.64"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.11 48.51v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.11 44.1V28.12"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M153.11 26.67v-1.5M47.32 48.51v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.32 44.1V28.12"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M47.32 26.67v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.32 25.09h105.79"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
