import * as React from 'react';
import { GlobalSearch as DefaultComponent } from './GlobalSearch';
import { GlobalSearchStore } from './GlobalSearchStore';
import { GlobalSearchProps } from './GlobalSearchTypes';
import { withRouter } from 'react-router-dom';

const globalSearchStore = new GlobalSearchStore();

export const GlobalSearch = withRouter(
  (props: GlobalSearchProps): JSX.Element => (
    <DefaultComponent {...props} store={globalSearchStore} />
  ),
);
