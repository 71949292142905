import { IObservableArray, makeAutoObservable } from 'mobx';
import store from 'root/store';
import { CountryImpl, StateImpl } from 'root/store/app';

const { app } = store;

export class CartConfirmationStore {
  isPromoCodeMessageShown = '';

  countries: IObservableArray<CountryImpl> = app.countries;

  isVisible = false;
  isDisabled = false;
  isLoading = false; // TODO: move to store for services
  statusRequest = ''; // TODO: move to store for services
  orderId = 0;

  constructor() {
    makeAutoObservable(this);
  }

  showPromoCodeMessage(value: string): void {
    this.isPromoCodeMessageShown = value;
  }

  setLoading(value: boolean): void {
    this.isLoading = value;
  }

  setDisabled(value: boolean): void {
    this.isDisabled = value;
  }

  setVisible(value: boolean): void {
    this.isVisible = value;
  }

  changeStatus(status: string): void {
    this.statusRequest = status;
  }

  setOrderId(orderId: number): void {
    this.orderId = orderId;
  }

  findCountry(id: number): CountryImpl | undefined {
    return this.countries.find((country: CountryImpl) => country.id === id);
  }

  findState(country?: CountryImpl): (id: number) => StateImpl | undefined {
    return (id: number) =>
      country?.states.find((state: StateImpl) => state.id === id);
  }
}

export default new CartConfirmationStore();
