import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

class PortalControl extends React.PureComponent<PropsWithChildren<any>> {
  el: HTMLElement;

  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount(): void {
    document.body.appendChild(this.el);
  }

  componentWillUnmount(): void {
    document.body.removeChild(this.el);
  }

  render = (): React.ReactPortal =>
    ReactDOM.createPortal(this.props.children, this.el);
}

export default PortalControl;
