import { DIAMOND_SHAPES } from 'utils/constants/stones/diamonds.constants';
import { ROUTER_PATHS } from 'root/routes/constants';

export const CAROUSEL_ACTIVE_ITEM = 1;
export const CAROUSEL_ITEM_WIDTH = 1.301;
export const CAROUSEL_ITEM_ACTIVE_WIDTH = 3.385;
export const COEFFICIENT = 0.6505;

export const DIAMONDS_IMG = [
  'asscher',
  'cushion',
  'emerald',
  'heart',
  'marquise',
  'oval',
  'pear',
  'princess',
  'radiant',
  'round',
];

export enum DiamondsFilterParams {
  SHAPE = 'shape',
}

export const STONE_TITLES = {
  title: 'Diamonds',
  subTile: 'Shapes',
  navigatePath: '/stones',
  folderImg: 'shapes',
};

export const SHAPES = [
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_CUSHION_MODIFIED],
      },
    },
    title: 'Cushion',
    description:
      'The Cushion Diamond combines a square cut with rounded corners. Traditional cushion diamonds return light in a chunkier pattern than modern cuts. Combined with the enlarged culet, it creates a distinctive look. Buyers are attracted to the antique feel combined with modern performance of the shape.',
    icon: 'cushion',
    popular: true,
  },
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_EMERALD],
      },
    },
    title: 'Emerald',
    description:
      'The Emerald Diamond unique look is created by the step cuts of its pavilion and large, open table. Emerald Diamonds produce a hall-of-mirrors effect with the interplay of light and dark planes. While less fiery the long lines and dramatic flashes of light give the emerald cut an elegant appeal.',
    icon: 'emerald',
    popular: false,
  },
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_ROUND],
      },
    },
    title: 'Round',
    description:
      'The Round Diamond is the most popular diamond shape. Due to the mechanics of its shape, the round diamond is generally superior to fancy diamond shapes at the proper reflection of light, maximizing potential brightness.',
    icon: 'round',
    popular: true,
  },
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_ASSCHER],
      },
    },
    title: 'Asscher',
    description:
      'The Asscher Diamond is similar to a emerald cut. The shape produces more brilliance than the emerald cut. The asscher cut has cropped corners; the cropped corners give to it a somewhat octagonal shape. Mounted in a four prong setting the diamond maintains its unique shape within a square silhouette.',
    icon: 'asscher',
    popular: false,
  },
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_PRINCESS],
      },
    },
    title: 'Princess',
    description:
      'The Princess Diamond is popular with its pretty square shape. The brightness is impressive, which is why it is often used for engagement rings. Princess diamonds are a good choice for their flexibility in working in almost any style of ring.',
    icon: 'princess',
    popular: false,
  },
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_RADIANT],
      },
    },
    title: 'Radiant',
    description:
      'The Radiant Diamond is the first square cut with a complete brilliant-cut facet pattern, creating a vibrant and lively square shape. The cropped corner square shape is a nice bridge between a cushion and a princess cut, looks beautiful set withboth rounded or square cornered diamonds.',
    icon: 'radiant',
    popular: true,
  },
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_OVAL],
      },
    },
    title: 'Oval',
    description:
      'The Oval Diamond is an ideal choice if you like the look of a round diamond, but wants something more unique. Oval shape has the advantage of an elongated shape, which creates the illusion of greater size. The slender shape makes the finger appear longer and slimmer, an effect often desired.',
    icon: 'oval',
    popular: true,
  },
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_PEAR],
      },
    },
    title: 'Pear',
    description:
      "The modified Pear Diamond is a combination of a round and a marquise shape, with a tapered point on one end. The diamond is worn with the narrow end pointing toward the hand of the wearer. The Pear shaped diamond has the added benefit of making the wearer's fingers appear longer and slimmer.",
    icon: 'pear',
    popular: false,
  },
  {
    link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
    params: {
      filters: {
        [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_MARQUISE],
      },
    },
    title: 'Marquise',
    description:
      'The Marquise Diamonds are long and narrow, they create the illusion of greater size.This shape has one of the largest crown of any other shape, making it a good choicewhen trying to maximize the perceived size of a diamond. Its elongated shape can make the finger appear longer and slimmer.',
    icon: 'marquise',
    popular: false,
  },
];
