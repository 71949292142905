import { makeAutoObservable } from 'mobx';

export const SORT_BY = 'id';
export const SORT_ORDER = 'ASC';

export default class OrderStore {
  sortBy: string = SORT_BY;
  sortOrder: string = SORT_ORDER;

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  /**
   * Update order values
   * @param {String} sortBy
   * @param {String} sortOrder
   */

  updateOrder(sortBy: string, sortOrder: string): void {
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
  }

  setSortBy(sortBy: string): void {
    this.sortBy = sortBy;
  }

  setSortOrder(sortOrder: string): void {
    this.sortOrder = sortOrder;
  }

  reset(): void {
    this.sortBy = SORT_BY;
    this.sortOrder = SORT_ORDER;
  }
}
