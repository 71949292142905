import React from 'react';
import Svg from '../../Svg';

interface IHeartDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function HeartDepthIcon(
  props: IHeartDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M189.81 56.05v85.79"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.52 142.73h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.07 142.73h65.58"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.05 3.05"
      />
      <path
        d="M188.18 142.73h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m116.52 141.84 17.26-10.79 46.03-49.24v-3.98l-14.38-14.96-14.15-6.85H61.87l-26.85 6.85-16.06 14.96v3.98l73.11 46.84 24.45 13.19z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 81.81a41.35 41.35 0 0 1 14.7.82c1.28-.16 9.75-1.86 18.22 0h26.81s15-.6 25.41 2.54c1.44.17 12.74-3.14 29.88 0 2.72-.14 10.43-3 24.78-.94 3.87-1.48 11-.77 11-.77l10-1.65"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m33.66 82.63 58.41 46.02-40.19-46.02 55.42 44.59-28.57-44.59M134.02 85.17l-12.22 45.52-17.66-45.52 3.16 42.05 9.22 14.62 5.28-11.15 36.99-46.46-25.01 46.82 36.04-47.59M19 77.83s13.06 0 14 1.32c.29.25 11.27-.84 19.18 0 8.822.495 17.664.532 26.49.11 1.92-.1 11.1 1.29 14.74 2.24 3.24.05 10.75 0 10.75 0H134s22.3-.19 24-.79c1.55-.12 11.82-1 11.82-1s8.43-1.13 10-1.85"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m32.98 79.15 2.04-16.28 17.14 16.28 35.36-13.9-11.27-9.23M78.65 79.26l8.87-14.01 21.09-9.23 20.62 8.87 10.55-8.87 25.65 6.85 4.39 16.81"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.43 62.87 158 80.71l-28.77-15.82 4.79 16.61M87.52 65.25l16.62 16.25 25.09-16.61"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.28 55.16h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.01 55.16h30.69"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.23 3.23"
      />
      <path
        d="M188.31 55.16h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
