import React from 'react';
import Svg from '../Svg';

interface IStarChristmasIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function StarChristmasIcon(
  props: IStarChristmasIconProps,
): JSX.Element {
  return (
    <Svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
      <path
        d="M13.3164 6.90234L9.98047 6.08203L11.6484 4.00391C11.8672 3.75781 11.8398 3.375 11.5938 3.12891C11.375 2.91016 10.9922 2.88281 10.7188 3.10156L8.66797 4.74219L7.84766 1.43359C7.73828 1.02344 7.38281 0.75 7 0.75H6.97266C6.58984 0.75 6.23438 1.02344 6.125 1.43359L5.30469 4.76953L3.25391 3.10156C2.98047 2.88281 2.59766 2.91016 2.37891 3.15625C2.13281 3.375 2.10547 3.75781 2.32422 4.03125L3.96484 6.08203L0.65625 6.90234C0.273438 7.01172 0 7.36719 0 7.75C0 8.16016 0.273438 8.51562 0.65625 8.625L3.99219 9.44531L2.32422 11.4961C2.10547 11.7695 2.13281 12.1523 2.37891 12.3711C2.48828 12.5078 2.65234 12.5898 2.81641 12.5898C2.98047 12.5898 3.11719 12.5352 3.25391 12.4258L5.30469 10.7852L6.125 14.0938C6.23438 14.5039 6.58984 14.7773 6.97266 14.7773H7C7.38281 14.7773 7.73828 14.5039 7.84766 14.0938L8.66797 10.7578L10.7461 12.4258C10.8555 12.5352 10.9922 12.5898 11.1562 12.5898C11.3203 12.5898 11.4844 12.5078 11.5938 12.3711C11.8398 12.1523 11.8672 11.7695 11.6484 11.4961L10.0078 9.44531L13.3164 8.625C13.7266 8.51562 14 8.16016 14 7.75C14 7.36719 13.7266 7.01172 13.3164 6.90234ZM7.84766 8.625L7 12.0977L6.125 8.625L2.65234 7.75L6.125 6.90234L7 3.42969L7.84766 6.90234L11.3203 7.75L7.84766 8.625Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
