import * as React from 'react';
import { PaymentOptionsFactory as DefaultComponent } from './PaymentOptions';
import { FormControl } from '@quantumart/mobx-form-validation-kit';
import CartStore from 'root/store/cart';
import { PaymentFormsStore } from '../paymentForms.store';

export interface IPaymentTypesFactoryProps {
  paymentType: string;
  store: PaymentFormsStore;
  cart: CartStore;
  handleChange: (
    formControl: FormControl,
  ) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleBlur: (
    formControl: FormControl,
  ) => (event: React.SyntheticEvent) => void;
}

export const PaymentTypesFactory = (
  props: IPaymentTypesFactoryProps,
): JSX.Element => <DefaultComponent {...props} />;
