import React from 'react';
import Svg from '../Svg';

interface IMinusCircleIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function MinusCircleIcon(
  props: IMinusCircleIconProps,
): JSX.Element {
  return (
    <Svg width="18" height="18" viewBox="0 0 512 512" fill="none" {...props}>
      <path
        fill="#1c1c1f"
        d="M140 284c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v32c0 6.6-5.4 12-12 12H140zm364-28c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z"
      />
    </Svg>
  );
}
