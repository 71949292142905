import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function ClarityDiamond05(props: IProps): JSX.Element {
  return (
    <Svg width="104" height="72" viewBox="0 0 104 72" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M52.05 71 103 26.554l-.302-5.656-9.364-10.202-17.52-7.374L52.05 1 28.187 3.323l-17.42 7.374-9.465 10.202L1 26.555 52.05 71Z"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m52.05 71 4.834-12.929 13.593-18.687 17.32-8.484 3.624-11.111-6.746-10.404-8.861-6.06"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m84.675 9.385-8.559 5.252-24.065 2.121 15.808 7.374L87.796 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m76.116 14.637-8.257 9.495-15.808 11.515M91.42 19.788l-15.304-5.151M52.05 35.647l18.427 3.737M52.05 40.092l18.226-6.06 16.11 1.615 10.573-6.565L103 26.557M96.959 29.082 87.796 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m103 26.555-11.58-6.768 5.538 9.293M93.334 10.697 103 26.556M84.674 9.385l8.66 1.313M67.859 24.13l2.416 9.9M86.386 35.647 72.39 53.222M56.883 58.07l29.503-22.423M52.05 40.09l4.834 17.98M52.05 71l-4.933-12.929-13.594-18.687L16.204 30.9 12.58 19.789l6.847-10.404 8.76-6.06"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m19.427 9.385 8.558 5.252 24.066 2.121-15.91 7.374L16.205 30.9"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m27.985 14.637 8.156 9.495L52.05 35.647M12.58 19.788l15.405-5.151M52.05 35.647l-18.527 3.737M52.05 40.092l-18.325-6.06-16.111 1.615-10.472-6.565L1 26.557M7.142 29.082l9.062 1.818"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m1 26.555 11.58-6.768-5.438 9.293M10.767 10.697 1 26.556M19.427 9.385l-8.66 1.313M36.141 24.13l-2.416 9.9M17.614 35.647 31.61 53.222M47.117 58.07 17.614 35.648M52.05 40.09l-4.934 17.98"
        stroke="#000"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m22.4 17.3-5.6 3.9 3 3.4 5.4-4.3-2.8-3ZM51.4 21.4 45.7 26l2.8 3.8 6.2-4.9-3.3-3.5ZM64.3 5.9l1.1 5.2 4.5-1.8-1.5-4.7-4.1 1.3ZM80.4 20.6l-2 3.7 4.6 2 1.6-4.1-4.2-1.6Z"
        fill="#54A97E"
      />
    </Svg>
  );
}
