import * as React from 'react';
import { ForgotPassword as DefaultComponent } from './ForgotPassword';
import { ForgotPasswordStore } from './ForgotPassword.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const forgotPasswordStore = new ForgotPasswordStore();

export const ForgotPassword = withRouter((props: RouteComponentProps) => (
  <DefaultComponent {...props} store={forgotPasswordStore} />
));
