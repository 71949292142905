import React, { useEffect, useMemo, useRef, useState } from 'react';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import CustomSelect from 'components/form/CustomSelect';
import {
  CartItemEngraveSettings,
  IEngraving,
  ProductDetailPageImpl,
} from 'pages/ProductDetailPage/ProductDetailPage.types';
import {
  getValuesForFillSelect,
  getValuesForFontSelect,
} from './EngravePopup.helper';
import Icon from 'components/common/Icon';

import './EngravePopup.scss';

export interface IProps {
  onClose(): void;
  onSave(
    props: CartItemEngraveSettings | undefined,
    index: number | null,
    prevSettings?: CartItemEngraveSettings,
  ): void;
  index: number | null;
  engraving: IEngraving[];
  engraveSettings: ProductDetailPageImpl['engravingSettings'];
}

const settingsType = {
  fonts: 'font',
  fillOptions: 'color',
};

const EngravePopup = ({
  index,
  engraving,
  engraveSettings,
  onClose,
  onSave,
}: IProps): JSX.Element => {
  const engravingItem = useMemo(
    () => (index !== null ? engraving[index] : undefined),
    [engraving, index],
  );

  const isEditSettings = index !== null && engraveSettings[index];

  const defaultSettings = useMemo(
    () =>
      engravingItem
        ? {
            description: engravingItem.description,
            locationNumber: engravingItem.locationNumber,
            engraveTypeCode: engravingItem.engraveTypeCode,
            price: engravingItem.price,
            text: '',
            font: engravingItem.fonts[0],
            color: engravingItem.fillOptions[0],
          }
        : ({} as CartItemEngraveSettings),
    [engravingItem],
  );

  const [settings, setSettings] = useState(isEditSettings || defaultSettings);

  const prevSettings = useRef(settings);

  const maxCharacters = useMemo(() => {
    return settings?.font?.maxCharacters || 1000;
  }, [settings]);

  const handleChangeMessage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.target.value.length > maxCharacters) return;
    setSettings((prev) => ({ ...prev, text: e.target.value }));
  };

  const handleChangeSelect = (
    value: { name: string },
    type: 'fonts' | 'fillOptions',
  ) => {
    if (!engravingItem) return;
    const selectedItem = engravingItem[type].find(
      (item) => item.name === value.name,
    );
    setSettings((prev) => ({ ...prev, [settingsType[type]]: selectedItem }));
  };

  const resetSettings = () => {
    engravingItem && setSettings(defaultSettings);
  };

  const handleReset = () => {
    resetSettings();
  };

  useEffect(() => {
    if (index === null) return;
    if (!engraveSettings?.[index]) {
      // new id
      resetSettings();
    } else {
      // same id
      engraveSettings?.[index] &&
        setSettings(
          engraveSettings?.[index] || ({} as CartItemEngraveSettings),
        );
    }
    engravingItem?.description &&
      setSettings((prev) => ({
        ...prev,
        type: engravingItem.description,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleSave = () => {
    onSave(settings, index, isEditSettings ? prevSettings.current : undefined);
    onClose();
    resetSettings();
  };
  const valuesForFontSelect = getValuesForFontSelect(engravingItem);
  const valuesForFillSelect = getValuesForFillSelect(engravingItem);

  return (
    <PortalControl>
      <ModalWindow
        visible={true}
        className="engrave-popup"
        footerText={
          " Please note, engraved jewelry can't be returned for a refund or exchange."
        }
      >
        <div
          className="btn btn_bordered-default btn_circle modal-window__btn-close"
          onClick={onClose}
        >
          <Icon id="close" />
        </div>

        <div className="modal-window-header">
          <h2 className="modal-window-header__title">
            {settings.description} band
          </h2>
        </div>

        <div className="modal-window-body engrave-popup__content">
          <div className="engrave-popup__description">
            Add a personal touch to your ring creation by using our engrave
            service.
          </div>

          {valuesForFontSelect && (
            <div className="engrave-popup__font-field">
              <span className="engrave-popup__message-input-info">
                Select style
              </span>
              <CustomSelect
                name=""
                label=""
                activeClassName="options-list__item_active-with-check-icon"
                values={valuesForFontSelect}
                customKey="bage"
                defaultValue={
                  Number(settings.font.id) || valuesForFontSelect[0].id
                }
                onChange={(_, value) => handleChangeSelect(value, 'fonts')}
              />
            </div>
          )}

          {valuesForFillSelect && (
            <div className="engrave-popup__color-field">
              <span className="engrave-popup__message-input-info">
                Select color
              </span>
              <CustomSelect
                name=""
                label=""
                activeClassName="options-list__item_active-with-check-icon"
                values={valuesForFillSelect}
                customKey="bage"
                defaultValue={
                  Number(settings.color.id) || valuesForFillSelect[0].id
                }
                onChange={(_, value) =>
                  handleChangeSelect(value, 'fillOptions')
                }
              />
            </div>
          )}

          <div className="engrave-popup__message-input">
            <span className="engrave-popup__message-input-info">
              Add your message for {settings.description.toLocaleLowerCase()}{' '}
              band
            </span>
            <div className="engrave-popup__message-input-field-wrapper">
              <input
                className="engrave-popup__message-input-field"
                value={settings.text}
                type="text"
                placeholder="You can leave a message"
                onChange={handleChangeMessage}
              />
              <span>
                {settings.text.length} / {settings.font.maxCharacters}
              </span>
            </div>
          </div>
          <div
            className="engrave-popup__message-preview"
            style={{
              color:
                settings.color.name !== 'No Color'
                  ? settings.color.name.toLowerCase()
                  : undefined,
              fontFamily: settings.font.name,
            }}
          >
            <span>{settings.text}</span>
          </div>

          <div className="engrave-popup__actions">
            <button
              className="btn btn_text-bold btn_bordered-default btn_fixed-height"
              onClick={handleReset}
            >
              Reset All
            </button>
            <button
              className="btn btn_brown btn_fixed-height"
              onClick={handleSave}
              disabled={!settings.text}
            >
              Save
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
};

export default EngravePopup;
