import React from 'react';
import { observer } from 'mobx-react';

interface IProps {
  cost: string;
  isMobile?: boolean;
}

const Cost = ({ cost, isMobile }: IProps) => {
  return (
    <div className="add-set-to-cart-popup__price-block">
      <span>Net cost:</span>
      <span className="add-set-to-cart-popup__price-block-price">{cost}</span>
      {!isMobile && (
        <span className="add-set-to-cart-popup__price-block-info">Item(s)</span>
      )}
    </div>
  );
};

export default observer(Cost);
