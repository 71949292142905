import React from 'react';

interface IProps {
  label: string;
  value: string;
}

const DetailsBlock = ({ label, value }: IProps): null | JSX.Element =>
  !value ? null : (
    <div className="orders-current-window__details-field">
      <div className="orders-current-window__details-field-label">{label}</div>
      <div className="orders-current-window__details-field-value">{value}</div>
    </div>
  );

export default DetailsBlock;
