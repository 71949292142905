import React from 'react';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Icon from 'components/common/Icon';

import './error500.scss';

type TError500 = {
  title?: string;
  visible: boolean;
  handleVisible: (value: boolean) => void;
};

function Error500(props: TError500): JSX.Element {
  const { visible, handleVisible, title } = props;

  const closeModal = () => handleVisible(false);

  return (
    <PortalControl>
      <ModalWindow visible={visible} modalSize="md">
        <div
          className="btn btn_bordered-default btn_circle modal-window__btn-close"
          onClick={closeModal}
        >
          <Icon id="close" />
        </div>

        <div className="modal-window-header error-500-header">
          <h2 className="modal-window-header__title error-500-header__title">
            Error 500
          </h2>

          <div className="modal-window-body__text modal-window-body__text_center modal-window-body__text_medium error-500-header__title__subtitle">
            {title}
          </div>
        </div>

        <div className="modal-window-body">
          <p className="modal-window-body__text modal-window-body__text_center modal-window-body__text_medium modal-window-body__text_my-10">
            Sorry. Something went wrong.
          </p>
          <p className="modal-window-body__text modal-window-body__text_center modal-window-body__text_medium modal-window-body__text_my-10">
            Please try again.
          </p>

          <div className="modal-window-body-btn-wrap">
            <button
              className="btn btn_sm btn_brown btn_font-montserrat error-500-btn"
              type="button"
              onClick={closeModal}
            >
              Try again
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
}

export default Error500;
