export enum CartRoutes { // TODO: move to app!
  Cart = '/cart',
  Checkout = '/cart/checkout',
  Customer = '/cart/checkout/customer',
  Shipping = '/cart/checkout/shipping',
  Payment = '/cart/checkout/payment',
  Confirmation = '/cart/confirmation',
}

export const REMOVE_CHILD = 1;
export const REMOVE_PARENT = 0; // remove the parent product leaving the child

export enum LevelPropductForSet {
  Parent = 'parent',
  Child = 'child',
}

export enum PaymentTypes {
  creditCard = 'authorize',
  bankWire = 'bankWire',
  affirm = 'affirm',
}

export const PaymentTypesTitle: { [key: string]: string } = {
  [PaymentTypes.creditCard]: 'Credit Card',
  [PaymentTypes.bankWire]: 'Bank Wire',
  [PaymentTypes.affirm]: 'Affirm',
};
