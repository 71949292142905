import { IObservableArray, makeAutoObservable, observable, toJS } from 'mobx';
import { FilterRange } from 'root/store/products/filterRange.store';
import { IFilterJewerlyExtraParams, QueryParamsJewelryFilters } from '../types';

export enum JewelryFilterParams {
  PRICE = 'price',
  METAL = 'metal',
  CATEGORIES = 'categories',
  COLLECTION = 'collection',
  PROMO = 'promo',
}

/**
 * Filters panel model
 */
export class JewelryFiltersStore {
  price!: FilterRange;
  metal: IObservableArray = observable([]);
  categories: IObservableArray = observable([]);
  collection: IObservableArray = observable([]);
  promo: IObservableArray = observable([]);

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  get queryParams(): JewelryFiltersStore {
    return toJS(this);
  }

  update(filters: QueryParamsJewelryFilters) {
    const { price, metal, categories, collection, promo } = filters;

    price && this.price.setRange(Number(price.from), Number(price.to));
    metal && this.metal.replace(metal);
    categories && this.categories.replace(categories);
    collection && this.collection.replace(collection);
    promo && this.promo.replace(promo);
  }

  updateByExtraParams(params: IFilterJewerlyExtraParams) {
    const { price } = params;

    this.price.setRange(Number(price?.min) || 0, Number(price?.max) || 999999);
  }

  reset() {
    this.price = new FilterRange(0, 999999);
    this.metal.clear();
    this.categories.clear();
    this.collection.clear();
    this.promo.clear();
  }
}
