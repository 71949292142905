import React from 'react';

export interface SvgProps {
  children: React.ReactNode;
  viewBox?: string;
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  shapeRendering?: string;
}

export default function Svg(props: SvgProps): JSX.Element {
  const { children, ...restProps } = props;

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...restProps}
    >
      {children}
    </svg>
  );
}
