import * as React from 'react';
import { observer } from 'mobx-react';
import CartStore from 'root/store/cart';
import { CartItemImpl } from 'root/store/cart/item.store';
import { joinClasses } from 'lib/utils';

interface ItemButtonProps {
  doesServerHasItem: (id: number) => boolean;
  doesCartHasItem: (productId: number) => CartItemImpl | undefined;
  cart: CartStore;
  id: number;
  guid: string;
  handleAddToCart: () => void;
}

export const ItemButton = observer((props: ItemButtonProps) => {
  const { doesCartHasItem, doesServerHasItem, cart, id, handleAddToCart } =
    props;
  const itemCart = cart.getCartItemById(id);
  const isServerHasItem = doesServerHasItem(id);

  return (
    <button
      style={{ cursor: 'pointer' }}
      className={joinClasses([
        'btn btn_bordered-dark-gray btn_rounded btn_text-big',
        itemCart && 'active',
        !isServerHasItem && 'disabled',
      ])}
      onClick={handleAddToCart}
      disabled={!isServerHasItem}
    >
      {doesCartHasItem(id) ? 'Added' : 'Add'} to Cart
    </button>
  );
});
