import { computed, IObservableArray, observable, override } from 'mobx';
import Currency, { CurrencyImpl } from '../currency';
import { CountryImpl, StateImpl } from '../app';
import {
  CategoriesProductImpl,
  DIAMOND_CATEGORY_TITLE,
  ProductTypes,
} from '../../ProductTypes';
import { FormGroup } from '@quantumart/mobx-form-validation-kit';
import { PaymentForm, ShippingForm, CustomerForm } from './_index_';
import { CartItemEngraveSettings } from 'pages/ProductDetailPage/ProductDetailPage.types';
import {
  BraceletsDescription,
  EarringsDescription,
  NecklaceDescription,
  PendantDescription,
  RingDescription,
  StoneDescription,
} from './types';

/**
 * Interface cart item detail
 */
export interface CartItemImpl {
  id: number;
  guid: string;
  quantity: number;
  sku: string;
  type: string;
  _price: number;
  price: string | number;
  priceSet: string | number;
  priceSetValue: number;
  photo: string;
  currency: CurrencyImpl;
  categories: CategoriesProductImpl[];
  customizations?: {
    [k: string]: any;
    engrave?: CartItemEngraveSettings[];
    stones?: CartItemStone[];
  };
  gradingLab: string;
  description: { [key: string]: any };
  children: CartItemStone[];
}

export interface CartItemStoneImpl extends CartItemImpl {
  categories: CategoriesProductImpl[];
  shape: string;
  carat: string;
  color: string;
  clarity: string;
  cut: string;
  certificate: string;
  fluorescence: string;
  polish: string;
  description: StoneDescription;
}

export interface CartProductsImpl extends CartItemImpl {
  primaryStoneType: string;
  primaryStoneShape: string;
  primaryStoneSize: string;
}

export interface CartItemRingImpl extends CartProductsImpl {
  size: string;
  gender: string;
  profile: string;
  width: string;
  weight: string;
  jeweleryImage: string;
  metal: string;
  quality: string;
  description: RingDescription;
  jewelryCollection: string;
  state: string;
  initialNumber: string;
}

export interface CartItemJewelryImpl extends CartProductsImpl {
  jeweleryImage: string;
  metal: string;
  quality: string;
  gender: string;
  collection: string;
  jewelryCollection: string;
}

export interface CartItemPendantImpl extends CartItemJewelryImpl {
  pendantType: string;
  description: PendantDescription;
  state: string;
  initialNumber: string;
  birthstoneMonth: string;
}

export interface CartItemEarringsImpl extends CartItemJewelryImpl {
  closureType: string;
  earringType: string;
  earringPostType: string;
  stoneClarityColor: string;
  state: string;
  description: EarringsDescription;
}

export interface CartItemBraceletsImpl extends CartItemJewelryImpl {
  closureType: string;
  description: BraceletsDescription;
  initialNumber: string;
  chainLength: string;
  state: string;
}

export interface CartItemNecklacesImpl extends CartItemJewelryImpl {
  closureType: string;
  necklaceLength: string;
  claspType: string;
  chainStyle: string;
  description: NecklaceDescription;
  initialNumber: string;
  birthstoneMonth: string;
  state: string;
}

export interface CustomerCartImpl {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: CountryImpl;
  state: StateImpl;
  city: string;
  address: string;
  unit: string;
  zip: string;
}

export interface DeliveryCartImpl {
  diffRecipient: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: CountryImpl;
  state: StateImpl;
  city: string;
  address: string;
  unit: string;
  zip: string;
}

export interface PaymentCartImpl {
  sameShippingAddress: boolean;
  country: CountryImpl;
  state: StateImpl;
  city: string;
  address: string;
  unit: string;
  zip: string;
  details: string;
}

export interface CartCustomerInfoImpl {
  customer: CustomerCartImpl;
  delivery: DeliveryCartImpl;
  payment: PaymentCartImpl;
}

export type CartDescription =
  | RingDescription
  | BraceletsDescription
  | NecklaceDescription
  | StoneDescription
  | PendantDescription
  | EarringsDescription;

class CartItem implements CartItemImpl {
  @observable id = 0;
  @observable guid = '';
  @observable quantity = 0;
  @observable sku = '';
  @observable type = '';
  @observable _price = 0;
  @observable _priceSet = 0;
  @observable photo = '';
  @observable currency: CurrencyImpl = new Currency();
  @observable categories = [] as CategoriesProductImpl[];
  @observable gradingLab = '';
  @observable children: CartItemStone[] = [];

  @observable customizations?: { [k: string]: any }; // TODO: in implement

  constructor(item: CartItemImpl) {
    this.id = item.id;
    this.guid = item.guid;
    this.quantity = item.quantity;
    this.sku = item.sku;
    this.type = item.type;
    this._price = item.price as number;
    this._priceSet = item.priceSet as number;
    item.currency && this.currency.update(item.currency);
    this.categories = item.categories;
    this.gradingLab = item.gradingLab;
    this.children = item.children
      ? item.children.map(
          (c) =>
            new CartItemStone({
              ...c,
              currency: item.currency,
            } as CartItemStone), // TODO: Currency Hack! Need refactoring!
        )
      : [];
  }

  @computed
  get description() {
    return {} as CartDescription;
  }

  @computed
  get price(): string {
    return this.currency.format(this._price);
  }

  @computed
  get priceSet(): string {
    return this.currency.format(this._priceSet);
  }

  @computed
  get priceSetValue(): number {
    return this._priceSet;
  }
}

/**
 * Cart item details with stone type
 */
class CartItemStone extends CartItem {
  @observable shape = '';
  @observable _carat = '';
  @observable color = '';
  @observable clarity = '';
  @observable cut = '';
  @observable certificate = '';
  @observable fluorescence = '';
  @observable polish = '';
  @observable stoneType = '';

  constructor(item: CartItemStoneImpl) {
    super(item);
    this.shape = item.shape;
    this._carat = item.carat;
    this.color = item.color;
    this.clarity = item.clarity;
    this.cut = item.cut;
    this.certificate = item.certificate;
    this.fluorescence = item.fluorescence;
    this.polish = item.polish;
    this.customizations = item.customizations;
    this.stoneType = item.categories
      ? DIAMOND_CATEGORY_TITLE[item.categories[0]?.alias]
      : 'N/A';
  }

  @computed
  get carat() {
    return `${this._carat} ct`;
  }

  @override
  get description() {
    return {
      carat: this.carat,
      shape: this.shape,
      color: this.color,
      stoneType: this.stoneType,
    };
  }
}

/**
 * Cart item details with rings type
 */
class CartItemRing extends CartItem {
  @observable size = '';
  @observable gender = '';
  @observable profile = '';
  @observable weight = '';
  @observable width = '';
  @observable metal = '';

  @observable primaryStoneShape = '';
  @observable primaryStoneType = '';
  @observable primaryStoneSize = '';
  @observable state = '';
  @observable collection = '';
  @observable initialNumber = '';

  constructor(item: CartItemRingImpl) {
    super(item);
    this.size = item.size;
    this.gender = item.gender;
    this.profile = item.profile;
    this.weight = item.weight;
    this.width = item.width;
    this.photo = item.jeweleryImage || item.photo;
    this.metal = item.metal || item.quality;

    this.primaryStoneShape = item.primaryStoneShape;
    this.primaryStoneType = item.primaryStoneType;
    this.primaryStoneSize = item.primaryStoneSize;
    this.state = item.state;
    this.collection = item.jewelryCollection;
    this.customizations = item.customizations;
    this.initialNumber = item.initialNumber;
  }

  @override
  get description() {
    return {
      size: this.size,
      metal: this.metal,
      primaryStoneShape: this.primaryStoneShape,
      primaryStoneType: this.primaryStoneType,
    };
  }
}

/**
 * Cart item details with fine jewelry type
 */
class CartItemJewelry extends CartItem {
  @observable metal = '';
  @observable gender = '';
  @observable collection = '';

  @observable primaryStoneShape = '';
  @observable primaryStoneType = '';
  @observable primaryStoneSize = '';

  constructor(item: CartItemJewelryImpl) {
    super(item);
    this.photo = item.jeweleryImage || item.photo;
    this.metal = item.metal || item.quality;
    this.gender = item.gender;
    this.collection = item.collection || item.jewelryCollection;

    this.primaryStoneShape = item.primaryStoneShape;
    this.primaryStoneType = item.primaryStoneType;
    this.primaryStoneSize = item.primaryStoneSize;
  }

  @override
  get description() {
    return {} as CartDescription;
  }
}

class CartItemPendant extends CartItemJewelry {
  @observable pendantType = '';
  @observable birthstoneMonth = '';
  @observable initialNumber = '';
  @observable state = '';

  constructor(item: CartItemPendantImpl) {
    super(item);
    this.pendantType = item.pendantType;

    this.birthstoneMonth = item.birthstoneMonth;
    this.initialNumber = item.initialNumber;
    this.state = item.state;
    this.customizations = item.customizations;
  }

  @override
  get description() {
    return {
      metal: this.metal,
      pendantType: this.pendantType,
      primaryStoneShape: this.primaryStoneShape,
      primaryStoneType: this.primaryStoneType,
    };
  }
}

class CartItemEarrings extends CartItemJewelry {
  @observable earringType = '';
  @observable closureType = '';

  @observable state = '';
  @observable earringPostType = '';
  @observable stoneClarityColor = '';

  constructor(item: CartItemEarringsImpl) {
    super(item);
    this.earringType = item.earringType;
    this.closureType = item.closureType;

    this.earringPostType = item.earringPostType;
    this.stoneClarityColor = item.stoneClarityColor;
    this.state = item.state;
    this.customizations = item.customizations;
  }

  @override
  get description() {
    return {
      earringType: this.earringType,
      metal: this.metal,
      primaryStoneShape: this.primaryStoneShape,
      primaryStoneType: this.primaryStoneType,
    };
  }
}

class CartItemBracelets extends CartItemJewelry {
  @observable closureType = '';
  @observable initialNumber = '';
  @observable chainLength = '';
  @observable state = '';

  constructor(item: CartItemBraceletsImpl) {
    super(item);
    this.closureType = item.closureType;

    this.initialNumber = item.initialNumber;
    this.chainLength = item.chainLength;
    this.state = item.state;
    this.customizations = item.customizations;
  }

  @override
  get description() {
    return {
      chainLength: this.chainLength,
      metal: this.metal,
      primaryStoneShape: this.primaryStoneShape,
      primaryStoneType: this.primaryStoneType,
    };
  }
}

class CartItemNecklaces extends CartItemJewelry {
  @observable closureType = '';
  @observable necklaceLength = '';
  @observable claspType = '';
  @observable chainStyle = '';

  @observable birthstoneMonth = '';
  @observable initialNumber = '';
  @observable state = '';

  constructor(item: CartItemNecklacesImpl) {
    super(item);
    this.closureType = item.closureType;
    this.necklaceLength = item.necklaceLength;
    this.claspType = item.claspType;
    this.chainStyle = item.chainStyle;

    this.initialNumber = item.initialNumber;
    this.birthstoneMonth = item.birthstoneMonth;
    this.state = item.state;
    this.customizations = item.customizations;
  }

  @override
  get description() {
    return {
      chainStyle: this.chainStyle,
      metal: this.metal,
      primaryStoneShape: this.primaryStoneShape,
      primaryStoneType: this.primaryStoneType,
    };
  }
}

export interface CartStoreImpl {
  _tax: string;
  _total: string;
  _shipping: string;

  items: IObservableArray<CartItemImpl>;
  currency: CurrencyImpl;
  tax: string;
  total: string;

  // TODO: map to interface!!
  customer: FormGroup<CustomerForm>;
  shipping: FormGroup<ShippingForm>;
  payment: FormGroup<PaymentForm>;

  reset: () => void;
  putToCart: (item: CartItemImpl) => void;
  removeFromCart: (item: CartItemImpl) => void;
  find: () => void;
  put: (item: { id: number; quantity: number }) => void;
  remove: (guid: string, removeChild: number) => void;
  clear: () => void;
}

export abstract class CartItemFactory {
  static getItem = (item: any): any => {
    if (item.type) {
      switch (item.type) {
        case ProductTypes.Stone:
          return new CartItemStone(item);
        case ProductTypes.Ring:
          return new CartItemRing(item);
        case ProductTypes.Earrings:
          return new CartItemEarrings(item);
        case ProductTypes.Pendant:
          return new CartItemPendant(item);
        case ProductTypes.Bracelets:
          return new CartItemBracelets(item);
        case ProductTypes.Necklaces:
          return new CartItemNecklaces(item);
      }
    }
  };
}
