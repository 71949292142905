import { makeAutoObservable, runInAction } from 'mobx';
import { getCurrentProfile } from 'service/api/account';

export interface UserParamsImpl {
  id: number | null;
  firstName: string;
  lastName: string;
  email: string;
  picturePath: string;
}

export interface UserStoreImpl {
  params: UserParamsImpl;
  isAuth: boolean;
  isLoaded: boolean;
  handleUserAuth: (value: boolean) => void;
  fetchCurrentUser: () => Promise<UserParamsImpl>;
}

export default class UserStore implements UserStoreImpl {
  isLoaded = false;
  isAuth = false;
  params: UserParamsImpl = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    picturePath: '',
  };

  constructor() {
    makeAutoObservable(this);
    void this.fetchCurrentUser();
  }

  handleUserAuth = (value: boolean): void => {
    runInAction(() => {
      this.isAuth = value;
    });
  };

  fetchCurrentUser = async (): Promise<UserParamsImpl> => {
    try {
      const params = await getCurrentProfile();

      runInAction(() => {
        this.params = { ...params };
        this.isAuth = !!params.id;
        this.isLoaded = true;
      });

      return params;
    } catch (error: any) {
      runInAction(() => {
        this.isAuth = false;
        this.isLoaded = true;
      });
      return error;
    }
  };
}
