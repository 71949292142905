import React, { FormEvent, useEffect } from 'react';
import { activateForgotPassword, sendNewPassword } from 'service/api/auth';
import qs from 'qs';
import { observer } from 'mobx-react';
import { InputFormControl } from '@quantumart/mobx-form-validation-kit';
import { joinClasses } from 'lib/utils';
import { IForgotPasswordProps } from './ForgotPassword.types';
import {
  ACTIVATION_ERROR,
  ACTIVATION_SUCCESS,
  COMPLETED_REDIRECT_DELAY,
  CREATION_FAILED,
  CREATION_PENDING,
  CREATION_SUCCESS,
  getContentValue,
} from './forgotPassword.constants';

import './ForgotPassword.scss';

export const ForgotPassword = observer(
  ({
    store,
    location: { search },
    history,
  }: IForgotPasswordProps): JSX.Element | null => {
    let timeout: ReturnType<typeof setInterval>;
    const { password, passwordConfirm } = store.form.controls;

    const checkActivateToken = async () => {
      if (search) {
        const searchParams = qs.parse(search.slice(1)) || {};
        const { token } = searchParams;
        try {
          await activateForgotPassword(token as string);
          return store.setStatus(ACTIVATION_SUCCESS);
        } catch (error) {
          return store.setStatus(ACTIVATION_ERROR);
        }
      }
    };

    const getContentByType = () => {
      const { resendTimerCount, status } = store;
      const content = getContentValue(status, resendTimerCount);
      content && store.setContent(content);
    };

    useEffect(() => {
      void checkActivateToken();
      return () => clearInterval(timeout);
    }, []);

    useEffect(() => {
      getContentByType();
    }, [store.status, store.resendTimerCount]);

    const setTimeout = (delay: number, callback?: () => void): void => {
      timeout && clearInterval(timeout);

      store.setTimerCount(delay);

      timeout = setInterval(() => {
        const { resendTimerCount } = store;
        const currentCount = resendTimerCount - 1;

        store.setTimerCount(currentCount);
        getContentByType();

        if (currentCount === 0) {
          timeout && clearInterval(timeout);
          callback && callback();
        }
      }, 1000);
    };

    const handleSubmit = async (
      event: FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();
      const isValidForm = [password, passwordConfirm].every(
        (input) => !input.invalid,
      );

      if (isValidForm) {
        const token = search.slice(7);

        const data = {
          token,
          password: password.value,
        };

        store.disableForm(true);
        store.setStatus(CREATION_PENDING);
        try {
          await sendNewPassword(data);
          store.disableForm(false);
          store.setStatus(CREATION_SUCCESS);
          setTimeout(COMPLETED_REDIRECT_DELAY, () =>
            history.push('/account/sigin'),
          );
        } catch (err) {
          store.disableForm(false);
          store.setStatus(CREATION_FAILED);
        }
      }
    };

    const btnAction = (): void => {
      history.push('/auth/signin');
    };

    if (!store.content) {
      return null;
    }

    return (
      <div className="forgotPassword">
        <p className="confirmation-page__title confirmation-page__title_email">
          {store.content.title}
        </p>
        <p
          className="confirmation-page__text confirmation-page__text_email"
          dangerouslySetInnerHTML={{ __html: store.content.text }}
        />
        <>
          {store.status === CREATION_SUCCESS ? (
            <div>
              <button
                className="button button_email btn btn_brown"
                onClick={btnAction}
              >
                {store.content.btnText}
              </button>
            </div>
          ) : (
            <>
              {store.status === ACTIVATION_SUCCESS ? (
                <form className="form" onSubmit={handleSubmit}>
                  <label className="form-label">
                    <input
                      className={joinClasses([
                        'autofill-bg-beige',
                        password.value && 'shrink',
                        (password.invalid && password.touched) ||
                        (password.invalid && password.value)
                          ? 'invalid'
                          : 'valid',
                      ])}
                      type={store.inputPasswordType}
                      name="password"
                      disabled={store.disable}
                      value={password.value}
                      {...InputFormControl.bindActions(password)}
                    />
                    <div className="label-text">Password *</div>

                    {(password.invalid && password.touched) ||
                    (password.invalid && password.value)
                      ? password.errors.map((error) => (
                          <div
                            className="form-label__error form-label__error_blocky"
                            key={error.key}
                          >
                            {error.message}
                          </div>
                        ))
                      : null}
                  </label>

                  <label className="form-label">
                    <input
                      className={joinClasses([
                        'autofill-bg-beige',
                        passwordConfirm.value && 'shrink',
                        (passwordConfirm.invalid && passwordConfirm.touched) ||
                        (passwordConfirm.invalid && passwordConfirm.value)
                          ? 'invalid'
                          : 'valid',
                      ])}
                      type={store.inputPasswordConfirmType}
                      name="passwordConfirm"
                      disabled={store.disable}
                      value={passwordConfirm.value}
                      {...InputFormControl.bindActions(passwordConfirm)}
                    />

                    <div className="label-text">Confirm Password *</div>

                    {(passwordConfirm.invalid && passwordConfirm.touched) ||
                    (passwordConfirm.invalid && passwordConfirm.value)
                      ? passwordConfirm.errors.map((error) => (
                          <div
                            className="form-label__error form-label__error_blocky"
                            key={error.key}
                          >
                            {error.message}
                          </div>
                        ))
                      : null}
                    <div className="form-label__error form-label__error_blocky">
                      {store.passwordConfirmErrorMessage}
                    </div>
                  </label>

                  <div>* Fields are required. Please fill them</div>

                  <input
                    className="button"
                    type="submit"
                    value={store.content.btnText}
                  />
                </form>
              ) : null}
            </>
          )}
        </>
      </div>
    );
  },
);
