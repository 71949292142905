import * as React from 'react';
import { observer } from 'mobx-react';
import { ProductDetailImpl } from 'root/ProductTypes';

interface ItemPriceProps {
  doesServerHasItem: (id: number) => boolean;
  product: ProductDetailImpl;
}

export const ItemPrice = observer((props: ItemPriceProps) => {
  const { doesServerHasItem, product } = props;
  return (
    <>
      {doesServerHasItem(product.id) ? (
        `${product.price}`
      ) : (
        <h2 className="no-item">Product out of stock </h2>
      )}
    </>
  );
});
