import React from 'react';
import Svg from '../Svg';

interface IListIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function ListIcon(props: IListIconProps): JSX.Element {
  return (
    <Svg width="24" height="22" viewBox="0 0 24 22" fill="none" {...props}>
      <path
        d="M0 2.75V19.25C0 20.5156 0.984375 21.5 2.25 21.5H21.75C22.9688 21.5 24 20.5156 24 19.25V2.75C24 1.53125 22.9688 0.5 21.75 0.5H2.25C0.984375 0.5 0 1.53125 0 2.75ZM22.125 13.25H9.23438V8.75H22.125V13.25ZM22.125 15.125V19.0625C22.125 19.3906 21.8438 19.625 21.5625 19.625H9.23438V15.125H22.125ZM1.875 8.75H7.35938V13.25H1.875V8.75ZM9.23438 6.875V2.375H21.5625C21.8438 2.375 22.125 2.65625 22.125 2.9375V6.875H9.23438ZM7.35938 2.375V6.875H1.875V2.9375C1.875 2.65625 2.10938 2.375 2.4375 2.375H7.35938ZM1.875 15.125H7.35938V19.625H2.4375C2.10938 19.625 1.875 19.3906 1.875 19.0625V15.125Z"
        fill="#928A8E"
      />
    </Svg>
  );
}
