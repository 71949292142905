class Storage {
  public isLocalStorageSupported: boolean;

  constructor() {
    this.isLocalStorageSupported =
      typeof window.localStorage !== 'undefined' &&
      window.localStorage !== null;

    if (!this.isLocalStorageSupported) {
      console.warn('localStorage in not supported in your browser');
    }
  }

  public set(key: string, value: unknown) {
    if (!this.isLocalStorageSupported) {
      return;
    }

    window.localStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : String(value),
    );
  }

  public get(key: string) {
    const result = window.localStorage.getItem(key);
    return result === undefined || result === null ? null : result;
  }

  public remove(key: string) {
    window.localStorage.removeItem(key);
  }

  public clear() {
    window.localStorage.clear();
  }
}

export default new Storage();
