import React from 'react';

interface IScrollableTabsItemProps {
  children?: React.ReactNode;
  className?: string;
}

class ScrollableTabsItem extends React.Component<IScrollableTabsItemProps> {
  render(): JSX.Element {
    const { children, className } = this.props;
    const classNames = ['slider-item', className].join(' ');

    return <li className={classNames}>{children}</li>;
  }
}

export default ScrollableTabsItem;
