import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { IObservableValue } from 'mobx/dist/internal';
import rootStore from 'root/store';
import { StoneDetailImpl } from 'root/store/stones/one.store';
import { RingDetailImpl } from 'root/store/rings/one.store';
import { IProductCustomizations } from 'root/store/products/types/product.type';
import { CartItemImpl } from 'root/store/cart/item.store';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import hintForProductSetStore from 'components/HintForProductSet/HintForProductSet.store';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import Table from './components/Table';
import BlockMobile from './components/BlockMobile';
import { ProductDetailPageImpl } from '../../ProductDetailPage.types';

import './AddSetToCartPopup.scss';

interface IProps {
  visiblePopup: IObservableValue<boolean>;
  productFromDetailPage: StoneDetailImpl | RingDetailImpl;
  customizationsParams?: CartItemImpl['customizations'];
  formatProductPrice: string;
  choosedPrice?: number;
  addToCart: () => void;
  stonesForSet: StoneDetailImpl | null;
  productStore: ProductDetailPageImpl;
}

const AddSetToCartPopup = ({
  visiblePopup,
  addToCart,
  productFromDetailPage,
  formatProductPrice,
  choosedPrice,
  customizationsParams,
  stonesForSet,
}: IProps) => {
  const {
    browserWindowType: {
      mediaQuery: { isMobile },
    },
  } = rootStore;
  const {
    product,
    level,
    customizations,
    formatPrice,
    price: hintPrice,
  } = hintForProductSetStore;

  const productCustomization =
    stonesForSet || level === LevelPropductForSet.Child
      ? customizationsParams
      : customizations;

  const productForSet = useMemo(
    () =>
      stonesForSet && !hintForProductSetStore.product
        ? {
            parent: {
              product: productFromDetailPage,
              price: formatProductPrice,
            },
            child: {
              product: stonesForSet,
              price: stonesForSet.formatPrice,
            },
          }
        : level === LevelPropductForSet.Parent
        ? {
            parent: { product: product as RingDetailImpl, price: formatPrice },
            child: {
              product: productFromDetailPage as StoneDetailImpl,
              price: formatProductPrice,
            },
          }
        : {
            parent: {
              product: productFromDetailPage as RingDetailImpl,
              price: formatProductPrice,
            },
            child: { product: product as StoneDetailImpl, price: formatPrice },
          },
    [
      level,
      product,
      productFromDetailPage,
      formatPrice,
      formatProductPrice,
      stonesForSet,
    ],
  );

  const handleAddToCart = () => {
    addToCart();
    visiblePopup.set(false);
  };

  const totalPrice = useMemo(() => {
    if (!product && !stonesForSet) return 0;
    if (stonesForSet) {
      const productPrice = choosedPrice || productFromDetailPage.price;
      return (Number(stonesForSet.price) + Number(productPrice)).toFixed(2);
    }

    const productPrice = choosedPrice || productFromDetailPage.price;

    return (Number(hintPrice) + Number(productPrice)).toFixed(2);
  }, [
    choosedPrice,
    hintPrice,
    product,
    productFromDetailPage.price,
    stonesForSet,
  ]);

  if (!product && !stonesForSet) return null;

  return (
    <PortalControl>
      <ModalWindow
        className="add-set-to-cart-popup"
        visible={visiblePopup.get()}
      >
        <div className="modal-window-header">
          <h2 className="modal-window-header__title">
            Do you want to add the set to the cart?
          </h2>
        </div>
        <div className="modal-window-body">
          <div className="add-set-to-cart-popup__set-info">
            <span>Set (Diamond included)</span>
          </div>
          <div className="add-set-to-cart-popup__list">
            {isMobile ? (
              <BlockMobile
                productForSet={productForSet as any}
                productCustomization={
                  productCustomization as IProductCustomizations
                }
              />
            ) : (
              <Table
                productForSet={productForSet as any}
                productCustomization={
                  productCustomization as IProductCustomizations
                }
              />
            )}
          </div>
          <div className="add-set-to-cart-popup__total">
            Total price:{' '}
            <span>
              {totalPrice} {productFromDetailPage.currency.symbol}
            </span>
          </div>
        </div>
        <div className="modal-window-body">
          <div className="add-set-to-cart-popup__btns">
            <button
              className="btn btn_brown btn_fixed-height add-set-to-cart-popup__button"
              onClick={handleAddToCart}
            >
              Add
            </button>
            <button
              className="btn btn_text-bold btn_bordered-default btn_fixed-height add-set-to-cart-popup__button"
              onClick={() => visiblePopup.set(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalWindow>
    </PortalControl>
  );
};

export default observer(AddSetToCartPopup);
