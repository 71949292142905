import React from 'react';
import Svg from '../../Svg';

interface IPearMeasLengthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function PearMeasLengthIcon(
  props: IPearMeasLengthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M143.2 79.74c-1.36-39.29-32.6-44.94-43.88-44.94-11.28 0-42.52 5.65-43.88 44.94C54.09 122.15 99.32 165 99.32 165s45.23-42.85 43.88-85.26z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.32 164.99 64.69 50.92l25.2 2.13 9.43-18.25M99.32 164.99l34.63-114.07-25.2 2.13-9.43-18.25"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m78.66 39.66 11.23 13.39L82 67.26l-11.31 3.43-15.25 9.05 43.88 50.08-15.9 17.29M119.98 39.66l-11.23 13.39 7.88 14.21 11.31 3.43 15.26 9.05-43.88 50.08 15.9 17.29"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m89.89 53.05 9.43 7.14L82 67.26l-9.83 31.58-12.69 6.05M108.75 53.05l-9.43 7.14 17.31 7.07 9.83 31.58 12.7 6.05M72.17 98.84l-2.77 27.69 21.89 12.02M126.37 98.84l2.77 27.69-21.89 12.02"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100 34.64h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.65 34.64h48.85"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.15 3.15"
      />
      <path
        d="M155.08 34.64h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.59 34.64v130.73"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100 165.37h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.65 165.37h48.85"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.15 3.15"
      />
      <path
        d="M155.08 165.37h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
