import React from 'react';
import { observer } from 'mobx-react';
import Icon from 'components/common/Icon';
import ImageItem from 'components/common/ImageItem';
import { StoneDetailImpl } from 'root/store/stones/one.store';
import {
  AVAILABLE_SHAPE_IMAGES,
  DIAMOND_TYPES,
} from 'utils/constants/stones/diamonds.constants';
import { IMAGES_FOLDER } from '../../../pages/Compare/constants';

interface IHintForDiamondProps {
  product: StoneDetailImpl;
  handleChange: () => void;
}

const HintForDiamond = (props: IHintForDiamondProps) => {
  const {
    product: { photos, sku, carat, shape, color, categories },
    handleChange,
  } = props;
  const stoneType = categories.some(
    ({ alias }) => alias === DIAMOND_TYPES.DIAMONDS_LAB_CREATED,
  )
    ? 'Lab-Created'
    : 'Natural';

  return (
    <div className="hint-product-set__info">
      <ImageItem
        imageFolder={IMAGES_FOLDER}
        images={AVAILABLE_SHAPE_IMAGES}
        currentImage={shape.split(' ').join('_')?.toLocaleLowerCase()}
        // src={photos[0]}
        needStyle
        className="hint-product-set__img"
      />

      <span className="hint-product-set__name">{sku}</span>

      <span
        className="hint-product-set__name hint-product-set__name_mobile"
        onClick={handleChange}
      >
        {sku} <Icon id="pencil" />
      </span>

      <div className="hint-product-set__description">
        <div>
          {carat} | {shape} | {color} | {stoneType}
        </div>
      </div>
    </div>
  );
};

export default observer(HintForDiamond);
