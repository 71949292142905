import React from 'react';
import Svg from '../../Svg';

interface IEmeraldDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function EmeraldDepthIcon(
  props: IEmeraldDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M162 68.66c-4-1.76-12.35-3.08-12.35-3.08H53.23s-8.38 1.32-12.35 3.08c-3.97 1.76-11.46 11.69-11.46 11.69v2.86L49 107.91l33.07 26.45h38.7l33.07-26.45 19.62-24.7v-2.86S166 70.43 162 68.66z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m53.23 65.57-8.08 14.85v2.21l13.96 25.57 12.79 15.43 10.21 10.73M150.09 65.57l8.08 14.85v2.21l-13.96 25.57-12.79 15.43-10.21 10.73M29.42 80.35H173.5M29.42 83.21H173.5M49.04 107.91h104.84M68.5 123.47h65.92M34.24 74.52h134.44M162.04 68.66H40.88"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.18 64.78h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.59 64.78h74.08"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M189.12 64.78h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.03 64.78v69.98"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.18 134.76h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.59 134.76h74.08"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M189.12 134.76h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
