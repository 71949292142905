import * as React from 'react';
import { observer } from 'mobx-react';
import { LoaderSpinner } from 'components/common/Loaders';
import { ProductDetailImpl } from 'root/ProductTypes';

export interface ComparePanelTemplateProps {
  product: ProductDetailImpl;
  handleRemoveItem: (
    product: ProductDetailImpl,
  ) => React.MouseEventHandler<HTMLDivElement> | undefined;
}

const template = React.lazy(() =>
  import('./ProductImage').then(({ ProductImage }) => ({
    default: ProductImage,
  })),
);

export const ProductImageTemplate = observer(
  (props: ComparePanelTemplateProps) => {
    const Component = template;
    return (
      <React.Suspense
        fallback={<LoaderSpinner className="loader-spinner_mr-sm" />}
      >
        <Component {...props} />
      </React.Suspense>
    );
  },
);
