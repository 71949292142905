import * as React from 'react';
import { observer } from 'mobx-react';
import { DiamondsMenu } from './DiamondsMenu';
import { WeddingRingsMenu } from './WeddingRingsMenu';
import { EngagementRingsMenu } from './EngagementRingsMenu';
import { FineJewelryMenu } from './FineJewelryMenu';

const templates: { [name: string]: () => JSX.Element } = {
  diamond: DiamondsMenu,
  wedding: WeddingRingsMenu,
  engagement: EngagementRingsMenu,
  jewelry: FineJewelryMenu,
};

export interface ProductMenuTemplateProps {
  templateType: string;
}

export const ProductMenuTemplate = observer(
  (props: ProductMenuTemplateProps) => {
    const Component = templates[props.templateType];

    return <Component />;
  },
);
