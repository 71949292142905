import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IFilterRingsExtraParams } from 'pages/EngagementRings/types';
import { WeddingRingsFiltersStore } from '../../store/weddingRingFilers.store';
import { SearchCustomSelect } from 'components/form/SearchCustomSelect/SearchCustomSelect';
import Grid from 'components/common/Filters/Grid';
import Range from 'components/common/Filters/Range';
import * as FILTER from './constants';

interface PanelProps extends RouteComponentProps {
  filters: WeddingRingsFiltersStore;
  filtersExtra: IFilterRingsExtraParams;
  handleFilters: () => void;
}

export const Panel = withRouter(
  observer((props: PanelProps) => {
    const {
      filtersExtra: { price },
    } = props;

    const clearFilters = () => {
      props.filters.reset();
    };

    return (
      <div className="grid-area-filters">
        <div className="filters-header">
          <h1 className="page-title">Filter</h1>
          <div className="btn btn_default btn_text-big" onClick={clearFilters}>
            Clear filters
          </div>
        </div>

        <Grid
          title="Bands"
          list={FILTER.BANDS}
          columns={3}
          values={props.filters.ringBand}
          icon={false}
          onAfterChange={props.handleFilters}
        />
        <Grid
          title="Metal"
          list={FILTER.METALS}
          columns={3}
          values={props.filters.metal}
          icon={false}
          onAfterChange={props.handleFilters}
        />
        <Grid
          title="Stone shape"
          list={FILTER.STONE_SHAPE}
          columns={3}
          values={props.filters.stoneShape}
          icon={false}
          onAfterChange={props.handleFilters}
        />
        <Range
          title="Price"
          label="$"
          max={price?.max ? Number(price?.max) : undefined}
          min={price?.min ? Number(price?.min) : undefined}
          step={0.01}
          range={props.filters.price}
          onAfterChange={props.handleFilters}
        />
      </div>
    );
  }),
);
