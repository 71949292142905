import React from 'react';
import { observer } from 'mobx-react';
import * as FILTER from 'utils/constants/stones/panel.constants';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Grid from 'components/common/Filters/Grid';
import Range from 'components/common/Filters/Range';
import Collapse from 'components/common/Collapse';
import { DiamondFiltersStore } from '../../store/diamondFilters.store';
import { IFilterStonesExtraParams } from '../../types';

interface PanelProps extends RouteComponentProps {
  filters: DiamondFiltersStore;
  filtersExtra: IFilterStonesExtraParams;
  handleFilters: () => void;
}

export const Panel = withRouter(
  observer((props: PanelProps) => {
    const {
      filtersExtra: { price, carat, table, depth, stoneWidth, stoneLength },
    } = props;

    const clearFilters = () => {
      props.filters.reset(props.filtersExtra);
    };

    return (
      <div className="grid-area-filters">
        <div className="filters-header">
          <h1 className="page-title">Filter</h1>
          <div className="btn btn_default btn_text-big" onClick={clearFilters}>
            Clear filters
          </div>
        </div>

        <Range
          title="Price"
          label="$"
          max={price?.max ? Number(price?.max) : undefined}
          min={price?.min ? Number(price?.min) : undefined}
          step={0.01}
          range={props.filters.price}
          tooltip={FILTER.TYPES.price.tooltip}
          onAfterChange={props.handleFilters}
        />

        <Grid
          title="Stone type"
          list={FILTER.STONE_TYPES}
          columns={3}
          values={props.filters.stoneType}
          tooltip={FILTER.TYPES.stoneType.tooltip}
          icon={false}
          onAfterChange={props.handleFilters}
        />

        <Grid
          title="Shape"
          list={FILTER.SHAPES}
          columns={3}
          values={props.filters.shape}
          tooltip={FILTER.TYPES.shape.tooltip}
          icon="icon"
          onAfterChange={props.handleFilters}
        />

        <Range
          title="Carat Weight"
          label="ct"
          max={carat?.max ? Number(carat?.max) : 50}
          min={carat?.min ? Number(carat?.min) : undefined}
          range={props.filters.carat}
          tooltip={FILTER.TYPES.carat.tooltip}
          step={0.1}
          onAfterChange={props.handleFilters}
        />

        <Range
          title="Stone width"
          label="mm"
          max={stoneWidth?.max ? Number(stoneWidth?.max) : undefined}
          min={stoneWidth?.min ? Number(stoneWidth?.min) : undefined}
          step={0.01}
          range={props.filters.stoneWidth}
          tooltip={FILTER.TYPES.measWidth.tooltip}
          onAfterChange={props.handleFilters}
        />

        <Range
          title="Stone length"
          label="mm"
          max={stoneLength?.max ? Number(stoneLength?.max) : undefined}
          min={stoneLength?.min ? Number(stoneLength?.min) : undefined}
          step={0.01}
          range={props.filters.stoneLength}
          tooltip={FILTER.TYPES.measLength.tooltip}
          onAfterChange={props.handleFilters}
        />

        <Grid
          title="Cut Grade"
          list={FILTER.CUT_GRADE}
          columns={3}
          values={props.filters.cut}
          tooltip={FILTER.TYPES.grade.tooltip}
          icon={false}
          onAfterChange={props.handleFilters}
        />
        <Range
          title="Color"
          className="filter_mb-67"
          tooltip={FILTER.TYPES.color.tooltip}
          range={props.filters.color}
          min={10}
          max={100}
          step={10}
          marks={FILTER.COLORS as any}
          dots={true}
          enableInputs={false}
          onAfterChange={props.handleFilters}
        />

        <Grid
          title="Fluorescence Intensity"
          list={FILTER.FLUORESCENCE}
          columns={3}
          values={props.filters.fluorescence}
          tooltip={FILTER.TYPES.fluorescence.tooltip}
          icon={false}
          onAfterChange={props.handleFilters}
        />

        <Grid
          title="Clarity"
          list={FILTER.CLARITY}
          columns={4}
          values={props.filters.clarity}
          tooltip={FILTER.TYPES.clarity.tooltip}
          icon={false}
          onAfterChange={props.handleFilters}
        />

        <Grid
          title="Certificate"
          list={FILTER.CERTIFICATE}
          columns={3}
          values={props.filters.gradingLab}
          tooltip={FILTER.TYPES.certificate.tooltip}
          icon={false}
          onAfterChange={props.handleFilters}
        />

        <div className="advanced-filters">
          <Collapse>
            <Range
              title="Table"
              label="%"
              max={table?.max ? Number(table?.max) : 100}
              min={table?.min ? Number(table?.min) : 0}
              step={0.01}
              range={props.filters.table}
              tooltip={FILTER.TYPES.table.tooltip}
              onAfterChange={props.handleFilters}
            />

            <Range
              title="Depth"
              label="%"
              max={depth?.max ? Number(depth?.max) : 100}
              min={depth?.min ? Number(depth?.min) : 0.01}
              step={0.01}
              range={props.filters.depth}
              tooltip={FILTER.TYPES.depth.tooltip}
              onAfterChange={props.handleFilters}
            />

            <Grid
              title="Polish"
              list={FILTER.POLISH}
              columns={3}
              values={props.filters.polish}
              icon={false}
              tooltip={FILTER.TYPES.polish.tooltip}
              onAfterChange={props.handleFilters}
            />

            <Grid
              title="Symmetry"
              list={FILTER.SYMMETRY}
              columns={3}
              values={props.filters.symmetry}
              tooltip={FILTER.TYPES.symmetry.tooltip}
              icon={false}
              onAfterChange={props.handleFilters}
            />
          </Collapse>
        </div>
      </div>
    );
  }),
);
