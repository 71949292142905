import * as React from 'react';
import { observer } from 'mobx-react';
import { Route, RouteProps as ReactRouteProps, Redirect } from 'react-router';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { RouteProps } from './routes';
import * as H from 'history';

interface IComponentType {
  children?: React.ReactNode;
  location: H.Location;
  key: string;
}

export interface PrivateRouteProps extends ReactRouteProps {
  route: RouteProps;
  isAuth: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (
  props: PrivateRouteProps,
) => {
  const { route, isAuth } = props;
  const state =
    props.location && props.location.state ? props.location.state : {};

  if (route.private && !isAuth && !(state as { email: string }).email) {
    return <Redirect to="/auth/signin" />;
  }

  return (
    <Route
      {...props}
      key={route.id}
      exact={!!route.main}
      path={route.path}
      render={(renderProps) => {
        const Component: React.ComponentType<IComponentType> = route.component;

        if (route.routes && route.routes.length) {
          const childrenRoute = route.routes.find((r) => {
            return renderProps.location.pathname === r.path;
          });
          if (childrenRoute) {
            const ChildrenComponent = childrenRoute.component;
            return (
              <ErrorBoundary>
                <Component key={route.id} {...renderProps}>
                  <ChildrenComponent />
                </Component>
              </ErrorBoundary>
            );
          }
        }
        return (
          <ErrorBoundary>
            <Component key={route.id} {...renderProps} />
          </ErrorBoundary>
        );
      }}
    />
  );
};

export default observer(PrivateRoute);
