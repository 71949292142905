import * as React from 'react';
import PaginateStore from 'root/store/paginate';
import { observer } from 'mobx-react';
import hintForProductSetStore from 'components/HintForProductSet/HintForProductSet.store';
import { QuantityItems } from 'components/common/QuanityItems';
import Icon from 'components/common/Icon';
import TooltipWithIcon from 'components/common/TooltipWithIcon';
import OrderStore from 'root/store/order';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import { joinClasses } from 'lib/utils';


interface CatalogsSortHeaderProps {
  pagination: PaginateStore;
  handleLimit: (limit: number) => void;
  changeCatalogAsTiles?: (value: boolean) => void;
  isDiamondCatalog?: boolean;
  handleOrder?: (property: string, direction: string | null) => void;
  order?: OrderStore;
  isTilesCatalog?: boolean;
  sortValues?: { name: string; value: string }[];
}

export const CatalogsSortHeader = observer((props: CatalogsSortHeaderProps) => {
  const {
    pagination,
    handleLimit,
    changeCatalogAsTiles,
    isDiamondCatalog,
    handleOrder,
    order,
    isTilesCatalog,
    sortValues,
  } = props;

  const isShowSetTooltip = hintForProductSetStore.product;

  const handleChangeTypeOfCatalog = (value: boolean) => {
    if (typeof changeCatalogAsTiles === 'function') {
      changeCatalogAsTiles(value);
    }
  };

  return (
    <>
      <div className="grid-area-sort-header">
        <QuantityItems
          limit={pagination.limit}
          handleLimit={handleLimit}
          handleOrder={handleOrder}
          order={order}
          isTilesCatalog={isTilesCatalog}
          sortValues={sortValues}
        />
        {!isDiamondCatalog && (
          <div className="icon-catalog">
            <Icon
                id="tiles"
                className={joinClasses([isTilesCatalog && 'active'])}
                onClick={() => handleChangeTypeOfCatalog(true)}
            />
            <Icon
                id="list"
                className={joinClasses([!isTilesCatalog && 'active'])}
                onClick={() => handleChangeTypeOfCatalog(false)}
            />
          </div>
        )}
      </div>

      <div className="grid-area-results">
        <p> {pagination.total} Results </p>
        {isShowSetTooltip && (
          <TooltipWithIcon modifiers="grid-area-results__tooltip">
            Select a{' '}
            {hintForProductSetStore.level === LevelPropductForSet.Child
              ? 'setting  to finish your diamond'
              : `diamond  to finish your ${hintForProductSetStore.productType}`}
          </TooltipWithIcon>
        )}
      </div>
    </>
  );
});
