import React from 'react';
import { observer } from 'mobx-react-lite';
import { joinClasses } from 'lib/utils';
import Icon from 'components/common/Icon';
import { FormFriendContactItemTypeImpl } from './FormFriendContactItem.types';

import './FormFriendContactItem.scss';

export function FormFriendContactItem({
  value,
  checkedItem,
  onChange,
}: FormFriendContactItemTypeImpl): JSX.Element {
  const handleChange = () => onChange(value);
  const isChecked = checkedItem === value;

  return (
    <label
      className={joinClasses([
        'form-friend-contacts__item',
        isChecked && 'form-friend-contacts__item--checked',
      ])}
    >
      <input
        type="radio"
        name="typeFriendContacts"
        value={value}
        checked={isChecked}
        onChange={handleChange}
      />
      <div className="form-friend-contacts__icon-wrapper">
        <Icon id={value} />
      </div>
    </label>
  );
}

export default observer(FormFriendContactItem);
