import StoneStore from './one.store';
import StonesListStore from './list.store';
import MainStore from './main.store';

export default class StonesStore {
  detail: StoneStore;
  list: StonesListStore;
  main: MainStore;

  constructor() {
    this.detail = new StoneStore();
    this.list = new StonesListStore();
    this.main = new MainStore();
  }
}
