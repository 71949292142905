import {
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { fetchStone, fetchStoneExtra } from 'service/api/stones';
import Currency, { CurrencyImpl } from '../currency';
import {
  BlocksElementsImpl,
  DetailBlocksImpl,
  ItemsBlockImpl,
  StoneDetailResponseData,
} from 'service/api/apiTypes/catalogsApiTypes';
import { CategoriesProductImpl } from '../../ProductTypes';
import { AxiosResponse } from 'axios';

export interface StoneDetailImpl {
  id: number;
  sku: string;
  shape: string;
  carat: string;
  cut: string;
  clarity: string;
  color: string;
  certificate: string;
  gradingLab: string;
  fluorescence: string;
  polish: string;
  photo: string[];
  hasLoupe: boolean;
  loupe: string;
  symmetry: string;
  table: string;
  depth: string;
  measLength: string;
  measWidth: string;
  measDepth: string;
  outOfStock?: boolean;
  price: string;
  formatPrice?: string;
  currency: CurrencyImpl;
  categories: CategoriesProductImpl[];
  mainBlock: IObservableArray<ItemsBlockImpl> | undefined;
  detailBlock: IObservableArray<ItemsBlockImpl> | undefined;

  [key: string]: any;
}

export interface StoneImpl extends StoneDetailImpl {
  updateStone: (stone: StoneDetailResponseData) => void;
  reset: () => void;
  loadProduct: (id: number) => Promise<void>;
  loadStoneExtra: (id: number, hasLoupe: boolean) => Promise<void>;
  updateDetailBlocks: (detailBlocks: BlocksElementsImpl[]) => void;
  _price: string;
}

/**
 * Stones detail initial state
 */
export default class StoneStore implements StoneImpl {
  id = 0;
  sku = '';
  shape = '';
  carat = '';
  cut = '';
  clarity = '';
  color = '';
  certificate = '';
  gradingLab = '';
  fluorescence = '';
  polish = '';
  photo: string[] = [];
  hasLoupe = false;
  loupe = '';
  symmetry = '';
  table = '';
  depth = '';
  measLength = '';
  measWidth = '';
  measDepth = '';
  outOfStock = false;
  _price = '';
  currency: CurrencyImpl = new Currency();
  categories = [] as CategoriesProductImpl[];

  mainBlock: IObservableArray<ItemsBlockImpl> | undefined = observable.array(
    [],
  );

  detailBlock: IObservableArray<ItemsBlockImpl> | undefined = observable.array(
    [],
  );

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  static new(stone: StoneDetailResponseData): StoneStore {
    const stoneStore = new StoneStore();
    stoneStore.updateStone(stone);

    return stoneStore;
  }

  updateStone(stone: StoneDetailResponseData): void {
    this.id = stone.id;
    this.sku = stone.sku;
    this.shape = stone.shape.split(' ')[0];
    this.carat = stone.carat;
    this.cut = stone.cut;
    this.clarity = stone.clarity;
    this.color = stone.color;
    this.certificate = stone.certificate;
    this.gradingLab = stone.gradingLab;
    this.fluorescence = stone.fluorescence;
    this.polish = stone.polish;
    this.photo = stone.photos;
    this.hasLoupe = stone.hasLoupe;
    this.loupe = stone.loupe;
    this.symmetry = stone.symmetry;
    this.table = stone.table;
    this.depth = stone.depth;
    this.measLength = stone.measLength;
    this.measWidth = stone.measWidth;
    this.measDepth = stone.measDepth;
    this.outOfStock = stone.outOfStock || false;
    this._price = stone.price;
    this.currency.update(stone.currency);
    this.categories = stone.categories;
  }

  updateDetailBlocks(detailBlocks: BlocksElementsImpl[]): void {
    this.mainBlock?.replace(
      detailBlocks.find((el: BlocksElementsImpl) => el.blockType === 'main')
        ?.items || [],
    );
    this.detailBlock?.replace(
      detailBlocks.find((el: BlocksElementsImpl) => el.blockType === 'detail')
        ?.items || [],
    );
  }

  reset(): void {
    this.id = 0;
    this.shape = '';
    this.carat = '';
    this.cut = '';
    this.clarity = '';
    this.color = '';
    this.certificate = '';
    this.gradingLab = '';
    this.fluorescence = '';
    this.photo = [];
    this.categories = [];
    this.hasLoupe = false;
    this.loupe = '';
    this.polish = '';
    this.symmetry = '';
    this.table = '';
    this.depth = '';
    this.measLength = '';
    this.measWidth = '';
    this.measDepth = '';
    this.outOfStock = false;
    this._price = '';
    this.currency = new Currency();
  }

  get price(): string {
    return this.currency.format(this._price);
  }

  /**
   * Update stone info
   * @param {Number=} id - stone id
   * @returns {Promise<void>}
   */
  async loadProduct(id: number): Promise<void> {
    id = id || this.id;
    try {
      const stone = await fetchStone(id);
      void this.loadStoneExtra(id, stone.hasLoupe);
      this.updateStone(stone);
    } catch (err) {
      if (err && (err as AxiosResponse).status === 404) {
        window.location.href = '/404';
      }
    }
  }

  async loadStoneExtra(id: number, hasLoupe: boolean): Promise<void> {
    id = id || this.id;
    fetchStoneExtra(id)
      .then((res: DetailBlocksImpl) => {
        if (hasLoupe && res.loupeUrl) {
          runInAction(() => (this.loupe = res.loupeUrl));
        }
        this.updateDetailBlocks(res.detailBlocks);
      })
      .catch(() => {
        runInAction(() => (this.outOfStock = true));
      });
  }
}
