import React from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import HintPage from './HintPage';
import { HintPageProps } from './HintPage.types';
import ProductDetailStore from './HintPage.store';

const productStore = new ProductDetailStore();

export default withRouter(
  observer((props: HintPageProps) => (
    <HintPage {...props} store={productStore} />
  )),
);
