import * as React from 'react';
import { IObservableArray } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import ImageItem from 'components/common/ImageItem';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CategoriesProductImpl, ProductStyles } from 'root/ProductTypes';
import { getCurrentCategory } from 'lib/utils';
import { TEngagementRingsCatalog } from '../../types';
import { CompareImpl } from 'root/store/compare';
import { IProduct } from 'root/store/products/types/product.type';

export interface TableBodyProps extends RouteComponentProps {
  data: IObservableArray<TEngagementRingsCatalog>;
  compare: CompareImpl;
}

export const TableBody = withRouter(
  observer((props: TableBodyProps) => {
    const { data, compare } = props;

    const stopPropagation = (
      e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    ) => {
      e.stopPropagation();
    };

    const handleAddToCompare = (product: IProduct) => () => {
      compare.toggle((product as any).productId);
    };

    const getCurrentStyle = (categories: CategoriesProductImpl[]) => {
      let currentStyle = '';
      const currentCategory = getCurrentCategory(categories);
      Object.keys(ProductStyles).forEach((productStyle) => {
        if (currentCategory.includes(productStyle)) {
          currentStyle = productStyle;
        }
      });
      const style = categories.find((el) => el.alias === currentStyle);
      return style?.name;
    };

    return (
      <tbody>
        {data &&
          (data.length === 0 ? (
            <tr className="no-hover">
              <td colSpan={8}>
                <p className="attention-message">
                  Found no items matched to search parameters.
                </p>
              </td>
            </tr>
          ) : (
            data.map((ring, index) => {
              return (
                <tr key={index}>
                  <td>
                    <label className="checkbox-container">
                      <input
                        checked={compare.exist(ring.productId)}
                        type="checkbox"
                        name={`compare_${ring.id}`}
                        onClick={stopPropagation}
                        onChange={handleAddToCompare(ring)}
                      />
                      <span
                        className="checkmark"
                        onClick={stopPropagation}
                      ></span>
                    </label>
                  </td>
                  <td>
                    <CSSTransition in appear timeout={1000} classNames="zoom">
                      <div>
                        <Link
                          to={`${props.location.pathname}/${ring.productId}`}
                        />
                        <ImageItem src={ring.photo} />
                      </div>
                    </CSSTransition>
                  </td>
                  <td>
                    <Link to={`${props.location.pathname}/${ring.productId}`} />
                    {ring.id}
                  </td>
                  <td>
                    <Link to={`${props.location.pathname}/${ring.productId}`} />
                    {getCurrentStyle(ring.categories) || 'N/A'}
                  </td>
                  <td>
                    <Link to={`${props.location.pathname}/${ring.productId}`} />
                    {ring.metal}
                  </td>
                  <td>
                    <Link to={`${props.location.pathname}/${ring.productId}`} />
                    ${ring.minPrice}
                  </td>
                </tr>
              );
            })
          ))}
      </tbody>
    );
  }),
);
