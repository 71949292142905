import React from 'react';
import Svg from '../../Svg';

interface IRadiantIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function RadiantIcon(props: IRadiantIconProps): JSX.Element {
  return (
    <Svg width="20" height="20" viewBox="0 0 410 410" fill="none" {...props}>
      <path
        d="M83.95 407.26c.47.48 1.11.75 1.78.75h238.54c.67 0 1.31-.27 1.78-.75l42.02-42.78c.46-.47.72-1.1.72-1.75V47.27c0-.66-.26-1.28-.72-1.75L326.05 2.74a2.49 2.49 0 0 0-1.78-.75H85.73c-.67 0-1.31.27-1.78.75L41.93 45.52c-.46.47-.72 1.1-.72 1.75v315.46c0 .66.26 1.28.72 1.75l42.02 42.78zm32.53-367.64L72.41 71.29 47.27 47.21 85.73 8.06l30.75 31.56zM324.27 8.06l38.45 39.15-25.13 24.08-44.07-31.68 30.75-31.55zm-30.75 362.32 44.07-31.68 25.13 24.08-38.45 39.15-30.75-31.55zM192.26 72.04h-69.47V43.67l69.47 28.37zm25.48 265.92h69.47v28.36l-69.47-28.36zM122.79 205V77.04h164.42v255.92H122.79V205zm69.47 132.96-69.47 28.36v-28.36h69.47zm99.95 0h37.85l-37.85 27.21v-27.21zm0-5V219l42.03 113.96h-42.03zm0-255.92h42.03L292.21 191V77.04zm0-5V44.83l37.85 27.21h-37.85zm-5 0h-69.47l69.47-28.36v28.36zm-169.42 0H79.94l37.85-27.21v27.21zm0 5V191L75.77 77.04h42.02zm0 255.92H75.77L117.8 219v113.96zm0 5v27.21l-37.85-27.21h37.85zm87.21.2 71.97 29.38H133.03L205 338.16zm130.32-16.7L292.37 205l42.95-116.46v232.92zM205 71.84l-71.97-29.38h143.94L205 71.84zM74.68 88.54 117.63 205 74.68 321.46V88.54zm-2.27 250.17 44.07 31.68-30.75 31.55-38.45-39.15 25.13-24.08zm19.25 64.3 29.69-30.46h167.3l29.69 30.46H91.66zm272.12-46.13-23.46-22.48V75.6l23.46-22.48v303.76zM318.34 6.99l-29.69 30.46h-167.3L91.66 6.99h226.68zM46.22 53.12 69.68 75.6v258.8l-23.46 22.48V53.12z"
        fill="#4d4048"
      />
    </Svg>
  );
}
