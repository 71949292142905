import React from 'react';
import Svg from '../../Svg';

interface IMarquiseMeasWidthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function MarquiseMeasWidthIcon(
  props: IMarquiseMeasWidthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M65.11 99.02v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.11 94.48V23.06"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.04 3.04"
      />
      <path
        d="M65.11 21.54v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.11 18.63h68.34"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.45 99.02v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.45 94.48V23.06"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.04 3.04"
      />
      <path
        d="M133.45 21.54v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.28 33.63s-76.88 62 0 130.78c76.88-68.73 0-130.78 0-130.78z"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.28 33.63 74.13 133.84l20.01 12.34-5.86 7.32M99.28 33.63l25.14 100.21-20 12.34 5.86 7.32"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m87.83 45.13 11.45 13.61-34.17 40.28 34.17 41.11-5.14 6.05"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.72 45.13 99.28 58.74l34.17 40.28-34.17 41.11 5.14 6.05M94.42 52.97 74.13 65.22l25.15 99.19"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m104.13 52.97 20.29 12.25-25.14 99.19"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
