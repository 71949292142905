import React from 'react';
import {
  EngraveColorSettings,
  IEngraving,
} from 'pages/ProductDetailPage/ProductDetailPage.types';

const getBageClassName = (name: string, type: 'color' | 'font') =>
  `engrave-popup__${type}-item engrave-popup__${type}-item_${name
    .split(' ')
    .join('-')
    .toLocaleLowerCase()}`;

export const getValuesForFontSelect = (
  engravingItem?: IEngraving,
):
  | {
      id: number;
      code: string;
      name: string;
      bage: JSX.Element;
    }[]
  | undefined =>
  engravingItem?.fonts?.reduce(
    (
      accum: {
        id: number;
        code: string;
        name: string;
        bage: JSX.Element;
      }[],
      option: EngraveColorSettings,
    ) => {
      return [
        ...accum,
        {
          ...option,
          bage: (
            <span
              style={{
                fontFamily: option.name,
              }}
              className={getBageClassName(option.name, 'font')}
            >
              Aa
            </span>
          ),
        },
      ];
    },
    [],
  );

export const getValuesForFillSelect = (
  engravingItem?: IEngraving,
):
  | {
      id: number;
      code: string;
      name: string;
      bage: JSX.Element;
    }[]
  | undefined =>
  engravingItem?.fillOptions?.reduce(
    (
      accum: {
        id: number;
        code: string;
        name: string;
        bage: JSX.Element;
      }[],
      option: EngraveColorSettings,
    ) => {
      return [
        ...accum,
        {
          ...option,
          bage: (
            <span
              style={{
                background:
                  option.name === 'No Color'
                    ? 'transparent'
                    : option.name.toLowerCase(),
                borderColor:
                  option.name === 'No Color' ? '#4d4048' : 'transparent',
              }}
              className={getBageClassName(option.name, 'color')}
            />
          ),
        },
      ];
    },
    [],
  );
