import React from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { ProductDetailImpl } from 'root/ProductTypes';
import CartStore from 'root/store/cart';
import Favorite from 'root/store/favorite';
import { ROUTER_PATHS } from 'root/routes/constants';
import { REMOVE_CHILD } from 'pages/ShoppingCart/constants';
import Loader from 'components/common/Loaders/Loader';
import Icon from 'components/common/Icon';
import { joinClasses } from 'lib/utils';

import './FavoritePage.scss';

interface IProps {
  cart: CartStore;
  favorite: Favorite;
}

const FavoritePage = ({ cart, favorite }: IProps): JSX.Element => {
  const history = useHistory();

  const handleGoToHint = (product: ProductDetailImpl) => (): void => {
    history.push(`/hint/${product.id}`);
  };

  return (
    <div className="account-favorite-page">
      <div className="account-favorite-page__content">
        <div className="account-favorite-page__header">
          <h1 className="account-favorite-page__title">FAVORITES</h1>
        </div>

        <div className="account-favorite-page__items-list">
          {favorite.isLoading ? (
            <Loader />
          ) : (
            <>
              {favorite.list.length ? (
                favorite.list.map((item: ProductDetailImpl, index: number) => {
                  return (
                    <div className="account-favorite-page__item" key={index}>
                      <div className="account-favorite-page__left-block">
                        <div className="account-favorite-page__item-image">
                          <img
                            alt=""
                            src={
                              item.shape
                                ? require(`img/shapes/${item.shape.toLowerCase()}.png`)
                                    .default
                                : item.photo
                            }
                          />
                        </div>
                        <div className="account-favorite-page__item-characteristics">
                          <div className="account-favorite-page__diamond-sku">
                            {item.sku || item.id}
                          </div>
                          <ul className="account-favorite-page__diamond-characteristics">
                            {item.description &&
                              Object.keys(item.description).map((key) => (
                                <li key={key}>
                                  <span>{item.description[key] || 'N/A'}</span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div className="account-favorite-page__right-block">
                        <div className="account-favorite-page__item-price">
                          {favorite.exist(item.id) ? (
                            <div className="diamond-price">{`${item.price}`}</div>
                          ) : (
                            <div className="diamond-price no-item">
                              Product out of stock
                            </div>
                          )}
                        </div>

                        <div className="item-actions">
                          <div className="grid-area-actions icons-container">
                            <button
                              className="btn btn_bordered-default btn_circle btn_circle-xl"
                              onClick={handleGoToHint(item)}
                            >
                              <Icon id="envelope" />
                            </button>
                            <button
                              className={joinClasses([
                                'btn btn_bordered-default btn_circle btn_circle-xl',
                                cart.exist(item.id) && 'active',
                              ])}
                              onClick={() => {
                                if (cart.exist(item.id)) {
                                  cart.remove(item.guid, REMOVE_CHILD);
                                } else {
                                  cart.put({ id: item.id, quantity: 1 });
                                }
                              }}
                            >
                              <Icon id="shopping" />
                            </button>

                            <button
                              className="btn btn_bordered-default btn_circle btn_circle-xl"
                              onClick={() => favorite.remove(item)}
                            >
                              <Icon id="trash" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="account-favorite-page__empty-list">
                  <div className="account-favorite-page__empty-icon">
                    <Icon id="gem_large" className="logo" />
                  </div>
                  <p className="account-favorite-page__empty-title">
                    You have no favorites
                  </p>
                  <p className="account-favorite-page__empty-subtitle">
                    You can select favorites in the inventory
                  </p>

                  <Link
                    to={{
                      pathname: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
                    }}
                    className="btn btn_brown account-favorite-page__empty-btn"
                  >
                    Search Inventory
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(FavoritePage);
