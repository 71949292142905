import React from 'react';
import Svg from '../Svg';

interface SpellCheckIcom {
  children?: React.ReactNode;
}

export default function Remove(props: SpellCheckIcom): JSX.Element {
  return (
    <Svg width="23" height="21" viewBox="0 0 23 21" fill="none" {...props}>
      <path
        d="M18.125 3.625H14.8828L13.5547 1.4375C13.2422 0.929688 12.5391 0.5 11.9531 0.5H8.00781C7.42188 0.5 6.71875 0.929688 6.40625 1.4375L5.07812 3.625H1.875C1.52344 3.625 1.25 3.9375 1.25 4.25V4.875C1.25 5.22656 1.52344 5.5 1.875 5.5H2.5L3.32031 18.7422C3.35938 19.7188 4.21875 20.5 5.19531 20.5H14.7656C15.7422 20.5 16.6016 19.7188 16.6406 18.7422L17.5 5.5H18.125C18.4375 5.5 18.75 5.22656 18.75 4.875V4.25C18.75 3.9375 18.4375 3.625 18.125 3.625ZM8.00781 2.375H11.9531L12.6953 3.625H7.26562L8.00781 2.375ZM14.7656 18.625H5.19531L4.375 5.5H15.5859L14.7656 18.625Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
