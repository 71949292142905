import СushionMeasLengthIcon from './СushionMeasLengthIcon';
import СushionMeasWidthIcon from './СushionMeasWidthIcon';
import СushionMeasDepthIcon from './СushionMeasDepthIcon';
import СushionDepthIcon from './СushionDepthIcon';
import СushionTableIcon from './СushionTableIcon';
import CushionIcon from './CushionIcon';

export default {
  cushion_measLength: СushionMeasLengthIcon,
  cushion_measWidth: СushionMeasWidthIcon,
  cushion_measDepth: СushionMeasDepthIcon,
  cushion_depth: СushionDepthIcon,
  cushion_table: СushionTableIcon,
  cushion: CushionIcon,
};
