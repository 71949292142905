import { makeAutoObservable } from 'mobx';
import CarouselStore, { CarouselStoreImpl } from 'root/store/carousel';
import { ProductItemsImpl } from '../types';
import { CAROUSEL_ACTIVE_ITEM } from '../constants';

export interface IMainStore {
  carousel: CarouselStoreImpl;
  setAnimation: (isAnimation: boolean) => void;
  setProductForHer: (isForHer: boolean) => void;
  setProductForHim: (isForHim: boolean) => void;
  updateCarouselItems: (productItems: Array<ProductItemsImpl>) => void;
  isNewAnimation: boolean;
  isProductForHer: boolean;
  isProductForHim: boolean;
}

class Index implements IMainStore {
  isProductForHer = false;
  isProductForHim = false;
  isNewAnimation = false;
  carousel: CarouselStoreImpl;

  constructor() {
    makeAutoObservable(this);
    this.carousel = new CarouselStore(CAROUSEL_ACTIVE_ITEM);
  }

  updateCarouselItems = (productItems: Array<ProductItemsImpl>) => {
    this.carousel.update(productItems);
  };

  setProductForHer = (isForHer: boolean) => {
    this.isProductForHer = isForHer;
  };

  setProductForHim = (isForHim: boolean) => {
    this.isProductForHim = isForHim;
  };

  setAnimation = (isAnimation: boolean) => {
    this.isNewAnimation = isAnimation;
  };
}

export default new Index();
