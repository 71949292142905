import { makeAutoObservable, observable } from 'mobx';
import { RouteDataType } from './FAQPage.type';
import { routes } from './constants';

export interface IFAQStore {
  formattedData: RouteDataType[];
  formatData: () => void;
}

class FAQStore implements IFAQStore {
  formattedData: RouteDataType[] = observable([]);

  constructor() {
    makeAutoObservable(this);
    this.formatData();
  }

  formatData() {
    this.formattedData = routes;
  }
}

export default new FAQStore();
