export enum RING_STYLES {
  EG_RING_SOLITAIRE = 'EG_RING_SOLITAIRE',
  EG_RING_HALO = 'EG_RING_HALO',
  EG_RING_TWOSTONE = 'EG_RING_TWOSTONE',
  EG_RING_THREESTONE = 'EG_RING_THREESTONE',
  EG_RING_ACCENTED = 'EG_RING_ACCENTED',
  EG_RING_BEZEL = 'EG_RING_BEZEL',
  EG_RING_VINTAGE = 'EG_RING_VINTAGE',
}

export enum RING_METAL {
  EG_RING_W_GLD = 'White Gold',
  EG_RING_Y_GLD = 'Yellow Gold',
  EG_RING_R_GLD = 'Rose Gold',
  EG_RING_PLT = 'Platinum',
}

export enum RING_BAND {
  WB_RING_DIAMOND_WOMAN = 'WB_RING_DIAMOND_WOMAN',
  WB_RING_DIAMOND_MAN = 'WB_RING_DIAMOND_MAN',
  WB_RING_CLASSIC = 'WB_RING_CLASSIC',
  WB_RING_STACKABLE = 'WB_RING_STACKABLE',
  WB_RING_ANNIVERSARY = 'WB_RING_ANNIVERSARY',
  WB_RING_ALT_METAL = 'WB_RING_ALT_METAL',
  WB_RING_CARVED = 'WB_RING_CARVED',
  WB_RING_ETERNITY = 'WB_RING_ETERNITY',
}
