import ColorD from './ColorD';
import ColorE from './ColorE';
import ColorF from './ColorF';
import ColorG from './ColorG';
import ColorH from './ColorH';
import ColorI from './ColorI';
import ColorJ from './ColorJ';
import ColorK from './ColorK';
import ColorL from './ColorL';

export default {
  color_d: ColorD,
  color_e: ColorE,
  color_f: ColorF,
  color_g: ColorG,
  color_h: ColorH,
  color_i: ColorI,
  color_j: ColorJ,
  color_k: ColorK,
  color_l: ColorL,
};
