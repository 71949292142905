import {
  FormControl,
  ValidationEvent,
  ValidationEventTypes,
} from '@quantumart/mobx-form-validation-kit';

export const validFormControlCountry = async (
  state: FormControl<number>,
  message: string,
): Promise<ValidationEvent[]> => {
  const value = state.value === null ? '' : state.value.toString();
  const rule = /^[0-9]+$/;

  return !!state.value && rule.test(value)
    ? []
    : [
        {
          message,
          type: ValidationEventTypes.Error,
        },
      ];
};

export const validFormControlPasswordConfirm = async (
  state: FormControl<string>,
  passwordState: FormControl<string>,
  isPassword?: boolean,
): Promise<ValidationEvent[]> => {
  const password = passwordState?.value || '';
  const passwordConfirm = state?.value || '';

  return (isPassword ? passwordConfirm : password === passwordConfirm)
    ? []
    : [
        {
          message: 'Confirm Password does not match with Password',
          type: ValidationEventTypes.Error,
          key: 'required',
        },
      ];
};

validFormControlPasswordConfirm.defaultProps = {
  isPassword: false,
};
