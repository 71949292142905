export enum TabLinks {
  CUT = 'CUT',
  COLOR = 'COLOR',
  CLARITY = 'CLARITY',
  CARAT = 'CARAT',
}

export const TABS = [
  { link: '/education/cutting', name: TabLinks.CUT },
  { link: '/education/color', name: TabLinks.COLOR },
  { link: '/education/clarity', name: TabLinks.CLARITY },
  { link: '/education/carat', name: TabLinks.CARAT },
];

export const CARAT_IMAGES = ['carat', 'education-img-cover-carat'];

export const CLARITY_IMAGES = [
  'clarity-fl',
  'clarity-vvs1',
  'clarity-vs2',
  'clarity-si1',
  'clarity-si2',
  'education-img-cover-clarity',
];

export const COLOR_IMAGES = [
  'color-colorless',
  'color-g',
  'color-h',
  'color-i',
  'color-noticeable',
  'education-img-cover-color',
];

export const CUTTING_IMAGES = [
  'cutting-1',
  'cutting-2',
  'cutting-3',
  'cutting-4',
  'education-img-cover-cutting',
];
