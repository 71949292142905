import * as React from 'react';
import { CustomerStep as DefaultComponent } from './CustomerStep';
import customerFormStore from './customerForm.store';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

export const CustomerStep = withRouter(
  (props: RouteComponentProps): JSX.Element => (
    <DefaultComponent {...props} store={customerFormStore} />
  ),
);
