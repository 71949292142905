import axios from 'axios';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { CategoriesProductImpl, ProductCategories } from 'root/ProductTypes';
import { ROUTER_PATHS } from 'root/routes/constants';
const moment = require('moment-business-days');

/**
 * Format price XX,XXX.XX
 * @param price
 */
export const formatPrice = (price: number | string): string =>
  Number(price)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');

/**
 * Get image from folder
 * @param folder
 * @param images
 * @param imgName
 * @param format
 */
export const getImg = (
  folder: string,
  images: string[],
  imgName: string,
  format = 'webp',
): string => {
  try {
    /* eslint @typescript-eslint/no-var-requires: "off" */
    const img: string =
      images.includes(imgName) && (isWebpSupported() || format !== 'webp')
        ? require(`img/${folder}${imgName}.${format}`)?.default
        : require(`img/${folder}${imgName}.png`)?.default;

    return img;
  } catch (error: any) {
    return '';
  }
};

export const getNoImage = (): string =>
  getImg('default_images/', ['venori_no_image'], 'venori_no_image');

export const getSuccessResponseOfImage = async (
  imageUrl: string,
): Promise<string | undefined> => {
  try {
    await axios.get(imageUrl, {
      withCredentials: false,
    });
    return imageUrl;
  } catch (error) {
    console.error(error);
  }
};

export const joinClasses = (arr: Array<unknown>): string =>
  arr
    .filter((item) => item)
    .join(' ')
    .trim();

export const getCreateDate = (
  createDate: string,
  difference?: number,
): string => {
  // const date = new Date(createDate);
  // difference && date.setDate(date.getDate() + difference);
  // const day = date.getDate();
  // const month = date.getMonth() + 1;
  // const year = date.getFullYear();
  //
  // const format = (d: number) => `0${d}`.slice(-2);
  // return `${format(day)}-${format(month)}-${year}`;
  // eslint-disable-next-line no-debugger

  const date = moment(createDate);
  return date.businessAdd(difference || 0).format('DD-MM-YYYY');
};

export const getFormattedTimeString = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes ? `${minutes} min` : ''} ${
    seconds ? `${seconds} sec` : ''
  }`;
};

export const getCurrentCategory = (
  categories: CategoriesProductImpl[],
): string[] => {
  return categories.map((category) => category.alias);
};

export const getIsMobileVersion = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const getPathnameByCategories = (
  categories: CategoriesProductImpl[],
): string => {
  if (!categories) return '';

  const categoryAliases = categories.map((c) => c.alias);

  if (categoryAliases.includes(ProductCategories.ENGAGEMENT_RINGS)) {
    return ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH;
  }

  if (categoryAliases.includes(ProductCategories.WEDDING_RINGS)) {
    return ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH;
  }

  if (
    categoryAliases.includes(ProductCategories.DIAMONDS) ||
    categoryAliases.includes(ProductCategories.DIAMONDS_LAB_CREATED)
  ) {
    return ROUTER_PATHS.DIAMONDS_CATALOG_PATH;
  }

  if (
    categoryAliases.includes(ProductCategories.RINGS) ||
    categoryAliases.includes(ProductCategories.BRACELETS) ||
    categoryAliases.includes(ProductCategories.PENDANTS) ||
    categoryAliases.includes(ProductCategories.EARRINGS) ||
    categoryAliases.includes(ProductCategories.NECKLACES)
  ) {
    return ROUTER_PATHS.JEWELRY_CATALOG_PATH;
  }
  return '';
};
