import SpellCheckIcon from './SpellCheckIcon';
import Image360Icon from './Image360Icon';
import ImageGalleryIcon from './ImageGalleryIcon';
import InsideBand from './InsideBand';
import OutsideBand from './OutsideBand';
import Change from './Change';
import Pencil from './Pencil';
import Remove from './Remove';
import Tooltip from './Tooltip';
import EnvelopeIcon from './EnvelopeIcon';
import SearchIcon from './SearchIcon';
import GemSolidIcon from './GemSolidIcon';
import GemLightIcon from './GemLightIcon';
import CogsIcon from './CogsIcon';
import FileCertificateIcon from './FileCertificateIcon';
import GemLargeIcon from './GemLargeIcon';

export default {
  spell_check: SpellCheckIcon,
  image_360: Image360Icon,
  image_gallery: ImageGalleryIcon,
  inside_bang: InsideBand,
  outside_bang: OutsideBand,
  change: Change,
  pencil: Pencil,
  remove: Remove,
  tooltip: Tooltip,
  envelope: EnvelopeIcon,
  search: SearchIcon,
  gem_solid: GemSolidIcon,
  gem_light: GemLightIcon,
  gem_large: GemLargeIcon,
  cogs: CogsIcon,
  file_certificate: FileCertificateIcon,
};
