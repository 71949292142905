import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface IuseClickOutsideProps {
  closestElement: string;
  callback: () => void;
}

interface IuseClickOutsideReturn {
  isClickOutside: boolean;
  setIsClickOutside: Dispatch<SetStateAction<boolean>>;
}

export default function useClickOutside({
  closestElement,
  callback,
}: IuseClickOutsideProps): IuseClickOutsideReturn {
  const [isClickOutside, setIsClickOutside] = useState<boolean>(false);

  const handleOutsideClick = (event: MouseEvent): void => {
    const { target } = event;
    const element = target as HTMLElement;
    const outsideElement = !element?.closest(closestElement);
    !!outsideElement && callback();
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  return {
    isClickOutside,
    setIsClickOutside,
  };
}
