import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ComparePanel as DefaultComponent } from './ComparePanel';
import store from 'root/store';

const { compare } = store;

export const ComparePanel = withRouter((props: RouteComponentProps) => (
  <DefaultComponent {...props} compare={compare} />
));
