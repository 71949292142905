import React, { useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import { observer } from 'mobx-react';
import { ProductCategories } from 'root/ProductTypes';
import { getCurrentCategory, joinClasses } from 'lib/utils';
import { ItemsBlockImpl } from 'service/api/apiTypes/catalogsApiTypes';
import Icon from 'components/common/Icon';
import { ProductDetailPageImpl } from 'pages/ProductDetailPage/ProductDetailPage.types';
import { INVALID_VALUES } from 'pages/ProductDetailPage/util/constants';
import CustomizationItem from './components/CustomizationItem';

export interface DetailItemsProps {
  item: ItemsBlockImpl;
  productStore: ProductDetailPageImpl;
  handleDetailsOn: (e: React.MouseEvent<HTMLElement>) => void;
  handleDetailsLeave: (e: React.MouseEvent<HTMLElement>) => void;
  constantTypes: {
    [key: string]: {
      tooltip: string | boolean;
      isNeedDetail: boolean;
    };
  };
  sizeValue: string | number | null;
}

const DetailItems = observer((props: DetailItemsProps) => {
  const {
    productStore,
    productStore: { product },
    handleDetailsOn,
    handleDetailsLeave,
    constantTypes,
    sizeValue,
    item,
  } = props;
  const [tipOpen, setTipOpen] = useState(false);
  const toggleTip = () => {
    setTipOpen(!tipOpen);
  };

  const currentCategory = getCurrentCategory(product.categories);

  if (item?.customization?.length) {
    return (
      <CustomizationItem
        sizeValue={sizeValue}
        item={item}
        productStore={productStore}
      />
    );
  }

  if (INVALID_VALUES.includes(product[item.property as 'id'] as any))
    return null;

  return (
    <li
      key={item.order}
      data-detail={
        constantTypes[item.property]?.isNeedDetail ? item.property : null
      }
      onMouseEnter={(e) => {
        constantTypes[item.property]?.isNeedDetail && handleDetailsOn(e);
      }}
      onMouseLeave={(e) => {
        constantTypes[item.property]?.isNeedDetail && handleDetailsLeave(e);
      }}
    >
      <span>
        {currentCategory.includes(ProductCategories.WEDDING_RINGS) &&
        item.caption === 'Size'
          ? 'Finger size'
          : item.caption}
        {currentCategory.includes(ProductCategories.DIAMONDS) &&
          constantTypes[item.property]?.tooltip && (
            <Tooltip
              content={constantTypes[item.property]?.tooltip}
              direction="up-start"
              className="custom-tooltip custom-tooltip_ml-15"
              tagName="span"
              onToggle={toggleTip}
            >
              <span
                className={joinClasses([
                  'info-circle',
                  tipOpen && 'active-info-circle',
                ])}
                data-detail="length"
              >
                ?
              </span>
            </Tooltip>
          )}
      </span>

      <span>
        {item.property === 'certificate' ? (
          <a
            className="common-link"
            target="_blank"
            rel="noreferrer"
            href={`${product[item.property]}`}
          >
            {product?.gradingLab} <Icon id="file_certificate" />
          </a>
        ) : (
          <>{product[item.property as 'id']}</>
        )}
      </span>
    </li>
  );
});

export default DetailItems;
