import { makeAutoObservable } from 'mobx';

export type TMediaQuery =
  | 'isMobileVertical'
  | 'isMobile'
  | 'isTablet'
  | 'isTabletMid'
  | 'isBigDesktop'
  | 'isLandscape'
  | 'isDesktop'
  | 'isTouchDevice';

export interface IBrowserWindowType {
  mediaQuery: {
    isMobileVertical: boolean;
    isMobile: boolean;
    isTablet: boolean;
    isTabletMid: boolean;
    isDesktop: boolean;
    isBigDesktop: boolean;
    isLandscape: boolean;
    isTouchDevice: boolean;
  };
}

export default class InfoBrowserWindowType implements IBrowserWindowType {
  mediaQuery = {
    isMobileVertical: false,
    isMobile: false,
    isTablet: false,
    isTabletMid: false,
    isBigDesktop: false,
    isDesktop: false,
    isLandscape: false,
    isTouchDevice: false,
  };

  constructor() {
    makeAutoObservable(this);

    const mobileVerticallQuery = window.matchMedia('(max-width: 480px)');
    const mobileQuery = window.matchMedia('(max-width: 767px)');
    const tabletQuery = window.matchMedia(
      '(min-width: 768px) and (max-width: 1199px)',
    );
    const tabletMidQuery = window.matchMedia(
      '(min-width: 768px) and (max-width: 1023px)',
    );
    const desktopQuery = window.matchMedia('(min-width: 1024px)');
    const fromBigDesktopQuery = window.matchMedia('(min-width: 1324px)');
    const mobileQueryLandscape = window.matchMedia('(orientation: landscape)');
    this.mediaQuery.isTouchDevice = !!('ontouchstart' in window);

    this.runMediaQueryListeners(mobileVerticallQuery, 'isMobileVertical');
    this.runMediaQueryListeners(mobileQuery, 'isMobile');
    this.runMediaQueryListeners(tabletQuery, 'isTablet');
    this.runMediaQueryListeners(tabletMidQuery, 'isTabletMid');
    this.runMediaQueryListeners(fromBigDesktopQuery, 'isBigDesktop');
    this.runMediaQueryListeners(mobileQueryLandscape, 'isLandscape');
    this.runMediaQueryListeners(mobileQueryLandscape, 'isDesktop');
    this.updateInfoMediaQuery('isMobileVertical')(mobileVerticallQuery);
    this.updateInfoMediaQuery('isMobile')(mobileQuery);
    this.updateInfoMediaQuery('isTablet')(tabletQuery);
    this.updateInfoMediaQuery('isTabletMid')(tabletMidQuery);
    this.updateInfoMediaQuery('isBigDesktop')(fromBigDesktopQuery);
    this.updateInfoMediaQuery('isDesktop')(desktopQuery);
    this.updateInfoMediaQuery('isLandscape')(mobileQueryLandscape);
  }

  runMediaQueryListeners = (mediaQuery: MediaQueryList, type: TMediaQuery) => {
    mediaQuery?.addListener
      ? mediaQuery?.addListener(this.updateInfoMediaQuery(type))
      : mediaQuery.addEventListener('change', this.updateInfoMediaQuery(type));
  };

  updateInfoMediaQuery =
    (device: TMediaQuery) =>
    ({ matches }: { matches: boolean }) => {
      this.mediaQuery[device] = matches;
    };
}
