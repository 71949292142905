import React from 'react';
import Svg from '../Svg';

interface UserActiveIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function UserActiveIcon(
  props: UserActiveIconProps,
): JSX.Element {
  return (
    <Svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M9 10.5c2.734 0 5-2.227 5-5 0-2.734-2.266-5-5-5-2.773 0-5 2.266-5 5 0 2.773 2.227 5 5 5Zm3.477 1.25h-.665c-.859.43-1.796.625-2.812.625-1.016 0-1.992-.195-2.852-.625h-.664c-2.89 0-5.234 2.383-5.234 5.273v1.602c0 1.055.82 1.875 1.875 1.875h13.75c1.016 0 1.875-.82 1.875-1.875v-1.602c0-2.89-2.383-5.273-5.273-5.273Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
