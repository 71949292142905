import { makeAutoObservable } from 'mobx';
import { CategoriesProductImpl, ProductCategories } from 'root/ProductTypes';
import Currency, { CurrencyImpl } from 'root/store/currency';
import { fetchHintProductById } from 'service/api/products';
import { TProductHint } from './HintPage.types';

class IProductHint implements TProductHint {
  id = 0;
  defaultPhoto = '';
  photos: string[] = [];
  sku = '';
  description = '';
  type = '';
  shape = '';
  metal = '';
  currency: CurrencyImpl = new Currency();
  categories: CategoriesProductImpl[] = [];

  private _price = '';

  get price() {
    return this.currency.format(this._price);
  }

  set price(value: string) {
    this._price = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  update(product: TProductHint) {
    this.id = product.id;
    this.sku = product.sku;
    this.photos = product.photos as [];
    this.type = product.type;
    this.shape = product.shape;
    this.price = product.price;
    this.metal = product.metal;
    this.description = product.description;
    this.currency.update(product.currency);
    this.categories = product.categories;
    this.defaultPhoto = product.categories
      .map((c) => c.alias)
      .includes(ProductCategories.DIAMONDS)
      ? require(`img/shapes/${product.shape
          .split(' ')
          .join('_')
          .toLowerCase()}.png`).default
      : require(`img/default_images/venori_no_image.png`)?.default;
  }
}

export default class HintPageStore {
  isProductLoading = false;
  product: TProductHint = new IProductHint();
  currency = new Currency();

  constructor() {
    makeAutoObservable(this);
  }

  handleToggleLoading(): void {
    this.isProductLoading = !this.isProductLoading;
  }

  *loadHintProduct(id: number): Generator {
    this.isProductLoading = true;
    yield fetchHintProductById(id)
      .then((product: TProductHint) => {
        this.product.update(product);
        this.isProductLoading = false;
      })
      .catch(() => {
        this.isProductLoading = false;
      });
  }
}
