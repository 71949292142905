import * as React from 'react';
import { observer } from 'mobx-react';
import { TableParamsImpl } from '../../store';
import { CartItemStoneImpl } from 'root/store/cart/item.store';
import { ItemCertificate } from './components/Items/ItemCertificate';

interface DiamondTemplateProps {
  product: CartItemStoneImpl;
  property: TableParamsImpl;
}

export const DiamondTemplate = observer((props: DiamondTemplateProps) => {
  const { product, property } = props;
  return (
    <>
      {(() => {
        switch (property.key) {
          case 'shape':
            return product.shape;
          case 'carat':
            return product.carat;
          case 'cut':
            return product.cut;
          case 'clarity':
            return product.clarity;
          case 'fluorescence':
            return product.fluorescence;
          case 'polish':
            return product.polish;
          case 'certificate':
            return <ItemCertificate key={'certificate'} product={product} />;
          default:
            return null;
        }
      })()}
    </>
  );
});
