import React, { useState } from 'react';
import { gsap } from 'gsap';
import { imagesPaths } from '../../constants';
import { getImg } from 'lib/utils';
import { Link } from 'react-router-dom';
import { ROUTER_PATHS } from 'root/routes/constants';
import ImageItem from 'components/common/ImageItem';
import useElementViewed from '../../helpers/useElementViewed';

import './Jewelry.scss';

interface IProps {
  setAnimatedIndex: (index: number) => void;
  animatedIndex: Record<number, boolean>;
}

export function Jewelry({
  setAnimatedIndex,
  animatedIndex,
}: IProps): JSX.Element {
  const [animated, setAnimated] = useState(false);

  const handleAnimate = () => {
    if (!animated && !animatedIndex[4]) {
      setAnimated(true);
      gsap.fromTo('.first', { x: -500 }, { x: 0, duration: 2 });
      gsap.fromTo('.second', { x: 500 }, { x: 0, duration: 2 });
    }
    setAnimatedIndex(3);
  };

  const { elementRef } = useElementViewed({
    callback: handleAnimate,
    isActiveListener: true,
    threshold: 0.2,
  });

  return (
    <div className="main-slide  main-slide_jewelry" ref={elementRef}>
      <div className="content-main_block" id="jewelry">
        <div className="content-main_block__title">Fine Jewelry</div>
        <div className="content-main_block__description">
          Timeless diamond jewelry that will never go out of style.
          <br /> Browse through our fine selection of diamond earrings,
          bracelets, necklaces and rings.
        </div>
        <div className="content-main_block__jewelry">
          <ImageItem
            className="first"
            src={getImg('mainPage', imagesPaths, '/diamond1')}
          />
          <ImageItem
            className="second"
            src={getImg('mainPage', imagesPaths, '/diamond2')}
          />
        </div>

        <div className="content-main_btn">
          <Link
            to={{ pathname: ROUTER_PATHS.JEWELRY_CATALOG_PATH }}
            className="btn btn_lg2 btn_brown"
          >
            Browse
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Jewelry;
