import * as React from 'react';
import { RouteDataType } from '../FAQPage.type';

export const routes: RouteDataType[] = [
  {
    topicName: 'About Us',
    path: '/about-us',
    title:
      'Hello! What would you like to learn about us? Please select a question below',
    questions: [
      // {
      //   questionName: 'What shipping carrier do you use?',
      //   path: '/carrier',
      // eslint-disable-next-line max-len
      //   answer: 'Terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes Anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven’t heard of them accusamus labore sustainable VHS.',
      // },
      // {
      //   questionName: 'Where do you ship?',
      //   path: '/ship',
      // eslint-disable-next-line max-len
      //   answer: 'Terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird',
      // },
      // {
      //   questionName: 'What if my order gets lost?',
      //   path: '/lost-order',
      //   answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus Terry richardson ad squid',
      // },
      // {
      //   questionName: 'Can I ship to an address other than my home?',
      //   path: '/address',
      // eslint-disable-next-line max-len
      //   answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus Terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin .',
      // },
      {
        questionName: 'Do I have to be at home to receive my shipment?',
        path: '/shipment',
        answer:
          'Yes, someone must be present that is over the age of 18 to accept the package. You must provide ID with proof of age to accept the package.',
      },
      {
        questionName: 'Is my information secure?',
        path: '/info-secure',
        answer:
          'Yes. Venori guarantees that all purchases and personal information of our customers are fully protected.  We utilize extensive fraud protection and secure encryption for all credit card purchases. We use 256-but Secure Socket Layers (SSL) to secure all sensitive data. ',
      },
      {
        questionName:
          'How long after my payment is accepted will I receive my order?',
        path: '/receiving-order',
        answer:
          'After you place your order, it takes 1-2 business days to process your transaction. After we have verified that you placed the order and the transaction is valid, it will take approximately 5-7 business for your order to be made. The completed order will be shipped to you via free FEDEX Overnight.',
      },
      {
        questionName: 'Do you offer payment plans?',
        path: '/payment-plans',
        answer:
          'We offer financing through a third party provider, Syncrony. Approval is subject to their discretion.',
      },
    ],
  },
  {
    topicName: 'Payments',
    path: '/payments',
    title:
      'Hello! What would you like to learn about payments? Please select a question below',
    questions: [
      {
        questionName: 'What payments do you accept?',
        path: '/accepted-payments',
        answer: (
          <>
            <p>
              We accept all major credit cards (VISA, MASTER CARD, American
              Express, Discover) and bank wires. For security purposes, Venori
              may request additional information from you such as a copy of your
              license and credit card to further verify the transaction.
            </p>
            <br />
            <p>
              A lot of our customers that purchase items valued over $500
              dollars select to pay via bank wire. In doing so, they receive a
              1.5% discount on their total order. Once a customer has placed an
              order using the bank wire option, an email with instructions will
              be sent to the customer. Upon confirmation from our bank that the
              funds have been received, your order will be processed.
            </p>
          </>
        ),
      },
      {
        questionName: 'International Payments',
        path: '/international-Payments',
        answer:
          'All international orders must be placed via bank wire unless the customer possesses a credit card issued in the USA with a US billing address.',
      },
      {
        questionName: 'Will I be charged Sales Tax on my order?',
        path: '/taxes',
        answer:
          'Venori collects sales tax on orders that are shipped to the following states: Alabama, Arizona, Arkansas, California, Colorado, District of Columbia, Connecticut, Hawaii, Idaho, Illinois, Indiana, Kansas, Kentucky, Maine, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, New Jersey, New Mexico, New York, Nevada, North Carolina, North Dakota, Ohio, Oklahoma, Pennsylvania, Rhode Island, South Carolina, South Dakota, Tennessee, Texas, Vermont, Washington, Wisconsin, Wyoming, Georgia, Iowa ,Nebraska, Utah, Virginia and West Virginia.',
      },
      {
        questionName: 'Can I pay with two different cards?',
        path: '/pay-different',
        answer:
          'Yes. You may do so by calling in and placing your order with one of our customer service representatives at 877-382-6275.',
      },
    ],
  },
  {
    topicName: 'Shipping',
    path: '/shipping',
    title:
      'Hello! What would you like to learn about shipping? Please select a question below',
    questions: [
      {
        questionName: 'Is there a charge for shipping?',
        path: '/charge-shipping',
        answer:
          'Shipping on all orders is free. We ship via FEDEX Overnight Priority.',
      },
      {
        questionName: 'Do you ship internationally?',
        path: '/ship-internationally',
        answer:
          'Yes. Shipping is free of charge on all orders.  International customers are responsible for any duties, VAT taxes or surcharges charged by their country.',
      },
      {
        questionName: 'When will I receive my order?',
        path: '/receive',
        answer:
          'All orders are processed with in 1-2 business days. Loose diamond orders are shipped within 5-7 business days after payment and processing. All other jewelry and engagement rings are shipped in one to three weeks.',
      },
      {
        questionName: 'Is my shipment secure?',
        path: '/shipment-secure',
        answer:
          'Yes. All shipments are fully insured during transit and require a signature from you or a trusted individual who is 18 years or older to accept the package.',
      },
      {
        questionName:
          'Do you ship to another address other than the billing address?',
        path: '/billing-address',
        answer:
          'If you wish to ship to an address other than your billing address you must contact your credit card company and have that address added to your account in order for us to ship to that address.',
      },
    ],
  },
  {
    topicName: 'Our Diamonds',
    path: '/our-diamonds',
    title:
      'Hello! What would you like to learn about our diamonds? Please select a question below',
    questions: [
      {
        questionName: 'Information about our diamonds',
        path: '/info-our-diamonds',
        answer:
          'Our diamonds are all natural and non-treated or enhanced in any way. To ensure the quality of the diamonds we sell, all of our diamonds come with a grading certificate from GIA or AGS.',
      },
    ],
  },
  {
    topicName: 'Conflict-Free Diamonds',
    path: '/conflict-free',
    title:
      'Hello! What would you like to learn about conflict-free diamonds? Please select a question below',
    questions: [
      {
        questionName: 'Information about conflict-free diamonds',
        path: '/info-conflict-free',
        answer:
          'We care deeply about the environment and human rights so that is why we only sell conflict-free diamonds that are ethically sourced.',
      },
    ],
  },
  {
    topicName: 'Lifetime Warranty and Maintenance',
    path: '/warranty-and-maintenance',
    title:
      'Hello! What would you like to learn about lifetime warranty and maintenance? Please select a question below',
    questions: [
      {
        questionName: 'Information about lifetime warranty and maintenance',
        path: '/info-warranty-and-maintenance',
        answer: (
          <>
            <p>
              We fully stand behind our products and ensure that they are made
              from the finest materials available. All of products are carefully
              hand-crafted and inspected for quality before getting to your
              hands. Venori guarantees that all items will be free from
              manufacturing defects at the time of delivery. We also offer free
              annual cleaning, polishing, rhodium plating and prong tightening
              services.
            </p>
            <br />
            <p>
              In the event that there is an issue with your jewelry that is not
              covered under our lifetime warranty, you can mail your jewelry
              back to us at your expense or purchase an insured shipping label
              from us. We will contact you prior to any work being done to your
              jewelry.
            </p>
          </>
        ),
      },
    ],
  },
  {
    topicName: 'Return Policy',
    path: '/return-policy',
    title:
      'Hello! What would you like to learn about Return Policy? Please select a question below',
    questions: [
      {
        questionName: 'Information about Return Policy',
        path: '/info-return-policy',
        answer: (
          <>
            <p>
              We want you to be 100% satisfied with your purchase so we offer a
              Free 14-Day Returns Policy. All jewelry must be in original form,
              not worn or damaged and in original packaging in order to receive
              a refund. All original items that came with your package including
              diamond certificates, appraisals and jewelry box must be returned
              in original form. A fee of $150 will be charged if the diamond
              certificate is not returned. You must contact customer service
              within 14 days of receiving your item to get an RMA number and
              begin the return process. We will provide you with a free return
              shipping label and you may take it to any local FEDEX if you need
              assistance with return packaging. Returns take up to 2 weeks to be
              processed and you will be refunded with original form of payment.
              If you paid by wire transfer, you will be refunded via a company
              check.
            </p>
          </>
        ),
      },
    ],
  },
  // {
  //   topicName: 'Orders and Returns',
  //   path: '/orders-and-returns',
  //   title: 'Hello! What would you like to learn about orders and returns? Please select a question below',
  //   questions: [
  //     {
  //       questionName: 'How long does it take to get a refund?',
  //       path: '/refund',
  //       answer: 'Cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.',
  //     },
  //   ],
  // },
  // {
  //   topicName: 'Shipping and Payments',
  //   path: '/shipping-and-payments',
  //   title: 'Hello! What would you like to learn about Shipping and Payments? Please select a question below',
  //   questions: [],
  // },
  // {
  //   topicName: 'Our Diamonds and Jewelry',
  //   path: '/jewelry',
  //   title: 'Hello! What would you like ? Please select a question below',
  //   questions: [],
  // },
  // {
  //   topicName: 'Life-time Warranty and Maintenance',
  //   path: '/maintenance',
  //   title: 'Hello! What would you like ? Please select a question below',
  //   questions: [],
  // },
  {
    topicName: 'Complimentary Ring Sizing',
    path: '/complimentary-ring-sizing',
    title:
      'Hello! What would you like to learn about Complimentary Ring Sizing? Please select a question below',
    questions: [
      {
        questionName: 'What is Complimentary Ring Sizing?',
        path: '/info-complimentary-ring-sizing',
        answer: (
          <>
            <p>
              We offer a complimentary ring sizing within 1 month of your
              purchase delivery date made within the USA. Simply call or send us
              an email requesting for your ring to be sized and we will send you
              a free return shipping label. If you need to know your exact ring
              size, we can ship you a free ring sizer.
            </p>
            <br />
            <p>
              In the event that your ring can’t be sized because of a size
              restriction, it falls outside of the recommended range or due to
              the design style, we can replace it only within the 14-day return
              period. If it is outside the 14 day return period, you will be
              charged a fee to replace the setting. Upon receipt and inspection
              of your ring, we will notify you prior to any work being done to
              discuss the charge to replace your ring.
            </p>
            <br />
            <p>
              Please note that your ring may not look “exactly” the same after
              being sized as minor variations can occur. Please contact us if
              you have any questions.
            </p>
          </>
        ),
      },
    ],
  },
];
