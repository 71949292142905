import React from 'react';
import Svg from '../Svg';

interface ICloseIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function CloseIcon(props: ICloseIconProps): JSX.Element {
  return (
    <Svg width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
      <path
        d="m7.031 5 3.219-3.188.656-.656c.094-.093.094-.25 0-.375L10.22.094C10.094 0 9.938 0 9.844.094L6 3.969 2.125.094C2.031 0 1.875 0 1.75.094L1.062.78c-.093.125-.093.281 0 .375L4.938 5 1.063 8.875c-.093.094-.093.25 0 .375l.688.688c.125.093.281.093.375 0L6 6.062l3.188 3.22.656.655c.094.094.25.094.375 0l.687-.687c.094-.125.094-.281 0-.375L7.031 5Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
