import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import OrderStore from 'root/store/order';
import PaginateStore from 'root/store/paginate';
import { JewelryFiltersStore } from './jewelryFilters.store';
import { fetchFullJewelry } from 'service/api/jewelry';
import { TJewelryCatalog } from '../types';
import {
  ICatalogProducts,
  ICatalogQueryParams,
} from 'root/store/products/types/product-catalog.type';

export class JewelryCatalogStore
  implements ICatalogProducts<TJewelryCatalog, JewelryFiltersStore>
{
  filters!: JewelryFiltersStore;
  order!: OrderStore;
  pagination!: PaginateStore;
  list: IObservableArray<TJewelryCatalog> = observable<TJewelryCatalog>([]);
  _loading = true;

  constructor() {
    makeAutoObservable(this);
    this.resetCatalog();
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get loading() {
    return this._loading;
  }

  get queryParams(): ICatalogQueryParams<JewelryFiltersStore> {
    return {
      page: this.pagination.page,
      limit: this.pagination.limit,
      sortBy: this.order.sortBy,
      sortOrder: this.order.sortOrder,
      filters: this.filters.queryParams,
    };
  }

  resetCatalog() {
    this.list.clear();
    this.filters = new JewelryFiltersStore();
    this.order = new OrderStore();
    this.pagination = new PaginateStore();
  }

  putCatalog(items: TJewelryCatalog[] = []) {
    this.list.replace(items);
  }

  updateCatalog(query: ICatalogQueryParams<JewelryFiltersStore>) {
    this.pagination.setPage(Number(query.page));
    this.filters.update(query.filters);
    this.pagination.setLimit(Number(query.limit));
    this.order.updateOrder(query.sortBy, query.sortOrder);
  }

  /**
   * Load products list
   * @param {Object=} query
   * @param {Number=} query.page
   * @param {Number=} query.limit
   * @param {String=} query.sortBy
   * @param {String=} query.sortOrder
   * @returns {Generator<void>}
   */
  *loadProducts(query?: ICatalogQueryParams<JewelryFiltersStore>) {
    const start = Date.now();
    const params = { ...this.queryParams, ...query };
    const { results, total, totalPages } = yield fetchFullJewelry(params);
    this.putCatalog(results);
    this.pagination.setTotalPages(totalPages);
    this.pagination.setTotal(total);

    const delay = Date.now() - start;
    setTimeout(
      () => {
        this.loading = false;
      },
      delay < 700 ? 700 - delay : 0,
    );
  }
}

export default new JewelryCatalogStore();
