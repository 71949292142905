import * as React from 'react';
import { observer } from 'mobx-react';
import rootStore from 'root/store';
import ProfilePage from './Profile';
import { profileStore } from './Profile.store';

const {
  account: { user, profile },
  app: { countries },
} = rootStore;

export default observer(() => (
  <ProfilePage
    profileStore={profileStore}
    user={user}
    profile={profile}
    countries={countries}
  />
));
