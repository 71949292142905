import 'lib/env';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { App } from './App';
import store from 'root/store/index';
import pkg from '../package.json';

import './index.scss';

if (process.env.REACT_APP_SENTRY_LOG === 'on') {
  Sentry.init({
    dsn: 'https://69771863a8744e8eb203b224857f07c1@o357447.ingest.sentry.io/5473249',
    release: `venori_frontend@${JSON.stringify(pkg.version)}`,
    environment: process.env.NODE_ENV,
  });
}

// TODO: delete Provider after rewrite components to new structure (without @reject) !

ReactDOM.render(
  <Provider {...store}>
    <Router>
      <App user={store.account.user} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
