import React from 'react';
import Svg from '../Svg';

interface TooltipkIcom {
  children?: React.ReactNode;
}

export default function Tooltip(props: TooltipkIcom): JSX.Element {
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M8 0.75C3.71875 0.75 0.25 4.25 0.25 8.5C0.25 12.7812 3.71875 16.25 8 16.25C12.25 16.25 15.75 12.7812 15.75 8.5C15.75 4.25 12.25 0.75 8 0.75ZM8 14.75C4.53125 14.75 1.75 11.9688 1.75 8.5C1.75 5.0625 4.53125 2.25 8 2.25C11.4375 2.25 14.25 5.0625 14.25 8.5C14.25 11.9688 11.4375 14.75 8 14.75ZM8 4.1875C7.25 4.1875 6.6875 4.78125 6.6875 5.5C6.6875 6.25 7.25 6.8125 8 6.8125C8.71875 6.8125 9.3125 6.25 9.3125 5.5C9.3125 4.78125 8.71875 4.1875 8 4.1875ZM9.75 12.125V11.375C9.75 11.1875 9.5625 11 9.375 11H9V7.875C9 7.6875 8.8125 7.5 8.625 7.5H6.625C6.40625 7.5 6.25 7.6875 6.25 7.875V8.625C6.25 8.84375 6.40625 9 6.625 9H7V11H6.625C6.40625 11 6.25 11.1875 6.25 11.375V12.125C6.25 12.3438 6.40625 12.5 6.625 12.5H9.375C9.5625 12.5 9.75 12.3438 9.75 12.125Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
