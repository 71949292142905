import { ROUTER_PATHS } from 'root/routes/constants';
import { EngagementRingsFilterParams } from 'pages/EngagementRings/store/engagementRingFilters.store';
import { RING_BAND, RING_METAL, RING_STYLES } from '../rings';
import { DIAMOND_SHAPES, DIAMOND_TYPES } from '../stones/diamonds.constants';
import { DiamondsFilterParams } from 'pages/Diamonds/store/diamondFilters.store';
import { WeddingRingsFilterParams } from 'pages/WeddingRings/store/weddingRingFilers.store';
import { ProductCategories, ProductPromos } from '../../../root/ProductTypes';
import { JewelryFilterParams } from '../../../pages/FineJewelry/store/jewelryFilters.store';

export const ENGAGEMENT_RINGS_ITEMS = [
  {
    text: 'Design Your Own Ring',
    index: 12,
    items: [
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Start with a Diamond',
        params: {
          filters: {
            [DiamondsFilterParams.TYPE]: [DIAMOND_TYPES.DIAMONDS],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Start With A Lab-Created Diamond',
        params: {
          filters: {
            [DiamondsFilterParams.TYPE]: [DIAMOND_TYPES.DIAMONDS_LAB_CREATED],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Start with a Setting',
        params: null,
      },
    ],
  },
  {
    text: 'Shop By Style',
    index: 11,
    items: [
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Solitaire',
        params: {
          filters: {
            [EngagementRingsFilterParams.RING_STYLE]: [
              RING_STYLES.EG_RING_SOLITAIRE,
            ],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Halo',
        params: {
          filters: {
            [EngagementRingsFilterParams.RING_STYLE]: [
              RING_STYLES.EG_RING_HALO,
            ],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Two-Stone',
        params: {
          filters: {
            [EngagementRingsFilterParams.RING_STYLE]: [
              RING_STYLES.EG_RING_TWOSTONE,
            ],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Three-Stone',
        params: {
          filters: {
            [EngagementRingsFilterParams.RING_STYLE]: [
              RING_STYLES.EG_RING_THREESTONE,
            ],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Accented Stones',
        params: {
          filters: {
            [EngagementRingsFilterParams.RING_STYLE]: [
              RING_STYLES.EG_RING_ACCENTED,
            ],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Bezel Set',
        params: {
          filters: {
            [EngagementRingsFilterParams.RING_STYLE]: [
              RING_STYLES.EG_RING_BEZEL,
            ],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Vintage',
        params: {
          filters: {
            [EngagementRingsFilterParams.RING_STYLE]: [
              RING_STYLES.EG_RING_VINTAGE,
            ],
          },
        },
      },
    ],
  },
  {
    text: 'Shop By Metal',
    index: 13,
    items: [
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'White Gold',
        params: {
          filters: {
            [EngagementRingsFilterParams.METAL]: [RING_METAL.EG_RING_W_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Yellow Gold',
        params: {
          filters: {
            [EngagementRingsFilterParams.METAL]: [RING_METAL.EG_RING_Y_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Rose Gold',
        params: {
          filters: {
            [EngagementRingsFilterParams.METAL]: [RING_METAL.EG_RING_R_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: 'Platinum',
        params: {
          filters: {
            [EngagementRingsFilterParams.METAL]: [RING_METAL.EG_RING_PLT],
          },
        },
      },
    ],
  },
];

export const DIAMONDS_ITEMS = [
  {
    text: 'Design Your Own Ring',
    index: 21,
    items: [
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Start with a Diamond',
        params: {
          filters: {
            [DiamondsFilterParams.TYPE]: [DIAMOND_TYPES.DIAMONDS],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Start with a Lab-Created Diamond',
        params: {
          filters: {
            [DiamondsFilterParams.TYPE]: [DIAMOND_TYPES.DIAMONDS_LAB_CREATED],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
        text: ' Start with a Setting',
        params: null,
      },
    ],
  },

  {
    text: 'Shop By Shape',
    index: 21,
    items: [
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Round',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_ROUND],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Princess',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_PRINCESS],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Cushion',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_CUSHION_MODIFIED],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Radiant',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_RADIANT],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Asscher',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_ASSCHER],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Emerald',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_EMERALD],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Oval',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_OVAL],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Pear',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_PEAR],
          },
        },
      },
      {
        link: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
        text: 'Marquise',
        params: {
          filters: {
            [DiamondsFilterParams.SHAPE]: [DIAMOND_SHAPES.D_MARQUISE],
          },
        },
      },
    ],
  },

  {
    text: `LEARN THE 4 C'S`,
    index: 21,
    items: [
      {
        link: ROUTER_PATHS.EDUCATION_CARAT_PATH,
        text: 'Carat',
        params: null,
      },
      {
        link: ROUTER_PATHS.EDUCATION_CUTTING_PATH,
        text: 'Cut',
        params: null,
      },
      {
        link: ROUTER_PATHS.EDUCATION_CLARITY_PATH,
        text: 'Clarity',
        params: null,
      },
      {
        link: ROUTER_PATHS.EDUCATION_COLOR_PATH,
        text: 'Color',
        params: null,
      },
    ],
  },
];

export const WENDING_RINGS_ITEMS = [
  {
    text: 'Shop Women`s',
    index: 31,
    items: [
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Diamond Bands',
        params: {
          filters: {
            [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_DIAMOND_WOMAN],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Stackable Rings',
        params: {
          filters: {
            [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_STACKABLE],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Eternity Rings',
        params: {
          filters: {
            [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_ETERNITY],
          },
        },
      },
    ],
  },
  {
    text: 'Shop Men`s',
    index: 31,
    items: [
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Diamond Bands',
        params: {
          filters: {
            [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_DIAMOND_MAN],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Alternative Metal Bands',
        params: {
          filters: {
            [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_ALT_METAL],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Сarved bands',
        params: {
          filters: {
            [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_CARVED],
          },
        },
      },
    ],
  },
  {
    text: 'Shop By Band Type',
    index: 31,
    items: [
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Classic Bands',
        params: {
          filters: {
            [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_CLASSIC],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Anniversary Bands',
        params: {
          filters: {
            [WeddingRingsFilterParams.BAND]: [RING_BAND.WB_RING_ANNIVERSARY],
          },
        },
      },
    ],
  },
  {
    text: 'Shop By Metal',
    index: 31,
    items: [
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'White Gold',
        params: {
          filters: {
            [WeddingRingsFilterParams.METAL]: [RING_METAL.EG_RING_W_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Yellow Gold',
        params: {
          filters: {
            [WeddingRingsFilterParams.METAL]: [RING_METAL.EG_RING_Y_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Rose Gold',
        params: {
          filters: {
            [WeddingRingsFilterParams.METAL]: [RING_METAL.EG_RING_R_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
        text: 'Platinum',
        params: {
          filters: {
            [WeddingRingsFilterParams.METAL]: [RING_METAL.EG_RING_PLT],
          },
        },
      },
    ],
  },
];

export const FINE_JEWELRY_ITEMS = [
  {
    text: 'Shop By Type',
    index: 41,
    items: [
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Rings',
        params: {
          filters: {
            [JewelryFilterParams.CATEGORIES]: [ProductCategories.RINGS],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Earrings',
        params: {
          filters: {
            [JewelryFilterParams.CATEGORIES]: [ProductCategories.EARRINGS],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Necklaces',
        params: {
          filters: {
            [JewelryFilterParams.CATEGORIES]: [ProductCategories.NECKLACES],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Bracelets',
        params: {
          filters: {
            [JewelryFilterParams.CATEGORIES]: [ProductCategories.BRACELETS],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Pendants',
        params: {
          filters: {
            [JewelryFilterParams.CATEGORIES]: [ProductCategories.PENDANTS],
          },
        },
      },
    ],
  },
  {
    text: 'Shop By Metal',
    index: 41,
    items: [
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'White Gold',
        params: {
          filters: {
            [JewelryFilterParams.METAL]: [RING_METAL.EG_RING_W_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Yellow Gold',
        params: {
          filters: {
            [JewelryFilterParams.METAL]: [RING_METAL.EG_RING_Y_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Rose Gold',
        params: {
          filters: {
            [JewelryFilterParams.METAL]: [RING_METAL.EG_RING_R_GLD],
          },
        },
      },
    ],
  },
  {
    text: 'Natural Diamond Jewelry and',
    index: 10,
    items: [
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Earrings',
        params: {
          filters: {
            [JewelryFilterParams.CATEGORIES]: [ProductCategories.EARRINGS],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Pendant',
        params: {
          filters: {
            [JewelryFilterParams.CATEGORIES]: [ProductCategories.PENDANTS],
          },
        },
      },
    ],
  },

  // Add along with jewelry constructor
  /* {
    text: 'Create Your Own Jewelry',
    index: 41,
    items: [
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Diamond Studs',
        params: {
          filters: {
            [JewelryFilterParams.METAL]: [RING_METAL.EG_RING_W_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Pendant Necklace',
        params: {
          filters: {
            [JewelryFilterParams.METAL]: [RING_METAL.EG_RING_Y_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Tennis Necklace',
        params: {
          filters: {
            [JewelryFilterParams.METAL]: [RING_METAL.EG_RING_R_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Tennis Bracelet',
        params: {
          filters: {
            [JewelryFilterParams.METAL]: [RING_METAL.EG_RING_R_GLD],
          },
        },
      },
      {
        link: ROUTER_PATHS.JEWELRY_CATALOG_PATH,
        text: 'Eternity Band',
        params: {
          filters: {
            [JewelryFilterParams.METAL]: [RING_METAL.EG_RING_R_GLD],
          },
        },
      },
    ],
  }, */
];
