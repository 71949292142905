import React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
import rootStore from 'root/store';
import FilterExtra from '../../store/diamondFiltersExtra.store';
import diamondCatalogStore from '../../store';
import { Catalog as DefaultComponent } from './Catalog';

interface IDiamondProps extends RouteComponentProps {
  historyReplace: (query: unknown) => void;
  location: H.Location<{ needToResetFilters?: boolean }>;
}

const filtersExtra = new FilterExtra();
const { cart } = rootStore;

export const Catalog = observer(
  withRouter((props: IDiamondProps) => (
    <DefaultComponent
      {...props}
      cart={cart}
      catalog={diamondCatalogStore}
      filtersExtra={filtersExtra}
    />
  )),
);
