/**
 * Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
 * @param {String} path
 */
export const escapedPath = (path: string): string =>
  path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

/**
 * Trim first and last slashes
 * @param {String} path
 */
export const trimSlash = (path: string): string => path.replace(/^\/|\/$/g, '');
