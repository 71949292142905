import DiamondSize025 from './DiamondSize025';
import DiamondSize050 from './DiamondSize050';
import DiamondSize075 from './DiamondSize075';
import DiamondSize100 from './DiamondSize100';
import DiamondSize150 from './DiamondSize150';
import DiamondSize200 from './DiamondSize200';
import DiamondSize250 from './DiamondSize250';
import DiamondSize300 from './DiamondSize300';

export default {
  carat_025: DiamondSize025,
  carat_050: DiamondSize050,
  carat_075: DiamondSize075,
  carat_100: DiamondSize100,
  carat_150: DiamondSize150,
  carat_200: DiamondSize200,
  carat_250: DiamondSize250,
  carat_300: DiamondSize300,
};
