import { TContentType } from './ForgotPassword.types';

export const COMPLETED_REDIRECT_DELAY = 10;

export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';
export const CREATION_SUCCESS = 'CREATION_SUCCESS';
export const CREATION_FAILED = 'CREATION_FAILED';
export const CREATION_PENDING = 'CREATION_PENDING';

export const getContentValue = (
  status: string | null,
  resendTimerCount: number,
): TContentType | null => {
  switch (status) {
    case ACTIVATION_SUCCESS:
      return {
        title: 'Write new password',
        text: '',
        btnText: 'Change password',
      };
    case CREATION_PENDING:
      return {
        title: 'Write new password',
        text: '',
        btnText: 'Changing...',
      };
    case ACTIVATION_ERROR:
      return {
        title: 'Something went wrong',
        text: 'Token was expired',
        btnText: '',
      };

    case CREATION_SUCCESS:
      return {
        title: 'Changing completed',
        // eslint-disable-next-line max-len
        text: `You Changing Process is completed successfully. You can sign in now. <br/>You will be redirected to sign in screen automatically in ${resendTimerCount} seconds…`,
        btnText: 'Proceed to Sing In',
      };
    case CREATION_FAILED:
      return {
        title: 'Changing FAILED',
        text: `Try again`,
        btnText: '',
      };
    default:
      return null;
  }
};
