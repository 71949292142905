import React from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import * as qs from 'qs';
import { CarouselStoreImpl } from 'root/store/carousel';
import { getImg } from 'lib/utils';
import ImageItem from '../ImageItem';

interface CarouselImpl {
  carousel: CarouselStoreImpl;
  sliderLength: number;
  handleSlide: (
    index: number,
  ) => (event: React.MouseEvent<HTMLElement>) => void;
  productImages: string[];
  folderImg: string;
}

export const Carousel = observer((props: CarouselImpl) => {
  const { carousel, sliderLength, handleSlide, productImages, folderImg } =
    props;

  /**
   * Redirect to catalog by ring style
   * @param event
   */
  const navigateByFilter =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      if (carousel.activeIndex !== index) {
        handleSlide(index);
      } else {
        event.stopPropagation();
      }
    };

  return (
    <div className="grid-area-carousel carousel">
      <div className="slider-size-control">
        <div className="slider">
          <div
            className="slider-inner"
            style={{
              width: `${sliderLength * 100}%`,
              transform: `translate3d(-${
                (carousel.activeIndex * 100) / sliderLength
              }%, 0, 0)`,
            }}
          >
            {carousel.items.map((param, index) => {
              const { params, link } = param;
              const queryString = qs.stringify(params || '', {
                arrayFormat: 'brackets',
              });
              return (
                <div
                  className={`slider-image-wrap ${
                    carousel.activeIndex === index ? 'active' : ''
                  }`}
                  style={{ width: `${100 / sliderLength}%` }}
                  key={index}
                  data-index={index}
                >
                  <NavLink
                    to={{
                      pathname: link as string,
                      search: queryString,
                      state: { params },
                    }}
                  >
                    <ImageItem
                      className="slider__image slider__image_initial"
                      modifiers="slider__image-img"
                      src={getImg(folderImg, productImages, `/${param.icon}`)}
                      onClick={navigateByFilter(index)}
                    />
                  </NavLink>
                  <NavLink
                    to={{
                      pathname: link as string,
                      search: queryString,
                      state: { params },
                    }}
                  >
                    <ImageItem
                      className="slider__image slider__image_blurred"
                      modifiers="slider__image-img"
                      src={getImg(
                        `${folderImg}/${folderImg}_blurred`,
                        productImages,
                        `/${param.icon}`,
                      )}
                      onClick={navigateByFilter(index)}
                    />
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {carousel.activeIndex ? (
        <div
          className="arrow arrow_left"
          onClick={handleSlide(carousel.activeIndex - 1)}
        >
          <i className="icon-arrow icon-arrow_left"></i>
        </div>
      ) : null}
      {carousel.activeIndex !== carousel.items.length - 1 ? (
        <div
          className="arrow arrow_right"
          onClick={handleSlide(carousel.activeIndex + 1)}
        >
          <i className="icon-arrow icon-arrow_right"></i>
        </div>
      ) : null}
    </div>
  );
});
