import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import ImageItem from 'components/common/ImageItem';
import { RouteComponentProps } from 'react-router-dom';
import { IObservableArray } from 'mobx';
import { TJewelryCatalog } from '../../types';
import { IProduct } from 'root/store/products/types/product.type';
import { CompareImpl } from 'root/store/compare';

export interface TableBodyProps extends RouteComponentProps {
  data: IObservableArray<TJewelryCatalog>;
  compare: CompareImpl;
}

export const TableBody = withRouter(
  observer((props: TableBodyProps) => {
    const { data, compare } = props;

    const handleClick =
      (id: number) =>
      (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        e.stopPropagation();
        const { pathname } = props.location;
        props.history.push(`${pathname}/${id}`);
      };

    const handleOpenUrl =
      (url: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(url, '_blank');
      };

    const stopPropagation = (
      e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    ) => {
      e.stopPropagation();
    };

    const handleAddToCompare = (product: IProduct) => () => {
      compare.toggle((product as any).productId);
    };

    return (
      <tbody>
        {data &&
          (data.length === 0 ? (
            <tr className="no-hover">
              <td colSpan={8}>
                <p className="attention-message">
                  Found no items matched to search parameters.
                </p>
              </td>
            </tr>
          ) : (
            data.map((item, index: number) => {
              return (
                <tr key={index} onClick={handleClick(item.productId)}>
                  <td>
                    <label className="checkbox-container">
                      <input
                        checked={compare.exist(item.productId)}
                        type="checkbox"
                        name={`compare_${item.id}`}
                        onClick={stopPropagation}
                        onChange={handleAddToCompare(item)}
                      />
                      <span
                        className="checkmark"
                        onClick={stopPropagation}
                      ></span>
                    </label>
                  </td>
                  <td>
                    <CSSTransition in appear timeout={1000} classNames="zoom">
                      <ImageItem src={item.photo} />
                    </CSSTransition>
                  </td>
                  <td>{item.id}</td>
                  <td>{item.metal}</td>
                  <td>{item.description}</td>
                  <td>${item.minPrice}</td>
                </tr>
              );
            })
          ))}
      </tbody>
    );
  }),
);
