import RadiantMeasLengthIcon from './RadiantMeasLengthIcon';
import RadiantMeasWidthIcon from './RadiantMeasWidthIcon';
import RadiantMeasDepthIcon from './RadiantMeasDepthIcon';
import RadiantDepthIcon from './RadiantDepthIcon';
import RadiantTableIcon from './RadiantTableIcon';
import RadiantIcon from './RadiantIcon';

export default {
  radiant_measLength: RadiantMeasLengthIcon,
  radiant_measWidth: RadiantMeasWidthIcon,
  radiant_measDepth: RadiantMeasDepthIcon,
  radiant_depth: RadiantDepthIcon,
  radiant_table: RadiantTableIcon,
  radiant: RadiantIcon,
};
