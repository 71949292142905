import React from 'react';
import { observer } from 'mobx-react';
import OrderStore from 'root/store/order';
import Icon from 'components/common/Icon';
import { joinClasses } from 'lib/utils';

interface ThProps {
  item: { className: string; title: string; order?: string };
  order: OrderStore;
  handleOrder: (property: string, direction: string | null) => void;
}

export const Th = observer((props: ThProps) => {
  const { item, order } = props;
  const property = order.sortBy;
  const direction = order.sortOrder;

  const sortBy = (event: React.MouseEvent<HTMLElement>): void => {
    const { handleOrder } = props;

    event.preventDefault();
    let newDirection = null;
    if (!direction || direction === 'null' || property !== item.order) {
      newDirection = 'ASC';
    } else {
      if (direction === 'ASC') {
        newDirection = 'DESC';
      } else if (direction === 'DESC') {
        newDirection = null;
      } else if (!direction) {
        newDirection = 'ASC';
      }
    }
    handleOrder(item.order || '', newDirection);
  };

  return (
    <th className={item.className}>
      <p className="content-wrap">
        <span>
          {item.title}
          {item.order ? (
            <span className="double-icons" onClick={sortBy}>
              <Icon
                id="caret"
                className={joinClasses([
                  'double-icons__caret-up',
                  item.order &&
                    item.order === property &&
                    direction === 'ASC' &&
                    'active',
                ])}
              />
              <Icon
                id="caret"
                className={joinClasses([
                  item.order &&
                    item.order === property &&
                    direction === 'DESC' &&
                    'active',
                ])}
              />
            </span>
          ) : null}
        </span>
      </p>
    </th>
  );
});
