import StonesStore from './stones';
import CartStore from './cart';
import AppStore from './app';
import Account from './account';
import Favorite from './favorite';
import Orders from './orders';
import RingsStore from './rings';
import JewelryStore from './jewelry';
import { ServiceRequestStore } from './serviceRequest.store';
import InfoBrowserWindowType from './infoBrowserWindowType.store';
import Compare from './compare';

export default {
  app: new AppStore(),
  stones: new StonesStore(),
  rings: new RingsStore(),
  jewelry: new JewelryStore(),
  cart: new CartStore(),
  compare: new Compare(),
  account: new Account(),
  favorite: new Favorite(),
  orders: new Orders(),
  service: new ServiceRequestStore(),
  browserWindowType: new InfoBrowserWindowType(),
};
