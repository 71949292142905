import React from 'react';
import OrderStore from 'root/store/order';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Th } from '../TableHeadTh/TableHeadTh';

export type ColsImpl = {
  title: string;
  className: string;
  order?: string;
};

/**
 * Constant for table head
 * @type {[]}
 */
const COLS_WEDDING_RINGS = [
  {
    title: 'Compare',
    className: '',
  },
  {
    title: 'View',
    className: '',
  },
  {
    title: 'Code',
    className: 'enableOrder',
    order: 'id',
  },
  {
    title: 'Band',
    className: 'enableOrder',
  },
  {
    title: 'Metal',
    className: 'enableOrder',
    order: 'metal',
  },
  {
    title: 'Price',
    className: 'enableOrder',
    order: 'min_price',
  },
];

interface TableHeadProps extends RouteComponentProps {
  order: OrderStore;
  handleOrder: (property: string, direction: string | null) => void;
}

export const TableHead = withRouter(
  observer((props: TableHeadProps) => {
    const { order, handleOrder } = props;

    return (
      <thead>
        <tr>
          {COLS_WEDDING_RINGS.map((i: ColsImpl) => {
            return (
              <Th
                key={i.title}
                item={i}
                order={order}
                handleOrder={handleOrder}
              />
            );
          })}
        </tr>
      </thead>
    );
  }),
);
