import { DIAMOND_SHAPES, DIAMOND_TYPES } from './diamonds.constants';

export const COLORS = {
  10: 'D',
  20: 'E',
  30: 'F',
  40: 'G',
  50: 'H',
  60: 'I',
  70: 'J',
  80: 'K',
  90: 'L',
  100: 'M',
};

export const TYPES = {
  price: {
    tooltip: false,
    isNeedDetail: false,
  },
  stoneType: {
    tooltip: false,
    isNeedDetail: false,
  },
  shape: {
    tooltip:
      "Shape describes a diamond's form. It is the geometric appearance of the diamond.",
    isNeedDetail: false,
  },
  carat: {
    tooltip: 'Carat weight is the measurement of how much a diamond weighs.',
    isNeedDetail: false,
  },
  grade: {
    tooltip:
      "Diamond cut affects the way light is reflected (how sparkly the diamond is). Diamond cut refers to a diamond's proportions, symmetry and polish.",
    isNeedDetail: false,
  },
  color: {
    tooltip:
      'The highest quality diamonds (D) are colorless, wile those of lower quality (M) have noticeable color, which manifests as pale yellow in diamonds.',
    isNeedDetail: false,
  },
  fluorescence: {
    tooltip:
      'Diamond Fluorescence is the tendency of a diamond to emit a (soft) glow when exposed to ultraviolet light (UV light).',
    isNeedDetail: false,
  },
  clarity: {
    tooltip:
      'Diamond clarity is the assessment of small imperfections on the surface and internally.',
    isNeedDetail: false,
  },
  certificate: {
    tooltip:
      'Laboratory certification provides an impartial judgment of the characteristics and quality of each diamond. This certification gives the purchaser added confidence that the diamond received is as described by the seller.',
    isNeedDetail: false,
  },
  table: {
    tooltip:
      'The table % of a diamond is calculated by dividing the width of the table facet by the width of the diamond.',
    isNeedDetail: true,
  },
  measDepth: {
    tooltip:
      'Depth is the distance from the table to the culet (the pointed tip) of the diamond.',
    isNeedDetail: true,
  },
  polish: {
    tooltip:
      'Polish refers to the degree of smoothness of each facet of a diamond as measured by a gemologist.',
    isNeedDetail: false,
  },
  symmetry: {
    tooltip:
      'Symmetry means how precisely the various facets of a diamond align and intersect.',
    isNeedDetail: false,
  },
  measLength: {
    tooltip:
      'Length is the measure of the diamond from "top" to "bottom" as it would sit in a ring.',
    isNeedDetail: true,
  },
  measWidth: {
    tooltip: 'Width is the measure that runs perpendicular to length.',
    isNeedDetail: true,
  },
  depth: {
    tooltip:
      'The depth % of a diamond is calculated by dividing the depth by the width of the diamond.',
    isNeedDetail: true,
  },
  tablePercent: {
    tooltip:
      'The table % of a diamond is calculated by dividing the width of the table facet by the width of the diamond.',
    isNeedDetail: false,
  },
};

export const STONE_TYPES = [
  {
    title: 'Natural',
    value: DIAMOND_TYPES.DIAMONDS,
    popular: false,
  },
  {
    title: 'Lab-Created',
    value: DIAMOND_TYPES.DIAMONDS_LAB_CREATED,
    popular: true,
  },
  {
    title: 'All',
    value: null,
    icon: null,
    popular: false,
  },
];

export const SHAPES = [
  {
    title: 'Round',
    value: DIAMOND_SHAPES.D_ROUND,
    icon: 'round',
    popular: true,
  },
  {
    title: 'Princess',
    value: DIAMOND_SHAPES.D_PRINCESS,
    icon: 'princess',
    popular: false,
  },
  {
    title: 'Cushion',
    value: DIAMOND_SHAPES.D_CUSHION_MODIFIED,
    icon: 'cushion',
    popular: true,
  },
  {
    title: 'Radiant',
    value: DIAMOND_SHAPES.D_RADIANT,
    icon: 'radiant',
    popular: true,
  },
  {
    title: 'Asscher',
    value: DIAMOND_SHAPES.D_ASSCHER,
    icon: 'asscher',
    popular: false,
  },
  {
    title: 'Emerald',
    value: DIAMOND_SHAPES.D_EMERALD,
    icon: 'emerald',
    popular: false,
  },
  {
    title: 'Oval',
    value: DIAMOND_SHAPES.D_OVAL,
    icon: 'oval',
    popular: true,
  },
  {
    title: 'Pear',
    value: DIAMOND_SHAPES.D_PEAR,
    icon: 'pear',
    popular: false,
  },
  {
    title: 'Marquise',
    value: DIAMOND_SHAPES.D_MARQUISE,
    icon: 'marquise',
    popular: false,
  },
  {
    title: 'All',
    value: null,
    icon: null,
    popular: false,
  },
];

export const CUT_GRADE = [
  {
    title: 'Excellent',
    value: 'Excellent',
  },
  {
    title: 'Very Good',
    value: 'Very Good',
  },
  {
    title: 'Good',
    value: 'Good',
  },
  {
    title: 'Fair',
    value: 'Fair',
  },
  {
    title: 'Poor',
    value: 'poor',
  },
  {
    title: 'Ideal',
    value: 'Ideal',
  },
  {
    title: 'All',
    value: null,
  },
];

export const FLUORESCENCE = [
  {
    title: 'Very Strong',
    value: 'Very Strong',
  },
  {
    title: 'Strong',
    value: 'Strong',
  },
  {
    title: 'Medium',
    value: 'Medium',
  },
  {
    title: 'Slight',
    value: 'Slight',
  },
  {
    title: 'Very Slight',
    value: 'Very Slight',
  },
  {
    title: 'Faint',
    value: 'Faint',
  },
  {
    title: 'None',
    value: 'None',
  },
  {
    title: 'All',
    value: null,
  },
];

export const CLARITY = [
  {
    title: 'IF',
    value: 'IF',
  },
  {
    title: 'VVS1',
    value: 'VVS1',
  },
  {
    title: 'VVS2',
    value: 'VVS2',
  },
  {
    title: 'VS1',
    value: 'VS1',
  },
  {
    title: 'VS2',
    value: 'VS2',
  },
  {
    title: 'SI1',
    value: 'SI1',
  },
  {
    title: 'SI2',
    value: 'SI2',
  },
  {
    title: 'SI3',
    value: 'SI3',
  },
  {
    title: 'All',
    value: null,
  },
];

export const CERTIFICATE = [
  {
    title: 'IGI',
    value: 'IGI',
  },
  {
    title: 'GIA',
    value: 'GIA',
  },
  {
    title: 'AGS',
    value: 'AGS',
  },
  {
    title: 'All',
    value: null,
  },
];

export const POLISH = [
  {
    title: 'Excellent',
    value: 'Excellent',
  },
  {
    title: 'Very Good',
    value: 'Very Good',
  },
  {
    title: 'Good',
    value: 'Good',
  },
  {
    title: 'Fair',
    value: 'Fair',
  },
  {
    title: 'Poor',
    value: 'Poor',
  },
  {
    title: 'All',
    value: null,
  },
];

export const SYMMETRY = [
  {
    title: 'Excellent',
    value: 'Excellent',
  },
  {
    title: 'Very Good',
    value: 'Very Good',
  },
  {
    title: 'Good',
    value: 'Good',
  },
  {
    title: 'Fair',
    value: 'Fair',
  },
  {
    title: 'Poor',
    value: 'Poor',
  },
  {
    title: 'All',
    value: null,
  },
];
