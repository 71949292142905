import React from 'react';
import Svg from '../../Svg';

interface IСushionTableIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function СushionTableIcon(
  props: IСushionTableIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M142.1 56.43H56.51l-30.1 25.23v4.21l62.85 56.43h20.08l62.86-56.43v-4.21l-30.1-25.23zM26.41 85.86H172.2M172.2 81.66H26.41"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m99.3 56.43-28.19 9-14.16 16.23-8.41-12.62-14.68 6.37M99.3 56.43l28.19 9 14.17 16.23 8.41-12.62 14.67 6.37"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m39.69 81.66 16.82-25.23L99.3 81.66M32.98 91.77l49.42 38.58-41.09-44.48H57.8l31.46 56.43M164.74 91.77l-49.42 38.58 41.09-44.48h-16.49l-31.46 56.43"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.34 85.87 99.3 129.81 57.8 85.87"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.3 142.3V81.66l42.8-25.23 16.82 25.23"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.76 85.46v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.76 80.81v-36.3"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.16 3.16"
      />
      <path
        d="M25.76 42.93v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.26 41.43h144.5"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.25 85.46v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.25 80.81v-36.3"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.16 3.16"
      />
      <path
        d="M173.25 42.93v-1.5M55.71 56.43v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.71 51.96V29.67"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.97 2.97"
      />
      <path
        d="M55.71 28.19v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.6 26.69h84.94"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.42 56.43v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.42 51.96V29.67"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.97 2.97"
      />
      <path
        d="M142.42 28.19v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
