import * as React from 'react';
import { PaymentStep as DefaultComponent } from './PaymentStep';
import paymentFormsStore from './paymentForms.store';
import CartStore from 'root/store/cart';

interface IPaymentStep {
  cart: CartStore;
}

export const PaymentStep = (props: IPaymentStep): JSX.Element => (
  <DefaultComponent {...props} store={paymentFormsStore} />
);
