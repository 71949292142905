import React, { useEffect, useMemo } from 'react';
import { Redirect, Switch, useLocation } from 'react-router';
import { configure } from 'mobx';
import { observer } from 'mobx-react';
import { UserStoreImpl } from 'root/store/account/user.store';
import routes, { RouteProps } from './root/routes';
import PrivateRoute from './root/PrivateRoute';
import Loader from 'components/common/Loaders/Loader';
import { Header } from 'components/app/Header';
import Footer from 'components/app/Footer';
import HintForProductSet from 'components/HintForProductSet';

import './App.scss';

configure({
  enforceActions: 'always',
});

interface AppProps {
  children?: React.ReactNode;
  user: UserStoreImpl;
}

interface IComponentType {
  children?: React.ReactNode;
  location: { pathname: string };
  key: string;
}

export const App = observer((props: AppProps) => {
  const { user } = props;
  const isAuth = !!user && user.isAuth;
  const { pathname } = useLocation();

  const showFooter = useMemo(() => pathname !== '/', [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return user && user.isLoaded ? (
    <>
      <Header />

      <HintForProductSet />

      <Switch>
        {routes.map((route: RouteProps) => (
          <PrivateRoute
            route={route}
            isAuth={isAuth}
            key={route.id}
            exact={!!route.main}
            path={route.path}
            render={(p: { location: { pathname: string } }) => {
              const Component: React.ComponentType<IComponentType> =
                route.component;

              if (route.routes && route.routes.length) {
                const childrenRoute = route.routes.find((r) => {
                  return p.location.pathname === r.path;
                });
                if (childrenRoute) {
                  const ChildrenComponent = childrenRoute.component;
                  return (
                    <Component key={route.id} {...p}>
                      <ChildrenComponent />
                    </Component>
                  );
                }
              }
              return <Component key={route.id} {...p} />;
            }}
          />
        ))}
        <Redirect from="/hint" to={isAuth ? '/account/favorite' : '/'} />
        <Redirect to="/404" />
      </Switch>
      {showFooter && <Footer />}
    </>
  ) : (
    <div className="app-loader">
      <Loader />
    </div>
  );
});
