import React from 'react';
import Svg from '../Svg';

interface IShoppingIcon {
  children?: React.ReactNode;
  className: string;
}

export default function ShoppingIcon(props: IShoppingIcon): JSX.Element {
  return (
    <Svg width="19" height="21" viewBox="0 0 19 21" fill="none" {...props}>
      <path
        d="M14 5.5c0-2.734-2.266-5-5-5-2.773 0-5 2.266-5 5H.25v11.875c0 1.758 1.367 3.125 3.125 3.125h11.25a3.11 3.11 0 0 0 3.125-3.125V5.5H14ZM9 2.375A3.134 3.134 0 0 1 12.125 5.5h-6.25A3.11 3.11 0 0 1 9 2.375Zm6.875 15c0 .703-.586 1.25-1.25 1.25H3.375c-.703 0-1.25-.547-1.25-1.25v-10H4v1.563c0 .546.39.937.938.937.507 0 .937-.39.937-.938V7.375h6.25v1.563c0 .546.39.937.938.937.507 0 .937-.39.937-.938V7.375h1.875v10Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
