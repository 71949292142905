import HeartMeasLengthIcon from './HeartMeasLengthIcon';
import HeartMeasWidthIcon from './HeartMeasWidthIcon';
import HeartMeasDepthIcon from './HeartMeasDepthIcon';
import HeartDepthIcon from './HeartDepthIcon';
import HeartTableIcon from './HeartTableIcon';

export default {
  heart_measLength: HeartMeasLengthIcon,
  heart_measWidth: HeartMeasWidthIcon,
  heart_measDepth: HeartMeasDepthIcon,
  heart_depth: HeartDepthIcon,
  heart_table: HeartTableIcon,
};
