import { Link } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../../../../root/routes/constants';
import React, { useEffect } from 'react';
import { observable } from 'mobx';
import { PromoOfferPopup, offerPopupStore } from '../../OfferPopup';
import { observer } from 'mobx-react';

const MainSlider = (): JSX.Element => {
  const visibleOfferPopup = observable.box(false);

  useEffect(() => {
    void offerPopupStore.fetchGiftPromoCodeData();
  }, []);

  return (
    <div className="fadeIn">
      {' '}
      <div className="img-block">
        {/* <ImageItem
                        id="startImage"
                        src={getImg('mainPage', imagesPaths, bgImgPath)}
                        onLoad={handleShowImage}
                      /> */}
      </div>
      {/* {startImageLoaded && (
                      <InfoPoint onClick={() => showInfo('ring-info')} />
                    )} */}
      <div className="main-title">
        <div className="main-title-title">Design the Ring of Your Dreams!</div>

        <div className="content-main_btn">
          <Link
            to={{
              pathname: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
            }}
            className="btn btn_lg2 btn_bordered-dark-gray"
          >
            {/* btn_brown*/}
            Start with a Setting
          </Link>
          <Link
            to={{
              pathname: ROUTER_PATHS.DIAMONDS_CATALOG_PATH,
            }}
            className="btn btn_lg2 btn_bordered-dark-gray"
          >
            Start with a Diamond
          </Link>
        </div>
      </div>
      {offerPopupStore.isGiftPromoCodeAvailable && (
        <div className="main-title-offer fadeIn">
          <div className="main-title-title">
            Get {offerPopupStore.giftPromoCodeDiscount} off your first order!
          </div>

          <div className="content-main_btn">
            <div
              className="btn btn_lg2 btn_brown"
              onClick={() => visibleOfferPopup.set(true)}
            >
              Get offer
            </div>
          </div>
        </div>
      )}
      <PromoOfferPopup visiblePopup={visibleOfferPopup}></PromoOfferPopup>
      {/* <InfoBlock
                      productPhoto={productPhoto}
                      productCategory={productCategory}
                      randomProduct={randomProduct}
                      closeInfo={closeInfo}
                    /> */}
    </div>
  );
};

export default observer(MainSlider);
