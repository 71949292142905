import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { getIsMobileVersion } from 'lib/utils';
import LightLoader from 'components/common/Loaders/LightLoader';
import {
  CatalogPagination,
  CatalogsSortHeader,
} from 'components/app/Product/CatalogPage/common';
import { TilesCatalogPage } from 'components/app/Product/CatalogPage/TilesCatalogPage';
import { TableBody } from '../TableBody';
import { TableHead } from '../TableHead';
import { IObservableArray } from 'mobx';
import { CompareImpl } from 'root/store/compare';
import PaginateStore from 'root/store/paginate';
import OrderStore from 'root/store/order';
import { TJewelryCatalog } from '../../types';
import { SORT_BY_VALUES } from '../../constants/constants';

export interface TableProps {
  data: IObservableArray<TJewelryCatalog>;
  compare: CompareImpl;
  order: OrderStore;
  pagination: PaginateStore;
  handlePagination: (page: number) => void;
  handleOrder: (property: string, direction: string | null) => void;
  handleLimit: (limit: number) => void;
  displaySpinner: boolean;
}

export const Table = observer((props: TableProps) => {
  const {
    data,
    order,
    pagination,
    handlePagination,
    handleOrder,
    handleLimit,
    displaySpinner,
  } = props;

  const [catalogAsTiles, changeCatalogAsTiles] = useState(true);

  return (
    <>
      <CatalogsSortHeader
        pagination={pagination}
        handleLimit={handleLimit}
        changeCatalogAsTiles={changeCatalogAsTiles}
        handleOrder={handleOrder}
        isTilesCatalog={catalogAsTiles}
        order={order}
        sortValues={SORT_BY_VALUES}
      />

      <div className="grid-area-table">
        {displaySpinner ? (
          <>
            <div className="table-wrap block-scrollbar">
              <div className="parent">
                <LightLoader />
              </div>
            </div>
          </>
        ) : catalogAsTiles ? (
          <>
            <TilesCatalogPage products={data} />

            {!!data?.length && (
              <CatalogPagination
                pagination={pagination}
                handlePagination={handlePagination}
              />
            )}
          </>
        ) : (
          <>
            <div className="table-wrap block-scrollbar">
              <table
                className={getIsMobileVersion() ? 'table_mobile' : 'table_pc'}
              >
                <TableHead order={order} handleOrder={handleOrder} />
                <TableBody data={data} />
              </table>
            </div>

            {!!data?.length && (
              <CatalogPagination
                pagination={pagination}
                handlePagination={handlePagination}
              />
            )}
          </>
        )}
      </div>
    </>
  );
});
