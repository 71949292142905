import React from 'react';
import Svg from '../../Svg';

interface IRadiantDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function RadiantDepthIcon(
  props: IRadiantDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M98.83 57.7h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.29 57.7h75.4"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.96 2.96"
      />
      <path
        d="M180.17 57.7h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181.07 57.7v81.91"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.59 139.61 76.3 123.55l-13.36-12.19-27.28-38.9v-3.28l16.25-10.26h47.68l-37.52 5.61H43.02l20.84-5.61"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m135.32 58.92 20.84 5.61h-19.05l-37.52-5.61h47.68l16.26 10.26v3.28l-27.29 38.9-13.35 12.19-23.3 16.06v-32.8M35.66 69.18h127.87M35.66 72.47h127.87M62.07 64.53h75.04M78.15 124.83 56.76 92.69l-8.83-2.73"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m57.7 94.09 41.89 34.88-19.71-29.71-18.77-12.57-4.35 6M121.03 124.83l21.4-32.14 8.82-2.73M141.49 94.09l-41.9 34.88 19.72-29.71 18.76-12.57 4.36 6"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m79.88 99.26 19.71 7.55 19.72-7.55M61.11 86.69l-4.35-14.23v-3.28l3.68-4.65M138.07 86.69l4.36-14.23v-3.28l-3.68-4.65"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.83 139.61h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.29 139.61h75.4"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.96 2.96"
      />
      <path
        d="M180.17 139.61h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
