import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { RingDetailImpl } from 'root/store/rings/one.store';
import { StoneDetailImpl } from 'root/store/stones/one.store';
import { getPathnameByCategories } from 'lib/utils';
import { LevelPropductForSet } from 'pages/ShoppingCart/constants';
import StopCreatingASetPopup from 'components/common/ModalPopup/popups/StopCreatingASetPopup';
import Icon from 'components/common/Icon';
import HintForJewelry from './components/HintForJewelry';
import HintForDiamond from './components/HintForDiamond';
import { HintForProductSetImpl } from './HintForProductSet.types';

import './HintForProductSet.scss';
import * as qs from 'qs';
import hintForProductSetStore from './HintForProductSet.store';

interface IProps {
  onRemoveSet: () => void;
  hintStore: HintForProductSetImpl;
}

const HintForProductSet = (props: IProps): JSX.Element | null => {
  const {
    hintStore: { product, customizations, level },
    onRemoveSet,
  } = props;
  const history = useHistory();

  if (!product) return null;

  const visiblePopup = observable.box(false);

  const handleChange = () => {
    const productPath = getPathnameByCategories(product.categories);
    product && history.push(`${productPath}/${product.id}`, { edit: true });
  };

  const handleClose = () => visiblePopup.set(true);

  return (
    <>
      <div className="hint-product-set">
        <div className="hint-product-set__inner">
          {level === LevelPropductForSet.Parent ? (
            <HintForJewelry
              product={product as RingDetailImpl}
              customizations={customizations}
              handleChange={handleChange}
            />
          ) : (
            <HintForDiamond
              product={product as StoneDetailImpl}
              handleChange={handleChange}
            />
          )}

          <button
            type="button"
            className="hint-product-set__change"
            onClick={handleChange}
          >
            <Icon id="change" />
          </button>
        </div>

        <Icon
          id="close"
          className="hint-product-set__close"
          onClick={handleClose}
        />
      </div>

      <StopCreatingASetPopup
        visiblePopup={visiblePopup}
        productParams={product}
        removeSet={onRemoveSet}
      />
    </>
  );
};

export default observer(HintForProductSet);
