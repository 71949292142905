export enum DIAMOND_SHAPES {
  D_ROUND = 'Round',
  D_PRINCESS = 'Princess',
  D_CUSHION_MODIFIED = 'Cushion Modified',
  D_RADIANT = 'Radiant',
  D_ASSCHER = 'Asscher',
  D_EMERALD = 'Emerald',
  D_OVAL = 'Oval',
  D_PEAR = 'Pear',
  D_MARQUISE = 'Marquise',
}

export enum DIAMOND_TYPES {
  DIAMONDS = 'DIAMONDS',
  DIAMONDS_LAB_CREATED = 'DIAMONDS_LAB_CREATED',
}

export const AVAILABLE_SHAPE_IMAGES = [
  'asscher',
  'cushion',
  'cushion_modified',
  'emerald',
  'heart',
  'marquise',
  'oval',
  'pear',
  'princess',
  'radiant',
  'round',
];
