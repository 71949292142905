import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, IObservableValue } from 'mobx';
import { Link } from 'react-router-dom';
import { joinClasses } from 'lib/utils';
import * as cartService from 'service/api/cart';
import { CartRoutes, REMOVE_CHILD } from 'pages/ShoppingCart/constants';
import CartListItem from 'pages/ShoppingCart/components/CartListItem';
import { LoaderSpinner } from 'components/common/Loaders';
import ModalWindow from 'components/common/ModalPopup';
import PortalControl from 'components/common/PortalControl';
import { PreviewProps } from './PreviewTypes';

import './Preview.scss';

@observer
class Preview extends Component<PreviewProps, unknown> {
  loading: IObservableValue<boolean> = observable.box(false);
  visible: IObservableValue<boolean> = observable.box(false);

  componentDidMount(): void {
    const { store } = this.props;
    store.showPreview(false);
  }

  showPreview = (): void => {
    const { store } = this.props;
    const preview = document.querySelector('.preview');

    if (store.isPreviewShown) {
      preview && preview.classList.add('show');

      setTimeout(() => {
        preview &&
          preview.classList.contains('show') &&
          preview.classList.remove('show');
        store.showPreview(false);
      }, 2300);
    }
  };

  handleRemoveItem =
    (guid: string, removeChild: number) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      this.props.cart.remove(guid, removeChild);
    };

  handlePlaceOrder = async (
    event: React.MouseEvent<HTMLElement>,
  ): Promise<void> => {
    event.stopPropagation();
    const { history } = this.props;

    this.loading.set(true);
    try {
      await cartService.placeOrder();
      this.loading.set(false);
      // if (storage.get('accessToken')) {
      // await cart.autofill();
      // }

      history.push(CartRoutes.Customer);
    } catch (e) {
      this.loading.set(false);
      this.visible.set(true);
      console.error(e); // TODO: handle errors with react error component!
      // window.location.reload();
    }
  };

  render(): JSX.Element {
    const { cart, history } = this.props;
    return (
      <div
        className={joinClasses([
          'preview',
          !history.location.pathname.includes(CartRoutes.Cart) &&
            this.showPreview(),
        ])}
      >
        <div className="preview-list block-scrollbar">
          {cart.items.length
            ? cart.items.map((item) => {
                return (
                  <CartListItem
                    key={item.guid}
                    cartItem={item}
                    handleRemoveItem={this.handleRemoveItem(
                      item.guid,
                      REMOVE_CHILD,
                    )}
                    isPreview
                  />
                );
              })
            : null}
        </div>

        <div className="preview-footer">
          <p className="preview-footer-total">
            Total Order
            <span className="preview-footer-total_span">{cart.total}</span>
          </p>

          <div className="preview-footer-btns-wrap">
            <Link
              to="/cart"
              title="Shopping cart"
              className="btn btn_p-0 btn_text-bold btn_bordered-default btn_fixed-height btn_stretch"
            >
              Go to Cart
            </Link>
            <a
              title="Cart checkout"
              className={`btn btn_p-0 btn_text-bold btn_bordered-default btn_fixed-height btn_stretch ${
                this.loading.get() ? 'disabled disabled-loading' : ''
              }`}
              onClick={this.handlePlaceOrder}
            >
              {this.loading.get() ? (
                <LoaderSpinner className="loader-spinner_mr-sm" />
              ) : (
                ''
              )}
              Checkout
            </a>
          </div>

          <PortalControl>
            <ModalWindow
              visible={this.visible.get()}
              closeModal={() => {
                this.visible.set(false);
              }}
            >
              <div className="modal-window-header">
                <h2 className="modal-window-header__title">
                  Something went wrong.
                </h2>
              </div>
              <div className="modal-window-body modal-window-scroll-area block-scrollbar">
                <div className="modal-window__text modal-window__text_center">
                  Try again later or contact customer service by phone{' '}
                  <span className="bold">+1-877-382-6275</span>
                </div>
                <div className="modal-window-btn-wrap">
                  <button className="btn btn_lg btn_brown">OK</button>
                </div>
              </div>
            </ModalWindow>
          </PortalControl>
        </div>
      </div>
    );
  }
}

export default Preview;
