import * as React from 'react';
import { observer } from 'mobx-react';

interface ISelectWrapProps {
  children?: React.ReactNode;
  className?: string;
}

@observer
class SelectWrap extends React.Component<ISelectWrapProps> {
  render(): JSX.Element {
    const { children, className } = this.props;

    return (
      <div
        className={`select-container ${
          className || ''
        } select-container_angle-down`}
      >
        {children}
      </div>
    );
  }
}

export default SelectWrap;
