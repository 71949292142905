import * as React from 'react';
import { observer } from 'mobx-react';
import Hint from './Hint';
import HintStore from './Hint.store';
import { HintInterfaceProps } from './Hint.types';

const store = new HintStore();

export default observer((props: HintInterfaceProps) => (
  <Hint {...props} store={store} />
));
