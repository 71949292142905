import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CartCheckout as DefaultComponent } from './CartCheckout';
import customerFormStore from './CustomerStep/customerForm.store';
import shippingFormStore from './ShippingStep/shipping.store';
import paymentFormsStore from './PaymentStep/paymentForms.store';
import store from 'root/store';

const { cart } = store;

export const CartCheckout = withRouter((props: RouteComponentProps) => (
  <DefaultComponent
    {...props}
    cart={cart}
    customerForm={customerFormStore.form}
    shippingForm={shippingFormStore.form}
    paymentForm={paymentFormsStore.form}
    billingForm={paymentFormsStore.billingForm}
    cardForms={paymentFormsStore.cardForms}
  />
));
