import React from 'react';
import { observer } from 'mobx-react';
import { joinClasses } from 'lib/utils';
import Icon from 'components/common/Icon';

import './ButtonImgType.scss';

interface IButtonImgTypeProps {
  iconId: string;
  isActive: boolean;
  handleClickOpen: () => void;
  handleClickclose: () => void;
}

const ButtonImgType = (props: IButtonImgTypeProps) => {
  const { iconId, isActive, handleClickOpen, handleClickclose } = props;

  return (
    <div
      className={joinClasses(['button-img-type', isActive && 'active'])}
      onClick={isActive ? handleClickclose : handleClickOpen}
    >
      <Icon
        id={isActive ? 'close' : iconId}
        className={joinClasses([isActive && 'button-img-type__icon'])}
      />

      {isActive && <p className="button-img-type__txt">Close</p>}
    </div>
  );
};

export default observer(ButtonImgType);
