import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import {
  DIAMOND_CATEGORIES,
  JEWELRY_CATEGORIES,
  ProductCategories,
} from 'root/ProductTypes';
import { ItemsBlockImpl } from 'service/api/apiTypes/catalogsApiTypes';
import { getCurrentCategory } from 'lib/utils';
import {
  ICurrentProductDetail,
  ProductDetailPageImpl,
} from 'pages/ProductDetailPage/ProductDetailPage.types';
import { INVALID_VALUES } from 'pages/ProductDetailPage/util/constants';
import Icon from 'components/common/Icon';

export interface ProductFeaturesProps {
  product: ICurrentProductDetail;
  productStore: ProductDetailPageImpl;
}

const DIAMOND_CATEGORY_TITLE: { [key: string]: string } = {
  [ProductCategories.DIAMONDS]: 'Natural Diamonds',
  [ProductCategories.DIAMONDS_LAB_CREATED]: 'Lab-Created Diamonds',
};

const ProductFeatures = (props: ProductFeaturesProps) => {
  const {
    product,
    productStore: { mainBlock },
  } = props;
  const currentCategory = getCurrentCategory(product.categories);
  const isWeddingRingsCategory = computed((): boolean =>
    currentCategory.some(
      (category) => category === ProductCategories.WEDDING_RINGS,
    ),
  ).get();
  const isDiamondCategory = computed((): boolean =>
    currentCategory.some((category) =>
      DIAMOND_CATEGORIES.includes(category as ProductCategories),
    ),
  ).get();
  const categoryToShow = computed(
    (): string =>
      JEWELRY_CATEGORIES.find((category) =>
        currentCategory.includes(category),
      ) || '',
  ).get();

  return (
    <>
      <div className="product-detail-page__product-info">
        <p className="diamond-sku diamond-sku_large-bold uppercase decoration">
          SKU: {product.sku}
        </p>
      </div>

      <div className="product-detail-page__product-features">
        <ul className="product-detail-page__product-features-list">
          {isDiamondCategory && (
            <li>
              <span>Category</span>
              <span>
                {DIAMOND_CATEGORY_TITLE[product.categories[0]?.alias]}
              </span>
            </li>
          )}

          {mainBlock?.map((item: ItemsBlockImpl) => {
            if (INVALID_VALUES.includes(product[item.property as 'id'] as any))
              return null;
            if (item.property === 'gender' && !isWeddingRingsCategory)
              return null;

            return (
              <li key={item.order}>
                <span>{item.caption}</span>
                {Object.hasOwnProperty.call(product, item.property) && (
                  <span>
                    {item.property === 'shape' && product.shape && (
                      <Icon
                        id={product.shape.toLowerCase()}
                        className="icon_mr-xs"
                      />
                    )}
                    {item.property === 'category'
                      ? categoryToShow
                      : product[item.property as 'id']}
                  </span>
                )}
              </li>
            );
          })}

          {categoryToShow === ProductCategories.ENGAGEMENT_RINGS &&
            !!product.styles?.length && (
              <li>
                <span>Style</span>
                <span>{product.styles?.join(', ') || 'N/A'}</span>
              </li>
            )}

          {categoryToShow === ProductCategories.WEDDING_RINGS &&
            !!product.bands?.length && (
              <li>
                <span>Bands</span>
                <span>{product.bands?.join(', ') || 'N/A'}</span>
              </li>
            )}

          {product.settings && product.settings?.length > 0 && (
            <p className="attention-message attention-message_mt-5 attention-message_mb-10">
              *This setting requires a center diamond{' '}
            </p>
          )}
        </ul>
      </div>
    </>
  );
};

export default observer(ProductFeatures);
