import React from 'react';
import Svg from '../../Svg';

interface IOvalMeasDepthIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function OvalMeasDepthIcon(
  props: IOvalMeasDepthIconProps,
): JSX.Element {
  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" fill="none" {...props}>
      <path
        d="M101 140.5s57.72-32.87 75.07-53.55v-5.34l-22-20H48.28l-22 20V87C43.61 107.63 101 140.5 101 140.5zm0 0 .34-53.72"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m129.02 86.45-28.03 54.05 51.38-54.05-22.87 36.51 36.22-36.51-4.34 14.19M72.97 86.45l28.02 54.05-51.38-54.05 22.78 36.35-36.12-36.35 4.67 14.01"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4 86.61c1.69 0 2.47-1.25 4.86-1.16 1.77.06 3 1 5 1s4.21-1.33 6.57-1.44c2.7-.13 4.1 1.55 6.77 1.44 3.53-.16 9.27-1.65 12.69-1.65 4.24 0 7.23 1.77 10.67 1.65 3.44-.12 9.67-1 14.34-1s12 1.25 14 1.22M26.4 81.45c1.69 0 2.47 1.25 4.86 1.17 1.77-.07 3-1 5-1s4.21 1.33 6.57 1.44c2.7.13 4.1-1.55 6.77-1.44 3.53.16 9.27 1.65 12.69 1.65 4.24 0 7.23-1.77 10.67-1.65 3.44.12 9.67 1 14.34 1s11-1.23 14-1.23 9 1.23 13.68 1.23 10.91-.88 14.34-1c3.43-.12 6.43 1.65 10.67 1.65 3.42 0 9.16-1.49 12.69-1.65 2.66-.11 4.07 1.57 6.77 1.44 2.36-.11 4.57-1.44 6.57-1.44s3.24.94 5 1c2.39.08 3.16-1.17 4.86-1.17"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.16 86.61c-1.7 0-2.47-1.25-4.86-1.16-1.77.06-3 1-5 1s-4.21-1.33-6.57-1.44c-2.7-.13-4.11 1.55-6.77 1.44-3.53-.16-9.27-1.65-12.69-1.65-4.24 0-7.23 1.77-10.67 1.65-3.44-.12-9.67-1-14.34-1s-12 1.25-14 1.22M101.33 61.59l-20.17 5.99-12.53-5.99-25.1 4.31-7.26 15.71"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.83 61.59 121 67.58l12.52-5.99 25.1 4.31 7.27 15.71M43.53 65.9l7.37 15.71 30.26-14.03-7.1 13.81"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m158.62 65.9-7.37 15.71L121 67.58l7.1 13.81M81.16 67.58l20.29 13.65L121 67.58"
        stroke="#777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.97 59.64h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.43 59.64h75.59"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.96 2.96"
      />
      <path
        d="M182.5 59.64h1.5M100.97 140.5h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.43 140.5h75.59"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.96 2.96"
      />
      <path
        d="M182.5 140.5h1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184 59.64v80.86"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.47 86.78v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.47 82.31V48.2"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.97 2.97"
      />
      <path
        d="M25.47 46.72v-1.5M177.02 86.78v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.02 82.31V48.2"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.97 2.97"
      />
      <path
        d="M177.02 46.72v-1.5"
        stroke="#B1A07E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.47 45.22h151.55"
        stroke="#B1A07E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
