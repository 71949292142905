import * as React from 'react';
import { observer } from 'mobx-react';
import { TableParamsImpl } from '../../store';
import { CartItemPendantImpl } from 'root/store/cart/item.store';

export interface PendantTemplateProps {
  product: CartItemPendantImpl;
  property: TableParamsImpl;
}

export const PendantTemplate = observer((props: PendantTemplateProps) => {
  const { product, property } = props;
  return (
    <>
      {(() => {
        switch (property.key) {
          case 'gender':
            return product.gender;
          case 'metal':
            return product.metal;
          case 'pendantType':
            return product.pendantType;
          case 'primaryStoneShape':
            return product.primaryStoneShape;
          case 'primaryStoneType':
            return product.primaryStoneType;
          case 'primaryStoneSize':
            return product.primaryStoneSize;

          case 'initialNumber':
            return product.initialNumber;
          case 'birthstoneMonth':
            return product.birthstoneMonth;
          case 'state':
            return product.state;
          default:
            return null;
        }
      })()}
    </>
  );
});
