import React, { memo } from 'react';
import { getFormattedTimeString } from 'lib/utils';

export function ResendInfo({ time }: { time: number }): JSX.Element {
  return (
    <>
      {`Please wait ${getFormattedTimeString(time)} sec to resend e-mail again`}
    </>
  );
}

export default memo(ResendInfo);
