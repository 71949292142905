import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ContactsStore from './store';
import { Contacts as DefaultComponent } from './Contacts';

const contactsStore = new ContactsStore();

export const ContactsPage = withRouter((props: RouteComponentProps) => (
  <DefaultComponent {...props} store={contactsStore} />
));
