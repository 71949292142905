import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getImg } from 'lib/utils';
import ImgLoader from 'components/common/Loaders/ImgLoader';
import Icon from 'components/common/Icon';
import { TabsLinks } from '../TabLinks';
import { CLARITY_IMAGES, TabLinks } from '../../constants';

import '../../EducationPage.scss';

const Clarity = (): JSX.Element => {
  return (
    <>
      <div className="education-page-img__container grid-area-cover-image-container">
        <ImgLoader
          folder="education-imgs/"
          images={CLARITY_IMAGES}
          name="education-img-cover-clarity"
          format="webp"
        >
          <TransitionGroup component={null}>
            <CSSTransition
              appear
              timeout={400}
              classNames="switch-transparency"
            >
              <div
                className="grid-area-cover-image clarity"
                style={{
                  backgroundImage: `url(${getImg(
                    'education-imgs/',
                    CLARITY_IMAGES,
                    'education-img-cover-clarity',
                  )})`,
                }}
              ></div>
            </CSSTransition>
          </TransitionGroup>
        </ImgLoader>
      </div>

      <div className="grid-area-tabs block-scrollbar block-scrollbar_margin-right">
        <div className="tabs_container">
          <TabsLinks activeTab={TabLinks.CLARITY} />

          <div className="tabs-panels block-scrollbar">
            <div className="panel">
              <div className="panel-header">
                <Icon id="gem_light" />
                <h1 className="panel-header__title">DIAMOND CLARITY</h1>
              </div>
              <div className="panel-text-block">
                <p>
                  Clarity grades how many imperfections are present in a
                  diamond. It’s natural for diamonds to have tiny marks and
                  scratches inside. The rarest stones are called Flawless-and
                  have no imperfections.
                </p>
                <p>
                  Diamonds with VS2 and higher are eye-clean (undetected by the
                  naked eye). Use our HD images to view a diamond up close - but
                  remember they’re magnified 30x!
                </p>
              </div>
              <div className="panel-imgs-wrap">
                <div className="three-imgs-in-block colorless">
                  <div className="figures-wrap">
                    <figure className="figure">
                      <Icon
                        id="clarity_01"
                        className="figure__img figure__img-big"
                      />
                      <figcaption>FL, IF</figcaption>
                    </figure>
                    <figure className="figure">
                      <Icon
                        id="clarity_02"
                        className="figure__img figure__img-big"
                      />
                      <figcaption>VVS1, VVS2</figcaption>
                    </figure>
                    <figure className="figure">
                      <Icon
                        id="clarity_03"
                        className="figure__img figure__img-big"
                      />
                      <figcaption>VS1</figcaption>
                    </figure>
                  </div>
                </div>
                <div className="three-imgs-in-block colorless">
                  <div className="figures-wrap">
                    <figure className="figure">
                      <Icon
                        id="clarity_04"
                        className="figure__img figure__img-big"
                      />
                      <figcaption>VS2</figcaption>
                    </figure>
                    <figure className="figure">
                      <Icon
                        id="clarity_05"
                        className="figure__img figure__img-big"
                      />
                      <figcaption>SI1</figcaption>
                    </figure>
                    <figure className="figure">
                      <Icon
                        id="clarity_06"
                        className="figure__img figure__img-big"
                      />
                      <figcaption>SI2, SI3</figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clarity;
