import axios from './index';
import { UserParamsImpl } from 'root/store/account/user.store';
import { SettingParamsImpl } from 'pages/AccountPage/components/ProfileSettings/ProfileSettings.types';
import {
  ProfileParamsResponseImpl,
  UpdateProfilePictureResponseImpl,
} from './apiTypes/accountApiTypes';
import { ProductDetailImpl } from 'root/ProductTypes';
import { AxiosPromise } from 'axios';

/**
 * Get Current Profile
 * @returns {AxiosPromise}
 */
export const getCurrentProfile = (): Promise<UserParamsImpl> =>
  axios.get('/profiles/current');

/**
 * Get Profile
 * @param {number} id
 * @returns {Promise<ProfileParamsResponseImpl>}
 */
export const getProfileParams = (
  id: number,
): Promise<ProfileParamsResponseImpl> => axios.get(`/profiles/${id}`);

/**
 * Update Profile
 * @param {number} id
 * @param {ProfileParamsResponseImpl} data
 * @returns {AxiosPromise}
 */
export const updateProfile = (
  id: number,
  data: Partial<ProfileParamsResponseImpl>,
): Promise<ProfileParamsResponseImpl> => axios.post(`/profiles/${id}`, data);

/**
 * Update Profile Picture
 * @param {number} id
 * @param {any} picture
 * @returns
 */
export const updateProfilePicture = (
  id: number,
  file: FormData,
): Promise<UpdateProfilePictureResponseImpl> => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  return axios.post(`/profiles/upload/${id}`, file, config);
};

/**
 * Get Settings
 * @returns {Promise<SettingParamsImpl>}
 */
export const getSettings = (): Promise<SettingParamsImpl> =>
  axios.get('/profile/settings');

/**
 * Update Settings Checkboxes
 * @param {SettingParamsImpl} data
 * @returns {AxiosPromise}
 */
export const updateSettings = (
  data: SettingParamsImpl,
): Promise<SettingParamsImpl> => axios.post('/profile/settings', data);

/**
 * Logout
 * @returns Promise<Void>
 */
export const logout = async (): Promise<void> => axios.get('/auth/logout');

/**
 * Get Favorites
 * @returns {Promise<ProductDetailImpl[]>}
 */
export const getFavorites = (): Promise<ProductDetailImpl[]> => {
  return axios.get('/profile/favorites');
};

/**
 * Update Favorites
 * @param {number} id
 */
export const updateFavorites = (id: number): AxiosPromise => {
  return axios.put('/profile/favorites', { ids: [id] });
};

/**
 * Remove Favorite item
 * @param {number} id
 */
export const removeFavoriteItem = (id: number): AxiosPromise => {
  return axios.delete(`/profile/favorites/${id}`);
};
