import * as React from 'react';
import { Contact as DefaultComponent } from './Contact';
import { ContactStore } from './ContactStore';
import { ContactProps } from './ContactTypes';

const contactServiceStore = new ContactStore();

export const Contact = (props: ContactProps): JSX.Element => (
  <DefaultComponent {...props} store={contactServiceStore} />
);
