import React from 'react';
import { observer } from 'mobx-react';
import { RingDetailImpl } from 'root/store/rings/one.store';

interface IProps {
  ring?: RingDetailImpl;
  customSize: null | string | number;
}

const InfoRing = ({ ring, customSize }: IProps) => {
  if (!ring) return null;

  return (
    <>
      {ring.metal} | {ring.gender} | Weight {ring.weight} | Size{' '}
      {customSize || ring.size}
    </>
  );
};

export default observer(InfoRing);
