import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';
import { observable, runInAction } from 'mobx';
import { ROUTER_PATHS } from 'root/routes/constants';
import hintForProductSetStore from 'components/HintForProductSet/HintForProductSet.store';
import qs from 'qs';

const visiblePopup = observable.box(false);

let unblockRoute: UnregisterCallback | false;

const useBlockRoutingIfThereIsAnOpenSet = (routerPath: ROUTER_PATHS) => {
  const history = useHistory();

  unblockRoute && unblockRoute();
  unblockRoute =
    !!hintForProductSetStore?.level &&
    history.block((event: any) => {
      if (
        !event?.state?.edit &&
        history.location.pathname.includes(routerPath) &&
        !event.pathname.includes(routerPath) &&
        hintForProductSetStore?.level
      ) {
        runInAction(() => {
          visiblePopup.set(true);
        });

        return false;
      }
    });

  useEffect(() => {
    return () => {
      unblockRoute && unblockRoute();
      runInAction(() => {
        visiblePopup.set(false);
      });
    };
  }, []);

  const releaseBlock =
    unblockRoute &&
    (() => {
      if (unblockRoute) {
        unblockRoute();
        history.replace(history.location.pathname);
      }
    });

  return {
    visiblePopup,
    releaseBlock,
  };
};

export default useBlockRoutingIfThereIsAnOpenSet;
