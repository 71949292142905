import React, { useMemo, useState } from 'react';
import { getCreateDate, joinClasses } from 'lib/utils';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { CurrencyImpl } from 'root/store/currency';
import { OrderItemDetailImpl, OrderItemImpl } from 'root/store/orders/types';
import { getProductImage } from 'utils';
import Icon from 'components/common/Icon';

interface IProps extends RouteComponentProps<{ type: string }> {
  order: OrderItemImpl;
  currency?: CurrencyImpl;
}

const TableRow = observer(({ order, currency }: IProps) => {
  const [isActive, setIsActive] = useState(false);

  const priceFormat = (price: number | string) =>
    currency && currency.format(price);

  const orderItems = useMemo(() => {
    const items: OrderItemDetailImpl[] = [];
    order.items.forEach((item) => {
      items.push(item);
      if (item.children && item.children.length) {
        items.push(...item.children);
      }
    });
    return items;
  }, [order.items]);

  return (
    <>
      <div
        className={joinClasses([
          'table-orders__table-row',
          isActive && 'table-orders__table-row table-orders__table-row_active',
        ])}
      >
        <div
          className="table-orders__table-row-item table-orders__table-row-item_order-number"
          onClick={() => setIsActive((prev) => !prev)}
        >
          <span>{order.id}</span>
        </div>
        <div className="table-orders__table-row-item table-orders__table-row-item_quantity">
          {orderItems.length} item
        </div>
        <div className="table-orders__table-row-item table-orders__table-row-item_status">
          <span>{order.status}</span>
        </div>
        <div className="table-orders__table-row-item table-orders__table-row-item_create-date">
          {getCreateDate(order.createDate)}
        </div>
        <div className="table-orders__table-row-item table-orders__table-row-item_shipping">
          {priceFormat(order.shipping)}
        </div>
        <div className="table-orders__table-row-item table-orders__table-row-item_total">
          <span>{priceFormat(order.total)}</span>
        </div>

        <div className="table-orders__table-row-actions">
          {/*
          <div className="table-orders__table-row-action">
            <img src={require(`img/svgicons/notes-light.svg`).default} />
          </div>
          */}

          <div
            className="table-orders__table-row-action"
            onClick={() => {
              window.location.hash = String(order.id);
            }}
          >
            <Icon id="eye" />
          </div>
          {/*
                    {match.params.type !== ORDER_HISTORY_TYPE && (
            <div className="table-orders__table-row-action">
              <img src={require(`img/svgicons/history-light.svg`).default} />
            </div>
          )}
          */}
        </div>
      </div>
      {isActive &&
        orderItems.map((product) => (
          <div
            key={product.id}
            className="table-orders__table-row table-orders__table-row_details"
          >
            <img
              src={getProductImage(product)}
              className="table-orders__table-row-image"
            />
            <div className="table-orders__table-row-details">
              <div className="table-orders__details-title">{product.sku}</div>
              <div className="table-orders__details-info">
                {product.description &&
                  Object.keys(product.description).map((key, index) => (
                    <span key={index}>{product.description[key]}</span>
                  ))}
              </div>
            </div>
            <div className="table-orders__table-row-item">
              <div className="table-orders__table-row-item-title">Quantity</div>
              <span>{product.quantity} item</span>
            </div>
            <div className="table-orders__table-row-item table-orders__table-row-item_total">
              <div className="table-orders__table-row-item-title">Net cost</div>
              <span>{product.price}</span>
            </div>
            {/* <div
            className="table-orders__table-row-item table-orders__table-row-item_tax"
          >
            <div className='table-orders__table-row-item-title'>TAX</div>
            <span>$40</span>
          </div> */}
            <div className="table-orders__table-row-item table-orders__table-row-item_total">
              <div className="table-orders__table-row-item-title">Summary</div>
              <span>{product.price}</span>
            </div>
          </div>
        ))}
    </>
  );
});

export default inject(({ orders }) => ({
  currency: orders.currency,
}))(withRouter(TableRow));
