import React, { useState } from 'react';
import { gsap } from 'gsap';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { getImg } from 'lib/utils';
import { ROUTER_PATHS } from 'root/routes/constants';
import ImageItem from 'components/common/ImageItem';
import { imagesPaths } from '../../constants';
import useElementViewed from '../../helpers/useElementViewed';

import './Rings.scss';

interface IProps {
  setAnimatedIndex: (index: number) => void;
  animatedIndex: Record<number, boolean>;
}

export function Rings({
  setAnimatedIndex,
  animatedIndex,
}: IProps): JSX.Element {
  const [animated, setAnimated] = useState(false);

  const handleAnimate = () => {
    if (!animated && !animatedIndex[3]) {
      setAnimated(true);
      gsap.fromTo('.rightRing', { x: -600 }, { x: 0, duration: 2 });
      gsap.fromTo('.leftRing', { x: 600 }, { x: 0, duration: 2 });
      gsap.fromTo(
        '#ringsTitle',
        { scale: 2, y: -100 },
        { scale: 1, y: 0, duration: 2 }
      );
    }
    setAnimatedIndex(2);
  };

  const { elementRef } = useElementViewed({
    callback: handleAnimate,
    isActiveListener: true,
    threshold: 0.2,
  });

  return (
    <div className="main-slide  main-slide_rings" ref={elementRef}>
      <div className="content-main_block" id="rings">
        <div className="content-main_block__title" id="ringsTitle">
          Wedding and anniversary
        </div>

        <div className="content-main_block__description">
          A true symbol of never-ending love. <br /> Choose from a fine
          selection of wedding and eternity bands to compliment your
          one-of-a-kind engagement ring.
        </div>

        <div className="content-main_block__rings">
          <ImageItem
            className="rightRing"
            src={getImg('mainPage', imagesPaths, '/ring1')}
          />
          <ImageItem
            className="leftRing"
            src={getImg('mainPage', imagesPaths, '/ring2')}
          />
        </div>

        <div className="content-main_btn">
          <Link
            to={{
              pathname: ROUTER_PATHS.RINGS_WEDDING_CATALOG_PATH,
            }}
            className="btn btn_lg2 btn_brown"
          >
            Browse
          </Link>
        </div>
      </div>
    </div>
  );
}

export default observer(Rings);
