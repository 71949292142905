import CuttingA from './CuttingA';
import CuttingB from './CuttingB';
import CuttingC from './CuttingC';
import CuttingD from './CuttingD';

export default {
  cuttingA: CuttingA,
  cuttingB: CuttingB,
  cuttingC: CuttingC,
  cuttingD: CuttingD,
};
