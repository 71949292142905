import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TilesCatalogPage as DefaultComponent } from './TilesCatalogPage';
import store from 'root/store';

const { compare } = store;

interface TilesCatalogPageI extends RouteComponentProps {
  products: any;
}

export const TilesCatalogPage = withRouter((props: TilesCatalogPageI) => (
  <DefaultComponent {...props} compare={compare} />
));
