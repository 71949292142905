import React from 'react';
import { withRouter } from 'react-router-dom';
import rootStore from 'root/store';
import jewelryCatalogStore from '../../store';
import FilterExtra from '../../store/jewerlyFiltersExtra.store';
import { Catalog as DefaultComponent } from './Catalog';

const { compare } = rootStore;
const filtersExtra = new FilterExtra();

export const Catalog = withRouter((props: any) => (
  <DefaultComponent
    {...props}
    catalog={jewelryCatalogStore}
    compare={compare}
    filtersExtra={filtersExtra}
  />
));
