import React from 'react';
import { observer } from 'mobx-react';
import { ErrorImpl } from '../../pages/LoginContainer/LoginContainer.types';
import Icon from 'components/common/Icon';

import './ErrorAlert.scss';

interface ErrorAlertImpl {
  error: ErrorImpl;
  onClose: () => void;
}

function ErrorAlert({ error, onClose }: ErrorAlertImpl): JSX.Element | null {
  if (!error.title && !error.status && !error.message) return null;

  return (
    <div
      className="alert alert-danger alert-dismissible auth-alert show"
      role="alert"
    >
      <p className="auth-alert__title">
        Error {error.status}. {error.title}
      </p>
      {typeof error.message === 'string' ? (
        <p className="auth-alert__text">{error.message}</p>
      ) : (
        error.message.map((mess) => (
          <p className="auth-alert__text" key={mess}>
            {mess}
          </p>
        ))
      )}
      <button className="btn close" onClick={onClose}>
        <Icon id="close" className="icon_close-cross_red" />
      </button>
    </div>
  );
}

export default observer(ErrorAlert);
