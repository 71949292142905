import React from 'react';
import Svg from '../../Svg';

interface IProps {
  children?: React.ReactNode;
  className: string;
}

export default function Carat025(props: IProps): JSX.Element {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4 1.185C8.218 1.185 1.585 7.818 1.585 16c0 8.182 6.633 14.815 14.815 14.815 8.182 0 14.815-6.633 14.815-14.815 0-8.182-6.633-14.815-14.815-14.815ZM.4 16c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.655.29c.167.142.188.391.047.558L12.7 5.577l3.857 1.665a.395.395 0 0 1-.014.73l-10.6 4.123-4.687 4.199a.395.395 0 1 1-.527-.588l4.74-4.247a.395.395 0 0 1 .121-.074l9.77-3.8-3.462-1.494a.395.395 0 0 1-.145-.618L16.099.337a.395.395 0 0 1 .556-.046Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.544 1.305c.207-.071.431.04.502.246l1.382 4.05c.028.079.028.166.003.246l-1.284 4.05a.396.396 0 0 1-.007.02L8.77 16.14a.395.395 0 0 1-.704.07l-2.603-4.146-3.446-1.53a.395.395 0 0 1 .321-.723l3.556 1.58a.395.395 0 0 1 .174.151l2.245 3.576 2.084-5.471 1.242-3.915-1.341-3.926a.395.395 0 0 1 .246-.501Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.472 4.642a.395.395 0 0 1 .31-.096l6.321.79a.395.395 0 1 1-.098.784l-5.877-.734v6.367a.395.395 0 1 1-.79 0V4.938c0-.113.049-.221.134-.296ZM16.141.294a.395.395 0 0 1 .558.04l4.444 5.136a.395.395 0 0 1-.145.622L17.451 7.59l9.759 3.795a.393.393 0 0 1 .12.074l4.741 4.247a.395.395 0 0 1-.527.588l-4.688-4.199-10.6-4.122a.395.395 0 0 1-.01-.732l3.944-1.665L16.101.85a.395.395 0 0 1 .04-.557Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.248 1.302a.395.395 0 0 1 .257.496l-1.248 3.934 1.146 3.917 2.17 5.465 2.155-3.566a.395.395 0 0 1 .182-.158l3.654-1.58a.395.395 0 1 1 .314.725l-3.539 1.53-2.502 4.139a.395.395 0 0 1-.706-.059l-2.469-6.222a.423.423 0 0 1-.012-.035l-1.185-4.05a.396.396 0 0 1 .003-.23l1.284-4.049a.395.395 0 0 1 .496-.257Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.429 4.644a.395.395 0 0 1 .131.3l-.098 6.815a.395.395 0 0 1-.79-.012l.092-6.36-5.87.733a.395.395 0 0 1-.099-.784l6.321-.79a.395.395 0 0 1 .313.098ZM.698 15.737a.395.395 0 0 1 .558-.03l4.688 4.198 10.6 4.122a.395.395 0 0 1 .013.731L12.7 26.424l4.002 4.729a.395.395 0 1 1-.604.51l-4.345-5.136a.395.395 0 0 1 .145-.618l3.461-1.494-9.769-3.8a.397.397 0 0 1-.12-.073L.73 16.295a.395.395 0 0 1-.032-.558Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.439 15.607c.149.015.277.113.33.253l2.37 6.222a.383.383 0 0 1 .008.021l1.284 4.05a.395.395 0 0 1-.003.247l-1.382 4.05a.395.395 0 0 1-.748-.256l1.34-3.926-1.24-3.915-2.085-5.471-2.245 3.575a.395.395 0 0 1-.174.151l-3.556 1.58a.395.395 0 0 1-.32-.721l3.445-1.532 2.603-4.144a.395.395 0 0 1 .373-.184Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.733 19.852c.219 0 .396.176.396.395v6.367l5.876-.735a.395.395 0 1 1 .098.784l-6.32.79a.395.395 0 0 1-.445-.392v-6.814c0-.219.177-.395.395-.395ZM32.102 15.737a.395.395 0 0 1-.031.558l-4.74 4.247a.395.395 0 0 1-.121.074L17.45 24.41l3.547 1.497a.395.395 0 0 1 .145.623L16.7 31.667a.395.395 0 0 1-.598-.518l4.089-4.724-3.944-1.666a.395.395 0 0 1 .01-.732l10.6-4.122 4.688-4.199a.395.395 0 0 1 .558.03Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.466 15.607c.15-.013.293.06.37.19l2.503 4.138 3.539 1.53a.395.395 0 1 1-.314.725l-3.654-1.58a.395.395 0 0 1-.181-.158l-2.157-3.566-2.168 5.465-1.147 3.916 1.248 3.935a.395.395 0 1 1-.753.239l-1.284-4.05a.395.395 0 0 1-.003-.23l1.185-4.05a.42.42 0 0 1 .012-.034l2.47-6.222a.395.395 0 0 1 .334-.248Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.06 19.852a.395.395 0 0 1 .402.389l.098 6.815a.395.395 0 0 1-.444.397l-6.32-.79a.395.395 0 1 1 .097-.784l5.87.734-.091-6.36a.395.395 0 0 1 .389-.401Z"
        fill="#000"
      />
    </Svg>
  );
}
