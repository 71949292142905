import React, { Component, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import Icon from 'components/common/Icon';
import { joinClasses } from 'lib/utils';

import './CustomSelect.scss';

type TValues = {
  [key: string]:
    | string
    | number
    | undefined
    | null
    | JSX.Element
    | { [key: string]: string | number | null }
    | Array<{
        [key: string]:
          | string
          | number
          | Array<{ [key: string]: string | number }>;
      }>;
};

export interface ICustomSelect {
  id?: string;
  name: string;
  form?: string;
  label: string;
  values: TValues[];
  defaultValue: string | number | undefined | { type: string; value: number };
  onChange?: (name: any, value: any) => void;
  disabled?: boolean;
  emptyValue?: string;
  oneLine?: boolean;
  customKey?: string;
  activeClassName?: string;
}

@observer
class CustomSelect extends Component<PropsWithChildren<ICustomSelect>> {
  isOpen = observable.box(false);
  value = observable.box('');

  componentDidMount(): void {
    const { values, defaultValue } = this.props;
    const currentOption =
      values &&
      values.find(
        (value) =>
          value.id === defaultValue ||
          value.value === defaultValue ||
          value.name === defaultValue,
      );

    runInAction(() => {
      this.value.set((currentOption?.name as string) || '');
    });
  }

  componentDidUpdate(prevProps: ICustomSelect): void {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      const { values, defaultValue } = this.props;
      const currentOption =
        values &&
        values.find(
          (value) => value.id === defaultValue || value.value === defaultValue,
        );
      runInAction(() => {
        this.value.set((currentOption?.name as string) || '');
      });
    }
  }

  handleClick = (): void => {
    const { disabled = false } = this.props;
    if (disabled) return;

    runInAction(() => {
      this.isOpen.set(!this.isOpen.get());
    });
  };

  handleBlur = (): void => {
    runInAction(() => {
      this.isOpen.set(false);
    });
  };

  handleOption = (option: TValues): void => {
    const { name, onChange } = this.props;

    runInAction(() => {
      this.value.set(option.name as string);
    });
    onChange && onChange(name, option);
  };

  render(): JSX.Element {
    const {
      id,
      form,
      values,
      label,
      emptyValue,
      disabled,
      oneLine,
      customKey,
      activeClassName = 'options-list__item_active',
    } = this.props;

    return (
      <div
        className={joinClasses([
          'custom-select',
          disabled && 'custom-select_disabled',
          this.isOpen.get() && 'open',
          !!this.value.get() && 'active',
        ])}
        id={id}
        data-form={form}
        tabIndex={0}
        onClick={this.handleClick}
        onBlur={this.handleBlur}
      >
        <div className="select">
          <div
            className={joinClasses([
              'select__inner',
              'select__inner_with-angle',
              customKey && 'custom-select__centered-item',
            ])}
          >
            {customKey && (
              <>
                {
                  (
                    (values.find((value) => value.name === this.value.get()) ||
                      []) as TValues
                  )[customKey]
                }
              </>
            )}
            {!oneLine && this.value.get()}
            <Icon id="arrow_top" className="select__arrow" />
          </div>

          <div
            className={joinClasses([
              'label-text',
              !!this.value.get() && !oneLine && 'label-text_open',
            ])}
          >
            {label}
          </div>

          {oneLine && this.value.get() && (
            <div className="label-text label-text_empty-value">
              {this.value.get()}
            </div>
          )}

          {!this.value.get() && emptyValue && (
            <div className="label-text label-text_empty-value">
              {emptyValue}
            </div>
          )}
        </div>

        <div className="options">
          <ul className="options-list">
            {values.map((option, i) => (
              <li
                className={joinClasses([
                  'options-list__item',
                  customKey && 'custom-select__centered-item',
                  option.name === this.value.get() && activeClassName,
                ])}
                key={`${option.id || option.name}_${i}`}
                data-value={option.id || option.name}
                onClick={() => this.handleOption(option)}
              >
                <>
                  {customKey && option[customKey]}
                  {option.name}
                </>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default CustomSelect;
