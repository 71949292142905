import * as React from 'react';
import { observer } from 'mobx-react';
import {
  FormControl,
  FormGroup,
  InputFormControl,
  TextAreaFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { getPathnameByCategories, joinClasses } from 'lib/utils';
import { sendHint } from 'service/api/app';
import { HintProduct } from './components/HintProduct/HintProduct';
import FieldErrors from 'components/FieldErrors';
import Icon from 'components/common/Icon';
import FormFriendContact from './components/FormFriendContact';
import type {
  ClientFormImpl,
  FriendFormImpl,
  HintInterfaceProps,
} from './Hint.types';
import HintStore from './Hint.store';

import './Hint.scss';

interface IState {
  isValidClientForm: boolean;
  isValidFriendForm: boolean;
}
interface IProps extends HintInterfaceProps {
  store: HintStore;
}

class Hint extends React.Component<IProps, IState> {
  state = {
    isValidClientForm: false,
    isValidFriendForm: false,
  };

  componentDidMount() {
    this.setInitialStateForms();
    this.setIsValidClientForms();
    this.setIsValidFriendForms();
  }

  componentDidUpdate() {
    this.setIsValidClientForms();
    this.setIsValidFriendForms();
  }

  setInitialStateForms() {
    const { resetForm, changeStatus } = this.props.store;
    resetForm();
    changeStatus('');
  }

  checkIsValidForm = (
    form: FormGroup<ClientFormImpl> | FormGroup<FriendFormImpl>,
  ) => Object.values(form.controls).every((f) => !f.invalid);

  setIsValidClientForms() {
    const { clientForm } = this.props.store;
    const newClientFormState = this.checkIsValidForm(clientForm);
    if (this.state.isValidClientForm === newClientFormState) return;
    this.setState({ isValidClientForm: newClientFormState });
  }

  setIsValidFriendForms() {
    const { friendsForm } = this.props.store;
    const isValidFields = friendsForm.reduce(
      (acc: boolean[], form: FormGroup<FriendFormImpl>) => {
        return [...acc, this.checkIsValidForm(form)];
      },
      [],
    );
    const newFriendFormState = isValidFields.every((item) => !!item);
    if (this.state.isValidFriendForm === newFriendFormState) return;
    this.setState({ isValidFriendForm: newFriendFormState });
  }

  handleSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    let formValid = true;
    const { goOutPage, product } = this.props;

    const {
      clientForm,
      friendsForm,
      disableForm,
      changeStatus,
      formValues,
      setErrorsFromServer,
    } = this.props.store;

    const { controls: clientFormControls } = clientForm;

    for (const field in clientFormControls) {
      if (clientFormControls[field].invalid) {
        clientFormControls[field].setTouched(true);
        formValid = false;
      }
    }

    friendsForm.forEach((friend: FormGroup<FriendFormImpl>) => {
      const { controls: friendFormControls } = friend;
      for (const field in friendFormControls) {
        if (friendFormControls[field].invalid) {
          friendFormControls[field].setTouched(true);
          formValid = false;
        }
      }
    });

    if (formValid) {
      if (!product) return;

      disableForm(true);
      changeStatus('PENDING');

      sendHint({
        ...formValues(product),
        pathname: getPathnameByCategories(product?.categories),
      })
        .then(() => {
          changeStatus('SUCCESS');
          setTimeout(() => {
            disableForm(false);
            goOutPage && goOutPage();
          }, 2000);
        })
        .catch(({ message }: { message: string[] }) => {
          changeStatus('FAILED');
          setErrorsFromServer(message);
          disableForm(false);
          setTimeout(() => {
            changeStatus('');
            setErrorsFromServer([]);
          }, 5000);
        });
    }
  };

  highLightInput = (formControl: FormControl<string>): boolean => {
    const { invalid, touched, value } = formControl;
    return invalid && (touched || !!value);
  };

  render(): JSX.Element {
    const { product, isProductLoading } = this.props;
    const {
      disable,
      clientForm,
      friendsForm,
      addFriend,
      deleteFriend,
      status,
      errorsFromServer,
      valueOfTypeOfContacts,
      handleChangeTypeOfContacts,
    } = this.props.store;
    const { isValidClientForm, isValidFriendForm } = this.state;
    const isDisableSubmitButton =
      disable.get() ||
      isProductLoading ||
      !isValidClientForm ||
      !isValidFriendForm;

    const { controls: clientFormControls } = clientForm;
    const {
      name: clientName,
      email: clientEmail,
      message: clientMessage,
    } = clientFormControls;

    return (
      <div className="hint">
        <div className="form-block">
          <form className="form" action="" noValidate>
            <h2 className="form-title">Send A Hint</h2>
            <div className="form-comment">
              Please enter recipient information
            </div>

            {friendsForm.map(
              (friend: FormGroup<FriendFormImpl>, index: number) => {
                const { controls: friendFormControls } = friend;
                const { name, email } = friendFormControls;

                return (
                  <div key={`${name.additionalData.blockId}`}>
                    <label className="form-label">
                      <input
                        type="text"
                        name="name"
                        className={joinClasses([
                          'autofill-bg-beige',
                          name.value && 'shrink',
                          this.highLightInput(name) ? 'invalid' : 'valid',
                        ])}
                        data-form="customer"
                        disabled={disable.get()}
                        value={name.value}
                        {...InputFormControl.bindActions(name)}
                      />
                      <div className="label-text">Friends Name *</div>
                      <FieldErrors control={name} />
                    </label>

                    <FormFriendContact
                      checkedItem={valueOfTypeOfContacts.get()}
                      onChange={handleChangeTypeOfContacts}
                    />

                    <label className="form-label">
                      <input
                        type="email"
                        name="email"
                        className={joinClasses([
                          'autofill-bg-beige',
                          email.value && 'shrink',
                          this.highLightInput(email) ? 'invalid' : 'valid',
                        ])}
                        data-form="customer"
                        disabled={disable.get()}
                        value={email.value}
                        {...InputFormControl.bindActions(email)}
                      />
                      <div className="label-text">Friends E-mail *</div>
                      <FieldErrors control={email} />
                    </label>

                    <div
                      className="add-friend"
                      onClick={() => {
                        friendsForm.length === index + 1
                          ? addFriend()
                          : deleteFriend(index);
                      }}
                    >
                      <Icon
                        id={
                          index + 1 === friendsForm.length
                            ? 'plus_circle'
                            : 'minus_circle'
                        }
                      />
                      &nbsp;
                      {index + 1 === friendsForm.length ? 'Add' : 'Delete'}{' '}
                      Friend
                    </div>
                  </div>
                );
              },
            )}

            <div className="form-textarea form-textarea_mt-15">
              <label className="label-text">Message</label>
              <textarea
                name="message"
                className={joinClasses([
                  'block-scrollbar',
                  clientMessage.value && 'shrink',
                  this.highLightInput(clientMessage) ? 'invalid' : 'valid',
                ])}
                disabled={disable.get()}
                value={clientMessage.value}
                {...TextAreaFormControl.bindActions(clientMessage)}
                placeholder={'You can leave a message '}
              ></textarea>
              <FieldErrors control={clientMessage} />
            </div>

            <div className="form-comment">Please enter your information</div>

            <label className="form-label">
              <input
                type="text"
                name="name"
                className={joinClasses([
                  'autofill-bg-beige',
                  clientName.value && 'shrink',
                  this.highLightInput(clientName) ? 'invalid' : 'valid',
                ])}
                data-form="customer"
                disabled={disable.get()}
                value={clientName.value}
                {...InputFormControl.bindActions(clientName)}
              />
              <div className="label-text">Your Name *</div>
              <FieldErrors control={clientName} />
            </label>

            <label className="form-label">
              <input
                type="text"
                name="email"
                className={joinClasses([
                  'autofill-bg-beige',
                  clientEmail.value && 'shrink',
                  this.highLightInput(clientEmail) ? 'invalid' : 'valid',
                ])}
                data-form="customer"
                disabled={disable.get()}
                value={clientEmail.value}
                {...InputFormControl.bindActions(clientEmail)}
              />
              <div className="label-text">Your E-mail*</div>
              <FieldErrors control={clientEmail} />
            </label>
          </form>
        </div>
        <div className="product-info">
          <HintProduct product={product} isProductLoading={isProductLoading} />

          {status.get() === 'FAILED' ? (
            <div className="info-message attention-message_warning">
              {errorsFromServer.get().length
                ? errorsFromServer
                    .get()
                    .map((error: string) => <div key={error}>{error}</div>)
                : 'Sorry. Some problem is happened. Please try again.'}
            </div>
          ) : null}

          {status.get() === 'SUCCESS' ? (
            <div className="info-message attention-message">
              Thank you! Your hint has been successfully sent. We will contact
              with your friend very soon!
            </div>
          ) : null}

          {!status.get() ? (
            <div className="hint-controls">
              <button
                className="btn btn_lg btn_bordered-default"
                disabled={disable.get()}
                onClick={this.props.goOutPage}
              >
                Cancel
              </button>
              <button
                className="btn btn_lg btn_brown"
                disabled={isDisableSubmitButton}
                onClick={this.handleSubmit}
              >
                Send
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default observer(Hint);
