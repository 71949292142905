import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ImageProps } from 'react-image-webp';
import { getImg, getNoImage, joinClasses } from 'lib/utils';
import LightLoader from '../Loaders/LightLoader';
import Icon from '../Icon';

import './ImageItem.scss';

interface ImageItemProps extends ImageProps {
  isShowDetails?: boolean;
  photo?: string;
  imageFolder?: string;
  images?: string[];
  currentImage?: string;
  needStyle?: boolean;
  modifiers?: string;
  alt?: string;
  className?: string;
  onLoad?: () => void;
}

export function ImageItem({
  photo,
  src,
  isShowDetails,
  imageFolder,
  images,
  currentImage,
  needStyle,
  modifiers,
  alt = '',
  className,
  onLoad,
  ...rest
}: ImageItemProps): JSX.Element {
  const srcStringFromProps = photo || src;
  const [isLoad, setLoad] = useState(true);
  const [isNoImg, setIsNoImg] = useState(false);
  const [srcState, setSrcState] = useState<string | undefined>(
    srcStringFromProps,
  );

  const handleError = () => {
    setLoad(false);
    setIsNoImg(true);
  };

  const handleLoad = () => {
    setLoad(false);
    setIsNoImg(false);

    onLoad && onLoad();
  };

  const handleSetNoImg = () => {
    isLoad && setIsNoImg(true);
  };

  const getResultSrc = useCallback(
    () =>
      srcStringFromProps ||
      (imageFolder && images?.length && currentImage
        ? getImg(imageFolder, images, currentImage)
        : getNoImage()),
    [srcStringFromProps, imageFolder, images, currentImage],
  );

  useEffect(() => {
    setSrcState(getResultSrc());
  }, [getResultSrc]);

  useEffect(() => {
    const timeoutId = setTimeout(handleSetNoImg, 20000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={joinClasses(['img-container', className])}>
      {isLoad &&
        (isNoImg ? <Icon id="no_img" {...rest} /> : <LightLoader {...rest} />)}

      <img
        className={joinClasses([
          needStyle && `product-image${isShowDetails ? '_notDisplay' : ''}`,
          isLoad && 'img-container_no-img',
          modifiers,
        ])}
        onLoad={handleLoad}
        onError={handleError}
        src={srcState}
        alt={alt}
        {...rest}
      />
    </div>
  );
}

export default observer(ImageItem);
