import * as React from 'react';
import { FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  InputFormControl,
  TextAreaFormControl,
} from '@quantumart/mobx-form-validation-kit';
import { contactUs } from 'service/api/app';
import Icon from 'components/common/Icon';
import { ContactsStoreImpl } from './types';

import './Contacts.scss';

interface ContactsProps extends RouteComponentProps {
  store: ContactsStoreImpl;
}

@observer
export class Contacts extends React.Component<ContactsProps> {
  handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const { store } = this.props;

    await store.form.wait();
    store.form.setTouched(true);

    if (store.form.valid) {
      store.disableForm(true);
      store.changeStatus('PENDING');
      try {
        await contactUs({ ...store.formValues });
        store.changeStatus('SUCCESS');
        store.disableForm(true);
        store.resetForm();
        store.form.setTouched(false);
      } catch {
        store.changeStatus('FAILED');
        store.disableForm(false);
        setTimeout(() => store.changeStatus(''), 5000);
      }
    }
  };

  render(): JSX.Element {
    const { store, location } = this.props;
    const { controls } = store.form;

    return (
      <div className="contacts-page grid">
        <h1 className="grid-area-page-title page-title">contacts</h1>

        <div className="grid-area-info-bar">
          <div className="info-block">
            <div className="circle-around-icon">
              <Icon id="email_light" />
            </div>
            <p className="info-block__text">customerservice@venori.com</p>
          </div>
          <div className="info-block">
            <div className="circle-around-icon">
              <Icon id="phone_light" />
            </div>
            <p className="info-block__text">+1-877-382-6275</p>
          </div>
          <div className="info-block">
            <div className="circle-around-icon">
              <Icon id="map_light" />
            </div>
            <p className="info-block__text">
              231 Central Ave, Suite 2, White Plains, NY, 10606.
            </p>
          </div>
          <div className="info-block">
            <p className="info-block__text-block">
              We look forward to serving you, and responding to any questions or
              comments you have. Speak to a diamond expert or customer service
              representative by calling +1-877-382-6275. Send us an email at
              customerservice@venori.com, and a representative or consultant
              will respond within one business day, usually within four business
              hours.
            </p>
          </div>
        </div>

        <div className="grid-area-form">
          <div className="form-block">
            <form
              className="form"
              id="customer"
              name="customer"
              action=""
              onSubmit={this.handleSubmit}
              noValidate
            >
              <h2 className="form-title">SEND A MESSAGE</h2>

              <label className="form-label">
                <input
                  type="text"
                  name="firstName"
                  className={`${
                    controls.firstName.invalid && controls.firstName.touched
                      ? 'invalid'
                      : 'valid'
                  } ${controls.firstName.value ? 'shrink' : ''}`}
                  data-form="customer"
                  disabled={store.disable}
                  value={controls.firstName.value}
                  {...InputFormControl.bindActions(controls.firstName)}
                />
                <div className="label-text">First Name *</div>
                {controls.firstName.touched && controls.firstName.errors && (
                  <div className="form-label__error">
                    {controls.firstName.errors.map((error) => (
                      <span key={error.message}>{error.message}</span>
                    ))}
                  </div>
                )}
              </label>

              <label className="form-label">
                <input
                  type="text"
                  name="lastName"
                  className={`${
                    controls.lastName.invalid && controls.lastName.touched
                      ? 'invalid'
                      : 'valid'
                  } ${controls.lastName.value ? 'shrink' : ''}`}
                  data-form="customer"
                  disabled={store.disable}
                  value={controls.lastName.value}
                  {...InputFormControl.bindActions(controls.lastName)}
                />
                <div className="label-text">Last Name *</div>
                {controls.lastName.touched && controls.lastName.errors && (
                  <div className="form-label__error">
                    {controls.lastName.errors.map((error) => (
                      <span key={error.message}>{error.message}</span>
                    ))}
                  </div>
                )}
              </label>

              <label className="form-label">
                <input
                  type="email"
                  name="email"
                  className={`${
                    controls.email.invalid && controls.email.touched
                      ? 'invalid'
                      : 'valid'
                  } ${controls.email.value ? 'shrink' : ''}`}
                  data-form="customer"
                  disabled={store.disable}
                  value={controls.email.value}
                  {...InputFormControl.bindActions(controls.email)}
                />
                <div className="label-text">E-mail *</div>
                {controls.email.touched && controls.email.errors && (
                  <div className="form-label__error">
                    {controls.email.errors.map((error) => (
                      <span key={error.message}>{error.message}</span>
                    ))}
                  </div>
                )}
              </label>

              <label className="form-label">
                <input
                  type="text"
                  name="phone"
                  className={`${
                    controls.phone.invalid && controls.phone.touched
                      ? 'invalid'
                      : 'valid'
                  } ${controls.phone.value ? 'shrink' : ''}`}
                  data-form="customer"
                  disabled={store.disable}
                  value={controls.phone.value}
                  {...InputFormControl.bindActions(controls.phone)}
                />
                <div className="label-text">Phone *</div>
                {controls.phone.touched && controls.phone.errors && (
                  <div className="form-label__error">
                    {controls.phone.errors.map((error) => (
                      <span key={error.message}>{error.message}</span>
                    ))}
                  </div>
                )}
              </label>

              <div className="form-textarea form-textarea_mt-30">
                <label className="label-text">Message</label>
                <textarea
                  name="message"
                  className="block-scrollbar"
                  disabled={store.disable}
                  value={controls.message.value}
                  {...TextAreaFormControl.bindActions(controls.message)}
                />
                {controls.message.touched && controls.message.errors && (
                  <div className="form-label__error">
                    {controls.message.errors.map((error) => (
                      <span key={error.message}>{error.message}</span>
                    ))}
                  </div>
                )}
              </div>

              <p className="attention-message attention-message_bold attention-message_mt-20 attention-message_mb-10">
                All questions will be answered within two business days.
              </p>
              <p className="attention-message attention-message_mb-30">
                You can contact us by phone
                <span className="bold text-brown"> +1-877-382-6275</span>
              </p>

              {store.status === 'FAILED' ? (
                <div className="attention-message attention-message_mb-30">
                  Sorry. Some problem is happened. Please try again.
                </div>
              ) : null}

              {store.status === 'SUCCESS' ? (
                <div className="attention-message attention-message_mb-30">
                  Thank you! Your message has been successfully sent. We will
                  contact you very soon!
                </div>
              ) : null}

              {!store.status ? (
                <button
                  className="btn btn_lg btn_brown btn_stretch"
                  type="submit"
                  value="Submit"
                  disabled={store.disable}
                >
                  Send
                </button>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
