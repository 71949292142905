import React, { Component, FormEvent, SyntheticEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { action, observable, runInAction } from 'mobx';
import { IObservableValue } from 'mobx/dist/internal';
import { observer } from 'mobx-react';
import {
  FormControl,
  FormGroup,
  maxLengthValidator,
  minLengthValidator,
  patternValidator,
  requiredValidator,
  wrapperSequentialCheck,
  ValidationEventTypes,
  InputFormControl,
} from '@quantumart/mobx-form-validation-kit';
import rootStore from 'root/store';
import { joinClasses } from 'lib/utils';
import { signIn, recoveryPassword } from 'service/api/auth';
import PortalControl from 'components/common/PortalControl';
import ModalWindow from 'components/common/ModalPopup';
import ErrorAlert from 'components/ErrorAlert';
import Icon from 'components/common/Icon';
import InputForm from 'components/common/InputForm';
import { ErrorImpl } from '../../LoginContainer.types';
import {
  SignInForgotPassFormImpl,
  SignInFormImpl,
  SignInPropsImpl,
} from './SignIn.types';

const {
  account: { user: userStore },
} = rootStore;

@observer
class SignIn extends Component<SignInPropsImpl> {
  visibleForgotPass = observable.box(false);
  visibleSendPass = observable.box(false);
  disable = observable.box(false);
  status = observable.box(''); // SUCCESS, PENDING, FAILED
  inputPasswordType = observable.box('password');
  formStatus: IObservableValue<boolean> = observable.box(
    this.props.location.state ? this.props.location.state.formStatus : false,
  );

  forgotPasswordError = observable.box('');
  keepSignIn = observable.box(true);
  showActivatedMessage = observable.box(false);
  showError = observable.box(false);
  @observable error: ErrorImpl = {
    status: 0,
    title: '',
    message: '',
  };

  @observable form = new FormGroup<SignInFormImpl>({
    email: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Email is required'),
          patternValidator(
            /^(\s+)?[a-zA-Z0-9+._-]+@[a-zA-Z0-9-]+[.]{1}[a-zA-Z]{2,4}([.]{1}[a-zA-Z]{2,4})?(\s+)?$/,
            'Wrong Email format',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
    password: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Password is required'),
          minLengthValidator(6, 'Field minimum length is 6 chars'),
          maxLengthValidator(
            255,
            'Field maximum length is 255 chars ',
            ValidationEventTypes.Error,
          ),
          patternValidator(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,255}$/,
            'Password must contain one uppercase, one lowercase, one number and spec chars',
          ),
        ]),
      ],
    }),
  });

  @observable forgotPassForm = new FormGroup<SignInForgotPassFormImpl>({
    forgotPassEmail: new FormControl<string>('', {
      validators: [
        wrapperSequentialCheck([
          requiredValidator('Email is required'),
          patternValidator(
            /^(\s+)?[a-zA-Z0-9+._-]+@[a-zA-Z0-9-]+[.]{1}[a-zA-Z]{2,4}([.]{1}[a-zA-Z]{2,4})?(\s+)?$/,
            'Wrong Email format',
          ),
          maxLengthValidator(
            80,
            'Field maximum length is 80 chars ',
            ValidationEventTypes.Error,
          ),
          minLengthValidator(1),
        ]),
      ],
    }),
  });

  @action
  changeStatus(status: string): void {
    this.status.set(status);
  }

  @action
  disableForm(isDisable: boolean): void {
    this.disable.set(isDisable);
  }

  @action
  visibleForgotPasswordModal(visible: boolean): void {
    this.visibleForgotPass.set(visible);
  }

  @action
  visibleSendPasswordModal(visible: boolean): void {
    this.visibleSendPass.set(visible);
  }

  handleChangeInputPasswordType = (e: SyntheticEvent): void => {
    e.preventDefault();
    this.inputPasswordType.get() === 'password'
      ? this.inputPasswordType.set('text')
      : this.inputPasswordType.set('password');
  };

  handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const { email, password } = this.form.controls;
    const isValidForm = [email, password].every((input) => !input.invalid);

    if (isValidForm) {
      const data = {
        username: email.value,
        password: password.value,
      };

      this.disableForm(true);
      this.changeStatus('PENDING');

      try {
        const result: any = await signIn(data);

        localStorage.setItem('accessToken', `${result.accessToken}`);
        await userStore.fetchCurrentUser();
        userStore.handleUserAuth(true);
        this.disableForm(false);
        this.changeStatus('SUCCESS');
      } catch (err: unknown) {
        switch ((err as ErrorImpl).status) {
          case 403:
            this.showActivatedMessage.set(true);
            break;
          default:
            runInAction(() => {
              this.error = err as ErrorImpl;
              this.showError.set(true);
            });
        }

        this.disableForm(false);
        this.changeStatus('FAILED');
      }
    }
  };

  resetShowErrorAfterFormChanging = () => {
    this.showError.set(false);
    this.showActivatedMessage.set(false);
    this.forgotPasswordError.set('');
  };

  handleSubmitForgotPassword = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();
    const { forgotPassEmail } = this.forgotPassForm.controls;

    if (!forgotPassEmail.invalid) {
      try {
        await recoveryPassword({ email: forgotPassEmail.value });
        this.visibleForgotPasswordModal(false);
        this.visibleSendPasswordModal(true);
      } catch (error) {
        this.forgotPasswordError.set(
          'Account with this email isn’t registered',
        );
      }
    }
  };

  render(): JSX.Element {
    const { email, password } = this.form.controls;
    const { forgotPassEmail } = this.forgotPassForm.controls;

    return (
      <>
        {this.status.get() === 'SUCCESS' && (
          <Redirect to={'/account/orders/current'} />
        )}
        <div className="content">
          <div className="tabbed">
            <div className="tab-content opened">
              <form
                onChange={this.resetShowErrorAfterFormChanging}
                className="form"
                onSubmit={this.handleSubmit}
              >
                {this.showError.get() && (
                  <ErrorAlert
                    error={this.error}
                    onClose={() => this.showError.set(false)}
                  />
                )}
                <div className="form-title">
                  <p>Please sign in to your Account</p>
                </div>

                <InputForm
                  label="E-mail"
                  type="email"
                  name="email"
                  data={email}
                  className={joinClasses([
                    'autofill-bg-beige',
                    email.value && 'shrink',
                  ])}
                  showErrors={
                    (email.invalid && email.touched) ||
                    (email.invalid && !!email.value)
                  }
                  disabled={this.disable.get()}
                  {...InputFormControl.bindActions(email)}
                />

                <InputForm
                  label="Password"
                  type={this.inputPasswordType.get()}
                  name="password"
                  data={password}
                  iconId={
                    this.inputPasswordType.get() === 'password'
                      ? 'eye'
                      : 'eye_light'
                  }
                  className={joinClasses([
                    'autofill-bg-beige',
                    password.value && 'shrink',
                  ])}
                  showErrors={
                    (password.invalid && password.touched) ||
                    (password.invalid && !!password.value)
                  }
                  disabled={this.disable.get()}
                  onIconClick={this.handleChangeInputPasswordType}
                  {...InputFormControl.bindActions(password)}
                />

                {/* <label className="checkbox-container">
                  <input
                    id="signedIn"
                    type="checkbox"
                    checked={this.keepSignIn.get()}
                    onChange={() => {
                      this.keepSignIn.set(!this.keepSignIn.get());
                    }}
                  />
                  <span className="checkmark" />
                  Keep me signed in on this computer
                </label> */}

                <div
                  className="forgot-pass"
                  onClick={() => this.visibleForgotPasswordModal(true)}
                >
                  Forgot your password?
                </div>

                <div
                  className={joinClasses([
                    'activate',
                    this.showActivatedMessage.get() && 'show',
                  ])}
                >
                  <img
                    className="activate__logo"
                    src={require('img/error.png').default}
                    alt="error"
                  />
                  <span className="activate__content">
                    <p className="activate__content_text">
                      Your Account hasn’t been activated yet by some reasons.
                      Please proceed the link below to activate account.
                    </p>
                    <a className="activate__content_link" href="#">
                      http://www.venori.com/awesome-link-to-activate-your-best-acc…
                    </a>
                  </span>
                </div>

                <input
                  className="btn_brown submit"
                  type="submit"
                  value="Sign in"
                />
              </form>
            </div>
          </div>
        </div>

        <PortalControl>
          <ModalWindow visible={this.visibleForgotPass.get()}>
            <div
              className="btn btn_bordered-default btn_circle modal-window__btn-close"
              onClick={() => this.visibleForgotPasswordModal(false)}
            >
              <Icon id="close" />
            </div>
            <div className="modal-window-header">
              <h2 className="modal-window-header__title">
                Forgot your password?
              </h2>
            </div>
            <div className="modal-window-body">
              <p className="modal-window-body__text modal-window-body__text_center modal-window-body__text_my-10">
                Please enter your e-mail associated with your account and follow
                the instructions
              </p>
              <form className="form" onSubmit={this.handleSubmitForgotPassword}>
                <label className="form-label">
                  <input
                    className={joinClasses([
                      'autofill-bg-beige',
                      forgotPassEmail.value && 'shrink',
                      (forgotPassEmail.invalid && forgotPassEmail.touched) ||
                      (forgotPassEmail.invalid && forgotPassEmail.value)
                        ? 'invalid'
                        : 'valid',
                    ])}
                    type="email"
                    name="forgotPassEmail"
                    value={forgotPassEmail.value}
                    {...InputFormControl.bindActions(forgotPassEmail)}
                  />
                  <div className="label-text">E-mail</div>
                  {(forgotPassEmail.invalid && forgotPassEmail.touched) ||
                  (forgotPassEmail.invalid && forgotPassEmail.value)
                    ? forgotPassEmail.errors.map((error) => (
                        <div
                          className="form-label__error form-label__error_blocky"
                          key={error.key}
                        >
                          {error.message}
                        </div>
                      ))
                    : this.forgotPasswordError.get() && (
                        <div className="form-label__error form-label__error_blocky">
                          {this.forgotPasswordError.get()}
                        </div>
                      )}
                </label>
                <div className="modal-window-body-btn-wrap">
                  <button
                    className="send btn btn_lg btn_px-0 btn_brown btn_stretch"
                    type="submit"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-window-footer"></div>
          </ModalWindow>
        </PortalControl>

        <PortalControl>
          <ModalWindow visible={this.visibleSendPass.get()} modalSize="md">
            <div
              className="btn btn_bordered-default btn_circle modal-window__btn-close"
              onClick={() => this.visibleSendPasswordModal(false)}
            >
              <Icon id="close" />
            </div>
            <div className="modal-window-header">
              <h2 className="modal-window-header__title">
                New password was sent to your e&#8209;mail
              </h2>
            </div>
            <div className="modal-window-body">
              <p className="modal-window-body__text modal-window-body__text_center modal-window-body__text_medium modal-window-body__text_my-10">
                Please check it
              </p>
              <br />
              <br />
              <div className="modal-window-body-btn-wrap">
                <button
                  className="btn btn_lg btn_brown btn_font-montserrat"
                  type="button"
                  onClick={() => this.visibleSendPasswordModal(false)}
                  disabled={this.disable.get()}
                >
                  OK
                </button>
              </div>
            </div>
            <div className="modal-window-footer"></div>
          </ModalWindow>
        </PortalControl>
      </>
    );
  }
}

export default SignIn;
