import React from 'react';
import TableRow from './components/TableRow';
import { joinClasses } from 'lib/utils';
import { OrderItemImpl } from 'root/store/orders/types';

import './TableOrders.scss';
import { observer } from 'mobx-react';

interface IProps {
  orders: OrderItemImpl[];
}

export const cols = [
  { title: 'Order number', modifer: 'order-number' },
  { title: 'Quantity', modifer: 'quantity' },
  { title: 'Status', modifer: 'status' },
  { title: 'Date', modifer: 'create-date' },
  { title: 'Shipping', modifer: 'shipping' },
  { title: 'Total', modifer: 'total' },
];

const TableOrders = observer(({ orders }: IProps) => {
  return (
    <div className="table-orders">
      <div className="grid-area-table table-orders__table">
        <div className="table-orders__table-head">
          {cols.map(({ title, modifer }) => (
            <div
              key={title}
              className={joinClasses([
                'table-orders__table-head-title',
                `table-orders__table-head-title_${modifer}`,
              ])}
            >
              {title}
            </div>
          ))}
        </div>
        <div className="table-orders__table-body">
          {orders.map((order) => (
            <TableRow key={order.id} order={order} />
          ))}
        </div>
      </div>
    </div>
  );
});

export default TableOrders;
