import React from 'react';
import Svg from '../Svg';

interface SpellCheckIcom {
  children?: React.ReactNode;
}

export default function Pencil(props: SpellCheckIcom): JSX.Element {
  return (
    <Svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M12.6484 4.35156C13.0938 3.90625 13.0938 3.17969 12.6484 2.75781L11.2422 1.35156C10.8203 0.90625 10.0938 0.90625 9.64844 1.35156L8.57031 2.42969C8.47656 2.52344 8.47656 2.71094 8.57031 2.82812L11.1719 5.42969C11.2891 5.52344 11.4766 5.52344 11.5703 5.42969L12.6484 4.35156ZM7.65625 3.34375L1.49219 9.50781L1 12.3438C0.929688 12.7422 1.25781 13.0703 1.65625 13L4.49219 12.5078L10.6562 6.34375C10.7734 6.25 10.7734 6.0625 10.6562 5.94531L8.05469 3.34375C7.9375 3.25 7.75 3.25 7.65625 3.34375ZM3.0625 10.9375H4.1875V11.8047L2.66406 12.0625L1.9375 11.3359L2.19531 9.8125H3.0625V10.9375Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
