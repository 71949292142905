import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Hint from 'components/app/Hint';
import { HintPageProps } from './HintPage.types';

import './HintPage.scss';

export function HintPage({
  match,
  history,
  store,
}: HintPageProps): JSX.Element {
  const { productId } = match.params;

  useEffect(() => {
    store.loadHintProduct(productId);
  }, []);

  const handleGoOutPage = () => {
    history.goBack();
  };

  return (
    <>
      <div className="hintpage grid">
        <div className="grid-area-page-title page-title">Send a hint</div>
        <div className="grid-area-form">
          <Hint
            product={store.product}
            isProductLoading={store.isProductLoading}
            goOutPage={handleGoOutPage}
          />
        </div>
      </div>
    </>
  );
}

export default observer(HintPage);
