import React from 'react';
import Svg from '../Svg';

interface IHistoryIconProps {
  children?: React.ReactNode;
  className: string;
}

export default function HistoryIcon(props: IHistoryIconProps): JSX.Element {
  return (
    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M1.438 1.125a.57.57 0 0 0-.563.563v6.75c0 .328.234.562.563.562h6.75a.57.57 0 0 0 .562-.563V7.97c0-.282-.281-.563-.563-.563h-4.64C5.234 4.22 8.609 1.97 12.5 1.97c5.531 0 10.031 4.547 10.031 10.031 0 5.578-4.547 10.031-10.031 10.031-2.625 0-4.969-.937-6.75-2.531-.188-.234-.563-.188-.75 0l-.328.328a.594.594 0 0 0 0 .844 11.809 11.809 0 0 0 7.828 2.953c6.375 0 11.578-5.156 11.625-11.578C24.125 5.672 18.922.422 12.5.422 8.234.375 4.484 2.719 2.469 6.14V1.687c0-.28-.281-.562-.563-.562h-.468Zm15.046 14.813.235-.329c.187-.234.14-.609-.14-.75l-3.329-2.484V5.437c0-.28-.281-.562-.563-.562h-.374a.57.57 0 0 0-.563.563v7.734l3.938 2.86c.28.187.609.14.796-.095Z"
        fill="#928A8E"
      />
    </Svg>
  );
}
