import cutting from './cutting';
import color from './color';
import clarity from './clarity';
import carat from './carat';

export default {
  ...cutting,
  ...color,
  ...clarity,
  ...carat,
};
