import React from 'react';
import { FormControl } from '@quantumart/mobx-form-validation-kit';
import { joinClasses } from 'lib/utils';

interface FieldErrorsInterface {
  control: FormControl<string>;
  modifiers?: string;
}

export const FieldErrors = ({
  control,
  modifiers,
}: FieldErrorsInterface): JSX.Element | null => {
  const { invalid, touched, errors } = control;
  if (!errors.length || !invalid || !touched) return null;

  return (
    <>
      {control.errors.map((error, i) => {
        return (
          <div
            key={i}
            className={joinClasses(['form-label__error', modifiers])}
          >
            {error.message}
          </div>
        );
      })}
    </>
  );
};

export default FieldErrors;
