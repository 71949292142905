import * as React from 'react';
import { observer } from 'mobx-react';
import { FormGroup } from '@quantumart/mobx-form-validation-kit';
import { IFormShippingStep } from '../../CartCheckout/ShippingStep/shippingForm.types';

interface CustomerOrderBlockProps {
  shippingForm: FormGroup<IFormShippingStep>;
}

export const DiffRecipientOrderBlock = observer(
  ({ shippingForm }: CustomerOrderBlockProps) => {
    return shippingForm.controls.diffRecipient.value ? (
      <div className="order-block">
        <h3 className="order-block__title uppercase">recipient</h3>
        <table className="order-block__table">
          <tbody>
            <tr>
              <td className="label">First Name</td>
              <td className="value">{shippingForm.controls.firstName.value}</td>
            </tr>
            <tr>
              <td className="label">Last Name</td>
              <td className="value">{shippingForm.controls.lastName.value}</td>
            </tr>
            <tr>
              <td className="label">E-mail</td>
              <td className="value">{shippingForm.controls.email.value}</td>
            </tr>
            <tr>
              <td className="label">Phone</td>
              <td className="value">{shippingForm.controls.phone.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    ) : null;
  },
);
