import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { observer } from 'mobx-react';
import { TabsLinks } from '../TabLinks';
import { getImg } from 'lib/utils';
import ImgLoader from 'components/common/Loaders/ImgLoader';
import Icon from 'components/common/Icon';
import { CARAT_IMAGES, TabLinks } from '../../constants';

import '../../EducationPage.scss';

export const Carat = observer(() => {
  return (
    <>
      <div className="education-page-img__container grid-area-cover-image-container">
        <ImgLoader
          folder="education-imgs/"
          images={CARAT_IMAGES}
          name="education-img-cover-carat"
        >
          <TransitionGroup component={null}>
            <CSSTransition
              appear
              timeout={400}
              classNames="switch-transparency"
            >
              <div
                className="grid-area-cover-image carat"
                style={{
                  backgroundImage: `url(${getImg(
                    'education-imgs/',
                    CARAT_IMAGES,
                    'education-img-cover-carat',
                  )})`,
                }}
              ></div>
            </CSSTransition>
          </TransitionGroup>
        </ImgLoader>
      </div>

      <div className="grid-area-tabs block-scrollbar block-scrollbar_margin-right">
        <div className="tabs_container">
          <TabsLinks activeTab={TabLinks.CARAT} />
          <div className="tabs-panels block-scrollbar">
            <div className="panel">
              <div className="panel-header">
                <Icon id="gem_light" />
                <h1 className="panel-header__title">DIAMOND CARAT</h1>
              </div>
              <div className="panel-text-block">
                <p>
                  Diamond carat weight measures how much a diamond weighs. A
                  metric “carat” is defined as 200 milligrams.
                </p>
                <p>
                  {' '}
                  Diamond price increases with diamond carat weight because
                  larger diamonds are rarer and more desirable.
                </p>
              </div>
              <div className="panel-imgs-wrap">
                <div className="figure__carat">
                  <figure className="figure">
                    <Icon id="carat_025" className="figure__img" />
                    <figcaption>0.25ct</figcaption>
                    <figcaption>4.1mm</figcaption>
                  </figure>

                  <figure className="figure">
                    <Icon id="carat_050" className="figure__img" />
                    <figcaption>0.50ct</figcaption>
                    <figcaption>5.2mm</figcaption>
                  </figure>

                  <figure className="figure">
                    <Icon id="carat_075" className="figure__img" />
                    <figcaption>0.75ct</figcaption>
                    <figcaption>5.9mm</figcaption>
                  </figure>

                  <figure className="figure">
                    <Icon id="carat_100" className="figure__img" />
                    <figcaption>1.00st</figcaption>
                    <figcaption>6.5mm</figcaption>
                  </figure>

                  <figure className="figure">
                    <Icon id="carat_150" className="figure__img" />
                    <figcaption>1.50ct</figcaption>
                    <figcaption>7.4mm</figcaption>
                  </figure>

                  <figure className="figure">
                    <Icon id="carat_200" className="figure__img" />
                    <figcaption>2.00ct</figcaption>
                    <figcaption>8.2mm</figcaption>
                  </figure>

                  <figure className="figure">
                    <Icon id="carat_250" className="figure__img" />
                    <figcaption>2.50ct</figcaption>
                    <figcaption>9.0mm</figcaption>
                  </figure>

                  <figure className="figure">
                    <Icon id="carat_300" className="figure__img" />
                    <figcaption>3.00ct</figcaption>
                    <figcaption>9.3mm</figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
