import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { FormGroup } from '@quantumart/mobx-form-validation-kit';
import { Link } from 'react-router-dom';
import * as path from 'path';
import CartStore from 'root/store/cart';
import { trimSlash } from 'lib/navigation';
import { ScrollToTopOnMount } from 'components/common/ScrollToTop';
import Icon from 'components/common/Icon';
import { GridAreaAmount } from '../GridAreaAmount';
import { CartRoutes } from '../../constants';
import { CheckoutTab } from './CheckoutTab';
import { CustomerStep } from './CustomerStep';
import { ShippingStep } from './ShippingStep';
import { PaymentStep } from './PaymentStep';
import CartListItem from '../CartListItem';
import { IFormCustomerStep } from './CustomerStep/customerForm.types';
import { IFormShippingStep } from './ShippingStep/shippingForm.types';
import {
  IFormCardPaymentStep,
  IFormControls,
  IFormPaymentStep,
} from './PaymentStep/paymentForms.types';
import { autofill } from './cartCheckout.store';

import './CartCheckout.scss';

interface CartCheckoutPageProps extends RouteComponentProps {
  cart: CartStore;
  customerForm: FormGroup<IFormCustomerStep>;
  shippingForm: FormGroup<IFormShippingStep>;
  paymentForm: FormGroup<IFormControls>;
  billingForm: FormGroup<IFormPaymentStep>;
  cardForms: FormGroup<IFormCardPaymentStep>[];
}

export const CartCheckout = observer((props: CartCheckoutPageProps) => {
  const { cart, location } = props;

  useEffect(() => {
    if (!props.cart.items.length) {
      props.history.push(CartRoutes.Cart);
    }
    autofill.fetch();
  }, []);

  const handleNextButtonClick = async (
    event: React.SyntheticEvent,
  ): Promise<void> => {
    event.preventDefault();
    const {
      customerForm,
      shippingForm,
      paymentForm,
      billingForm,
      cardForms,
      history,
    } = props;
    const segments = trimSlash(location.pathname).split(path.sep);
    const lastSegment = segments[segments.length - 1];

    switch (lastSegment) {
      case 'customer':
        await customerForm.wait();
        customerForm.invalid && customerForm.setTouched(true);
        customerForm.valid && history.push(CartRoutes.Shipping);
        break;
      case 'shipping':
        await shippingForm.wait();
        shippingForm.invalid && shippingForm.setTouched(true);
        shippingForm.valid && history.push(CartRoutes.Payment);
        break;
      case 'payment':
        await billingForm.wait();
        billingForm.invalid && billingForm.setTouched(true);
        await paymentForm.wait();
        paymentForm.invalid && paymentForm.setTouched(true);

        for (const form of cardForms) {
          await form.wait();
          form.invalid && form.setTouched(true);
        }

        if (
          billingForm.valid &&
          paymentForm.valid &&
          !~cardForms.findIndex((form) => form.invalid)
        ) {
          history.push(CartRoutes.Confirmation);
        }
        break;
      default:
        history.replace(CartRoutes.Customer);
        break;
    }
  };

  const handlePrevButtonClick = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { history } = props;
    const segments = trimSlash(location.pathname).split(path.sep);
    const lastSegment = segments[segments.length - 1];

    switch (lastSegment) {
      case 'shipping':
        history.replace(CartRoutes.Customer);
        break;
      case 'payment':
        history.push(CartRoutes.Shipping);
        break;
      case 'customer':
      default:
        history.replace(CartRoutes.Cart);
        break;
    }
  };

  return (
    <div className="checkout-page grid">
      <ScrollToTopOnMount />

      <ul className="grid-area-breadcrumbs">
        <li>
          <Link to="/stones">search inventory</Link>
        </li>
        <li>
          <Link to="/cart">cart</Link>
        </li>
        <li>checkout</li>
      </ul>

      <div className="grid-area-link"></div>

      <h1 className="grid-area-page-title page-title">checkout</h1>

      <div className="grid-area-items-list">
        <div className="items-list block-scrollbar">
          {cart.items.map((item) => (
            <CartListItem
              className="cart-item-checkout"
              key={item.guid}
              cartItem={item}
            />
          ))}
        </div>
      </div>

      <div className="grid-area-order-info">
        <div className="tabs">
          <CheckoutTab
            id="customer"
            title="CUSTOMER"
            icon="user_active"
            checked={~location.pathname.search('customer')}
          >
            <CustomerStep />
          </CheckoutTab>

          <CheckoutTab
            id="shipping"
            icon="marker"
            title="SHIPPING"
            checked={~location.pathname.search('shipping')}
          >
            <ShippingStep cart={cart} />
          </CheckoutTab>

          <CheckoutTab
            id="payment"
            icon="card"
            title="PAYMENT"
            checked={~location.pathname.search('payment')}
          >
            <PaymentStep cart={cart} />
          </CheckoutTab>
        </div>
      </div>

      <div className="grid-area-page-pointer-prev">
        <a
          className="btn btn_sm btn_text-bold btn_bordered-default btn_fixed-height btn_stretch-mobile"
          onClick={handlePrevButtonClick}
        >
          <Icon id="long_arrow_left" className="icon_mr-sm" />
          BACK
        </a>
      </div>

      <GridAreaAmount cart={cart} />

      <div className="grid-area-additional-block">
        <div className="additional-wrap additional-wrap_mb-25">
          <p className="title-label title-label_mr-50 hidden-for-desktop">
            Tax
          </p>
          <p className="attention-message">Subtotal {cart.subtotal}</p>
        </div>
        <div className="additional-wrap additional-wrap_mb-15">
          <p className="title-label hidden-for-desktop">Tax</p>
          <p className="attention-message">Tax {cart.tax}</p>
        </div>
        <div className="additional-wrap additional-wrap_mb-15">
          <p className="title-label hidden-for-desktop">Shipping</p>
          <p className="attention-message">Free shipping</p>
        </div>
      </div>

      <div className="grid-area-page-pointer-next grid-area-page-pointer-next_mt">
        <a
          className="btn btn_sm btn_text-bold btn_bordered-default btn_fixed-height btn_stretch-mobile"
          onClick={handleNextButtonClick}
        >
          NEXT
          <Icon id="long_arrow_left" className="icon_ml-sm" />
        </a>
      </div>
    </div>
  );
});
