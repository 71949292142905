import React from 'react';
import Svg from '../Svg';

interface SpellCheckIcom {
  children?: React.ReactNode;
}

export default function Change(props: SpellCheckIcom): JSX.Element {
  return (
    <Svg width="23" height="21" viewBox="0 0 23 21" fill="none" {...props}>
      <path
        d="M15.9531 13.9766C15.875 14.0547 15.875 14.1328 15.875 14.2109V18.625H2.125V4.875H11.5391C11.6172 4.875 11.6953 4.875 11.7734 4.79688L13.0234 3.54688C13.2188 3.35156 13.0625 3 12.7891 3H2.125C1.07031 3 0.25 3.85938 0.25 4.875V18.625C0.25 19.6797 1.07031 20.5 2.125 20.5H15.875C16.8906 20.5 17.75 19.6797 17.75 18.625V12.9609C17.75 12.6875 17.3984 12.5312 17.2031 12.7266L15.9531 13.9766ZM22.0469 6.125C22.9453 5.22656 22.9453 3.78125 22.0469 2.88281L20.3672 1.20312C19.4688 0.304688 18.0234 0.304688 17.125 1.20312L6.89062 11.4375L6.5 14.9922C6.38281 16.0078 7.24219 16.8672 8.25781 16.75L11.8125 16.3594L22.0469 6.125ZM18.2188 7.29688L10.9531 14.5625L8.375 14.875L8.6875 12.2969L15.9531 5.03125L18.2188 7.29688ZM20.7188 4.21094C20.9141 4.36719 20.9141 4.60156 20.7578 4.79688L19.5469 6.00781L17.2812 3.70312L18.4531 2.53125C18.6094 2.33594 18.8828 2.33594 19.0391 2.53125L20.7188 4.21094Z"
        fill="#4D4048"
      />
    </Svg>
  );
}
