import { observable } from 'mobx';
import { IObservableValue } from 'mobx/dist/internal';

export interface IMainStore {
  isPopular: IObservableValue<boolean>;
  setPopular: (isPopular: boolean) => void;
}

export default class MainStore implements IMainStore {
  isPopular: IObservableValue<boolean> = observable.box(true);

  setPopular = (isPopular: boolean): void => {
    this.isPopular.set(isPopular);
  };
}
