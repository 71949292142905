import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import Tooltip from 'react-tooltip-lite';
import { CSSTransition } from 'react-transition-group';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTER_PATHS } from 'root/routes/constants';
import { Carousel } from 'components/common/CarouselMainPage';
import Icon from 'components/common/Icon';
import {
  CAROUSEL_ITEM_ACTIVE_WIDTH,
  CAROUSEL_ITEM_WIDTH,
  COEFFICIENT,
  ENGAGEMENT_RINGS_IMG,
  RINGS,
  RINGS_TITLES,
} from '../../constants';
import { IMainStore } from '../../store';

import './MainPage.scss';

interface MainPagePropsImpl extends RouteComponentProps {
  store: IMainStore;
}

export const MainPage = withRouter(
  observer((props: MainPagePropsImpl) => {
    /**
     * Handler for key down
     * @param event
     */
    const handleKeyDown = (event: KeyboardEvent) => {
      const { carousel } = props.store;
      if (event.code === 'ArrowLeft') {
        carousel.activeIndex > 0 && carousel.updateActiveIndex(-1);
        event.preventDefault();
      } else if (event.code === 'ArrowRight') {
        carousel.activeIndex < carousel.items.length - 1 &&
          carousel.updateActiveIndex(1);
        event.preventDefault();
      }
    };

    useEffect(() => {
      props.store.updateCarouselItems(RINGS);
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    /**
     * Handle slide carousel
     * @param {number} index
     */
    const handleSlide =
      (index: number) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const { carousel } = props.store;
        carousel.updateActiveIndex(index - carousel.activeIndex);
      };

    /**
     * Show popular product
     * @param event
     */
    const showPopularProducts = (event: React.MouseEvent<HTMLElement>) => {
      const { store } = props;
      event.stopPropagation();

      store.carousel.update(
        RINGS.filter((item) => item.popular),
        1,
      );
      store.setAnimation(true);
      store.setPopular(true);
    };

    /**
     * Show all products
     * @param event
     */
    const showAllProducts = (event: React.MouseEvent<HTMLElement>) => {
      const { store } = props;
      event.stopPropagation();

      store.carousel.update(RINGS, 1);
      store.setAnimation(true);
      store.setPopular(false);
    };

    const { items, activeIndex, activeItem } = props.store.carousel;

    return (
      <div className="stone-main-page main-page grid">
        <CSSTransition in appear timeout={3000} classNames="fadeInLeft">
          <div className="grid-area-slider-title grid-area-slider-title-1">
            {/* <span
              className={`slider-title ${props.store.isPopular && 'active'}`}
              title="Show popular shapes"
              onClick={showPopularProducts}
            >
              Popular {RINGS_TITLES.subTile}
            </span> */}
          </div>
        </CSSTransition>

        <CSSTransition in appear timeout={1000} classNames="fadeInUp">
          <div className="grid-area-title">
            <h2 className="main-title expanded uppercase">
              {RINGS_TITLES.title}
            </h2>
          </div>
        </CSSTransition>

        <CSSTransition in appear timeout={3000} classNames="fadeInRight">
          <div className="grid-area-slider-title grid-area-slider-title-2">
            {/* <span
              className={`slider-title ${!props.store.isPopular && 'active'}`}
              title="Show all shapes"
              onClick={showAllProducts}
            >
              All {RINGS_TITLES.subTile}
            </span> */}
          </div>
        </CSSTransition>

        <CSSTransition
          in
          appear
          timeout={3000}
          key={props.store.isPopular.toString()}
          classNames={`fadeInDown${props.store.isNewAnimation ? '-fast' : ''}`}
        >
          <Carousel
            sliderLength={items.length}
            carousel={props.store.carousel}
            handleSlide={handleSlide}
            productImages={ENGAGEMENT_RINGS_IMG}
            folderImg={RINGS_TITLES.folderImg}
          />
        </CSSTransition>

        <CSSTransition
          in
          appear
          timeout={3000}
          key={items.length}
          classNames={`fadeInDown${props.store.isNewAnimation ? '-fast' : ''}`}
        >
          <div className="grid-area-pagination">
            <ul
              className="pagination"
              style={{
                width: `${
                  (items.length - 1) * CAROUSEL_ITEM_WIDTH +
                  CAROUSEL_ITEM_ACTIVE_WIDTH
                }vw`,
                transform: `translate3d(${
                  items.length * COEFFICIENT -
                  activeIndex * CAROUSEL_ITEM_WIDTH -
                  COEFFICIENT
                }vw, 0, 0)`,
              }}
            >
              {items.map((ring, index) => {
                return (
                  <li
                    className={`pagination-item ${
                      activeIndex === index ? 'active' : ''
                    }`}
                    key={index}
                    onClick={handleSlide(index)}
                  ></li>
                );
              })}
            </ul>
          </div>
        </CSSTransition>

        <CSSTransition in appear timeout={3000} classNames="fadeInUp2">
          <div className="grid-area-slider-description">
            <div className="description-title-wrap">
              <h2 className="description-title uppercase">
                {activeItem.title}
                <Tooltip
                  content="All of the loose diamonds that are offered on Venori.com are conflict-free and are graded by one of top three grading laboratories: GIA, AGS or IGI. The diamond certificates are listed on the diamond information page."
                  direction="up-start"
                  className="custom-tooltip"
                  styles={{ position: 'absolute', display: 'inline-block' }}
                >
                  <span className="tooltip-base">
                    <Icon id="leaf_regular" />
                  </span>
                </Tooltip>
              </h2>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in appear timeout={3000} classNames="fadeInRight">
          <div className="grid-area-next-page">
            <button className="btn btn_link btn_link-brown btn_p-0 btn_text-xl btn_text-bold btn_font-roboto">
              <Link
                to={{
                  pathname: ROUTER_PATHS.RINGS_ENGAGEMENT_CATALOG_PATH,
                }}
              >
                {' '}
                SEARCH INVENTORY
              </Link>
            </button>
          </div>
        </CSSTransition>
      </div>
    );
  }),
);
